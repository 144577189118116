<template>
    <div>
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="8" v-if="activeCre">
                        <v-card
                        style="margin-top: 18px"
                        class="mx-auto"
                        height="450"
                        >
                            <v-row style="display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;">
                                <v-col style="margin-top: 15px" cols="3">
                                    <v-combobox
                                        v-model="nameUser"
                                        :items="users"
                                        label="Ingrese usuario"
                                        outlined
                                        dense
                                    ></v-combobox>
                                </v-col>
                                <v-col style="margin-top: 15px"  cols="3" >
                                    <v-select
                                        v-model="selectCanvas"
                                        :items="listCanvas"
                                        label="Seleccione canvas"
                                        dense
                                        outlined
                                    ></v-select>
                                </v-col>
                                <v-col style="margin-top: 15px"  cols="3" >
                                    <v-select
                                        v-model="selectPriva"
                                        :items="listPriva"
                                        label="Tipo de privacidad"
                                        dense
                                        outlined
                                    ></v-select>
                                </v-col>
                                <v-col cols="1" style="display: flex;
                                justify-content: center; margin-top: 10px
                                align-items: flex-start;">
                                    <v-btn
                                    class="mx-2"
                                    small
                                    fab
                                    dark
                                    color="light-green darken-4"
                                    @click="createPrivacy();"
                                    >
                                        <v-icon dark>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-responsive
                                class="overflow-y-auto"
                                max-height="400"
                            >
                                <!-- <v-list-item
                                    v-for="(codeP, index) in listCodeP"
                                    :key="index"
                                >
                                    <v-list-item-avatar>
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-code-braces
                                        </v-icon>
                                    </v-list-item-avatar>
                            
                                    <v-list-item-content>
                                        <v-list-item-title v-text="codeP.code"></v-list-item-title>
                                
                                        <v-list-item-subtitle v-text="codeP.createdAt"></v-list-item-subtitle>
                                    </v-list-item-content>
                            
                                    <v-list-item-action>
                                        <v-btn icon>
                                            <v-icon color="grey lighten-1">mdi-information</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item> -->
                            </v-responsive>
                        </v-card>  
                    </v-col>
                    <v-col cols="8" v-if="activeEdi">
                        <v-card
                        style="margin-top: 18px"
                        class="mx-auto"
                        height="450"
                        >
                            <v-row style="display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;">
                                <v-col cols="6">
                                    <v-text-field
                                        label="Ingrese nombre"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" style="display: flex;
                                justify-content: center;
                                align-items: center;">
                                    <v-btn
                                    class="mx-2"
                                    small
                                    fab
                                    dark
                                    color="light-green darken-4"
                                    >
                                        <v-icon dark>
                                            mdi-magnify
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-responsive
                                class="overflow-y-auto"
                                style="margin-top: 22px;"
                                max-height="400"
                            >
                                <!-- <v-list-item
                                    v-for="(codeP, index) in listCodeP"
                                    :key="index"
                                >
                                    <v-list-item-avatar>
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-code-braces
                                        </v-icon>
                                    </v-list-item-avatar>
                            
                                    <v-list-item-content>
                                        <v-list-item-title v-text="codeP.code"></v-list-item-title>
                                
                                        <v-list-item-subtitle v-text="codeP.createdAt"></v-list-item-subtitle>
                                    </v-list-item-content>
                            
                                    <v-list-item-action>
                                        <v-btn icon @click="$bvModal.show(`modal-codeEdit-${index}`)">
                                            <v-icon color="grey lighten-1"
                                            >mdi-lead-pencil</v-icon>
                                        </v-btn>
                                    </v-list-item-action>

                                    <b-modal :id="`modal-codeEdit-${index}`" size="lg" hide-footer>
                                        <v-row style="justify-content: space-evenly;">
                                            <v-col cols="4" style="display: flex; text-align: center;
                                            align-items: center;">
                                                Codigo: {{codeP.code}}
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="idSelect"
                                                    :items="folderGoogle"
                                                    label="Asignar carpeta"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="1"></v-col>
                                            <v-col cols="4">
                                                <v-btn
                                                    color="blue lighten-4"
                                                    class="ma-2 white--text"
                                                    @click="editCode(codeP.id)"
                                                >
                                                Guardar
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </b-modal>

                                </v-list-item> -->
                            </v-responsive>
                        </v-card>  
                    </v-col>
                    <v-col cols="8" v-if="activeDel">
                        <v-card
                        style="margin-top: 18px"
                        class="mx-auto"
                        height="450"
                        >
                            <v-row style="display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;">
                                <v-col cols="6">
                                    <v-text-field
                                        label="Ingrese nombre"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" style="display: flex;
                                justify-content: center;
                                align-items: center;">
                                    <v-btn
                                    class="mx-2"
                                    small
                                    fab
                                    dark
                                    color="light-green darken-4"
                                    >
                                        <v-icon dark>
                                            mdi-magnify
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-responsive
                                class="overflow-y-auto"
                                style="margin-top: 22px;"
                                max-height="400"
                            >
                                <!-- <v-list-item
                                    v-for="(codeP, index) in listCodeP"
                                    :key="index"
                                >
                                    <v-list-item-avatar>
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-code-braces
                                        </v-icon>
                                    </v-list-item-avatar>
                            
                                    <v-list-item-content>
                                        <v-list-item-title v-text="codeP.code"></v-list-item-title>
                                
                                        <v-list-item-subtitle v-text="codeP.createdAt"></v-list-item-subtitle>
                                    </v-list-item-content>
                            
                                    <v-list-item-action>
                                        <v-btn icon @click="$bvModal.show(`modal-codeDelete-${index}`)">
                                            <v-icon color="grey lighten-1">mdi-delete-empty</v-icon>
                                        </v-btn>

                                        <b-modal :id="`modal-codeDelete-${index}`" size="lg" hide-footer>
                                            <v-row>
                                                <v-col cols="8" style="display: flex;
                                                justify-content: center;
                                                align-items: center;">
                                                    {{"Desea eliminar este codigo? Confirme haciendo click en la casilla: "}}
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-checkbox
                                                    v-model="confirmDelete"
                                                    label="Confirmar"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="1"></v-col>
                                                <v-col cols="4">
                                                    <v-btn
                                                        :disabled="!confirmDelete"
                                                        style="color: white;"
                                                        color="#F44336"
                                                        class="ma-2 white--text"
                                                        @click="deleteCode(codeP.id)"
                                                    >
                                                    Eliminar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </b-modal>

                                    </v-list-item-action>
                                </v-list-item> -->
                            </v-responsive>
                        </v-card>  
                    </v-col>
                    <v-col cols="2"></v-col>
                </v-row> 
                <v-row>
                    <v-card
                        style="display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 18px;"
                        class="mx-auto"
                        height="60"
                        max-width="670"
                    >
                        <v-slide-group
                        multiple
                        show-arrows
                        >
                            <v-slide-item
                            >
                                <v-btn
                                class="mx-2"
                                :class="activeCre ? `blue-grey white--text` : ``"
                                depressed
                                rounded
                                @click="activeCre = true; countCre = countCre+1"
                                >
                                Asignar privacidad
                                </v-btn>
                            </v-slide-item>
                            <v-slide-item
                            >
                                <v-btn
                                class="mx-2"
                                :class="activeEdi ? `blue-grey white--text` : ``"
                                depressed
                                rounded
                                @click="activeEdi = true; countEdi = countEdi+1"
                                >
                                Editar privacidad
                                </v-btn>
                            </v-slide-item>
                            <v-slide-item
                            >
                                <v-btn
                                class="mx-2"
                                :class="activeDel ? `blue-grey white--text` : ``"
                                depressed
                                rounded
                                @click="activeDel = true; countDel = countDel+1"
                                >
                                Eliminar privacidad
                                </v-btn>
                            </v-slide-item>
                        </v-slide-group>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="notiAsingC"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha asignado canva(s) a <strong>{{nameUser.text}}</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="notiAsingC = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar
            v-model="notiErrorAsingC"
            color="error"
            outlined
            timeout="5000"
            :top="true"
        >
            No se ha podido asignar canva(s) a <strong>{{nameUser.text}}</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="error"
                text
                v-bind="attrs"
                @click="notiErrorAsingC = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>

import api from "@/api";

export default {
    name: "configPrivaciCan",
    data(){
        return{
            activeCre: true,
            activeEdi: false,
            activeDel: false,
            countEdi: 0,
            countDel: 0,
            countCre: 0,
            users: [],
            nameUser: '',
            listPriva: [
                {   
                    text: 'Lectura',
                    value: 0
                },
                {
                    text: 'Escritura',
                    value: 1
                }
            ],
            selectPriva: '',
            listCanvas: [],
            selectCanvas: '',
            notiAsingC: false,
            notiErrorAsingC: false
        }
    },
    mounted(){
        this.getUsers()
        this.getCanvas()
        
    },
    props: [ 'idProject','dataGeo' ],
    methods: {
        getUsers: async function () {
            var self = this
            var clientsTmp = []
            this.users = []

            await api
            .request(
                "get",
                "/listUsers/" + JSON.parse(localStorage.user).client_id + "/clients"
            )
            .then((data) => {
                clientsTmp = data.data.users;
                console.log(data)
            })
            .catch(function (e) {
                console.log(e);
            });
    
            clientsTmp.forEach((element) => {
                if (JSON.parse(localStorage.user).id != element.id){
                    if(element.roleId.length > 0){
                        element.roleId.map(function(rol){
                            if(rol.project_id == self.idProject && rol.role_id == 2){
                                var tmp_obj = {
                                    value: element.id,
                                    text: element.name,
                                };

                                self.users.push(tmp_obj);
                            }else{
                                if(element.grupoRole.length > 0){
                                    element.grupoRole.map(function(grupo){
                                        if(grupo.idProject == self.idProject && grupo.IdRole == 2){
                                            var tmp_obj = {
                                                value: element.id,
                                                text: element.name,
                                            };

                                            self.users.push(tmp_obj);
                                        }
                                    })
                                }
                            }
                        })
                    }                    
                }
                
            });
            console.log(this.users)
        },
        createPrivacy: async function(){

            var canvaAny = `${this.selectPriva}, ${this.selectCanvas}`
            var self = this
            var obj =
            {
                arrayPolicy: [
                    {
                        Canvas: canvaAny,
                        idUser: this.nameUser.value,
                        idProject: this.idProject
                    }
                ]
            }

            console.log(obj)
            await api
            .request(
                "post",
                "/createPolicy",
                obj
            )
            .then((response) => {
                console.log(response)
                self.notiAsingC = true
            })
            .catch(function (e) {
                console.log(e);
                self.notiErrorAsingC = true
            });
        },
        getCanvas: async function(){
            this.listCanvas = []
            if(this.dataGeo.length){
                this.dataGeo.forEach((element) => {
                    this.listCanvas.push({
                        text: element.Name,
                        value: element.IdCanvas
                    })
                })
            }

            this.listCanvas.unshift({
                text: "Todos los canvas",
                value: "x"
            })
        }

    },
    watch:{
        countEdi: function(){
            this.activeCre = false
            this.activeDel = false
        },
        countDel: function(){
            this.activeCre = false
            this.activeEdi = false
        },
        countCre: function(){
            this.activeEdi = false
            this.activeDel = false
        }
    }
}
</script>
<style>
    
</style>