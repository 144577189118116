import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {}

export default new Vuetify(opts,
    {
        icons: {
            iconfont: 'mdi',
            values: {
                plus: 'mdi-plus ',
            }
          },
        
    })