import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/auth/Login.vue'
import MainF from '@/components/layout/MainFrame.vue'
import ChartComponents from '@/components/layout/chart/Chart.vue'
import mainCanvasGeo from '@/components/layout/canvasGeorrefenciado/mainCanvasGeo.vue'
import mainCanvasMpbits from '@/components/layout/canvasGeorrefenciado/mainCanvasMpbits.vue'
import ActiveAccount from '@/components/register/ActiveAccount.vue'
import ActiveAccountUser from '@/components/register/ActiveAccountUser.vue'
import RecoveryPassword from '@/components/register/RecoveryPassword.vue'

Vue.use(VueRouter)

const guest = (to, from, next) => {
  if (!localStorage.getItem('user')) {
    return next()
  } else {
    return next('/app')
  }
}

const auth = (to, from, next) => {
  if (localStorage.getItem('user')) {
    return next()
  } else {
    return next('/')
  }
}

const routes = [
  {
    path: '/',
    name: 'LoginS',
    beforeEnter: guest,
    component: Login
  },
  {
    path: '/app',
    name: 'MainF',
    beforeEnter: auth,
    component: MainF,
    children: [
      {
        path: 'mapGeo',
        name: 'mainCanvasGeo',
        component: mainCanvasGeo,
      },
      {
        path: 'mapBits',
        name: 'mainCanvasMpbits',
        component: mainCanvasMpbits,
      },
      {
        path: ':projectId/chart/:idChart/:namePoint',
        name: 'Chart',
        component: ChartComponents,
      },
    ]
  },
  {
    path: '/active-account/:idClient/start',
    beforeEnter: guest,
    component: ActiveAccount
  },
  {
    path: '/active-account-user/:emailClient/start',
    beforeEnter: guest,
    component: ActiveAccountUser
  },
  {
    path: '/recovery-password/:emailClient/start',
    beforeEnter: guest,
    component: RecoveryPassword
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
