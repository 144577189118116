<template>
    <div>
        <v-card
        style="width: 185px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
        @click="dialog=true">
            <v-img
                :src="icon_delete"
                alt=""
                style="width: 110px"
            />

            <v-card-title>
                <h3 style="font-size: 12px">Eliminar grupo de usuarios</h3>
            </v-card-title>
        </v-card>

        <v-dialog v-model="dialog" width="600">
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Elegir proyecto
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        step="2"
                    >
                        Seleccionar grupo
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card height="250px" class="mb-12" style="display: flex; flex-direction: row;
                        align-items: center;">
                            <v-col>
                                <v-row style="display: flex; justify-content: center;">
                                    <v-col cols="10">
                                        <v-select
                                            v-model="selectP"
                                            :items="listProject"
                                            label="Seleccione el proyecto"
                                            persistent-hint
                                            return-object
                                            single-line
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>

                        <v-btn 
                        :disabled="selectP.length == 0"
                        color="primary"
                        @click="getGroup()"
                        >
                            Continuar
                        </v-btn>

                        <v-btn @click="dialog = false; reset()">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                            class="mb-12"
                            height="200px"
                        >
                            <v-row style="display: flex; justify-content: center;">
                                <v-col cols="10">
                                    <v-select
                                        v-model="selectGroup"
                                        :items="listGroup"
                                        label="Seleccione el grupo que desea eliminar"
                                        persistent-hint
                                        return-object
                                        single-line
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-btn
                            :disabled="selectGroup.length == 0"
                            color="green"
                            @click="deleteGroup()"
                            style="color:white;"
                        >
                            Finalizar
                        </v-btn>

                        <v-btn @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-snackbar
                v-model="NotideleteG"
                color="success"
                outlined
                timeout="5000"
                :top="true"
            >
                Se ha eliminado el <strong>Grupo</strong>

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="success"
                    text
                    v-bind="attrs"
                    @click="NotideleteG = false"
                    >
                    <v-icon>
                        mdi-close
                    </v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            
            <v-snackbar
                v-model="NotiFail"
                color="red accent-2"
                outlined
                timeout="5000"
                :top="true"
            >
                No se puedo realizar la accion

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="red accent-2"
                    text
                    v-bind="attrs"
                    @click="NotiFail = false"
                    >
                    <v-icon>
                        mdi-close
                    </v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

    </div>
</template>
<script>

import api from '../../../../../../../api/index.js'
import icon_delete from '../../../../../../../assets/img/icons/icon_users_delete.svg'

export default {
    name: 'deleteGroup',
    data(){
        return{
            icon_delete: icon_delete,
            NotideleteG: false,
            NotiFail: false,
            dialog: false,
            e1: 1,
            selectGroup: {},
            selectP: {},
            foundGroup: [],
            listGroup: [],

        }
    },
    props: [ 'listProject' ],
    methods:{
        getGroup: async function(){
            var self = this

            await api
            .request("get", "/getGroupByProject/" + self.selectP.value)
            .then(function (response) {
                
                response.data.map(function(arr){
                    self.listGroup.push({
                        text: arr.grupos.name,
                        value: arr.grupos.id
                    })
                })

                self.e1 = 2
            })
            .catch(function (e) {
                console.log(e);
                self.NotiFail = true
            });
        },
        deleteGroup: async function(){
            var self = this

            await api
            .request("delete", "/deleteGroup/" + self.selectGroup.value +"/" + 
                JSON.parse(localStorage.user).id)
            .then(function (response) {
                console.log(response)

                
                self.NotideleteG = true
                self.dialog = false
            })
            .catch(function () {
                self.NotiFail = true
            });
        },
        reset: function(){
            this.dialog = false
            this.e1= 1
            this.selectGroup = {}
            this.selectP =  {}
        },
    },
    watch:{
        dialog: function(){
            if(!this.dialog){
                this.reset();
            }
        },
    }
}
</script>
<style>
    
</style>