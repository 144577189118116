<template>
  <div>
    <router-view/>
  </div>
</template>
<script>

  export default {
    
    name: "App",
    data() {
      return {
      };
    },
    methods: {},
    created(){
      if(localStorage.getItem("lang") == null){
        localStorage.setItem("lang", "es");
      }
    },
    mounted() {
    },
  };
</script>
<style>
  @import './assets/styles/style.css';
</style>
