<template>
  <div class="containt_slide" style="height: 100px; width: 930px">
    <v-slide-group class="pa-4" show-arrows>
      <v-card
        v-if="permitEdit"
        @click="viewModalCreateVistaGeo = true"
        class="ma-1"
        :height="posiSelect != null ? 70 : 80"
        :width="posiSelect != null ? 60 : 70"
        style="display: flex; justify-content: center"
      >
        <div
          style="
            display: flex;
            justify-content: center;
            border-style: dotted;
            width: inherit;
          "
        >
          <v-icon v-html="'$vuetify.icons.plus'" size="35"></v-icon>
        </div>
        <modalCreateVistaGeo
          :permitEdit="permitEdit"
          :dataGeoL="dataGeo"
          :viewModalCreateVistaGeo="viewModalCreateVistaGeo"
        />
      </v-card>
      <v-slide-item v-for="(dataG, index) in dataGeo" :key="index">
        <div>
          <v-card
            :style="
              (index == 0 && permitEdit) || (index == 0 && viewPrincipal)
                ? 'background-color: #efb810;'
                : ''
            "
            class="ma-1"
            :height="posiSelect != null ? 70 : 80"
            :width="posiSelect != null ? 60 : 70"
            @click="
              valueSelect(index, dataG);
              posiSelect = index;
            "
          >
            <v-img
              style="margin-left: 5px; margin-top: 3px"
              :max-height="posiSelect != null ? 48 : 58"
              :max-width="posiSelect != null ? 48 : 58"
              :src="logoCanvasGPS"
            >
            </v-img>
            <infinite-slide-bar>
              <h6 class="marquee" style="margin-top: 5px">
                {{ dataG.Name }}&nbsp;
              </h6>
            </infinite-slide-bar>
          </v-card>
          <div
            v-if="posiSelect == index"
            style="
              display: flex;
              height: 10px;
              width: 60px;
              justify-content: center;
              margin-top: 8px;
            "
          >
            <i style="color: #e8e8e8" class="fa-solid fa-circle fa-2xs"></i>
          </div>
        </div>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>
<script>
import modalCreateVistaGeo from "../../../../modals/ModalCreateVistaGeo.vue";
import logoCanvasGPS from "../../../../../assets/img/icons/icon_card_canva_georeferenciado.svg";

export default {
  name: "vistaGeo",
  components: {
    modalCreateVistaGeo,
  },
  data() {
    return {
      logoCanvasGPS,
      posiSelect: null,
      viewModalCreateVistaGeo: false,
      selectC: {
        posicionGeo: null,
        dataGS: null,
        IdCanvas: null,
      },
    };
  },
  props: ["dataGeo", "permitEdit", "orgdataGeo", "viewPrincipal"],
  mounted: async function () {
    this.posiSelect = await this.$store.state.posicionselectCE;
    var rInitGeo = {
      posicionG: 0,
      dataGeo: await this.dataGeo[0],
    };
    this.$store.commit("setRestoreGeo", rInitGeo);
  },
  methods: {
    valueSelect: async function (index, dataG) {
      await this.verifyPosicion(dataG);
      this.selectC.dataGS = dataG;

      this.$store.commit("setPosicionselectC", await this.selectC.posicionGeo);
      this.$store.commit("setPosicionselectCE", await this.selectC.posicionGeo);
      this.$store.commit("setDataselectC", await this.selectC.dataGS);

      var data = {
        viewprojectsM: false,
        viewprojectSelect: false,
        viewcanvasG: true,
        viewcanvasMb: false,
      };
      this.$store.commit("setPermissionC", dataG.permissionsC);
      this.$store.commit("setselectionCanvas", data);
      this.$store.commit("setPosicionselectMbE", null);
    },
    verifyPosicion: async function (dataG) {
      var self = this;

      await this.orgdataGeo.map(function (dataGeo, index) {
        if (dataGeo.IdCanvas == dataG.IdCanvas) {
          self.selectC.posicionGeo = index;
          self.selectC.IdCanvas = dataGeo.IdCanvas;
        }
      });
    },
  },
  watch: {
    posiSelect: function () {
      console.log(this.posiSelect);
    },
    dialog: function () {
      if (this.dialog == true) {
        this.$store.commit("setactiveModalCreateVistaGeo", true);
      }
    },
    "$store.state.activeModalCreateVistaGeo": function () {
      if (this.$store.state.activeModalCreateVistaGeo == false) {
        this.viewModalCreateVistaGeo =
          this.$store.state.activeModalCreateVistaGeo;
      }
    },
    "$store.state.restoreInit": function () {
      this.posiSelect = null;
    },
    "$store.state.posicionselectCE": function () {
      this.posiSelect = this.$store.state.posicionselectCE;
    },
  },
};
</script>
<style></style>
