<template>
  <div class="center grid">
    <b-row>
      <b-col sm="1">
      </b-col>
      <b-col sm="10">
        <b-card style="margin-top: 29px; margin-bottom: 30px;">
          <div style="height: 90px;">
          </div>
          <b-row style="margin-left: 3px; margin-right: 3px;">
            <b-col sm="1">
            </b-col>
            <b-col sm="2">
              <createuser-modal id="card_style"/>
            </b-col>
            <b-col sm="2" >
              <rolescliente-modal :dataBusiness="dataBusiness" :listProject="listProject" id="card_style" />
            </b-col>
            <b-col sm="2" >
              <deleteuser-modal id="card_style" />
            </b-col>
            <b-col sm="2" >
              <blockuser-modal  :msjunblock="msjunblock" v-on:blocked_user="onBlockUser"  id="card_style"/>
            </b-col>
            <b-col sm="2" >
              <unblockuser-modal :msjblock="msjblock" v-on:unblocked_user="onUnBlockUser"  id="card_style"/>
           </b-col>
           <b-col sm="1">
          </b-col>
          </b-row>
          <div style="height: 110px;">
          </div>
        </b-card>
      </b-col>
      <b-col sm="1">
      </b-col>
    </b-row>
  </div>
</template>
<script>

import api from "@/api";
import createuserModal from "./modals/CreateUserModal.vue";
import deleteuserModal from "./modals/DeleteUserModal.vue";
import blockuserModal from "./modals/BlockuserModal.vue";
import unblockuserModal from "./modals/UnblockuserModal.vue";
import rolesclienteModal from "../usuarios/modals/RolesUserModal.vue"

export default {
  name: "usersB",
  components: {
    createuserModal,
    deleteuserModal,
    blockuserModal,
    unblockuserModal,
    rolesclienteModal
  },
  data() {
    return {
      msjblock: '',
      msjunblock: '',
      listProject: [],
      dataBusiness: []
    }
  },
  mounted(){
    this.sizeChange();
    this.initListG();
    this.getBusiness();
  },
  props: [ 'projects' ],
  methods: {
    async getBusiness(){
      var self = this

      var obj = {
        idUser: JSON.parse(localStorage.user).id
      }

      await api
      .request("post", "/showCompany", obj)
      .then((data) => {
        console.log(data)
        self.dataBusiness = data.data
      })
      .catch(function (error) {
        console.log(error)
      });
    },
    initListG: async function(){
      var self = this
      this.projects.map(function(project){
        self.listProject.push({
          text: project.name,
          value: project.id
        })
      })
    },
    sizeChange(){
      var data = {
        namepage: "users",
        sizePageChange: false,
      }
      this.$store.commit("setNovedadesDash", data);
    },
    onBlockUser() {
      var al = Math.floor(Math.random() * (100 - 1)) + 1
      this.msjblock = al
    },
    onUnBlockUser() {
      var al = Math.floor(Math.random() * (100 - 1)) + 1
      this.msjunblock = al
    }
  },
};
</script>
<style>
  #card_style:hover div{
    transition: 0.3s all;
    -webkit-transform:scale(1.1);
    transform:scale(1.1);
  }

</style>