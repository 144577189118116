<template>
        <v-row>
            <v-col cols="6" style="display: flex; justify-content: flex-end;">
                <v-img
                    :src="logoS"
                    max-height="70"
                    max-width="70"
                >
                </v-img>
            </v-col>
        </v-row>
</template>
<script>

import LogoS from "../../../assets/img/logos/safered-logo-02.svg"

export default {
    name: 'NavbarP',
    components:{

    },
    data(){
        return{
            logoS: LogoS,
        };
    },
    mounted(){
    },
    methods: {
    }
}
</script>
<style>

</style>