<template>
    <div>
        <v-card
        style="width: 170px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
        @click="dialog = true">
            <v-img
                :src="icon_edit"
                alt=""
                style="width: 110px"
            />

            <v-card-title>
                <h3 style="font-size: 12px">Editar empresa</h3>
            </v-card-title>
        </v-card>
        <v-dialog v-model="dialog" width="650">
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Elegir empresa
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        step="2"
                    >
                        Editar empresa
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card class="mb-12" height="180px" style=" display:flex; 
                            align-items: center;">
                            <v-row >
                                <v-combobox
                                    v-model="selectBusiness"
                                    :items="dataBusiness"
                                    label="Seleccione la empresa que desea editar"
                                    item-text="name"
                                    required
                                ></v-combobox>
                            </v-row>
                        </v-card>
                        <v-btn
                            :disabled="selectBusiness == null"
                            color="primary"
                            @click="mountData(); getCountrie(); getState(); getCities();
                            e1 = 2"
                        >
                            Continuar
                        </v-btn>

                        <v-btn @click="dialog = false; reset()">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>
                    <v-stepper-content v-if="selectBusiness != null" step="2">
                        <v-card class="mb-5">
                            <v-row>

                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        v-model="selectBusiness.name"
                                        :counter="55"
                                        label="Nombre de la empresa"
                                        required
                                        :error-messages="error_nameBusiness"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        v-model="selectBusiness.nit"
                                        :counter="12"
                                        label="Nit"
                                        type="number"
                                        required
                                        :error-messages="error_nit"
                                    ></v-text-field>
                                </v-col>

                            </v-row>

                            <v-row>

                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-combobox
                                        v-model="selectCountry"
                                        :items="countries"
                                        label="Pais de domicilio de la empresa"
                                        item-text="country"
                                        required
                                    ></v-combobox>
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >   
                                    <v-combobox
                                        v-model="selectState"
                                        :items="states"
                                        label="Estado de domicilio de la empresa"
                                        item-text="name"
                                        required
                                    ></v-combobox>
                                </v-col>
                                <v-col
                                cols="12"
                                md="6"
                                >   
                                    <v-combobox
                                        v-model="selectBusiness.city"
                                        :items="cities"
                                        label="Ciudad de domicilio de la empresa"
                                        required
                                    ></v-combobox>
                                </v-col>

                            </v-row>
                            
                            <v-row>

                                <v-col
                                cols="12"
                                md="6"
                                >
                                    <v-text-field
                                        v-model="selectBusiness.address"
                                        label="Dirección del domicilio"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="6"
                                >
                                    <v-text-field
                                        v-model="selectBusiness.legal_representative"
                                        label="Nombre del representante legal"
                                        :counter="55"
                                        required
                                    ></v-text-field>
                                </v-col>

                            </v-row>
                        </v-card>
                        <v-btn
                            :disabled="selectBusiness == null"
                            color="primary"
                            @click="sendUpdateBusiness(); e1 = 2"
                        >
                            finalizar
                        </v-btn>

                        <v-btn @click="dialog = false; reset()">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <v-snackbar
            v-model="notiUpdate"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha actualizado la empresa

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="notiUpdate = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar
            v-model="notiNoUpdate"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
            No se pudo actualizar la empresa

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="notiNoUpdate = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import api from "@/api";
import axios from "axios";
import icon_edit from '../../../../../../../assets/img/icons/icon_edit_business.svg'

export default {
    name: 'editBusiness',
    data(){
        return{
            icon_edit: icon_edit,
            dialog: false,
            selectBusiness: null,
            e1: 1,
            error_nameBusiness: '',
            error_nit: '',
            countries: null,
            states: null,
            cities: null,
            selectState: null,
            selectCountry: null,
            notiUpdate: false,
            notiNoUpdate: false
        }
    },
    props: [ 'dataBusiness' ],
    async mounted(){

    },
    methods:{
        mountData(){
            this.selectState = this.selectBusiness.state;
            this.selectCountry = this.selectBusiness.country;
        },
        async getCountrie(){
            await axios
            .get("https://countriesnow.space/api/v0.1/countries/")
            .then((result) => {
                this.countries = result.data.data;
            });
        },
        async getState(){

            await axios
            .post("https://countriesnow.space/api/v0.1/countries/states", {
                country: this.selectBusiness.country,
            })
            .then((result) => {
                this.states = result.data.data.states;
            });
        },
        async getCities(){    

            await axios
            .post("https://countriesnow.space/api/v0.1/countries/state/cities", {

                country:  this.selectBusiness.country,
                state: this.selectBusiness.state,
            })
            .then((result) => {
                this.cities = result.data.data;
            });
        },
        async sendUpdateBusiness(){
            var self = this;
            
            await api
            .request("put", `/company/${this.selectBusiness.id}/edit`, this.selectBusiness)
            .then((data) => {
                if(data.data.message == "Empresa actualizada correctamente"){
                    self.notiUpdate = true;

                    self.dialog = false
                    var count = 1 + self.$store.state.refreshBusiness;
                    self.$store.commit("setRefreshBusiness",  count);
                }
            })
            .catch(function() {
                self.notiNoUpdate = true;
            });
        },
        reset: function(){
            this.dialog = false
            this.e1= 1
            this.selectState = null,
            this.selectCountry = null
        },
    },
    watch:{
        selectState: function(){
            if(this.selectState != null)
                if(this.selectState.name != undefined){
                    this.selectBusiness.state =  this.selectState.name
                    this.getCities()
                }
        },
        selectCountry: function(){
            if(this.selectCountry != null)
                if(this.selectCountry.country != undefined){
                    this.selectBusiness.country =  this.selectCountry.country
                    this.getState()
                }
        },
        dialog: function(){
            if(!this.dialog){
                this.reset();
            }
        },
    }
}
</script>

<style>

</style>