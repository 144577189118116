<template>
  <v-card style="height: 530px; width: auto">
    <l-map
      v-if="center != null"
      :ref="refM"
      :zoom="zoom"
      :center="center"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
      @update:bounds="boundsUpdated"
      style="height: 530px; width: auto; z-index: 1 !important"
    >
      <!-- <v-geosearch :options="geosearchOptions" ></v-geosearch> -->
      <l-control v-if="principal == 0" position="topleft">
        <div class="leaflet-draw-section">
          <div class="leaflet-bar leaflet-draw-toolbar-top">
            <a
              data-toggle="tooltip"
              data-placement="left"
              title="Crear punto manual"
              v-b-modal.modal-inputCoordenadas
            >
              <i
                syle="color: #464646;"
                class="fa-solid fa-map-location-dot"
              ></i>
            </a>

            <b-modal
              id="modal-inputCoordenadas"
              size="lg"
              hide-footer
              hide-backdrop
              centered
              content-class="shadow"
              :title="`Crear punto para el canvas: ${nameCanvas}`"
            >
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="dataPoint.lat"
                    label="Latitud"
                  ></v-text-field>
                  <v-text-field
                    v-model="dataPoint.name"
                    label="Nombre"
                  ></v-text-field>
                  <v-text-field
                    v-model="dataPoint.sector"
                    label="Sector"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="dataPoint.lng"
                    label="Longitud"
                  ></v-text-field>
                  <v-text-field
                    v-model="dataPoint.tipoSensor"
                    label="Tipo de sensor"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="display: flex; justify-content: flex-end">
                  <v-btn
                    elevation="2"
                    color="#57B55D"
                    style="color: white"
                    @click="createPoint()"
                  >
                    Crear
                  </v-btn>
                </v-col>
              </v-row>
            </b-modal>
          </div>
        </div>
      </l-control>

      <l-control position="topright">
        <v-col class="col_iconMap2" v-if="permitEdit">
          <v-row class="row_iconMap">
            <a v-if="principal != 0" v-b-modal.modal-canvasAsing>
              <i
                class="fa-brands fa-codepen fa-2x"
                v-b-popover.hover=""
                title="Asignar figura"
                style="color: black; cursor: pointer"
              ></i>
            </a>

            <b-modal
              id="modal-canvasAsing"
              size="lg"
              hide-footer
              hide-backdrop
              centered
              content-class="shadow"
              :title="`Asignar área o punto para: ${nameCanvas}`"
            >
              <v-col>
                <h6>Áreas:</h6>
                <v-row>
                  <div style="height: 150px; overflow-y: scroll">
                    <v-list flat subheader three-line>
                      <v-list-item-group v-model="itemsAreas" multiple>
                        <template
                          v-for="(dataPArea, index) in dataObjectP.areas"
                        >
                          <v-divider
                            v-if="!dataPArea"
                            :key="`divider-${index}`"
                          ></v-divider>

                          <v-list-item
                            v-else
                            :key="`item-${index}`"
                            :value="dataPArea"
                            active-class="deep-purple--text text--accent-4"
                          >
                            <template v-slot:default="{ active }">
                              <v-list-item-action
                                style="margin-top: 20px; margin-right: 15px"
                              >
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title
                                  >Nombre:
                                  {{ dataPArea.name }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  v-if="dataPArea.type == 'rectangle'"
                                  >Tipo: Rectángulo</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  v-if="dataPArea.type == 'polygon'"
                                  >Tipo: Poligono</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  >Fecha creacion:
                                  {{
                                    dataPArea.created_at
                                  }}</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-row>
              </v-col>
              <v-col>
                <h6>Puntos:</h6>
                <v-row>
                  <div style="height: 150px; overflow-y: scroll">
                    <v-list flat subheader three-line>
                      <v-list-item-group v-model="itemsPuntos" multiple>
                        <template
                          v-for="(dataPPunto, index) in dataObjectP.puntos"
                        >
                          <v-divider
                            v-if="!dataPPunto"
                            :key="`divider-${index}`"
                          ></v-divider>

                          <v-list-item
                            v-else
                            :key="`item-${index}`"
                            :value="dataPPunto"
                            active-class="deep-purple--text text--accent-4"
                          >
                            <template v-slot:default="{ active }">
                              <v-list-item-action
                                style="margin-top: 20px; margin-right: 15px"
                              >
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title
                                  >Nombre:
                                  {{ dataPPunto.Codigo }}</v-list-item-title
                                >
                                <v-list-item-subtitle
                                  >UbigacionGPS:
                                  {{
                                    dataPPunto.UbigacionGPS
                                  }}</v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  >Fecha creacion:
                                  {{
                                    dataPPunto.created_at
                                  }}</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-row>
                <v-col
                  style="
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 15px;
                  "
                >
                  <v-btn
                    elevation="2"
                    small
                    @click="addFigures()"
                    style="color: white"
                    color="#409E92"
                  >
                    Asignar figuras
                  </v-btn>
                </v-col>
              </v-col>
            </b-modal>
          </v-row>
        </v-col>
      </l-control>

      <l-control position="bottomleft">
        <v-row>
          <v-col class="col_iconMap">
            <a @click="recenterMap">
              <i
                class="fa-solid fa-location-crosshairs fa-2x"
                v-b-popover.hover=""
                title="Centrar Posición"
                style="display: block; color: black; cursor: pointer"
              ></i>
            </a>
          </v-col>
          <v-col class="col_iconMap">
            <v-menu v-if="permitEdit" top :offset-x="true">
              <template v-slot:activator="{ on, attrs }">
                <b-button
                  v-bind="attrs"
                  v-on="on"
                  style="
                    background-color: transparent;
                    border-color: transparent;
                  "
                >
                  <i
                    class="fa fa-eye fa-lg"
                    style="display: block; color: black; cursor: pointer"
                  ></i>
                </b-button>
              </template>

              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <b-form-checkbox-group
                    v-model="selectedAreas"
                    :options="optionsAreas"
                    plain
                    stacked
                  ></b-form-checkbox-group>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                    "
                  >
                    <div
                      v-for="(color_P, index) in color_Poly"
                      :key="index"
                      :style="color_P"
                    ></div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </v-menu>
          </v-col>
        </v-row>
      </l-control>

      <l-control position="bottomright">
        <v-col class="col_iconMap2">
          <v-row>
            <a @click="savePosition()">
              <i
                class="fa fa-dot-circle-o fa-2x"
                v-b-popover.hover=""
                title="Ajustar zoom y centrado"
                style="color: black; cursor: pointer"
              ></i>
            </a>
          </v-row>
          <v-row class="row_iconMap">
            <a v-b-modal.modal-canvasD>
              <i
                class="fa-brands fa-buffer fa-2x"
                v-b-popover.hover=""
                title="Datos vista"
                @click="listFileC()"
                style="color: black; cursor: pointer"
              ></i>
            </a>

            <b-modal
              id="modal-canvasD"
              size="sm"
              hide-backdrop
              centered
              content-class="shadow"
              :title="`Informacion de la vista ${nameCanvas}`"
            >
              <b-dropdown
                style="display: grid"
                block
                id="dropdown-dropright"
                dropright
                text="Ver archivos"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-item
                  v-b-modal.modal-tagsList
                  v-for="(tagsLC, inde) in tagsListC"
                  :key="inde"
                  @click="ordenarForTagC(tagsLC)"
                >
                  {{ tagsLC }}

                  <b-modal
                    id="modal-tagsList"
                    size="lg"
                    hide-footer
                    centered
                    hide-backdrop
                    scrollable
                    content-class="shadow"
                    :title="'Ordenado por ' + tagsLC"
                  >
                    <b-list-group>
                      <b-list-group-item
                        v-for="(fileC, inde1) in filesC"
                        :key="inde1"
                      >
                        <v-row>
                          <v-col sm="9" style="text-align: initial">
                            <v-row>
                              <v-col>
                                {{ fileC.text }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col sm="2">
                                <h6>Ubicacion:</h6>
                              </v-col>
                              <v-col sm="10">
                                {{ fileC.ubicacion }}
                              </v-col>
                            </v-row>
                            <p style="font-style: oblique">
                              {{ fileC.created_at }}
                            </p>
                            <p style="font-style: oblique">{{ fileC.tag }}</p>
                          </v-col>
                          <v-col sm="1" style="text-align: center">
                            <br />
                            <i
                              style="cursor: pointer"
                              class="fa fa-cloud-download fa-lg"
                              @click="downloadFile(fileC.path, fileC.text)"
                              aria-hidden="true"
                            ></i>
                          </v-col>

                          <v-col sm="1" style="text-align: center">
                            <br />
                            <a
                              v-if="
                                fileC.relacion.Hijos.length > 0 ||
                                fileC.relacion.Padres.length > 0
                              "
                              style="cursor: pointer"
                              :id="
                                'popover-button-variant' + inde + '-' + inde1
                              "
                              tabindex="0"
                              @click="selectPopC(inde, inde1)"
                            >
                              <v-badge
                                bordered
                                color="#ff0000"
                                icon="mdi-alert-circle"
                                overlap
                              >
                                <v-icon color="#000000">
                                  mdi-link-variant-plus
                                </v-icon>
                              </v-badge>
                            </a>

                            <a
                              v-if="
                                fileC.relacion.Hijos.length == 0 &&
                                fileC.relacion.Padres.length == 0
                              "
                              style="cursor: pointer"
                              :id="
                                'popover-button-variant' + inde + '-' + inde1
                              "
                              tabindex="0"
                              @click="selectPopC(inde, inde1)"
                            >
                              <v-icon color="#000000">
                                mdi-link-variant-plus
                              </v-icon>
                            </a>

                            <b-popover
                              :target="
                                'popover-button-variant' + inde + '-' + inde1
                              "
                              :id="
                                'popover-button-variant' + inde + '-' + inde1
                              "
                              variant="ligth"
                              triggers="focus"
                            >
                              <b-button-group vertical>
                                <b-button
                                  v-b-modal.modal-multi-list
                                  v-b-tooltip.hover.left
                                  title="Relación consecuente"
                                  @click="selectListR(inde1)"
                                  variant="light"
                                >
                                  <b-icon
                                    icon="box-arrow-in-down"
                                    aria-hidden="true"
                                  ></b-icon>
                                  <b-badge
                                    pill
                                    style="background-color: #19a2fa"
                                    >{{ fileC.relacion.Padres.length }}</b-badge
                                  >
                                </b-button>
                                <b-button
                                  v-b-modal.modal-multi-listH
                                  v-b-tooltip.hover.left
                                  title="Relación precedente"
                                  @click="selectListRH(inde1)"
                                  variant="light"
                                >
                                  <b-icon
                                    icon="diagram3"
                                    aria-hidden="true"
                                  ></b-icon>
                                  <b-badge
                                    pill
                                    style="background-color: #19a2fa"
                                    >{{ fileC.relacion.Hijos.length }}</b-badge
                                  >
                                </b-button>
                                <b-button
                                  v-b-modal.modal-multi-listC
                                  v-b-tooltip.hover.left
                                  title="Crear relacion"
                                  @click="
                                    listTagandFileR(fileC);
                                    selectListRC(inde, inde1);
                                  "
                                  variant="light"
                                >
                                  <i class="fa fa-plus" aria-hidden="true"></i
                                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </b-button>
                              </b-button-group>
                            </b-popover>

                            <b-modal
                              v-if="fileC.relacion.Padres.length > 0"
                              :id="'modal-multi-list-' + inde1"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              :title="'Relacion anterior de ' + fileC.text"
                            >
                              <b-list-group>
                                <b-list-group-item
                                  v-for="(listR, inde11) in fileC.relacion
                                    .Padres"
                                  :key="inde11"
                                >
                                  <v-row>
                                    <v-col sm="9" style="text-align: initial">
                                      <v-row>
                                        <v-col>
                                          {{ listR.title }}
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col sm="2">
                                          <h6>Ubicación:</h6>
                                        </v-col>
                                        <v-col sm="10">
                                          {{ listR.ubicacion }}
                                        </v-col>
                                      </v-row>
                                      <p style="font-style: oblique">
                                        {{ listR.created_at }}
                                      </p>
                                      <p style="font-style: oblique">
                                        {{ listR.tag }} - por:
                                        {{ listR.name_user }}
                                      </p>
                                    </v-col>

                                    <v-col sm="1" style="text-align: center">
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-cloud-download fa-lg"
                                        @click="
                                          downloadFile(listR.path, listR.title)
                                        "
                                        aria-hidden="true"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="
                                        listR.path.split('.')[1] == 'png' ||
                                        listR.path.split('.')[1] == 'jpg'
                                      "
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="viewFile(listR.path)"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="listR.path.split('.')[1] == 'pdf'"
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        v-b-modal.modal-multi
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="
                                          viewFile(listR.path);
                                          selectPPDF(inde, inde1, inde11);
                                        "
                                      ></i>

                                      <b-modal
                                        :id="
                                          'modal-multi-' +
                                          inde +
                                          '-' +
                                          inde1 +
                                          '-' +
                                          inde11
                                        "
                                        size="lg"
                                        centered
                                        scrollable
                                        hide-footer
                                        hide-backdrop
                                        content-class="shadow"
                                        :title="
                                          'Visualización de ' + listR.title
                                        "
                                      >
                                        <v-row>
                                          <v-col>
                                            {{ currentPage }} / {{ pageCount }}
                                          </v-col>
                                        </v-row>
                                        <pdf
                                          v-for="i in numPages"
                                          :key="i"
                                          :src="fileView"
                                          :page="i"
                                          style="
                                            display: inline-block;
                                            width: 50%;
                                          "
                                          @num-pages="pageCount = $event"
                                          @page-loaded="currentPage = $event"
                                        ></pdf>
                                      </b-modal>
                                    </v-col>
                                  </v-row>
                                </b-list-group-item>
                              </b-list-group>
                            </b-modal>

                            <b-modal
                              v-if="fileC.relacion.Hijos.length > 0"
                              :id="'modal-multi-listH-' + inde1"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              :title="'Relacion posterior de ' + fileC.text"
                            >
                              <b-list-group>
                                <b-list-group-item
                                  v-for="(listRH, inde11) in fileC.relacion
                                    .Hijos"
                                  :key="listRH.id"
                                >
                                  <v-row>
                                    <v-col sm="9" style="text-align: initial">
                                      <v-row>
                                        <v-col>
                                          {{ listRH.title }}
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col sm="2">
                                          <h6>Ubicacion:</h6>
                                        </v-col>
                                        <v-col sm="10">
                                          {{ listRH.ubicacion }}
                                        </v-col>
                                      </v-row>
                                      <p style="font-style: oblique">
                                        {{ listRH.created_at }}
                                      </p>
                                      <p style="font-style: oblique">
                                        {{ listRH.tag }} - por:
                                        {{ listRH.name_user }}
                                      </p>
                                    </v-col>

                                    <v-col sm="1" style="text-align: center">
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-cloud-download fa-lg"
                                        @click="
                                          downloadFile(
                                            listRH.path,
                                            listRH.title
                                          )
                                        "
                                        aria-hidden="true"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="
                                        listRH.path.split('.')[1] == 'png' ||
                                        listRH.path.split('.')[1] == 'jpg'
                                      "
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="viewFile(listRH.path)"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="listRH.path.split('.')[1] == 'pdf'"
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        v-b-modal.modal-multi
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="
                                          viewFile(listRH.path);
                                          selectPPDF(inde, inde1, inde11);
                                        "
                                      ></i>

                                      <b-modal
                                        :id="
                                          'modal-multi-' +
                                          inde +
                                          '-' +
                                          inde1 +
                                          '-' +
                                          inde11
                                        "
                                        size="lg"
                                        centered
                                        scrollable
                                        hide-footer
                                        hide-backdrop
                                        content-class="shadow"
                                        :title="
                                          'Visualización de ' + listRH.title
                                        "
                                      >
                                        <v-row>
                                          <v-col>
                                            {{ currentPage }} / {{ pageCount }}
                                          </v-col>
                                        </v-row>
                                        <pdf
                                          v-for="i in numPages"
                                          :key="i"
                                          :src="fileView"
                                          :page="i"
                                          style="
                                            display: inline-block;
                                            width: 50%;
                                          "
                                          @num-pages="pageCount = $event"
                                          @page-loaded="currentPage = $event"
                                        ></pdf>
                                      </b-modal>
                                    </v-col>
                                  </v-row>
                                </b-list-group-item>
                              </b-list-group>
                            </b-modal>

                            <b-modal
                              :id="'modal-multi-listC-' + inde + '-' + inde1"
                              size="lg"
                              centered
                              hide-footer
                              :title="'Crear relacion para ' + fileC.text"
                            >
                              <v-row>
                                <v-col sm="9">
                                  <v-combobox
                                    v-model="selectdateFileR"
                                    :items="dateFileR"
                                    label="Ingrese el nombre del archivo"
                                    dense
                                    persistent-hint
                                  ></v-combobox>
                                </v-col>
                                <v-col
                                  sm="3"
                                  style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                >
                                  <b-button
                                    variant="outline-info"
                                    @click="createRel(fileC.idFile)"
                                  >
                                    Generar relacion
                                  </b-button>
                                </v-col>
                              </v-row>
                            </b-modal>
                          </v-col>

                          <v-col
                            v-if="
                              fileC.path.split('.')[1] == 'png' ||
                              fileC.path.split('.')[1] == 'jpg'
                            "
                            sm="1"
                            style="text-align: center"
                          >
                            <br />
                            <i
                              style="cursor: pointer"
                              class="fa fa-eye fa-lg"
                              aria-hidden="true"
                              @click="viewFile(fileC.path)"
                            ></i>
                          </v-col>

                          <v-col
                            v-if="fileC.path.split('.')[1] == 'pdf'"
                            sm="1"
                            style="text-align: center"
                          >
                            <br />
                            <i
                              style="cursor: pointer"
                              v-b-modal.modal-multi
                              class="fa fa-eye fa-lg"
                              aria-hidden="true"
                              @click="
                                viewFile(fileC.path);
                                selectPPDF(inde, inde1);
                              "
                            ></i>

                            <b-modal
                              :id="'modal-multi-' + inde + '-' + inde1"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              hide-backdrop
                              content-class="shadow"
                              :title="'Visualización de ' + fileC.text"
                            >
                              <v-row>
                                <v-col>
                                  {{ currentPage }} / {{ pageCount }}
                                </v-col>
                              </v-row>
                              <pdf
                                v-for="i in numPages"
                                :key="i"
                                :src="fileView"
                                :page="i"
                                style="display: inline-block; width: 50%"
                                @num-pages="pageCount = $event"
                                @page-loaded="currentPage = $event"
                              ></pdf>
                            </b-modal>
                          </v-col>

                          <v-col
                            v-if="
                              fileC.path.split('.')[1] == 'xlsx' ||
                              fileC.path.split('.')[1] == 'pptx' ||
                              fileC.path.split('.')[1] == 'docx'
                            "
                            sm="1"
                            style="text-align: center"
                          >
                            <br />
                            <i
                              style="cursor: pointer"
                              v-b-modal.modal-multi
                              class="fa fa-eye fa-lg"
                              aria-hidden="true"
                              @click="
                                viewFile(fileC.path), selectPPDF(inde, inde1)
                              "
                            ></i>

                            <b-modal
                              :id="'modal-multi-' + inde + '-' + inde1"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              hide-backdrop
                              content-class="shadow"
                              :title="'Visualización de ' + fileC.text"
                            >
                              <VueDocPreview
                                v-if="fileView != null"
                                :value="fileView"
                                type="office"
                              />
                            </b-modal>
                          </v-col>
                        </v-row>
                      </b-list-group-item>
                    </b-list-group>
                  </b-modal>
                </b-dropdown-item>
              </b-dropdown>

              <div style="margin: 0.5rem !important">
                <b-button
                  v-if="permissionCUser"
                  variant="light"
                  class="btn_drop"
                  v-b-modal.modal-uploadC
                >
                  Cargar archivo
                </b-button>
              </div>

              <b-dropdown
                style="display: grid"
                block
                id="dropdown-dropright"
                dropright
                text="Links"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-form>
                  <b-dropdown-item-button v-b-modal.modal-linkC>
                    Asociar link
                  </b-dropdown-item-button>
                  <b-modal
                    size="lg"
                    hide-footer
                    id="modal-linkC"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="'Asociar link en la vista ' + nameCanvas"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    "
                  >
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model="refLink"
                          label="Nombre o referencia del link"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="linkAsoc"
                          label="Link"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          v-model="selectedTypeDocument"
                          :items="typeDocument"
                          label="Seleccione tipo de documento"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-btn
                      class="ma-2"
                      color="#57B55D"
                      style="color: white;"
                      @click="setLink('N/A', 'canva'); $bvModal.hide('modal-linkC');"
                    >
                      Asociar
                    </v-btn>
                  </b-modal>

                  <b-dropdown-item-button @click="getDataLinks('N/A', 'canva')" v-b-modal.modal-listlinkC>
                    Listar links
                  </b-dropdown-item-button>
                  <b-modal
                    size="lg"
                    hide-footer
                    id="modal-listlinkC"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="'Lista de links asociada a la vista ' + nameCanvas"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    "
                  >
                    <div v-if="listLink.length > 0" style="overflow-y: auto!important;
                      height: 320px;">
                      <v-list-item v-for="LinkP in listLink" :key="LinkP.id">
                        <v-list-item-content>
                          <v-list-item-title>Descripción: {{LinkP.titulo}}</v-list-item-title>
                          <v-list-item-subtitle>Tipo de documento: {{LinkP.tag}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-row>
                              <v-col cols="8" style="display: flex;
                                justify-content: flex-end;">
                                <v-btn
                                  class="ma-2"
                                  color="#57B55D"
                                  :href="LinkP.link" 
                                  target="popup"
                                  style="text-transform: none!important; color: white;"
                                  small
                                  @click="popupWindows(LinkP.link)"
                                >
                                  Clic para visualizar
                                </v-btn>
                              </v-col>
                              <v-col cols="4" >
                                <v-btn
                                  class="ma-2"
                                  color="#F3575D"
                                  small
                                  @click="deleteLink(LinkP.id);"
                                >
                                  <i class="fa-solid fa-trash" style="color: white;"></i>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </b-modal>

                </b-dropdown-form>
              </b-dropdown>

              <b-dropdown
                style="display: grid"
                block
                id="dropdown-dropright"
                dropright
                text="Areas"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-item
                  @click="
                    listZonaF(line.name);
                    selectCtoArea(index);
                  "
                  v-for="(line, index) in polygonsR"
                  :key="line.id"
                >
                  {{ line.name }}

                  <b-modal
                    :id="'modal-canvasD-Area-' + index"
                    size="sm"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="`Información del área ${line.name}`"
                  >
                    <b-dropdown
                      style="display: grid; text-align: -webkit-match-parent"
                      block
                      id="dropdown-dropright"
                      dropright
                      text="Ver archivos"
                      variant="light"
                      class="m-2 drop_L"
                    >
                      <b-dropdown-form
                        v-for="(tagsLG, index1) in tagsListG"
                        :key="tagsLG.id"
                      >
                        <b-dropdown-item-button
                          v-b-modal.modal-no-backdrop-2
                          @click="
                            ordenarForTagG(tagsLG);
                            selectGC(index, index1);
                          "
                        >
                          {{ tagsLG }}
                        </b-dropdown-item-button>

                        <b-modal
                          :id="'modal-tag-AreaC-' + index + '-' + index1"
                          size="lg"
                          hide-footer
                          centered
                          hide-backdrop
                          scrollable
                          content-class="shadow"
                          :title="'Ordenado por ' + tagsLG"
                        >
                          <b-list-group>
                            <b-list-group-item
                              v-for="(fileG, index11) in filesG"
                              :key="fileG.id"
                            >
                              <v-row>
                                <v-col sm="9" style="text-align: initial">
                                  <v-row>
                                    <v-col>
                                      {{ fileG.text }}
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col sm="2">
                                      <h6>Ubicacion:</h6>
                                    </v-col>
                                    <v-col sm="10">
                                      {{ fileG.ubicacion }}
                                    </v-col>
                                  </v-row>
                                  <p style="font-style: oblique">
                                    {{ fileG.created_at }}
                                  </p>
                                  <p style="font-style: oblique">
                                    {{ fileG.tag }}
                                  </p>
                                </v-col>
                                <v-col sm="1" style="text-align: center">
                                  <br />
                                  <i
                                    style="cursor: pointer"
                                    class="fa fa-cloud-download fa-lg"
                                    @click="
                                      downloadFile(fileG.path, fileG.text)
                                    "
                                    aria-hidden="true"
                                  ></i>
                                </v-col>

                                <v-col sm="1" style="text-align: center">
                                  <br />
                                  <a
                                    v-if="
                                      fileG.relacion.Hijos.length > 0 ||
                                      fileG.relacion.Padres.length > 0
                                    "
                                    style="cursor: pointer"
                                    :id="
                                      'popover-button-variant' +
                                      index +
                                      '-' +
                                      index1 +
                                      '-' +
                                      index11
                                    "
                                    tabindex="0"
                                    @click="selectPop(index, index1, index11)"
                                  >
                                    <v-badge
                                      bordered
                                      color="#ff0000"
                                      icon="mdi-alert-circle"
                                      overlap
                                    >
                                      <v-icon color="#000000">
                                        mdi-link-variant-plus
                                      </v-icon>
                                    </v-badge>
                                  </a>

                                  <a
                                    v-if="
                                      fileG.relacion.Hijos.length == 0 &&
                                      fileG.relacion.Padres.length == 0
                                    "
                                    style="cursor: pointer"
                                    :id="
                                      'popover-button-variant' +
                                      index +
                                      '-' +
                                      index1 +
                                      '-' +
                                      index11
                                    "
                                    tabindex="0"
                                    @click="selectPop(index, index1, index11)"
                                  >
                                    <v-icon color="#000000">
                                      mdi-link-variant-plus
                                    </v-icon>
                                  </a>

                                  <b-popover
                                    :target="
                                      'popover-button-variant' +
                                      index +
                                      '-' +
                                      index1 +
                                      '-' +
                                      index11
                                    "
                                    :id="
                                      'popover-button-variant' +
                                      index +
                                      '-' +
                                      index1 +
                                      '-' +
                                      index11
                                    "
                                    variant="ligth"
                                    triggers="focus"
                                  >
                                    <b-button-group vertical>
                                      <b-button
                                        v-b-modal.modal-multi-list
                                        v-b-tooltip.hover.left
                                        title="Relacion consecuente"
                                        @click="selectListR(index11)"
                                        variant="light"
                                      >
                                        <b-icon
                                          icon="box-arrow-in-down"
                                          aria-hidden="true"
                                        ></b-icon>
                                        <b-badge
                                          pill
                                          style="background-color: #19a2fa"
                                          >{{
                                            fileG.relacion.Padres.length
                                          }}</b-badge
                                        >
                                      </b-button>
                                      <b-button
                                        v-b-modal.modal-multi-listH
                                        v-b-tooltip.hover.left
                                        title="Relacion precedente"
                                        @click="selectListRH(index11)"
                                        variant="light"
                                      >
                                        <b-icon
                                          icon="diagram3"
                                          aria-hidden="true"
                                        ></b-icon>
                                        <b-badge
                                          pill
                                          style="background-color: #19a2fa"
                                          >{{
                                            fileG.relacion.Hijos.length
                                          }}</b-badge
                                        >
                                      </b-button>
                                      <b-button
                                        v-b-modal.modal-multi-listC
                                        v-b-tooltip.hover.left
                                        title="Crear relacion"
                                        @click="
                                          listTagandFileR(fileG);
                                          selectListRCA(index, index11);
                                        "
                                        variant="light"
                                      >
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i
                                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </b-button>
                                    </b-button-group>
                                  </b-popover>

                                  <b-modal
                                    v-if="fileG.relacion.Padres.length > 0"
                                    :id="'modal-multi-list-' + index11"
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    :title="
                                      'Relacion anterior de ' + fileG.text
                                    "
                                  >
                                    <b-list-group>
                                      <b-list-group-item
                                        v-for="(listR, index11) in fileG
                                          .relacion.Padres"
                                        :key="index11"
                                      >
                                        <v-row>
                                          <v-col
                                            sm="9"
                                            style="text-align: initial"
                                          >
                                            <v-row>
                                              <v-col>
                                                {{ listR.text }}
                                              </v-col>
                                            </v-row>
                                            <v-row>
                                              <v-col sm="2">
                                                <h6>Ubicacion:</h6>
                                              </v-col>
                                              <v-col sm="10">
                                                {{ listR.ubicacion }}
                                              </v-col>
                                            </v-row>
                                            <p style="font-style: oblique">
                                              {{ listR.created_at }}
                                            </p>
                                            <p style="font-style: oblique">
                                              {{ listR.tag }} - por:
                                              {{ listR.name_user }}
                                            </p>
                                          </v-col>

                                          <v-col
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              class="fa fa-cloud-download fa-lg"
                                              @click="
                                                downloadFile(
                                                  listR.path,
                                                  listR.text
                                                )
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </v-col>

                                          <v-col
                                            v-if="
                                              listR.path.split('.')[1] ==
                                                'png' ||
                                              listR.path.split('.')[1] == 'jpg'
                                            "
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              class="fa fa-eye fa-lg"
                                              aria-hidden="true"
                                              @click="viewFile(listR.path)"
                                            ></i>
                                          </v-col>

                                          <v-col
                                            v-if="
                                              listR.path.split('.')[1] == 'pdf'
                                            "
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              v-b-modal.modal-multi
                                              class="fa fa-eye fa-lg"
                                              aria-hidden="true"
                                              @click="
                                                viewFile(listR.path);
                                                selectPPDF(
                                                  index,
                                                  index1,
                                                  index11
                                                );
                                              "
                                            ></i>

                                            <b-modal
                                              :id="
                                                'modal-multi-' +
                                                index +
                                                '-' +
                                                index1 +
                                                '-' +
                                                index11
                                              "
                                              size="lg"
                                              centered
                                              scrollable
                                              hide-footer
                                              hide-backdrop
                                              content-class="shadow"
                                              :title="
                                                'Visualización de ' + listR.text
                                              "
                                            >
                                              <v-row>
                                                <v-col>
                                                  {{ currentPage }} /
                                                  {{ pageCount }}
                                                </v-col>
                                              </v-row>
                                              <pdf
                                                v-for="i in numPages"
                                                :key="i"
                                                :src="fileView"
                                                :page="i"
                                                style="
                                                  display: inline-block;
                                                  width: 50%;
                                                "
                                                @num-pages="pageCount = $event"
                                                @page-loaded="
                                                  currentPage = $event
                                                "
                                              >
                                              </pdf>
                                            </b-modal>
                                          </v-col>
                                        </v-row>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-modal>

                                  <b-modal
                                    v-if="fileG.relacion.Hijos.length > 0"
                                    :id="'modal-multi-listH-' + index11"
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    :title="
                                      'Relación posterior de ' + fileG.text
                                    "
                                  >
                                    <b-list-group>
                                      <b-list-group-item
                                        v-for="(listRH, index11) in fileG
                                          .relacion.Hijos"
                                        :key="listRH.id"
                                      >
                                        <v-row>
                                          <v-col
                                            sm="9"
                                            style="text-align: initial"
                                          >
                                            <v-row>
                                              <v-col>
                                                {{ listRH.text }}
                                              </v-col>
                                            </v-row>
                                            <v-row>
                                              <v-col sm="2">
                                                <h6>Ubicacion:</h6>
                                              </v-col>
                                              <v-col sm="10">
                                                {{ listRH.ubicacion }}
                                              </v-col>
                                            </v-row>
                                            <p style="font-style: oblique">
                                              {{ listRH.created_at }}
                                            </p>
                                            <p style="font-style: oblique">
                                              {{ listRH.tag }} - por:
                                              {{ listRH.name_user }}
                                            </p>
                                          </v-col>

                                          <v-col
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              class="fa fa-cloud-download fa-lg"
                                              @click="
                                                downloadFile(
                                                  listRH.path,
                                                  listRH.text
                                                )
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </v-col>

                                          <v-col
                                            v-if="
                                              listRH.path.split('.')[1] ==
                                                'png' ||
                                              listRH.path.split('.')[1] == 'jpg'
                                            "
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              class="fa fa-eye fa-lg"
                                              aria-hidden="true"
                                              @click="viewFile(listRH.path)"
                                            ></i>
                                          </v-col>

                                          <v-col
                                            v-if="
                                              listRH.path.split('.')[1] == 'pdf'
                                            "
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              v-b-modal.modal-multi
                                              class="fa fa-eye fa-lg"
                                              aria-hidden="true"
                                              @click="
                                                viewFile(listRH.path);
                                                selectPPDF(
                                                  index,
                                                  index1,
                                                  index11
                                                );
                                              "
                                            ></i>

                                            <b-modal
                                              :id="
                                                'modal-multi-' +
                                                index +
                                                '-' +
                                                index1 +
                                                '-' +
                                                index11
                                              "
                                              size="lg"
                                              centered
                                              scrollable
                                              hide-footer
                                              hide-backdrop
                                              content-class="shadow"
                                              :title="
                                                'Visualización de ' +
                                                listRH.text
                                              "
                                            >
                                              <v-row>
                                                <v-col>
                                                  {{ currentPage }} /
                                                  {{ pageCount }}
                                                </v-col>
                                              </v-row>
                                              <pdf
                                                v-for="i in numPages"
                                                :key="i"
                                                :src="fileView"
                                                :page="i"
                                                style="
                                                  display: inline-block;
                                                  width: 50%;
                                                "
                                                @num-pages="pageCount = $event"
                                                @page-loaded="
                                                  currentPage = $event
                                                "
                                              >
                                              </pdf>
                                            </b-modal>
                                          </v-col>
                                        </v-row>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-modal>

                                  <b-modal
                                    :id="
                                      'modal-multi-listCA-' +
                                      index +
                                      '-' +
                                      index11
                                    "
                                    size="lg"
                                    centered
                                    hide-footer
                                    :title="'Crear relación para ' + fileG.text"
                                  >
                                    <v-row>
                                      <v-col sm="9">
                                        <v-combobox
                                          v-model="selectdateFileR"
                                          :items="dateFileR"
                                          label="Ingrese el nombre del archivo"
                                          dense
                                          persistent-hint
                                        ></v-combobox>
                                      </v-col>
                                      <v-col
                                        sm="3"
                                        style="
                                          display: flex;
                                          align-items: center;
                                          justify-content: center;
                                        "
                                      >
                                        <b-button
                                          variant="outline-info"
                                          @click="createRel(fileG.idFile)"
                                        >
                                          Generar relación
                                        </b-button>
                                      </v-col>
                                    </v-row>
                                  </b-modal>
                                </v-col>

                                <v-col
                                  v-if="
                                    fileG.path.split('.')[1] == 'png' ||
                                    fileG.path.split('.')[1] == 'jpg'
                                  "
                                  sm="1"
                                  style="text-align: center"
                                >
                                  <br />
                                  <i
                                    style="cursor: pointer"
                                    class="fa fa-eye fa-lg"
                                    aria-hidden="true"
                                    @click="viewFile(fileG.path)"
                                  ></i>
                                </v-col>

                                <v-col
                                  v-if="fileG.path.split('.')[1] == 'pdf'"
                                  sm="1"
                                  style="text-align: center"
                                >
                                  <br />
                                  <i
                                    style="cursor: pointer"
                                    v-b-modal.modal-multi
                                    class="fa fa-eye fa-lg"
                                    aria-hidden="true"
                                    @click="
                                      viewFile(fileG.path);
                                      selectPPDF(index, index1, index11);
                                    "
                                  ></i>

                                  <b-modal
                                    :id="
                                      'modal-multi-' +
                                      index +
                                      '-' +
                                      index1 +
                                      '-' +
                                      index11
                                    "
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    hide-backdrop
                                    content-class="shadow"
                                    :title="'Visualización de ' + fileG.text"
                                  >
                                    <v-row>
                                      <v-col>
                                        {{ currentPage }} / {{ pageCount }}
                                      </v-col>
                                    </v-row>
                                    <pdf
                                      v-for="i in numPages"
                                      :key="i"
                                      :src="fileView"
                                      :page="i"
                                      style="display: inline-block; width: 50%"
                                      @num-pages="pageCount = $event"
                                      @page-loaded="currentPage = $event"
                                    ></pdf>
                                  </b-modal>
                                </v-col>

                                <v-col
                                  v-if="
                                    fileG.path.split('.')[1] == 'xlsx' ||
                                    fileG.path.split('.')[1] == 'pptx' ||
                                    fileG.path.split('.')[1] == 'docx'
                                  "
                                  sm="1"
                                  style="text-align: center"
                                >
                                  <br />
                                  <i
                                    style="cursor: pointer"
                                    v-b-modal.modal-multi
                                    class="fa fa-eye fa-lg"
                                    aria-hidden="true"
                                    @click="
                                      viewFile(fileG.path),
                                        selectPPDF(index, index1, index11)
                                    "
                                  ></i>

                                  <b-modal
                                    :id="
                                      'modal-multi-' +
                                      index +
                                      '-' +
                                      index1 +
                                      '-' +
                                      index11
                                    "
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    hide-backdrop
                                    content-class="shadow"
                                    :title="'Visualización de ' + fileG.text"
                                  >
                                    <VueDocPreview
                                      v-if="fileView != null"
                                      :value="fileView"
                                      type="office"
                                    />
                                  </b-modal>
                                </v-col>
                              </v-row>
                            </b-list-group-item>
                          </b-list-group>
                        </b-modal>
                      </b-dropdown-form>
                    </b-dropdown>

                    <div style="margin: 0.5rem !important">
                      <b-button
                        v-if="permitEdit || permissionCUser"
                        variant="light"
                        class="btn_drop"
                        @click="selectUploadZC(index)"
                      >
                        Cargar archivo
                      </b-button>

                      <b-modal
                        size="lg"
                        hide-footer
                        :id="'modal-uploadZoneC-' + index"
                        hide-backdrop
                        centered
                        content-class="shadow"
                        :title="'Subir archivo en la zona ' + line.name"
                      >
                        <v-row style="display: flex; justify-content: flex-end">
                          <v-col cols="3">
                            <v-checkbox
                              v-if="selectedTypeServer == 'GoogleDrive'"
                              v-model="codeActive"
                              label="Asociar por codigo"
                            ></v-checkbox>
                          </v-col>
                          <v-col
                            cols="4"
                            style="display: flex; align-items: center"
                          >
                            <v-select
                              v-model="selectedTypeServer"
                              :items="typeServer"
                              label="Seleccione servidor destino"
                              height="25"
                              dense
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-file-input
                              dense
                              height="25"
                              v-model="documentZ"
                              label="Seleccionar un archivo"
                            ></v-file-input>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              height="25"
                              dense
                              v-model="selectedTypeDocument"
                              :items="typeDocument"
                              label="Seleccione tipo de documento"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            v-if="
                              selectedTypeServer == 'GoogleDrive' && !codeActive
                            "
                            cols="12"
                          >
                            <v-row v-if="!structThreeF">
                              <v-col>
                                <v-chip class="ma-2" label>
                                  <p
                                    v-if="folderSelectN.length == 0"
                                    style="
                                      margin-top: 0.5rem;
                                      margin-bottom: 0.5rem;
                                    "
                                  >
                                    No se ha seleccionado carpeta
                                  </p>
                                  <p v-else>
                                    Carpeta seleccionada: {{ folderSelectN }}
                                  </p>
                                </v-chip>
                                <v-btn
                                  elevation="2"
                                  color="#009688"
                                  style="color: white"
                                  fab
                                  small
                                  @click="structThreeF = true"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>

                            <v-responsive
                              v-if="structThreeF"
                              style="overflow: scroll"
                              class="overflow-y-auto"
                              max-height="250"
                            >
                              <v-treeview
                                v-model="treeTest"
                                :open="initiallyOpen"
                                :items="folderGoogle"
                                activatable
                                item-key="text"
                                item-text="text"
                              >
                                <template v-slot:prepend="{ item, open }">
                                  <v-icon
                                    @click="
                                      selectFolderGoogle = item.id;
                                      structThreeF = false;
                                      folderSelectN = item.text;
                                    "
                                  >
                                    {{
                                      open ? "mdi-folder-open" : "mdi-folder"
                                    }}
                                  </v-icon>
                                </template>
                              </v-treeview>
                            </v-responsive>
                          </v-col>
                          <v-col v-if="codeActive" cols="6">
                            <v-autocomplete
                              height="25"
                              dense
                              v-model="selectCode"
                              :items="listCode"
                              label="Seleccione el codigo"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <br />
                        <v-row>
                          <v-col>
                            <b-button
                              style="color: white"
                              :disabled="
                                selectedTypeServer == 'GoogleDrive'
                                  ? selectFolderGoogle == null ||
                                    documentZ == null ||
                                    selectedTypeDocument == 0
                                  : documentZ == null ||
                                    selectedTypeDocument == 0
                              "
                              variant="primary"
                              type="submit"
                              @click="uploadDocumentsA(line.id)"
                              >Cargar archivo</b-button
                            >

                            <v-dialog
                              transition="dialog-top-transition"
                              max-width="600"
                              v-model="dialogUpCopyA2"
                            >
                              <v-card>
                                <v-card-text>
                                  <div class="text-h6 pa-12">
                                    El archivo que intenta cargar está
                                    duplicado.
                                  </div>
                                  <p>
                                    Ingrese un nombre diferente o dejar sin
                                    editar asignar un consecutivo
                                  </p>
                                  <v-text-field
                                    :label="nameFileRandom"
                                    hide-details="auto"
                                    v-model="mameFileUp"
                                    :rules="fileRules"
                                  ></v-text-field>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                    :disabled="!statusVerify"
                                    color="#57B55D"
                                    class="ma-2"
                                    style="color: white"
                                    @click="reeSendFile('Area', line.id)"
                                    >Guardar</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </b-modal>
                    </div>

                    <b-dropdown
                      style="display: grid"
                      block
                      id="dropdown-dropright"
                      dropright
                      text="Puntos"
                      variant="light"
                      class="m-2 drop_L"
                    >
                      <b-dropdown-item
                        @click="
                          listPointF(anillo.name);
                          selectCAtoPoint(indx, index);
                        "
                        v-for="(anillo, indx) in line.pointsD"
                        :key="anillo.id"
                        :id="indx"
                      >
                        {{ anillo.name }}

                        <b-modal
                          :id="`modal-AreaCD-Point-${indx}-${index}`"
                          size="sm"
                          hide-backdrop
                          centered
                          content-class="shadow"
                          :title="`Información del punto ${anillo.name}`"
                        >
                          <b-dropdown
                            style="
                              display: grid;
                              padding-top: 12px;
                              padding-bottom: 6px;
                            "
                            block
                            id="dropdown-dropright"
                            dropright
                            text="Ver archivos"
                            variant="light"
                            class="m-2 drop_L"
                          >
                            <b-dropdown-form
                              v-for="(tagsL, indx1) in tagsList"
                              :key="tagsL.id"
                            >
                              <b-dropdown-item-button
                                @click="
                                  ordenarForTag(tagsL);
                                  selectPA(indx, index, indx1);
                                "
                              >
                                {{ tagsL }}

                                <b-modal
                                  :id="`modal-no-backdropA-${indx}-${index}-${indx1}`"
                                  size="lg"
                                  centered
                                  hide-footer
                                  hide-backdrop
                                  scrollable
                                  content-class="shadow"
                                  :title="'Ordenado por ' + tagsL"
                                >
                                  <b-list-group>
                                    <b-list-group-item
                                      v-for="(fileP, indx11) in filesP"
                                      :key="fileP.idFile"
                                    >
                                      <v-row>
                                        <v-col
                                          sm="8"
                                          style="text-align: initial"
                                        >
                                          <v-row>
                                            <v-col>
                                              {{ fileP.text }}
                                            </v-col>
                                          </v-row>
                                          <v-row>
                                            <v-col sm="2">
                                              <h6>Ubicacion:</h6>
                                            </v-col>
                                            <v-col sm="10">
                                              {{ fileP.ubicacion }}
                                            </v-col>
                                          </v-row>
                                          <p style="font-style: oblique">
                                            {{ fileP.created_at }}
                                          </p>
                                        </v-col>

                                        <v-col
                                          sm="1"
                                          style="text-align: center"
                                        >
                                          <br />
                                          <i
                                            style="cursor: pointer"
                                            class="fa fa-cloud-download fa-lg"
                                            @click="
                                              downloadFile(
                                                fileP.path,
                                                fileP.text
                                              )
                                            "
                                            aria-hidden="true"
                                          ></i>
                                        </v-col>

                                        <v-col
                                          sm="1"
                                          style="text-align: center"
                                        >
                                          <br />
                                          <a
                                            v-if="
                                              fileP.relacion.original.Hijos
                                                .length > 0 ||
                                              fileP.relacion.original.Padres
                                                .length > 0
                                            "
                                            style="cursor: pointer"
                                            :id="
                                              'popover-relacion-item' +
                                              indx +
                                              '-' +
                                              indx1 +
                                              '-' +
                                              indx11
                                            "
                                            tabindex="0"
                                            @click="
                                              selectPopP(indx, indx1, indx11)
                                            "
                                          >
                                            <v-badge
                                              bordered
                                              color="#ff0000"
                                              icon="mdi-alert-circle"
                                              overlap
                                            >
                                              <v-icon color="#000000">
                                                mdi-link-variant-plus
                                              </v-icon>
                                            </v-badge>
                                          </a>

                                          <a
                                            v-if="
                                              fileP.relacion.original.Hijos
                                                .length == 0 &&
                                              fileP.relacion.original.Padres
                                                .length == 0
                                            "
                                            style="cursor: pointer"
                                            :id="
                                              'popover-relacion-item' +
                                              indx +
                                              '-' +
                                              indx1 +
                                              '-' +
                                              indx11
                                            "
                                            tabindex="0"
                                            @click="
                                              selectPopP(indx, indx1, indx11)
                                            "
                                          >
                                            <v-icon color="#000000">
                                              mdi-link-variant-plus
                                            </v-icon>
                                          </a>

                                          <b-popover
                                            :target="
                                              'popover-relacion-item' +
                                              indx +
                                              '-' +
                                              indx1 +
                                              '-' +
                                              indx11
                                            "
                                            :id="
                                              'popover-relacion-item' +
                                              indx +
                                              '-' +
                                              indx1 +
                                              '-' +
                                              indx11
                                            "
                                            variant="ligth"
                                            triggers="focus"
                                          >
                                            <b-button-group vertical>
                                              <b-button
                                                v-b-modal.modal-multi-list-p
                                                v-b-tooltip.hover.left
                                                title="Relacion consecuente"
                                                @click="selectListRP(indx11)"
                                                variant="light"
                                              >
                                                <b-icon
                                                  icon="box-arrow-in-down"
                                                  aria-hidden="true"
                                                ></b-icon>
                                                <b-badge
                                                  pill
                                                  style="
                                                    background-color: #19a2fa;
                                                  "
                                                  >{{
                                                    fileP.relacion.original
                                                      .Padres.length
                                                  }}</b-badge
                                                >
                                              </b-button>
                                              <b-button
                                                v-b-modal.modal-multi-listH-p
                                                v-b-tooltip.hover.left
                                                title="Relacion precedente"
                                                @click="selectListRHP(indx11)"
                                                variant="light"
                                              >
                                                <b-icon
                                                  icon="diagram3"
                                                  aria-hidden="true"
                                                ></b-icon>
                                                <b-badge
                                                  pill
                                                  style="
                                                    background-color: #19a2fa;
                                                  "
                                                  >{{
                                                    fileP.relacion.original
                                                      .Hijos.length
                                                  }}</b-badge
                                                >
                                              </b-button>
                                              <b-button
                                                v-b-modal.modal-multi-listC-p
                                                v-b-tooltip.hover.left
                                                title="Crear relacion"
                                                @click="
                                                  listTagandFileR(fileP);
                                                  selectListRCP(indx11);
                                                "
                                                variant="light"
                                              >
                                                <i
                                                  class="fa fa-plus"
                                                  aria-hidden="true"
                                                ></i
                                                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              </b-button>
                                            </b-button-group>
                                          </b-popover>

                                          <b-modal
                                            v-if="
                                              fileP.relacion.original.Padres
                                                .length > 0
                                            "
                                            :id="'modal-multi-list-p-' + indx11"
                                            size="lg"
                                            centered
                                            scrollable
                                            hide-footer
                                            :title="
                                              'Relacion anterior de ' +
                                              fileP.text
                                            "
                                          >
                                            <b-list-group>
                                              <b-list-group-item
                                                v-for="(listR, indx11) in fileP
                                                  .relacion.original.Padres"
                                                :key="listR.id"
                                              >
                                                <v-row>
                                                  <v-col
                                                    sm="9"
                                                    style="text-align: initial"
                                                  >
                                                    <v-row>
                                                      <v-col>
                                                        {{ listR.title }}
                                                      </v-col>
                                                    </v-row>
                                                    <v-row>
                                                      <v-col sm="2">
                                                        <h6>Ubicacion:</h6>
                                                      </v-col>
                                                      <v-col sm="10">
                                                        {{ listR.ubicacion }}
                                                      </v-col>
                                                    </v-row>
                                                    <p
                                                      style="
                                                        font-style: oblique;
                                                      "
                                                    >
                                                      {{ listR.created_at }}
                                                    </p>
                                                    <p
                                                      style="
                                                        font-style: oblique;
                                                      "
                                                    >
                                                      {{ listR.tag }} - por:
                                                      {{ listR.name_user }}
                                                    </p>
                                                  </v-col>

                                                  <v-col
                                                    sm="1"
                                                    style="text-align: center"
                                                  >
                                                    <br />
                                                    <i
                                                      style="cursor: pointer"
                                                      class="fa fa-cloud-download fa-lg"
                                                      @click="
                                                        downloadFile(
                                                          listR.path,
                                                          listR.title
                                                        )
                                                      "
                                                      aria-hidden="true"
                                                    ></i>
                                                  </v-col>

                                                  <v-col
                                                    v-if="
                                                      listR.path.split(
                                                        '.'
                                                      )[1] == 'png' ||
                                                      listR.path.split(
                                                        '.'
                                                      )[1] == 'jpg'
                                                    "
                                                    sm="1"
                                                    style="text-align: center"
                                                  >
                                                    <br />
                                                    <i
                                                      style="cursor: pointer"
                                                      class="fa fa-eye fa-lg"
                                                      aria-hidden="true"
                                                      @click="
                                                        viewFile(listR.path)
                                                      "
                                                    ></i>
                                                  </v-col>

                                                  <v-col
                                                    v-if="
                                                      listR.path.split(
                                                        '.'
                                                      )[1] == 'pdf'
                                                    "
                                                    sm="1"
                                                    style="text-align: center"
                                                  >
                                                    <br />
                                                    <i
                                                      style="cursor: pointer"
                                                      v-b-modal.modal-multi
                                                      class="fa fa-eye fa-lg"
                                                      aria-hidden="true"
                                                      @click="
                                                        viewFile(listR.path);
                                                        selectPPDF(
                                                          indx,
                                                          indx1,
                                                          indx11
                                                        );
                                                      "
                                                    ></i>

                                                    <b-modal
                                                      :id="
                                                        'modal-multi-' +
                                                        indx +
                                                        '-' +
                                                        indx1 +
                                                        '-' +
                                                        indx11
                                                      "
                                                      size="lg"
                                                      centered
                                                      scrollable
                                                      hide-footer
                                                      hide-backdrop
                                                      content-class="shadow"
                                                      :title="
                                                        'Visualización de ' +
                                                        listR.text
                                                      "
                                                    >
                                                      <v-row>
                                                        <v-col>
                                                          {{ currentPage }} /
                                                          {{ pageCount }}
                                                        </v-col>
                                                      </v-row>
                                                      <pdf
                                                        v-for="i in numPages"
                                                        :key="i"
                                                        :src="fileView"
                                                        :page="i"
                                                        style="
                                                          display: inline-block;
                                                          width: 50%;
                                                        "
                                                        @num-pages="
                                                          pageCount = $event
                                                        "
                                                        @page-loaded="
                                                          currentPage = $event
                                                        "
                                                      ></pdf>
                                                    </b-modal>
                                                  </v-col>
                                                </v-row>
                                              </b-list-group-item>
                                            </b-list-group>
                                          </b-modal>

                                          <b-modal
                                            v-if="
                                              fileP.relacion.original.Hijos
                                                .length > 0
                                            "
                                            :id="
                                              'modal-multi-listH-p-' + indx11
                                            "
                                            size="lg"
                                            centered
                                            scrollable
                                            hide-footer
                                            :title="
                                              'Relacion posterior de ' +
                                              fileP.text
                                            "
                                          >
                                            <b-list-group>
                                              <b-list-group-item
                                                v-for="(listRH, indx11) in fileP
                                                  .relacion.original.Hijos"
                                                :key="listRH.id"
                                              >
                                                <v-row>
                                                  <v-col
                                                    sm="9"
                                                    style="text-align: initial"
                                                  >
                                                    <v-row>
                                                      <v-col>
                                                        {{ listRH.title }}
                                                      </v-col>
                                                    </v-row>
                                                    <v-row>
                                                      <v-col sm="2">
                                                        <h6>Ubicacion:</h6>
                                                      </v-col>
                                                      <v-col sm="10">
                                                        {{ listRH.ubicacion }}
                                                      </v-col>
                                                    </v-row>
                                                    <p
                                                      style="
                                                        font-style: oblique;
                                                      "
                                                    >
                                                      {{ listRH.created_at }}
                                                    </p>
                                                    <p
                                                      style="
                                                        font-style: oblique;
                                                      "
                                                    >
                                                      {{ listRH.tag }} - por:
                                                      {{ listRH.name_user }}
                                                    </p>
                                                  </v-col>

                                                  <v-col
                                                    sm="1"
                                                    style="text-align: center"
                                                  >
                                                    <br />
                                                    <i
                                                      style="cursor: pointer"
                                                      class="fa fa-cloud-download fa-lg"
                                                      @click="
                                                        downloadFile(
                                                          listRH.path,
                                                          listRH.title
                                                        )
                                                      "
                                                      aria-hidden="true"
                                                    ></i>
                                                  </v-col>

                                                  <v-col
                                                    v-if="
                                                      listRH.path.split(
                                                        '.'
                                                      )[1] == 'png' ||
                                                      listRH.path.split(
                                                        '.'
                                                      )[1] == 'jpg'
                                                    "
                                                    sm="1"
                                                    style="text-align: center"
                                                  >
                                                    <br />
                                                    <i
                                                      style="cursor: pointer"
                                                      class="fa fa-eye fa-lg"
                                                      aria-hidden="true"
                                                      @click="
                                                        viewFile(listRH.path)
                                                      "
                                                    ></i>
                                                  </v-col>

                                                  <v-col
                                                    v-if="
                                                      listRH.path.split(
                                                        '.'
                                                      )[1] == 'pdf'
                                                    "
                                                    sm="1"
                                                    style="text-align: center"
                                                  >
                                                    <br />
                                                    <i
                                                      style="cursor: pointer"
                                                      v-b-modal.modal-multi
                                                      class="fa fa-eye fa-lg"
                                                      aria-hidden="true"
                                                      @click="
                                                        viewFile(listRH.path);
                                                        selectPPDF(
                                                          indx,
                                                          indx1,
                                                          indx11
                                                        );
                                                      "
                                                    ></i>

                                                    <b-modal
                                                      :id="
                                                        'modal-multi-' +
                                                        indx +
                                                        '-' +
                                                        indx1 +
                                                        '-' +
                                                        indx11
                                                      "
                                                      size="lg"
                                                      centered
                                                      scrollable
                                                      hide-footer
                                                      hide-backdrop
                                                      content-class="shadow"
                                                      :title="
                                                        'Visualización de ' +
                                                        listRH.text
                                                      "
                                                    >
                                                      <v-row>
                                                        <v-col>
                                                          {{ currentPage }} /
                                                          {{ pageCount }}
                                                        </v-col>
                                                      </v-row>
                                                      <pdf
                                                        v-for="i in numPages"
                                                        :key="i"
                                                        :src="fileView"
                                                        :page="i"
                                                        style="
                                                          display: inline-block;
                                                          width: 50%;
                                                        "
                                                        @num-pages="
                                                          pageCount = $event
                                                        "
                                                        @page-loaded="
                                                          currentPage = $event
                                                        "
                                                      ></pdf>
                                                    </b-modal>
                                                  </v-col>
                                                </v-row>
                                              </b-list-group-item>
                                            </b-list-group>
                                          </b-modal>

                                          <b-modal
                                            :id="
                                              'modal-multi-listC-p-' + indx11
                                            "
                                            size="lg"
                                            centered
                                            hide-footer
                                            :title="
                                              'Crear relacion para ' +
                                              fileP.text
                                            "
                                          >
                                            <v-row>
                                              <v-col sm="9">
                                                <v-combobox
                                                  v-model="selectdateFileR"
                                                  :items="dateFileR"
                                                  label="Ingrese el nombre del archivo"
                                                  dense
                                                  persistent-hint
                                                ></v-combobox>
                                              </v-col>
                                              <v-col
                                                sm="3"
                                                style="
                                                  display: flex;
                                                  align-items: center;
                                                  justify-content: center;
                                                "
                                              >
                                                <b-button
                                                  variant="outline-info"
                                                  @click="
                                                    createRel(fileP.idFile)
                                                  "
                                                >
                                                  Generar relacion
                                                </b-button>
                                              </v-col>
                                            </v-row>
                                          </b-modal>
                                        </v-col>

                                        <v-col
                                          v-if="
                                            fileP.path.split('.')[1] == 'png' ||
                                            fileP.path.split('.')[1] == 'jpg'
                                          "
                                          sm="1"
                                          style="text-align: center"
                                        >
                                          <br />
                                          <i
                                            style="cursor: pointer"
                                            class="fa fa-eye fa-lg"
                                            aria-hidden="true"
                                            @click="viewFile(fileP.path)"
                                          ></i>
                                        </v-col>

                                        <v-col
                                          v-if="
                                            fileP.path.split('.')[1] == 'pdf'
                                          "
                                          sm="1"
                                          style="text-align: center"
                                        >
                                          <br />
                                          <i
                                            style="cursor: pointer"
                                            v-b-modal.modal-multi
                                            class="fa fa-eye fa-lg"
                                            aria-hidden="true"
                                            @click="
                                              viewFile(fileP.path),
                                                selectPPDF(indx, indx1, indx11)
                                            "
                                          ></i>

                                          <b-modal
                                            :id="
                                              'modal-multi-' +
                                              indx +
                                              '-' +
                                              indx1 +
                                              '-' +
                                              indx11
                                            "
                                            size="lg"
                                            centered
                                            scrollable
                                            hide-footer
                                            hide-backdrop
                                            content-class="shadow"
                                            :title="
                                              'Visualización de ' + fileP.text
                                            "
                                          >
                                            <v-row>
                                              <v-col>
                                                {{ currentPage }} /
                                                {{ pageCount }}
                                              </v-col>
                                            </v-row>
                                            <pdf
                                              v-for="i in numPages"
                                              :key="i"
                                              :src="fileView"
                                              :page="i"
                                              style="
                                                display: inline-block;
                                                width: 50%;
                                              "
                                              @num-pages="pageCount = $event"
                                              @page-loaded="
                                                currentPage = $event
                                              "
                                            ></pdf>
                                          </b-modal>
                                        </v-col>
                                      </v-row>
                                    </b-list-group-item>
                                  </b-list-group>
                                </b-modal>
                              </b-dropdown-item-button>
                            </b-dropdown-form>
                          </b-dropdown>

                          <div style="margin: 0.5rem !important">
                            <b-button
                              @click="selectCUpload(indx)"
                              v-if="permitEdit || permissionCUser"
                              variant="light"
                              class="btn_drop"
                              v-b-modal.modal-no-backdrop-3
                            >
                              Cargar archivo
                            </b-button>
                          </div>
                          <b-modal
                            hide-footer
                            size="lg"
                            :id="'modal-no-backdropCU-' + indx"
                            hide-backdrop
                            centered
                            content-class="shadow"
                            :title="'Subir archivo en el punto ' + anillo.name"
                          >
                            <v-row
                              style="display: flex; justify-content: flex-end"
                            >
                              <v-col cols="3">
                                <v-checkbox
                                  v-if="selectedTypeServer == 'GoogleDrive'"
                                  v-model="codeActive"
                                  label="Asociar por codigo"
                                ></v-checkbox>
                              </v-col>
                              <v-col
                                cols="4"
                                style="display: flex; align-items: center"
                              >
                                <v-select
                                  v-model="selectedTypeServer"
                                  :items="typeServer"
                                  label="Seleccione servidor destino"
                                  height="25"
                                  dense
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <v-file-input
                                  dense
                                  height="25"
                                  v-model="documentP"
                                  label="Seleccionar un archivo"
                                ></v-file-input>
                              </v-col>
                              <v-col cols="6">
                                <v-select
                                  height="25"
                                  dense
                                  v-model="selectedTypeDocument"
                                  :items="typeDocument"
                                  label="Seleccione tipo de documento"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col
                                v-if="
                                  selectedTypeServer == 'GoogleDrive' &&
                                  !codeActive
                                "
                                cols="12"
                              >
                                <v-row v-if="!structThreeF">
                                  <v-col>
                                    <v-chip class="ma-2" label>
                                      <p
                                        v-if="folderSelectN.length == 0"
                                        style="
                                          margin-top: 0.5rem;
                                          margin-bottom: 0.5rem;
                                        "
                                      >
                                        No se ha seleccionado carpeta
                                      </p>
                                      <p v-else>
                                        carpeta seleccionado:
                                        {{ folderSelectN }}
                                      </p>
                                    </v-chip>
                                    <v-btn
                                      elevation="2"
                                      color="#009688"
                                      style="color: white"
                                      fab
                                      small
                                      @click="structThreeF = true"
                                    >
                                      <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>

                                <v-responsive
                                  v-if="structThreeF"
                                  style="overflow: scroll"
                                  class="overflow-y-auto"
                                  max-height="250"
                                >
                                  <v-treeview
                                    v-model="treeTest"
                                    :open="initiallyOpen"
                                    :items="folderGoogle"
                                    activatable
                                    item-key="text"
                                    item-text="text"
                                  >
                                    <template v-slot:prepend="{ item, open }">
                                      <v-icon
                                        @click="
                                          selectFolderGoogle = item.id;
                                          structThreeF = false;
                                          folderSelectN = item.text;
                                        "
                                      >
                                        {{
                                          open
                                            ? "mdi-folder-open"
                                            : "mdi-folder"
                                        }}
                                      </v-icon>
                                    </template>
                                  </v-treeview>
                                </v-responsive>
                              </v-col>
                              <v-col v-if="codeActive" cols="6">
                                <v-autocomplete
                                  height="25"
                                  dense
                                  v-model="selectCode"
                                  :items="listCode"
                                  label="Seleccione el codigo"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                            <br />
                            <v-row>
                              <v-col>
                                <b-button
                                  style="color: white"
                                  :disabled="
                                    selectedTypeServer == 'GoogleDrive'
                                      ? selectFolderGoogle == null ||
                                        documentP == null ||
                                        selectedTypeDocument == 0
                                      : documentP == null ||
                                        selectedTypeDocument == 0
                                  "
                                  variant="primary"
                                  type="submit"
                                  @click="uploadDocumentsP(anillo.id, 'Punto')"
                                >
                                  Cargar archivo
                                </b-button>

                                <v-dialog
                                  transition="dialog-top-transition"
                                  max-width="600"
                                  v-model="dialogUpCopyP"
                                >
                                  <v-card>
                                    <v-card-text>
                                      <div class="text-h6 pa-12">
                                        El archivo que intenta cargar esta
                                        duplicado.
                                      </div>
                                      <p>
                                        Ingrese un nombre diferente o dejar sin
                                        editar asignar un consecutivo
                                      </p>
                                      <v-text-field
                                        :label="nameFileRandom"
                                        hide-details="auto"
                                        v-model="mameFileUp"
                                        :rules="fileRules"
                                      ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                      <v-btn
                                        :disabled="!statusVerify"
                                        color="#57B55D"
                                        class="ma-2"
                                        style="color: white"
                                        @click="reeSendFile('Punto', anillo.id)"
                                        >Guardar</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-col>
                            </v-row>
                          </b-modal>

                          

                          <div
                            style="
                              margin: 0.5rem !important;
                              padding-bottom: 12px;
                              padding-top: 6px;
                            "
                          >
                            <b-button
                              v-if="permitEdit"
                              @click="
                                $bvModal.show('bv-modal-deletePointC' + indx)
                              "
                              variant="light"
                              class="btn_drop"
                            >
                              Eliminar punto
                            </b-button>
                          </div>
                          <b-modal
                            centered
                            :id="'bv-modal-deletePointC' + indx"
                            hide-footer
                          >
                            <v-row>
                              <v-col>
                                <h6>Desea eliminar el punto?</h6>
                              </v-col>
                              <v-row>
                                <v-col>
                                  <b-form-group
                                    id="input-group-deleteFig"
                                    label="Confirme escribiendo la palabra 'Si':"
                                    label-for="input-deletePoint"
                                    description="Al eliminar el punto se eliminarán los archivos adjuntos en el punto."
                                  >
                                    <b-form-input
                                      id="input-deletePoint"
                                      v-model="confirDelete"
                                      placeholder=""
                                      required
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-button
                                    @click="
                                      deletePoint(anillo.id);
                                      $bvModal.hide(
                                        'bv-modal-deletePoint' + indx
                                      );
                                    "
                                    :disabled="confirDelete != 'Si'"
                                    variant="danger"
                                    >Eliminar</b-button
                                  >
                                </v-col>
                              </v-row>
                            </v-row>
                          </b-modal>

                          <template #modal-footer>
                            <v-row class="text_w">
                              <v-col
                                cols="12"
                                style="padding-right: 0px; padding-left: 0px"
                              >
                                <em style="font-size: 12px"
                                  ># de Archivos: {{ filesPoints.length }}</em
                                >
                              </v-col>
                            </v-row>
                          </template>
                        </b-modal>
                      </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown
                      v-if="permitEdit"
                      style="display: grid; text-align: -webkit-match-parent"
                      block
                      id="dropdown-dropright"
                      dropright
                      text="Opciones"
                      variant="light"
                      class="m-2 drop_L"
                    >
                      <b-dropdown-form>
                        <b-dropdown-item-button
                          v-if="permitEdit"
                          @click="$bvModal.show('bv-modal-deleteFigC' + index)"
                        >
                          Eliminar área
                        </b-dropdown-item-button>
                        <b-modal
                          centered
                          :id="'bv-modal-deleteFigC' + index"
                          hide-footer
                        >
                          <v-row>
                            <v-col>
                              <h6>Desea eliminar el area {{ line.text }} ?</h6>
                            </v-col>
                            <v-row>
                              <v-col>
                                <b-form-group
                                  id="input-group-deleteFig"
                                  label="Confirme escribiendo la palabra 'Si':"
                                  label-for="input-deleteFig"
                                  description="Al eliminar el area se eliminarán los archivos adjuntos al area."
                                >
                                  <b-form-input
                                    id="input-deleteFig"
                                    v-model="confirDelete"
                                    placeholder=""
                                    required
                                  ></b-form-input>
                                </b-form-group>
                                <b-button
                                  @click="
                                    deletePolygon(line.id);
                                    $bvModal.hide('bv-modal-deleteFig' + index);
                                  "
                                  :disabled="confirDelete != 'Si'"
                                  variant="danger"
                                  >Eliminar</b-button
                                >
                              </v-col>
                            </v-row>
                          </v-row>
                        </b-modal>

                        <b-dropdown-item-button
                          v-if="permitEdit"
                          @click="$bvModal.show('bv-modal-colorFigC' + index)"
                        >
                          Color área
                        </b-dropdown-item-button>
                        <b-modal
                          centered
                          :id="'bv-modal-colorFigC' + index"
                          hide-footer
                        >
                          <v-row>
                            <v-col>
                              <h6>Elija un color para {{ line.text }} ?</h6>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <b-form-group
                                id="input-group-color"
                                label="Dele clic al color para elegir:"
                                label-for="type-color"
                                description="Se definirá el color del área dependiendo del elegido."
                              >
                                <b-form-input
                                  id="type-color"
                                  @change="getColor($event)"
                                  type="color"
                                  required
                                ></b-form-input>
                              </b-form-group>
                              <b-button
                                :disabled="colorEleg.length < 0"
                                variant="success"
                                @click="saveColorC(line.id)"
                                >Guardar</b-button
                              >
                            </v-col>
                          </v-row>
                        </b-modal>
                      </b-dropdown-form>
                    </b-dropdown>

                    <template #modal-footer>
                      <v-row class="text_w">
                        <v-col
                          cols="6"
                          style="padding-right: 0px; padding-left: 0px"
                        >
                          <em style="font-size: 12px"
                            ># de puntos:{{ line.numberPoints }}</em
                          >
                        </v-col>
                        <v-col
                          cols="6"
                          style="padding-right: 0px; padding-left: 0px"
                        >
                          <em style="font-size: 12px"
                            ># de archivos: {{ filesZones.length }}</em
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </b-modal>
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown
                style="display: grid"
                block
                id="dropdown-dropright"
                dropright
                text="Puntos"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-item
                  @click="
                    listPointF(anillo.name);
                    selectCtoPoint(indx);
                  "
                  v-for="(anillo, indx) in anillos.data"
                  :key="anillo.id"
                >
                  {{ anillo.name }}

                  <b-modal
                    :id="'modal-canvasD-Point-' + indx"
                    size="sm"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="`Información del punto ${anillo.name}`"
                  >
                    <b-dropdown
                      style="
                        display: grid;
                        padding-top: 12px;
                        padding-bottom: 6px;
                      "
                      block
                      id="dropdown-dropright"
                      dropright
                      text="Ver archivos"
                      variant="light"
                      class="m-2 drop_L"
                    >
                      <b-dropdown-form
                        v-for="(tagsL, indx1) in tagsList"
                        :key="tagsL.id"
                      >
                        <b-dropdown-item-button
                          v-b-modal.modal-no-backdrop
                          @click="
                            ordenarForTag(tagsL);
                            selectCP(indx, indx1);
                          "
                        >
                          {{ tagsL }}
                        </b-dropdown-item-button>

                        <b-modal
                          :id="'modal-no-backdropC-' + indx + '-' + indx1"
                          size="lg"
                          centered
                          hide-footer
                          hide-backdrop
                          scrollable
                          content-class="shadow"
                          :title="'Ordenado por ' + tagsL"
                        >
                          <b-list-group>
                            <b-list-group-item
                              v-for="(fileP, indx11) in filesP"
                              :key="fileP.idFile"
                            >
                              <v-row>
                                <v-col sm="8" style="text-align: initial">
                                  <v-row>
                                    <v-col>
                                      {{ fileP.text }}
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col sm="2">
                                      <h6>Ubicacion:</h6>
                                    </v-col>
                                    <v-col sm="10">
                                      {{ fileP.ubicacion }}
                                    </v-col>
                                  </v-row>
                                  <p style="font-style: oblique">
                                    {{ fileP.created_at }}
                                  </p>
                                </v-col>

                                <v-col sm="1" style="text-align: center">
                                  <br />
                                  <i
                                    style="cursor: pointer"
                                    class="fa fa-cloud-download fa-lg"
                                    @click="
                                      downloadFile(fileP.path, fileP.text)
                                    "
                                    aria-hidden="true"
                                  ></i>
                                </v-col>

                                <v-col sm="1" style="text-align: center">
                                  <br />
                                  <a
                                    v-if="
                                      fileP.relacion.original.Hijos.length >
                                        0 ||
                                      fileP.relacion.original.Padres.length > 0
                                    "
                                    style="cursor: pointer"
                                    :id="
                                      'popover-relacion-item' +
                                      indx +
                                      '-' +
                                      indx1 +
                                      '-' +
                                      indx11
                                    "
                                    tabindex="0"
                                    @click="selectPopP(indx, indx1, indx11)"
                                  >
                                    <v-badge
                                      bordered
                                      color="#ff0000"
                                      icon="mdi-alert-circle"
                                      overlap
                                    >
                                      <v-icon color="#000000">
                                        mdi-link-variant-plus
                                      </v-icon>
                                    </v-badge>
                                  </a>

                                  <a
                                    v-if="
                                      fileP.relacion.original.Hijos.length ==
                                        0 &&
                                      fileP.relacion.original.Padres.length == 0
                                    "
                                    style="cursor: pointer"
                                    :id="
                                      'popover-relacion-item' +
                                      indx +
                                      '-' +
                                      indx1 +
                                      '-' +
                                      indx11
                                    "
                                    tabindex="0"
                                    @click="selectPopP(indx, indx1, indx11)"
                                  >
                                    <v-icon color="#000000">
                                      mdi-link-variant-plus
                                    </v-icon>
                                  </a>

                                  <b-popover
                                    :target="
                                      'popover-relacion-item' +
                                      indx +
                                      '-' +
                                      indx1 +
                                      '-' +
                                      indx11
                                    "
                                    :id="
                                      'popover-relacion-item' +
                                      indx +
                                      '-' +
                                      indx1 +
                                      '-' +
                                      indx11
                                    "
                                    variant="ligth"
                                    triggers="focus"
                                  >
                                    <b-button-group vertical>
                                      <b-button
                                        v-b-modal.modal-multi-list-p
                                        v-b-tooltip.hover.left
                                        title="Relacion consecuente"
                                        @click="selectListRP(indx11)"
                                        variant="light"
                                      >
                                        <b-icon
                                          icon="box-arrow-in-down"
                                          aria-hidden="true"
                                        ></b-icon>
                                        <b-badge
                                          pill
                                          style="background-color: #19a2fa"
                                          >{{
                                            fileP.relacion.original.Padres
                                              .length
                                          }}</b-badge
                                        >
                                      </b-button>
                                      <b-button
                                        v-b-modal.modal-multi-listH-p
                                        v-b-tooltip.hover.left
                                        title="Relacion precedente"
                                        @click="selectListRHP(indx11)"
                                        variant="light"
                                      >
                                        <b-icon
                                          icon="diagram3"
                                          aria-hidden="true"
                                        ></b-icon>
                                        <b-badge
                                          pill
                                          style="background-color: #19a2fa"
                                          >{{
                                            fileP.relacion.original.Hijos.length
                                          }}</b-badge
                                        >
                                      </b-button>
                                      <b-button
                                        v-b-modal.modal-multi-listC-p
                                        v-b-tooltip.hover.left
                                        title="Crear relacion"
                                        @click="
                                          listTagandFileR(fileP);
                                          selectListRCP(indx11);
                                        "
                                        variant="light"
                                      >
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i
                                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </b-button>
                                    </b-button-group>
                                  </b-popover>

                                  <b-modal
                                    v-if="
                                      fileP.relacion.original.Padres.length > 0
                                    "
                                    :id="'modal-multi-list-p-' + indx11"
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    :title="
                                      'Relacion anterior de ' + fileP.text
                                    "
                                  >
                                    <b-list-group>
                                      <b-list-group-item
                                        v-for="(listR, indx11) in fileP.relacion
                                          .original.Padres"
                                        :key="listR.id"
                                      >
                                        <v-row>
                                          <v-col
                                            sm="9"
                                            style="text-align: initial"
                                          >
                                            <v-row>
                                              <v-col>
                                                {{ listR.title }}
                                              </v-col>
                                            </v-row>
                                            <v-row>
                                              <v-col sm="2">
                                                <h6>Ubicacion:</h6>
                                              </v-col>
                                              <v-col sm="10">
                                                {{ listR.ubicacion }}
                                              </v-col>
                                            </v-row>
                                            <p style="font-style: oblique">
                                              {{ listR.created_at }}
                                            </p>
                                            <p style="font-style: oblique">
                                              {{ listR.tag }} - por:
                                              {{ listR.name_user }}
                                            </p>
                                          </v-col>

                                          <v-col
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              class="fa fa-cloud-download fa-lg"
                                              @click="
                                                downloadFile(
                                                  listR.path,
                                                  listR.title
                                                )
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </v-col>

                                          <v-col
                                            v-if="
                                              listR.path.split('.')[1] ==
                                                'png' ||
                                              listR.path.split('.')[1] == 'jpg'
                                            "
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              class="fa fa-eye fa-lg"
                                              aria-hidden="true"
                                              @click="viewFile(listR.path)"
                                            ></i>
                                          </v-col>

                                          <v-col
                                            v-if="
                                              listR.path.split('.')[1] == 'pdf'
                                            "
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              v-b-modal.modal-multi
                                              class="fa fa-eye fa-lg"
                                              aria-hidden="true"
                                              @click="
                                                viewFile(listR.path);
                                                selectPPDF(indx, indx1, indx11);
                                              "
                                            ></i>

                                            <b-modal
                                              :id="
                                                'modal-multi-' +
                                                indx +
                                                '-' +
                                                indx1 +
                                                '-' +
                                                indx11
                                              "
                                              size="lg"
                                              centered
                                              scrollable
                                              hide-footer
                                              hide-backdrop
                                              content-class="shadow"
                                              :title="
                                                'Visualización de ' + listR.text
                                              "
                                            >
                                              <v-row>
                                                <v-col>
                                                  {{ currentPage }} /
                                                  {{ pageCount }}
                                                </v-col>
                                              </v-row>
                                              <pdf
                                                v-for="i in numPages"
                                                :key="i"
                                                :src="fileView"
                                                :page="i"
                                                style="
                                                  display: inline-block;
                                                  width: 50%;
                                                "
                                                @num-pages="pageCount = $event"
                                                @page-loaded="
                                                  currentPage = $event
                                                "
                                              >
                                              </pdf>
                                            </b-modal>
                                          </v-col>
                                        </v-row>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-modal>

                                  <b-modal
                                    v-if="
                                      fileP.relacion.original.Hijos.length > 0
                                    "
                                    :id="'modal-multi-listH-p-' + indx11"
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    :title="
                                      'Relacion posterior de ' + fileP.text
                                    "
                                  >
                                    <b-list-group>
                                      <b-list-group-item
                                        v-for="(listRH, indx11) in fileP
                                          .relacion.original.Hijos"
                                        :key="listRH.id"
                                      >
                                        <v-row>
                                          <v-col
                                            sm="9"
                                            style="text-align: initial"
                                          >
                                            <v-row>
                                              <v-col>
                                                {{ listRH.title }}
                                              </v-col>
                                            </v-row>
                                            <v-row>
                                              <v-col sm="2">
                                                <h6>Ubicacion:</h6>
                                              </v-col>
                                              <v-col sm="10">
                                                {{ listRH.ubicacion }}
                                              </v-col>
                                            </v-row>
                                            <p style="font-style: oblique">
                                              {{ listRH.created_at }}
                                            </p>
                                            <p style="font-style: oblique">
                                              {{ listRH.tag }} - por:
                                              {{ listRH.name_user }}
                                            </p>
                                          </v-col>

                                          <v-col
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              class="fa fa-cloud-download fa-lg"
                                              @click="
                                                downloadFile(
                                                  listRH.path,
                                                  listRH.title
                                                )
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </v-col>

                                          <v-col
                                            v-if="
                                              listRH.path.split('.')[1] ==
                                                'png' ||
                                              listRH.path.split('.')[1] == 'jpg'
                                            "
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              class="fa fa-eye fa-lg"
                                              aria-hidden="true"
                                              @click="viewFile(listRH.path)"
                                            ></i>
                                          </v-col>

                                          <v-col
                                            v-if="
                                              listRH.path.split('.')[1] == 'pdf'
                                            "
                                            sm="1"
                                            style="text-align: center"
                                          >
                                            <br />
                                            <i
                                              style="cursor: pointer"
                                              v-b-modal.modal-multi
                                              class="fa fa-eye fa-lg"
                                              aria-hidden="true"
                                              @click="
                                                viewFile(listRH.path);
                                                selectPPDF(indx, indx1, indx11);
                                              "
                                            ></i>

                                            <b-modal
                                              :id="
                                                'modal-multi-' +
                                                indx +
                                                '-' +
                                                indx1 +
                                                '-' +
                                                indx11
                                              "
                                              size="lg"
                                              centered
                                              scrollable
                                              hide-footer
                                              hide-backdrop
                                              content-class="shadow"
                                              :title="
                                                'Visualización de ' +
                                                listRH.text
                                              "
                                            >
                                              <v-row>
                                                <v-col>
                                                  {{ currentPage }} /
                                                  {{ pageCount }}
                                                </v-col>
                                              </v-row>
                                              <pdf
                                                v-for="i in numPages"
                                                :key="i"
                                                :src="fileView"
                                                :page="i"
                                                style="
                                                  display: inline-block;
                                                  width: 50%;
                                                "
                                                @num-pages="pageCount = $event"
                                                @page-loaded="
                                                  currentPage = $event
                                                "
                                              >
                                              </pdf>
                                            </b-modal>
                                          </v-col>
                                        </v-row>
                                      </b-list-group-item>
                                    </b-list-group>
                                  </b-modal>

                                  <b-modal
                                    :id="'modal-multi-listC-p-' + indx11"
                                    size="lg"
                                    centered
                                    hide-footer
                                    :title="'Crear relacion para ' + fileP.text"
                                  >
                                    <v-row>
                                      <v-col sm="9">
                                        <v-combobox
                                          v-model="selectdateFileR"
                                          :items="dateFileR"
                                          label="Ingrese el nombre del archivo"
                                          dense
                                          persistent-hint
                                        ></v-combobox>
                                      </v-col>
                                      <v-col
                                        sm="3"
                                        style="
                                          display: flex;
                                          align-items: center;
                                          justify-content: center;
                                        "
                                      >
                                        <b-button
                                          variant="outline-info"
                                          @click="createRel(fileP.idFile)"
                                        >
                                          Generar relacion
                                        </b-button>
                                      </v-col>
                                    </v-row>
                                  </b-modal>
                                </v-col>

                                <v-col
                                  v-if="
                                    fileP.path.split('.')[1] == 'png' ||
                                    fileP.path.split('.')[1] == 'jpg'
                                  "
                                  sm="1"
                                  style="text-align: center"
                                >
                                  <br />
                                  <i
                                    style="cursor: pointer"
                                    class="fa fa-eye fa-lg"
                                    aria-hidden="true"
                                    @click="viewFile(fileP.path)"
                                  ></i>
                                </v-col>

                                <v-col
                                  v-if="fileP.path.split('.')[1] == 'pdf'"
                                  sm="1"
                                  style="text-align: center"
                                >
                                  <br />
                                  <i
                                    style="cursor: pointer"
                                    v-b-modal.modal-multi
                                    class="fa fa-eye fa-lg"
                                    aria-hidden="true"
                                    @click="
                                      viewFile(fileP.path),
                                        selectPPDF(indx, indx1, indx11)
                                    "
                                  ></i>

                                  <b-modal
                                    :id="
                                      'modal-multi-' +
                                      indx +
                                      '-' +
                                      indx1 +
                                      '-' +
                                      indx11
                                    "
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    hide-backdrop
                                    content-class="shadow"
                                    :title="'Visualización de ' + fileP.text"
                                  >
                                    <v-row>
                                      <v-col>
                                        {{ currentPage }} / {{ pageCount }}
                                      </v-col>
                                    </v-row>
                                    <pdf
                                      v-for="i in numPages"
                                      :key="i"
                                      :src="fileView"
                                      :page="i"
                                      style="display: inline-block; width: 50%"
                                      @num-pages="pageCount = $event"
                                      @page-loaded="currentPage = $event"
                                    ></pdf>
                                  </b-modal>
                                </v-col>
                              </v-row>
                            </b-list-group-item>
                          </b-list-group>
                        </b-modal>
                      </b-dropdown-form>
                    </b-dropdown>

                    <div style="margin: 0.5rem !important; padding-bottom: 6px">
                      <b-button
                        @click="selectCUpload(indx)"
                        v-if="permitEdit || permissionCUser"
                        variant="light"
                        class="btn_drop"
                      >
                        Cargar archivo
                      </b-button>
                    </div>

                    <b-modal
                      hide-footer
                      size="lg"
                      :id="'modal-no-backdropCU-' + indx"
                      hide-backdrop
                      centered
                      content-class="shadow"
                      :title="'Subir archivo en el punto ' + anillo.name"
                    >
                      <v-row style="display: flex; justify-content: flex-end">
                        <v-col cols="3">
                          <v-checkbox
                            v-if="selectedTypeServer == 'GoogleDrive'"
                            v-model="codeActive"
                            label="Asociar por codigo"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="4"
                          style="display: flex; align-items: center"
                        >
                          <v-select
                            v-model="selectedTypeServer"
                            :items="typeServer"
                            label="Seleccione servidor destino"
                            height="25"
                            dense
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-file-input
                            dense
                            height="25"
                            v-model="documentP"
                            label="Seleccionar un archivo"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            height="25"
                            dense
                            v-model="selectedTypeDocument"
                            :items="typeDocument"
                            label="Seleccione tipo de documento"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          v-if="
                            selectedTypeServer == 'GoogleDrive' && !codeActive
                          "
                          cols="12"
                        >
                          <v-row v-if="!structThreeF">
                            <v-col>
                              <v-chip class="ma-2" label>
                                <p
                                  v-if="folderSelectN.length == 0"
                                  style="
                                    margin-top: 0.5rem;
                                    margin-bottom: 0.5rem;
                                  "
                                >
                                  No se ha seleccionado carpeta
                                </p>
                                <p v-else>
                                  carpeta seleccionado: {{ folderSelectN }}
                                </p>
                              </v-chip>
                              <v-btn
                                elevation="2"
                                color="#009688"
                                style="color: white"
                                fab
                                small
                                @click="structThreeF = true"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>

                          <v-responsive
                            v-if="structThreeF"
                            style="overflow: scroll"
                            class="overflow-y-auto"
                            max-height="250"
                          >
                            <v-treeview
                              v-model="treeTest"
                              :open="initiallyOpen"
                              :items="folderGoogle"
                              activatable
                              item-key="text"
                              item-text="text"
                            >
                              <template v-slot:prepend="{ item, open }">
                                <v-icon
                                  @click="
                                    selectFolderGoogle = item.id;
                                    structThreeF = false;
                                    folderSelectN = item.text;
                                  "
                                >
                                  {{ open ? "mdi-folder-open" : "mdi-folder" }}
                                </v-icon>
                              </template>
                            </v-treeview>
                          </v-responsive>
                        </v-col>
                        <v-col v-if="codeActive" cols="6">
                          <v-autocomplete
                            height="25"
                            dense
                            v-model="selectCode"
                            :items="listCode"
                            label="Seleccione el codigo"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <br />
                      <v-row>
                        <v-col>
                          <b-button
                            style="color: white"
                            :disabled="
                              selectedTypeServer == 'GoogleDrive'
                                ? selectFolderGoogle == null ||
                                  documentP == null ||
                                  selectedTypeDocument == 0
                                : documentP == null || selectedTypeDocument == 0
                            "
                            variant="primary"
                            type="submit"
                            @click="uploadDocumentsP(anillo.id, 'Punto_Area')"
                          >
                            Cargar archivo
                          </b-button>

                          <v-dialog
                            transition="dialog-top-transition"
                            max-width="600"
                            v-model="dialogUpCopyP2"
                          >
                            <v-card>
                              <v-card-text>
                                <div class="text-h6 pa-12">
                                  El archivo que intenta cargar esta duplicado.
                                </div>
                                <p>
                                  Ingrese un nombre diferente o dejar sin editar
                                  asignar un consecutivo
                                </p>
                                <v-text-field
                                  :label="nameFileRandom"
                                  hide-details="auto"
                                  v-model="mameFileUp"
                                  :rules="fileRules"
                                ></v-text-field>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  :disabled="!statusVerify"
                                  color="#57B55D"
                                  class="ma-2"
                                  style="color: white"
                                  @click="reeSendFile('Punto', anillo.id)"
                                  >Guardar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </b-modal>

                    <div
                      style="margin: 0.5rem !important; padding-bottom: 12px"
                    >
                      <b-button
                        v-if="permitEdit"
                        @click="$bvModal.show('bv-modal-deletePointC' + indx)"
                        variant="light"
                        class="btn_drop"
                      >
                        Eliminar punto
                      </b-button>
                    </div>

                    <b-modal
                      centered
                      :id="'bv-modal-deletePointC' + indx"
                      hide-footer
                    >
                      <v-row>
                        <v-col>
                          <h6>Desea eliminar el punto?</h6>
                        </v-col>
                        <v-row>
                          <v-col>
                            <b-form-group
                              id="input-group-deleteFig"
                              label="Confirme escribiendo la palabra 'Si':"
                              label-for="input-deletePoint"
                              description="Al eliminar el area se eliminaran los archivos adjuntos en el punto."
                            >
                              <b-form-input
                                id="input-deletePoint"
                                v-model="confirDelete"
                                placeholder=""
                                required
                              ></b-form-input>
                            </b-form-group>
                            <b-button
                              @click="
                                deletePoint(anillo.id);
                                $bvModal.hide('bv-modal-deletePoint' + indx);
                              "
                              :disabled="confirDelete != 'Si'"
                              variant="danger"
                              >Eliminar</b-button
                            >
                          </v-col>
                        </v-row>
                      </v-row>
                    </b-modal>

                    <template #modal-footer>
                      <v-row class="text_w">
                        <v-col
                          cols="12"
                          style="padding-right: 0px; padding-left: 0px"
                        >
                          <em style="font-size: 12px"
                            ># de Archivos: {{ filesPoints.length }}</em
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </b-modal>
                </b-dropdown-item>
              </b-dropdown>

              <b-modal
                size="lg"
                hide-footer
                id="modal-uploadC"
                hide-backdrop
                centered
                content-class="shadow"
                :title="'Subir archivo en el canvas ' + nameCanvas"
              >
                <v-row style="display: flex; justify-content: flex-end">
                  <v-col cols="3">
                    <v-checkbox
                      v-if="selectedTypeServer == 'GoogleDrive'"
                      v-model="codeActive"
                      label="Asociar por codigo"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" style="display: flex; align-items: center">
                    <v-select
                      v-model="selectedTypeServer"
                      :items="typeServer"
                      label="Seleccione servidor destino"
                      height="25"
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-file-input
                      dense
                      height="25"
                      v-model="documentC"
                      label="Seleccionar un archivo"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      height="25"
                      dense
                      v-model="selectedTypeDocument"
                      :items="typeDocument"
                      label="Seleccione tipo de documento"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-if="selectedTypeServer == 'GoogleDrive' && !codeActive"
                    cols="12"
                  >
                    <v-row v-if="!structThreeF">
                      <v-col>
                        <v-chip class="ma-2" label>
                          <p
                            v-if="folderSelectN.length == 0"
                            style="margin-top: 0.5rem; margin-bottom: 0.5rem"
                          >
                            No se ha seleccionado carpeta
                          </p>
                          <p v-else>
                            carpeta seleccionado: {{ folderSelectN }}
                          </p>
                        </v-chip>
                        <v-btn
                          elevation="2"
                          color="#009688"
                          style="color: white"
                          fab
                          small
                          @click="structThreeF = true"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-responsive
                      v-if="structThreeF"
                      style="overflow: scroll"
                      class="overflow-y-auto"
                      max-height="250"
                    >
                      <v-treeview
                        v-model="treeTest"
                        :open="initiallyOpen"
                        :items="folderGoogle"
                        activatable
                        item-key="text"
                        item-text="text"
                      >
                        <template v-slot:prepend="{ item, open }">
                          <v-icon
                            @click="
                              selectFolderGoogle = item.id;
                              structThreeF = false;
                              folderSelectN = item.text;
                            "
                          >
                            {{ open ? "mdi-folder-open" : "mdi-folder" }}
                          </v-icon>
                        </template>
                      </v-treeview>
                    </v-responsive>
                  </v-col>
                  <v-col v-if="codeActive" cols="6">
                    <v-autocomplete
                      height="25"
                      dense
                      v-model="selectCode"
                      :items="listCode"
                      label="Seleccione el codigo"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <br />
                <v-row>
                  <v-col>
                    <b-button
                      style="color: white"
                      :disabled="
                        selectedTypeServer == 'GoogleDrive'
                          ? selectFolderGoogle == null ||
                            documentC == null ||
                            selectedTypeDocument == 0
                          : documentC == null || selectedTypeDocument == 0
                      "
                      variant="primary"
                      type="submit"
                      @click="uploadDocumentsC()"
                    >
                      Cargar archivo
                    </b-button>
                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600"
                      v-model="dialogUpCopyC"
                    >
                      <v-card>
                        <v-card-text>
                          <div class="text-h6 pa-12">
                            El archivo que intenta cargar esta duplicado.
                          </div>
                          <p>
                            Ingrese un nombre diferente o dejar sin editar
                            asignar un consecutivo
                          </p>
                          <v-text-field
                            :label="nameFileRandom"
                            hide-details="auto"
                            v-model="mameFileUp"
                            :rules="fileRules"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            :disabled="!statusVerify"
                            color="#57B55D"
                            class="ma-2"
                            style="color: white"
                            @click="reeSendFile('Canva')"
                            >Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </b-modal>

              <template #modal-footer>
                <v-row class="text_w">
                  <v-col cols="6" style="padding-right: 0px; padding-left: 0px">
                    <em style="font-size: 12px"
                      ># de areas {{ polygonsLoaded.length }}</em
                    >
                  </v-col>
                  <v-col cols="6" style="padding-right: 0px; padding-left: 0px">
                    <em style="font-size: 12px"
                      ># de puntos {{ dataPoints.length }}</em
                    >
                  </v-col>
                </v-row>
              </template>
            </b-modal>
          </v-row>
        </v-col>
      </l-control>

      <l-tile-layer :url="url" :attribution="attribution" />
      <l-polyline
        v-for="(line, index) in linesLoaded"
        :lat-lngs="line"
        :color="polyline.color"
        :key="line.id"
        :id="index"
      >
        <!-- <l-popup content="Ocensa - Oleoducto submarino" /> -->
      </l-polyline>
      <l-polygon
        @click="listZonaF(line.name)"
        :lat-lngs="line.points"
        :color="line.colorBack"
        lineCap="square"
        :fillColor="line.fillColor"
        v-for="(line, index) in polygonsR"
        :key="line.id"
        :id="index"
      >
        <l-tooltip>Area: {{ line.name }}</l-tooltip>
        <l-popup>
          <b-card header-tag="header" footer-tag="footer">
            <template #header>
              <h6 class="mb-0" style="margin-right: 15px">
                Informacion del Area {{ line.name }}
              </h6>
            </template>
            <b-card-text>
              <b-dropdown
                style="display: grid; text-align: -webkit-match-parent"
                block
                id="dropdown-dropright"
                dropright
                text="Ver archivos"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-form
                  v-for="(tagsLG, index1) in tagsListG"
                  :key="tagsLG.id"
                >
                  <b-dropdown-item-button
                    @click="
                      ordenarForTagG(tagsLG);
                      selectG(index, index1);
                    "
                  >
                    {{ tagsLG }}

                    <b-modal
                      :id="`modal-tag-Area-${index}-${index1}`"
                      size="lg"
                      hide-footer
                      centered
                      hide-backdrop
                      scrollable
                      content-class="shadow"
                      :title="`Ordenado por ${tagsLG}`"
                    >
                      <b-list-group>
                        <b-list-group-item
                          v-for="(fileG, index11) in filesG"
                          :key="fileG.id"
                        >
                          <v-row>
                            <v-col sm="9" style="text-align: initial">
                              <v-row>
                                <v-col>
                                  {{ fileG.text }}
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col sm="2">
                                  <h6>Ubicacion:</h6>
                                </v-col>
                                <v-col sm="10">
                                  {{ fileG.ubicacion }}
                                </v-col>
                              </v-row>
                              <p style="font-style: oblique">
                                {{ fileG.created_at }}
                              </p>
                              <p style="font-style: oblique">{{ fileG.tag }}</p>
                            </v-col>
                            <v-col sm="1" style="text-align: center">
                              <br />
                              <i
                                style="cursor: pointer"
                                class="fa fa-cloud-download fa-lg"
                                @click="downloadFile(fileG.path, fileG.text)"
                                aria-hidden="true"
                              ></i>
                            </v-col>

                            <v-col sm="1" style="text-align: center">
                              <br />
                              <a
                                v-if="
                                  fileG.relacion.Hijos.length > 0 ||
                                  fileG.relacion.Padres.length > 0
                                "
                                style="cursor: pointer"
                                :id="`popover-button-variant${index}-${index11}`"
                                tabindex="0"
                                @click="selectPop(index, index11)"
                              >
                                <v-badge
                                  bordered
                                  color="#ff0000"
                                  icon="mdi-alert-circle"
                                  overlap
                                >
                                  <v-icon color="#000000">
                                    mdi-link-variant-plus
                                  </v-icon>
                                </v-badge>
                              </a>

                              <a
                                v-if="
                                  fileG.relacion.Hijos.length == 0 &&
                                  fileG.relacion.Padres.length == 0
                                "
                                style="cursor: pointer"
                                :id="`popover-button-variant${index}-${index11}`"
                                tabindex="0"
                                @click="selectPop(index, index11)"
                              >
                                <v-icon color="#000000">
                                  mdi-link-variant-plus
                                </v-icon>
                              </a>

                              <b-popover
                                :target="`popover-button-variant${index}-${index11}`"
                                :id="`popover-button-variant${index}-${index11}`"
                                variant="ligth"
                                triggers="focus"
                              >
                                <b-button-group vertical>
                                  <b-button
                                    v-b-tooltip.hover.left
                                    title="Relacion consecuente"
                                    @click="selectListR(index11)"
                                    variant="light"
                                  >
                                    <b-icon
                                      icon="box-arrow-in-down"
                                      aria-hidden="true"
                                    ></b-icon>
                                    <b-badge
                                      pill
                                      style="background-color: #19a2fa"
                                      >{{
                                        fileG.relacion.Padres.length
                                      }}</b-badge
                                    >
                                  </b-button>
                                  <b-button
                                    v-b-tooltip.hover.left
                                    title="Relacion precedente"
                                    @click="selectListRH(index11)"
                                    variant="light"
                                  >
                                    <b-icon
                                      icon="diagram3"
                                      aria-hidden="true"
                                    ></b-icon>
                                    <b-badge
                                      pill
                                      style="background-color: #19a2fa"
                                      >{{
                                        fileG.relacion.Hijos.length
                                      }}</b-badge
                                    >
                                  </b-button>
                                  <b-button
                                    v-b-modal.modal-multi-listC
                                    v-b-tooltip.hover.left
                                    title="Crear relacion"
                                    @click="
                                      listTagandFileR(fileG);
                                      selectListRC(index, index11);
                                    "
                                    variant="light"
                                  >
                                    <i class="fa fa-plus" aria-hidden="true"></i
                                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </b-button>
                                </b-button-group>
                              </b-popover>
                            </v-col>

                            <v-col
                              v-if="
                                fileG.path.split('.')[1] == 'png' ||
                                fileG.path.split('.')[1] == 'jpg'
                              "
                              sm="1"
                              style="text-align: center"
                            >
                              <br />
                              <i
                                style="cursor: pointer"
                                class="fa fa-eye fa-lg"
                                aria-hidden="true"
                                @click="viewFile(fileG.path)"
                              ></i>
                            </v-col>

                            <v-col
                              v-if="fileG.path.split('.')[1] == 'pdf'"
                              sm="1"
                              style="text-align: center"
                            >
                              <br />
                              <i
                                style="cursor: pointer"
                                class="fa fa-eye fa-lg"
                                aria-hidden="true"
                                @click="
                                  viewFile(fileG.path);
                                  selectAPDF(index, index11);
                                "
                              ></i>
                            </v-col>

                            <v-col
                              v-if="
                                fileG.path.split('.')[1] == 'xlsx' ||
                                fileG.path.split('.')[1] == 'pptx' ||
                                fileG.path.split('.')[1] == 'docx'
                              "
                              sm="1"
                              style="text-align: center"
                            >
                              <br />
                              <i
                                style="cursor: pointer"
                                class="fa fa-eye fa-lg"
                                aria-hidden="true"
                                @click="
                                  viewFile(fileG.path),
                                    selectAPDF(index, index11)
                                "
                              ></i>
                            </v-col>

                            <b-modal
                              v-if="filesG[index11].relacion.Padres.length > 0"
                              :id="`modal-multi-list-${index11}`"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              :title="`Relacion anterior de ${filesG[index11].text}`"
                            >
                              <b-list-group>
                                <b-list-group-item
                                  v-for="(listR, index2) in filesG[index11]
                                    .relacion.Padres"
                                  :key="index2"
                                >
                                  <v-row>
                                    <v-col sm="9" style="text-align: initial">
                                      <v-row>
                                        <v-col>
                                          {{ listR.text }}
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col sm="2">
                                          <h6>Ubicacion:</h6>
                                        </v-col>
                                        <v-col sm="10">
                                          {{ listR.ubicacion }}
                                        </v-col>
                                      </v-row>
                                      <p style="font-style: oblique">
                                        {{ listR.created_at }}
                                      </p>
                                      <p style="font-style: oblique">
                                        {{ listR.tag }} - por:
                                        {{ listR.name_user }}
                                      </p>
                                    </v-col>

                                    <v-col sm="1" style="text-align: center">
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-cloud-download fa-lg"
                                        @click="
                                          downloadFile(listR.path, listR.text)
                                        "
                                        aria-hidden="true"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="
                                        listR.path.split('.')[1] == 'png' ||
                                        listR.path.split('.')[1] == 'jpg'
                                      "
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="viewFile(listR.path)"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="listR.path.split('.')[1] == 'pdf'"
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="
                                          viewFile(listR.path);
                                          selectPPDF(index, index11);
                                        "
                                      ></i>
                                    </v-col>
                                  </v-row>

                                  <b-modal
                                    v-if="
                                      filesG[index11].relacion.Padres.length > 0
                                    "
                                    :id="`modal-multiP-${index}-${index11}`"
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    hide-backdrop
                                    content-class="shadow"
                                    :title="`Visualización de ${filesG[index11].relacion.Padres[index2].text}`"
                                  >
                                    <v-row>
                                      <v-col>
                                        {{ currentPage }} / {{ pageCount }}
                                      </v-col>
                                    </v-row>
                                    <pdf
                                      v-for="i in numPages"
                                      :key="i"
                                      :src="fileView"
                                      :page="i"
                                      style="display: inline-block; width: 50%"
                                      @num-pages="pageCount = $event"
                                      @page-loaded="currentPage = $event"
                                    ></pdf>
                                  </b-modal>
                                </b-list-group-item>
                              </b-list-group>
                            </b-modal>

                            <b-modal
                              v-if="filesG[index11].relacion.Hijos.length > 0"
                              :id="`modal-multi-listH-${index11}`"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              :title="`Relacion posterior de ${filesG[index11].text}`"
                            >
                              <b-list-group>
                                <b-list-group-item
                                  v-for="(listRH, index2) in filesG[index11]
                                    .relacion.Hijos"
                                  :key="listRH.id"
                                >
                                  <v-row>
                                    <v-col sm="9" style="text-align: initial">
                                      <v-row>
                                        <v-col>
                                          {{ listRH.text }}
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col sm="2">
                                          <h6>Ubicacion:</h6>
                                        </v-col>
                                        <v-col sm="10">
                                          {{ listRH.ubicacion }}
                                        </v-col>
                                      </v-row>
                                      <p style="font-style: oblique">
                                        {{ listRH.created_at }}
                                      </p>
                                      <p style="font-style: oblique">
                                        {{ listRH.tag }} - por:
                                        {{ listRH.name_user }}
                                      </p>
                                    </v-col>

                                    <v-col sm="1" style="text-align: center">
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-cloud-download fa-lg"
                                        @click="
                                          downloadFile(listRH.path, listRH.text)
                                        "
                                        aria-hidden="true"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="
                                        listRH.path.split('.')[1] == 'png' ||
                                        listRH.path.split('.')[1] == 'jpg'
                                      "
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="viewFile(listRH.path)"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="listRH.path.split('.')[1] == 'pdf'"
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        v-b-modal.modal-multi
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="
                                          viewFile(listRH.path);
                                          selectHPDF(index, index2);
                                        "
                                      ></i>
                                    </v-col>
                                  </v-row>

                                  <b-modal
                                    v-if="
                                      filesG[index11].relacion.Hijos.length > 0
                                    "
                                    :id="`modal-multiH-${index}-${index11}`"
                                    size="lg"
                                    centered
                                    scrollable
                                    hide-footer
                                    hide-backdrop
                                    content-class="shadow"
                                    :title="`Visualización de ${listRH.text}`"
                                  >
                                    <v-row>
                                      <v-col>
                                        {{ currentPage }} / {{ pageCount }}
                                      </v-col>
                                    </v-row>
                                    <pdf
                                      v-for="i in numPages"
                                      :key="i"
                                      :src="fileView"
                                      :page="i"
                                      style="display: inline-block; width: 50%"
                                      @num-pages="pageCount = $event"
                                      @page-loaded="currentPage = $event"
                                    ></pdf>
                                  </b-modal>
                                </b-list-group-item>
                              </b-list-group>
                            </b-modal>

                            <b-modal
                              :id="`modal-multi-listC-${index}-${index11}`"
                              size="lg"
                              centered
                              hide-footer
                              :title="`Crear relacion para ${filesG[index11].text}`"
                            >
                              <v-row>
                                <v-col sm="9">
                                  <v-combobox
                                    v-model="selectdateFileR"
                                    :items="dateFileR"
                                    label="Ingrese el nombre del archivo"
                                    dense
                                    persistent-hint
                                  ></v-combobox>
                                </v-col>
                                <v-col
                                  sm="3"
                                  style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                >
                                  <b-button
                                    variant="outline-info"
                                    @click="createRel(filesG[index11].idFile)"
                                  >
                                    Generar relacion
                                  </b-button>
                                </v-col>
                              </v-row>
                            </b-modal>

                            <b-modal
                              :id="`modal-multi-${index}-${index11}`"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              hide-backdrop
                              content-class="shadow"
                              :title="`Visualización de ${filesG[index11].text}`"
                            >
                              <v-row>
                                <v-col>
                                  {{ currentPage }} / {{ pageCount }}
                                </v-col>
                              </v-row>
                              <pdf
                                v-for="i in numPages"
                                :key="i"
                                :src="fileView"
                                :page="i"
                                style="display: inline-block; width: 50%"
                                @num-pages="pageCount = $event"
                                @page-loaded="currentPage = $event"
                              ></pdf>
                            </b-modal>
                            <b-modal
                              :id="`modal-multi-${index}-${index11}`"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              hide-backdrop
                              content-class="shadow"
                              :title="
                                'Visualización de ' + filesG[index11].text
                              "
                            >
                              <VueDocPreview
                                v-if="fileView != null"
                                :value="fileView"
                                type="office"
                              />
                            </b-modal>
                          </v-row>
                        </b-list-group-item>
                      </b-list-group>
                    </b-modal>
                  </b-dropdown-item-button>
                </b-dropdown-form>
              </b-dropdown>

              <div style="margin: 0.5rem !important">
                <b-button
                  v-if="permitEdit || permissionCUser"
                  variant="light"
                  class="btn_drop"
                  @click="selectUploadZ(index)"
                >
                  Cargar archivo
                </b-button>
                
                <b-modal
                  hide-footer
                  size="lg"
                  :id="'modal-uploadZone-' + index"
                  hide-backdrop
                  centered
                  content-class="shadow"
                  :title="'Subir archivo en la zona ' + line.name"
                >
                  <v-row style="display: flex; justify-content: flex-end">
                    <v-col cols="3">
                      <v-checkbox
                        v-if="selectedTypeServer == 'GoogleDrive'"
                        v-model="codeActive"
                        label="Asociar por codigo"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4" style="display: flex; align-items: center">
                      <v-select
                        v-model="selectedTypeServer"
                        :items="typeServer"
                        label="Seleccione servidor destino"
                        height="25"
                        dense
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-file-input
                        dense
                        height="25"
                        v-model="documentZ"
                        label="Seleccionar un archivo"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        height="25"
                        dense
                        v-model="selectedTypeDocument"
                        :items="typeDocument"
                        label="Seleccione tipo de documento"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="selectedTypeServer == 'GoogleDrive' && !codeActive"
                      cols="12"
                    >
                      <v-row v-if="!structThreeF">
                        <v-col>
                          <v-chip class="ma-2" label>
                            <p
                              v-if="folderSelectN.length == 0"
                              style="margin-top: 0.5rem; margin-bottom: 0.5rem"
                            >
                              No se ha seleccionado carpeta
                            </p>
                            <p v-else>
                              carpeta seleccionado: {{ folderSelectN }}
                            </p>
                          </v-chip>
                          <v-btn
                            elevation="2"
                            color="#009688"
                            style="color: white"
                            fab
                            small
                            @click="structThreeF = true"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-responsive
                        v-if="structThreeF"
                        style="overflow: scroll"
                        class="overflow-y-auto"
                        max-height="250"
                      >
                        <v-treeview
                          v-model="treeTest"
                          :open="initiallyOpen"
                          :items="folderGoogle"
                          activatable
                          item-key="text"
                          item-text="text"
                        >
                          <template v-slot:prepend="{ item, open }">
                            <v-icon
                              @click="
                                selectFolderGoogle = item.id;
                                structThreeF = false;
                                folderSelectN = item.text;
                              "
                            >
                              {{ open ? "mdi-folder-open" : "mdi-folder" }}
                            </v-icon>
                          </template>
                        </v-treeview>
                      </v-responsive>
                    </v-col>
                    <v-col v-if="codeActive" cols="6">
                      <v-autocomplete
                        height="25"
                        dense
                        v-model="selectCode"
                        :items="listCode"
                        label="Seleccione el codigo"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <br />
                  <v-row>
                    <v-col>
                      <b-button
                        style="color: white"
                        :disabled="
                          selectedTypeServer == 'GoogleDrive'
                            ? selectFolderGoogle == null ||
                              documentZ == null ||
                              selectedTypeDocument == 0
                            : documentZ == null || selectedTypeDocument == 0
                        "
                        variant="primary"
                        type="submit"
                        @click="uploadDocumentsA(line.id, 'Area')"
                      >
                        Cargar archivo
                      </b-button>

                      <v-dialog
                        transition="dialog-top-transition"
                        max-width="600"
                        v-model="dialogUpCopyA"
                      >
                        <v-card>
                          <v-card-text>
                            <div class="text-h6 pa-12">
                              El archivo que intenta cargar esta duplicado.
                            </div>
                            <p>
                              Ingrese un nombre diferente o dejar sin editar
                              asignar un consecutivo
                            </p>
                            <v-text-field
                              :label="nameFileRandom"
                              hide-details="auto"
                              v-model="mameFileUp"
                              :rules="fileRules"
                            ></v-text-field>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn
                              :disabled="!statusVerify"
                              color="#57B55D"
                              class="ma-2"
                              style="color: white"
                              @click="reeSendFile('Area', line.id)"
                              >Guardar</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </b-modal>
              </div>

              <b-dropdown
                style="display: grid"
                block
                id="dropdown-dropright"
                dropright
                text="Links"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-form>
                  <b-dropdown-item-button @click="showAddLinkA(index)">
                    Asociar link
                  </b-dropdown-item-button>
                  <b-modal
                    size="lg"
                    hide-footer
                    :id="'modal-add-linksA-' + index"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="'Asociar link en el area ' + line.name"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    "
                  >
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model="refLink"
                          label="Nombre o refrencia del link"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="linkAsoc"
                          label="Link"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          v-model="selectedTypeDocument"
                          :items="typeDocument"
                          label="Seleccione tipo de documento"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-btn
                      class="ma-2"
                      color="#57B55D"
                      style="color: white;"
                      @click="setLink(line.id,  'area'); $bvModal.hide(`modal-add-linksA-${index}`);"
                    >
                      Asociar
                    </v-btn>
                  </b-modal>

                  <b-dropdown-item-button @click="showLinksA(index); getDataLinks(line.id, 'area')">
                    Listar links
                  </b-dropdown-item-button>
                  <b-modal
                    size="lg"
                    hide-footer
                    :id="'modal-linksA-' + index"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="'Lista de links asociada al área ' + line.name"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    "
                  >
                    <div v-if="listLink.length > 0" style="overflow-y: auto!important;
                      height: 320px;">
                      <v-list-item v-for="LinkA in listLink" :key="LinkA.id">
                        <v-list-item-content>
                          <v-list-item-title>Descripción: {{LinkA.titulo}}</v-list-item-title>
                          <v-list-item-subtitle>Tipo de documento: {{LinkA.tag}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-row>
                              <v-col cols="8" style="display: flex;
                                justify-content: flex-end;">
                                <v-btn
                                  class="ma-2"
                                  color="#57B55D"
                                  :href="LinkA.link" 
                                  target="popup"
                                  style="text-transform: none!important; color: white;"
                                  small
                                  @click="popupWindows(LinkA.link)"
                                >
                                  Click para visualizar
                                </v-btn>
                              </v-col>
                              <v-col cols="4" >
                                <v-btn
                                  class="ma-2"
                                  color="#F3575D"
                                  small
                                  @click="deleteLink(LinkA.id);"
                                >
                                  <i class="fa-solid fa-trash" style="color: white;"></i>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </b-modal>

                </b-dropdown-form>
              </b-dropdown>

              <b-dropdown
                style="display: grid"
                block
                id="dropdown-dropright"
                dropright
                text="Puntos"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-item
                  @click="
                    listPointF(anillo.name);
                    selectAtoPoint(indx, index);
                  "
                  v-for="(anillo, indx) in line.pointsD"
                  :key="indx"
                >
                  {{ anillo.name }}

                  <b-modal
                    :id="`modal-AreaD-Point-${indx}-${index}`"
                    size="sm"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="`Informacion del punto ${anillo.name}`"
                  >
                    <b-dropdown
                      style="display: grid; padding-top: 12px"
                      block
                      id="dropdown-dropright"
                      dropright
                      text="Ver archivos"
                      variant="light"
                      class="m-2 drop_L"
                    >
                      <b-dropdown-form
                        v-for="(tagsL, indx1) in tagsList"
                        :key="tagsL.id"
                      >
                        <b-dropdown-item-button
                          @click="
                            ordenarForTag(tagsL);
                            selectPA(indx, index, indx1);
                          "
                        >
                          {{ tagsL }}-A

                          <b-modal
                            :id="`modal-no-backdropA-${indx}-${index}-${indx1}`"
                            size="lg"
                            centered
                            hide-footer
                            hide-backdrop
                            scrollable
                            content-class="shadow"
                            :title="'Ordenado por ' + tagsL"
                          >
                            <b-list-group>
                              <b-list-group-item
                                v-for="(fileP, indx11) in filesP"
                                :key="fileP.idFile"
                              >
                                <v-row>
                                  <v-col sm="8" style="text-align: initial">
                                    <v-row>
                                      <v-col>
                                        {{ fileP.text }}
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col sm="2">
                                        <h6>Ubicacion:</h6>
                                      </v-col>
                                      <v-col sm="10">
                                        {{ fileP.ubicacion }}
                                      </v-col>
                                    </v-row>
                                    <p style="font-style: oblique">
                                      {{ fileP.created_at }}
                                    </p>
                                  </v-col>

                                  <v-col sm="1" style="text-align: center">
                                    <br />
                                    <i
                                      style="cursor: pointer"
                                      class="fa fa-cloud-download fa-lg"
                                      @click="
                                        downloadFile(fileP.path, fileP.text)
                                      "
                                      aria-hidden="true"
                                    ></i>
                                  </v-col>

                                  <v-col sm="1" style="text-align: center">
                                    <br />
                                    <a
                                      v-if="
                                        fileP.relacion.original.Hijos.length >
                                          0 ||
                                        fileP.relacion.original.Padres.length >
                                          0
                                      "
                                      style="cursor: pointer"
                                      :id="
                                        'popover-relacion-item' +
                                        indx +
                                        '-' +
                                        indx1 +
                                        '-' +
                                        indx11
                                      "
                                      tabindex="0"
                                      @click="selectPopP(indx, indx1, indx11)"
                                    >
                                      <v-badge
                                        bordered
                                        color="#ff0000"
                                        icon="mdi-alert-circle"
                                        overlap
                                      >
                                        <v-icon color="#000000">
                                          mdi-link-variant-plus
                                        </v-icon>
                                      </v-badge>
                                    </a>

                                    <a
                                      v-if="
                                        fileP.relacion.original.Hijos.length ==
                                          0 &&
                                        fileP.relacion.original.Padres.length ==
                                          0
                                      "
                                      style="cursor: pointer"
                                      :id="
                                        'popover-relacion-item' +
                                        indx +
                                        '-' +
                                        indx1 +
                                        '-' +
                                        indx11
                                      "
                                      tabindex="0"
                                      @click="selectPopP(indx, indx1, indx11)"
                                    >
                                      <v-icon color="#000000">
                                        mdi-link-variant-plus
                                      </v-icon>
                                    </a>

                                    <b-popover
                                      :target="
                                        'popover-relacion-item' +
                                        indx +
                                        '-' +
                                        indx1 +
                                        '-' +
                                        indx11
                                      "
                                      :id="
                                        'popover-relacion-item' +
                                        indx +
                                        '-' +
                                        indx1 +
                                        '-' +
                                        indx11
                                      "
                                      variant="ligth"
                                      triggers="focus"
                                    >
                                      <b-button-group vertical>
                                        <b-button
                                          v-b-modal.modal-multi-list-p
                                          v-b-tooltip.hover.left
                                          title="Relacion consecuente"
                                          @click="selectListRP(indx11)"
                                          variant="light"
                                        >
                                          <b-icon
                                            icon="box-arrow-in-down"
                                            aria-hidden="true"
                                          ></b-icon>
                                          <b-badge
                                            pill
                                            style="background-color: #19a2fa"
                                            >{{
                                              fileP.relacion.original.Padres
                                                .length
                                            }}</b-badge
                                          >
                                        </b-button>
                                        <b-button
                                          v-b-modal.modal-multi-listH-p
                                          v-b-tooltip.hover.left
                                          title="Relacion precedente"
                                          @click="selectListRHP(indx11)"
                                          variant="light"
                                        >
                                          <b-icon
                                            icon="diagram3"
                                            aria-hidden="true"
                                          ></b-icon>
                                          <b-badge
                                            pill
                                            style="background-color: #19a2fa"
                                            >{{
                                              fileP.relacion.original.Hijos
                                                .length
                                            }}</b-badge
                                          >
                                        </b-button>
                                        <b-button
                                          v-b-modal.modal-multi-listC-p
                                          v-b-tooltip.hover.left
                                          title="Crear relacion"
                                          @click="
                                            listTagandFileR(fileP);
                                            selectListRCP(indx11);
                                          "
                                          variant="light"
                                        >
                                          <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                          ></i
                                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </b-button>
                                      </b-button-group>
                                    </b-popover>

                                    <b-modal
                                      v-if="
                                        fileP.relacion.original.Padres.length >
                                        0
                                      "
                                      :id="'modal-multi-list-p-' + indx11"
                                      size="lg"
                                      centered
                                      scrollable
                                      hide-footer
                                      :title="
                                        'Relacion anterior de ' + fileP.text
                                      "
                                    >
                                      <b-list-group>
                                        <b-list-group-item
                                          v-for="(listR, indx11) in fileP
                                            .relacion.original.Padres"
                                          :key="listR.id"
                                        >
                                          <v-row>
                                            <v-col
                                              sm="9"
                                              style="text-align: initial"
                                            >
                                              <v-row>
                                                <v-col>
                                                  {{ listR.title }}
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col sm="2">
                                                  <h6>Ubicacion:</h6>
                                                </v-col>
                                                <v-col sm="10">
                                                  {{ listR.ubicacion }}
                                                </v-col>
                                              </v-row>
                                              <p style="font-style: oblique">
                                                {{ listR.created_at }}
                                              </p>
                                              <p style="font-style: oblique">
                                                {{ listR.tag }} - por:
                                                {{ listR.name_user }}
                                              </p>
                                            </v-col>

                                            <v-col
                                              sm="1"
                                              style="text-align: center"
                                            >
                                              <br />
                                              <i
                                                style="cursor: pointer"
                                                class="fa fa-cloud-download fa-lg"
                                                @click="
                                                  downloadFile(
                                                    listR.path,
                                                    listR.title
                                                  )
                                                "
                                                aria-hidden="true"
                                              ></i>
                                            </v-col>

                                            <v-col
                                              v-if="
                                                listR.path.split('.')[1] ==
                                                  'png' ||
                                                listR.path.split('.')[1] ==
                                                  'jpg'
                                              "
                                              sm="1"
                                              style="text-align: center"
                                            >
                                              <br />
                                              <i
                                                style="cursor: pointer"
                                                class="fa fa-eye fa-lg"
                                                aria-hidden="true"
                                                @click="viewFile(listR.path)"
                                              ></i>
                                            </v-col>

                                            <v-col
                                              v-if="
                                                listR.path.split('.')[1] ==
                                                'pdf'
                                              "
                                              sm="1"
                                              style="text-align: center"
                                            >
                                              <br />
                                              <i
                                                style="cursor: pointer"
                                                v-b-modal.modal-multi
                                                class="fa fa-eye fa-lg"
                                                aria-hidden="true"
                                                @click="
                                                  viewFile(listR.path);
                                                  selectPPDF(
                                                    indx,
                                                    indx1,
                                                    indx11
                                                  );
                                                "
                                              ></i>

                                              <b-modal
                                                :id="
                                                  'modal-multi-' +
                                                  indx +
                                                  '-' +
                                                  indx1 +
                                                  '-' +
                                                  indx11
                                                "
                                                size="lg"
                                                centered
                                                scrollable
                                                hide-footer
                                                hide-backdrop
                                                content-class="shadow"
                                                :title="
                                                  'Visualización de ' +
                                                  listR.text
                                                "
                                              >
                                                <v-row>
                                                  <v-col>
                                                    {{ currentPage }} /
                                                    {{ pageCount }}
                                                  </v-col>
                                                </v-row>
                                                <pdf
                                                  v-for="i in numPages"
                                                  :key="i"
                                                  :src="fileView"
                                                  :page="i"
                                                  style="
                                                    display: inline-block;
                                                    width: 50%;
                                                  "
                                                  @num-pages="
                                                    pageCount = $event
                                                  "
                                                  @page-loaded="
                                                    currentPage = $event
                                                  "
                                                >
                                                </pdf>
                                              </b-modal>
                                            </v-col>
                                          </v-row>
                                        </b-list-group-item>
                                      </b-list-group>
                                    </b-modal>

                                    <b-modal
                                      v-if="
                                        fileP.relacion.original.Hijos.length > 0
                                      "
                                      :id="'modal-multi-listH-p-' + indx11"
                                      size="lg"
                                      centered
                                      scrollable
                                      hide-footer
                                      :title="
                                        'Relacion posterior de ' + fileP.text
                                      "
                                    >
                                      <b-list-group>
                                        <b-list-group-item
                                          v-for="(listRH, indx11) in fileP
                                            .relacion.original.Hijos"
                                          :key="listRH.id"
                                        >
                                          <v-row>
                                            <v-col
                                              sm="9"
                                              style="text-align: initial"
                                            >
                                              <v-row>
                                                <v-col>
                                                  {{ listRH.title }}
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col sm="2">
                                                  <h6>Ubicacion:</h6>
                                                </v-col>
                                                <v-col sm="10">
                                                  {{ listRH.ubicacion }}
                                                </v-col>
                                              </v-row>
                                              <p style="font-style: oblique">
                                                {{ listRH.created_at }}
                                              </p>
                                              <p style="font-style: oblique">
                                                {{ listRH.tag }} - por:
                                                {{ listRH.name_user }}
                                              </p>
                                            </v-col>

                                            <v-col
                                              sm="1"
                                              style="text-align: center"
                                            >
                                              <br />
                                              <i
                                                style="cursor: pointer"
                                                class="fa fa-cloud-download fa-lg"
                                                @click="
                                                  downloadFile(
                                                    listRH.path,
                                                    listRH.title
                                                  )
                                                "
                                                aria-hidden="true"
                                              ></i>
                                            </v-col>

                                            <v-col
                                              v-if="
                                                listRH.path.split('.')[1] ==
                                                  'png' ||
                                                listRH.path.split('.')[1] ==
                                                  'jpg'
                                              "
                                              sm="1"
                                              style="text-align: center"
                                            >
                                              <br />
                                              <i
                                                style="cursor: pointer"
                                                class="fa fa-eye fa-lg"
                                                aria-hidden="true"
                                                @click="viewFile(listRH.path)"
                                              ></i>
                                            </v-col>

                                            <v-col
                                              v-if="
                                                listRH.path.split('.')[1] ==
                                                'pdf'
                                              "
                                              sm="1"
                                              style="text-align: center"
                                            >
                                              <br />
                                              <i
                                                style="cursor: pointer"
                                                v-b-modal.modal-multi
                                                class="fa fa-eye fa-lg"
                                                aria-hidden="true"
                                                @click="
                                                  viewFile(listRH.path);
                                                  selectPPDF(
                                                    indx,
                                                    indx1,
                                                    indx11
                                                  );
                                                "
                                              ></i>

                                              <b-modal
                                                :id="
                                                  'modal-multi-' +
                                                  indx +
                                                  '-' +
                                                  indx1 +
                                                  '-' +
                                                  indx11
                                                "
                                                size="lg"
                                                centered
                                                scrollable
                                                hide-footer
                                                hide-backdrop
                                                content-class="shadow"
                                                :title="
                                                  'Visualización de ' +
                                                  listRH.text
                                                "
                                              >
                                                <v-row>
                                                  <v-col>
                                                    {{ currentPage }} /
                                                    {{ pageCount }}
                                                  </v-col>
                                                </v-row>
                                                <pdf
                                                  v-for="i in numPages"
                                                  :key="i"
                                                  :src="fileView"
                                                  :page="i"
                                                  style="
                                                    display: inline-block;
                                                    width: 50%;
                                                  "
                                                  @num-pages="
                                                    pageCount = $event
                                                  "
                                                  @page-loaded="
                                                    currentPage = $event
                                                  "
                                                >
                                                </pdf>
                                              </b-modal>
                                            </v-col>
                                          </v-row>
                                        </b-list-group-item>
                                      </b-list-group>
                                    </b-modal>

                                    <b-modal
                                      :id="'modal-multi-listC-p-' + indx11"
                                      size="lg"
                                      centered
                                      hide-footer
                                      :title="
                                        'Crear relacion para ' + fileP.text
                                      "
                                    >
                                      <v-row>
                                        <v-col sm="9">
                                          <v-combobox
                                            v-model="selectdateFileR"
                                            :items="dateFileR"
                                            label="Ingrese el nombre del archivo"
                                            dense
                                            persistent-hint
                                          ></v-combobox>
                                        </v-col>
                                        <v-col
                                          sm="3"
                                          style="
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                          "
                                        >
                                          <b-button
                                            variant="outline-info"
                                            @click="createRel(fileP.idFile)"
                                          >
                                            Generar relacion
                                          </b-button>
                                        </v-col>
                                      </v-row>
                                    </b-modal>
                                  </v-col>

                                  <v-col
                                    v-if="
                                      fileP.path.split('.')[1] == 'png' ||
                                      fileP.path.split('.')[1] == 'jpg'
                                    "
                                    sm="1"
                                    style="text-align: center"
                                  >
                                    <br />
                                    <i
                                      style="cursor: pointer"
                                      class="fa fa-eye fa-lg"
                                      aria-hidden="true"
                                      @click="viewFile(fileP.path)"
                                    ></i>
                                  </v-col>

                                  <v-col
                                    v-if="fileP.path.split('.')[1] == 'pdf'"
                                    sm="1"
                                    style="text-align: center"
                                  >
                                    <br />
                                    <i
                                      style="cursor: pointer"
                                      v-b-modal.modal-multi
                                      class="fa fa-eye fa-lg"
                                      aria-hidden="true"
                                      @click="
                                        viewFile(fileP.path),
                                          selectPPDF(indx, indx1, indx11)
                                      "
                                    ></i>

                                    <b-modal
                                      :id="
                                        'modal-multi-' +
                                        indx +
                                        '-' +
                                        indx1 +
                                        '-' +
                                        indx11
                                      "
                                      size="lg"
                                      centered
                                      scrollable
                                      hide-footer
                                      hide-backdrop
                                      content-class="shadow"
                                      :title="'Visualización de ' + fileP.text"
                                    >
                                      <v-row>
                                        <v-col>
                                          {{ currentPage }} / {{ pageCount }}
                                        </v-col>
                                      </v-row>
                                      <pdf
                                        v-for="i in numPages"
                                        :key="i"
                                        :src="fileView"
                                        :page="i"
                                        style="
                                          display: inline-block;
                                          width: 50%;
                                        "
                                        @num-pages="pageCount = $event"
                                        @page-loaded="currentPage = $event"
                                      ></pdf>
                                    </b-modal>
                                  </v-col>
                                </v-row>
                              </b-list-group-item>
                            </b-list-group>
                          </b-modal>
                        </b-dropdown-item-button>
                      </b-dropdown-form>
                    </b-dropdown>

                    <div style="margin: 0.5rem !important; padding-top: 6px">
                      <b-button
                        @click="selectAUpload(indx)"
                        v-if="permitEdit || permissionCUser"
                        variant="light"
                        class="btn_drop"
                        v-b-modal.modal-no-backdrop-3
                      >
                        Cargar archivo
                      </b-button>
                    </div>

                    <b-modal
                      size="lg"
                      hide-footer
                      :id="'modal-no-backdropAU-' + indx"
                      hide-backdrop
                      centered
                      content-class="shadow"
                      :title="'Subir archivo en el punto ' + anillo.name"
                    >
                      <v-row style="display: flex; justify-content: flex-end">
                        <v-col cols="3">
                          <v-checkbox
                            v-if="selectedTypeServer == 'GoogleDrive'"
                            v-model="codeActive"
                            label="Asociar por codigo"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="4"
                          style="display: flex; align-items: center"
                        >
                          <v-select
                            v-model="selectedTypeServer"
                            :items="typeServer"
                            label="Seleccione servidor destino"
                            height="25"
                            dense
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-file-input
                            dense
                            height="25"
                            v-model="documentP"
                            label="Seleccionar un archivo"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            height="25"
                            dense
                            v-model="selectedTypeDocument"
                            :items="typeDocument"
                            label="Seleccione tipo de documento"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          v-if="
                            selectedTypeServer == 'GoogleDrive' && !codeActive
                          "
                          cols="12"
                        >
                          <v-row v-if="!structThreeF">
                            <v-col>
                              <v-chip class="ma-2" label>
                                <p
                                  v-if="folderSelectN.length == 0"
                                  style="
                                    margin-top: 0.5rem;
                                    margin-bottom: 0.5rem;
                                  "
                                >
                                  No se ha seleccionado carpeta
                                </p>
                                <p v-else>
                                  carpeta seleccionado: {{ folderSelectN }}
                                </p>
                              </v-chip>
                              <v-btn
                                elevation="2"
                                color="#009688"
                                style="color: white"
                                fab
                                small
                                @click="structThreeF = true"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>

                          <v-responsive
                            v-if="structThreeF"
                            style="overflow: scroll"
                            class="overflow-y-auto"
                            max-height="250"
                          >
                            <v-treeview
                              v-model="treeTest"
                              :open="initiallyOpen"
                              :items="folderGoogle"
                              activatable
                              item-key="text"
                              item-text="text"
                            >
                              <template v-slot:prepend="{ item, open }">
                                <v-icon
                                  @click="
                                    selectFolderGoogle = item.id;
                                    structThreeF = false;
                                    folderSelectN = item.text;
                                  "
                                >
                                  {{ open ? "mdi-folder-open" : "mdi-folder" }}
                                </v-icon>
                              </template>
                            </v-treeview>
                          </v-responsive>
                        </v-col>
                        <v-col v-if="codeActive" cols="6">
                          <v-autocomplete
                            height="25"
                            dense
                            v-model="selectCode"
                            :items="listCode"
                            label="Seleccione el codigo"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <br />
                      <v-row>
                        <v-col>
                          <b-button
                            style="color: white"
                            :disabled="
                              selectedTypeServer == 'GoogleDrive'
                                ? selectFolderGoogle == null ||
                                  documentP == null ||
                                  selectedTypeDocument == 0
                                : documentP == null || selectedTypeDocument == 0
                            "
                            variant="primary"
                            type="submit"
                            @click="
                              uploadDocumentsP(anillo.id, 'Punto_Area_Canvas')
                            "
                          >
                            Cargar archivo
                          </b-button>

                          <v-dialog
                            transition="dialog-top-transition"
                            max-width="600"
                            v-model="dialogUpCopyP3"
                          >
                            <v-card>
                              <v-card-text>
                                <div class="text-h6 pa-12">
                                  El archivo que intenta cargar esta duplicado.
                                </div>
                                <p>
                                  Ingrese un nombre diferente o dejar sin editar
                                  asignar un consecutivo
                                </p>
                                <v-text-field
                                  :label="nameFileRandom"
                                  hide-details="auto"
                                  v-model="mameFileUp"
                                  :rules="fileRules"
                                ></v-text-field>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  :disabled="!statusVerify"
                                  color="#57B55D"
                                  class="ma-2"
                                  style="color: white"
                                  @click="reeSendFile('Punto', anillo.id)"
                                  >Guardar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </b-modal>

                    <div
                      style="
                        margin: 0.5rem !important;
                        padding-top: 6px;
                        padding-bottom: 12px;
                      "
                    >
                      <b-button
                        v-if="permitEdit"
                        @click="$bvModal.show('bv-modal-deletePoint' + indx)"
                        variant="light"
                        class="btn_drop"
                      >
                        Eliminar punto
                      </b-button>
                    </div>
                    <b-modal
                      centered
                      :id="'bv-modal-deletePoint' + indx"
                      hide-footer
                    >
                      <v-row>
                        <v-col>
                          <h6>Desea eliminar el punto?</h6>
                        </v-col>
                        <v-row>
                          <v-col>
                            <b-form-group
                              id="input-group-deleteFig"
                              label="Confirme escribiendo la palabra 'Si':"
                              label-for="input-deletePoint"
                              description="Al eliminar el area se eliminaran los archivos adjuntos en el punto."
                            >
                              <b-form-input
                                id="input-deletePoint"
                                v-model="confirDelete"
                                placeholder=""
                                required
                              ></b-form-input>
                            </b-form-group>
                            <b-button
                              @click="
                                deletePoint(anillo.id);
                                $bvModal.hide('bv-modal-deletePoint' + indx);
                              "
                              :disabled="confirDelete != 'Si'"
                              variant="danger"
                              >Eliminar</b-button
                            >
                          </v-col>
                        </v-row>
                      </v-row>
                    </b-modal>

                    <template #modal-footer>
                      <v-row class="text_w">
                        <v-col
                          cols="12"
                          style="padding-right: 0px; padding-left: 0px"
                        >
                          <em style="font-size: 12px"
                            ># de Archivos: {{ filesPoints.length }}</em
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </b-modal>
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown
                v-if="permitEdit"
                style="display: grid; text-align: -webkit-match-parent"
                block
                id="dropdown-dropright"
                dropright
                text="Opciones"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-form>
                  <b-dropdown-item-button
                    v-if="permitEdit"
                    @click="$bvModal.show('bv-modal-deleteFig' + index)"
                  >
                    Eliminar area
                  </b-dropdown-item-button>
                  <b-modal
                    centered
                    :id="'bv-modal-deleteFig' + index"
                    hide-footer
                  >
                    <v-row>
                      <v-col>
                        <h6>Desea eliminar el area {{ line.text }} ?</h6>
                      </v-col>
                      <v-row>
                        <v-col>
                          <b-form-group
                            id="input-group-deleteFig"
                            label="Confirme escribiendo la palabra 'Si':"
                            label-for="input-deleteFig"
                            description="Al eliminar el area se eliminaran los archivos adjuntos al area."
                          >
                            <b-form-input
                              id="input-deleteFig"
                              v-model="confirDelete"
                              placeholder=""
                              required
                            ></b-form-input>
                          </b-form-group>
                          <b-button
                            @click="
                              deletePolygon(line.id);
                              $bvModal.hide('bv-modal-deleteFig' + index);
                            "
                            :disabled="confirDelete != 'Si'"
                            variant="danger"
                            >Eliminar</b-button
                          >
                        </v-col>
                      </v-row>
                    </v-row>
                  </b-modal>

                  <b-dropdown-item-button
                    v-if="permitEdit"
                    @click="$bvModal.show('bv-modal-colorFig' + index)"
                  >
                    Color area
                  </b-dropdown-item-button>
                  <b-modal
                    centered
                    :id="'bv-modal-colorFig' + index"
                    hide-footer
                  >
                    <v-row>
                      <v-col>
                        <h6>Elija un color para {{ line.text }} ?</h6>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <b-form-group
                          id="input-group-color"
                          label="Darle clic al color para elegir:"
                          label-for="type-color"
                          description="Se definira el color del area dependiendo de el elegido."
                        >
                          <b-form-input
                            id="type-color"
                            @change="getColor($event)"
                            type="color"
                            required
                          ></b-form-input>
                        </b-form-group>
                        <b-button
                          :disabled="colorEleg.length < 0"
                          variant="success"
                          @click="saveColorC(line.id)"
                          >Guardar</b-button
                        >
                      </v-col>
                    </v-row>
                  </b-modal>
                </b-dropdown-form>
              </b-dropdown>
            </b-card-text>
            <template #footer>
              <v-row>
                <v-col cols="6" style="padding-right: 0px; padding-left: 0px">
                  <em style="font-size: 12px"
                    ># de puntos:{{ line.numberPoints }}</em
                  >
                </v-col>
                <v-col cols="6" style="padding-right: 0px; padding-left: 0px">
                  <em style="font-size: 12px"
                    ># de archivos:{{ filesZones.length }}</em
                  >
                </v-col>
              </v-row>
            </template>
          </b-card>
        </l-popup>
      </l-polygon>
      <l-marker
        @click="listPointF(anillo.name)"
        v-for="(anillo, indx) in anillos.data"
        :key="anillo.name"
        :lat-lng="anillo.position"
        :ref="refP"
        :id="`${anillo.id}`"
        class="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
      >
        <l-icon
          :id="`${anillo.id}`"
          :iconSize="iconSize"
          :icon-url="anillo.marcador_var"
        />
        <l-popup style="display: flex">
          <b-card header-tag="header" footer-tag="footer">
            <template #header>
              <h6 class="mb-0" style="margin-right: 15px">
                Informacion del Punto {{ anillo.name }}
              </h6>
            </template>
            <b-card-text>
              <!-- <i  v-on:click="goToChart(anillo.id, anillo.name)" class="fa fa-bar-chart fa-lg user__more" aria-hidden="true"></i> -->
              <b-dropdown
                style="display: grid"
                block
                id="dropdown-dropright"
                dropright
                text="Ver archivos"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-form
                  v-for="(tagsL, indx1) in tagsList"
                  :key="tagsL.id"
                >
                  <b-dropdown-item-button
                    v-b-modal.modal-no-backdrop
                    @click="
                      ordenarForTag(tagsL);
                      selectP(indx, indx1);
                    "
                  >
                    {{ tagsL }}
                  </b-dropdown-item-button>

                  <b-modal
                    :id="'modal-no-backdrop-' + indx + '-' + indx1"
                    size="lg"
                    centered
                    hide-footer
                    hide-backdrop
                    scrollable
                    content-class="shadow"
                    :title="'Ordenado por ' + tagsL"
                  >
                    <b-list-group>
                      <b-list-group-item
                        v-for="(fileP, indx11) in filesP"
                        :key="fileP.idFile"
                      >
                        <v-row>
                          <v-col sm="8" style="text-align: initial">
                            <v-row>
                              <v-col>
                                {{ fileP.text }}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col sm="2">
                                <h6>Ubicacion:</h6>
                              </v-col>
                              <v-col sm="10">
                                {{ fileP.ubicacion }}
                              </v-col>
                            </v-row>
                            <p style="font-style: oblique">
                              {{ fileP.created_at }}
                            </p>
                          </v-col>

                          <v-col sm="1" style="text-align: center">
                            <br />
                            <i
                              style="cursor: pointer"
                              class="fa fa-cloud-download fa-lg"
                              @click="downloadFile(fileP.path, fileP.text)"
                              aria-hidden="true"
                            ></i>
                          </v-col>

                          <v-col sm="1" style="text-align: center">
                            <br />
                            <a
                              v-if="
                                fileP.relacion.original.Hijos.length > 0 ||
                                fileP.relacion.original.Padres.length > 0
                              "
                              style="cursor: pointer"
                              :id="
                                'popover-relacion-item' +
                                indx +
                                '-' +
                                indx1 +
                                '-' +
                                indx11
                              "
                              tabindex="0"
                              @click="selectPopP(indx, indx1, indx11)"
                            >
                              <v-badge
                                bordered
                                color="#ff0000"
                                icon="mdi-alert-circle"
                                overlap
                              >
                                <v-icon color="#000000">
                                  mdi-link-variant-plus
                                </v-icon>
                              </v-badge>
                            </a>

                            <a
                              v-if="
                                fileP.relacion.original.Hijos.length == 0 &&
                                fileP.relacion.original.Padres.length == 0
                              "
                              style="cursor: pointer"
                              :id="
                                'popover-relacion-item' +
                                indx +
                                '-' +
                                indx1 +
                                '-' +
                                indx11
                              "
                              tabindex="0"
                              @click="selectPopP(indx, indx1, indx11)"
                            >
                              <v-icon color="#000000">
                                mdi-link-variant-plus
                              </v-icon>
                            </a>

                            <b-popover
                              :target="
                                'popover-relacion-item' +
                                indx +
                                '-' +
                                indx1 +
                                '-' +
                                indx11
                              "
                              :id="
                                'popover-relacion-item' +
                                indx +
                                '-' +
                                indx1 +
                                '-' +
                                indx11
                              "
                              variant="ligth"
                              triggers="focus"
                            >
                              <b-button-group vertical>
                                <b-button
                                  v-b-modal.modal-multi-list-p
                                  v-b-tooltip.hover.left
                                  title="Relacion consecuente"
                                  @click="selectListRP(indx11)"
                                  variant="light"
                                >
                                  <b-icon
                                    icon="box-arrow-in-down"
                                    aria-hidden="true"
                                  ></b-icon>
                                  <b-badge
                                    pill
                                    style="background-color: #19a2fa"
                                    >{{
                                      fileP.relacion.original.Padres.length
                                    }}</b-badge
                                  >
                                </b-button>
                                <b-button
                                  v-b-modal.modal-multi-listH-p
                                  v-b-tooltip.hover.left
                                  title="Relacion precedente"
                                  @click="selectListRHP(indx11)"
                                  variant="light"
                                >
                                  <b-icon
                                    icon="diagram3"
                                    aria-hidden="true"
                                  ></b-icon>
                                  <b-badge
                                    pill
                                    style="background-color: #19a2fa"
                                    >{{
                                      fileP.relacion.original.Hijos.length
                                    }}</b-badge
                                  >
                                </b-button>
                                <b-button
                                  v-b-modal.modal-multi-listC-p
                                  v-b-tooltip.hover.left
                                  title="Crear relacion"
                                  @click="
                                    listTagandFileR(fileP);
                                    selectListRCP(indx11);
                                  "
                                  variant="light"
                                >
                                  <i class="fa fa-plus" aria-hidden="true"></i
                                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </b-button>
                              </b-button-group>
                            </b-popover>

                            <b-modal
                              v-if="fileP.relacion.original.Padres.length > 0"
                              :id="'modal-multi-list-p-' + indx11"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              :title="'Relacion anterior de ' + fileP.text"
                            >
                              <b-list-group>
                                <b-list-group-item
                                  v-for="(listR, indx11) in fileP.relacion
                                    .original.Padres"
                                  :key="listR.id"
                                >
                                  <v-row>
                                    <v-col sm="9" style="text-align: initial">
                                      <v-row>
                                        <v-col>
                                          {{ listR.title }}
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col sm="2">
                                          <h6>Ubicacion:</h6>
                                        </v-col>
                                        <v-col sm="10">
                                          {{ listR.ubicacion }}
                                        </v-col>
                                      </v-row>
                                      <p style="font-style: oblique">
                                        {{ listR.created_at }}
                                      </p>
                                      <p style="font-style: oblique">
                                        {{ listR.tag }} - por:
                                        {{ listR.name_user }}
                                      </p>
                                    </v-col>

                                    <v-col sm="1" style="text-align: center">
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-cloud-download fa-lg"
                                        @click="
                                          downloadFile(listR.path, listR.title)
                                        "
                                        aria-hidden="true"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="
                                        listR.path.split('.')[1] == 'png' ||
                                        listR.path.split('.')[1] == 'jpg'
                                      "
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="viewFile(listR.path)"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="listR.path.split('.')[1] == 'pdf'"
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        v-b-modal.modal-multi
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="
                                          viewFile(listR.path);
                                          selectPPDF(indx, indx1, indx11);
                                        "
                                      ></i>

                                      <b-modal
                                        :id="
                                          'modal-multi-' +
                                          indx +
                                          '-' +
                                          indx1 +
                                          '-' +
                                          indx11
                                        "
                                        size="lg"
                                        centered
                                        scrollable
                                        hide-footer
                                        hide-backdrop
                                        content-class="shadow"
                                        :title="
                                          'Visualización de ' + listR.text
                                        "
                                      >
                                        <v-row>
                                          <v-col>
                                            {{ currentPage }} / {{ pageCount }}
                                          </v-col>
                                        </v-row>
                                        <pdf
                                          v-for="i in numPages"
                                          :key="i"
                                          :src="fileView"
                                          :page="i"
                                          style="
                                            display: inline-block;
                                            width: 50%;
                                          "
                                          @num-pages="pageCount = $event"
                                          @page-loaded="currentPage = $event"
                                        ></pdf>
                                      </b-modal>
                                    </v-col>
                                  </v-row>
                                </b-list-group-item>
                              </b-list-group>
                            </b-modal>

                            <b-modal
                              v-if="fileP.relacion.original.Hijos.length > 0"
                              :id="'modal-multi-listH-p-' + indx11"
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              :title="'Relacion posterior de ' + fileP.text"
                            >
                              <b-list-group>
                                <b-list-group-item
                                  v-for="(listRH, indx11) in fileP.relacion
                                    .original.Hijos"
                                  :key="listRH.id"
                                >
                                  <v-row>
                                    <v-col sm="9" style="text-align: initial">
                                      <v-row>
                                        <v-col>
                                          {{ listRH.title }}
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col sm="2">
                                          <h6>Ubicacion:</h6>
                                        </v-col>
                                        <v-col sm="10">
                                          {{ listRH.ubicacion }}
                                        </v-col>
                                      </v-row>
                                      <p style="font-style: oblique">
                                        {{ listRH.created_at }}
                                      </p>
                                      <p style="font-style: oblique">
                                        {{ listRH.tag }} - por:
                                        {{ listRH.name_user }}
                                      </p>
                                    </v-col>

                                    <v-col sm="1" style="text-align: center">
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-cloud-download fa-lg"
                                        @click="
                                          downloadFile(
                                            listRH.path,
                                            listRH.title
                                          )
                                        "
                                        aria-hidden="true"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="
                                        listRH.path.split('.')[1] == 'png' ||
                                        listRH.path.split('.')[1] == 'jpg'
                                      "
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="viewFile(listRH.path)"
                                      ></i>
                                    </v-col>

                                    <v-col
                                      v-if="listRH.path.split('.')[1] == 'pdf'"
                                      sm="1"
                                      style="text-align: center"
                                    >
                                      <br />
                                      <i
                                        style="cursor: pointer"
                                        v-b-modal.modal-multi
                                        class="fa fa-eye fa-lg"
                                        aria-hidden="true"
                                        @click="
                                          viewFile(listRH.path);
                                          selectPPDF(indx, indx1, indx11);
                                        "
                                      ></i>

                                      <b-modal
                                        :id="
                                          'modal-multi-' +
                                          indx +
                                          '-' +
                                          indx1 +
                                          '-' +
                                          indx11
                                        "
                                        size="lg"
                                        centered
                                        scrollable
                                        hide-footer
                                        hide-backdrop
                                        content-class="shadow"
                                        :title="
                                          'Visualización de ' + listRH.text
                                        "
                                      >
                                        <v-row>
                                          <v-col>
                                            {{ currentPage }} / {{ pageCount }}
                                          </v-col>
                                        </v-row>
                                        <pdf
                                          v-for="i in numPages"
                                          :key="i"
                                          :src="fileView"
                                          :page="i"
                                          style="
                                            display: inline-block;
                                            width: 50%;
                                          "
                                          @num-pages="pageCount = $event"
                                          @page-loaded="currentPage = $event"
                                        ></pdf>
                                      </b-modal>
                                    </v-col>
                                  </v-row>
                                </b-list-group-item>
                              </b-list-group>
                            </b-modal>

                            <b-modal
                              :id="'modal-multi-listC-p-' + indx11"
                              size="lg"
                              centered
                              hide-footer
                              :title="'Crear relacion para ' + fileP.text"
                            >
                              <v-row>
                                <v-col sm="9">
                                  <v-combobox
                                    v-model="selectdateFileR"
                                    :items="dateFileR"
                                    label="Ingrese el nombre del archivo"
                                    dense
                                    persistent-hint
                                  ></v-combobox>
                                </v-col>
                                <v-col
                                  sm="3"
                                  style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                >
                                  <b-button
                                    variant="outline-info"
                                    @click="createRel(fileP.idFile)"
                                  >
                                    Generar relacion
                                  </b-button>
                                </v-col>
                              </v-row>
                            </b-modal>
                          </v-col>

                          <v-col
                            v-if="
                              fileP.path.split('.')[1] == 'png' ||
                              fileP.path.split('.')[1] == 'jpg'
                            "
                            sm="1"
                            style="text-align: center"
                          >
                            <br />
                            <i
                              style="cursor: pointer"
                              class="fa fa-eye fa-lg"
                              aria-hidden="true"
                              @click="viewFile(fileP.path)"
                            ></i>
                          </v-col>

                          <v-col
                            v-if="fileP.path.split('.')[1] == 'pdf'"
                            sm="1"
                            style="text-align: center"
                          >
                            <br />
                            <i
                              style="cursor: pointer"
                              v-b-modal.modal-multi
                              class="fa fa-eye fa-lg"
                              aria-hidden="true"
                              @click="
                                viewFile(fileP.path),
                                  selectPPDF(indx, indx1, indx11)
                              "
                            ></i>

                            <b-modal
                              :id="
                                'modal-multi-' +
                                indx +
                                '-' +
                                indx1 +
                                '-' +
                                indx11
                              "
                              size="lg"
                              centered
                              scrollable
                              hide-footer
                              hide-backdrop
                              content-class="shadow"
                              :title="'Visualización de ' + fileP.text"
                            >
                              <v-row>
                                <v-col>
                                  {{ currentPage }} / {{ pageCount }}
                                </v-col>
                              </v-row>
                              <pdf
                                v-for="i in numPages"
                                :key="i"
                                :src="fileView"
                                :page="i"
                                style="display: inline-block; width: 50%"
                                @num-pages="pageCount = $event"
                                @page-loaded="currentPage = $event"
                              ></pdf>
                            </b-modal>
                          </v-col>
                        </v-row>
                      </b-list-group-item>
                    </b-list-group>
                  </b-modal>
                </b-dropdown-form>
              </b-dropdown>

              <div style="margin: 0.5rem !important">
                <b-button
                  @click="selectUpload(indx)"
                  v-if="permitEdit || permissionCUser"
                  variant="light"
                  class="btn_drop"
                  v-b-modal.modal-no-backdrop-3
                >
                  Cargar archivo
                </b-button>
              </div>

              <b-modal
                size="lg"
                hide-footer
                :id="'modal-no-backdropU-' + indx"
                hide-backdrop
                centered
                content-class="shadow"
                :title="'Subir archivo en el punto ' + anillo.name"
              >
                <v-row style="display: flex; justify-content: flex-end">
                  <v-col cols="3">
                    <v-checkbox
                      v-if="selectedTypeServer == 'GoogleDrive'"
                      v-model="codeActive"
                      label="Asociar por codigo"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="4" style="display: flex; align-items: center">
                    <v-select
                      v-model="selectedTypeServer"
                      :items="typeServer"
                      label="Seleccione servidor destino"
                      height="25"
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-file-input
                      dense
                      height="25"
                      v-model="documentP"
                      label="Seleccionar un archivo"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      height="25"
                      dense
                      v-model="selectedTypeDocument"
                      :items="typeDocument"
                      label="Seleccione tipo de documento"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-if="selectedTypeServer == 'GoogleDrive' && !codeActive"
                    cols="12"
                  >
                    <v-row v-if="!structThreeF">
                      <v-col>
                        <v-chip class="ma-2" label>
                          <p
                            v-if="folderSelectN.length == 0"
                            style="margin-top: 0.5rem; margin-bottom: 0.5rem"
                          >
                            No se ha seleccionado carpeta
                          </p>
                          <p v-else>
                            carpeta seleccionado: {{ folderSelectN }}
                          </p>
                        </v-chip>
                        <v-btn
                          elevation="2"
                          color="#009688"
                          style="color: white"
                          fab
                          small
                          @click="structThreeF = true"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-responsive
                      v-if="structThreeF"
                      style="overflow: scroll"
                      class="overflow-y-auto"
                      max-height="250"
                    >
                      <v-treeview
                        v-model="treeTest"
                        :open="initiallyOpen"
                        :items="folderGoogle"
                        activatable
                        item-key="text"
                        item-text="text"
                      >
                        <template v-slot:prepend="{ item, open }">
                          <v-icon
                            @click="
                              selectFolderGoogle = item.id;
                              structThreeF = false;
                              folderSelectN = item.text;
                            "
                          >
                            {{ open ? "mdi-folder-open" : "mdi-folder" }}
                          </v-icon>
                        </template>
                      </v-treeview>
                    </v-responsive>
                  </v-col>
                  <v-col
                    v-if="selectedTypeServer == 'GoogleDrive' && codeActive"
                    cols="6"
                  >
                    <v-autocomplete
                      height="25"
                      dense
                      v-model="selectCode"
                      :items="listCode"
                      label="Seleccione el codigo"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <br />
                <v-row>
                  <v-col>
                    <b-button
                      style="color: white"
                      :disabled="documentP == null || selectedTypeDocument == 0"
                      variant="primary"
                      type="submit"
                      @click="uploadDocumentsP(anillo.id)"
                    >
                      Cargar archivo
                    </b-button>

                    <v-dialog
                      transition="dialog-top-transition"
                      max-width="600"
                      v-model="dialogUpCopyP4"
                    >
                      <v-card>
                        <v-card-text>
                          <div class="text-h6 pa-12">
                            El archivo que intenta cargar esta duplicado.
                          </div>
                          <p>
                            Ingrese un nombre diferente o dejar sin editar
                            asignar un consecutivo
                          </p>
                          <v-text-field
                            :label="nameFileRandom"
                            hide-details="auto"
                            v-model="mameFileUp"
                            :rules="fileRules"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            :disabled="!statusVerify"
                            color="#57B55D"
                            class="ma-2"
                            style="color: white"
                            @click="reeSendFile('Punto', anillo.id)"
                            >Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </b-modal>

              <b-dropdown
                style="display: grid"
                block
                id="dropdown-dropright"
                dropright
                text="Links"
                variant="light"
                class="m-2 drop_L"
              >
                <b-dropdown-form>
                  <b-dropdown-item-button @click="showAddLink(indx)">
                    Asociar link
                  </b-dropdown-item-button>
                  <b-modal
                    size="lg"
                    hide-footer
                    :id="'modal-add-links-' + indx"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="'Asociar link en el punto ' + anillo.name"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    "
                  >
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          v-model="refLink"
                          label="Nombre o refrencia del link"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="linkAsoc"
                          label="Link"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          v-model="selectedTypeDocument"
                          :items="typeDocument"
                          label="Seleccione tipo de documento"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-btn
                      class="ma-2"
                      color="#57B55D"
                      style="color: white;"
                      @click="setLink(anillo.id, 'point'); $bvModal.hide(`modal-add-links-${indx}`);"
                    >
                      Asociar
                    </v-btn>
                  </b-modal>

                  <b-dropdown-item-button @click="showLinks(indx); getDataLinks(anillo.id, 'point')">
                    Listar links
                  </b-dropdown-item-button>
                  <b-modal
                    size="lg"
                    hide-footer
                    :id="'modal-links-' + indx"
                    hide-backdrop
                    centered
                    content-class="shadow"
                    :title="'Lista de links asociada al punto ' + anillo.name"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                    "
                  >
                    <div v-if="listLink.length > 0" style="overflow-y: auto!important;
                      height: 320px;">
                      <v-list-item v-for="LinkP in listLink" :key="LinkP.id">
                        <v-list-item-content>
                          <v-list-item-title>Descripción: {{LinkP.titulo}}</v-list-item-title>
                          <v-list-item-subtitle>Tipo de documento: {{LinkP.tag}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-row>
                              <v-col cols="8" style="display: flex;
                                justify-content: flex-end;">
                                <v-btn
                                  class="ma-2"
                                  color="#57B55D"
                                  :href="LinkP.link" 
                                  target="popup"
                                  style="text-transform: none!important; color: white;"
                                  small
                                  @click="popupWindows(LinkP.link)"
                                >
                                  Click para visualizar
                                </v-btn>
                              </v-col>
                              <v-col cols="4" >
                                <v-btn
                                  class="ma-2"
                                  color="#F3575D"
                                  small
                                  @click="deleteLink(LinkP.id);"
                                >
                                  <i class="fa-solid fa-trash" style="color: white;"></i>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </b-modal>

                </b-dropdown-form>
              </b-dropdown>




              <div
                v-if="projectId == 1 || projectId == 2 || projectId == 4"
                style="margin: 0.5rem !important"
              >
                <b-button
                  v-on:click="goToChart(anillo.id, anillo.name)"
                  variant="light"
                  class="btn_drop"
                >
                  Ver grafica
                </b-button>
              </div>

              <div style="margin: 0.5rem !important">
                <b-button
                  v-if="permitEdit"
                  @click="$bvModal.show('bv-modal-deletePoint' + indx)"
                  variant="light"
                  class="btn_drop"
                >
                  Eliminar punto
                </b-button>
              </div>

              <b-modal centered :id="'bv-modal-deletePoint' + indx" hide-footer>
                <v-row>
                  <v-col>
                    <h6>Desea eliminar el punto?</h6>
                  </v-col>
                  <v-row>
                    <v-col>
                      <b-form-group
                        id="input-group-deleteFig"
                        label="Confirme escribiendo la palabra 'Si':"
                        label-for="input-deletePoint"
                        description="Al eliminar el punto se eliminarán los archivos adjuntos en el punto."
                      >
                        <b-form-input
                          id="input-deletePoint"
                          v-model="confirDelete"
                          placeholder=""
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-button
                        @click="
                          deletePoint(anillo.id);
                          $bvModal.hide('bv-modal-deletePoint' + indx);
                        "
                        :disabled="confirDelete != 'Si'"
                        variant="danger"
                        >Eliminar</b-button
                      >
                    </v-col>
                  </v-row>
                </v-row>
              </b-modal>
            </b-card-text>
            <template #footer>
              <v-row>
                <v-col sm="12">
                  <em> # de Archivos: {{ filesPoints.length }}</em>
                </v-col>
              </v-row>
            </template>
          </b-card>
        </l-popup>
        <l-tooltip>{{ anillo.name }}</l-tooltip>
      </l-marker>
    </l-map>

    <v-snackbar
      v-model="NotiUploadD"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se ha cargado un nuevo <strong>Archivo</strong>

      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="NotiUploadD = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="NotideleteP"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se ha eliminado el <strong>Punto</strong>

      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="NotideleteP = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="NotiCenterFijo"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se ha cargado centrado del <strong>Georreferenciado</strong>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="NotiCenterFijo = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="NotiColorFig"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se ha fijado el color del <strong>Area</strong>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="NotiColorFig = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="notiDeleteLink"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se ha eliminado el link

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="notiDeleteLink = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    
    <v-snackbar
      v-model="NotiLinkData"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se ha fijado el link al elemento

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="NotiLinkData = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="NotiAsingFig"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se ha fijado las figuras <strong>{{ nameCanvas }}</strong>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="NotiAsingFig = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="NotiFail"
      color="red accent-2"
      outlined
      timeout="5000"
      :top="true"
    >
      No se puedo realizar la accion

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red accent-2"
          text
          v-bind="attrs"
          @click="NotiFail = false"
        >
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
/*eslint-disable */
import Vue from "vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

import api from "../../../../../api/index.js";
import axios from "axios";
import marcador from "../../../../../assets/img/icons/icon_gps_safered.svg";
import marcadorGreen from "../../../../../assets/img/icons/icon_gps_safered-green.svg";
import marcadorPurple from "../../../../../assets/img/icons/icon_gps_safered-purple.svg";
import marcadorYellow from "../../../../../assets/img/icons/icon_gps_safered-yellow.svg";
import marcadorOrange from "../../../../../assets/img/icons/icon_gps_safered-orange.svg";
import marcadorRed from "../../../../../assets/img/icons/icon_gps_safered-red.svg";
import marcadorBlack from "../../../../../assets/img/icons/icon_gps_safered-black.svg";
import marcadorGrey from "../../../../../assets/img/icons/icon_gps_safered-grey.svg";
import { BIcon, BIconArrowUp, BIconArrowDown } from "bootstrap-vue";

import {
  LMap,
  LTileLayer,
  LIcon,
  LPolyline,
  LMarker,
  LPopup,
  LTooltip,
  LControl,
  LPolygon,
  LControlLayers,
} from "vue2-leaflet";

import LDraw from "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import VueDocPreview from "vue-doc-preview";
import * as turf from "@turf/turf";
import pdf from "vue-pdf";

import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BIcon,
    BIconArrowUp,
    BIconArrowDown,
    ClipLoader,
    VueDocPreview,
    LMap,
    LTileLayer,
    LIcon,
    LPolyline,
    LPolygon,
    LMarker,
    LPopup,
    LTooltip,
    LControl,
    LControlLayers,
    LDraw,
    pdf,
    VueBootstrapTypeahead,
  },
  data() {
    return {
      notiDeleteLink: false,
      NotiLinkData: false,
      NotiColorFig: false,
      NotiFail: false,
      NotiCenterFijo: false,
      NotideleteP: false,
      NotiUploadD: false,
      NotiAsingFig: false,
      color_Poly: [],
      colorEleg: "",
      showPoints: true,
      currentPoint: "",
      loadingPopover: true,
      loading: false,
      hideenLoading: true,
      documentP: null,
      documentZ: null,
      documentC: null,
      fileView: null,
      numPages: undefined,
      currentPage: 0,
      pageCount: 0,
      selectedTypeDocument: 0,
      selectedAreas: [],
      optionsAreas: [],
      confirDelete: "",
      tags: [],
      tagsListC: [],
      tagsList: [],
      tagsListG: [],
      isAdmin: false,
      zoom: 5,
      bounds: null,
      chartIs: true,
      iconSize: [15, 15],
      center: null,
      polyline: {
        type: "polyline",
        latlngs: [],
        color: "black",
        hover: false,
      },
      layer: {},
      markers: [],
      lines: [],
      dataCenter: {},
      circles: [],
      rectangles: [],
      polygons: [],
      dataCopyAnillos: [],
      anillos: {
        data: [],
        icon: [],
      },
      pointsFound: [],
      polygonsR: [],
      backup_polygonsR: [],
      filesC: [],
      filesZones: [],
      filesPoints: [],
      filesP: [],
      filesG: [],
      listRelationalDoc: {
        Padres: [],
        Hijos: [],
      },
      permitEdit: false,
      selectdateFileR: null,
      dateFileR: [],
      optionsM: {
        name: "",
      },
      map: {},
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      zoomUp: null,
      centerUp: null,
      boundsUp: null,
      token: "",
      currentUser: null,
      selectFolderGoogle: null,
      selectedTypeServer: "",
      typeServer: [
        { text: "SafeRed", value: "SafeRed" },
        { text: "GoogleDrive", value: "GoogleDrive" },
      ],
      codeActive: false,
      listCode: [],
      selectCode: null,
      structThreeF: false,
      initiallyOpen: [],
      treeTest: [],
      folderSelectN: "",
      filesCanvas: [],
      itemsAreas: [],
      itemsPuntos: [],
      permissionCUser: null,
      dialogUpCopyA: false,
      dialogUpCopyA2: false,
      fileRules: [
        (v) =>
          !/[():<>*\;"\\|.\/?~]/.test(v) ||
          "No se admiten caracteres especiales", //eslint-disable-line
      ],
      nameFile: "",
      nameFileRandom: "",
      statusVerify: true,
      mameFileUp: null,
      dialogUpCopyC: false,
      dialogUpCopyP: false,
      dialogUpCopyP2: false,
      dialogUpCopyP3: false,
      dialogUpCopyP4: false,
      dataPoint: {
        name: "",
        lat: "",
        lng: "",
        tipoSensor: "",
        sector: "",
      },
      refLink: '',
      linkAsoc: '',
      listLink: [],
    };
  },
  props: [
    "projectId",
    "typeDocument",
    "dataPoints",
    "polygonsLoaded",
    "linesLoaded",
    "idCanvas",
    "pointCenter",
    "refM",
    "refP",
    "dateFilt",
    "nameCanvas",
    "rolesActualU",
    "folderGoogle",
    "listCodeP",
    "principal",
    "dataObjectP",
    "idPrincipal",
    "dataLinks",
    "permissionC",
    "projectName",
    "dataMap",
    "dataLocation"
  ],
  async mounted() {
    this.initiallyOpen = [this.projectName];
    await this.loadPosition();
    this.currentUser = JSON.parse(localStorage.user);
    this.token = JSON.parse(localStorage.user).token;
    await this.permitSection();
    await this.formatPoints();
    this.optionsM.name = this.refM;
    await this.findIntersections();
    await this.toolbarMap();
    await this.genListCode();
    this.getpermissionUser();
    this.dataObjectPFind();
    this.findDataLocation();
  },
  methods: {
    findDataLocation: function(){
      var self = this
      var index = null

      if(this.dataLocation != null){
        if(this.dataLocation.idPoint != undefined){
          this.dataPoints.map(function(data, i){
            if(data.id == self.dataLocation.idPoint){
              index = i;
            }
          })

          var object_leaflet_marker = document.getElementsByClassName('leaflet-marker-pane')[0].childNodes
          for(let i = 0; i < object_leaflet_marker.length; i++) {
            if (index == i) {
              console.log(object_leaflet_marker[i].attributes)
              object_leaflet_marker[i].attributes[1].nodeValue = "fadeIcon leaflet-marker-icon leaflet-zoom-animated leaflet-interactive"
              object_leaflet_marker[i].attributes[0].nodeValue = marcadorRed
            }
          }
        }else{
          this.polygonsLoaded.map(function(data, i){
            if(data.id == self.dataLocation.idArea){
              index = i;
            }
          })
          if(this.linesLoaded.length > 0){
          index = index + this.linesLoaded.length
          }
          var dataResult_Poly = document.getElementsByTagName('path');
          console.log(dataResult_Poly)

          for (let i = 0; i < dataResult_Poly.length; i++) {
            if (index == i) {
              dataResult_Poly[i].attributes[1].nodeValue = "#ff0000"
              dataResult_Poly[i].attributes[0].nodeValue = "fadeIn leaflet-interactive"
            }
          }
        }
      }
    },
    popupWindows(link){
      window.open(link,'popup','width=600,height=600'); 
      return false;
    },
    getDataLinks: async function(id, type){
      var self = this
      this.listLink = []
      if(type == 'point'){
        this.dataLinks.map(function(element){
          if(element.point_id == id){
            var text_tag = "N/A"
            self.typeDocument.map(function (tag){
              if(tag.value == element.id_tag){
                text_tag = tag.text
              }
            });

            self.listLink.push({
              id: element.id,
              titulo: element.title,
              link: element.link_a,
              tag: text_tag
            })
            
          }
        })
      }else if(type == 'area'){
        console.log(this.dataLinks)
        this.dataLinks.map(function(element){
          if(element.area_id == id){
            var text_tag = "N/A"
            self.typeDocument.map(function (tag){
              if(tag.value == element.id_tag){
                text_tag = tag.text
              }
            });

            self.listLink.push({
              id: element.id,
              titulo: element.title,
              link: element.link_a,
              tag: text_tag
            })
            
          }
        })
      }else{
        this.dataLinks.map(function(element){
          if(element.canvas_id == self.idPrincipal){
            var text_tag = "N/A"
            self.typeDocument.map(function (tag){
              if(tag.value == element.id_tag){
                text_tag = tag.text
              }
            });

            self.listLink.push({
              id: element.id,
              titulo: element.title,
              link: element.link_a,
              tag: text_tag
            })
            
          }
        })
      }
    },
    setLink: async function(id, type){
      var self = this
      if(type == "point"){
        var obj = {
          title: this.refLink,
          link_a: this.linkAsoc,
          user_id: JSON.parse(localStorage.user).id,
          project_id: this.projectId,
          point_id: id,
          id_tag: this.selectedTypeDocument
        }
      }else if(type == "area"){
        obj = {
          title: this.refLink,
          link_a: this.linkAsoc,
          user_id: JSON.parse(localStorage.user).id,
          project_id: this.projectId,
          area_id: id,
          id_tag: this.selectedTypeDocument
        }
      }else{
        obj = {
          title: this.refLink,
          link_a: this.linkAsoc,
          user_id: JSON.parse(localStorage.user).id,
          project_id: this.projectId,
          canvas_id: this.idPrincipal,
          id_tag: this.selectedTypeDocument
        }
      }

      await axios
      .post("https://api.safered.co/api/setLinksInElement", obj, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
          console.log(response)
          var refr = self.$store.state.refreshDataGeo + 1;
          self.$store.commit("setRefreshDataGeo", refr);

          self.refLink = '';
          self.linkAsoc = '';
          self.selectedTypeDocument = '';
          self.NotiLinkData = true;      
      });
    },
    deleteLink: async function(id){
      var self = this;
      await axios
      .delete("https://api.safered.co/api/deleteLInksById/"+id, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
          console.log(response)
          var refr = self.$store.state.refreshDataGeo + 1;
          self.$store.commit("setRefreshDataGeo", refr);
          
          self.notiDeleteLink = true; 
      })
      .catch((e) => console.log(e));
    },
    dataObjectPFind() {
      var self = this;
      console.log(self.dataObjectP.areas);
      
      this.polygonsLoaded.map(function (obj) {
        var result = self.dataObjectP.areas.find(
          (element) => element.id == obj.id
        );
        self.itemsAreas.push(result);
      });

      this.dataPoints.map(function (obj) {
        var resultP = self.dataObjectP.puntos.find(
          (element) => element.id == obj.id
        );
        self.itemsPuntos.push(resultP);
      });
    },
    getpermissionUser() {
      var perm = null;
      perm = this.permissionC ?  1 : 0;

      if (this.permitEdit == false && perm == 0) {
        this.permissionCUser = false;
      } else if (this.permitEdit == false && perm == 1) {
        this.permissionCUser = true;
      } else {
        this.permissionCUser = true;
      }
    },
    genListCode() {
      var self = this;

      if (this.listCodeP != null) {
        this.listCodeP.map(function (codeP) {
          self.listCode.push({
            text: codeP.code,
            value: codeP.idFolder,
          });
        });
      }
    },
    getColor(e) {
      this.colorEleg = e;
      console.log(e);
    },
    saveColorC: async function (id) {
      var self = this;
      var obj = {
        idPoint: id,
        color: self.colorEleg,
      };
      await api
        .request("post", "/updateBackColorArea", obj)
        .then(function (response) {
          console.log(response);

          var refr = self.$store.state.refreshDrawGeo + 1;
          self.$store.commit("setRefreshDrawGeo", refr);
          self.NotiColorFig = true;
        })
        .catch(function (error) {
          console.log(error);
          self.NotiFail = true;
        });
    },
    zoomUpdated(zoom) {
      this.zoomUp = zoom;
    },
    centerUpdated(center) {
      this.centerUp = center;
    },
    boundsUpdated(bounds) {
      this.boundsUp = bounds;
    },
    savePosition: async function () {
      var self = this;
      var zoom = this.zoomUp;
      var center = this.centerUp;
      var bound = this.boundsUp;
      var positionArr = {
        project_id: parseInt(this.projectId),
        user_id: JSON.parse(window.localStorage.user).id,
        canvas_id: this.idCanvas,
        zoom: zoom,
        center: [center.lat.toString(), center.lng.toString()],
        bound: {
          _northEast: {
            lat: bound._northEast.lat.toString(),
            lng: bound._northEast.lng.toString(),
          },
          _southWest: {
            lat: bound._southWest.lat.toString(),
            lng: bound._southWest.lng.toString(),
          },
        },
      };
      await api
        .request("post", "/updateZoomCenter", positionArr)
        .then(function (response) {
          self.NotiCenterFijo = true;
        })
        .catch(function (error) {
          self.NotiFail = true;
        });
    },
    loadPosition: async function () {
      var positionGet = {
        project_id: parseInt(this.projectId),
        user_id: JSON.parse(window.localStorage.user).id,
        canvas_id: this.idCanvas,
      };
      var dataC = null;
      await api
        .request("post", "/getZoomCenter", positionGet)
        .then(function (response) {
          dataC = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      if (dataC.length != undefined) {
        this.dataCenter = dataC[0];
      } else {
        this.dataCenter = null;
      }
      await this.datosCenter();
    },
    ocultarPoints() {
      if (this.showPoints == true) {
        this.showPoints = false;
        this.anillos.data = [];
      } else {
        this.showPoints = true;
        this.anillos.data = this.dataCopyAnillos;
      }
    },
    permitSection: async function () {
      var groups = JSON.parse(window.localStorage.user).groups;
      var statusE = false;
      var self = this;

      if (groups != "NaN") {
        groups.map(function (arr) {
          self.rolesActualU.map(function (arr2) {
            if (arr2.project_id == self.projectId) {
              if (arr2.role_id == 2) {
                statusE = false;
              } else if (arr2.role_id == 4) {
                statusE = true;
              }
            } else {
              if (arr.idProject == self.projectId) {
                if (arr.idRole == 2) {
                  statusE = false;
                } else if (arr.idRole) {
                  statusE = true;
                }
              }
            }
          });
        });
      } else {
        self.rolesActualU.map(function (arr) {
          if (arr.project_id == self.projectId) {
            if (arr.role_id == 2) {
              statusE = false;
            } else if (arr.role_id == 4) {
              statusE = true;
            }
          }
        });
      }

      if (JSON.parse(window.localStorage.user).isAdmin == true || statusE) {
        this.permitEdit = true;
      } else {
        this.permitEdit = false;
      }
      console.log(this.permitEdit)
    },
    foo(fileURL) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [fileURL],
        isEnableBlurBackground: false,
        isEnableLoopToggle: true,
        initViewMode: "contain",
        containScale: 1,
        shirnkAndEnlargeDeltaRatio: 0.2,
        wheelScrollDeltaRatio: 0.2,
        isEnableImagePageIndicator: true,
        maskBackgroundColor: "rgba(0,0,0,0.6)",
        zIndex: 4000,
        isEnableDownloadImage: true,
      });
    },
    formatPoints: async function () {
      if (this.dataPoints.length > 0) {
        this.dataPoints.forEach((element) => {
          let coordenadaArray = element.UbigacionGPS.replace(/"/g, "")
            .replace(/[[\]]/g, "")
            .split(",");
          let marcador_var = null;

          if (element.Status == null || element.Status == "Sin Alerta") {
            if (element.TipoSensor == "Piezometro Casagrande") {
              marcador_var = marcador;
            } else if (element.TipoSensor == "Inclinometro") {
              marcador_var = marcadorPurple;
            } else if (element.TipoSensor == "Piezometro Hilo Vibratil") {
              marcador_var = marcadorGreen;
            } else {
              marcador_var = marcadorGreen;
            }
          } else if (element.Status != null || element.Status != "Sin Alerta") {
            if (element.Status == "Alerta Amarilla") {
              marcador_var = marcadorYellow;
            } else if (element.Status == "Alerta Naranja") {
              marcador_var = marcadorOrange;
            } else if (element.Status == "Alerta Roja") {
              marcador_var = marcadorRed;
            } else if (element.Status == "Alerta Negra") {
              marcador_var = marcadorBlack;
            } else if (element.Status == "Equipo Dañado") {
              marcador_var = marcadorGrey;
            }
          }

          var dato = {
            name: element.Codigo,
            position: coordenadaArray,
            tipoSensor: element.TipoSensor,
            grupo: element.UbigacionCartesiana,
            id: element.id,
            status: element.Status,
            marcador_var: marcador_var,
          };

          this.anillos.data.push(dato);
          this.dataCopyAnillos.push(dato);
          this.$store.commit("setDataPoint", this.anillos.data);

          this.polyline.latlngs.push(coordenadaArray);
        });
        let mitad = Math.floor(this.polyline.latlngs.length / 2);
        console.log(mitad);
        // this.center = this.polyline.latlngs[mitad]
      } else {
        console.log("datapoints is null...");
      }
    },
    viewFile: async function (pathFile) {
      var nameFile = pathFile.split("/")[2];

      await axios({
        url: "https://api.safered.co/api/download-file/" + nameFile,
        method: "POST",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        if (
          pathFile.split(".")[1] == "xlsx" ||
          pathFile.split(".")[1] == "docs" ||
          pathFile.split(".")[1] == "pptx"
        ) {
          this.fileView =
            "https://safe-net.co/informe/INFORME%20SAFE%20NET%20-%20OCENSA%20SUBMARINO.xlsx";
        } else if (pathFile.split(".")[1] == "pdf") {
          this.fileView = pdf.createLoadingTask(fileURL);
        } else if (
          pathFile.split(".")[1] == "png" ||
          pathFile.split(".")[1] == "jpg"
        ) {
          this.foo(fileURL);
        }
      });
    },
    downloadFile: async function (pathFile, title) {
      var isSafe = pathFile.includes("/");

      if (isSafe) {
        this.hideenLoading = true;
        this.loading = false;
        var nameFile = pathFile.split("/")[2];

        await axios({
          url: "https://api.safered.co/api/download-file/" + nameFile,
          method: "POST",
          responseType: "blob",
        }).then((response) => {
          console.log(response);
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", title);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
        this.hideenLoading = true;
        this.loading = false;
      } else {
        var result = {};
        var obj = {
          idFile: pathFile,
          idProject: this.projectId,
        };
        const config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };

        await axios
          .post(
            "https://microservices.safered.co/api/downloadFile",
            obj,
            config,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => (result = res.data))
          .catch((e) => console.log(e));

        const base64 = await fetch(result.FileB64);
        const blob = await base64.blob();

        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", result.NameFile);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    chart: function (anillo) {
      this.$router.push({
        path: "Chart",
        query: { plan: anillo.name },
      });
    },
    download: function (anillo) {
      if (anillo) {
        this.$router.push({
          path: "Profile",
          query: { plan: anillo.name },
        });
      }
    },
    rainbow(numOfSteps, step) {
      // This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
      // Adam Cole, 2011-Sept-14
      // HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
      var r, g, b;
      var h = step / numOfSteps;
      var i = ~~(h * 6);
      var f = h * 6 - i;
      var q = 1 - f;
      switch (i % 6) {
        case 0:
          r = 1;
          g = f;
          b = 0;
          break;
        case 1:
          r = q;
          g = 1;
          b = 0;
          break;
        case 2:
          r = 0;
          g = 1;
          b = f;
          break;
        case 3:
          r = 0;
          g = q;
          b = 1;
          break;
        case 4:
          r = f;
          g = 0;
          b = 1;
          break;
        case 5:
          r = 1;
          g = 0;
          b = q;
          break;
      }
      var c =
        "#" +
        ("00" + (~~(r * 255)).toString(16)).slice(-2) +
        ("00" + (~~(g * 255)).toString(16)).slice(-2) +
        ("00" + (~~(b * 255)).toString(16)).slice(-2);
      return c;
    },
    recenterMap() {
      this.$refs[this.refM].mapObject.panTo(this.center);
      this.$refs[this.refM].mapObject._zoom = 12;
    },
    goToChart(idChart, namePoint) {
      let routeData = this.$router.resolve({
        path:
          `/app/` +
          this.projectId +
          `/chart/` +
          idChart +
          `/` +
          namePoint +
          ".sfr",
      });
      window.open(routeData.href, "_blank");
    },
    findIntersections() {
      var polygonsPointN = [];
      var temp = [];
      var self = this;

      this.polygonsLoaded.map(function (polygonsL) {
        polygonsL.points.map(function (points) {
          temp.push([points[0], points[1]]);
        });
        temp.push([polygonsL.points[0][0], polygonsL.points[0][1]]);
        polygonsPointN.push(Array.from(temp));
        temp = [];
      });

      var pointCoordN = [];
      var pointCoordName = [];

      this.anillos.data.map(function (anillo) {
        let lati = Number(anillo.position[0]);
        let long = Number(anillo.position[1]);
        var name = anillo.name;
        pointCoordN.push([lati, long]);
        pointCoordName.push({
          name: name,
          coord: [lati, long],
        });
      });

      var points = turf.points(pointCoordN);

      polygonsPointN.map(function (polygonsP) {
        temp.push(turf.polygon([polygonsP]));
      });

      temp.map(function (tem) {
        self.pointsFound.push(turf.pointsWithinPolygon(points, tem));
      });
    },
    foundNamesPoints() {
      this.polygonsR = [];
      var namePointsS = [];
      var temp = [];
      var tempD = [];
      var nameFound = [];
      var pointsData = [];
      var self = this;

      this.pointsFound.map(function (element) {
        element.features.map(function (element2) {
          temp.push(
            Array.from([
              element2.geometry.coordinates[0].toString(),
              element2.geometry.coordinates[1].toString(),
            ])
          );
        });
        namePointsS.push(temp);
        temp = [];
      });

      namePointsS.map(function (element) {
        element.map(function (element2) {
          self.anillos.data.map(function (element3) {
            if (
              element3.position[0] == element2[0] ||
              element3.position[1] == element2[1]
            ) {
              temp.push([element3.name]);
              tempD.push(element3);
            }
          });
        });
        nameFound.push(temp);
        pointsData.push(tempD);
        tempD = [];
        temp = [];
      });

      this.polygonsLoaded.map(function (element, indice) {
        self.polygonsR.push({
          name: element.name,
          id: element.id,
          points: element.points,
          colorBack: element.colorBack,
          fillColor: element.fillColor,
          pointsD: pointsData[indice],
          namePoints: nameFound[indice],
          numberPoints: nameFound[indice].length,
        });
      });

      this.polygonsLoaded.map(function (element) {
        self.color_Poly.push({
          background: element.fillColor,
          width: "20px",
          height: "10px",
          margin: "7px 1px 8px 7px",
        });
      });

      this.backup_polygonsR = this.polygonsR;
      this.$store.commit("setDataFindPoint", this.polygonsR);
    },
    listFileC: async function () {
      this.filesCanvas = [];
      var self = this;
      var dataFileC = [];
      dataFileC.push(this.dataMap[0]);
      dataFileC[0].children.map(function (obj) {
        if (obj.path != undefined) {
          self.filesCanvas.push(obj);
        }
      });

      await this.listForTagC();
    },
    listForTagC: async function () {
      var data = [];

      await this.filesCanvas.map(function (element) {
        if (element.tag != undefined) {
          data.push(element.tag);
        }
      });

      const result = data.filter(function (ele, pos) {
        return data.indexOf(ele) == pos;
      });
      this.tagsListC = result;
    },
    ordenarForTagC(tagsLC) {
      var tempFiles = this.filesCanvas;
      this.filesC = [];

      function filtTags(tagL) {
        return tagL.tag == tagsLC;
      }

      this.filesC = tempFiles.filter(filtTags);
    },
    listZonaF: async function (nameZ) {
      this.filesZones = [];
      var dataMap = [];
      dataMap.push(this.dataMap);

      function areaS(parent) {
        return parent.text == nameZ;
      }
      
      console.log(dataMap)
      var dataF = dataMap[0][0].children.find(areaS);

      var self = this;
      await dataF.children.map(function (element) {
        if (element != null) {
          if (element.tag != undefined) {
            var hijos = [];
            var padres = [];

            if (element.relacion != null) {
              if (element.relacion.Hijos.length > 0) {
                element.relacion.Hijos.map(function (elementRH) {
                  const d1 = new Date(elementRH.created_at);
                  var tmp =
                    d1.getFullYear() +
                    "-" +
                    (d1.getMonth() + 1) +
                    "-" +
                    d1.getDate() +
                    " " +
                    d1.getHours() +
                    ":" +
                    d1.getMinutes();

                  hijos.push({
                    created_at: tmp,
                    ubicacion: elementRH.ubicacion,
                    name_user: elementRH.name_user,
                    path: elementRH.path,
                    text: elementRH.title,
                    tag: elementRH.tag_name,
                    idFile: elementRH.id,
                  });
                });
              }

              if (element.relacion.Padres.length > 0) {
                element.relacion.Padres.map(function (elementR) {
                  const d1 = new Date(elementR.created_at);
                  var tmp =
                    d1.getFullYear() +
                    "-" +
                    (d1.getMonth() + 1) +
                    "-" +
                    d1.getDate() +
                    " " +
                    d1.getHours() +
                    ":" +
                    d1.getMinutes();

                  padres.push({
                    created_at: tmp,
                    ubicacion: elementR.ubicacion,
                    name_user: elementR.name_user,
                    path: elementR.path,
                    text: elementR.title,
                    tag: elementR.tag_name,
                    idFile: elementR.id,
                  });
                });
              }
            }

            const d1 = new Date(element.created_at);
            var tmp =
              d1.getFullYear() +
              "-" +
              (d1.getMonth() + 1) +
              "-" +
              d1.getDate() +
              " " +
              d1.getHours() +
              ":" +
              d1.getMinutes();

            self.filesZones.push({
              created_at: tmp,
              nameArea: element.parent,
              path: element.path,
              text: element.text,
              tag: element.tag,
              idFile: element.idFile,
              relacion: {
                Padres: padres,
                Hijos: hijos,
              },
            });
          }
        }
      });

      console.log(self.filesZones);
      await this.listForTagG();
    },
    listForTagG: async function () {
      var data = [];

      await this.filesZones.map(function (element) {
        if (element.tag != undefined) {
          data.push(element.tag);
        }
      });

      const result = data.filter(function (ele, pos) {
        return data.indexOf(ele) == pos;
      });
      this.tagsListG = result;
    },
    selectG(index, index1) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-tag-Area-" + index + "-" + index1,
        "#focusThisOnClose"
      );
    },
    selectGC(index, index1) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-tag-AreaC-" + index + "-" + index1,
        "#focusThisOnClose"
      );
    },
    ordenarForTagG(tagsLG) {
      var tempFiles = this.filesZones;
      this.filesG = [];

      function filtTags(tagL) {
        return tagL.tag == tagsLG;
      }

      this.filesG = tempFiles.filter(filtTags);
    },
    listPointF(nameP) {
      var self = this;
      this.filesPoints = [];

      this.dateFilt.map(function (element) {
        if (element.ubicacion == nameP) {
          var tmp = new Date(element.created_at);

          tmp =
            tmp.getFullYear() +
            "-" +
            (tmp.getMonth() + 1) +
            "-" +
            tmp.getDate() +
            " " +
            tmp.getHours() +
            ":" +
            tmp.getMinutes();
          self.filesPoints.push({
            created_at: tmp,
            ubicacion: element.ubicacion,
            path: element.path,
            text: element.title,
            tag: element.tag,
            idFile: element.idFile,
            relacion: element.relacion,
          });
        }
      });
      this.listForTag();
    },
    listForTag() {
      var data = [];

      this.filesPoints.map(function (element) {
        data.push(element.tag);
      });

      const result = data.filter(function (ele, pos) {
        return data.indexOf(ele) == pos;
      });
      this.tagsList = result;
    },
    showAddLinkA(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-add-linksA-" + index,
        "#focusThisOnClose"
      );
    },
    showAddLink(indx) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-add-links-" + indx,
        "#focusThisOnClose"
      );
    },
    showLinks(indx){
      this.$root.$emit(
        "bv::show::modal",
        "modal-links-" + indx,
        "#focusThisOnClose"
      );
    },
    showLinksA(index){
      this.$root.$emit(
        "bv::show::modal",
        "modal-linksA-" + index,
        "#focusThisOnClose"
      );
    },
    selectP(index, index1) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-no-backdrop-" + index + "-" + index1,
        "#focusThisOnClose"
      );
    },
    selectCP(index, index1) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-no-backdropC-" + index + "-" + index1,
        "#focusThisOnClose"
      );
    },
    selectPA(indx, index, index1) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-no-backdropA-" + indx + "-" + index + "-" + index1,
        "#focusThisOnClose"
      );
    },
    selectAPDF(index, index11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multiA-" + index + "-" + index11,
        "#focusThisOnClose"
      );
    },
    selectPPDF(index, index11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multiP-" + index + "-" + index11,
        "#focusThisOnClose"
      );
    },
    selectHPDF(index, index11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multiP-" + index + "-" + index11,
        "#focusThisOnClose"
      );
    },
    selectListR(index11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multi-list-" + index11,
        "#focusThisOnClose"
      );
    },
    selectListRH(index11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multi-listH-" + index11,
        "#focusThisOnClose"
      );
    },
    selectListRC(index, index11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multi-listC-" + index + "-" + index11,
        "#focusThisOnClose"
      );
    },
    selectListRCA(index, index11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multi-listCA-" + index + "-" + index11,
        "#focusThisOnClose"
      );
    },
    selectListRP(indx11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multi-list-p-" + indx11,
        "#focusThisOnClose"
      );
    },
    selectListRHP(indx11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multi-listH-p-" + indx11,
        "#focusThisOnClose"
      );
    },
    selectListRCP(indx11) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-multi-listC-p-" + indx11,
        "#focusThisOnClose"
      );
    },
    selectPopAreaPoint() {
      this.$root.$emit(
        "bv::show::popover",
        "popover-AreandPoint",
        "#focusThisOnClose"
      );
    },
    selectPop(index, index1, index11) {
      this.$root.$emit(
        "bv::show::popover",
        "popover-button-variant" + index + "-" + index11,
        "#focusThisOnClose"
      );
    },
    selectPopC(inde, inde1) {
      this.$root.$emit(
        "bv::show::popover",
        "popover-button-variant" + inde + "-" + inde1,
        "#focusThisOnClose"
      );
    },
    selectPopP(indx, indx1, indx11) {
      this.$root.$emit(
        "bv::show::popover",
        "popover-relacion-item" + indx + "-" + indx1 + "-" + indx11,
        "#focusThisOnClose"
      );
    },
    selectUpload(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-no-backdropU-" + index,
        "#focusThisOnClose"
      );
    },
    selectAUpload(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-no-backdropAU-" + index,
        "#focusThisOnClose"
      );
    },
    selectCUpload(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-no-backdropCU-" + index,
        "#focusThisOnClose"
      );
    },
    selectUploadZ(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-uploadZone-" + index,
        "#focusThisOnClose"
      );
    },
    selectUploadZC(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-uploadZoneC-" + index,
        "#focusThisOnClose"
      );
    },
    selectCtoArea(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-canvasD-Area-" + index,
        "#focusThisOnClose"
      );
    },
    selectCtoPoint(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-canvasD-Point-" + index,
        "#focusThisOnClose"
      );
    },
    selectAtoPoint(indx, index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-AreaD-Point-" + indx + "-" + index,
        "#focusThisOnClose"
      );
    },
    selectCAtoPoint(indx, index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-AreaCD-Point-" + indx + "-" + index,
        "#focusThisOnClose"
      );
    },
    ordenarForTag: async function (tagsL) {
      var tempFiles = this.filesPoints;
      this.filesP = [];

      function filtTags(tags) {
        return tags.tag == tagsL;
      }

      this.filesP = await tempFiles.filter(filtTags);
    },
    toolbarMap() {
      if (this.permitEdit && this.principal == 0) {
        // Leaflet Map Object
        this.$nextTick(() => {
          var customMarker = window.L.icon({
            iconUrl: marcadorGreen,
            iconSize: [18, 18],
            iconAnchor: [5, 4],
          });

          this.map = this.$refs[this.refM].mapObject;

          // Tell leaflet that the map is exactly as big as the image
          this.map.setMaxBounds(this.bounds);
          // The view model from Vue Data used in JS
          // This works, since wm refers to your view model.
          let vm = this;
          // Leaflet Draw
          // Add new FeatureGroup from leaflet for Draw objects on map
          const featureGroup = new window.L.featureGroup().addTo(this.map);
          // Create leaflet draw control menu

          // Set the button title text for the polygon button
          window.L.drawLocal.draw.toolbar.buttons.polyline = "Dibujar línea";
          // Set the tooltip start text for the rectangle
          window.L.drawLocal.draw.handlers.polyline.tooltip.start =
            "Haga clic para fijar punto inicial de la línea";
          window.L.drawLocal.draw.handlers.polyline.tooltip.cont =
            "Haga clic para continuar dibujando su línea";
          window.L.drawLocal.draw.handlers.polyline.tooltip.end =
            "Haga clic en el último punto para finalizar la línea.";

          // Set the button title text for the polygon button
          window.L.drawLocal.draw.toolbar.buttons.polygon = "Dibujar polígono";
          // Set the tooltip start text for the rectangle
          window.L.drawLocal.draw.handlers.polygon.tooltip.start =
            "Haga clic para fijar punto inicial del polígono";
          window.L.drawLocal.draw.handlers.polygon.tooltip.cont =
            "Haga clic para continuar dibujando el poligono";
          window.L.drawLocal.draw.handlers.polygon.tooltip.end =
            "Haga clic en el primer punto para cerrar esta forma.";

          // Set the button title text for the polygon button
          window.L.drawLocal.draw.toolbar.buttons.rectangle =
            "Dibujar rectangulo";
          window.L.drawLocal.draw.toolbar.buttons.circle = "Dibujar circulo";
          window.L.drawLocal.draw.toolbar.buttons.marker = "Dibujar marcador";
          // Set the tooltip start text for the rectangle
          window.L.drawLocal.draw.handlers.rectangle.tooltip.start =
            "Haga clic y arrastre para dibujar un rectángulo";
          window.L.drawLocal.draw.handlers.marker.tooltip.start =
            "Haga clic en el mapa para colocar el marcador.";
          // window.L.drawLocal.draw.handlers.circle.tooltip.start =
          ("Haga clic y arrastre para dibujar un circulo");
          window.L.drawLocal.draw.handlers.simpleshape.tooltip.end =
            "Suelte el mouse para terminar de dibujar.";

          window.L.drawLocal.draw.toolbar.actions.title = "Cancelar dibujo";
          window.L.drawLocal.draw.toolbar.actions.text = "Cancelar";

          window.L.drawLocal.draw.toolbar.finish.title = "Terminar de dibujar";
          window.L.drawLocal.draw.toolbar.finish.text = "Terminar";

          window.L.drawLocal.draw.toolbar.undo.title =
            "Eliminar el último punto dibujado";
          window.L.drawLocal.draw.toolbar.undo.text =
            "Eliminar el último punto";

          const drawControl = new window.L.Control.Draw({
            position: "topleft",
            draw: {
              polyline: {
                allowIntersection: true, // Restricts shapes to simple polygons
                drawError: {
                  color: "orange",
                  timeout: 2000,
                  message: "<strong>Nicht erlauben<strong>",
                },
                showArea: true,
                metric: true, //m2
                repeatMode: false,
                zIndexOffset: -10000,
                shapeOptions: {
                  polylineID: true,
                  customArrow: true,
                  color: "#000000",
                  weight: 1,
                  lineCap: "round",
                  lineJoin: "bevel",
                  dashArray: "",
                  opacity: 1,
                },
              },
              polygon: {
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                  color: "orange",
                  timeout: 2000,
                  message: "<strong>Nicht erlauben<strong>",
                },
                showArea: true,
                metric: true, //m2
                repeatMode: false,
                shapeOptions: {
                  polylineID: false,
                  color: "#000000",
                  fillColor: "#009688",
                  weight: 1,
                  fillOpacity: 0.7,
                  lineCap: "round",
                  lineJoin: "bevel",
                  dashArray: "",
                  opacity: 1,
                },
              },
              rectangle: {
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                  color: "orange",
                  timeout: 2000,
                  message: "<strong>Nicht erlauben<strong>",
                },
                showArea: true,
                metric: true, //m2
                repeatMode: false,
                shapeOptions: {
                  polylineID: false,
                  color: "#000000",
                  fillColor: "#CDDC39",
                  weight: 1,
                  fillOpacity: 0.7,
                  lineCap: "round",
                  lineJoin: "bevel",
                  dashArray: "",
                  opacity: 1,
                },
              },
              // circle: {
              //   allowIntersection: false,
              //   showArea: true,
              //   metric: true, //m2
              //   showRadius: true,
              //   repeatMode: false,
              //   shapeOptions: {
              //     polylineID: false,
              //     color: "#000000",
              //     fillColor: "#E040FB",
              //     weight: 1,
              //     fillOpacity: 0.7,
              //     lineCap: "round",
              //     lineJoin: "bevel",
              //     dashArray: "",
              //     opacity: 1,
              //   },
              // },
              marker: {
                icon: customMarker,
              },
              circlemarker: false,
              circle: false,
            },
          });
          // Add all draw shapes on the map
          this.map.addControl(drawControl);

          const editableLayers = new window.L.featureGroup().addTo(this.map);
          this.map.on(window.L.Draw.Event.CREATED, (e) => {
            if (e.layerType === "marker") {
              var name = prompt("Asigne el nombre a este marcador:", "");
              var type = prompt("Tipo:", "");
              var sector = prompt("Sector:", "");
              var objTemp = {
                lat: e.layer.getLatLng().lat,
                lng: e.layer.getLatLng().lng,
                radius: "",
                name: name,
                TipoSensor: type,
                sector: sector,
              };
              this.markers.push(objTemp);
              this.saveForms();
            }

            if (e.layerType === "polyline") {
              var name = prompt("Asigne el nombre a esta línea: ", "");
              var objTemp = {
                points:
                  "[" +
                  e.layer
                    .getLatLngs()
                    .toString()
                    .replaceAll("LatLng(", "[")
                    .replaceAll(")", "]") +
                  "]",
                radius: "",
                name: name,
                type: "polyline",
              };

              this.lines.push(objTemp);
              this.saveForms();
            }

            // if (e.layerType === "circle") {
            //   var name = prompt("Asigne el nombre a este circulo: ", "");
            //   var objTemp = {
            //     points: e.layer
            //       .getLatLng()
            //       .toString()
            //       .replaceAll("LatLng(", "[[")
            //       .replaceAll(")", "]]"),
            //     radius: e.layer.getRadius(),
            //     name: name,
            //     type: "circle",
            //     idProject: this.$route.params.idProject,
            //   };
            //   this.circles.push(objTemp);
            // }

            if (e.layerType === "rectangle") {
              var name = prompt("Asigne el nombre a este rectángulo: ", "");
              var objTemp = {
                points:
                  "[" +
                  e.layer
                    .getLatLngs()
                    .toString()
                    .replaceAll("LatLng(", "[")
                    .replaceAll(")", "]") +
                  "]",
                radius: "",
                name: name,
                type: "rectangle",
              };
              this.rectangles.push(objTemp);
              this.saveForms();
            }

            if (e.layerType === "polygon") {
              var name = prompt("Asigne el nombre a este polígono: ", "");
              var objTemp = {
                points:
                  "[" +
                  e.layer
                    .getLatLngs()
                    .toString()
                    .replaceAll("LatLng(", "[")
                    .replaceAll(")", "]") +
                  "]",
                radius: "",
                name: name,
                type: "polygon",
              };

              this.polygons.push(objTemp);
              this.saveForms();
            }

            this.layer = e.layer;

            // Do whatever else you need to. (save to db, add to map etc)
            editableLayers.addLayer(this.layer);
          });
        });
      }
    },
    createPoint: async function () {
      var element = this.dataPoint;
      var self = this;
      var arrayPoints = {
        idUser: JSON.parse(localStorage.user).id,
        IdCanvas: this.idCanvas,
        IdProyecto: this.projectId,
        data: [],
      };

      arrayPoints.data.push({
        Codigo: element.name,
        TipoSensor: element.tipoSensor,
        Unidad: "NA",
        Grupo: "NA",
        UbicacionGPS: "[" + element.lat + "," + element.lng + "]",
        UbicacionCartesiana: element.sector,
        AreaMpBits: "NA",
      });

      await axios
        .post("https://api.safered.co/api/uploadSensors", arrayPoints, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (data) {
          console.log("Puntos cargados...");
          self.$bvModal.hide("modal-inputCoordenadas");
        })
        .catch(function (e) {
          console.log(e);
        });

      this.markers = null;

      var refr = this.$store.state.refreshDrawGeo + 1;
      this.$store.commit("setRefreshDrawGeo", refr);
    },
    saveForms: async function () {
      var forms = {
        idUser: JSON.parse(localStorage.user).id,
        IdCanvas: this.idCanvas,
        IdProyecto: this.projectId,
        data: [],
      };

      if (this.markers != null) {
        if (this.markers.length > 0) {
          var arrayPoints = {
            idUser: JSON.parse(localStorage.user).id,
            IdCanvas: this.idCanvas,
            IdProyecto: this.projectId,
            data: [],
          };
          this.markers.forEach((element) => {
            arrayPoints.data.push({
              Codigo: element.name,
              TipoSensor: element.TipoSensor,
              Unidad: "NA",
              Grupo: "NA",
              UbicacionGPS: "[" + element.lat + "," + element.lng + "]",
              UbicacionCartesiana: element.sector,
              AreaMpBits: "NA",
            });
          });
          await axios
            .post("https://api.safered.co/api/uploadSensors", arrayPoints, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then(function (data) {
              console.log("Puntos cargados...");
            })
            .catch(function (e) {
              console.log(e);
            });

          this.markers = null;

          var refr = this.$store.state.refreshDrawGeo + 1;
          this.$store.commit("setRefreshDrawGeo", refr);
        }
      }

      this.lines.forEach((element) => {
        forms.data.push(element);
      });
      this.lines = [];
      this.circles.forEach((element) => {
        forms.data.push(element);
      });
      this.circles = [];
      this.rectangles.forEach((element) => {
        forms.data.push(element);
      });
      this.rectangles = [];
      this.polygons.forEach((element) => {
        forms.data.push(element);
      });
      this.polygons = [];

      if (forms.data.length > 0) {
        await api
          .request("post", "/addFigure", forms)
          .then(function (data) {
            console.log("Figuras cargados...");
          })
          .catch(function (e) {
            console.log(e);
          });

        forms = [];

        var refr = this.$store.state.refreshDrawGeo + 1;
        this.$store.commit("setRefreshDrawGeo", refr);
      }
    },
    registerLog: async function (sustantivo) {
      var obj = {
        tipo: "EDICIÓN",
        verbo: "UPLOAD",
        sustantivo,
        idUser: JSON.parse(localStorage.user).id,
        idProject: this.projectId,
        typeCanvas: "C_GEO",
        idCanvas: this.idPrincipal,
      };

      await axios
        .post("https://api.safered.co/api/setLog", obj, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (data) {
          console.log(data);
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    reeSendFile: async function (type, idObject) {
      var dataFile = null;
      var f = null;

      var self = this;
      let formData = new FormData();

      if (this.selectCode != null) {
        formData.append("folderId", this.selectCode);
      } else {
        formData.append("folderId", this.selectFolderGoogle);
      }

      if (type == "Punto") {
        var nameF = this.documentP.name.split(".");

        if (this.mameFile == nameF[0] || this.mameFileUp == null) {
          f = new File([this.documentP], `${this.nameFileRandom}.${nameF[1]}`, {
            type: this.documentP.type,
            lastModified: Date(),
          });
          this.documentP = f;
        } else {
          f = new File([this.documentP], `${this.mameFileUp}.${nameF[1]}`, {
            type: this.documentP.type,
            lastModified: Date(),
          });
          this.documentP = f;
        }

        formData.append("mimeType", await this.documentP.type);
        formData.append("idProject", this.projectId);
        formData.append("sampleFile", this.documentP);
      } else if (type == "Area") {
        var nameF = this.documentZ.name.split(".");

        if (this.mameFile == nameF[0] || this.mameFileUp == null) {
          f = new File([this.documentZ], `${this.nameFileRandom}.${nameF[1]}`, {
            type: this.documentZ.type,
            lastModified: Date(),
          });
          this.documentZ = f;
        } else {
          f = new File([this.documentZ], `${this.mameFileUp}.${nameF[1]}`, {
            type: this.documentZ.type,
            lastModified: Date(),
          });
          this.documentZ = f;
        }

        formData.append("mimeType", await this.documentZ.type);
        formData.append("idProject", this.projectId);
        formData.append("sampleFile", this.documentZ);
      } else if (type == "Canva") {
        var nameF = this.documentC.name.split(".");

        if (this.mameFile == nameF[0] || this.mameFileUp == null) {
          f = new File([this.documentC], `${this.nameFileRandom}.${nameF[1]}`, {
            type: this.documentC.type,
            lastModified: Date(),
          });
          this.documentC = f;
        } else {
          f = new File([this.documentC], `${this.mameFileUp}.${nameF[1]}`, {
            type: this.documentC.type,
            lastModified: Date(),
          });
          this.documentC = f;
        }

        formData.append("mimeType", await this.documentC.type);
        formData.append("idProject", this.projectId);
        formData.append("sampleFile", this.documentC);
      }
      formData.append("emailClient", this.currentUser.clientInfo.emailContacto);

      var token = JSON.parse(localStorage.user).token;

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      console.log(formData);
      await axios
        .post(
          "https://microservices.safered.co/api/storeFile",
          formData,
          config,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function (response) {
          console.log(response);
          dataFile = {
            id: response.data.file.id,
            name: response.data.file.name,
          };

          self.NotiUploadD = true;
          self.documentZ = null;
          self.documentC = null;
          self.dialogUpCopyA = false;
          self.dialogUpCopyA2 = false;
          self.dialogUpCopyC = false;
          self.dialogUpCopyP = false;
          self.dialogUpCopyP2 = false;
          self.dialogUpCopyP3 = false;
          self.dialogUpCopyP4 = false;
        })
        .catch(function (e) {
          if (e.response.status != undefined) {
            if (e.response.status == 409) {
              console.log(e);
            }
          }
        });

      if (type == "Area") {
        var obj = {
          arrayLink: [
            {
              idTag: this.selectedTypeDocument,
              idUser: this.currentUser.id,
              idProject: this.projectId,
              idCanvas: this.idPrincipal,
              idArea: idObject,
              idGoogle: dataFile.id,
              nameFile: dataFile.name,
              type_canvas: 0
            },
          ],
        };
      } else if (type == "Canva") {
        var obj = {
          arrayLink: [
            {
              idTag: this.selectedTypeDocument,
              idUser: this.currentUser.id,
              idProject: this.projectId,
              idCanvas: this.idPrincipal,
              idGoogle: dataFile.id,
              nameFile: dataFile.name,
              type_canvas: 0
            },
          ],
        };
      } else if (type == "Punto") {
        var obj = {
          arrayLink: [
            {
              idTag: this.selectedTypeDocument,
              idUser: this.currentUser.id,
              idProject: this.projectId,
              idCanvas: this.idPrincipal,
              idPoint: idObject,
              idGoogle: dataFile.id,
              nameFile: dataFile.name,
              type_canvas: 0
            },
          ],
        };
      }

      await axios
        .post("https://microservices.safered.co/api/linkedFile", obj, config, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (res) {
          var sustantivo = `Usuario ${
            JSON.parse(localStorage.user).name
          } ha subido ${dataFile.name} en el ${type}: ${idObject}`;
          self.registerLog(sustantivo);

          self.documentP = null;
          self.selectedTypeDocument = 0;
          self.selectFolderGoogle = null;
          self.selectCode = null;

          self.documentZ = null;
          self.documentC = null;
          self.documentP = null;
          self.selectedTypeDocument = 0;
          self.selectFolderGoogle = null;
          self.selectCode = null;

          var refr = self.$store.state.refreshDataGeo + 1;
          self.$store.commit("setRefreshDataGeo", refr);
          var count = self.$store.state.refreshDGeneral + 1;
          self.$store.commit("setRefreshDGeneral", count);
          self.NotiUploadD = true;
          self.$bvModal.hide("modal-uploadZone-" + index);
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    uploadDocumentsP: async function (idPunto, from) {
      console.log(from);
      if (this.selectFolderGoogle != null || this.selectCode != null) {
        var dataFile = null;

        var self = this;
        let formData = new FormData();

        var nameFile = this.documentP.name.replaceAll("(", "[");
        nameFile = nameFile.replaceAll(")", "]");

        var f = new File([this.documentP], `${nameFile}`, {
          type: this.documentP.type,
          lastModified: Date(),
        });

        formData.append("mimeType", this.documentP.type);
        if (this.selectCode != null) {
          formData.append("folderId", this.selectCode);
        } else {
          formData.append("folderId", this.selectFolderGoogle);
        }
        formData.append("idProject", this.projectId);
        formData.append("sampleFile", f);
        formData.append(
          "emailClient",
          this.currentUser.clientInfo.emailContacto
        );

        var token = JSON.parse(localStorage.user).token;

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        await axios
          .post(
            "https://microservices.safered.co/api/storeFile",
            formData,
            config,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (data) {
            dataFile = {
              id: data.data.file.id,
              name: data.data.file.name,
            };

            self.NotiUploadD = true;
          })
          .catch(function (e) {
            if (e.response.status == 409) {
              var dataResponse = e.response.data.resultsLike;
              var count = dataResponse.length;
              var numSecuente = count + 1;

              if (count > 0) {
                dataResponse.map(function (obj, index) {
                  var data = dataResponse.find((element) =>
                    element.text.includes(`(${index + 1})`)
                  );
                  if (data == undefined) {
                    numSecuente = index + 1;
                  }
                });
              }

              var nameF = self.documentP.name.split(".");

              self.nameFileRandom = `${nameF[0]}(${numSecuente})`;
              if (from == "Punto") {
                self.dialogUpCopyP = true;
              } else if (from == "Punto_Area") {
                self.dialogUpCopyP2 = true;
              } else if (from == "Punto_Area_Canvas") {
                self.dialogUpCopyP3 = true;
              } else {
                self.dialogUpCopyP4 = true;
              }
            }
            console.log(e);
          });

        if (dataFile != null) {
          var obj = {
            arrayLink: [
              {
                idTag: this.selectedTypeDocument,
                idUser: this.currentUser.id,
                idProject: this.projectId,
                idCanvas: this.idPrincipal,
                idPoint: idPunto,
                idGoogle: dataFile.id,
                nameFile: dataFile.name,
                type_canvas: 0
              },
            ],
          };

          await axios
            .post(
              "https://microservices.safered.co/api/linkedFile",
              obj,
              config,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(function (res) {
              var sustantivo = `Usuario ${
                JSON.parse(localStorage.user).name
              } ha subido ${dataFile.name} en el punto: ${idPunto}`;
              self.registerLog(sustantivo);
            })
            .catch(function (e) {
              self.NotiFail = true;
              console.log(e);
            });

          var count = self.$store.state.refreshDGeneral + 1;
          self.$store.commit("setRefreshDGeneral", count);

          this.documentP = null;
          this.selectedTypeDocument = 0;
          this.selectFolderGoogle = null;
          this.selectCode = null;
        }
      } else {
        let formData = new FormData();
        formData.append("point_id", idPunto);
        formData.append("file", this.documentP);
        formData.append("tag_id", this.selectedTypeDocument),
          formData.append("project_id", this.projectId),
          formData.append("user_id", JSON.parse(window.localStorage.user).id);

        var self = this;
        await axios
          .post("https://api.safered.co/api/store-file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (data) {
            var refr = self.$store.state.refreshDataGeo + 1;
            self.$store.commit("setRefreshDataGeo", refr);
            self.NotiUploadD = true;
          })
          .catch(function (e) {
            self.NotiFail = true;
          });

        this.documentP = null;
        this.selectedTypeDocument = 0;

        var refr = this.$store.state.refreshDataGeo + 1;
        this.$store.commit("setRefreshDataGeo", refr);
      }
    },
    uploadDocumentsA: async function (idArea, from) {
      if (this.selectFolderGoogle != null || this.selectCode != null) {
        var dataFile = null;

        var self = this;
        let formData = new FormData();

        var nameFile = this.documentZ.name.replaceAll("(", "[");
        nameFile = nameFile.replaceAll(")", "]");

        var f = new File([this.documentZ], `${nameFile}`, {
          type: this.documentZ.type,
          lastModified: Date(),
        });

        formData.append("mimeType", this.documentZ.type);
        if (this.selectCode != null) {
          formData.append("folderId", this.selectCode);
        } else {
          formData.append("folderId", this.selectFolderGoogle);
        }
        formData.append("idProject", this.projectId);
        formData.append("sampleFile", f);
        formData.append(
          "emailClient",
          this.currentUser.clientInfo.emailContacto
        );

        var token = JSON.parse(localStorage.user).token;

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        await axios
          .post(
            "https://microservices.safered.co/api/storeFile",
            formData,
            config,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (data) {
            dataFile = {
              id: data.data.file.id,
              name: data.data.file.name,
            };
            console.log(data);
          })
          .catch(function (e) {
            if (e.response.status == 409) {
              var dataResponse = e.response.data.resultsLike;
              var count = dataResponse.length;
              var numSecuente = count + 1;

              if (count > 0) {
                dataResponse.map(function (obj, index) {
                  var data = dataResponse.find((element) =>
                    element.text.includes(`(${index + 1})`)
                  );
                  if (data == undefined) {
                    numSecuente = index + 1;
                  }
                });
              }

              var nameF = self.documentZ.name.split(".");

              self.nameFileRandom = `${nameF[0]}(${numSecuente})`;
              if (from == "Area") {
                self.dialogUpCopyA = true;
              } else {
                self.dialogUpCopyA2 = true;
              }
            }
            console.log(e);
          });

        if (dataFile != null) {
          var obj = {
            arrayLink: [
              {
                idTag: this.selectedTypeDocument,
                idUser: this.currentUser.id,
                idProject: this.projectId,
                idCanvas: this.idPrincipal,
                idArea,
                idGoogle: dataFile.id,
                nameFile: dataFile.name,
                type_canvas: 0
              },
            ],
          };

          await axios
            .post(
              "https://microservices.safered.co/api/linkedFile",
              obj,
              config,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(function (res) {
              console.log(res);
              var sustantivo = `Usuario ${
                JSON.parse(localStorage.user).name
              } ha subido ${dataFile.name} en el area: ${idArea}`;
              self.registerLog(sustantivo);

              var refr = self.$store.state.refreshDataGeo + 1;
              self.$store.commit("setRefreshDataGeo", refr);
              var count = self.$store.state.refreshDGeneral + 1;
              self.$store.commit("setRefreshDGeneral", count);
              self.NotiUploadD = true;
            })
            .catch((e) => console.log(e));

          this.documentZ = null;
          this.selectedTypeDocument = 0;
          this.selectFolderGoogle = null;
          this.selectCode = null;
        }
      } else {
        let formData = new FormData();
        formData.append("area_id", idArea);
        formData.append("file", this.documentZ);
        formData.append("tag_id", this.selectedTypeDocument),
          formData.append("project_id", this.projectId),
          formData.append("user_id", JSON.parse(window.localStorage.user).id);

        var self = this;
        await axios
          .post("https://api.safered.co/api/store-file-area", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (data) {
            self.NotiUploadD = true;
          })
          .catch(function (e) {
            self.NotiFail = true;
          });

        var refr = this.$store.state.refreshDataGeo + 1;
        this.$store.commit("setRefreshDataGeo", refr);

        this.documentZ = null;
        this.selectedTypeDocument = 0;
      }
    },
    uploadDocumentsC: async function () {
      if (this.selectFolderGoogle != null || this.selectCode != null) {
        var dataFile = null;

        var self = this;
        let formData = new FormData();

        var nameFile = this.documentC.name.replaceAll("(", "[");
        nameFile = nameFile.replaceAll(")", "]");

        var f = new File([this.documentC], `${nameFile}`, {
          type: this.documentC.type,
          lastModified: Date(),
        });

        formData.append("mimeType", this.documentC.type);
        if (this.selectCode != null) {
          formData.append("folderId", this.selectCode);
        } else {
          formData.append("folderId", this.selectFolderGoogle);
        }
        formData.append("idProject", this.projectId);
        formData.append("sampleFile", f);
        formData.append(
          "emailClient",
          this.currentUser.clientInfo.emailContacto
        );
        var token = JSON.parse(localStorage.user).token;

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        await axios
          .post(
            "https://microservices.safered.co/api/storeFile",
            formData,
            config,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (data) {
            dataFile = {
              id: data.data.file.id,
              name: data.data.file.name,
            };
            var count = self.$store.state.refreshDGeneral + 1;
            self.$store.commit("setRefreshDGeneral", count);
            self.NotiUploadD = true;
          })
          .catch(function (e) {
            console.log(e);
            if (e.response.status == 409) {
              var dataResponse = e.response.data.resultsLike;
              var count = dataResponse.length;
              var numSecuente = count + 1;

              if (count > 0) {
                dataResponse.map(function (obj, index) {
                  var data = dataResponse.find((element) =>
                    element.text.includes(`(${index + 1})`)
                  );
                  if (data == undefined) {
                    numSecuente = index + 1;
                  }
                });
              }

              var nameF = self.documentC.name.split(".");

              self.nameFileRandom = `${nameF[0]}(${numSecuente})`;
              self.dialogUpCopyC = true;
            }
          });

        if (dataFile != null) {
          var obj = {
            arrayLink: [
              {
                idTag: this.selectedTypeDocument,
                idUser: this.currentUser.id,
                idProject: this.projectId,
                idCanvas: this.idPrincipal,
                idGoogle: dataFile.id,
                nameFile: dataFile.name,
                type_canvas: 0
              },
            ],
          };

          await axios
            .post(
              "https://microservices.safered.co/api/linkedFile",
              obj,
              config,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(function (res) {
              var sustantivo = `Usuario ${
                JSON.parse(localStorage.user).name
              } ha subido ${dataFile.name} en el canva: ${self.idPrincipal}`;
              self.registerLog(sustantivo);

              console.log(res);
              var refr = self.$store.state.refreshDataGeo + 1;
              self.$store.commit("setRefreshDataGeo", refr);
            })
            .catch((e) => console.log(e));

          this.documentC = null;
          this.selectedTypeDocument = 0;
          this.selectFolderGoogle = null;
          this.selectCode = null;
        }
      } else {
        let formData = new FormData();
        formData.append("canvas_id", this.idPrincipal);
        formData.append("file", this.documentC);
        formData.append("tag_id", this.selectedTypeDocument),
          formData.append("project_id", this.projectId),
          formData.append("user_id", JSON.parse(window.localStorage.user).id);

        var self = this;
        await axios
          .post("https://api.safered.co/api/store-file-canvas", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (data) {
            self.NotiUploadD = true;
          })
          .catch(function (e) {
            self.NotiFail = true;
          });

        var refr = this.$store.state.refreshDataGeo + 1;
        this.$store.commit("setRefreshDataGeo", refr);

        this.documentP = null;
        this.selectedTypeDocument = 0;
      }
    },
    createRel: async function (idFilePadre) {

      if (typeof idFilePadre == "number") {
        var dataRel = {
          idPadre: idFilePadre,
          idsChildres: this.selectdateFileR,
        };
        this.selectdateFileR = null;
        await api
          .request("post", "/setRelationalForDoc", dataRel)
          .then(function (response) {
            console.log(response);

          })
          .catch(function (error) {
            console.log(error);
          });

      } else if (typeof idFilePadre == "string") {
        var dataRel = {
          idProject: this.projectId,
          idPadre: idFilePadre,
          idHijo: this.selectdateFileR.value,
          idCanvas: this.idCanvas,
          typeCanvas: 0,
        };
        const config = {
          headers: { Authorization: `Bearer ${this.token}` },
        };

        await axios
          .post(
            "https://microservices.safered.co/api/createRelations",
            dataRel,
            config,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => (result = res.data))
          .catch((e) => console.log(e));
      }
      var refr = this.$store.state.refreshDataGeo + 1;
      this.$store.commit("setRefreshDataGeo", refr);
    },
    listTagandFileR: function (file) {
      var self = this;
      self.dateFileR = [];
      var dataMap = this.dataMap[0].children;
      var checkType = false;

      if (file.idFile == file.path) {
        checkType = true;
      }

      dataMap.map(function (dataM) {
        if (dataM.text != file.text) {
          if (dataM.path != undefined) {
            if (checkType == (dataM.idFile == dataM.path)) {
              self.dateFileR.push({
                value: dataM.idFile,
                text: dataM.text,
              });
            }
          } else {
            if (dataM.children != undefined) {
              dataM.children.map(async function (arr) {
                if (arr.text != file.text) {
                  if (arr.path != undefined) {
                    if (checkType == (arr.idFile == arr.path)) {
                      self.dateFileR.push({
                        value: arr.idFile,
                        text: arr.text,
                      });
                    }
                  } else {
                    if (arr.children != undefined) {
                      arr.children.map(async function (arr1) {
                        if (arr1.text != file.text) {
                          if (arr1.path != undefined) {
                            if (checkType == (arr1.idFile == arr1.path)) {
                              self.dateFileR.push({
                                value: arr1.idFile,
                                text: arr1.text,
                              });
                            }
                          }
                        }
                      });
                    }
                  }
                }
              });
            }
          }
        }
      });
    },
    datosCenter() {
      if (this.dataCenter != null) {
        var dataC = JSON.parse(this.dataCenter.center);

        this.center = [parseFloat(dataC[0]), parseFloat(dataC[1])];
        var dataCB = JSON.parse(this.dataCenter.bound);
        this.bounds = {
          _northEast: {
            lat: dataCB._northEast.lat,
            lng: dataCB._northEast.lng,
          },
          _southWest: {
            lat: dataCB._southWest.lat,
            lng: dataCB._southWest.lng,
          },
        };
        this.zoom = parseInt(this.dataCenter.zoom);
      } else if (this.pointCenter != undefined) {
        this.center = this.pointCenter;
        this.zoom = 20;
      } else {
        this.center = [4.60971, -74.08175];
      }
    },
    deletePoint: async function (id) {
      var self = this;

      if (this.permitEdit == true) {
        await axios
          .delete(
            "https://api.safered.co/api/deletePoint/" +
              id +
              "/" +
              JSON.parse(localStorage.user).id
          )
          .then(function (response) {
            self.NotideleteP = true;
          })
          .catch(function (error) {
            self.NotiFail = true;
          });
      } else {
        console.log("No tiene permisos");
      }
      var refr = this.$store.state.refreshDrawGeo + 1;
      this.$store.commit("setRefreshDrawGeo", refr);
    },
    deletePolygon: async function (id) {
      if (this.permitEdit == true) {
        await axios
          .delete(
            "https://api.safered.co/api/deleteArea/" +
              id +
              "/" +
              JSON.parse(localStorage.user).id
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log("No tiene permisos");
      }

      var refr = this.$store.state.refreshDrawGeo + 1;
      this.$store.commit("setRefreshDrawGeo", refr);
    },
    addFigures: async function () {
      var self = this;
      var arrayObject = [];
      var arrayObjectDel = [];

      if (this.itemsAreas.length > 0) {
        self.dataObjectP.areas.map(function (obj) {
          var result = self.itemsAreas.find((element) => element.id == obj.id);
          if (result != undefined) {
            arrayObject.push({
              idObject: obj.id,
              idCanvasPpal: self.idPrincipal,
              idCanvasSec: self.idCanvas,
              Type: 0,
            });
          } else {
            arrayObjectDel.push({
              idObject: obj.id,
              idCanvasPpal: self.idPrincipal,
              idCanvasSec: self.idCanvas,
              Type: 0,
            });
          }
        });
      }else{
        console.log(self.dataObjectP)
      }

      if (this.itemsPuntos.length > 0) {
        self.dataObjectP.puntos.map(function (obj) {
          var result = self.itemsPuntos.find((element) => element.id == obj.id);
          if (result != undefined) {
            arrayObject.push({
              idObject: obj.id,
              idCanvasPpal: self.idPrincipal,
              idCanvasSec: self.idCanvas,
              Type: 1,
            });
          } else {
            arrayObjectDel.push({
              idObject: obj.id,
              idCanvasPpal: self.idPrincipal,
              idCanvasSec: self.idCanvas,
              Type: 1,
            });
          }
        });
      }

      var obj = {
        arrayObject: arrayObject,
      };

      var obj1 = {
        arrayObject: arrayObjectDel,
      };

      await api
        .request("post", "/linkObjectForCanvas", obj)
        .then((response) => {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          self.NotiFail = true;
        });

      await api
        .request("post", "/deletLinkObjectForCanvas", obj1)
        .then((response) => {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

      self.NotiAsingFig = true;
      var refr = self.$store.state.refreshDrawGeo + 1;
      self.$store.commit("setRefreshDrawGeo", refr);
    },
  },
  watch: {
    permitEdit: function(){
      console.log(this.permitEdit);
    },
    mameFile: function () {
      this.statusVerify = !/[():<>*\;"\\|.\/?~]/.test(this.mameFile); //eslint-disable-line
    },
    codeActive: function () {
      this.selectFolderGoogle = null;
      this.selectCode = null;
    },
    backup_polygonsR: function () {
      var self = this;

      this.backup_polygonsR.map(function (element) {
        self.optionsAreas.push({
          text: element.name,
          value: element.name,
        });
      });

      this.optionsAreas.map(function (element) {
        self.selectedAreas.push(element.text);
      });
    },
    selectedAreas: function () {
      this.polygonsR = [];
      // this.anillos.data = [];
      var self = this;
      this.selectedAreas.map(function (element) {
        self.polygonsR.push(
          self.backup_polygonsR.find((element2) => element2.name == element)
        );
      });
      // this.polygonsR.map(function(element){
      //   element.namePoints.map(function(element2){
      //     self.anillos.data.push(self.dataCopyAnillos.find(element3 => element3.name == element2[0]))
      //   })
      // })
    },
    fileView: function () {
      this.fileView.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
    polygonsLoaded: function () {
      this.findIntersections();
    },
    pointsFound: function () {
      this.foundNamesPoints();
    },
    dataPoints: function () {
      if (Object.keys(this.dataPoints) < 0) {
        this.formatPoints();
      }
    },
  },
};
/*eslint-enable */
</script>
<style>
@import "bootstrap/dist/css/bootstrap.css";
@import "bootstrap-vue/dist/bootstrap-vue.css";

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.modal-sm .modal-content header {
  background-color: #283271;
}

.modal-sm .modal-content footer {
  background-color: #283271;
}

.modal-sm .modal-content header h5 {
  color: white;
}

.modal-sm .modal-content header .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  opacity: 0.9;
}

.modal-sm .modal-header {
  padding-top: 3px;
  padding-bottom: 3px;
}

.modal-sm .modal-footer {
  padding-top: 3px;
  padding-bottom: 3px;
}

.modal-sm .modal-header h5 {
  font-size: 0.8rem !important;
  color: white !important;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}

#modal-canvasD .modal-content header {
  background-color: #283271;
}

#modal-canvasD .modal-content footer {
  background-color: #283271;
}

#modal-canvasD .modal-content header h5 {
  color: white;
}

#modal-canvasD .modal-content header .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  opacity: 0.9;
}

.row.text_w .col {
  color: white;
}

.drop_L button {
  text-align: -webkit-match-parent;
}

#modal-canvasD .modal-header {
  padding-top: 3px;
  padding-bottom: 3px;
}

#modal-canvasD .modal-header h5 {
  font-size: 0.8rem !important;
  color: white !important;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}

.btn_drop {
  font-size: 12px !important;
  white-space: nowrap !important;
  width: 100% !important;
  color: #212529 !important;
  font-weight: 400 !important;
  text-align: -webkit-match-parent !important;
  vertical-align: middle !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
  border: 1px solid transparent !important;
  padding: 0.375rem 0.75rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

div.leaflet-popup-content-wrapper {
  background: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}

div.leaflet-popup-tip {
  background: red;
}

.leaflet-container a.leaflet-popup-close-button {
  font: 19px/24px Tahoma, Verdana, sans-serif !important;
  color: white !important;
}

a.leaflet-popup-close-button {
  margin-right: 5px;
  top: 14px !important;
  right: 22px !important;
}

.vue2leaflet-map {
  z-index: 1001 !important;
}

button#dropdown-dropright__BV_toggle_ {
  font-size: 12px !important;
}

.dropdown-item {
  font-size: 12px !important;
}

div.card-body {
  padding: 0px !important;
}

.card {
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%) !important;
}

header.card-header {
  background-color: #283271 !important;
}

footer.card-footer {
  background-color: #283271 !important;
}

footer.card-footer em {
  color: white !important;
}

ul.dropdown-menu.show {
  height: 160px;
  overflow-y: scroll !important;
}

h4.card-title {
  font-size: 0.7rem !important;
}

h6.mb-0 {
  font-size: 0.8rem !important;
  color: white !important;
}

img.btn_img {
  cursor: pointer !important;
  height: 25px !important;
  width: auto !important;
}

.user__more {
  cursor: pointer !important;
  text-decoration: none !important;
  color: black !important;
}

a:hover {
  text-decoration: none !important;
}

div.icon_drop button#dropdown-dropright__BV_toggle_:hover {
  -webkit-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
}

.user__more:hover {
  -webkit-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
}

div.icon_drop button#dropdown-dropright__BV_toggle_ {
  font-size: 14px !important;
  padding: 0px 0px 30px 0px !important;
}

div.col_iconMap {
  position: relative !important;
  width: 100% !important;
  padding-right: 1px !important;
  padding-left: 10px !important;
}

div.row.colum_itemP div.col {
  padding-right: 1px !important;
  padding-left: 20px !important;
}

div.col_iconMap2 {
  position: relative !important;
  width: 100% !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

div.row_iconMap {
  padding-top: 5px !important;
}

.color_list {
  max-width: 2rem !important;
  color: white !important;
  font-size: smaller !important;
}

img.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive.fadeIcon {
  animation: parpadeo 0.5s linear infinite;
}

@keyframes parpadeo {
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}

svg g path.fadeIn.leaflet-interactive{
  stroke-width: 4;
  animation: animacion-color 4s ease-in-out infinite;
  stroke: #ff0000;
}

@keyframes animacion-color {
  0%{
    stroke: #ff0000;
  }
  15%{
    stroke: #757575;
  }
  35%{
    stroke: #ff0000;
  }
  55%{
    stroke: #757575;
  }
  75%{
    stroke: #ff0000;
  }
  80%{
    stroke: #757575;
  }
  100%{
    stroke: #ff0000;
  }
}
</style>
