export default {
    projects:{
        welcome_projectsM: "Welcome, you have access to these projects:"
    },
    Auth:{
        enterEmail_login: "Enter your email",
        enterPass_login: "Enter your password",
        login: "Login",
        forgotPass_login: "¿Forgot your password?",
        enterReseting_login: "Enter your email for reseting your password.",
        reset_login: "Reset",
        sentMail: "An email will be sent to you in order to reset your password.",
        emailReq_login: "E-mail is required",
        emailValid_login: "E-mail must be valid",
        req_login: "Required",
        minCha_login: "Minimum 3 characters",
        passReq_login: "Password is required.",
        emailR_login: "Email account is mandatory.",
        validEma_login: "You must have a valid email address.",
        passV_login: "Email or password are not valid.",
        userBloc_login: "This user have been blocked.",
    },
    Structure_p:{
        allRigh_footer: "All rights reserved",
        custServ_footer: "Customer service",
        contUs_footer: "Contact us",
        legalInf_footer: "Legal information",
        privPolicy_footer: "Privacy Policy",
        termUse: "Terms of use",
        veracityInf_footer: "Terms for the veracity of information",
        consPro_footer: "Consumer protection",
    },
    register:{
        activaAcc_activeAccUser: "Activate your account",
        userCreated_activeAccUser: "An user of SAFERED have been created! Please write your password to complete your profile.",
        comfAcc_activeAccUser: "Comfirm account",
        enterInf_activeAccUser: "Enter your login information",
        name_activeAccUser: "Name:",
        nameUser_activeAccUser: "Name of the user.",
        email_activeAccUser: "Email:",
        validEmail_activeAccUser: "Please write a valid email",
        pass_activeAccUser: "Password:",
        passConfir_activeAccUser: "Password Confirmation",
        close_activeAccUser: "Close",
        save_activeAccUser: "Save",
        invaLink_activeAccUser: "Invalid link",
        notCreated_activeAccUser: "Your user or account have not been created, please contact customer service",
        statUpd_activeAccUser: "Status update",
        statUpdNot_activeAccUser: "Status update not possible",
        passNotMatch_activeAccUser: "Your password does not match",
        enterValEmail_activeAccUser: "Please enter a valid email",
        enterValPass_activeAccUser: "Please enter a valid password",
        confPass_activeAccUser: "Confirm your password",
        insetUName_activeAccUser: "Insert user name",
        confirmUser_activeAcc: "An user of SAFERED have been created! Please write your password to complete your profile.",
        persInf_activeAcc: "Personal information",
        contNa_activeAcc: "Contact name:",
        typeIDN_activeAcc: "Type of ID - ID Number:",
        emailCont_activeAcc: "Email contact:",
        phoneCont_activeAcc: "Phone contact:",
        coStCi: "Country / State / City:",
        address_activeAcc: "Address:",
        setAdmPass_activeAcc: "Set administrator email and password",
        userSucc_activeAcc: "User created successfully"
    },
    modals:{
        infOf_modalPerfil: "Information of",
        downFile_modalPerfil: "Download file",
        attCanv_modalPerfil: "Attach to canvas",
        attArea_modalPerfil: "Attach to area",
        tagAssig_modalPerfil: "Tag assignment",
        selTyp_modalPerfil: "Select type",
        assArea_modalPerfil:"Assign to an area",
        attPoint_modalPerfil: "Attach to point",
        assPoint_modalPerfil: "Assign a point",
        shaPock_modalPerfil: "Share pocket",
        "shaFilePock_modalPerfil {text}": "Share the file {text} to the pocket",
        selUser_modalPerfil: "Select user",
        share_modalPerfil: "Share",
        deleFile_modalPerfil: "Delete file",
        docAtt_modalPerfil: "Document has been attached",
        assoValid_modalPerfil: "Association not valid",
        wiRemNode: "Wish to remove this node?"
    }
}