<template>
  <div class="center grid">
    <b-row>
      <b-col sm="1">
      </b-col>
      <b-col sm="10">
        <b-card style="margin-top: 29px; margin-bottom: 30px;">
          <div style="height: 90px;">
          </div>
          <b-row>
            <b-col sm="2">
            </b-col>
            <b-col sm="3">
              <createBusiness :dataBusiness="dataBusiness" id="card_style"/>
            </b-col>
            <b-col sm="3">
              <editBusiness :dataBusiness="dataBusiness" id="card_style"/>
            </b-col>
            <b-col sm="3">
              <deleteBusiness :dataBusiness="dataBusiness" id="card_style"/>
            </b-col>
            <b-col sm="2">
            </b-col>
          </b-row>
          <div style="height: 110px;">
          </div>
        </b-card>
      </b-col>
      <b-col sm="1">
      </b-col>
    </b-row>
  </div>
</template>

<script>

    import api from "@/api";
    import createBusiness from './modals/CreateBusiness'
    import editBusiness from './modals/EditBusiness'
    import deleteBusiness from './modals/DeleteBusiness'

    export default {
        name: "businessAdm",
        components:{
            createBusiness,
            editBusiness,
            deleteBusiness
        },
        data(){
            return{
                dataBusiness: []
            }
        },
        created(){
            this.getBusiness();
        },
        methods: {
            async getBusiness(){
                var self = this
                var obj = {
                    idUser: JSON.parse(localStorage.user).id
                }

                await api
                .request("post", "/showCompany", obj)
                .then((data) => {
                    console.log(data)
                    self.dataBusiness = data.data
                })
                .catch(function (error) {
                  console.log(error)
                });
            }
        },
        watch:{
            "$store.state.refreshBusiness": function(){
              this.getBusiness();
            }
            
        }
    }

</script>

<style>

</style>