<template>
    <v-row>
        <v-col cols="10">
          <v-menu
            bottom
            origin="center center"
            transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#283271"
                dark
                v-bind="attrs"
                v-on="on"
                elevation="2"
                small  > 
                {{items[select_diretory].text}}
                <v-icon right  dark >
                mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list-item-group>
              <template  v-for="(item, i) in items">
                <v-list-item 
                  @click="select_diretory = i" 
                  :key="i" 
                  style="background-color: white;">
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-menu>   
        </v-col>       
      </v-row>
</template>

<script>

export default ({
    name: "botonDirectory",
    data() {
      return{
        select_diretory: 0,
        items: [
          { text: 'Directorio Global' },
          { text: 'Directorio Personalizado'},
          { text: 'Directorio Georeferenciado'},
          { text: 'Directorio Mapa de Bits'}
        ],
      };
    },
    props: [ 'posicionTitle', ],
    async mounted(){
      await this.modifEstruct();
    },
    methods:{
      modifEstruct(){
        var canvas = this.$store.state.dataInit.canvas;
        console.log(canvas);
        var foundGeo = canvas.find(element => element.includes("icon_canvas_gps"));
        var foundMpbits = canvas.find(element => element.includes("icon_canvas_img"));

        if(foundGeo == undefined){
          this.items.splice(2, 1);
        }

        if(foundGeo == undefined && foundMpbits == undefined){
          this.items.splice(2, 1);
        }else if(foundMpbits == undefined){
          this.items.splice(3, 1);
        }
      }
    },
    watch:{
      posicionTitle: function(){
        if(this.posicionTitle != null){
          this.select_diretory = this.posicionTitle
        }
      },
      select_diretory: function(){
        this.$store.commit("setSelect_diretory", this.select_diretory)
      },
    }
})
</script>
