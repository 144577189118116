<template>
  <div>
    <v-card
      style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
      @click="CreateClients"
    >
      <v-img
        :src="icon_add_user"
        alt=""
        style="width: 110px"
      />

      <v-card-title>
        <h3 style="font-size: 12px">Crear cliente</h3>
      </v-card-title>
    </v-card>
  </div>
</template>
<script>
import icon_add_user from '../../../../../../../assets/img/icons/icon_add_user.svg'
import axios from "axios";

export default {
  name: "createClientM",
  components: {},
  data() {
    return {
      icon_add_user: icon_add_user,
      s_pjuridica: false,
      s_pnatural: false,
      selectSection: true,
      formEdit: {
        id: "",
        email: "",
        name: "",
        lastName: "",
        typeClient: null,
        typeDocument: null,
        numIdClient: 0,
        checked: [],
      },
      typeClients: [
        { text: "Seleccionar tipo de cliente", value: null },
        "Persona Natural",
        "Persona Jurídica",
      ],
      typeDocuments: [
        { text: "Seleccionar tipo de documento", value: null },
        "Cedula de ciudadania",
        "DNI",
        "Pasaporte",
        "Cedula Extranjeria",
      ],
      pNatural: {
        tipo: "",
        nombreContacto: "",
        apellidosContacto: "",
        tipoDocumentoContacto: "",
        numeroDocumentoContacto: "",
        emailContacto: "",
        numeroTelefonoContacto: "",
        PaisContacto: "",
        estadoContacto: "",
        ciudadContacto: "",
        direccionContacto: "",
        docIdTributariaContacto: "",
        cargoContacto: "",
      },
      pJuridica: {
        company: {
          razonSocial: "",
          numIdTributaria: "",
          paisCompany: "",
          estadoCompany: "",
          ciudadCompany: "",
          direccionCompany: "",
          certificadoTributario: "",
        },
        contact: {
          tipo: "",
          nombreContacto: "",
          apellidosContacto: "",
          tipoDocumentoContacto: "",
          numeroDocumentoContacto: "",
          emailContacto: "",
          numeroTelefonoContacto: "",
          PaisContacto: "",
          estadoContacto: "",
          ciudadContacto: "",
          direccionContacto: "",
          docIdTributariaContacto: "",
          cargoContacto: "",
        },
        repLegal: {
          nombres: "",
          apellidos: "",
          tipoDocumento: "",
          numeroDocumento: "",
          email: "",
          numeroTelefono: "",
        },
      },

      countries: [],
      validatedCountry: true,
      states: [],
      cities: [],
    };
  },
  methods: {
    loadCountries: async function () {
      await axios
        .get("https://countriesnow.space/api/v0.1/countries/")
        .then((result) => {
          this.countries = result.data.data;
        });
    },
    onChangeCountrie: async function () {
      if (this.pNatural.PaisContacto !== "") {
        this.validatedCountry = false;
        await axios
          .post("https://countriesnow.space/api/v0.1/countries/states", {
            country: this.pNatural.PaisContacto,
          })
          .then((result) => {
            this.states = result.data.data.states;
          });
      } else if (this.pJuridica.company.paisCompany !== "") {
        this.validatedCountry = false;
        await axios
          .post("https://countriesnow.space/api/v0.1/countries/states", {
            country: this.pJuridica.company.paisCompany,
          })
          .then((result) => {
            this.states = result.data.data.states;
          });
      } else {
        console.log("vacio");
      }
    },
    onChangeStates: async function () {
      if (this.pNatural.estadoContacto !== "") {
        this.validatedCountry = false;
        await axios
          .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
            country: this.pNatural.PaisContacto,
            state: this.pNatural.estadoContacto,
          })
          .then((result) => {
            console.log(result);
            this.cities = result.data.data;
          });
      } else if (this.pJuridica.company.estadoCompany !== "") {
        this.validatedCountry = false;
        await axios
          .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
            country: this.pJuridica.company.paisCompany,
            state: this.pJuridica.company.estadoCompany,
          })
          .then((result) => {
            console.log(result);
            this.cities = result.data.data;
          });
      } else {
        console.log("vacio");
      }
    },
    validateInfPersJurContact: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.pJuridica.contact.nombreContacto === "" &&
            this.pJuridica.contact.apellidosContacto === "" &&
            this.pJuridica.contact.tipoDocumentoContacto === "" &&
            this.pJuridica.contact.numeroDocumentoContacto === "" &&
            this.pJuridica.contact.emailContacto === "" &&
            this.pJuridica.contact.cargoContacto === "" &&
            this.pJuridica.contact.numeroTelefonoContacto === ""
          ) {
            reject(
              "Debe registrar la información del contacto antes de continuar."
            );
            alert(
              "Debe registrar la información del contacto antes de continuar."
            );
          } else if (this.pJuridica.contact.nombreContacto === "") {
            reject("Debe ingresar el nombre del contacto de la emrpesa.");
            alert("Debe ingresar el nombre del contacto de la emrpesa.");
          } else if (this.pJuridica.contact.apellidosContacto === "") {
            reject("Debe ingresar el apellido del contacto de la empresa.");
            alert("Debe ingresar el apellido del contacto de la empresa.");
          } else if (this.pJuridica.contact.tipoDocumentoContacto === "") {
            reject(
              "Debe seleccionar el tipo de documento del contacto de la empresa"
            );
            alert(
              "Debe seleccionar el tipo de documento del contacto de la empresa"
            );
          } else if (this.pJuridica.company.estadoCompany === "") {
            reject(
              "Debe seleccionar el estado o departamento del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar el estado o departamento del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.ciudadCompany === "") {
            reject(
              "Debe seleccionar la ciudad o provincia del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar la ciudad o provincia del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.direccionCompany === "") {
            reject(
              "Debe ingresar la dirección del domicilio principal de la empresa"
            );
            alert(
              "Debe ingresar la dirección del domicilio principal de la empresa"
            );
          } else {
            console.log(this.pJuridica.company);
            resolve(true);
          }
        }, 1000);
      });
    },
    savePerJuridica: async function () {
      if (this.pNatural.docIdTributariaContacto === "") {
        alert(
          "Debe cargar el documento de certificación tributaria del cliente."
        );
      } else {
        console.log(this.pJuridica)
        await axios
          .post(
            "https://api.safered.co/api/store_p_jur_client",
            this.pJuridica,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (data) {
            console.log(data);
            alert("Cliente creado exitosamente");
          })
          .catch(function (e) {
            console.log(e);
          });
      }
    },
    viewPNatural() {
      this.s_pjuridica = false;
      this.s_pnatural = true;
      this.selectSection = false;
    },
    viewPJuridica() {
      this.s_pjuridica = true;
      this.s_pnatural = false;
      this.selectSection = false;
    },
    resetView() {
      this.s_pjuridica = false;
      this.s_pnatural = false;
      this.selectSection = true;
    },
    CreateClients: function () {
      this.$store.commit("setCreateClients", true)
    },
    savePerNatural: async function () {
      let formData = new FormData();

      if (this.pNatural.docIdTributariaContacto === "") {
        alert(
          "Debe cargar el documento de certificación tributaria del cliente."
        );
      } else {
        formData.append("tipo", "Persona natural");
        formData.append("nombreContacto", this.pNatural.nombreContacto);
        formData.append("apellidosContacto", this.pNatural.apellidosContacto);
        formData.append(
          "tipoDocumentoContacto",
          this.pNatural.tipoDocumentoContacto
        );
        formData.append(
          "numeroDocumentoContacto",
          this.pNatural.numeroDocumentoContacto
        );
        formData.append(
          "numeroTelefonoContacto",
          this.pNatural.numeroTelefonoContacto
        );
        formData.append("emailContacto", this.pNatural.emailContacto);
        formData.append("PaisContacto", this.pNatural.PaisContacto);
        formData.append("estadoContacto", this.pNatural.estadoContacto);
        formData.append("ciudadContacto", this.pNatural.ciudadContacto);
        formData.append("direccionContacto", this.pNatural.direccionContacto);
        formData.append(
          "docIdTributariaContacto",
          this.pNatural.docIdTributariaContacto
        );
        formData.append("cargoContacto", this.pNatural.cargoContacto);
        await axios
          .post(
            "https://api.safered.co/api/store_p_natural_client",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(function (data) {
            console.log(data);
            alert("Cliente creado exitosamente");
          })
          .catch(function (e) {
            console.log(e);
          });
      }
    },
    validateInfPersJur: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.pJuridica.company.razonSocial === "" &&
            this.pJuridica.company.numIdTributaria === "" &&
            this.pJuridica.company.paisCompany === "" &&
            this.pJuridica.company.estadoCompany === "" &&
            this.pJuridica.company.ciudadCompany === "" &&
            this.pJuridica.company.direccionCompany === ""
          ) {
            reject(
              "Debe registrar la información de la empresa antes de continuar."
            );
            alert(
              "Debe registrar la información de la empresa antes de continuar."
            );
          } else if (this.pJuridica.company.razonSocial === "") {
            reject("Debe ingresar la razón social o nombre de la emrpesa.");
            alert("Debe ingresar la razón social o nombre de la emrpesa.");
          } else if (this.pJuridica.company.numIdTributaria === "") {
            reject(
              "Debe ingresar el numero de identificación tributaria de la empresa"
            );
            alert(
              "Debe ingresar el numero de identificación tributaria de la empresa"
            );
          } else if (this.pJuridica.company.paisCompany === "") {
            reject(
              "Debe seleccionar el pais del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar el pais del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.estadoCompany === "") {
            reject(
              "Debe seleccionar el estado o departamento del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar el estado o departamento del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.ciudadCompany === "") {
            reject(
              "Debe seleccionar la ciudad o provincia del domicilio principal de la empresa"
            );
            alert(
              "Debe seleccionar la ciudad o provincia del domicilio principal de la empresa"
            );
          } else if (this.pJuridica.company.direccionCompany === "") {
            reject(
              "Debe ingresar la dirección del domicilio principal de la empresa"
            );
            alert(
              "Debe ingresar la dirección del domicilio principal de la empresa"
            );
          } else {
            console.log(this.pJuridica.company);
            resolve(true);
          }
        }, 1000);
      });
    },
    validateInfPers: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.pNatural.nombreContacto === "" &&
            this.pNatural.apellidosContacto === "" &&
            this.pNatural.tipoDocumentoContacto === "" &&
            this.pNatural.numeroDocumentoContacto === "" &&
            this.pNatural.emailContacto === "" &&
            this.pNatural.numeroTelefonoContacto === ""
          ) {
            reject(
              "Debe registrar la información personal antes de continuar."
            );
            alert("Debe registrar la información personal antes de continuar.");
          } else if (this.pNatural.nombreContacto === "") {
            reject("Debe ingresar el nombre completo del cliente.");
            alert("Debe ingresar el nombre completo del cliente.");
          } else if (this.pNatural.apellidosContacto === "") {
            reject("Debe ingresar los apellidos completos del cliente.");
            alert("Debe ingresar los apellidos completos del cliente.");
          } else if (this.pNatural.tipoDocumentoContacto === "") {
            reject(
              "Debe seleccionar el tipo de documento de identificación del cliente."
            );
            alert(
              "Debe seleccionar el tipo de documento de identificación del cliente."
            );
          } else if (this.pNatural.numeroDocumentoContacto === "") {
            reject(
              "Debe ingresar el numero de documento de identificación del cliente."
            );
            alert(
              "Debe ingresar el numero de documento de identificación del cliente."
            );
          } else if (this.pNatural.emailContacto === "") {
            reject("Debe ingresar un email de contacto valido del cliente.");
            alert("Debe ingresar un email de contacto valido del cliente.");
          } else if (this.pNatural.numeroTelefonoContacto === "") {
            reject(
              "Debe ingresar un numero telefonico de contacto del cliente"
            );
            alert("Debe ingresar un número telefónico de contacto del cliente");
          } else {
            console.log(this.pNatural);
            resolve(true);
          }
        }, 1000);
      });
    },
    validateInfResist: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.pNatural.PaisContacto === "" &&
            this.pNatural.estadoContacto === "" &&
            this.pNatural.ciudadContacto === "" &&
            this.pNatural.direccionContacto === ""
          ) {
            reject(
              "Debe registrar la información de residencia antes de continuar."
            );
            alert(
              "Debe registrar la información de residencia antes de continuar."
            );
          } else if (this.pNatural.PaisContacto === "") {
            reject("Debe ingresar el país de residencia del cliente.");
            alert("Debe ingresar el país de residencia del cliente.");
          } else if (this.pNatural.estadoContacto === "") {
            reject("Debe ingresar el estado de residencia del cliente.");
            alert("Debe ingresar el estado de residencia del cliente.");
          } else if (this.pNatural.ciudadContacto === "") {
            reject("Debe ingresar la ciudad de residencia del cliente.");
            alert("Debe ingresar la ciudad de residencia del cliente.");
          } else if (this.pNatural.direccionContacto === "") {
            reject("Debe ingresar la dirección de residencia del cliente.");
            alert("Debe ingresar la dirección de residencia del cliente.");
          } else {
            console.log(this.pNatural);
            resolve(true);
          }
        }, 1000);
      });
    },
  },
  mounted() {
    this.loadCountries();
  },
};
</script>
<style scoped>
</style>
