/* eslint-disable valid-typeof */
/* eslint-disable no-undef */
module.exports = {
    newChildren(obj1, obj2) {
      var diff = {}
      
      for (let prop in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, prop)) {
          if (obj1[prop] !== obj2[prop]) {
            diff[prop] = obj1[prop];
          }
        }
      }

      return diff;
    },
    find: async function(data) {
      var lookup = {}
      
      const registerIds = a => {
        a.map(function(o){
          if('text' in o){
            lookup[o.text] = o;
          }

          if('children' in o){
            registerIds(o.children)
          }
        });
      }

      await registerIds(await data.children);
      var result = Object.values(await lookup)

      return result;
    },
    findDelete: async function(data, elementDelete){
      var tempData = data.children

        for(let i = 0;i < elementDelete.length; i++){
          var elementNofind = a => {
          a.map(async function(obj, index){
            if((obj.text == elementDelete[i].text) && elementDelete[i].text != "Novedades"){
              await a.splice(index, 1)
            }
            if('children' in obj){
              await elementNofind(obj.children)
            }
          })
        }
        await elementNofind(await tempData)
      }
      
    },
    addElement: async function(data, inFolder){
      var fileAdd = a => {
        a.map(async function(obj){
          if(obj.icon == null){
            if(obj.id == inFolder.folder){
              await obj.children.push({
                "text": inFolder.nameFile,
                "value": inFolder.nameFile,
                "id": "",
                "icon": "fa fa-file",
                "opened": false,
                "parent": inFolder.folder.id,
                "children": []
              })
            }
          }
          if('children' in obj){
            await fileAdd(obj.children)
          }
        })
      }

      var folderAdd = a => {
        a.map(async function(obj){
          var dateId = new Date()
          if(obj.icon == null){
            if(obj.id == inFolder.folder){
              await obj.children.push({
                "text": inFolder.nameFile,
                "value": inFolder.nameFile,
                "id": `temp_${dateId.getDay()}${dateId.getMonth()}${dateId.getFullYear()}${dateId.getHours()}${dateId.getMinutes()}${dateId.getSeconds()}${dateId.getMilliseconds()}`,
                "icon": "",
                "opened": false,
                "parent": inFolder.folder,
                "children": []
              })
            }
          }
          if('children' in obj){
            await folderAdd(obj.children)
          }
        })
      }
      
      if(inFolder.type == "File"){
        await fileAdd(await data.children)
      }else{
        var dateId = new Date()
        if(data.id == inFolder.folder){
          await data.children.push({
            "text": inFolder.nameFile,
            "value": inFolder.nameFile,
            "id": `temp_${dateId.getDay()}${dateId.getMonth()}${dateId.getFullYear()}${dateId.getHours()}${dateId.getMinutes()}${dateId.getSeconds()}${dateId.getMilliseconds()}`,
            "icon": "",
            "opened": false,
            "parent": inFolder.folder,
            "children": []
          })
        }else{
          await folderAdd(await data.children)
        }
      }

    },
    asingValue: async function(data, resultData){
      var tempData = data.children;

      for(let i = 0;i < resultData.length; i++){
        var elementFind = a => {
          a.map(async function(obj, index){
            if(obj.text == resultData[i].text){
              a[index] = await resultData[i]
            }
            if('children' in obj){
              await elementFind(obj.children)
            }
          })
        }
        await elementFind(await tempData);
      }
      return await tempData
    },
    deleteGhost: async function(data, id){
      var tempData = data.children

      var elementNofind = a => {
        a.map(async function(obj, index){
          if(obj.id == id){
            await a.splice(index, 1)
          }
          if('children' in obj){
            await elementNofind(obj.children)
          }
        })
      }

      await elementNofind(await tempData)
    },
    findChildrenEquals: async function(data, idFather, idChildren){

      var tempData = data[0].children;
      var findF = null;
      var exist = false;

      var elementDetect = a => {
        a.map(async function(obj){
          if(obj.id == idFather){
            findF = await obj;
          }
          if('children' in obj){
            await elementDetect(obj.children)
          }
        })
      }

      await elementDetect(tempData);
      
      if(findF != null && findF.children.length > 0){
        var elementDetectChildren = a => {
          a.map(async function(obj){
            if(obj.id == idChildren){
              exist = true;
            }
            if('children' in obj){
              await elementDetectChildren(obj.children)
            }
          })
        }
        await elementDetectChildren(findF.children);
      }else if(findF.children.length == 0){
        exist =  findF.id == idChildren;
      }

      return await exist;
    }
}