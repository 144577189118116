<template>
  <v-row style="background-color: #e4e4e4">
    <v-col cols="1"></v-col>
    <v-col cols="10">
      <v-card
        style="margin-top: 35px; margin-bottom: 35px; display: flex"
        height="380"
      >
        <v-row
          style="
            margin-left: 5px;
            margin-top: 5px;
            display: flex;
            justify-content: space-around;
          "
        >
          <v-col cols="4" id="date_auditoria">
            <div @click="changeType()">
              <v-select
                v-model="typeConsulta"
                style="padding-top: 20px"
                :items="itemsConsulta"
                label="Tipo de consulta"
                dense
              ></v-select>              
            </div>

            <VueCtkDateTimePicker
              style="width: 100%; margin-top: 80px; margin-bottom: 40px"
              v-model="dateSet"
              :range="true"
              label="Seleccionar rango de fecha"
            />

            <p style="font-size: 14px; text-align: justify">
              El archivo descargado será de tipo texto separado por comas (CSV) y contendrá la 
              información solicitada de acuerdo a los criterios seleccionados.
            </p>
          </v-col>
          <v-col cols="4" style="margin-left: 5px; margin-top: 5px">
            <v-select
              v-model="selectProject"
              v-if=" typeConsulta == 'EDICIÓN'"
              style="padding-top: 120px"
              :items="projectsFormat"
              label="Seleccione el proyecto"
              dense
            ></v-select>
            <div @click="userData()">
              <v-select
                v-model="selectUser"
                :style="typeConsulta == 'EDICIÓN'
                    ? 'padding-top: 10px;'
                    : 'padding-top: 140px'
                "
                :items="dataUser"
                label="Seleccione el usuario"
                dense
              ></v-select>
            </div>
            <v-btn
              :style="typeConsulta == 'EDICIÓN'
                  ? 'margin-top: 30px!important;'
                  : 'margin-top: 60px!important;'
              "
              class="ma-2"
              color="success"
              @click="consultLog()"
            >
              Descargar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="1"></v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import api from "@/api";

export default {
  name: "auditoriaUsu",
  data() {
    return {
      selectUser: null,
      dataUser: [],
      text: "",
      dateSet: null,
      itemsConsulta: [
        {
          text: "General",
          value: "GENERAL"
        },
        { text:"Administración",
          value: "ADMINISTRACIÓN"
        }, 
        { text:"Edición",
          value: "EDICIÓN"
        },
      ],
      typeConsulta: "",
      dataUsers: null,
      projectsFormat: [],
      selectProject: null,
    };
  },
  mounted() {
    this.getUsers();
    this.getprojects();
  },
  methods: {
    getUsers: async function () {
      var clientsTmp = [];
      var self = this;
      await api
        .request(
          "get",
          "/listUsers/" + JSON.parse(localStorage.user).client_id + "/clients"
        )
        .then((data) => {
          clientsTmp = data.data.users;
        })
        .catch(function (e) {
          console.log(e);
        });
      self.dataUsers = clientsTmp;
    },
    getprojects: async function () {
      var projectsTmp = [];
      this.projects = [];

      await api
        .request(
          "post",
          "/getProjectsForUser/" + JSON.parse(localStorage.user).id
        )
        .then((data) => {
          projectsTmp = data.data;
        })
        .catch(function (e) {
          console.log(e);
        });
      console.log(projectsTmp);
      this.projects = projectsTmp;

      for (let property1 in projectsTmp) {
        var tmp_obj = {
          value: this.projects[property1][0].id,
          text: this.projects[property1][0].name,
        };
        this.projectsFormat.push(tmp_obj);
      }
      console.log(this.projectsFormat);
    },
    userData: function () {
      var self = this;
      if (this.typeConsulta == "GENERAL") {
        this.dataUser = [];
        this.dataUsers.map(function (element) {
          self.dataUser.push({
            text: element.name,
            value: element.id,
          });
        });
      } else if (this.typeConsulta == "ADMINISTRACIÓN") {
        this.dataUser = [];
        console.log(this.dataUsers);
        this.dataUsers.map(function (element) {
          if (element.roleId.length > 0) {
            element.roleId.map(function (element1) {
              if (element1.role_id == 1) {
                self.dataUser.push({
                  text: element.name,
                  value: element.id,
                });
              }
            });
          }
        });
      } else if (this.typeConsulta == "EDICIÓN") {
        this.dataUser = [];
        console.log(this.dataUsers)
        this.dataUsers.map(function (element) {
          if (element.roleId.length > 0) {
            element.roleId.map(function (element1) {
              if (
                self.selectProject == element1.project_id
              ) {
                self.dataUser.push({
                  text: element.name,
                  value: element.id,
                });
              }else if(element1.role_id == 1){ 
                self.dataUser.push({
                  text: element.name,
                  value: element.id,
                });
              }
            });
          }
        });
      }
      console.log(self.dataUser);
    },
    changeType: async function(){
      this.selectUser = null;
      this.selectProject = null;
    },
    consultLog: async function () {
      console.log(this.dateSet)
      if(this.dateSet != null ? (this.dateSet.end == null && this.dateSet.start != null) : false){
        if(this.selectProject != null != this.selectUser != null){
          var obj = {
            idUser: this.selectUser,
            idProject: this.selectProject,
            keyTime: "date",
            tipo: this.typeConsulta,
            fecha: this.dateSet.start.slice(0,-3),
          };
        }else if(this.selectUser == null) {
          if(this.selectProject != null){
            obj = {
              idProject: this.selectProject,
              keyTime: "date",
              tipo: this.typeConsulta,
              fecha: this.dateSet.start.slice(0,-3),
            };
          }else{
            obj = {
              keyTime: "date",
              tipo: this.typeConsulta,
              fecha: this.dateSet.start.slice(0,-3),
            };
          }
        } else {
          obj = {
            keyTime: "date",
            tipo: this.typeConsulta,
            fecha: this.dateSet.start.slice(0,-3)
          };
        }
      }else if(this.dateSet == null){
        if(this.selectProject != null && this.selectUser != null){
          obj = {
            idUser: this.selectUser,
            idProject: this.selectProject,
            keyTime: "all",
            tipo: this.typeConsulta,
          };
        }else if(this.selectUser == null) {
          if(this.selectProject != null){
            obj = {
              idProject: this.selectProject,
              keyTime: "all",
              tipo: this.typeConsulta,
            };
          }else{
            obj = {
              keyTime: "all",
              tipo: this.typeConsulta,
            };
          }
        } else {
          obj = {
            keyTime: "all",
            tipo: this.typeConsulta,
          };
        }
      }else{
        if(this.selectProject != null && this.dateSet != null){
          obj = {
            idUser: this.selectUser,
            idProject: this.selectProject,
            keyTime: "range",
            tipo: this.typeConsulta,
            fechaInicio:this.dateSet.start.slice(0,-3),
            fechaFin: this.dateSet.end.slice(0,-3)
          };
        }else if(this.selectUser == null) {
          obj = {
            idProject: this.selectProject,
            keyTime: "range",
            tipo: this.typeConsulta,
            fechaInicio:this.dateSet.start.slice(0,-3),
            fechaFin: this.dateSet.end.slice(0,-3)
          };
        } else {
          obj = {
            keyTime: "range",
            tipo: this.typeConsulta,
            fechaInicio:this.dateSet.start.slice(0,-3),
            fechaFin: this.dateSet.end.slice(0,-3)
          };
        }
      }
      
      let date = new Date();
      var name = `SAFERED_Log_${date.getFullYear()}:${date.getMonth()+1}:${date.getDay()}-${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.csv`;
      await axios
        .post("https://api.safered.co/api/getLogForDate", obj, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", name);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
  },
  watch: {
    dateSet: function() {
      console.log(this.dateSet);
    },
    selectProject: function(){
      console.log(this.selectProject);
    }
  },
};
</script>
<style>
div#date_auditoria .datetimepicker.flex.visible {
  top: -460% !important;
}
</style>
