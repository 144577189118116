<template>
    <div class="containt_slide" style="height: 100px; width: 930px;">
        <v-slide-group class="pa-4" show-arrows>
            <v-card v-if="permitEdit" @click="viewModalCreateVistaMBits = true" class="ma-1"
                :height="posiSelect != null ? 70 : 80" :width="posiSelect != null ? 60 : 70"
                style="display: flex; justify-content: center;">
                <div style="display: flex; justify-content: center; border-style: dotted;
                    width: inherit;">
                    <v-icon v-html="'$vuetify.icons.plus'" size="35"></v-icon>
                </div>
                <ModalCreateVistaMBits :permitEdit="permitEdit" :dataMpBits="dataMpBits"
                    :viewModalCreateVistaMBits="viewModalCreateVistaMBits" />
            </v-card>
            <v-slide-item v-for="(dataMpBit, index) in dataMpBits" :key="index">
                <div>
                    <v-card class="ma-1" :height="posiSelect != null ? 70 : 80" :width="posiSelect != null ? 60 : 70"
                        @click="valueSelect(index); posiSelect = index">
                        <v-img style="margin-left: 1px; margin-top: 3px;" :max-height="posiSelect != null ? 48 : 58"
                            :max-width="posiSelect != null ? 48 : 58" :src="logoCanvasMP">
                        </v-img>
                        <infinite-slide-bar>
                            <h5 class="marquee" style="margin-top: 5px; ">
                                {{ dataMpBit.Name }}&nbsp;
                            </h5>
                        </infinite-slide-bar>
                    </v-card>
                    <div v-if="posiSelect == index" style="display: flex; height: 10px; width: 60px;
                        justify-content: center; margin-top: 8px;">
                        <i style="color: white;" class="fa-solid fa-circle fa-2xs"></i>
                    </div>
                </div>
            </v-slide-item>
        </v-slide-group>
    </div>
</template>
<script>

import ModalCreateVistaMBits from '../../../../modals/ModalCreateVistaMBits.vue';
import logoCanvasMP from '../../../../../assets/img/icons/icon_card_canva_mapabits.svg'

export default {
    name: 'vistaMBits',
    components: {
        ModalCreateVistaMBits,
    },
    data() {
        return {
            logoCanvasMP,
            posiSelect: null,
            viewModalCreateVistaMBits: false,
            selectMBits: {
                posicionMBits: null,
                dataGS: null,
            }
        }
    },
    mounted: async function () {
        this.posiSelect = await this.$store.state.posicionselectMbE
        console.log(this.posiSelect);
    },
    props: ['dataMpBits', 'permitEdit'],
    methods: {
        valueSelect: async function (index) {
            this.selectMBits.posicionMBits = index

            this.$store.commit("setPosicionselectMb", await this.selectMBits.posicionMBits)
            this.$store.commit("setPosicionselectMbE", await this.selectMBits.posicionMBits)

            var data = {
                viewprojectsM: false,
                viewprojectSelect: false,
                viewcanvasG: false,
                viewcanvasMb: true,
            }
            this.$store.commit("setselectionCanvasMBits", data);
            this.$store.commit("setPosicionselectCE", null)
        }
    },
    watch: {
        dialog: function () {
            if (this.dialog == true) {
                this.$store.commit("setactiveModalCreateVistaMBits", true);
            }
        },
        '$store.state.activeModalCreateVistaMBits': function () {
            if (this.$store.state.activeModalCreateVistaMBits == false) {
                this.viewModalCreateVistaMBits = this.$store.state.activeModalCreateVistaMBits
            }
        },
        '$store.state.restoreInit': function () {
            this.posiSelect = null
        }
    }
}
</script>
<style>

</style>