<template>
    <div>
        <b-row>
            <b-col style="display: flex; flex-direction: column;
                justify-content: center;" sm="1" v-if="permitEdit">
                <b-card style="display: flex;
                    flex-direction: column;
                    align-items: center;">
                    <v-list>
                        <v-list-item>
                            <b-button type="button" :style="status_P ? button_active : button_noactive"
                                class="btn btn-light struct_btn" id="point" v-on:click="select_point">
                                <i class="fa fas fa-map-marker"></i>
                            </b-button>
                        </v-list-item>
                        <v-list-item>
                            <b-button type="button" :style="status_Sq ? button_active : button_noactive"
                                class="btn btn-light struct_btn" id="square" v-on:click="select_square">
                                <i class="fa fa-solid fa-square"></i>
                            </b-button>
                        </v-list-item>
                        <v-list-item>
                            <b-button type="button" :style="status_Poly ? button_active : button_noactive"
                                class="btn btn-light button_fi struct_btn" id="polygon" v-on:click="select_poly">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
                                    <path
                                        d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z" />
                                </svg>
                            </b-button>
                        </v-list-item>
                        <v-list-item>
                            <b-button type="button" :style="status_circ ? button_active : button_noactive"
                                class="btn btn-light struct_btn" id="circle" v-on:click="select_circle">
                                <i class="fa fa-solid fa-circle"></i>
                            </b-button>
                        </v-list-item>
                        <v-list-item>
                            <b-button type="button" class="btn btn-light button_fi struct_btn"
                                :style="status_lin ? button_active : button_noactive" id="line"
                                v-on:click="select_line">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M476.8 288C461.1 361 397.4 416 320 416C242.6 416 178 361 163.2 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H163.2C178 150.1 242.6 96 320 96C397.4 96 461.1 150.1 476.8 224H608C625.7 224 640 238.3 640 256C640 273.7 625.7 288 608 288H476.8zM320 336C364.2 336 400 300.2 400 256C400 211.8 364.2 176 320 176C275.8 176 240 211.8 240 256C240 300.2 275.8 336 320 336z" />
                                </svg>
                            </b-button>
                        </v-list-item>
                        <v-list-item>
                            <b-button type="button" class="btn btn-light button_fi struct_btn" id="upimg"
                                @click="uploadImageModal()">
                                <i class="fa fa-solid fa-image"></i>
                            </b-button>
                        </v-list-item>
                        <v-list-item>
                            <b-button type="button" class="btn btn-light button_fi struct_btn" id="upimg"
                                v-b-modal.modal-dataC @click="listFileC()">
                                <i class="fa-brands fa-buffer" v-b-popover.hover="" title="Vista"
                                    aria-hidden="true"></i>
                            </b-button>
                        </v-list-item>
                    </v-list>
                </b-card>
            </b-col>
            <b-col sm="11">
                <b-card class="edit" style="height: 530px; overflow-x: scroll; overflow-y: hidden; 
                    display: flex; flex-direction: column;  align-content: center;  align-items: center;">
                    <b-row
                        :style="'background-image: url(' + backgroundImage + `); background-size: 100% 100%; 
                            width: 1000px; height: 450px; margin-left: 5px; margin-top: 10px;`">
                        <svg width="550" height="500" :id="referencia" v-on:click="drawLClick"
                            v-on:mousedown="drawLDown" v-on:mouseup="drawLUp">
                        </svg>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-modal hide-backdrop centered size="sm" id="modal-dataC" :title="`Informacion de la vista ${dataI.name}`">

            <b-dropdown style="display: grid;" block id="dropdown-dropright" dropright text="Ver archivos"
                variant="light" class="m-2 drop_L">
                <b-dropdown-item v-b-modal.modal-tagsList v-for="(tagsLC, inde) in tagsListC" :key="inde"
                    @click="ordenarForTagC(tagsLC);">
                    {{ tagsLC }}

                    <b-modal id="modal-tagsList" size="lg" hide-footer centered hide-backdrop scrollable
                        content-class="shadow" :title="'Ordenado por ' + tagsLC">
                        <b-list-group>
                            <b-list-group-item v-for="(fileC, inde1) in filesC" :key="inde1">
                                <v-row>
                                    <v-col sm="9" style="text-align: initial;">
                                        <v-row>
                                            <v-col>
                                                {{ fileC.text }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="2">
                                                <h6>Ubicacion: </h6>
                                            </v-col>
                                            <v-col sm="10">
                                                {{ fileC.namePoint }}
                                            </v-col>
                                        </v-row>
                                        <p style="font-style: oblique;">{{ fileC.created_at }}</p>
                                        <p style="font-style: oblique;">{{ fileC.tag }}</p>
                                    </v-col>
                                    <v-col sm="1" style="text-align: center;">
                                        <br />
                                        <i style="cursor: pointer;" class="fa fa-cloud-download fa-lg"
                                            @click="downloadFile(fileC.path, fileC.text)" aria-hidden="true"></i>
                                    </v-col>

                                    <v-col sm="1" style="text-align: center;">
                                        <br />
                                        <a v-if="fileC.relacion.Hijos.length > 0 || fileC.relacion.Padres.length > 0"
                                            style="cursor: pointer;" :id="'popover-button-variantC' + inde + '-' + inde1"
                                            tabindex="0">
                                            <v-badge bordered color="#ff0000" icon="mdi-alert-circle" overlap>
                                                <v-icon color="#000000">
                                                    mdi-link-variant-plus
                                                </v-icon>
                                            </v-badge>
                                        </a>

                                        <a v-if="fileC.relacion.Hijos.length == 0 && fileC.relacion.Padres.length == 0"
                                            style="cursor: pointer;" :id="'popover-button-variantC' + inde + '-' + inde1"
                                            tabindex="0">
                                            <v-icon color="#000000">
                                                mdi-link-variant-plus
                                            </v-icon>
                                        </a>

                                        <b-popover :target="'popover-button-variantC' + inde + '-' + inde1"
                                            :id="'popover-button-variantC' + inde + '-' + inde1" variant="ligth"
                                            triggers="focus">

                                            <b-button-group vertical>
                                                <b-button v-b-modal.modal-multi-list v-b-tooltip.hover.left
                                                    title="Relacion precedencia" @click="selectListRCG(inde, inde1)"
                                                    variant="light">
                                                    <b-icon icon="box-arrow-in-down" aria-hidden="true"></b-icon>
                                                    <b-badge pill style="background-color: #19A2FA;">{{
                                                        fileC.relacion.Padres.length
                                                    }}</b-badge>
                                                </b-button>
                                                <b-button v-b-modal.modal-multi-listH v-b-tooltip.hover.left
                                                    title="Relacion antelación" @click="selectListRCH(inde, inde1)"
                                                    variant="light">
                                                    <b-icon icon="diagram3" aria-hidden="true"></b-icon> <b-badge pill
                                                        style="background-color: #19A2FA;">{{
                                                            fileC.relacion.Hijos.length
                                                        }}</b-badge>
                                                </b-button>
                                                <b-button v-b-modal.modal-multi-listC v-b-tooltip.hover.left
                                                    title="Crear relacion"
                                                    @click="listTagandFileR(fileC.text); selectListRCC(inde, inde1);"
                                                    variant="light">
                                                    <i class="fa fa-plus"
                                                        aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </b-button>
                                            </b-button-group>
                                        </b-popover>

                                        <b-modal v-if="fileC.relacion.Padres.length > 0"
                                            :id="'modal-multi-listC-'+ inde + '-' + inde1" size="lg" centered scrollable hide-footer
                                            :title="'Relacion anterior de ' + fileC.text">
                                            <b-list-group>
                                                <b-list-group-item v-for="(listR, inde11) in fileC.relacion.Padres"
                                                    :key="inde11">
                                                    <v-row>
                                                        <v-col sm="9" style="text-align: initial;">
                                                            <v-row>
                                                                <v-col>
                                                                    {{ listR.title }}
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col sm="2">
                                                                    <h6>Ubicacion: </h6>
                                                                </v-col>
                                                                <v-col sm="10">
                                                                    {{ listR.ubicacion }}
                                                                </v-col>
                                                            </v-row>
                                                            <p style="font-style: oblique;">{{ listR.created_at }}</p>
                                                            <p style="font-style: oblique;">{{ listR.tag }} - por:
                                                                {{ listR.name_user }}</p>
                                                        </v-col>

                                                        <v-col sm="1" style="text-align: center;">
                                                            <br />
                                                            <i style="cursor: pointer;"
                                                                class="fa fa-cloud-download fa-lg"
                                                                @click="downloadFile(listR.path, listR.title)"
                                                                aria-hidden="true"></i>
                                                        </v-col>

                                                        <v-col
                                                            v-if="listR.path.split('.')[1] == 'png' || listR.path.split('.')[1] == 'jpg'"
                                                            sm="1" style="text-align: center;">
                                                            <br />
                                                            <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                aria-hidden="true" @click="viewFile(listR.path)"></i>
                                                        </v-col>

                                                        <v-col v-if="listR.path.split('.')[1] == 'pdf'" sm="1"
                                                            style="text-align: center;">
                                                            <br />
                                                            <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                class="fa fa-eye fa-lg" aria-hidden="true"
                                                                @click="viewFile(listR.path); selectPPDF(inde, inde1, inde11)"></i>

                                                            <b-modal
                                                                :id="'modal-multi-' + inde + '-' + inde1 + '-' + inde11"
                                                                size="lg" centered scrollable hide-footer hide-backdrop
                                                                content-class="shadow"
                                                                :title="'Visualización de ' + listR.title">
                                                                <v-row>
                                                                    <v-col>
                                                                        {{ currentPage }} / {{ pageCount }}
                                                                    </v-col>
                                                                </v-row>
                                                                <pdf v-for="i in numPages" :key="i" :src="fileView"
                                                                    :page="i" style="display: inline-block; width: 50%"
                                                                    @num-pages="pageCount = $event"
                                                                    @page-loaded="currentPage = $event"></pdf>
                                                            </b-modal>
                                                        </v-col>
                                                    </v-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-modal>

                                        <b-modal v-if="fileC.relacion.Hijos.length > 0"
                                            :id="'modal-multi-listCH-' + inde + '-' + inde1" size="lg" centered scrollable hide-footer
                                            :title="'Relacion posterior de ' + fileC.text">
                                            <b-list-group>
                                                <b-list-group-item v-for="(listRH, inde11) in fileC.relacion.Hijos"
                                                    :key="listRH.id">
                                                    <v-row>
                                                        <v-col sm="9" style="text-align: initial;">
                                                            <v-row>
                                                                <v-col>
                                                                    {{ listRH.title }}
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col sm="2">
                                                                    <h6>Ubicacion: </h6>
                                                                </v-col>
                                                                <v-col sm="10">
                                                                    {{ listRH.ubicacion }}
                                                                </v-col>
                                                            </v-row>
                                                            <p style="font-style: oblique;">{{ listRH.created_at }}</p>
                                                            <p style="font-style: oblique;">{{ listRH.tag }} - por:
                                                                {{ listRH.name_user }}</p>
                                                        </v-col>

                                                        <v-col sm="1" style="text-align: center;">
                                                            <br />
                                                            <i style="cursor: pointer;"
                                                                class="fa fa-cloud-download fa-lg"
                                                                @click="downloadFile(listRH.path, listRH.text)"
                                                                aria-hidden="true"></i>
                                                        </v-col>

                                                        <v-col
                                                            v-if="listRH.path.split('.')[1] == 'png' || listRH.path.split('.')[1] == 'jpg'"
                                                            sm="1" style="text-align: center;">
                                                            <br />
                                                            <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                aria-hidden="true" @click="viewFile(listRH.path)"></i>
                                                        </v-col>

                                                        <v-col v-if="listRH.path.split('.')[1] == 'pdf'" sm="1"
                                                            style="text-align: center;">
                                                            <br />
                                                            <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                class="fa fa-eye fa-lg" aria-hidden="true"
                                                                @click="viewFile(listRH.path); selectPPDF(inde, inde1, inde11)"></i>

                                                            <b-modal
                                                                :id="'modal-multi-' + inde + '-' + inde1 + '-' + inde11"
                                                                size="lg" centered scrollable hide-footer hide-backdrop
                                                                content-class="shadow"
                                                                :title="'Visualización de ' + listRH.text">
                                                                <v-row>
                                                                    <v-col>
                                                                        {{ currentPage }} / {{ pageCount }}
                                                                    </v-col>
                                                                </v-row>
                                                                <pdf v-for="i in numPages" :key="i" :src="fileView"
                                                                    :page="i" style="display: inline-block; width: 50%"
                                                                    @num-pages="pageCount = $event"
                                                                    @page-loaded="currentPage = $event"></pdf>
                                                            </b-modal>
                                                        </v-col>
                                                    </v-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-modal>

                                        <b-modal :id="'modal-multi-listCC-' + inde + '-' + inde1" size="lg" centered
                                            hide-footer :title="'Crear relacion para ' + fileC.text">
                                            <v-row>
                                                <v-col sm="9">
                                                    <v-combobox v-model="selectdateFileR" :items="dateFileR"
                                                        label="Ingrese el nombre del archivo" dense
                                                        persistent-hint></v-combobox>
                                                </v-col>
                                                <v-col sm="3"
                                                    style="display: flex; align-items: center; justify-content: center;">
                                                    <b-button variant="outline-info"
                                                        @click="createRel(fileC.idFile);">
                                                        Generar relacion
                                                    </b-button>
                                                </v-col>
                                            </v-row>
                                        </b-modal>
                                    </v-col>

                                    <v-col v-if="fileC.path.split('.')[1] == 'png' || fileC.path.split('.')[1] == 'jpg'"
                                        sm="1" style="text-align: center;">
                                        <br />
                                        <i style="cursor: pointer;" class="fa fa-eye fa-lg" aria-hidden="true"
                                            @click="viewFile(fileC.path)"></i>
                                    </v-col>

                                    <v-col v-if="fileC.path.split('.')[1] == 'pdf'" sm="1" style="text-align: center;">
                                        <br />
                                        <i style="cursor: pointer;" v-b-modal.modal-multi class="fa fa-eye fa-lg"
                                            aria-hidden="true"
                                            @click="viewFile(fileC.path); selectPPDF(inde, inde1)"></i>

                                        <b-modal :id="'modal-multi-' + inde + '-' + inde1" size="lg" centered scrollable
                                            hide-footer hide-backdrop content-class="shadow"
                                            :title="'Visualización de ' + fileC.text">
                                            <v-row>
                                                <v-col>
                                                    {{ currentPage }} / {{ pageCount }}
                                                </v-col>
                                            </v-row>
                                            <pdf v-for="i in numPages" :key="i" :src="fileView" :page="i"
                                                style="display: inline-block; width: 50%"
                                                @num-pages="pageCount = $event" @page-loaded="currentPage = $event">
                                            </pdf>
                                        </b-modal>
                                    </v-col>

                                    <v-col
                                        v-if="fileC.path.split('.')[1] == 'xlsx' || fileC.path.split('.')[1] == 'pptx' || fileC.path.split('.')[1] == 'docx'"
                                        sm="1" style="text-align: center;">
                                        <br />
                                        <i style="cursor: pointer;" v-b-modal.modal-multi class="fa fa-eye fa-lg"
                                            aria-hidden="true"
                                            @click="viewFile(fileC.path), selectPPDF(inde, inde1)"></i>

                                        <b-modal :id="'modal-multi-' + inde + '-' + inde1" size="lg" centered scrollable
                                            hide-footer hide-backdrop content-class="shadow"
                                            :title="'Visualización de ' + fileC.text">
                                            <VueDocPreview v-if="fileView != null" :value="fileView" type="office" />
                                        </b-modal>
                                    </v-col>

                                </v-row>
                            </b-list-group-item>
                        </b-list-group>
                    </b-modal>
                </b-dropdown-item>
            </b-dropdown>

            <div style="margin: 0.5rem !important;">
                <b-btn variant="light" class="btn_drop" v-b-modal.modal-uploadCM>
                    Cargar archivo
                </b-btn>
            </div>
            <b-modal hide-footer size="lg" id="modal-uploadCM" hide-backdrop centered content-class="shadow"
                :title="'Subir archivo en el canvas ' + dataI.name">
                <v-row style="display: flex;
                    justify-content: flex-end;">
                    <v-col cols="3">
                        <v-checkbox v-if="selectedTypeServer == 'GoogleDrive'" v-model="codeActive"
                            label="Asociar por codigo"></v-checkbox>
                    </v-col>
                    <v-col cols="4" style="display: flex;
                    align-items: center;">
                        <v-select v-model="selectedTypeServer" :items="typeServer" label="Seleccione servidor destino"
                            height="25" dense>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-file-input dense height="25" v-model="documentC"
                            label="Seleccionar un archivo"></v-file-input>
                    </v-col>
                    <v-col cols="6">
                        <v-select height="25" dense v-model="selectedTypeDocument" :items="typeDocument"
                            label="Seleccione tipo de documento"></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="selectedTypeServer == 'GoogleDrive' && !codeActive" cols="12">
                        <v-row v-if="!structThreeF">
                            <v-col>
                                <v-chip class="ma-2" label>
                                    <p v-if="folderSelectN.length == 0" style="margin-top: 0.5rem;
                                    margin-bottom: 0.5rem;">No se ha seleccionado carpeta</p>
                                    <p v-else>carpeta seleccionado: {{ folderSelectN }}</p>
                                </v-chip>
                                <v-btn elevation="2" color="#009688" style="color: white;" fab small
                                    @click="structThreeF = true">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-responsive v-if="structThreeF" style="overflow: scroll;" class="overflow-y-auto"
                            max-height="250">
                            <v-treeview v-model="treeTest" :open="initiallyOpen" :items="folderGoogle" activatable
                                item-key="text" item-text="text">
                                <template v-slot:prepend="{ item, open }">
                                    <v-icon @click="selectFolderGoogle = item.id; structThreeF = false;
                                    folderSelectN = item.text">
                                        {{ open? 'mdi-folder-open': 'mdi-folder' }}
                                    </v-icon>
                                </template>
                            </v-treeview>
                        </v-responsive>
                    </v-col>
                    <v-col v-if="codeActive" cols="6">
                        <v-autocomplete height="25" dense v-model="selectCode" :items="listCode"
                            label="Seleccione el codigo"></v-autocomplete>
                    </v-col>
                </v-row>
                <br />
                <v-row>
                    <v-col>
                        <b-button :disabled="!documentC && !selectedTypeDocument" variant="primary" type="submit"
                            @click="uploadDocumentsCM();">
                            Cargar archivo
                        </b-button>

                        <v-dialog transition="dialog-top-transition" max-width="600" v-model="dialogUpCopyC">
                            <v-card>
                                <v-card-text>
                                    <div class="text-h6 pa-12">
                                        El archivo que intenta cargar esta duplicado.
                                    </div>
                                    <p>Ingrese un nombre diferente o dejar sin editar asignar un consecutivo</p>
                                    <v-text-field :label="nameFileRandom" hide-details="auto" v-model="mameFileUp"
                                        :rules="fileRules"></v-text-field>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                    <v-btn :disabled="!statusVerify" color="#57B55D" class="ma-2" style="color: white;"
                                        @click="reeSendFile('Canva');">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-col>
                </v-row>
            </b-modal>

            <b-dropdown style="display: grid;" block id="dropdown-dropright" dropright text="Areas" variant="light"
                class="m-2 drop_L">
                <b-dropdown-item>
                    <b-dropdown-item
                        @click="loadDatafigure(figure.name, 'clickF'); $bvModal.show(`modal-figureViewC-${referencia}-${index}`);"
                        v-for="(figure, index) in polygonsLoadedMpBits" :key="index">
                        {{ figure.name }}

                        <b-modal hide-backdrop centered size="sm" :id="`modal-figureViewC-${referencia}-${index}`"
                            :title="`Informacion del Area ${figure.name}`">

                            <b-dropdown style="display: grid;" block id="dropdown-dropright" dropright
                                text="Ver archivos" variant="light" class="m-2 drop_L">
                                <b-dropdown-item @click="filtTagsLG(tag); $bvModal.show(`modal-fileView-${index}`)"
                                    v-for="(tag, index) in tagsListG" :key="index.id">
                                    {{ tag }}

                                    <b-modal :title="'Archivos de ' + nameFigure" centered scrollable size="lg"
                                        :id="`modal-fileView-${index}`" hide-footer>
                                        <v-row v-if="filesG.length > 0">
                                            <b-list-group>
                                                <b-list-group-item v-for="(fileG, index1) in filesG" :key="fileG.id">
                                                    <v-row style="margin-top: 5px;">
                                                        <v-col sm="9" style="text-align: initial;">
                                                            <v-row>
                                                                <v-col cols="4" style="padding: 3px;">
                                                                    <h6>Nombre del Archivo:</h6>
                                                                </v-col>
                                                                <v-col cols="8" style="padding: 3px;">
                                                                    {{ fileG.text }}
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="2" style="padding: 3px;">
                                                                    <h6>Ubicacion: </h6>
                                                                </v-col>
                                                                <v-col cols="10" v-if="fileG.namePoint != undefined"
                                                                    style="padding: 3px;">
                                                                    {{ fileG.namePoint }}
                                                                </v-col>
                                                                <v-col cols="10" v-if="fileG.nameArea != undefined"
                                                                    style="padding: 3px;">
                                                                    {{ fileG.nameArea }}
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="2" style="padding: 3px;">
                                                                    <h6>Tipo:</h6>
                                                                </v-col>
                                                                <v-col cols="10" style="padding: 3px;">
                                                                    <p style="font-style: oblique;">{{ fileG.tag }}</p>
                                                                </v-col>
                                                            </v-row>
                                                            <p style="font-style: oblique;">{{ fileG.created_at }}</p>
                                                        </v-col>
                                                        <v-col cols="1" style="text-align: center;">
                                                            <br />
                                                            <i style="cursor: pointer;"
                                                                class="fa fa-cloud-download fa-lg"
                                                                @click="downloadFile(fileG.path, fileG.text)"
                                                                aria-hidden="true"></i>
                                                        </v-col>

                                                        <v-col sm="1" style="text-align: center;">
                                                            <br />
                                                            <a v-if="fileG.relacion.Hijos.length > 0 || fileG.relacion.Padres.length > 0"
                                                                style="cursor: pointer;" :id="'popover-button-variantCA' + index + '-' + index1"
                                                                tabindex="0">
                                                                <v-badge bordered color="#ff0000" icon="mdi-alert-circle" overlap>
                                                                    <v-icon color="#000000">
                                                                        mdi-link-variant-plus
                                                                    </v-icon>
                                                                </v-badge>
                                                            </a>
                    
                                                            <a v-if="fileG.relacion.Hijos.length == 0 && fileG.relacion.Padres.length == 0"
                                                                style="cursor: pointer;" :id="'popover-button-variantCA' + index + '-' + index1"
                                                                tabindex="0">
                                                                <v-icon color="#000000">
                                                                    mdi-link-variant-plus
                                                                </v-icon>
                                                            </a>
                    
                                                            <b-popover :target="'popover-button-variantCA' + index + '-' + index1"
                                                                :id="'popover-button-variantCA' + index + '-' + index1" variant="ligth"
                                                                triggers="focus">
                    
                                                                <b-button-group vertical>
                                                                    <b-button v-b-modal.modal-multi-list v-b-tooltip.hover.left
                                                                        title="Relacion precedencia" @click="selectListRCGA(index, index1)"
                                                                        variant="light">
                                                                        <b-icon icon="box-arrow-in-down" aria-hidden="true"></b-icon>
                                                                        <b-badge pill style="background-color: #19A2FA;">{{
                                                                            fileG.relacion.Padres.length
                                                                        }}</b-badge>
                                                                    </b-button>
                                                                    <b-button v-b-modal.modal-multi-listH v-b-tooltip.hover.left
                                                                        title="Relacion antelación" @click="selectListRCHA(index, index1)"
                                                                        variant="light">
                                                                        <b-icon icon="diagram3" aria-hidden="true"></b-icon> <b-badge pill
                                                                            style="background-color: #19A2FA;">{{
                                                                                fileG.relacion.Hijos.length
                                                                            }}</b-badge>
                                                                    </b-button>
                                                                    <b-button v-b-modal.modal-multi-listC v-b-tooltip.hover.left
                                                                        title="Crear relacion"
                                                                        @click="listTagandFileR(fileG.text); selectListRCCA(index, index1);"
                                                                        variant="light">
                                                                        <i class="fa fa-plus"
                                                                            aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </b-button>
                                                                </b-button-group>
                                                            </b-popover>
                    
                                                            <b-modal v-if="fileG.relacion.Padres.length > 0"
                                                                :id="'modal-multi-listCA-'+ index + '-' + index1" size="lg" centered scrollable hide-footer
                                                                :title="'Relacion anterior de ' + fileG.text">
                                                                <b-list-group>
                                                                    <b-list-group-item v-for="(listR, inde11) in fileG.relacion.Padres"
                                                                        :key="inde11">
                                                                        <v-row>
                                                                            <v-col sm="9" style="text-align: initial;">
                                                                                <v-row>
                                                                                    <v-col>
                                                                                        {{ listR.title }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row>
                                                                                    <v-col sm="2">
                                                                                        <h6>Ubicacion: </h6>
                                                                                    </v-col>
                                                                                    <v-col sm="10">
                                                                                        {{ listR.ubicacion }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <p style="font-style: oblique;">{{ listR.created_at }}</p>
                                                                                <p style="font-style: oblique;">{{ listR.tag }} - por:
                                                                                    {{ listR.name_user }}</p>
                                                                            </v-col>
                    
                                                                            <v-col sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    class="fa fa-cloud-download fa-lg"
                                                                                    @click="downloadFile(listR.path, listR.title)"
                                                                                    aria-hidden="true"></i>
                                                                            </v-col>
                    
                                                                            <v-col
                                                                                v-if="listR.path.split('.')[1] == 'png' || listR.path.split('.')[1] == 'jpg'"
                                                                                sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                                    aria-hidden="true" @click="viewFile(listR.path)"></i>
                                                                            </v-col>
                    
                                                                            <v-col v-if="listR.path.split('.')[1] == 'pdf'" sm="1"
                                                                                style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                                    class="fa fa-eye fa-lg" aria-hidden="true"
                                                                                    @click="viewFile(listR.path); selectPPDF(inde, inde1, inde11)"></i>
                    
                                                                                <b-modal
                                                                                    :id="'modal-multi-' + inde + '-' + inde1 + '-' + inde11"
                                                                                    size="lg" centered scrollable hide-footer hide-backdrop
                                                                                    content-class="shadow"
                                                                                    :title="'Visualización de ' + listR.title">
                                                                                    <v-row>
                                                                                        <v-col>
                                                                                            {{ currentPage }} / {{ pageCount }}
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <pdf v-for="i in numPages" :key="i" :src="fileView"
                                                                                        :page="i" style="display: inline-block; width: 50%"
                                                                                        @num-pages="pageCount = $event"
                                                                                        @page-loaded="currentPage = $event"></pdf>
                                                                                </b-modal>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </b-list-group-item>
                                                                </b-list-group>
                                                            </b-modal>
                    
                                                            <b-modal v-if="fileG.relacion.Hijos.length > 0"
                                                                :id="'modal-multi-listCHA-' + index + '-' + index1" size="lg" centered scrollable hide-footer
                                                                :title="'Relacion posterior de ' + fileG.text">
                                                                <b-list-group>
                                                                    <b-list-group-item v-for="(listRH, inde11) in fileG.relacion.Hijos"
                                                                        :key="listRH.id">
                                                                        <v-row>
                                                                            <v-col sm="9" style="text-align: initial;">
                                                                                <v-row>
                                                                                    <v-col>
                                                                                        {{ listRH.title }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row>
                                                                                    <v-col sm="2">
                                                                                        <h6>Ubicacion: </h6>
                                                                                    </v-col>
                                                                                    <v-col sm="10">
                                                                                        {{ listRH.ubicacion }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <p style="font-style: oblique;">{{ listRH.created_at }}</p>
                                                                                <p style="font-style: oblique;">{{ listRH.tag }} - por:
                                                                                    {{ listRH.name_user }}</p>
                                                                            </v-col>
                    
                                                                            <v-col sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    class="fa fa-cloud-download fa-lg"
                                                                                    @click="downloadFile(listRH.path, listRH.text)"
                                                                                    aria-hidden="true"></i>
                                                                            </v-col>
                    
                                                                            <v-col
                                                                                v-if="listRH.path.split('.')[1] == 'png' || listRH.path.split('.')[1] == 'jpg'"
                                                                                sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                                    aria-hidden="true" @click="viewFile(listRH.path)"></i>
                                                                            </v-col>
                    
                                                                            <v-col v-if="listRH.path.split('.')[1] == 'pdf'" sm="1"
                                                                                style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                                    class="fa fa-eye fa-lg" aria-hidden="true"
                                                                                    @click="viewFile(listRH.path); selectPPDF(inde, inde1, inde11)"></i>
                    
                                                                                <b-modal
                                                                                    :id="'modal-multi-' + inde + '-' + inde1 + '-' + inde11"
                                                                                    size="lg" centered scrollable hide-footer hide-backdrop
                                                                                    content-class="shadow"
                                                                                    :title="'Visualización de ' + listRH.text">
                                                                                    <v-row>
                                                                                        <v-col>
                                                                                            {{ currentPage }} / {{ pageCount }}
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <pdf v-for="i in numPages" :key="i" :src="fileView"
                                                                                        :page="i" style="display: inline-block; width: 50%"
                                                                                        @num-pages="pageCount = $event"
                                                                                        @page-loaded="currentPage = $event"></pdf>
                                                                                </b-modal>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </b-list-group-item>
                                                                </b-list-group>
                                                            </b-modal>
                    
                                                            <b-modal :id="'modal-multi-listCCA-' + index + '-' + index1" size="lg" centered
                                                                hide-footer :title="'Crear relacion para ' + fileG.text">
                                                                <v-row>
                                                                    <v-col sm="9">
                                                                        <v-combobox v-model="selectdateFileR" :items="dateFileR"
                                                                            label="Ingrese el nombre del archivo" dense
                                                                            persistent-hint></v-combobox>
                                                                    </v-col>
                                                                    <v-col sm="3"
                                                                        style="display: flex; align-items: center; justify-content: center;">
                                                                        <b-button variant="outline-info"
                                                                            @click="createRel(fileG.idFile);">
                                                                            Generar relacion
                                                                        </b-button>
                                                                    </v-col>
                                                                </v-row>
                                                            </b-modal>
                                                        </v-col>

                                                    </v-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </v-row>
                                    </b-modal>

                                </b-dropdown-item>
                            </b-dropdown>


                            <div style="margin: 0.5rem !important;">
                                <b-btn variant="light" class="btn_drop" v-b-modal.modal-ListUp>
                                    Cargar archivo
                                </b-btn>
                            </div>
                            <b-modal size="lg" id="modal-ListUp" hide-footer hide-backdrop centered>
                                <v-row>
                                    <v-col>
                                        <v-row style="display: flex;
                                            justify-content: flex-end;">
                                            <v-col cols="3">
                                                <v-checkbox v-if="selectedTypeServer == 'GoogleDrive'"
                                                    v-model="codeActive" label="Asociar por codigo"></v-checkbox>
                                            </v-col>
                                            <v-col cols="4" style="display: flex;
                                            align-items: center;">
                                                <v-select v-model="selectedTypeServer" :items="typeServer"
                                                    label="Seleccione servidor destino" height="25" dense>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-file-input dense height="25" v-model="documentF"
                                                    label="Seleccionar un archivo"></v-file-input>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select height="25" dense v-model="selectedTypeDocument"
                                                    :items="typeDocument"
                                                    label="Seleccione tipo de documento"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col v-if="selectedTypeServer == 'GoogleDrive' && !codeActive" cols="12">
                                                <v-row v-if="!structThreeF">
                                                    <v-col>
                                                        <v-chip class="ma-2" label>
                                                            <p v-if="folderSelectN.length == 0" style="margin-top: 0.5rem;
                                                            margin-bottom: 0.5rem;">No se ha seleccionado carpeta</p>
                                                            <p v-else>carpeta seleccionado: {{ folderSelectN }}</p>
                                                        </v-chip>
                                                        <v-btn elevation="2" color="#009688" style="color: white;" fab
                                                            small @click="structThreeF = true">
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>

                                                <v-responsive v-if="structThreeF" style="overflow: scroll;"
                                                    class="overflow-y-auto" max-height="250">
                                                    <v-treeview v-model="treeTest" :open="initiallyOpen"
                                                        :items="folderGoogle" activatable item-key="text"
                                                        item-text="text">
                                                        <template v-slot:prepend="{ item, open }">
                                                            <v-icon @click="selectFolderGoogle = item.id; structThreeF = false;
                                                            folderSelectN = item.text">
                                                                {{ open? 'mdi-folder-open': 'mdi-folder' }}
                                                            </v-icon>
                                                        </template>
                                                    </v-treeview>
                                                </v-responsive>
                                            </v-col>
                                            <v-col v-if="codeActive" cols="6">
                                                <v-autocomplete height="25" dense v-model="selectCode" :items="listCode"
                                                    label="Seleccione el codigo"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn :disabled="documentF == null || selectedTypeDocument == null"
                                                    @click="uploadDocumentsP(figure.name, 'Canva_Area');" class="ma-2"
                                                    style="background-color: #198754; color: white;">
                                                    Cargar archivo
                                                </v-btn>

                                                <v-dialog transition="dialog-top-transition" max-width="600"
                                                    v-model="dialogUpCopyA2">
                                                    <v-card>
                                                        <v-card-text>
                                                            <div class="text-h6 pa-12">
                                                                El archivo que intenta cargar esta duplicado.
                                                            </div>
                                                            <p>Ingrese un nombre diferente o dejar sin editar asignar un
                                                                consecutivo</p>
                                                            <v-text-field :label="nameFileRandom" hide-details="auto"
                                                                v-model="mameFileUp" :rules="fileRules"></v-text-field>
                                                        </v-card-text>
                                                        <v-card-actions class="justify-end">
                                                            <v-btn :disabled="!statusVerify" color="#57B55D"
                                                                class="ma-2" style="color: white;"
                                                                @click="reeSendFile('Area');">
                                                                Guardar
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>

                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                            </b-modal>

                            <b-dropdown style="display: grid;" block id="dropdown-dropright" dropright text="Puntos"
                                variant="light" class="m-2 drop_L">

                                <b-dropdown-item
                                    @click="loadDatafigure(anillo.text, 'clickM'); $bvModal.show(`modal-pointView-${referencia}-${indx}`);"
                                    v-for="(anillo, indx) in puntoFind" :key="indx">
                                    {{ anillo.text }}

                                    <b-modal hide-backdrop centered size="sm"
                                        :id="`modal-pointView-${referencia}-${indx}`"
                                        :title="`Informacion del Punto ${anillo.text}`">

                                        <b-dropdown style="display: grid; padding-top: 12px;" block
                                            id="dropdown-dropright" dropright text="Ver archivos" variant="light"
                                            class="m-2 drop_L">
                                            <b-dropdown-item
                                                @click="filtTagsLGP(tag); $bvModal.show(`modal-fileView-${referencia}-${indx}-${inx}`)"
                                                v-for="(tag, inx) in tagsListP" :key="inx">
                                                {{ tag }}

                                                <b-modal :title="'Archivos ordenado por ' + tag" centered scrollable
                                                    size="lg" :id="`modal-fileView-${referencia}-${indx}-${inx}`"
                                                    hide-footer>
                                                    <v-row v-if="filesG.length > 0">
                                                        <b-list-group>
                                                            <b-list-group-item v-for="(fileG, index) in filesG"
                                                                :key="fileG.id">
                                                                <v-row style="margin-top: 5px;">
                                                                    <v-col sm="9" style="text-align: initial;">
                                                                        <v-row>
                                                                            <v-col cols="4" style="padding: 3px;">
                                                                                <h6>Nombre del Archivo:</h6>
                                                                            </v-col>
                                                                            <v-col cols="8" style="padding: 3px;">
                                                                                {{ fileG.text }}
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col cols="2" style="padding: 3px;">
                                                                                <h6>Ubicacion: </h6>
                                                                            </v-col>
                                                                            <v-col cols="10"
                                                                                v-if="fileG.namePoint != undefined"
                                                                                style="padding: 3px;">
                                                                                {{ fileG.namePoint }}
                                                                            </v-col>
                                                                            <v-col cols="10"
                                                                                v-if="fileG.nameArea != undefined"
                                                                                style="padding: 3px;">
                                                                                {{ fileG.nameArea }}
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col cols="2" style="padding: 3px;">
                                                                                <h6>Tipo:</h6>
                                                                            </v-col>
                                                                            <v-col cols="10" style="padding: 3px;">
                                                                                <p style="font-style: oblique;">
                                                                                    {{ fileG.tag }}</p>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <p style="font-style: oblique;">
                                                                            {{ fileG.created_at }}</p>
                                                                    </v-col>
                                                                    <v-col cols="1" style="text-align: center;">
                                                                        <br />
                                                                        <i style="cursor: pointer;"
                                                                            class="fa fa-cloud-download fa-lg"
                                                                            @click="downloadFile(fileG.path, fileG.text)"
                                                                            aria-hidden="true">
                                                                        </i>
                                                                    </v-col>
                                                                    <v-col sm="1" style="text-align: center;">
                                                                        <br />
                                                                        <a v-if="fileG.relacion.Hijos.length > 0 || fileG.relacion.Padres.length > 0"
                                                                            style="cursor: pointer;" :id="'popover-button-variantCAP-' + index"
                                                                            tabindex="0">
                                                                            <v-badge bordered color="#ff0000" icon="mdi-alert-circle" overlap>
                                                                                <v-icon color="#000000">
                                                                                    mdi-link-variant-plus
                                                                                </v-icon>
                                                                            </v-badge>
                                                                        </a>
                                
                                                                        <a v-if="fileG.relacion.Hijos.length == 0 && fileG.relacion.Padres.length == 0"
                                                                            style="cursor: pointer;" :id="'popover-button-variantCAP-' + index"
                                                                            tabindex="0">
                                                                            <v-icon color="#000000">
                                                                                mdi-link-variant-plus
                                                                            </v-icon>
                                                                        </a>
                                
                                                                        <b-popover :target="'popover-button-variantCAP-' + index"
                                                                            :id="'popover-button-variantCAP-' + index" variant="ligth"
                                                                            triggers="focus">
                                
                                                                            <b-button-group vertical>
                                                                                <b-button v-b-modal.modal-multi-list v-b-tooltip.hover.left
                                                                                    title="Relacion precedencia" @click="selectListRCGAP(index)"
                                                                                    variant="light">
                                                                                    <b-icon icon="box-arrow-in-down" aria-hidden="true"></b-icon>
                                                                                    <b-badge pill style="background-color: #19A2FA;">{{
                                                                                        fileG.relacion.Padres.length
                                                                                    }}</b-badge>
                                                                                </b-button>
                                                                                <b-button v-b-modal.modal-multi-listH v-b-tooltip.hover.left
                                                                                    title="Relacion antelación" @click="selectListRCHAP(index)"
                                                                                    variant="light">
                                                                                    <b-icon icon="diagram3" aria-hidden="true"></b-icon> <b-badge pill
                                                                                        style="background-color: #19A2FA;">{{
                                                                                            fileG.relacion.Hijos.length
                                                                                        }}</b-badge>
                                                                                </b-button>
                                                                                <b-button v-b-modal.modal-multi-listC v-b-tooltip.hover.left
                                                                                    title="Crear relacion"
                                                                                    @click="listTagandFileR(fileG.text); selectListRCCAP(index);"
                                                                                    variant="light">
                                                                                    <i class="fa fa-plus"
                                                                                        aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                </b-button>
                                                                            </b-button-group>
                                                                        </b-popover>
                                
                                                                        <b-modal v-if="fileG.relacion.Padres.length > 0"
                                                                            :id="'modal-multi-listCAP-'+ index" size="lg" centered scrollable hide-footer
                                                                            :title="'Relacion anterior de ' + fileG.text">
                                                                            <b-list-group>
                                                                                <b-list-group-item v-for="(listR, inde11) in fileG.relacion.Padres"
                                                                                    :key="inde11">
                                                                                    <v-row>
                                                                                        <v-col sm="9" style="text-align: initial;">
                                                                                            <v-row>
                                                                                                <v-col>
                                                                                                    {{ listR.title }}
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                            <v-row>
                                                                                                <v-col sm="2">
                                                                                                    <h6>Ubicacion: </h6>
                                                                                                </v-col>
                                                                                                <v-col sm="10">
                                                                                                    {{ listR.ubicacion }}
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                            <p style="font-style: oblique;">{{ listR.created_at }}</p>
                                                                                            <p style="font-style: oblique;">{{ listR.tag }} - por:
                                                                                                {{ listR.name_user }}</p>
                                                                                        </v-col>
                                
                                                                                        <v-col sm="1" style="text-align: center;">
                                                                                            <br />
                                                                                            <i style="cursor: pointer;"
                                                                                                class="fa fa-cloud-download fa-lg"
                                                                                                @click="downloadFile(listR.path, listR.title)"
                                                                                                aria-hidden="true"></i>
                                                                                        </v-col>
                                
                                                                                        <v-col
                                                                                            v-if="listR.path.split('.')[1] == 'png' || listR.path.split('.')[1] == 'jpg'"
                                                                                            sm="1" style="text-align: center;">
                                                                                            <br />
                                                                                            <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                                                aria-hidden="true" @click="viewFile(listR.path)"></i>
                                                                                        </v-col>
                                
                                                                                        <v-col v-if="listR.path.split('.')[1] == 'pdf'" sm="1"
                                                                                            style="text-align: center;">
                                                                                            <br />
                                                                                            <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                                                class="fa fa-eye fa-lg" aria-hidden="true"
                                                                                                @click="viewFile(listR.path); selectPPDF(inde, inde1, inde11)"></i>
                                
                                                                                            <b-modal
                                                                                                :id="'modal-multi-' + inde + '-' + inde1 + '-' + inde11"
                                                                                                size="lg" centered scrollable hide-footer hide-backdrop
                                                                                                content-class="shadow"
                                                                                                :title="'Visualización de ' + listR.title">
                                                                                                <v-row>
                                                                                                    <v-col>
                                                                                                        {{ currentPage }} / {{ pageCount }}
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                                <pdf v-for="i in numPages" :key="i" :src="fileView"
                                                                                                    :page="i" style="display: inline-block; width: 50%"
                                                                                                    @num-pages="pageCount = $event"
                                                                                                    @page-loaded="currentPage = $event"></pdf>
                                                                                            </b-modal>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </b-list-group-item>
                                                                            </b-list-group>
                                                                        </b-modal>
                                
                                                                        <b-modal v-if="fileG.relacion.Hijos.length > 0"
                                                                            :id="'modal-multi-listCHAP-' + index" size="lg" centered scrollable hide-footer
                                                                            :title="'Relacion posterior de ' + fileG.text">
                                                                            <b-list-group>
                                                                                <b-list-group-item v-for="(listRH, inde11) in fileG.relacion.Hijos"
                                                                                    :key="listRH.id">
                                                                                    <v-row>
                                                                                        <v-col sm="9" style="text-align: initial;">
                                                                                            <v-row>
                                                                                                <v-col>
                                                                                                    {{ listRH.title }}
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                            <v-row>
                                                                                                <v-col sm="2">
                                                                                                    <h6>Ubicacion: </h6>
                                                                                                </v-col>
                                                                                                <v-col sm="10">
                                                                                                    {{ listRH.ubicacion }}
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                            <p style="font-style: oblique;">{{ listRH.created_at }}</p>
                                                                                            <p style="font-style: oblique;">{{ listRH.tag }} - por:
                                                                                                {{ listRH.name_user }}</p>
                                                                                        </v-col>
                                
                                                                                        <v-col sm="1" style="text-align: center;">
                                                                                            <br />
                                                                                            <i style="cursor: pointer;"
                                                                                                class="fa fa-cloud-download fa-lg"
                                                                                                @click="downloadFile(listRH.path, listRH.text)"
                                                                                                aria-hidden="true"></i>
                                                                                        </v-col>
                                
                                                                                        <v-col
                                                                                            v-if="listRH.path.split('.')[1] == 'png' || listRH.path.split('.')[1] == 'jpg'"
                                                                                            sm="1" style="text-align: center;">
                                                                                            <br />
                                                                                            <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                                                aria-hidden="true" @click="viewFile(listRH.path)"></i>
                                                                                        </v-col>
                                
                                                                                        <v-col v-if="listRH.path.split('.')[1] == 'pdf'" sm="1"
                                                                                            style="text-align: center;">
                                                                                            <br />
                                                                                            <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                                                class="fa fa-eye fa-lg" aria-hidden="true"
                                                                                                @click="viewFile(listRH.path); selectPPDF(inde, inde1, inde11)"></i>
                                
                                                                                            <b-modal
                                                                                                :id="'modal-multi-' + inde + '-' + inde1 + '-' + inde11"
                                                                                                size="lg" centered scrollable hide-footer hide-backdrop
                                                                                                content-class="shadow"
                                                                                                :title="'Visualización de ' + listRH.text">
                                                                                                <v-row>
                                                                                                    <v-col>
                                                                                                        {{ currentPage }} / {{ pageCount }}
                                                                                                    </v-col>
                                                                                                </v-row>
                                                                                                <pdf v-for="i in numPages" :key="i" :src="fileView"
                                                                                                    :page="i" style="display: inline-block; width: 50%"
                                                                                                    @num-pages="pageCount = $event"
                                                                                                    @page-loaded="currentPage = $event"></pdf>
                                                                                            </b-modal>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </b-list-group-item>
                                                                            </b-list-group>
                                                                        </b-modal>
                                
                                                                        <b-modal :id="'modal-multi-listCCAP-' + index" size="lg" centered
                                                                            hide-footer :title="'Crear relacion para ' + fileG.text">
                                                                            <v-row>
                                                                                <v-col sm="9">
                                                                                    <v-combobox v-model="selectdateFileR" :items="dateFileR"
                                                                                        label="Ingrese el nombre del archivo" dense
                                                                                        persistent-hint></v-combobox>
                                                                                </v-col>
                                                                                <v-col sm="3"
                                                                                    style="display: flex; align-items: center; justify-content: center;">
                                                                                    <b-button variant="outline-info"
                                                                                        @click="createRel(fileG.idFile);">
                                                                                        Generar relacion
                                                                                    </b-button>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </b-modal>
                                                                    </v-col>
                                                                </v-row>
                                                            </b-list-group-item>
                                                        </b-list-group>
                                                    </v-row>
                                                </b-modal>

                                            </b-dropdown-item>
                                        </b-dropdown>

                                        <div style="margin: 0.5rem !important; padding-top: 6px;">
                                            <b-btn variant="light" class="btn_drop" v-b-modal.modal-ListUpGP>
                                                Cargar archivo
                                            </b-btn>
                                        </div>
                                        <b-modal size="lg" id="modal-ListUpGP" hide-backdrop hide-footer>
                                            <v-row>
                                                <v-col>
                                                    <v-row style="display: flex;
                                                        justify-content: flex-end;">
                                                        <v-col cols="3">
                                                            <v-checkbox v-if="selectedTypeServer == 'GoogleDrive'"
                                                                v-model="codeActive"
                                                                label="Asociar por codigo"></v-checkbox>
                                                        </v-col>
                                                        <v-col cols="4" style="display: flex;
                                                        align-items: center;">
                                                            <v-select v-model="selectedTypeServer" :items="typeServer"
                                                                label="Seleccione servidor destino" height="25" dense>
                                                            </v-select>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-file-input dense height="25" v-model="documentF"
                                                                label="Seleccionar un archivo"></v-file-input>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-select height="25" dense v-model="selectedTypeDocument"
                                                                :items="typeDocument"
                                                                label="Seleccione tipo de documento"></v-select>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col v-if="selectedTypeServer == 'GoogleDrive' && !codeActive"
                                                            cols="12">
                                                            <v-row v-if="!structThreeF">
                                                                <v-col>
                                                                    <v-chip class="ma-2" label>
                                                                        <p v-if="folderSelectN.length == 0" style="margin-top: 0.5rem;
                                                                        margin-bottom: 0.5rem;">No se ha seleccionado
                                                                            carpeta</p>
                                                                        <p v-else>carpeta seleccionado:
                                                                            {{ folderSelectN }}</p>
                                                                    </v-chip>
                                                                    <v-btn elevation="2" color="#009688"
                                                                        style="color: white;" fab small
                                                                        @click="structThreeF = true">
                                                                        <v-icon>mdi-plus</v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>

                                                            <v-responsive v-if="structThreeF" style="overflow: scroll;"
                                                                class="overflow-y-auto" max-height="250">
                                                                <v-treeview v-model="treeTest" :open="initiallyOpen"
                                                                    :items="folderGoogle" activatable item-key="text"
                                                                    item-text="text">
                                                                    <template v-slot:prepend="{ item, open }">
                                                                        <v-icon @click="selectFolderGoogle = item.id; structThreeF = false;
                                                                        folderSelectN = item.text">
                                                                            {{
                                                                                open? 'mdi-folder-open': 'mdi-folder'
                                                                            }}
                                                                        </v-icon>
                                                                    </template>
                                                                </v-treeview>
                                                            </v-responsive>
                                                        </v-col>
                                                        <v-col v-if="codeActive" cols="6">
                                                            <v-autocomplete height="25" dense v-model="selectCode"
                                                                :items="listCode"
                                                                label="Seleccione el codigo"></v-autocomplete>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6">
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn
                                                                :disabled="documentF == null || selectedTypeDocument == null"
                                                                @click="uploadDocumentsP(anillo.text, 'Canva_Area_Punto');"
                                                                class="ma-2"
                                                                style="background-color: #198754; color: white;">
                                                                Cargar archivo
                                                            </v-btn>
                                                            <v-dialog transition="dialog-top-transition" max-width="600"
                                                                v-model="dialogUpCopyP3">
                                                                <v-card>
                                                                    <v-card-text>
                                                                        <div class="text-h6 pa-12">
                                                                            El archivo que intenta cargar esta
                                                                            duplicado.
                                                                        </div>
                                                                        <p>Ingrese un nombre diferente o dejar sin
                                                                            editar asignar un consecutivo</p>
                                                                        <v-text-field :label="nameFileRandom"
                                                                            hide-details="auto" v-model="mameFileUp"
                                                                            :rules="fileRules"></v-text-field>
                                                                    </v-card-text>
                                                                    <v-card-actions class="justify-end">
                                                                        <v-btn :disabled="!statusVerify" color="#57B55D"
                                                                            class="ma-2" style="color: white;"
                                                                            @click="reeSendFile('Punto');">
                                                                            Guardar
                                                                        </v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                        </b-modal>

                                        <div style="margin: 0.5rem !important; padding-top: 6px; padding-bottom: 12px;">
                                            <b-btn variant="light" class="btn_drop"
                                                @click="eliminarF(selectP, nameFigure); $bvModal.hide('modal-object' + referencia);">
                                                Eliminar
                                            </b-btn>
                                        </div>

                                        <template #modal-footer>
                                            <v-row class="text_w">
                                                <v-col style="padding-right: 0px; padding-left: 0px;">
                                                    <em style="font-size: 12px;"># de archivos: {{ numberFileP }}</em>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </b-modal>

                                </b-dropdown-item>
                            </b-dropdown>

                            <div style="margin: 0.5rem !important;">
                                <b-btn variant="light" class="btn_drop"
                                    @click="eliminarF(selectP, nameFigure); $bvModal.hide('modal-object' + referencia);">
                                    Eliminar
                                </b-btn>
                            </div>

                            <template #modal-footer>
                                <v-row class="text_w">
                                    <v-col cols="6" style="padding-right: 0px; padding-left: 0px;">
                                        <em style="font-size: 12px;"># de puntos:{{ puntoFind.length }}</em>
                                    </v-col>
                                    <v-col style="padding-right: 0px; padding-left: 0px;">
                                        <em style="font-size: 12px;"># de archivos: {{ numberFileG }}</em>
                                    </v-col>
                                </v-row>
                            </template>
                        </b-modal>
                    </b-dropdown-item>
                </b-dropdown-item>
            </b-dropdown>

            <b-dropdown style="display: grid;" block id="dropdown-dropright" dropright text="Puntos" variant="light"
                class="m-2 drop_L">
                <b-dropdown-item>
                    <b-dropdown-item
                        @click="loadDatafigure(anillo.Codigo, 'clickM'); $bvModal.show(`modal-pointViewC-${referencia}-${indx}`);"
                        v-for="(anillo, indx) in dataPoints" :key="indx">
                        {{ anillo.Codigo }}

                        <b-modal hide-backdrop centered size="sm" :id="`modal-pointViewC-${referencia}-${indx}`"
                            :title="`Informacion del Punto ${anillo.Codigo}`">

                            <b-dropdown style="display: grid; padding-top: 12px;" block id="dropdown-dropright"
                                dropright text="Ver archivos" variant="light" class="m-2 drop_L">
                                <b-dropdown-item
                                    @click="filtTagsLGP(tag); $bvModal.show(`modal-fileView-${referencia}-${indx}-${inx}`)"
                                    v-for="(tag, inx) in tagsListP" :key="inx">
                                    {{ tag }}

                                    <b-modal :title="'Archivos ordenado por ' + tag" centered scrollable size="lg"
                                        :id="`modal-fileView-${referencia}-${indx}-${inx}`" hide-footer>
                                        <v-row v-if="filesG.length > 0">
                                            <b-list-group>
                                                <b-list-group-item v-for="(fileG, index) in filesG" :key="fileG.id">
                                                    <v-row style="margin-top: 5px;">
                                                        <v-col sm="9" style="text-align: initial;">
                                                            <v-row>
                                                                <v-col cols="4" style="padding: 3px;">
                                                                    <h6>Nombre del Archivo:</h6>
                                                                </v-col>
                                                                <v-col cols="8" style="padding: 3px;">
                                                                    {{ fileG.text }}
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="2" style="padding: 3px;">
                                                                    <h6>Ubicacion: </h6>
                                                                </v-col>
                                                                <v-col cols="10" v-if="fileG.namePoint != undefined"
                                                                    style="padding: 3px;">
                                                                    {{ fileG.namePoint }}
                                                                </v-col>
                                                                <v-col cols="10" v-if="fileG.nameArea != undefined"
                                                                    style="padding: 3px;">
                                                                    {{ fileG.nameArea }}
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col cols="2" style="padding: 3px;">
                                                                    <h6>Tipo:</h6>
                                                                </v-col>
                                                                <v-col cols="10" style="padding: 3px;">
                                                                    <p style="font-style: oblique;">{{ fileG.tag }}</p>
                                                                </v-col>
                                                            </v-row>
                                                            <p style="font-style: oblique;">{{ fileG.created_at }}</p>
                                                        </v-col>
                                                        <v-col cols="1" style="text-align: center;">
                                                            <br />
                                                            <i style="cursor: pointer;"
                                                                class="fa fa-cloud-download fa-lg"
                                                                @click="downloadFile(fileG.path, fileG.text)"
                                                                aria-hidden="true"></i>
                                                        </v-col>

                                                        <v-col sm="1" style="text-align: center;">
                                                            <br />
                                                            <a v-if="fileG.relacion.Hijos.length > 0 || fileG.relacion.Padres.length > 0"
                                                                style="cursor: pointer;" :id="'popover-button-variantCP-' + index"
                                                                tabindex="0">
                                                                <v-badge bordered color="#ff0000" icon="mdi-alert-circle" overlap>
                                                                    <v-icon color="#000000">
                                                                        mdi-link-variant-plus
                                                                    </v-icon>
                                                                </v-badge>
                                                            </a>
                    
                                                            <a v-if="fileG.relacion.Hijos.length == 0 && fileG.relacion.Padres.length == 0"
                                                                style="cursor: pointer;" :id="'popover-button-variantCAP-' + index"
                                                                tabindex="0">
                                                                <v-icon color="#000000">
                                                                    mdi-link-variant-plus
                                                                </v-icon>
                                                            </a>
                    
                                                            <b-popover :target="'popover-button-variantCP-' + index"
                                                                :id="'popover-button-variantCP-' + index" variant="ligth"
                                                                triggers="focus">
                    
                                                                <b-button-group vertical>
                                                                    <b-button v-b-modal.modal-multi-list v-b-tooltip.hover.left
                                                                        title="Relacion precedencia" @click="selectListRCGP(index)"
                                                                        variant="light">
                                                                        <b-icon icon="box-arrow-in-down" aria-hidden="true"></b-icon>
                                                                        <b-badge pill style="background-color: #19A2FA;">{{
                                                                            fileG.relacion.Padres.length
                                                                        }}</b-badge>
                                                                    </b-button>
                                                                    <b-button v-b-modal.modal-multi-listH v-b-tooltip.hover.left
                                                                        title="Relacion antelación" @click="selectListRCHP(index)"
                                                                        variant="light">
                                                                        <b-icon icon="diagram3" aria-hidden="true"></b-icon> <b-badge pill
                                                                            style="background-color: #19A2FA;">{{
                                                                                fileG.relacion.Hijos.length
                                                                            }}</b-badge>
                                                                    </b-button>
                                                                    <b-button v-b-modal.modal-multi-listC v-b-tooltip.hover.left
                                                                        title="Crear relacion"
                                                                        @click="listTagandFileR(fileG.text); selectListRCCP(index);"
                                                                        variant="light">
                                                                        <i class="fa fa-plus"
                                                                            aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </b-button>
                                                                </b-button-group>
                                                            </b-popover>
                    
                                                            <b-modal v-if="fileG.relacion.Padres.length > 0"
                                                                :id="'modal-multi-listCP-'+ index" size="lg" centered scrollable hide-footer
                                                                :title="'Relacion anterior de ' + fileG.text">
                                                                <b-list-group>
                                                                    <b-list-group-item v-for="(listR, inde11) in fileG.relacion.Padres"
                                                                        :key="inde11">
                                                                        <v-row>
                                                                            <v-col sm="9" style="text-align: initial;">
                                                                                <v-row>
                                                                                    <v-col>
                                                                                        {{ listR.title }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row>
                                                                                    <v-col sm="2">
                                                                                        <h6>Ubicacion: </h6>
                                                                                    </v-col>
                                                                                    <v-col sm="10">
                                                                                        {{ listR.ubicacion }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <p style="font-style: oblique;">{{ listR.created_at }}</p>
                                                                                <p style="font-style: oblique;">{{ listR.tag }} - por:
                                                                                    {{ listR.name_user }}</p>
                                                                            </v-col>
                    
                                                                            <v-col sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    class="fa fa-cloud-download fa-lg"
                                                                                    @click="downloadFile(listR.path, listR.title)"
                                                                                    aria-hidden="true"></i>
                                                                            </v-col>
                    
                                                                            <v-col
                                                                                v-if="listR.path.split('.')[1] == 'png' || listR.path.split('.')[1] == 'jpg'"
                                                                                sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                                    aria-hidden="true" @click="viewFile(listR.path)"></i>
                                                                            </v-col>
                    
                                                                            <v-col v-if="listR.path.split('.')[1] == 'pdf'" sm="1"
                                                                                style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                                    class="fa fa-eye fa-lg" aria-hidden="true"
                                                                                    @click="viewFile(listR.path); selectPPDF(inde, inde1, inde11)"></i>
                    
                                                                                <b-modal
                                                                                    :id="'modal-multi-' + inde + '-' + inde1 + '-' + inde11"
                                                                                    size="lg" centered scrollable hide-footer hide-backdrop
                                                                                    content-class="shadow"
                                                                                    :title="'Visualización de ' + listR.title">
                                                                                    <v-row>
                                                                                        <v-col>
                                                                                            {{ currentPage }} / {{ pageCount }}
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <pdf v-for="i in numPages" :key="i" :src="fileView"
                                                                                        :page="i" style="display: inline-block; width: 50%"
                                                                                        @num-pages="pageCount = $event"
                                                                                        @page-loaded="currentPage = $event"></pdf>
                                                                                </b-modal>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </b-list-group-item>
                                                                </b-list-group>
                                                            </b-modal>
                    
                                                            <b-modal v-if="fileG.relacion.Hijos.length > 0"
                                                                :id="'modal-multi-listCHP-' + index" size="lg" centered scrollable hide-footer
                                                                :title="'Relacion posterior de ' + fileG.text">
                                                                <b-list-group>
                                                                    <b-list-group-item v-for="(listRH, inde11) in fileG.relacion.Hijos"
                                                                        :key="listRH.id">
                                                                        <v-row>
                                                                            <v-col sm="9" style="text-align: initial;">
                                                                                <v-row>
                                                                                    <v-col>
                                                                                        {{ listRH.title }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row>
                                                                                    <v-col sm="2">
                                                                                        <h6>Ubicacion: </h6>
                                                                                    </v-col>
                                                                                    <v-col sm="10">
                                                                                        {{ listRH.ubicacion }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <p style="font-style: oblique;">{{ listRH.created_at }}</p>
                                                                                <p style="font-style: oblique;">{{ listRH.tag }} - por:
                                                                                    {{ listRH.name_user }}</p>
                                                                            </v-col>
                    
                                                                            <v-col sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    class="fa fa-cloud-download fa-lg"
                                                                                    @click="downloadFile(listRH.path, listRH.text)"
                                                                                    aria-hidden="true"></i>
                                                                            </v-col>
                    
                                                                            <v-col
                                                                                v-if="listRH.path.split('.')[1] == 'png' || listRH.path.split('.')[1] == 'jpg'"
                                                                                sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                                    aria-hidden="true" @click="viewFile(listRH.path)"></i>
                                                                            </v-col>
                    
                                                                            <v-col v-if="listRH.path.split('.')[1] == 'pdf'" sm="1"
                                                                                style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                                    class="fa fa-eye fa-lg" aria-hidden="true"
                                                                                    @click="viewFile(listRH.path); selectPPDF(inde, inde1, inde11)"></i>
                    
                                                                                <b-modal
                                                                                    :id="'modal-multi-' + inde + '-' + inde1 + '-' + inde11"
                                                                                    size="lg" centered scrollable hide-footer hide-backdrop
                                                                                    content-class="shadow"
                                                                                    :title="'Visualización de ' + listRH.text">
                                                                                    <v-row>
                                                                                        <v-col>
                                                                                            {{ currentPage }} / {{ pageCount }}
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <pdf v-for="i in numPages" :key="i" :src="fileView"
                                                                                        :page="i" style="display: inline-block; width: 50%"
                                                                                        @num-pages="pageCount = $event"
                                                                                        @page-loaded="currentPage = $event"></pdf>
                                                                                </b-modal>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </b-list-group-item>
                                                                </b-list-group>
                                                            </b-modal>
                    
                                                            <b-modal :id="'modal-multi-listCCP-' + index" size="lg" centered
                                                                hide-footer :title="'Crear relacion para ' + fileG.text">
                                                                <v-row>
                                                                    <v-col sm="9">
                                                                        <v-combobox v-model="selectdateFileR" :items="dateFileR"
                                                                            label="Ingrese el nombre del archivo" dense
                                                                            persistent-hint></v-combobox>
                                                                    </v-col>
                                                                    <v-col sm="3"
                                                                        style="display: flex; align-items: center; justify-content: center;">
                                                                        <b-button variant="outline-info"
                                                                            @click="createRel(fileG.idFile);">
                                                                            Generar relacion
                                                                        </b-button>
                                                                    </v-col>
                                                                </v-row>
                                                            </b-modal>
                                                        </v-col>

                                                    </v-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </v-row>
                                    </b-modal>

                                </b-dropdown-item>
                            </b-dropdown>

                            <div style="margin: 0.5rem !important; padding-top: 6px;">
                                <b-btn variant="light" class="btn_drop" v-b-modal.modal-ListUpGP>
                                    Cargar archivo
                                </b-btn>
                            </div>
                            <b-modal size="lg" id="modal-ListUpGP" hide-footer hide-backdrop centered
                                :title="`Subir archivo en el Punto ${anillo.Codigo}`">
                                <v-row>
                                    <v-col>
                                        <v-row style="display: flex;
                                            justify-content: flex-end;">
                                            <v-col cols="3">
                                                <v-checkbox v-if="selectedTypeServer == 'GoogleDrive'"
                                                    v-model="codeActive" label="Asociar por codigo"></v-checkbox>
                                            </v-col>
                                            <v-col cols="4" style="display: flex;
                                            align-items: center;">
                                                <v-select v-model="selectedTypeServer" :items="typeServer"
                                                    label="Seleccione servidor destino" height="25" dense>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-file-input dense height="25" v-model="documentF"
                                                    label="Seleccionar un archivo"></v-file-input>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select height="25" dense v-model="selectedTypeDocument"
                                                    :items="typeDocument"
                                                    label="Seleccione tipo de documento"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col v-if="selectedTypeServer == 'GoogleDrive' && !codeActive" cols="12">
                                                <v-row v-if="!structThreeF">
                                                    <v-col>
                                                        <v-chip class="ma-2" label>
                                                            <p v-if="folderSelectN.length == 0" style="margin-top: 0.5rem;
                                                            margin-bottom: 0.5rem;">No se ha seleccionado carpeta</p>
                                                            <p v-else>carpeta seleccionado: {{ folderSelectN }}</p>
                                                        </v-chip>
                                                        <v-btn elevation="2" color="#009688" style="color: white;" fab
                                                            small @click="structThreeF = true">
                                                            <v-icon>mdi-plus</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>

                                                <v-responsive v-if="structThreeF" style="overflow: scroll;"
                                                    class="overflow-y-auto" max-height="250">
                                                    <v-treeview v-model="treeTest" :open="initiallyOpen"
                                                        :items="folderGoogle" activatable item-key="text"
                                                        item-text="text">
                                                        <template v-slot:prepend="{ item, open }">
                                                            <v-icon @click="selectFolderGoogle = item.id; structThreeF = false;
                                                            folderSelectN = item.text">
                                                                {{ open? 'mdi-folder-open': 'mdi-folder' }}
                                                            </v-icon>
                                                        </template>
                                                    </v-treeview>
                                                </v-responsive>
                                            </v-col>
                                            <v-col v-if="codeActive" cols="6">
                                                <v-autocomplete height="25" dense v-model="selectCode" :items="listCode"
                                                    label="Seleccione el codigo"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                            </v-col>
                                            <v-col cols="6">
                                                <v-btn :disabled="documentF == null || selectedTypeDocument == null"
                                                    @click="uploadDocumentsP(anillo.Codigo, 'Canva_Punto');"
                                                    class="ma-2" style="background-color: #198754; color: white;">
                                                    Cargar archivo
                                                </v-btn>

                                                <v-dialog transition="dialog-top-transition" max-width="600"
                                                    v-model="dialogUpCopyP4">
                                                    <v-card>
                                                        <v-card-text>
                                                            <div class="text-h6 pa-12">
                                                                El archivo que intenta cargar esta duplicado.
                                                            </div>
                                                            <p>Ingrese un nombre diferente o dejar sin editar asignar un
                                                                consecutivo</p>
                                                            <v-text-field :label="nameFileRandom" hide-details="auto"
                                                                v-model="mameFileUp" :rules="fileRules"></v-text-field>
                                                        </v-card-text>
                                                        <v-card-actions class="justify-end">
                                                            <v-btn :disabled="!statusVerify" color="#57B55D"
                                                                class="ma-2" style="color: white;"
                                                                @click="reeSendFile('Punto');">
                                                                Guardar
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                            </b-modal>

                            <div style="margin: 0.5rem !important; padding-top: 6px; padding-bottom: 12px;">
                                <b-btn variant="light" class="btn_drop"
                                    @click="eliminarF(selectP, nameFigure); $bvModal.hide('modal-object' + referencia);">
                                    Eliminar
                                </b-btn>
                            </div>

                            <template #modal-footer>
                                <v-row class="text_w">
                                    <v-col style="padding-right: 0px; padding-left: 0px;">
                                        <em style="font-size: 12px;"># de archivos: {{ numberFileP }}</em>
                                    </v-col>
                                </v-row>
                            </template>
                        </b-modal>

                    </b-dropdown-item>
                </b-dropdown-item>
            </b-dropdown>


            <template #modal-footer>
                <v-row class="text_w">
                    <v-col style="padding-right: 0px; padding-left: 0px;">
                        <em style="font-size: 12px;"># de areas: {{ polygonsLoadedMpBits.length }}</em>
                    </v-col>
                    <v-col style="padding-right: 0px; padding-left: 0px;">
                        <em style="font-size: 12px;"># de puntos: {{ dataPoints.length }}</em>
                    </v-col>
                </v-row>
            </template>
        </b-modal>


        <b-modal hide-backdrop centered size="sm" :id="'modal-object' + referencia"
            :title="changeN ? nameFigure : typeFigure == 'figure' ? `Informacion del Area ${nameFigure}` : `Informacion del Punto ${nameFigure}`">

            <div style="margin: 0.5rem !important;">
                <b-btn variant="light" class="btn_drop" v-if="changeN" v-b-modal.modal-nameAsing>
                    Definir nombre
                </b-btn>
            </div>
            <b-modal id="modal-nameAsing" hide-footer>
                <v-col>
                    <v-row>
                        <v-col cols="1">
                        </v-col>
                        <v-col cols="10">
                            <v-text-field v-model="nameFigure" label="Nombre de la figura"></v-text-field>
                        </v-col>
                        <v-col cols="1">
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="9">
                        </v-col>
                        <v-col cols="3">
                            <v-btn class="ma-2" style="background-color: #198754; color: white;"
                                @click="$bvModal.hide('modal-nameAsing');">
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </b-modal>


            <div style="margin: 0.5rem !important;">
                <b-btn variant="light" class="btn_drop" v-if="changeN && !(nameCopy.slice(-3) == '_Po' || nameCopy.slice(-2) == '_C'
                || nameCopy.slice(-3) == '_Sq' || nameCopy.slice(-3) == '_Li')" v-b-modal.modal-areaAsing>
                    Definir area
                </b-btn>
            </div>
            <b-modal id="modal-areaAsing" hide-footer>
                <v-col>
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col cols="10">
                            <v-select v-model="selectedFig" :items="dataFig" label="Seleccione el área a asociar"
                                persistent-hint return-object single-line></v-select>
                        </v-col>
                        <v-col cols="1"></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="9">
                        </v-col>
                        <v-col cols="3">
                            <v-btn :disabled="selectedFig.value == undefined" class="ma-2"
                                style="background-color: #198754; color: white;"
                                @click="$bvModal.hide('modal-areaAsing');">
                                Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </b-modal>

            <div style="margin: 0.5rem !important;">
                <b-btn variant="light" class="btn_drop" v-if="changeN" :disabled="selectedFig.value == undefined && !(nameCopy.slice(-3) == '_Po' || nameCopy.slice(-2) == '_C'
                || nameCopy.slice(-3) == '_Sq' || nameCopy.slice(-3) == '_Li')"
                    @click="guardarNameFig(selectP); saveDrawCloud(); $bvModal.hide('modal-object' + referencia);">
                    Guardar
                </b-btn>
            </div>
            <div v-if="!changeN">
                <div v-if="dataObject[selectP].id.slice(-3) == '_Li'">
                    <v-col style="display: flex; flex-direction: column; align-items: center;">
                        Figura no habilitada para cargar archivos
                    </v-col>
                </div>
                <div v-if="dataObject[selectP].id.slice(-3) != '_Li'">

                    <b-dropdown style="display: grid;" block id="dropdown-dropright" dropright text="Ver archivos"
                        variant="light" class="m-2 drop_L">
                        <b-dropdown-item @click="filtTagsLG(tag); $bvModal.show(`modal-fileView-${index}`)"
                            v-for="(tag, index) in tagsListG" :key="index.id">
                            {{ tag }}

                            <b-modal :title="'Archivos de ' + nameFigure" centered scrollable size="lg"
                                :id="`modal-fileView-${index}`" hide-footer>
                                <v-row v-if="filesG.length > 0">
                                    <b-list-group>
                                        <b-list-group-item v-for="(fileG, index1) in filesG" :key="fileG.id">
                                            <v-row style="margin-top: 5px;">
                                                <v-col sm="9" style="text-align: initial;">
                                                    <v-row>
                                                        <v-col cols="4" style="padding: 3px;">
                                                            <h6>Nombre del Archivo:</h6>
                                                        </v-col>
                                                        <v-col cols="8" style="padding: 3px;">
                                                            {{ fileG.text }}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="2" style="padding: 3px;">
                                                            <h6>Ubicacion: </h6>
                                                        </v-col>
                                                        <v-col cols="10" v-if="fileG.namePoint != undefined"
                                                            style="padding: 3px;">
                                                            {{ fileG.namePoint }}
                                                        </v-col>
                                                        <v-col cols="10" v-if="fileG.nameArea != undefined"
                                                            style="padding: 3px;">
                                                            {{ fileG.nameArea }}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="2" style="padding: 3px;">
                                                            <h6>Tipo:</h6>
                                                        </v-col>
                                                        <v-col cols="10" style="padding: 3px;">
                                                            <p style="font-style: oblique;">{{ fileG.tag }}</p>
                                                        </v-col>
                                                    </v-row>
                                                    <p style="font-style: oblique;">{{ fileG.created_at }}</p>
                                                </v-col>

                                                <v-col cols="1" style="text-align: center;">
                                                    <br />
                                                    <i style="cursor: pointer;" class="fa fa-cloud-download fa-lg"
                                                        @click="downloadFile(fileG.path, fileG.text)"
                                                        aria-hidden="true"></i>
                                                </v-col>
                                                <v-col sm="1" style="text-align: center;">
                                                    <br />
                                                    <a v-if="fileG.relacion.Hijos.length > 0 || fileG.relacion.Padres.length > 0"
                                                        style="cursor: pointer;"
                                                        :id="'popover-button-variantO-' + index + '-' + index1" tabindex="0"
                                                        >
                                                        <v-badge bordered color="#ff0000" icon="mdi-alert-circle"
                                                            overlap>
                                                            <v-icon color="#000000">
                                                                mdi-link-variant-plus
                                                            </v-icon>
                                                        </v-badge>
                                                    </a>

                                                    <a v-if="fileG.relacion.Hijos.length == 0 && fileG.relacion.Padres.length == 0"
                                                        style="cursor: pointer;"
                                                        :id="'popover-button-variantO-' + index+ '-' + index1" tabindex="0"
                                                        >
                                                        <v-icon color="#000000">
                                                            mdi-link-variant-plus
                                                        </v-icon>
                                                    </a>
                                                </v-col>

                                                <b-popover :target="'popover-button-variantO-' + index + '-' + index1"
                                                    :id="'popover-button-variantO-' + index + '-' + index1" variant="ligth"
                                                    triggers="focus">

                                                    <b-button-group vertical>
                                                        <b-button v-b-modal.modal-multi-list v-b-tooltip.hover.left
                                                            title="Relacion consecuente" @click="selectListR(index, index1)"
                                                            variant="light">
                                                            <b-icon icon="box-arrow-in-down"
                                                                aria-hidden="true"></b-icon>
                                                            <b-badge pill style="background-color: #19A2FA;">
                                                                {{ fileG.relacion.Padres.length }}
                                                            </b-badge>
                                                        </b-button>
                                                        <b-button v-b-modal.modal-multi-listH v-b-tooltip.hover.left
                                                            title="Relacion precedente" @click="selectListRH(index, index1)"
                                                            variant="light">
                                                            <b-icon icon="diagram3" aria-hidden="true"></b-icon>
                                                            <b-badge pill style="background-color: #19A2FA;">
                                                                {{ fileG.relacion.Hijos.length }}
                                                            </b-badge>
                                                        </b-button>
                                                        <b-button v-b-modal.modal-multi-listC v-b-tooltip.hover.left
                                                            title="Crear relacion"
                                                            @click="listTagandFileR(fileG); selectListRC(index, index1);"
                                                            variant="light">
                                                            <i class="fa fa-plus"
                                                                aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </b-button>
                                                    </b-button-group>
                                                </b-popover>

                                                <b-modal v-if="fileG.relacion.Padres.length > 0"
                                                    :id="'modal-multi-listA-' + index + '-' + index1" size="lg" centered scrollable
                                                    hide-footer :title="'Relacion anterior de ' + fileG.text">
                                                    <b-list-group>
                                                        <b-list-group-item
                                                            v-for="(listR, index1) in fileG.relacion.Padres"
                                                            :key="index1">
                                                            <v-row>
                                                                <v-col sm="9" style="text-align: initial;">
                                                                    <v-row>
                                                                        <v-col>
                                                                            {{ listR.title }}
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col sm="2">
                                                                            <h6>Ubicacion: </h6>
                                                                        </v-col>
                                                                        <v-col sm="10">
                                                                            {{ listR.ubicacion }}
                                                                        </v-col>
                                                                    </v-row>
                                                                    <p style="font-style: oblique;">{{
                                                                        listR.created_at
                                                                    }}</p>
                                                                    <p style="font-style: oblique;">{{ listR.tag }} -
                                                                        por: {{ listR.name_user }}</p>
                                                                </v-col>

                                                                <v-col sm="1" style="text-align: center;">
                                                                    <br />
                                                                    <i style="cursor: pointer;"
                                                                        class="fa fa-cloud-download fa-lg"
                                                                        @click="downloadFile(listR.path, listR.title)"
                                                                        aria-hidden="true"></i>
                                                                </v-col>

                                                                <v-col
                                                                    v-if="listR.path.split('.')[1] == 'png' || listR.path.split('.')[1] == 'jpg'"
                                                                    sm="1" style="text-align: center;">
                                                                    <br />
                                                                    <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                        aria-hidden="true"
                                                                        @click="viewFile(listR.path)"></i>
                                                                </v-col>

                                                                <v-col v-if="listR.path.split('.')[1] == 'pdf'" sm="1"
                                                                    style="text-align: center;">
                                                                    <br />
                                                                    <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                        class="fa fa-eye fa-lg" aria-hidden="true"
                                                                        @click="viewFile(listR.path); selectPPDF(index, index1)"></i>

                                                                    <b-modal :id="'modal-multi-' + index + '-' + index1"
                                                                        size="lg" centered scrollable hide-footer
                                                                        hide-backdrop content-class="shadow"
                                                                        :title="'Visualización de ' + listR.title">
                                                                        <v-row>
                                                                            <v-col>
                                                                                {{ currentPage }} / {{ pageCount }}
                                                                            </v-col>
                                                                        </v-row>
                                                                        <pdf v-for="i in numPages" :key="i"
                                                                            :src="fileView" :page="i"
                                                                            style="display: inline-block; width: 50%"
                                                                            @num-pages="pageCount = $event"
                                                                            @page-loaded="currentPage = $event">
                                                                        </pdf>
                                                                    </b-modal>
                                                                </v-col>
                                                            </v-row>
                                                        </b-list-group-item>
                                                    </b-list-group>
                                                </b-modal>

                                                <b-modal v-if="fileG.relacion.Hijos.length > 0"
                                                    :id="'modal-multi-listHA-' + index + '-' + index1" size="lg" centered scrollable
                                                    hide-footer :title="'Relacion posterior de ' + fileG.text">
                                                    <b-list-group>
                                                        <b-list-group-item
                                                            v-for="(listRH, index1) in fileG.relacion.Hijos"
                                                            :key="listRH.id">
                                                            <v-row>
                                                                <v-col sm="9" style="text-align: initial;">
                                                                    <v-row>
                                                                        <v-col>
                                                                            {{ listRH.title }}
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col sm="2">
                                                                            <h6>Ubicacion: </h6>
                                                                        </v-col>
                                                                        <v-col sm="10">
                                                                            {{ listRH.ubicacion }}
                                                                        </v-col>
                                                                    </v-row>
                                                                    <p style="font-style: oblique;">{{
                                                                        listRH.created_at
                                                                    }}</p>
                                                                    <p style="font-style: oblique;">{{ listRH.tag }} -
                                                                        por: {{ listRH.name_user }}
                                                                    </p>
                                                                </v-col>

                                                                <v-col sm="1" style="text-align: center;">
                                                                    <br />
                                                                    <i style="cursor: pointer;"
                                                                        class="fa fa-cloud-download fa-lg"
                                                                        @click="downloadFile(listRH.path, listRH.title)"
                                                                        aria-hidden="true"></i>
                                                                </v-col>

                                                                <v-col
                                                                    v-if="listRH.path.split('.')[1] == 'png' || listRH.path.split('.')[1] == 'jpg'"
                                                                    sm="1" style="text-align: center;">
                                                                    <br />
                                                                    <i style="cursor: pointer;" class="fa fa-eye fa-lg"
                                                                        aria-hidden="true"
                                                                        @click="viewFile(listRH.path)"></i>
                                                                </v-col>

                                                                <v-col v-if="listRH.path.split('.')[1] == 'pdf'" sm="1"
                                                                    style="text-align: center;">
                                                                    <br />
                                                                    <i style="cursor: pointer;" v-b-modal.modal-multi
                                                                        class="fa fa-eye fa-lg" aria-hidden="true"
                                                                        @click="viewFile(listRH.path); selectPPDF(index, index1)"></i>

                                                                    <b-modal :id="'modal-multi-' + index + '-' + index1"
                                                                        size="lg" centered scrollable hide-footer
                                                                        hide-backdrop content-class="shadow"
                                                                        :title="'Visualización de ' + listRH.title">
                                                                        <v-row>
                                                                            <v-col>
                                                                                {{ currentPage }} / {{ pageCount }}
                                                                            </v-col>
                                                                        </v-row>
                                                                        <pdf v-for="i in numPages" :key="i"
                                                                            :src="fileView" :page="i"
                                                                            style="display: inline-block; width: 50%"
                                                                            @num-pages="pageCount = $event"
                                                                            @page-loaded="currentPage = $event">
                                                                        </pdf>
                                                                    </b-modal>
                                                                </v-col>
                                                            </v-row>
                                                        </b-list-group-item>
                                                    </b-list-group>
                                                </b-modal>

                                                <b-modal :id="'modal-multi-listCA-' + index + '-' + index1" size="lg" centered
                                                    hide-footer :title="'Crear relacion para ' + fileG.text">
                                                    <v-row>
                                                        <v-col sm="9">
                                                            <v-combobox v-model="selectdateFileR" :items="dateFileR"
                                                                label="Ingrese el nombre del archivo" dense
                                                                persistent-hint></v-combobox>
                                                        </v-col>
                                                        <v-col sm="3"
                                                            style="display: flex; align-items: center; justify-content: center;">
                                                            <b-button variant="outline-info"
                                                                @click="createRel(fileG.idFile);">
                                                                Generar relacion
                                                            </b-button>
                                                        </v-col>
                                                    </v-row>
                                                </b-modal>

                                            </v-row>
                                        </b-list-group-item>
                                    </b-list-group>
                                </v-row>
                            </b-modal>

                        </b-dropdown-item>
                    </b-dropdown>

                    <div style="margin: 0.5rem !important;">
                        <b-btn variant="light" class="btn_drop" v-b-modal.modal-ListUp>
                            Cargar archivo
                        </b-btn>
                    </div>
                    <b-modal size="lg" id="modal-ListUp" hide-footer hide-backdrop centered
                        :title="changeN ? nameFigure : typeFigure == 'figure' ? `Subir archivo en el Area ${nameFigure}` : `Subir archivo en el Punto ${nameFigure}`">
                        <v-row style="display: flex;
                        justify-content: flex-end;">
                            <v-col cols="3">
                                <v-checkbox v-if="selectedTypeServer == 'GoogleDrive'" v-model="codeActive"
                                    label="Asociar por codigo"></v-checkbox>
                            </v-col>
                            <v-col cols="4" style="display: flex;
                        align-items: center;">
                                <v-select v-model="selectedTypeServer" :items="typeServer"
                                    label="Seleccione servidor destino" height="25" dense>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-file-input dense height="25" v-model="documentF"
                                    label="Seleccionar un archivo"></v-file-input>
                            </v-col>
                            <v-col cols="6">
                                <v-select height="25" dense v-model="selectedTypeDocument" :items="typeDocument"
                                    label="Seleccione tipo de documento"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="selectedTypeServer == 'GoogleDrive' && !codeActive" cols="12">
                                <v-row v-if="!structThreeF">
                                    <v-col>
                                        <v-chip class="ma-2" label>
                                            <p v-if="folderSelectN.length == 0" style="margin-top: 0.5rem;
                                        margin-bottom: 0.5rem;">No se ha seleccionado carpeta</p>
                                            <p v-else>carpeta seleccionado: {{ folderSelectN }}</p>
                                        </v-chip>
                                        <v-btn elevation="2" color="#009688" style="color: white;" fab small
                                            @click="structThreeF = true">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-responsive v-if="structThreeF" style="overflow: scroll;" class="overflow-y-auto"
                                    max-height="250">
                                    <v-treeview v-model="treeTest" :open="initiallyOpen" :items="folderGoogle"
                                        activatable item-key="text" item-text="text">
                                        <template v-slot:prepend="{ item, open }">
                                            <v-icon @click="selectFolderGoogle = item.id; structThreeF = false;
                                            folderSelectN = item.text">
                                                {{ open? 'mdi-folder-open': 'mdi-folder' }}
                                            </v-icon>
                                        </template>
                                    </v-treeview>
                                </v-responsive>
                            </v-col>
                            <v-col v-if="codeActive" cols="6">
                                <v-autocomplete height="25" dense v-model="selectCode" :items="listCode"
                                    label="Seleccione el codigo"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn :disabled="documentF == null || selectedTypeDocument == null"
                                    @click="uploadDocumentsP(nameFigure, 'Area');" class="ma-2"
                                    style="background-color: #198754; color: white;">
                                    Cargar archivo
                                </v-btn>

                                <v-dialog transition="dialog-top-transition" max-width="600" v-model="dialogUpCopyA">
                                    <v-card>
                                        <v-card-text>
                                            <div class="text-h6 pa-12">
                                                El archivo que intenta cargar esta duplicado.
                                            </div>
                                            <p>Ingrese un nombre diferente o dejar sin editar asignar un consecutivo</p>
                                            <v-text-field :label="nameFileRandom" hide-details="auto"
                                                v-model="mameFileUp" :rules="fileRules"></v-text-field>
                                        </v-card-text>
                                        <v-card-actions class="justify-end">
                                            <v-btn :disabled="!statusVerify" color="#57B55D" class="ma-2"
                                                style="color: white;" @click="reeSendFile('Punto');">
                                                Guardar
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                            </v-col>
                        </v-row>

                    </b-modal>

                    <b-dropdown v-if="typeFigure == 'figure'" style="display: grid;" block id="dropdown-dropright"
                        dropright text="Puntos" variant="light" class="m-2 drop_L">

                        <b-dropdown-item
                            @click="loadDatafigure(anillo.text, 'clickM'); $bvModal.show(`modal-pointView-${referencia}-${indx}`);"
                            v-for="(anillo, indx) in puntoFind" :key="indx">
                            {{ anillo.text }}

                            <b-modal hide-backdrop centered size="sm" :id="`modal-pointView-${referencia}-${indx}`"
                                :title="`Informacion del Punto ${anillo.text}`">

                                <b-dropdown style="display: grid; padding-top: 12px" block id="dropdown-dropright"
                                    dropright text="Ver archivos" variant="light" class="m-2 drop_L">
                                    <b-dropdown-item
                                        @click="filtTagsLGP(tag); $bvModal.show(`modal-fileView-${referencia}-${indx}-${inx}`)"
                                        v-for="(tag, inx) in tagsListP" :key="inx">
                                        {{ tag }}

                                        <b-modal :title="'Archivos ordenado por ' + tag" centered scrollable size="lg"
                                            :id="`modal-fileView-${referencia}-${indx}-${inx}`" hide-footer>
                                            <v-row v-if="filesG.length > 0">
                                                <b-list-group>
                                                    <b-list-group-item v-for="(fileG, ind) in filesG" :key="fileG.id">
                                                        <v-row style="margin-top: 5px;">
                                                            <v-col sm="9" style="text-align: initial;">
                                                                <v-row>
                                                                    <v-col cols="4" style="padding: 3px;">
                                                                        <h6>Nombre del Archivo:</h6>
                                                                    </v-col>
                                                                    <v-col cols="8" style="padding: 3px;">
                                                                        {{ fileG.text }}
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="2" style="padding: 3px;">
                                                                        <h6>Ubicacion: </h6>
                                                                    </v-col>
                                                                    <v-col cols="10" v-if="fileG.namePoint != undefined"
                                                                        style="padding: 3px;">
                                                                        {{ fileG.namePoint }}
                                                                    </v-col>
                                                                    <v-col cols="10" v-if="fileG.nameArea != undefined"
                                                                        style="padding: 3px;">
                                                                        {{ fileG.nameArea }}
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="2" style="padding: 3px;">
                                                                        <h6>Tipo:</h6>
                                                                    </v-col>
                                                                    <v-col cols="10" style="padding: 3px;">
                                                                        <p style="font-style: oblique;">{{ fileG.tag }}
                                                                        </p>
                                                                    </v-col>
                                                                </v-row>
                                                                <p style="font-style: oblique;">{{ fileG.created_at }}
                                                                </p>
                                                            </v-col>
                                                            <v-col cols="1" style="text-align: center;">
                                                                <br />
                                                                <i style="cursor: pointer;"
                                                                    class="fa fa-cloud-download fa-lg"
                                                                    @click="downloadFile(fileG.path, fileG.text)"
                                                                    aria-hidden="true">
                                                                </i>
                                                            </v-col>
                                                            <v-col sm="1" style="text-align: center;">
                                                                <br />
                                                                <a v-if="fileG.relacion.Hijos.length > 0 || fileG.relacion.Padres.length > 0"
                                                                    style="cursor: pointer;"
                                                                    :id="'popover-button-variantOAP-' + indx + '-' + inx + '-' + ind"
                                                                    tabindex="0">
                                                                    <v-badge bordered color="#ff0000"
                                                                        icon="mdi-alert-circle" overlap>
                                                                        <v-icon color="#000000">
                                                                            mdi-link-variant-plus
                                                                        </v-icon>
                                                                    </v-badge>
                                                                </a>

                                                                <a v-if="fileG.relacion.Hijos.length == 0 && fileG.relacion.Padres.length == 0"
                                                                    style="cursor: pointer;"
                                                                    :id="'popover-button-variantOAP-' + indx + '-' + inx + '-' + ind"
                                                                    tabindex="0">
                                                                    <v-icon color="#000000">
                                                                        mdi-link-variant-plus
                                                                    </v-icon>
                                                                </a>
                                                            </v-col>
                                                            <b-popover :target="'popover-button-variantOAP-' + indx + '-' + inx + '-' + ind"
                                                                :id="'popover-button-variantOAP-'+ indx + '-' + inx + '-' + ind" variant="ligth"
                                                                triggers="focus">

                                                                <b-button-group vertical>
                                                                    <b-button v-b-modal.modal-multi-list
                                                                        v-b-tooltip.hover.left
                                                                        title="Relacion consecuente"
                                                                        @click="selectListRAP(inx, ind)" variant="light">
                                                                        <b-icon icon="box-arrow-in-down"
                                                                            aria-hidden="true"></b-icon>
                                                                        <b-badge pill
                                                                            style="background-color: #19A2FA;">
                                                                            {{ fileG.relacion.Padres.length }}
                                                                        </b-badge>
                                                                    </b-button>
                                                                    <b-button v-b-modal.modal-multi-listH
                                                                        v-b-tooltip.hover.left
                                                                        title="Relacion precedente"
                                                                        @click="selectListRHAP(inx, ind)" variant="light">
                                                                        <b-icon icon="diagram3"
                                                                            aria-hidden="true"></b-icon>
                                                                        <b-badge pill
                                                                            style="background-color: #19A2FA;">
                                                                            {{ fileG.relacion.Hijos.length }}
                                                                        </b-badge>
                                                                    </b-button>
                                                                    <b-button v-b-modal.modal-multi-listC
                                                                        v-b-tooltip.hover.left title="Crear relacion"
                                                                        @click="listTagandFileR(fileG); selectListRCAP(inx, ind);"
                                                                        variant="light">
                                                                        <i class="fa fa-plus"
                                                                            aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </b-button>
                                                                </b-button-group>
                                                            </b-popover>

                                                            <b-modal v-if="fileG.relacion.Padres.length > 0"
                                                                :id="'modal-multi-listAP-' + inx + '-' + ind" size="lg" centered
                                                                scrollable hide-footer
                                                                :title="'Relacion anterior de ' + fileG.text">
                                                                <b-list-group>
                                                                    <b-list-group-item
                                                                        v-for="(listR, index1) in fileG.relacion.Padres"
                                                                        :key="index1">
                                                                        <v-row>
                                                                            <v-col sm="9" style="text-align: initial;">
                                                                                <v-row>
                                                                                    <v-col>
                                                                                        {{ listR.title }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row>
                                                                                    <v-col sm="2">
                                                                                        <h6>Ubicacion: </h6>
                                                                                    </v-col>
                                                                                    <v-col sm="10">
                                                                                        {{ listR.ubicacion }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <p style="font-style: oblique;">{{
                                                                                    listR.created_at
                                                                                }}</p>
                                                                                <p style="font-style: oblique;">{{
                                                                                    listR.tag }} - por: {{ listR.name_user 
                                                                                }}</p>
                                                                            </v-col>

                                                                            <v-col sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    class="fa fa-cloud-download fa-lg"
                                                                                    @click="downloadFile(listR.path, listR.title)"
                                                                                    aria-hidden="true"></i>
                                                                            </v-col>

                                                                            <v-col
                                                                                v-if="listR.path.split('.')[1] == 'png' || listR.path.split('.')[1] == 'jpg'"
                                                                                sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    class="fa fa-eye fa-lg"
                                                                                    aria-hidden="true"
                                                                                    @click="viewFile(listR.path)"></i>
                                                                            </v-col>

                                                                            <v-col
                                                                                v-if="listR.path.split('.')[1] == 'pdf'"
                                                                                sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    v-b-modal.modal-multi
                                                                                    class="fa fa-eye fa-lg"
                                                                                    aria-hidden="true"
                                                                                    @click="viewFile(listR.path); selectPPDF(index, index1)"></i>

                                                                                <b-modal
                                                                                    :id="'modal-multi-' + index + '-' + index1"
                                                                                    size="lg" centered scrollable
                                                                                    hide-footer hide-backdrop
                                                                                    content-class="shadow"
                                                                                    :title="'Visualización de ' + listR.title">
                                                                                    <v-row>
                                                                                        <v-col>
                                                                                            {{ currentPage }} / {{
                                                                                                pageCount
                                                                                            }}
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <pdf v-for="i in numPages" :key="i"
                                                                                        :src="fileView" :page="i"
                                                                                        style="display: inline-block; width: 50%"
                                                                                        @num-pages="pageCount = $event"
                                                                                        @page-loaded="currentPage = $event">
                                                                                    </pdf>
                                                                                </b-modal>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </b-list-group-item>
                                                                </b-list-group>
                                                            </b-modal>

                                                            <b-modal v-if="fileG.relacion.Hijos.length > 0"
                                                                :id="'modal-multi-listHAP-' + inx + '-' + ind " size="lg" centered
                                                                scrollable hide-footer
                                                                :title="'Relacion posterior de ' + fileG.text">
                                                                <b-list-group>
                                                                    <b-list-group-item
                                                                        v-for="(listRH, index1) in fileG.relacion.Hijos"
                                                                        :key="listRH.id">
                                                                        <v-row>
                                                                            <v-col sm="9" style="text-align: initial;">
                                                                                <v-row>
                                                                                    <v-col>
                                                                                        {{ listRH.text }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <v-row>
                                                                                    <v-col sm="2">
                                                                                        <h6>Ubicacion: </h6>
                                                                                    </v-col>
                                                                                    <v-col sm="10">
                                                                                        {{ listRH.namePoint }}
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <p style="font-style: oblique;">{{
                                                                                    listRH.created_at
                                                                                }}</p>
                                                                                <p style="font-style: oblique;">{{
                                                                                    listRH.tag
                                                                                }} - por: {{
                                                                                    listRH.name_user
                                                                                }}
                                                                                </p>
                                                                            </v-col>

                                                                            <v-col sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    class="fa fa-cloud-download fa-lg"
                                                                                    @click="downloadFile(listRH.path, listRH.text)"
                                                                                    aria-hidden="true"></i>
                                                                            </v-col>

                                                                            <v-col
                                                                                v-if="listRH.path.split('.')[1] == 'png' || listRH.path.split('.')[1] == 'jpg'"
                                                                                sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    class="fa fa-eye fa-lg"
                                                                                    aria-hidden="true"
                                                                                    @click="viewFile(listRH.path)"></i>
                                                                            </v-col>

                                                                            <v-col
                                                                                v-if="listRH.path.split('.')[1] == 'pdf'"
                                                                                sm="1" style="text-align: center;">
                                                                                <br />
                                                                                <i style="cursor: pointer;"
                                                                                    v-b-modal.modal-multi
                                                                                    class="fa fa-eye fa-lg"
                                                                                    aria-hidden="true"
                                                                                    @click="viewFile(listRH.path); selectPPDF(indx, index1)"></i>

                                                                                <b-modal
                                                                                    :id="'modal-multi-' + index + '-' + index1"
                                                                                    size="lg" centered scrollable
                                                                                    hide-footer hide-backdrop
                                                                                    content-class="shadow"
                                                                                    :title="'Visualización de ' + listRH.text">
                                                                                    <v-row>
                                                                                        <v-col>
                                                                                            {{ currentPage }} / {{
                                                                                                pageCount
                                                                                            }}
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <pdf v-for="i in numPages" :key="i"
                                                                                        :src="fileView" :page="i"
                                                                                        style="display: inline-block; width: 50%"
                                                                                        @num-pages="pageCount = $event"
                                                                                        @page-loaded="currentPage = $event">
                                                                                    </pdf>
                                                                                </b-modal>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </b-list-group-item>
                                                                </b-list-group>
                                                            </b-modal>

                                                            <b-modal :id="'modal-multi-listCAP-' + inx + '-' + ind" size="lg"
                                                                centered hide-footer
                                                                :title="'Crear relacion para ' + fileG.text">
                                                                <v-row>
                                                                    <v-col sm="9">
                                                                        <v-combobox v-model="selectdateFileR"
                                                                            :items="dateFileR"
                                                                            label="Ingrese el nombre del archivo" dense
                                                                            persistent-hint></v-combobox>
                                                                    </v-col>
                                                                    <v-col sm="3"
                                                                        style="display: flex; align-items: center; justify-content: center;">
                                                                        <b-button variant="outline-info"
                                                                            @click="createRel(fileG.idFile);">
                                                                            Generar relacion
                                                                        </b-button>
                                                                    </v-col>
                                                                </v-row>
                                                            </b-modal>

                                                        </v-row>
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </v-row>
                                        </b-modal>

                                    </b-dropdown-item>
                                </b-dropdown>

                                <div style="margin: 0.5rem !important; padding-top: 6px;">
                                    <b-btn variant="light" class="btn_drop" v-b-modal.modal-ListUpGP>
                                        Cargar archivo
                                    </b-btn>
                                </div>
                                <b-modal size="lg" id="modal-ListUpGP" hide-backdrop centered hide-footer
                                    :title="'Subir archivo en el punto ' + anillo.text">

                                    <v-row style="display: flex;
                                        justify-content: flex-end;">
                                        <v-col cols="3">
                                            <v-checkbox v-if="selectedTypeServer == 'GoogleDrive'" v-model="codeActive"
                                                label="Asociar por codigo"></v-checkbox>
                                        </v-col>
                                        <v-col cols="4" style="display: flex;
                                        align-items: center;">
                                            <v-select v-model="selectedTypeServer" :items="typeServer"
                                                label="Seleccione servidor destino" height="25" dense>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-file-input dense height="25" v-model="documentF"
                                                label="Seleccionar un archivo"></v-file-input>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select height="25" dense v-model="selectedTypeDocument"
                                                :items="typeDocument" label="Seleccione tipo de documento"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col v-if="selectedTypeServer == 'GoogleDrive' && !codeActive" cols="12">
                                            <v-row v-if="!structThreeF">
                                                <v-col>
                                                    <v-chip class="ma-2" label>
                                                        <p v-if="folderSelectN.length == 0" style="margin-top: 0.5rem;
                                                        margin-bottom: 0.5rem;">No se ha seleccionado carpeta</p>
                                                        <p v-else>carpeta seleccionado: {{ folderSelectN }}</p>
                                                    </v-chip>
                                                    <v-btn elevation="2" color="#009688" style="color: white;" fab small
                                                        @click="structThreeF = true">
                                                        <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                            <v-responsive v-if="structThreeF" style="overflow: scroll;"
                                                class="overflow-y-auto" max-height="250">
                                                <v-treeview v-model="treeTest" :open="initiallyOpen"
                                                    :items="folderGoogle" activatable item-key="text" item-text="text">
                                                    <template v-slot:prepend="{ item, open }">
                                                        <v-icon @click="selectFolderGoogle = item.id; structThreeF = false;
                                                        folderSelectN = item.text">
                                                            {{ open? 'mdi-folder-open': 'mdi-folder' }}
                                                        </v-icon>
                                                    </template>
                                                </v-treeview>
                                            </v-responsive>
                                        </v-col>
                                        <v-col v-if="codeActive" cols="6">
                                            <v-autocomplete height="25" dense v-model="selectCode" :items="listCode"
                                                label="Seleccione el codigo"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn :disabled="documentF == null || selectedTypeDocument == null"
                                                @click="uploadDocumentsP(anillo.text, 'Area_Punto');" class="ma-2"
                                                style="background-color: #198754; color: white;">
                                                Cargar archivo
                                            </v-btn>

                                            <v-dialog transition="dialog-top-transition" max-width="600"
                                                v-model="dialogUpCopyP2">
                                                <v-card>
                                                    <v-card-text>
                                                        <div class="text-h6 pa-12">
                                                            El archivo que intenta cargar esta duplicado.
                                                        </div>
                                                        <p>Ingrese un nombre diferente o dejar sin editar asignar un
                                                            consecutivo</p>
                                                        <v-text-field :label="nameFileRandom" hide-details="auto"
                                                            v-model="mameFileUp" :rules="fileRules"></v-text-field>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <v-btn :disabled="!statusVerify" color="#57B55D" class="ma-2"
                                                            style="color: white;" @click="reeSendFile('Punto');">
                                                            Guardar
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-col>
                                    </v-row>

                                </b-modal>

                                <div style="margin: 0.5rem !important; padding-top: 6px; padding-bottom: 12px;">
                                    <b-btn variant="light" class="btn_drop"
                                        @click="$bvModal.show(`modal-delete-${referencia}-${indx}`)">
                                        Eliminar
                                    </b-btn>

                                    <b-modal centered :id="`modal-delete-${referencia}-${indx}`" hide-footer>
                                        <v-row>
                                            <v-col>
                                                <h6>Desea eliminar el punto?</h6>
                                            </v-col>
                                            <v-row>
                                                <v-col>
                                                    <b-form-group id="input-group-deleteFig"
                                                        label="Confirme escribiendo la palabra 'Si':"
                                                        label-for="input-deletePoint"
                                                        description="Al eliminar el area se eliminaran los archivos adjuntos en el punto.">
                                                        <b-form-input id="input-deletePoint" v-model="confirDelete"
                                                            placeholder="" required></b-form-input>
                                                    </b-form-group>
                                                    <b-button
                                                        @click="eliminarF(selectP, nameFigure); $bvModal.hide('modal-object' + referencia); $bvModal.hide(`modal-delete-${referencia}-${indx}`);"
                                                        :disabled="confirDelete != 'Si'" variant="danger">
                                                        Eliminar
                                                    </b-button>
                                                </v-col>
                                            </v-row>
                                        </v-row>
                                    </b-modal>
                                </div>

                                <template #modal-footer>
                                    <v-row v-if="!changeN" class="text_w">
                                        <v-col style="padding-right: 0px; padding-left: 0px;">
                                            <em style="font-size: 12px;"># de archivos: {{ numberFileP }}</em>
                                        </v-col>
                                    </v-row>
                                </template>
                            </b-modal>

                        </b-dropdown-item>
                    </b-dropdown>

                </div>
            </div>

            <div style="margin: 0.5rem !important;">
                <b-btn variant="light" class="btn_drop"
                    @click="eliminarF(selectP, nameFigure); $bvModal.hide('modal-object' + referencia);">
                    Eliminar
                </b-btn>
            </div>

            <template #modal-footer>
                <v-row v-if="!changeN" class="text_w">
                    <v-col v-if="((dataObject[selectP].id.slice(-3) != '_Li') && (typeFigure == 'figure'))" cols="6"
                        style="padding-right: 0px; padding-left: 0px;">
                        <em style="font-size: 12px;"># de puntos:{{ puntoFind.length }}</em>
                    </v-col>
                    <v-col v-if="((dataObject[selectP].id.slice(-3) != '_Li') && (typeFigure == 'figure'))"
                        style="padding-right: 0px; padding-left: 0px;">
                        <em style="font-size: 12px;"># de archivos: {{ numberFileG }}</em>
                    </v-col>
                    <v-col v-if="((dataObject[selectP].id.slice(-3) != '_Li') && (typeFigure == 'point'))"
                        style="padding-right: 0px; padding-left: 0px;">
                        <em style="font-size: 12px;"># de archivos: {{ numberFileP }}</em>
                    </v-col>
                </v-row>
                <v-row v-if="changeN"></v-row>
            </template>
        </b-modal>


        <v-dialog v-model="active" width="500">
            <v-card>
                <v-card-title>
                    <h4 class="not-margin">Cargar imagen</h4>
                </v-card-title>
                <v-card-text>
                    <div class="con-form">
                        <b-container class="mt-3" fluid>
                            <b-form @submit.stop.prevent="onSubmit">
                                <div class="d-flex mb-3">
                                    <v-file-input v-model="image" chips accept="image/png, image/jpeg, image/bmp"
                                        label="Seleccionar una imagen"></v-file-input>
                                    <v-btn v-if="hasImage" depressed color="error" @click="clearImage">Limpiar imagen
                                    </v-btn>
                                </div>

                                <b-img v-if="hasImage" :src="imageSrc" class="mb-3" fluid block rounded></b-img>

                                <v-btn :disabled="!hasImage" depressed color="primary"
                                    @click="setImage(); saveBackground(); active = false">Upload image</v-btn>
                            </b-form>
                        </b-container>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="NotiFail" color="red accent-2" outlined timeout="5000" :top="true" style="z-index: 999">
            No se puedo realizar la accion

            <template v-slot:action="{ attrs }">
                <v-btn color="red accent-2" text v-bind="attrs" @click="NotiFail = false">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar v-model="NotiFigure" color="success" outlined timeout="5000" :top="true" style="z-index: 999">
            Se ha creado exitosamente la figura en la <strong>vista</strong>

            <template v-slot:action="{ attrs }">
                <v-btn color="success" text v-bind="attrs" @click="NotiFigure = false">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar v-model="NotiUploadD" color="success" outlined timeout="5000" :top="true" style="z-index: 999">
            Se ha cargado exitosamente el <strong>Documento</strong>

            <template v-slot:action="{ attrs }">
                <v-btn color="success" text v-bind="attrs" @click="NotiUploadD = false">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import api from '../../../../../api/index.js'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Vue from 'vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import axios from "axios";

const base64Encode = (data) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export default {
    name: "canvasImage",
    component: {
        VueBootstrapTypeahead
    },
    data() {
        return {
            dataFig: [],
            selectedFig: {},
            puntos: [],
            NotiFigure: false,
            NotiFail: false,
            image: null,
            imageSrc: null,
            imageForm: null,
            backgroundImage: null,
            active: false,
            status_P: false,
            status_Sq: false,
            status_circ: false,
            status_Poly: false,
            status_lin: false,
            button_noactive: "background-color: #f8f9fa!important; padding-left: 11px; padding-right: 11px",
            button_active: "background-color: #138496!important; padding-left: 11px; padding-right: 11px",
            numero_point: 0,
            numero_square: 0,
            numero_polygon: 0,
            dataObject: [],
            selectObject: "",
            selectP: null,
            nameFigure: "",
            cloudData: null,
            estadoC: 0,
            documentF: null,
            selectedTypeDocument: null,
            statusEdit: false,
            changeN: true,
            documentC: null,
            idFigure: null,
            nameCopy: "",
            filesZones: [],
            filesZoneP: [],
            filesG: [],
            tagsListP: [],
            tagsListG: [],
            NotiUploadD: false,
            permitEdit: false,
            numberFileG: 0,
            numberFileP: 0,
            typeFigure: "",
            puntoFind: [],
            token: "",
            currentUser: null,
            selectFolderGoogle: null,
            selectedTypeServer: '',
            typeServer: [
                { text: "SafeRed", value: "SafeRed" },
                { text: "GoogleDrive", value: "GoogleDrive" }
            ],
            codeActive: false,
            listCode: [],
            selectCode: null,
            structThreeF: false,
            treeTest: [],
            initiallyOpen: [],
            folderSelectN: "",
            filesC: [],
            tagsListC: [],
            selectdateFileR: '',
            dateFileR: [],
            filesCanvas: [],
            dialogUpCopyA: false,
            dialogUpCopyA2: false,
            fileRules: [
                v => !/[():<>*\;"\\|.\/?~]/.test(v) || 'No se admiten caracteres especiales', //eslint-disable-line
            ],
            nameFile: '',
            nameFileRandom: '',
            statusVerify: true,
            mameFileUp: null,
            dialogUpCopyC: false,
            dialogUpCopyP: false,
            dialogUpCopyP2: false,
            dialogUpCopyP3: false,
            dialogUpCopyP4: false,
            idArea: null,
            idPunto: null,
            confirDelete: ''
        };
    },
    props: ['dataI', 'referencia', 'id', 'dataPoints', 'typeDocument', 'polygonsLoadedMpBits',
        'rolesActualU', 'projectId', 'folderGoogle', 'listCodeP', 'dataMap' ],
    computed: {
        hasImage() {
            return !!this.image;
        },
    },
    async mounted() {
        this.initiallyOpen = [this.$store.state.dataInit.projectName]
        this.currentUser = JSON.parse(localStorage.user)
        this.token = JSON.parse(localStorage.user).token
        await this.dataInit()
        await this.permitSection()
        await this.genListCode()
        await this.setdataThreeG()
        this.detectFig()
    },
    methods: {
        selectListRCC(inde, inde1){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCC-' + inde + '-' + inde1, '#focusThisOnClose');
        },
        selectListRCH(inde, inde1){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCH-' + inde + '-' + inde1, '#focusThisOnClose');
        },
        selectListRCG(inde, inde1){
            this.$root.$emit('bv::show::modal', 'modal-multi-listC-' + inde + '-' + inde1, '#focusThisOnClose');
        },
        selectListRCCA(index, index1){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCCA-' + index + '-' + index1, '#focusThisOnClose');
        },
        selectListRCHA(index, index1){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCHA-' + index + '-' + index1, '#focusThisOnClose');
        },
        selectListRCGA(index, index1){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCA-' + index + '-' + index1, '#focusThisOnClose');
        },
        selectListRCCAP(index){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCCAP-' + index, '#focusThisOnClose');
        },
        selectListRCHAP(index){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCHAP-' + index, '#focusThisOnClose');
        },
        selectListRCGAP(index){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCAP-' + index, '#focusThisOnClose');
        },
        selectListRCCP(index){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCCP-' + index, '#focusThisOnClose');
        },
        selectListRCHP(index){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCHP-' + index, '#focusThisOnClose');
        },
        selectListRCGP(index){
            this.$root.$emit('bv::show::modal', 'modal-multi-listCP-' + index, '#focusThisOnClose');
        },
        selectListRAP(indx, inx) {
            this.$root.$emit('bv::show::modal', 'modal-multi-listAP-' + indx + '-' + inx, '#focusThisOnClose');
        },
        selectListRHAP(indx, inx) {
            this.$root.$emit('bv::show::modal', 'modal-multi-listHAP-' + indx + '-' + inx, '#focusThisOnClose');
        },
        selectListRCAP(indx, inx) {
            this.$root.$emit('bv::show::modal', 'modal-multi-listCAP-' + indx + '-' + inx, '#focusThisOnClose');
        },
        selectListR(index, index1) {
            this.$root.$emit('bv::show::modal', 'modal-multi-listA-' + index + '-' + index1, '#focusThisOnClose');
        },
        selectListRH(index, index1) {
            this.$root.$emit('bv::show::modal', 'modal-multi-listHA-' + index + '-' + index1, '#focusThisOnClose');
        },
        selectListRC(index, index1) {
            this.$root.$emit('bv::show::modal', 'modal-multi-listCA-' + index + '-' + index1, '#focusThisOnClose');
        },
        listTagandFileR: function (file) {

            var self = this;
            self.dateFileR = [];

            var dataMap = this.dataMap[0].children;
            var checkType = false;

            if (file.idFile == file.path) {
                checkType = true
            }

            dataMap.map(function (dataM) {
                if (dataM.text != file.text) {
                    if (dataM.path != undefined) {
                        if (checkType == (dataM.idFile == dataM.path)) {
                            self.dateFileR.push({
                                value: dataM.idFile,
                                text: dataM.text,
                            })
                        }
                    } else {
                        if (dataM.children != undefined) {
                            dataM.children.map(async function (arr) {
                                if (arr.text != file.text) {
                                    if (arr.path != undefined) {
                                        if (checkType == (arr.idFile == arr.path)) {
                                            self.dateFileR.push({
                                                value: arr.idFile,
                                                text: arr.text,
                                            })
                                        }
                                    } else {
                                        if (arr.children != undefined) {
                                            arr.children.map(async function (arr1) {
                                                if (arr1.text != file.text) {
                                                    if (arr1.path != undefined) {
                                                        if (checkType == (arr1.idFile == arr1.path)) {
                                                            self.dateFileR.push({
                                                                value: arr1.idFile,
                                                                text: arr1.text,
                                                            })
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            })
        },
        createRel: async function (idFilePadre) {
            if (typeof idFilePadre == "number") {
                var dataRel = {
                    idPadre: idFilePadre,
                    idsChildres: this.selectdateFileR
                };
                this.selectdateFileR = null
                await api
                    .request(
                        "post", '/setRelationalForDoc', dataRel)
                    .then(function (response) {
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else if (typeof idFilePadre == "string") {

                dataRel = {
                    idProject: this.projectId,
                    idPadre: idFilePadre,
                    idHijo: this.selectdateFileR.value,
                    idCanvas: this.dataI.id,
                    typeCanvas: 1
                }
                const config = {
                    headers: { Authorization: `Bearer ${this.token}` },
                };

                await axios
                    .post("https://microservices.safered.co/api/createRelations", dataRel, config, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => console.log(res.data))
                    .catch((e) => console.log(e));

            }
        },
        detectFig() {

            var data_svg = document.getElementById(this.referencia)
            var childnodesL = data_svg.childNodes

            for (let i = 0; i < childnodesL.length; i++) {
                if (data_svg.childNodes[i].id != undefined) {
                    if (!data_svg.childNodes[i].id.includes("_P")) {

                        if (this.dataObject.length > 0) {

                            for (let j = 0; j < this.dataObject.length; j++) {


                                if (this.dataObject[j].name == undefined) {

                                    if (childnodesL[i].id != undefined) {

                                        if (childnodesL[i].id == this.dataObject[j].id) {
                                            var strokedasharray = document.createAttribute("stroke-dasharray");
                                            strokedasharray.value = "5,5";

                                            childnodesL[i].setAttributeNode(strokedasharray)
                                        } else {
                                            var strokedasharray1 = document.createAttribute("stroke-dasharray");
                                            strokedasharray1.value = "5,5";

                                            childnodesL[i].setAttributeNode(strokedasharray1)
                                        }
                                    }

                                }
                            }
                        } else {
                            childnodesL[i].setAttributeNode(strokedasharray)
                        }
                    }
                }
            }

            for (let i = 0; i < childnodesL.length; i++) {
                if (data_svg.childNodes[i].id != undefined) {
                    if (!data_svg.childNodes[i].id.includes("_P")) {

                        if (this.dataObject.length > 0) {

                            for (let j = 0; j < this.dataObject.length; j++) {
                                if (this.dataObject[j].name != undefined) {
                                    if (childnodesL[i].id != undefined) {
                                        if (childnodesL[i].id == this.dataObject[j].id) {

                                            childnodesL[i].removeAttribute("stroke-dasharray")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            for (let i = 0; i < childnodesL.length; i++) {
                if (data_svg.childNodes[i].id != undefined) {
                    if (this.dataObject.length > 0) {
                        for (let j = 0; j < this.dataObject.length; j++) {
                            if (childnodesL[i].id == this.dataObject[j].id) {
                                var nameElement = document.createAttribute("name");
                                nameElement.value = this.dataObject[j].name;

                                childnodesL[i].setAttributeNode(nameElement)
                            }
                        }
                    }
                }
            }

        },
        genListCode() {
            var self = this

            if(this.listCodeP != null){
                this.listCodeP.map(function (codeP) {
                    self.listCode.push({
                        text: codeP.code,
                        value: codeP.idFolder
                    })
                })
            }
        },
        setdataThreeG() {
            this.$store.commit("setDataFindPoint", this.polygonsLoadedMpBits);
            this.$store.commit("setDataPoint", this.dataPoints);
        },
        permissionEditor: async function () {
            var groups = await JSON.parse(window.localStorage.user).groups
            var idSelect = this.projectId
            var statusE = false
            var self = this

            if (groups != "NaN") {
                groups.map(function (arr) {
                    self.rolesActualU.map(function (arr2) {
                        if (arr2.project_id == idSelect) {
                            if (arr2.role_id == 2) {
                                statusE = false
                            } else if (arr2.role_id == 4) {
                                statusE = true
                            }
                        } else {
                            if (arr.idProject == idSelect) {
                                if (arr.idRole == 2) {
                                    statusE = false
                                } else if (arr.idRole == 4) {
                                    statusE = true
                                }
                            }
                        }
                    })
                })
            } else {
                self.rolesActualU.map(function (arr) {
                    if (arr.project_id == idSelect) {
                        if (arr.role_id == 2) {
                            statusE = false
                        } else if (arr.role_id == 4) {
                            statusE = true
                        }
                    }
                })
            }


            if (JSON.parse(window.localStorage.user).isAdmin == true || statusE) {
                this.permitEdit = true;
            } else {
                this.permitEdit = false;
            }
        },
        loadDatafigure: async function (nameFigure, typeM) {
            let dataMap = []
            dataMap.push(this.dataMap[0])
            let isPoint = false
            let isFigure = false
            let self = this

            this.polygonsLoadedMpBits.map(function (element) {

                if (element.name == nameFigure) {
                    isFigure = true
                }
            })

            this.dataPoints.map(function (element) {
                if (element.Codigo == nameFigure) {
                    isPoint = true
                }
            })


            if (isFigure) {
                this.filesZones = []
                self.puntoFind = []
                self.filesG = []

                let dataF = dataMap[0].children[0].children.find(({ text }) => text == nameFigure)

                await dataF.children.map(function (element) {
                    if (element != null) {
                        if (element.tag != undefined) {
                            var hijos = [];
                            var padres = [];

                            if (element.relacion != null) {
                                if (element.relacion.Hijos.length > 0) {
                                    element.relacion.Hijos.map(function (elementRH) {
                                        if(elementRH){
                                            const d1 = new Date(elementRH.created_at);
                                            var tmp = d1.getFullYear() + "-" +
                                                (d1.getMonth() + 1) + "-" +
                                                d1.getDate() + " " +
                                                d1.getHours() + ":" +
                                                d1.getMinutes();

                                            hijos.push({
                                                created_at: tmp,
                                                ubicacion: elementRH.ubicacion,
                                                name_user: elementRH.name_user,
                                                path: elementRH.path,
                                                title: elementRH.title,
                                                tag: elementRH.tag_name,
                                                idFile: elementRH.id,
                                            })
                                        }
                                    })
                                }

                                if (element.relacion.Padres.length > 0) {
                                    element.relacion.Padres.map(function (elementR) {
                                        if(elementR != null){
                                            const d1 = new Date(elementR.created_at);
                                            var tmp = d1.getFullYear() + "-" +
                                                (d1.getMonth() + 1) + "-" +
                                                d1.getDate() + " " +
                                                d1.getHours() + ":" +
                                                d1.getMinutes();

                                            padres.push({
                                                created_at: tmp,
                                                ubicacion: elementR.ubicacion,
                                                name_user: elementR.name_user,
                                                path: elementR.path,
                                                title: elementR.title,
                                                tag: elementR.tag_name,
                                                idFile: elementR.id,
                                            })
                                        }
                                    })
                                }
                            }

                            if(element != null){
                                const d1 = new Date(element.created_at);
                                var tmp = d1.getFullYear() + "-" +
                                    (d1.getMonth() + 1) + "-" +
                                    d1.getDate() + " " +
                                    d1.getHours() + ":" +
                                    d1.getMinutes();

                                self.filesZones.push({
                                    created_at: tmp,
                                    nameArea: element.parent,
                                    path: element.path,
                                    text: element.text,
                                    tag: element.tag,
                                    idFile: element.idFile,
                                    relacion: {
                                        Padres: padres,
                                        Hijos: hijos,
                                    }
                                })
                            }
                        }
                    }
                    if (element.path == undefined) {
                        self.puntoFind.push(element)
                    }
                });

                self.numberFileG = self.filesZones.length
            } else if (isPoint) {
                if (typeM == "clickF") {
                    self.filesZones = []
                    self.filesG = []
                } else {
                    self.filesZoneP = []
                    self.filesG = []
                }

                await dataMap[0].children.map(function (element) {
                    let dataF = element.children.find(({ text }) => text == nameFigure)

                    if (dataF != undefined) {
                        dataF.children.map(function (element2) {
                            console.log(element2)
                            if (element2 != null) {
                                if (element2.tag != undefined) {
                                    var hijos = [];
                                    var padres = [];

                                    if (element2.relacion != null) {
                                        if (element2.relacion.Hijos.length > 0) {
                                            console.log(element2.relacion.Hijos)
                                            element2.relacion.Hijos.map(function (elementRH) {
                                                
                                                if(elementRH != null){
                                                    const d1 = new Date(elementRH.created_at);
                                                    var tmp = d1.getFullYear() + "-" +
                                                        (d1.getMonth() + 1) + "-" +
                                                        d1.getDate() + " " +
                                                        d1.getHours() + ":" +
                                                        d1.getMinutes();

                                                    hijos.push({
                                                        created_at: tmp,
                                                        ubicacion: elementRH.ubicacion,
                                                        name_user: elementRH.name_user,
                                                        path: elementRH.path,
                                                        title: elementRH.title,
                                                        tag: elementRH.tag_name,
                                                        idFile: elementRH.id,
                                                    })
                                                }
                                                
                                            })
                                        }

                                        if (element2.relacion.Padres.length > 0) {
                                            element2.relacion.Padres.map(function (elementR) {
                                                if(elementR != null){
                                                    const d1 = new Date(elementR.created_at);
                                                    var tmp = d1.getFullYear() + "-" +
                                                        (d1.getMonth() + 1) + "-" +
                                                        d1.getDate() + " " +
                                                        d1.getHours() + ":" +
                                                        d1.getMinutes();

                                                    padres.push({
                                                        created_at: tmp,
                                                        ubicacion: elementR.ubicacion,
                                                        name_user: elementR.name_user,
                                                        path: elementR.path,
                                                        title: elementR.title,
                                                        tag: elementR.tag_name,
                                                        idFile: elementR.id,
                                                    })
                                                }
                                            })
                                        }
                                    }

                                    if(element2 != null){
                                        const d1 = new Date(element2.created_at);
                                        var tmp = d1.getFullYear() + "-" +
                                            (d1.getMonth() + 1) + "-" +
                                            d1.getDate() + " " +
                                            d1.getHours() + ":" +
                                            d1.getMinutes();
                                        
                                        if (typeM == "clickF") {
                                            self.filesZones.push({
                                                created_at: tmp,
                                                namePoint: element2.namePoint,
                                                path: element2.path,
                                                text: element2.text,
                                                tag: element2.tag,
                                                idFile: element2.idFile,
                                                relacion: {
                                                    Padres: padres,
                                                    Hijos: hijos,
                                                }
                                            })
                                        } else {
                                            self.filesZoneP.push({
                                                created_at: tmp,
                                                namePoint: element2.namePoint,
                                                path: element2.path,
                                                text: element2.text,
                                                tag: element2.tag,
                                                idFile: element2.idFile,
                                                relacion: {
                                                    Padres: padres,
                                                    Hijos: hijos,
                                                }
                                            })
                                        }
                                    }
                                }
                            }
                        });
                    }
                });

            }

            if (typeM == "clickF") {
                await this.listForTagG();
                self.numberFileP = self.filesZones.length

                if (isPoint == true) {
                    this.typeFigure = "point"
                } else {
                    this.typeFigure = "figure"
                }
            } else {
                await this.listForTagP()
                self.numberFileP = self.filesZoneP.length
            }


        },
        listForTagG: async function () {
            var data = []
            await this.filesZones.map(function (element) {
                if (element.tag != undefined) {
                    data.push(element.tag);
                }
            });

            const result = data.filter(function (ele, pos) {
                return data.indexOf(ele) == pos;
            })
            this.tagsListG = result;
        },
        listForTagP: async function () {
            var data = []
            await this.filesZoneP.map(function (element) {
                if (element.tag != undefined) {
                    data.push(element.tag);
                }
            });

            const result = data.filter(function (ele, pos) {
                return data.indexOf(ele) == pos;
            })
            this.tagsListP = result;
        },
        permitSection: async function () {
            var groups = JSON.parse(window.localStorage.user).groups
            var statusE = false
            var self = this

            if (groups != "NaN") {
                groups.map(function (arr) {
                    self.rolesActualU.map(function (arr2) {
                        if (arr.idProject == self.projectId) {
                            if (arr.idRole == 2) {
                                statusE = false
                            } else if (arr.idRole) {
                                statusE = true
                            }
                        } else {
                            if (arr2.project_id == self.projectId) {
                                if (arr2.role_id == 2) {
                                    statusE = false
                                } else if (arr2.role_id == 4) {
                                    statusE = true
                                }
                            }
                        }
                    })
                })
            } else {
                self.rolesActualU.map(function (arr) {
                    if (arr.project_id == self.projectId) {
                        if (arr.role_id == 2) {
                            statusE = false
                        } else if (arr.role_id == 4) {
                            statusE = true
                        }
                    }
                })
            }

            if (JSON.parse(window.localStorage.user).isAdmin == true || statusE) {
                this.permitEdit = true;
            } else {
                this.permitEdit = false;
            }

        },
        registerLog: async function (sustantivo) {

            var obj ={
                tipo: "EDICIÓN", 
                verbo: "UPLOAD",
                sustantivo,
                idUser : JSON.parse(localStorage.user).id,
                idProject: this.projectId,
                'typeCanvas' : "C_MPB",
                'idCanvas': this.dataI.id
            }

            await axios
            .post("https://api.safered.co/api/setLog", obj, {
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(function (data) {
                console.log(data)
            })
            .catch(function (e) {
                console.log(e)
            });
        },
        reeSendFile: async function (type) {
            var dataFile = null
            var f = null


            var self = this
            let formData = new FormData();

            if (this.selectCode != null) {
                formData.append("folderId", this.selectCode);
            } else {
                formData.append("folderId", this.selectFolderGoogle);
            }

            if (type == "Area" || type == "Punto") {
                var nameF = this.documentF.name.split('.')

                if (this.mameFile == nameF[0] || this.mameFileUp == null) {
                    f = new File([this.documentF], `${this.nameFileRandom}.${nameF[1]}`, { type: this.documentF.type, lastModified: Date() })
                    this.documentF = f
                } else {
                    f = new File([this.documentF], `${this.mameFileUp}.${nameF[1]}`, { type: this.documentF.type, lastModified: Date() })
                    this.documentF = f
                }

                formData.append("mimeType", await this.documentF.type)
                formData.append("idProject", this.projectId)
                formData.append("sampleFile", this.documentF)

            } else if (type == "Canva") {
                nameF = this.documentC.name.split('.')

                if (this.mameFile == nameF[0] || this.mameFileUp == null) {
                    f = new File([this.documentC], `${this.nameFileRandom}.${nameF[1]}`, { type: this.documentC.type, lastModified: Date() })
                    this.documentC = f
                } else {
                    f = new File([this.documentC], `${this.mameFileUp}.${nameF[1]}`, { type: this.documentC.type, lastModified: Date() })
                    this.documentC = f
                }

                formData.append("mimeType", await this.documentC.type)
                formData.append("idProject", this.projectId)
                formData.append("sampleFile", this.documentC)
            }
            formData.append("emailClient", this.currentUser.clientInfo.emailContacto)

            var token = JSON.parse(localStorage.user).token;

            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };


            await axios
                .post("https://microservices.safered.co/api/storeFile", formData, config, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (response) {

                    dataFile = {
                        id: response.data.file.id,
                        name: response.data.file.name
                    }

                    self.documentF = null;
                    self.documentC = null;
                    self.dialogUpCopyA = false;
                    self.dialogUpCopyA2 = false;
                    self.dialogUpCopyC = false;
                    self.dialogUpCopyP = false;
                    self.dialogUpCopyP2 = false;
                    self.dialogUpCopyP3 = false;
                    self.dialogUpCopyP4 = false;

                }).catch(function (e) {
                    if (e.response.status != undefined) {
                        if (e.response.status == 409) {
                            console.log(e)
                        } else {
                            self.NotiFail = true
                        }
                    }

                });


            if (type == "Area" && this.idArea != null) {
                var obj = {
                    arrayLink: [
                        {
                            idTag: this.selectedTypeDocument,
                            idUser: this.currentUser.id,
                            idProject: this.projectId,
                            idCanvas: this.dataI.id,
                            idArea: this.idArea,
                            idGoogle: dataFile.id,
                            nameFile: dataFile.name,
                            type_canvas: 1
                        },
                    ]
                }
            } else if (type == "Canva") {
                obj = {
                    arrayLink: [
                        {
                            idTag: this.selectedTypeDocument,
                            idUser: this.currentUser.id,
                            idProject: this.projectId,
                            idCanvas: this.dataI.id,
                            idGoogle: dataFile.id,
                            nameFile: dataFile.name,
                            type_canvas: 1
                        }
                    ]
                }
            } else if (type == "Punto" || this.idPunto != null) {
                obj = {
                    arrayLink: [
                        {
                            idTag: this.selectedTypeDocument,
                            idUser: this.currentUser.id,
                            idProject: this.projectId,
                            idCanvas: this.dataI.id,
                            idPoint: this.idPunto,
                            idGoogle: dataFile.id,
                            nameFile: dataFile.name,
                            type_canvas: 1
                        },
                    ]
                }
            }

            await axios
                .post("https://microservices.safered.co/api/linkedFile", obj, config, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (res) {
                    console.log(res)
                    var sustantivo = `Usuario ${JSON.parse(localStorage.user).name} ha subido ${dataFile.name} en el ${type}: ${ type == "Canva" ? self.dataI.id : self.idPunto != null ? self.idPunto : self.idArea }`
                    self.registerLog(sustantivo);

                    var refr = self.$store.state.refreshImage + 1
                    self.$store.commit("setRefreshImage", refr)

                    self.NotiUploadD = true
                })
                .catch((e) => console.log(e));

            this.documentF = null;
            this.documentC = null;
            this.selectedTypeDocument = 0;
            this.selectFolderGoogle = null;
            this.selectCode = null;
        },
        uploadDocumentsCM: async function () {
            if (this.selectFolderGoogle != null || this.selectCode != null) {
                var dataFile = null


                var self = this
                let formData = new FormData();

                var nameFile = this.documentC.name.replaceAll('(', '[');
                nameFile = nameFile.replaceAll(')', ']');

                var f = new File([this.documentC], `${nameFile}`, { type: this.documentC.type, lastModified: Date() })

                formData.append("mimeType", this.documentC.type);
                if (this.selectCode != null) {
                    formData.append("folderId", this.selectCode);
                } else {
                    formData.append("folderId", this.selectFolderGoogle);
                }
                formData.append("idProject", this.projectId),
                    formData.append("sampleFile", f),
                    formData.append("emailClient", this.currentUser.clientInfo.emailContacto)


                var token = JSON.parse(localStorage.user).token;

                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };

                await axios
                    .post("https://microservices.safered.co/api/storeFile", formData, config, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(
                        function (data) {

                            dataFile = {
                                id: data.data.file.id,
                                name: data.data.file.name
                            }
                        })
                    .catch(
                        function (e) {
                            if (e.response.status == 409) {
                                var dataResponse = e.response.data.resultsLike
                                var count = dataResponse.length
                                var numSecuente = count + 1

                                if (count > 0) {
                                    dataResponse.map(function (obj, index) {
                                        var data = dataResponse.find(element => element.text.includes(`(${index + 1})`))
                                        if (data == undefined) {
                                            numSecuente = index + 1
                                        }
                                    })
                                }


                                var nameF = self.documentC.name.split('.')

                                self.nameFileRandom = `${nameF[0]}(${numSecuente})`
                                self.dialogUpCopyC = true;
                            } else {
                                self.NotiFail = true
                            }
                            console.log(e)

                        });
                if (dataFile != null) {
                    var obj = {
                        arrayLink: [
                            {
                                idTag: this.selectedTypeDocument,
                                idUser: this.currentUser.id,
                                idProject: this.projectId,
                                idCanvas: this.dataI.id,
                                idGoogle: dataFile.id,
                                nameFile: dataFile.name,
                                type_canvas: 1
                            },
                        ]
                    }

                    console.log(obj)
                    await axios
                        .post("https://microservices.safered.co/api//api/linkedFile", obj, config, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(function (res) {
                            var sustantivo = `Usuario ${JSON.parse(localStorage.user).name} ha subido ${dataFile.name} en el canva: ${self.this.dataI.id}`
                            self.registerLog(sustantivo);

                            var refr = self.$store.state.refreshImage + 1
                            self.$store.commit("setRefreshImage", refr)

                            self.NotiUploadD = true
                            console.log(res)
                        })
                        .catch((e) => console.log(e));

                    this.documentC = null;
                    this.selectedTypeDocument = 0;
                    this.selectFolderGoogle = null;
                    this.selectCode = null
                }

            } else {
                let self = this
                let formData = new FormData();
                formData.append("canvas_id_mp_bits", this.dataI.id);
                formData.append("file", this.documentC);
                formData.append("tag_id", this.selectedTypeDocument),
                formData.append("project_id", this.projectId),
                formData.append("user_id", JSON.parse(window.localStorage.user).id)


                await axios
                    .post("https://api.safered.co/api/store-file-canvas-mp-bits", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(function () {
                        self.NotiUploadD = true

                        var refr = self.$store.state.refreshImage + 1
                        self.$store.commit("setRefreshImage", refr)
                    })
                    .catch(function () {
                        self.NotiFail = true
                    });

                this.documentZ = null;
                this.selectedTypeDocument = 0;
            }
        },
        uploadDocumentsP: async function (nameFigure, from) {

            this.idPunto = null
            this.idArea = null
            var isPoint = false
            var isFigure = false
            var self = this
            
            console.log(this.polygonsLoadedMpBits);
            this.polygonsLoadedMpBits.map(function (element) {
                if (element.name == nameFigure) {
                    self.idArea = element.id
                    isFigure = true
                    isPoint = false
                }
            })

            this.dataPoints.map(function (element) {
                if (element.Codigo == nameFigure) {
                    self.idPunto = element.id
                    isPoint = true
                    isFigure = false
                }
            })

            console.log(isFigure);

            if (isPoint == true) {
                if (this.selectFolderGoogle != null || this.selectCode != null) {
                    var dataFile = null

                    let formData = new FormData();

                    var nameFile = this.documentF.name.replaceAll('(', '[');
                    nameFile = nameFile.replaceAll(')', ']');

                    var f = new File([this.documentF], `${nameFile}`, { type: this.documentF.type, lastModified: Date() })

                    formData.append("mimeType", this.documentF.type);
                    if (this.selectCode != null) {
                        formData.append("folderId", this.selectCode);
                    } else {
                        formData.append("folderId", this.selectFolderGoogle);
                    }
                    formData.append("idProject", this.projectId)
                    formData.append("sampleFile", f)
                    formData.append("emailClient", this.currentUser.clientInfo.emailContacto)

                    var token = JSON.parse(localStorage.user).token;

                    const config = {
                        headers: { Authorization: `Bearer ${token}` },
                    };


                    await axios
                        .post("https://microservices.safered.co/api/storeFile", formData, config, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(
                            function (data) {
                                dataFile = {
                                    id: data.data.file.id,
                                    name: data.data.file.name
                                }
                            })
                        .catch(
                            function (e) {
                                if (e.response.status == 409) {
                                    var dataResponse = e.response.data.resultsLike
                                    var count = dataResponse.length
                                    var numSecuente = count + 1

                                    if (count > 0) {
                                        dataResponse.map(function (obj, index) {
                                            var data = dataResponse.find(element => element.text.includes(`(${index + 1})`))
                                            if (data == undefined) {
                                                numSecuente = index + 1
                                            }
                                        })
                                    }


                                    var nameF = self.documentF.name.split('.')

                                    self.nameFileRandom = `${nameF[0]}(${numSecuente})`
                                    if (from == "Area") {
                                        self.dialogUpCopyA = true;
                                    } else if (from == "Area_Punto") {
                                        self.dialogUpCopyP2 = true;
                                    } else if (from == "Canva_Punto") {
                                        self.dialogUpCopyP4 = true;
                                    } else {
                                        self.dialogUpCopyP3 = true;
                                    }
                                } else {
                                    self.NotiFail = true
                                }
                                console.log(e)

                            });

                    if (dataFile != null) {
                        var obj = {
                            arrayLink: [
                                {
                                    idTag: this.selectedTypeDocument,
                                    idUser: this.currentUser.id,
                                    idProject: this.projectId,
                                    idCanvas: this.dataI.id,
                                    idPoint: this.idPunto,
                                    idGoogle: dataFile.id,
                                    nameFile: dataFile.name,
                                    type_canvas: 1
                                },
                            ]
                        }

                        await axios
                            .post("https://microservices.safered.co/api/linkedFile", obj, config, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then(function (res) {
                                var refr = self.$store.state.refreshImage + 1
                                self.$store.commit("setRefreshImage", refr)

                                self.NotiUploadD = true
                                console.log(res)
                            })
                            .catch((e) => console.log(e));

                        this.documentF = null;
                        this.selectedTypeDocument = 0;
                        this.selectFolderGoogle = null;
                        this.selectCode = null
                    }

                } else {


                    let formData = new FormData();
                    formData.append("canvas_id_mp_bits", this.dataI.id);
                    formData.append("point_id", this.idPunto);
                    formData.append("file", this.documentF);
                    formData.append("tag_id", this.selectedTypeDocument),
                    formData.append("project_id", this.projectId),
                    formData.append("user_id", JSON.parse(window.localStorage.user).id)

                    await axios
                        .post("https://api.safered.co/api/store-file", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(function () {
                            self.NotiUploadD = true

                            var refr = self.$store.state.refreshImage + 1
                            self.$store.commit("setRefreshImage", refr)
                        })
                        .catch(function () {
                            self.NotiFail = true
                        });

                    this.documentF = null;
                    this.selectedTypeDocument = 0;
                }

            } else if (isFigure == true) {
                
                if (this.selectFolderGoogle != null || this.selectCode != null) {
                    dataFile = null

                    let formData = new FormData();

                    nameFile = this.documentF.name.replaceAll('(', '[');
                    nameFile = nameFile.replaceAll(')', ']');

                    f = new File([this.documentF], `${nameFile}`, { type: this.documentF.type, lastModified: Date() })

                    formData.append("mimeType", this.documentF.type);
                    if (this.selectCode != null) {
                        formData.append("folderId", this.selectCode);
                    } else {
                        formData.append("folderId", this.selectFolderGoogle);
                    }
                    formData.append("idProject", this.projectId)
                    formData.append("sampleFile", f)
                    formData.append("emailClient", this.currentUser.clientInfo.emailContacto)

                    token = JSON.parse(localStorage.user).token;

                    const config = {
                        headers: { Authorization: `Bearer ${token}` },
                    };


                    await axios
                        .post("https://microservices.safered.co/api/storeFile", formData, config, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(
                            function (data) {
                                dataFile = {
                                    id: data.data.file.id,
                                    name: data.data.file.name
                                }
                                var count = self.$store.state.refreshDGeneral + 1;
                                self.$store.commit("setRefreshDGeneral", count);
                                alert("Archivo cargado exitosamente.")
                            })
                        .catch(
                            function (e) {
                                if (e.response.status == 409) {
                                    var dataResponse = e.response.data.resultsLike
                                    var count = dataResponse.length
                                    var numSecuente = count + 1

                                    if (count > 0) {
                                        dataResponse.map(function (obj, index) {
                                            var data = dataResponse.find(element => element.text.includes(`(${index + 1})`))
                                            if (data == undefined) {
                                                numSecuente = index + 1
                                            }
                                        })
                                    }


                                    var nameF = self.documentF.name.split('.')

                                    self.nameFileRandom = `${nameF[0]}(${numSecuente})`
                                    if (from == "Area") {
                                        self.dialogUpCopyA = true;
                                    } else {
                                        self.dialogUpCopyA2 = true;
                                    }
                                } else {
                                    self.NotiFail = true
                                }
                                console.log(e)
                            });

                    if (dataFile != null) {
                        obj = {
                            arrayLink: [
                                {
                                    idTag: this.selectedTypeDocument,
                                    idUser: this.currentUser.id,
                                    idProject: this.projectId,
                                    idCanvas: this.dataI.id,
                                    idArea: this.idArea,
                                    idGoogle: dataFile.id,
                                    nameFile: dataFile.name,
                                    type_canvas: 1
                                },
                            ]
                        }
                        await axios
                            .post("https://microservices.safered.co/api/linkedFile", obj, config, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then((res) => console.log(res))
                            .catch((e) => console.log(e));

                        this.documentF = null;
                        this.selectedTypeDocument = 0;
                        this.selectFolderGoogle = null;
                        this.selectCode = null
                    }
                } else {

                    let formData = new FormData();
                    formData.append("canvas_id_mp_bits", this.dataI.id);
                    formData.append("area_id", this.idArea);
                    formData.append("file", this.documentF);
                    formData.append("tag_id", this.selectedTypeDocument),
                    formData.append("project_id", this.projectId),
                    formData.append("user_id", JSON.parse(window.localStorage.user).id)

                    await axios
                        .post("https://api.safered.co/api/store-file-area", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(function () {
                            self.NotiUploadD = true

                            var refr = self.$store.state.refreshImage + 1
                            self.$store.commit("setRefreshImage", refr)
                        })
                        .catch(function () {
                            self.NotiFail = true
                        });

                    this.documentF = null;
                    this.selectedTypeDocument = null;
                }
            }
        },
        dataInit: async function () {
            if (this.dataI.image.length > 0) {
                var nameFile = this.dataI.image.split('/')[2]
                await axios({
                    url: 'https://api.safered.co/api/download-file/' + nameFile,
                    method: 'POST',
                    responseType: 'blob'
                })
                    .then((response) => {
                        this.imageForm = response.data
                    });
                this.backgroundImage = URL.createObjectURL(this.imageForm);
            }


            if (this.dataI.data[0] != undefined) {
                var contentP = document.getElementById(this.referencia)

                contentP.innerHTML = this.dataI.data[0];
            }

            if (this.dataI.data[1] != undefined) {
                this.numero_point = parseInt(this.dataI.data[1]);
            }

            if (this.dataI.data[2] != undefined) {
                this.numero_square = parseInt(this.dataI.data[2]);
            }

            if (this.dataI.data[3] != undefined) {
                this.numero_polygon = parseInt(this.dataI.data[3]);
            }

            if (this.dataI.data[4] != undefined) {
                this.dataObject = this.dataI.data[4];
            }

            this.polygonsLoadedMpBits.map((element) => {
                this.dataFig.push({
                    text: element.name,
                    value: element.name
                })
            })
            this.dataFig.push({
                text: "N/A",
                value: "N/A"
            })

            this.idPoint();
            this.idSquare();
            this.idPoly();
            this.textPoint();
        },
        select_point: function () {
            if (this.status_P == false) {
                this.status_P = true;
                this.status_Sq = false;
                this.status_circ = false;
                this.status_Poly = false;
                this.status_lin = false;
            } else {
                this.status_P = false;
                this.idPoint();
                this.textPoint()
                this.idSquare();
                this.idPoly();
            }
        },
        drawLClick: function (e) {

            var imgX = "";
            var imgY = "";

            if (this.status_P == true) {

                imgX = e.offsetX;
                imgY = e.offsetY;

                this.puntos.push({
                    x: imgX,
                    y: imgY
                })

                this.puntoC();
            } else if (this.status_Poly == true) {
                imgX = e.offsetX;
                imgY = e.offsetY;

                this.puntos.push({
                    x: imgX,
                    y: imgY
                })

                this.punto_poly();
            }
        },
        drawLDown: function (e) {
            var imgX = "";
            var imgY = "";

            if (this.status_Sq == true) {
                imgX = e.offsetX;
                imgY = e.offsetY;

                this.puntos.push({
                    x: imgX,
                    y: imgY
                })

            } else if (this.status_circ == true) {
                imgX = e.offsetX;
                imgY = e.offsetY;

                this.puntos.push({
                    x: imgX,
                    y: imgY
                })

            } else if (this.status_lin == true) {
                imgX = e.offsetX;
                imgY = e.offsetY;

                this.puntos.push({
                    x: imgX,
                    y: imgY
                })
            }
        },
        drawLUp: function (e) {
            var imgX = "";
            var imgY = "";
            if (this.status_Sq == true) {
                imgX = e.offsetX;
                imgY = e.offsetY;

                this.puntos.push({
                    x: imgX,
                    y: imgY
                })

                this.squareC();
            } else if (this.status_circ == true) {
                imgX = e.offsetX;
                imgY = e.offsetY;

                this.puntos.push({
                    x: imgX,
                    y: imgY
                })

                this.circleC();
            } else if (this.status_lin == true) {
                imgX = e.offsetX;
                imgY = e.offsetY;

                this.puntos.push({
                    x: imgX,
                    y: imgY
                })

                this.lineC();
            }

        },
        puntoC: function () {
            var tag = document.createElement("circle");
            var cx = document.createAttribute("cx");
            cx.value = (this.puntos[0].x - 10);
            var cy = document.createAttribute("cy");
            cy.value = (this.puntos[0].y + 2);
            var r = document.createAttribute("r");
            r.value = 8;
            var stroke = document.createAttribute("stroke");
            stroke.value = "black";
            var strokeWidth = document.createAttribute("stroke-width");
            strokeWidth.value = 1.5;
            var fill = document.createAttribute("fill");
            fill.value = "black";
            var id = document.createAttribute("id");

            this.idPoint();
            if (this.numero_point > 0) {
                id.value = this.referencia + this.numero_point + "_P";
            } else {
                id.value = (this.referencia + 0 + "_P");
            }

            tag.setAttributeNode(cx);
            tag.setAttributeNode(cy);
            tag.setAttributeNode(r);
            tag.setAttributeNode(stroke);
            tag.setAttributeNode(strokeWidth);
            tag.setAttributeNode(fill);
            tag.setAttributeNode(id);

            var data_svg = document.getElementById(this.referencia);
            data_svg.appendChild(tag);

            this.puntos = [];
            var test = [];

            for (let i = 0; i < data_svg.childNodes.length; i++) {
                test.push(data_svg.childNodes[i].outerHTML);
            }

            let value_svg = "";

            for (let t of test) {
                if (t != undefined) {
                    let fila = t + ' '
                    value_svg += fila
                }
            }

            data_svg.innerHTML = value_svg;
            this.estadoC = this.estadoC + 1;
        },
        idPoint() {

            var punto_data = document.querySelectorAll("circle");
            this.numero_point = punto_data.length;
        },
        select_square: function () {
            if (this.status_Sq == false) {
                this.status_P = false;
                this.status_Sq = true;
                this.status_circ = false;
                this.status_Poly = false;
                this.status_lin = false;
            } else {
                this.status_Sq = false;
                this.idPoint();
                this.textPoint();
                this.idSquare();
                this.idPoly();
            }
        },
        squareC: function () {
            var tag = document.createElement("rect");

            var x = document.createAttribute("x");
            x.value = (this.puntos[0].x - 10);
            var y = document.createAttribute("y");
            y.value = (this.puntos[0].y + 2);

            var width = document.createAttribute("width");
            width.value = this.puntos[1].x - this.puntos[0].x;
            var height = document.createAttribute("height");
            height.value = this.puntos[1].y - this.puntos[0].y;

            var stroke = document.createAttribute("stroke");
            stroke.value = "blue";
            var strokeWidth = document.createAttribute("stroke-width");
            strokeWidth.value = 1.5;
            var fill_opacity = document.createAttribute("fill-opacity");
            fill_opacity.value = 0.3;
            var fill = document.createAttribute("fill");
            fill.value = "orange";

            var id = document.createAttribute("id");

            this.idSquare();
            if (this.numero_square > 0) {
                id.value = this.referencia + this.numero_square + "_Sq";
            } else {
                id.value = (this.referencia + 0 + "_Sq");
            }


            tag.setAttributeNode(x);
            tag.setAttributeNode(y);
            tag.setAttributeNode(width);
            tag.setAttributeNode(height);
            tag.setAttributeNode(stroke);
            tag.setAttributeNode(strokeWidth);
            tag.setAttributeNode(fill);
            tag.setAttributeNode(fill_opacity);
            tag.setAttributeNode(id);

            var data_svg = document.getElementById(this.referencia);
            data_svg.appendChild(tag);

            this.puntos = [];
            var test = [];

            for (let i = 0; i < data_svg.childNodes.length; i++) {
                let stringAdd = data_svg.childNodes[i].outerHTML

                if (stringAdd != undefined) {
                    if (stringAdd.includes("<rect")) {
                        test.unshift(stringAdd);
                    } else {
                        test.push(stringAdd);
                    }
                }
            }

            let value_svg = "";

            for (let t of test) {
                if (t != undefined) {
                    let fila = t + ' '
                    value_svg += fila
                }
            }

            data_svg.innerHTML = value_svg;
            this.estadoC = this.estadoC + 1;
        },
        idSquare: function () {

            var square_data = document.querySelectorAll("rect");
            this.numero_square = square_data.length;
        },
        select_poly: function () {
            if (this.status_Poly == false) {
                this.status_P = false;
                this.status_Sq = false;
                this.status_circ = false;
                this.status_Poly = true;
                this.status_lin = false;
            } else {
                this.status_Poly = false;
                this.idPoint();
                this.idSquare();
                this.textPoint();
                this.idPoly();
            }
        },
        punto_poly: function () {
            var tag = document.createElement("circle");
            var cx = document.createAttribute("cx");
            var cy = document.createAttribute("cy");

            for (let i = 0; i < this.puntos.length; i++) {
                if ((this.puntos.length - 1) == i) {
                    cx.value = (this.puntos[i].x - 10);
                    cy.value = (this.puntos[i].y + 2);
                }
            }

            var fill = null;
            var r = document.createAttribute("r");
            r.value = 4;
            var stroke = document.createAttribute("stroke");
            stroke.value = "black";
            var strokeWidth = document.createAttribute("stroke-width");
            strokeWidth.value = 1.5;
            if (this.puntos.length == 1) {
                fill = document.createAttribute("fill");
                fill.value = "red";
            } else {
                fill = document.createAttribute("fill");
                fill.value = "white";
            }
            var id = document.createAttribute("id");
            id.value = "temp_delete";

            tag.setAttributeNode(cx);
            tag.setAttributeNode(cy);
            tag.setAttributeNode(r);
            tag.setAttributeNode(stroke);
            tag.setAttributeNode(strokeWidth);
            tag.setAttributeNode(fill);
            tag.setAttributeNode(id);

            var data_svg = document.getElementById(this.referencia);
            data_svg.appendChild(tag);

            var test = [];

            for (let i = 0; i < data_svg.childNodes.length; i++) {
                test.push(data_svg.childNodes[i].outerHTML);
            }

            let value_svg = "";

            for (let t of test) {
                if (t != undefined) {
                    let fila = t + ' '
                    value_svg += fila
                }
            }

            data_svg.innerHTML = value_svg;
            this.confirmFP();
        },
        confirmFP: function () {

            var fposition = (this.puntos.length - 1);
            var menorCoordX = (this.puntos[0].x - 10);
            var maxCoordX = (this.puntos[0].x + 10);
            var menorCoordY = (this.puntos[0].y - 10);
            var maxCoordY = (this.puntos[0].y + 10)

            if (this.puntos.length > 4) {
                if (this.puntos[fposition].x > menorCoordX && this.puntos[fposition].x < maxCoordX &&
                    this.puntos[fposition].y > menorCoordY && this.puntos[fposition].y < maxCoordY) {

                    this.polygon();
                }
            }

        },
        polygon: function () {
            var tag = document.createElement("polygon");

            var points = document.createAttribute("points");
            var point_data = "";

            for (let i = 0; i < this.puntos.length; i++) {
                let fila = this.puntos[i].x + ',' + this.puntos[i].y + ' '
                point_data += fila
            }
            points.value = point_data;


            var stroke = document.createAttribute("stroke");
            stroke.value = "blue";
            var strokeWidth = document.createAttribute("stroke-width");
            strokeWidth.value = 1.5;
            var fill = document.createAttribute("fill");
            fill.value = "orange";
            var fill_opacity = document.createAttribute("fill-opacity");
            fill_opacity.value = 0.3;
            var id = document.createAttribute("id");

            this.idPoly();
            if (this.numero_polygon > 0) {
                id.value = this.referencia + this.numero_polygon + "_Po";
            } else {
                id.value = (this.referencia + 0 + "_Po");
            }

            tag.setAttributeNode(points);
            tag.setAttributeNode(fill);
            tag.setAttributeNode(fill_opacity);
            tag.setAttributeNode(stroke);
            tag.setAttributeNode(strokeWidth);
            tag.setAttributeNode(id);

            var data_svg = document.getElementById(this.referencia);
            data_svg.appendChild(tag);

            this.puntos = [];
            var test = [];

            for (let i = 0; i < data_svg.childNodes.length; i++) {
                if (data_svg.childNodes[i].id != "temp_delete") {

                    let stringAdd = data_svg.childNodes[i].outerHTML
                    if (stringAdd != undefined) {
                        if (stringAdd.includes("<polygon")) {
                            test.unshift(stringAdd);
                        } else {
                            test.push(stringAdd);
                        }
                    }
                }
            }

            let value_svg = "";

            for (let t of test) {
                if (t != undefined) {
                    let fila = t + ' '
                    value_svg += fila
                }
            }

            data_svg.innerHTML = value_svg;
            this.estadoC = this.estadoC + 1;
        },
        idPoly: function () {

            var polygon_data = document.querySelectorAll("polygon");
            this.numero_polygon = polygon_data.length;

        },
        select_circle: function () {
            if (this.status_circ == false) {
                this.status_P = false;
                this.status_Sq = false;
                this.status_circ = true;
                this.status_Poly = false;
                this.status_lin = false;
            } else {
                this.status_circ = false;
                this.idPoint();
                this.idSquare();
                this.idPoly();
                this.textPoint();
            }
        },
        circleC: function () {
            var tag = document.createElement("circle");

            var cx = document.createAttribute("cx");
            cx.value = (this.puntos[0].x - 10);
            var cy = document.createAttribute("cy");
            cy.value = (this.puntos[0].y + 2);

            var d = ((this.puntos[1].x - this.puntos[0].x));

            var r = document.createAttribute("r");
            r.value = d;

            var stroke = document.createAttribute("stroke");
            stroke.value = "blue";
            var strokeWidth = document.createAttribute("stroke-width");
            strokeWidth.value = 1.5;
            var fill = document.createAttribute("fill");
            fill.value = "orange";
            var fill_opacity = document.createAttribute("fill-opacity");
            fill_opacity.value = 0.3;
            var id = document.createAttribute("id");

            this.idPoint();
            if (this.numero_point > 0) {
                id.value = this.referencia + this.numero_point + "_C";
            } else {
                id.value = (this.referencia + 0 + "_C");
            }

            tag.setAttributeNode(cx);
            tag.setAttributeNode(cy);
            tag.setAttributeNode(r);
            tag.setAttributeNode(stroke);
            tag.setAttributeNode(strokeWidth);
            tag.setAttributeNode(fill);
            tag.setAttributeNode(id);
            tag.setAttributeNode(fill_opacity);

            var data_svg = document.getElementById(this.referencia);
            data_svg.appendChild(tag);

            this.puntos = [];
            var test = [];

            for (let i = 0; i < data_svg.childNodes.length; i++) {
                let stringAdd = data_svg.childNodes[i]
                if (stringAdd.outerHTML != undefined) {
                    if (stringAdd.id.slice(-2) == "_C") {
                        test.unshift(stringAdd.outerHTML);
                    } else {
                        test.push(stringAdd.outerHTML);
                    }
                }
            }

            let value_svg = "";

            for (let t of test) {
                if (t != undefined) {
                    let fila = t + ' '
                    value_svg += fila
                }
            }

            data_svg.innerHTML = value_svg;
            this.estadoC = this.estadoC + 1;
        },
        select_line: function () {
            if (this.status_lin == false) {
                this.status_P = false;
                this.status_Sq = false;
                this.status_circ = false;
                this.status_Poly = false;
                this.status_lin = true;
            } else {
                this.status_lin = false;
                this.idPoint();
                this.idSquare();
                this.idPoly();
                this.textPoint();
            }
        },
        lineC: function () {
            var tag = document.createElement("line");

            var x1 = document.createAttribute("x1");
            x1.value = (this.puntos[0].x - 10);
            var y1 = document.createAttribute("y1");
            y1.value = (this.puntos[0].y + 2);

            var x2 = document.createAttribute("x2");
            x2.value = (this.puntos[1].x - 10);
            var y2 = document.createAttribute("y2");
            y2.value = (this.puntos[1].y + 2);

            var stroke = document.createAttribute("stroke");
            stroke.value = "black";
            var strokeWidth = document.createAttribute("stroke-width");
            strokeWidth.value = 5;
            var id = document.createAttribute("id");

            if (this.numero_line > 0) {
                id.value = this.referencia + this.numero_line + "_Li";
            } else {
                id.value = (this.referencia + 0 + "_Li");
            }

            tag.setAttributeNode(x1);
            tag.setAttributeNode(y1);
            tag.setAttributeNode(x2);
            tag.setAttributeNode(y2);
            tag.setAttributeNode(stroke);
            tag.setAttributeNode(strokeWidth);
            tag.setAttributeNode(id);

            var data_svg = document.getElementById(this.referencia);
            data_svg.appendChild(tag);

            this.puntos = [];
            var test = [];

            for (let i = 0; i < data_svg.childNodes.length; i++) {
                let stringAdd = data_svg.childNodes[i].outerHTML
                if (stringAdd != undefined) {
                    if (stringAdd.includes("<line")) {
                        test.unshift(stringAdd);
                    } else {
                        test.push(stringAdd);
                    }
                }
            }

            let value_svg = "";

            for (let t of test) {
                if (t != undefined) {
                    let fila = t + ' '
                    value_svg += fila
                }
            }

            data_svg.innerHTML = value_svg;
            this.estadoC = this.estadoC + 1;
        },
        textPoint() {
            var self = this;
            var punto_data = document.querySelectorAll("circle");

            punto_data.forEach(punto => {
                punto.addEventListener('click', function (e) {

                    var data = self.dataObject;
                    var idObject = e.target.id;

                    if (self.referencia == idObject.slice(0, 4)) {
                        if (data.length == 0) {
                            self.dataObject.push({
                                id: idObject
                            });
                            self.selectObject = idObject;
                            self.selectObjectM(idObject);
                        } else {
                            const foundId = data.find(item => {
                                return item.id === idObject;
                            })

                            if (foundId == undefined) {
                                self.dataObject.push({
                                    id: idObject
                                });
                                self.selectObject = idObject;
                                self.selectObjectM(idObject);
                            } else {
                                self.selectObject = idObject;
                                self.selectObjectM(idObject);
                            }
                        }
                    }
                });
            });

            var square_data = document.querySelectorAll("rect");

            square_data.forEach(square => {
                square.addEventListener('click', function (e) {
                    console.log(e)
                    var data = self.dataObject;
                    var idObject = e.target.id;

                    if (self.referencia == idObject.slice(0, 4)) {
                        if (data.length == 0) {
                            self.dataObject.push({
                                id: idObject
                            });
                            self.selectObject = idObject;
                            self.selectObjectM(idObject);
                        } else {
                            const foundId = data.find(item => {
                                return item.id === idObject;
                            })

                            if (foundId == undefined) {
                                self.dataObject.push({
                                    id: idObject
                                });
                                self.selectObject = idObject;
                                self.selectObjectM(idObject);
                            } else {
                                self.selectObject = idObject;
                                self.selectObjectM(idObject);
                            }
                        }
                    }
                });
            });

            var polygon_data = document.querySelectorAll("polygon");

            polygon_data.forEach(polygon => {
                polygon.addEventListener('click', function (e) {

                    var data = self.dataObject;
                    var idObject = e.target.id;

                    if (self.referencia == idObject.slice(0, 4)) {
                        if (data.length == 0) {
                            self.dataObject.push({
                                id: idObject
                            });
                            self.selectObject = idObject;
                            self.selectObjectM(idObject);
                        } else {
                            const foundId = data.find(item => {
                                return item.id === idObject;
                            })

                            if (foundId == undefined) {
                                self.dataObject.push({
                                    id: idObject
                                });
                                self.selectObject = idObject;
                                self.selectObjectM(idObject);
                            } else {
                                self.selectObject = idObject;
                                self.selectObjectM(idObject);
                            }
                        }
                    }
                });
            });

            var line_data = document.querySelectorAll("line");

            line_data.forEach(line => {
                line.addEventListener('click', function (e) {
                    var data = self.dataObject;
                    var idObject = e.target.id;

                    if (self.referencia == idObject.slice(0, 4)) {
                        if (data.length == 0) {
                            self.dataObject.push({
                                id: idObject
                            });
                            self.selectObject = idObject;
                            self.selectObjectM(idObject);
                        } else {
                            const foundId = data.find(item => {
                                return item.id === idObject;
                            })

                            if (foundId == undefined) {
                                self.dataObject.push({
                                    id: idObject
                                });
                                self.selectObject = idObject;
                                self.selectObjectM(idObject);
                            } else {
                                self.selectObject = idObject;
                                self.selectObjectM(idObject);
                            }
                        }
                    }
                })
            })

        },
        guardarNameFig(id) {
            this.dataObject[id].name = this.nameFigure;
            var idData = this.dataObject[id].id

            if (idData.slice(-2) == "_P") {
                this.createSensor(this.dataObject[id].name, "point");
            } else if (idData.slice(-3) == "_Po" || idData.slice(-3) == "_Po" || idData.slice(-2) == "_C"
                || idData.slice(-3) == "_Sq") {
                this.createFigure(this.dataObject[id].name, "polygon")
            } else if (idData.slice(-3) == "_Li") {
                alert("Se ha guardado la figura");
            } else {
                alert("No es una figura contenedora");
            }
        },
        eliminarF: async function (id, nameFigure) {

            let data_svg = document.getElementById(this.referencia);
            let self = this
            let test = []
            let idArea = null
            let idPunto = null
            let isFigure = false
            let isPoint = false

            for (let i = 0; i < data_svg.childNodes.length; i++) {
                if (data_svg.childNodes[i].id != this.dataObject[id].id) {
                    console.log(data_svg.childNodes[i])
                    test.push(data_svg.childNodes[i].outerHTML);
                }
            }

            let value_svg = "";

            for (let t of test) {
                if (t != undefined) {
                    let fila = t + ' '
                    value_svg += fila
                }
            }

            var copyDataOb = []

            for (let i = 0; i < this.dataObject.length; i++) {
                if (id != i) {
                    copyDataOb.push(this.dataObject[i])
                }
            }

            this.dataObject = copyDataOb
            data_svg.innerHTML = value_svg
            this.estadoC = this.estadoC + 1

            this.polygonsLoadedMpBits.map(function (element) {
                if (element.name == nameFigure) {
                    idArea = element.id
                    isFigure = true
                    isPoint = false
                }
            })

            this.dataPoints.map(function (element) {
                if (element.Codigo == nameFigure) {
                    idPunto = element.id
                    isPoint = true
                    isFigure = false
                }
            })



            if (isPoint && self.permitEdit) {

                await axios
                    .delete('https://api.safered.co/api/deletePoint/' + idPunto +"/"+ JSON.parse(localStorage.user).id)
                    .then(function () {
                        self.NotideleteP = true
                    })
                    .catch(function () {
                        self.NotiFail = true
                    });

            } else if (isFigure && self.permitEdit) {
                await axios
                    .delete('https://api.safered.co/api/deleteArea/' + idArea +"/"+ JSON.parse(localStorage.user).id)
                    .then(function (response) {
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            } else {
                console.log("No tiene permisos o no ha asignado la figura")
            }


            var refr = self.$store.state.refreshImage + 1
            self.$store.commit("setRefreshImage", refr)
            setTimeout(function () {
                self.dataInit()
            }, 4000)
        },
        selectObjectM(id) {
            if (this.status_P == false && this.status_Sq == false && this.status_circ == false
                && this.status_Poly == false && this.status_lin == false) {
                    
                if(!id.includes('Li')){
                    this.$root.$emit('bv::show::modal', 'modal-object' + this.referencia, '#focusThisOnClose');
                }
                
                console.log(this.dataObject);

                if (this.referencia == id.slice(0, 4)) {
                    for (let i = 0; i < this.dataObject.length; i++) {
                        if (this.dataObject[i].id == id) {
                            this.selectP = i;
                            if (this.dataObject[i].name == undefined) {
                                this.nameFigure = id;
                                this.nameCopy = id
                                this.statusEdit = false;
                                this.changeN = true;
                            } else {
                                this.nameFigure = this.dataObject[i].name;
                                this.idFigure = this.dataObject[i].id;
                                this.statusEdit = true;
                                this.changeN = false;
                                this.loadDatafigure(this.nameFigure, "clickF");
                            }
                        }
                    }
                }
            }
        },
        async setImage() {
            let URL = window.URL;
            this.backgroundImage = URL.createObjectURL(this.image);
        },
        uploadImageModal: function () {
            this.active = true;
        },
        clearImage() {
            this.image = null;
        },
        onSubmit() {
            if (!this.image) {
                alert("Por favor seleccione una imagen.");
                return;
            }
        },
        saveDrawCloud() {
            this.estadoOrg();
            var self = this
            setTimeout(function () {
                var dataCloud = {
                    id: self.dataI.id,
                    data: [
                        self.cloudData,
                        self.numero_point,
                        self.numero_square,
                        self.numero_polygon,
                        self.dataObject,
                    ]
                }

                axios
                    .post('https://api.safered.co/api/setDataCanvasImage', dataCloud)
                    .then(function (response) {
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }, 500)
            var refr = self.$store.state.refreshImage + 1
            self.$store.commit("setRefreshImage", refr)
        },
        saveBackground: async function () {
            var self = this;

            if (JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true || JSON.parse(window.localStorage.user).isEditor == true) {
                let formData = new FormData();
                formData.append("id", this.dataI.id);
                formData.append("image", this.image);

                await axios
                    .post('https://api.safered.co/api/updateImageBackground', formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(function () {
                        var refr = self.$store.state.refreshImage + 1
                        self.$store.commit("setRefreshImage", refr)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            } else {
                console.log("No tiene los permisos")
            }
        },
        createSensor: function (id, tipo) {
            let self = this
            let arrayPoints = {
                idUser: JSON.parse(localStorage.user).id,
                IdCanvas: this.dataI.id,
                IdProyecto: this.projectId,
                data: []
            };

            arrayPoints.data.push({
                
                Codigo: id,
                TipoSensor: tipo,
                Unidad: "N/A",
                Grupo: "N/A",
                UbicacionGPS: "NA",
                UbicacionCartesiana: "N/A",
                AreaMpBits: this.selectedFig.text,
            });
            console.log(arrayPoints);
            axios
                .post("https://api.safered.co/api/uploadSensors", arrayPoints, {
                    headers: { 
                        'Content-Type': 'application/json'
                    }
                })
                .then(function () {
                    console.log("Puntos cargados...");

                    self.NotiFigure = true
                    var refr = self.$store.state.refreshImage + 1
                    self.$store.commit("setRefreshImage", refr)
                })
                .catch(function (e) {
                    console.log(e);

                    self.NotiFail = true
                });
            var refr = self.$store.state.refreshImage + 1
            self.$store.commit("setRefreshImage", refr)
        },
        createFigure: function (id, tipo) {
            let self = this
            let forms = {
                idUser: JSON.parse(localStorage.user).id,
                IdCanvas: this.dataI.id,
                IdProyecto: this.projectId,
                data: []
            };
            forms.data = [{
                points: "[]",
                radius: "",
                name: id,
                type: tipo,
            }]
            console.log(forms)
            axios
                .post("https://api.safered.co/api/addFigure", forms)
                .then(function () {
                    console.log("Figuras cargados...");

                    self.NotiFigure = true

                    var refr = self.$store.state.refreshImage + 1
                    self.$store.commit("setRefreshImage", refr)
                })
                .catch(function (e) {
                    console.log(e);

                    self.NotiFail = true
                });

            forms = []
            var refr = self.$store.state.refreshImage + 1
            self.$store.commit("setRefreshImage", refr)
        },
        estadoOrg: function () {
            var test = [];
            var data_svg = document.getElementById(this.referencia);

            for (let i = 0; i < data_svg.childNodes.length; i++) {
                test.push(data_svg.childNodes[i].outerHTML);
            }

            this.cloudData = "";
            for (let t of test) {
                if (t != undefined) {
                    let fila = t + ' '
                    this.cloudData += fila
                }
            }
        },
        downloadFile: async function (pathFile, title) {
            var isSafe = pathFile.includes("/")
            console.log(isSafe)

            if (isSafe) {
                var nameFile = pathFile.split("/")[2];

                await axios({
                    url: "https://api.safered.co/api/download-file/" + nameFile,
                    method: "POST",
                    responseType: "blob",
                }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", title);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
            } else {
                var result = {}
                var obj = {
                    idFile: pathFile,
                    idProject: this.projectId
                }
                const config = {
                    headers: { Authorization: `Bearer ${this.token}` },
                };

                await axios
                    .post("https://microservices.safered.co/api/downloadFile", obj, config, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => result = res.data)
                    .catch((e) => console.log(e));

                const base64 = await fetch(result.FileB64)
                const blob = await base64.blob();

                var fileURL = window.URL.createObjectURL(blob);
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", result.NameFile);
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        },
        filtTagsLG: async function (tag) {
            let tempFiles = this.filesZones;
            this.filesG = [];

            function filtTags(tagL) {
                return tagL.tag == tag;
            }
            console.log(tempFiles)
            this.filesG = tempFiles.filter(filtTags);
        },
        filtTagsLGP: async function (tag) {
            let tempFiles = this.filesZoneP;
            this.filesG = [];

            function filtTags(tagL) {
                return tagL.tag == tag;
            }

            this.filesG = tempFiles.filter(filtTags);
        },
        listFileC: async function () {
            this.filesCanvas = []
            var self = this
            var dataFileC = []
            dataFileC.push(this.dataMap[0])

            dataFileC[0].children.map(function (obj) {
                if (obj.path != undefined) {
                    self.filesCanvas.push(obj)
                }
            })

            await this.listForTagC();
        },
        listForTagC: async function () {
            var data = [];

            await this.filesCanvas.map(function (element) {
                if (element.tag != undefined) {
                    data.push(element.tag);
                }
            });

            const result = data.filter(function (ele, pos) {
                return data.indexOf(ele) == pos;
            })
            this.tagsListC = result;
        },
        ordenarForTagC(tagsLC) {
            var tempFiles = this.filesCanvas;
            this.filesC = [];

            function filtTags(tagL) {
                return tagL.tag == tagsLC;
            }

            this.filesC = tempFiles.filter(filtTags);
        },
    },
    watch: {
        codeActive: function () {
            this.selectFolderGoogle = null
            this.selectCode = null
        },
        image(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then((value) => {
                            this.imageSrc = value;
                        })
                        .catch(() => {
                            this.imageSrc = null;
                        });
                } else {
                    this.imageSrc = null;
                }
            }
        },
        estadoC: async function () {
            await this.estadoOrg();
            this.saveDrawCloud();
        },
    }
};
</script>
<style>

.button_fi svg {
    width: 14px;
    height: 14px;
}

.struct_btn {
    margin-block: 10px;
}
</style>