<template>
    <div>
        <b-modal size="sm" hide-backdrop centered id="modal-02-file" :title="`Informacion de ${editingItem.text}`" hide-footer>
            
            <div style="margin: 0.5rem !important;">
                <b-button variant="light" class="btn_drop"  @click="downloadFile()">
                    Descargar Archivo
                </b-button>
            </div>
            <!-- <div style="margin: 0.5rem !important;">
                <b-button variant="light" class="btn_drop" @click="$bvModal.show('modal-tagAsing')">
                    Asociar al canvas
                </b-button>
            </div>
            <b-dropdown style="display: grid;" block id="dropdown-dropright" dropright text="Asociar a Area" variant="light" class="m-2 drop_L">
                <b-dropdown-item @click="$bvModal.show(`modal-tagAsing-${index}`)" v-for="(line, index) in areasC" :key="index">
                    {{line.name}}

                    <b-modal hide-backdrop centered :id="`modal-tagAsing-${index}`" :title="`Asignacion del tag para ${editingItem.text}`" hide-footer>
                        <v-row>
                            <v-col cols="6">
                                <v-select  v-model="selectTag" :items="typeDocument" label="Seleccione tipo"></v-select>
                            </v-col>
                            <v-col cols="6" style="display: flex; align-items: center;
                                justify-content: center;">
                                <v-btn
                                elevation="2"
                                @click="addAsoci(1, line.id);"
                                >
                                Asignar a un area
                                </v-btn> 
                            </v-col>
                        </v-row>
                    </b-modal>

                </b-dropdown-item>
            </b-dropdown>
            <b-dropdown style="display: grid;" block id="dropdown-dropright" dropright text="Asociar a punto" variant="light" class="m-2 drop_L">
                <b-dropdown-item @click="$bvModal.show(`modal-tagAsingP-${index}`)" v-for="(anillo, index) in pointsC" :key="index">
                    <p style="margin-bottom: 0;" v-if="anillo.name != undefined">{{anillo.name}}</p>
                    <p style="margin-bottom: 0;" v-if="anillo.name == undefined">{{anillo.Codigo}}</p>

                    <b-modal hide-backdrop centered :id="`modal-tagAsingP-${index}`" :title="`Asignacion del tag para ${editingItem.text}`" hide-footer>
                        <v-row>
                            <v-col cols="6">
                                <v-select  v-model="selectTag" :items="typeDocument" label="Seleccione tipo"></v-select>
                            </v-col>
                            <v-col cols="6" style="display: flex; align-items: center;
                                justify-content: center;">
                                <v-btn
                                    elevation="2"
                                    @click="addAsoci(2, anillo.id);"
                                >
                                    Asignar a un punto
                                </v-btn> 
                            </v-col>
                        </v-row>
                    </b-modal>

                </b-dropdown-item>
            </b-dropdown> -->
            <div style="margin: 0.5rem !important;">
                <b-button variant="light" class="btn_drop" @click="reset();">
                    Compartir al pocket
                </b-button>

                <b-modal size="lg" centered id="modal-shareUser" :title="`Compartir el archivo ${editingItem.text} al pocket`" hide-footer>
                    <v-row>
                        <v-col cols="6">
                            <v-select @change="verifyPermision()"  v-model="selectUser" :items="users" label="Seleccione el usuario"></v-select>
                        </v-col>
                        <v-col cols="6" style="display: flex; align-items: center;
                            justify-content: center;">
                            <v-btn
                                elevation="2"
                                @click="sendPocket();"
                                :disabled="fileF || selectUser == null"
                                color="#5AB55E"
                                style="color: white;"
                            >
                                Compartir
                            </v-btn> 
                        </v-col>
                    </v-row>
                </b-modal>
            </div>
            <div style="margin: 0.5rem !important;">
                <b-button variant="light" class="btn_drop"  @click="customItemClick()">
                    Eliminar Archivo
                </b-button>
            </div>
        </b-modal>

        <b-modal hide-backdrop centered id="modal-tagAsing" :title="$t('shaFilePock_modalPerfil {text}', {text:editingItem.text})" hide-footer>
            <v-row>
                <v-col cols="6">
                    <v-select  v-model="selectTag" :items="typeDocument" label="Seleccione tipo"></v-select>
                </v-col>
                <v-col cols="6" style="display: flex; align-items: center;
                    justify-content: center;">
                    <v-btn
                    elevation="2"
                    @click="addAsoci(0);"
                    >
                    Asignar a canvas
                    </v-btn> 
                </v-col>
            </v-row>
        </b-modal>

        <v-snackbar
            v-model="NotiAsoc"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se han asociado el <strong>documento</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="NotiAsoc = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar
            v-model="NotiFail"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
            No se ha podido realizar la asociación

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="NotiFail = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>

    import api from "@/api";
    import axios from "axios";
    import filtExistObj from '@/api/scriptE/index';

    export default {
        name: "downloadFile",
        data(){
            return{
                NotiAsoc: false,
                NotiFail: false,
                areasC: [],
                pointsC: [],
                token: null,
                currentUser: null,
                selectTag: '',
                selectUser: null,
                users: [],
                fileF: false,
                tempV: [],
            }
        },
        props: [ 'data', 'editingItem', 'projectId', 'typeDocument', 'idCanvas', 'typeCanvas', 
            'dataDelete'],
        async mounted(){
            this.getUsers();
            this.areasC = this.$store.state.dataFindPoint
            this.pointsC = this.$store.state.dataPoint
            this.currentUser = JSON.parse(localStorage.user)
            this.token = JSON.parse(localStorage.user).token
        },
        methods: {
            reset(){
                this.$bvModal.show('modal-shareUser');
                this.selectUser = null;
            },
            sendPocket: async function(){
                var self = this
                var obj = {
                    idProject: this.projectId,
                    idUserSend: JSON.parse(localStorage.user).id,
                    idUserRecive: this.selectUser,
                    idCloudFile: this.editingItem.id,
                    nameFile: this.editingItem.text,
                }
                
                await api
                .request( "post", "/setPocketFile", obj, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (res) {
                   console.log(res)
                   self.$bvModal.hide('modal-shareUser')
                })
                .catch(function (e) {
                    console.log(e)
                });
            },
            verifyPermision: async function(){
                var self = this;
                var rulesPerson = null;
                var obj = {
                    id: this.selectUser,
                    key: 'user'
                };
                this.fileF = true;

                const config = {
                    headers: { Authorization: `Bearer ${this.token}` },
                };
                await axios
                .post("https://microservices.safered.co/api/getRules", obj, config, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (res) {
                    if(res.data.rules.length > 0){
                        rulesPerson = JSON.parse(res.data.rules[0].rule)
                    }
                })
                .catch(function (e) {
                    console.log(e)
                });
                
                var findV = []
                console.log(self.data)
                console.log(self.editingItem.id)
                if(rulesPerson != null){
                    rulesPerson.map(async function(element){
                        console.log(element)
                        var ind = await filtExistObj.findChildrenEquals(self.data, element, self.editingItem.id);
                        if(ind == true){
                            findV.push(ind)
                        }
                    })
                }

                this.tempV = findV
            },  
            getUsers: async function () {
                var self = this
                var clientsTmp = []
                this.users = []

                await api
                .request(
                    "get",
                    "/listUsers/" + JSON.parse(localStorage.user).client_id + "/clients"
                )
                .then((data) => {
                    clientsTmp = data.data.users;
                })
                .catch(function (e) {
                    console.log(e);
                });

                clientsTmp.forEach((element) => {
                    if (JSON.parse(localStorage.user).id != element.id){
                        var tmp_obj = {
                            value: element.id,
                            text: element.name,
                        };
                        self.users.push(tmp_obj);
                    }
                    
                });

            },
            downloadFile: async function(){
                
                var result = {}
                var obj = {
                    idFile: this.editingItem.id,
                    idProject: this.projectId
                }
                const config = {
                    headers: { Authorization: `Bearer ${this.token}` },
                };

                console.log(obj)
                await axios
                .post("https://microservices.safered.co/api/downloadFile", obj, config, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => result = res.data)
                .catch((e) => console.log(e));

                const base64 = await fetch(result.FileB64)
                const blob = await base64.blob();

                var fileURL = window.URL.createObjectURL(blob);
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", result.NameFile);
                document.body.appendChild(fileLink);
                fileLink.click();
            }, 
            addAsoci: async function(option, id){
                var self = this;
                var obj = {};
                const config = {
                    headers: { Authorization: `Bearer ${this.token}` },
                };
                
                console.log(this.editingItem)
                if(option == 0){
                    obj = {
                        arrayLink: [
                            {   
                                idTag: this.selectTag,
                                idUser: this.currentUser.id,
                                idProject: this.projectId,
                                idCanvas: this.idCanvas,
                                idGoogle: this.editingItem.id,
                                nameFile: this.editingItem.text,
                                type_canvas: this.typeCanvas
                            },
                        ]
                    }
                    
                }else if(option == 1){
                    obj = {
                        arrayLink: [
                            {
                                idTag: this.selectTag,
                                idUser: this.currentUser.id,
                                idProject: this.projectId,
                                idCanvas: this.idCanvas,
                                idArea: id,
                                idGoogle: this.editingItem.id,
                                nameFile: this.editingItem.text,
                                type_canvas: this.typeCanvas
                            },
                        ]
                    }

                }else if(option == 2){
                    obj = {
                        arrayLink: [
                            {
                                idTag: this.selectTag,
                                idUser: this.currentUser.id,
                                idProject: this.projectId,
                                idCanvas: this.idCanvas,
                                idPoint: id,
                                idGoogle: this.editingItem.id,
                                nameFile: this.editingItem.text,
                                type_canvas: this.typeCanvas
                            },
                        ]
                    }
                }

                await axios
                .post("https://microservices.safered.co/api/linkedFile", obj, config, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (){
                    var count = self.$store.state.refreshDGeneral + 1;
                    self.$store.commit("setRefreshDGeneral", count);
                    self.NotiAsoc = true;
                })  
                .catch(function(){
                    self.NotiFail = true;
                });
            },
            customItemClick: async function () {
                var sign = confirm("¿Desea eliminar este nodo?");

                if (sign) {
                    console.log( this.dataDelete)
                    this.dataDelete.e.stopPropagation();
                    var index = this.dataDelete.node.parentItem.indexOf(this.dataDelete.item);
                    console.log(index)
                    await this.deleteNode(this.dataDelete.item.id, index);
                }
            },
            deleteNode: async function (id, index) {
                var token = JSON.parse(localStorage.user).token;
                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                let formData = new FormData();
                formData.append("fileId", id);
                formData.append("idProject", this.projectId)
                formData.append("emailClient", this.currentUser.clientInfo.emailContacto)
                

                const data = {};
                formData.forEach((value, key) => (data[key] = value));
                // Log the data.
                await axios
                    .post("https://microservices.safered.co/api/deleteComponent", formData, config, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    })
                    .then((res) => console.log(res))
                    .catch((e) => console.log(e));
                
                this.$store.commit("setIndexNodeDelete", index)
            },
        },
        watch:{
            tempV: function(){
                this.fileF = this.tempV.length > 0 ? true : false;
            },
            '$store.state.dataFindPoint': function(){
                this.areasC = this.$store.state.dataFindPoint
            },
            '$store.state.dataPoint': function(){
                this.pointsC = this.$store.state.dataPoint
            },
            'typeDocument': function(){
                console.log(this.typeDocument)
            }
        }
    }
</script>
<style>
    
</style>