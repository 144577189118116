<template>
    <div style="width: -webkit-fill-available;">
        <v-tabs background-color="white" v-model="tab">
            <v-tabs-slider color="grey"></v-tabs-slider>

            <v-tab @click="viewProjects();">
                <v-badge v-if="this.projects.length > 0" color="error" :content="this.projects.length">
                    Proyectos&nbsp;
                </v-badge>
                <v-badge v-if="this.projects.length == 0" color="error" content="0">
                    Proyectos&nbsp;
                </v-badge>
            </v-tab>

            <!-- <v-tab :disabled="selectProject">
                Canvas
            </v-tab> -->
            <v-btn @click="activeNovedades = true" large depressed :disabled="selectProject"
                style="background: white!important;">
                Novedades
            </v-btn>
            <v-btn @click="activeBuscar = true" large depressed :disabled="selectProject"
                style="background: white!important;">
                Buscar
            </v-btn>
            <v-btn v-if="permitEdit || selectProject" @click="activeOpciones = true" large depressed
                :disabled="selectProject" style="background: white!important;">
                Opciones
            </v-btn>
            <v-menu offset-y v-if="isAdmin">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn large depressed v-bind="attrs" v-on="on" style="background: white!important;">
                        Administración
                    </v-btn>
                </template>
                <v-list v-if="isAdmin && !isBoss">
                    <v-list-item>
                        <v-btn large depressed style="background: white!important;" @click="activeBusinessAdm()">
                            Empresas
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn large depressed style="background: white!important;" @click="activeProjectsAdm()">
                            Proyectos
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn large depressed style="background: white!important;" @click="activeUserAdm()">
                            Usuarios
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn large depressed style="background: white!important;" @click="activeGroupAdmin()">
                            Grupos
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn large depressed style="background: white!important;" @click="activeEmpresaAdm()">
                            Mi Empresa
                        </v-btn>
                    </v-list-item>
                </v-list>
                <v-list v-if="isBoss">
                    <v-list-item>
                        <v-btn large depressed style="background: white!important;" @click="activeClientesAdm()">
                            Clientes
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn large depressed style="background: white!important;"
                v-on:click="toWiki()">
                Ayuda
            </v-btn>
            <div class="row" style="align-items: center; justify-content: end; width: 85px;">
                <v-col :cols="isAdmin ? 2 : 3"></v-col>
                <v-col cols="3">
                    <infinite-slide-bar>
                        <p style="margin-bottom: 0px!important; color: black;">
                            {{ projectName }}&nbsp;
                        </p>
                    </infinite-slide-bar>
                </v-col>
                <!-- <v-col cols="2" v-if="!selectProject">
                    <v-btn @click="hideSub()" depressed style="background: white; width: 15px;
                        margin-right: 15px;">
                        <i :class="!statusIcon ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'"></i>
                    </v-btn>
                </v-col> -->
                <v-col :cols="isAdmin ? 5 : 4">
                    <v-badge
                        bordered
                        color="green"
                        overlap
                        dot
                    >
                        <v-chip >
                         Tiempo en linea {{hours}}:{{minutes}}:{{seconds}}

                        </v-chip>
                    </v-badge>
                </v-col>
            </div>
        </v-tabs>
        <v-card style="display: node!important;">
            <v-tabs-items v-model="tab" center-active style="margin-right: 5px; margin-left: 5px;">
                <v-tab-item>
                    <div :projects="projects"></div>
                </v-tab-item>
                <v-tab-item>
                    <canvasSubCanvas :finishLoad="finishLoad" :valueLoad="valueLoad" :projects="projects"
                        :permitEdit="permitEdit" :dataGeo="dataGeoResult" :orgdataGeo="dataGeo" :dataMpBits="dataMpBits"
                        :viewPrincipal="viewPrincipal" />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <OpcionesGenerales :listCodeP="listCodeP" :folderGoogle="folderGoogle" v-if="activeO"
            :activeOpciones="activeOpciones" :idProject="idProject" :dataGeo="dataGeo" :itemsGoogle="itemsGoogle" />
        <BodyN v-if="activeN" :activeNovedades="activeNovedades" />
    </div>
</template>
<script>

import BodyN from "../body_main/novedades/BodyN.vue"
import canvasSubCanvas from "./sub_menu/CanvasSubCanvas.vue"
import OpcionesGenerales from "../body_main/opciones/OpcionesGenerales.vue";

export default {
    name: "menuM",
    components: {
        canvasSubCanvas,
        OpcionesGenerales,
        BodyN,
    },
    data() {
        return {
            selectProject: true,
            selectCanvas: true,
            tab: null,
            projectName: "",
            activeOpciones: false,
            activeO: false,
            activeBuscar: false,
            activeNovedades: false,
            activeN: false,
            isAdmin: false,
            isBoss: false,
            permitEdit: false,
            viewPrincipal: false,
            dataGeoResult: [],
            statusIcon: false,
            timeActivity: '',
            hours: 0,
            minutes: 0,
            seconds: 0,
        }
    },
    props: ['projects', 'dataGeo', 'dataMpBits', 'rolesActualU', 'idProject', 'folderGoogle', 'itemsGoogle',
        'listCodeP', 'finishLoad', 'valueLoad', 'policyCanvas'],
    mounted: async function () {
        this.isAdmin = JSON.parse(localStorage.getItem("user")).isAdmin
        this.isBoss = JSON.parse(localStorage.getItem("user")).isBoss

        setInterval(() => this.setTime(), 1000);
    },
    methods: {
        toWiki(){
            window.open("https://wiki.safered.co/", '_blank')
        },
        setTime() {
            const ingreso = new Date(JSON.parse(localStorage.user).timeLogin);
            const date = new Date();

            var calculador = Math.floor((date.getTime() - ingreso.getTime()) / 1000);

            var horas = Math.floor(calculador / 3600) % 24;
            var minutos = Math.floor(calculador / 60) % 60;
            var segundos = calculador % 60;


                this.hours = this.formatNumber(horas);
                this.minutes = this.formatNumber(minutos);
                this.seconds = this.formatNumber(segundos);
        },
        formatNumber(number){
            if (number < 10) {
                return '0' + number;
            }
            return number;
        },
        longTime(horas){
            if(horas > 23){
                localStorage.removeItem("user");
                this.$router.replace("/");
                window.location.reload();
            }
        },
        verifyIdCanvas: async function () {
            var temp = []
            var found = []
            var foundR = []

            if (!this.permitEdit) {
                for (let i = 0; i < this.dataGeo.length; i++) {
                    found = this.policyCanvas.find(element => element.canva == this.dataGeo[i].IdCanvas)
                    foundR = this.policyCanvas.find(element => element.canva == 'x')
                    if (found != undefined) {
                        this.dataGeoResult.push({
                            Figuras: this.dataGeo[i].Figuras,
                            IdCanvas: this.dataGeo[i].IdCanvas,
                            Name: this.dataGeo[i].Name,
                            Puntos: this.dataGeo[i].Puntos,
                            permissionsC: found.permission
                        })

                    } else if (foundR != undefined) {
                        this.dataGeoResult = []
                        this.dataGeoResult = await this.dataGeo
                        break;
                    }
                }
            } else {
                this.dataGeoResult = await this.dataGeo
            }

            if (foundR != undefined) {
                this.dataGeoResult.map(function (element) {
                    element = {
                        ...element,
                        permissionsC: foundR.permission
                    }
                    temp.push(element)
                })
                this.viewPrincipal = true
                this.dataGeoResult = temp
            }

        },
        activeProjectsAdm() {
            this.$store.commit("setproyectAdmin", true)
            this.$store.commit("setEmpresaAdmin", false)
            this.$store.commit("setUserAdmin", false)
            this.$store.commit("setGroupAdmin", false)
            this.$store.commit("setBusinessAdmin", false)
        },
        activeBusinessAdm(){
            this.$store.commit("setBusinessAdmin", true)
            this.$store.commit("setEmpresaAdmin", false)
            this.$store.commit("setproyectAdmin", false)
            this.$store.commit("setUserAdmin", false)
            this.$store.commit("setGroupAdmin", false)
        },
        activeEmpresaAdm() {
            this.$store.commit("setEmpresaAdmin", true)
            this.$store.commit("setproyectAdmin", false)
            this.$store.commit("setUserAdmin", false)
            this.$store.commit("setGroupAdmin", false)
            this.$store.commit("setBusinessAdmin", false)
        },
        activeUserAdm() {
            this.$store.commit("setUserAdmin", true)
            this.$store.commit("setproyectAdmin", false)
            this.$store.commit("setEmpresaAdmin", false)
            this.$store.commit("setGroupAdmin", false)
            this.$store.commit("setBusinessAdmin", false)
        },
        activeGroupAdmin() {
            this.$store.commit("setGroupAdmin", true)
            this.$store.commit("setEmpresaAdmin", false)
            this.$store.commit("setproyectAdmin", false)
            this.$store.commit("setUserAdmin", false)
            this.$store.commit("setBusinessAdmin", false)
        },
        activeClientesAdm() {
            this.$store.commit("setClientesAdmin", true)
            this.$store.commit("setCreateClients", false)
        },
        viewProjects() {

            this.selectProject = true;

            var data = {
                projectsM: true,
                projectSelect: false,
            }
            this.$store.commit("setrestoreInit", data)
            this.$store.commit("setPosicionselectMbE", null)
            this.$store.commit("setPosicionselectCE", null)
            this.$store.commit("setViewDirectorioGlobal", false)
            this.$store.commit("setViewDirectorioVista", false)
            this.$store.commit("setClientesAdmin", false)
            this.$store.commit("setCreateClients", false)
            this.$store.commit("setBusinessAdmin", false)
        },
        hideSub() {
            if (this.tab == 0) {
                this.tab = 1
            } else {
                this.tab = 0
            }

            if (this.statusIcon) {
                this.statusIcon = false
            } else {
                this.statusIcon = true
            }
        }
    },
    watch: {
        hours(){
            this.longTime(this.hours);
        },
        'policyCanvas': async function () {
            await this.verifyIdCanvas()
        },
        '$store.state.dataInit': async function () {
            this.selectProject = this.$store.state.dataInit.selectProject
            this.activeO = true
            this.activeN = true
            this.tab = 1

            var groups = JSON.parse(window.localStorage.user).groups
            var idSelect = this.$store.state.dataInit.idProject
            var statusE = false
            var self = this

            if (groups != "NaN") {
                groups.map(function (arr) {
                    self.rolesActualU.map(function (arr2) {
                        if (arr2.project_id == idSelect) {
                            if (arr2.role_id == 2) {
                                statusE = false
                            } else if (arr2.role_id == 4) {
                                statusE = true
                            }
                        } else {
                            if (arr.idProject == idSelect) {
                                if (arr.idRole == 2) {
                                    statusE = false
                                } else if (arr.idRole == 4) {
                                    statusE = true
                                }
                            }
                        }
                    })
                })
            } else {
                self.rolesActualU.map(function (arr) {
                    if (arr.project_id == idSelect) {
                        if (arr.role_id == 2) {
                            statusE = false
                        } else if (arr.role_id == 4) {
                            statusE = true
                        }
                    }

                })
            }
            if (JSON.parse(window.localStorage.user).isAdmin == true || statusE) {
                this.permitEdit = true;
            } else {
                this.permitEdit = false;
            }
        },
        '$store.state.restoreInit': function () {
            this.projectName = ""
            this.activeO = false
            this.selectCanvas = true
            this.$store.commit("setDataMap", [])
            this.activeN = false
            this.$store.commit("setproyectAdmin", false)
            this.$store.commit("setEmpresaAdmin", false)
            this.$store.commit("setUserAdmin", false)
            this.$store.commit("setGroupAdmin", false)
        },
        '$store.state.selectionCanvas': function () {
            this.projectName = this.$store.state.dataInit.projectName
            this.selectCanvas = false
            this.$store.commit("setproyectAdmin", false)
            this.$store.commit("setEmpresaAdmin", false)
            this.$store.commit("setUserAdmin", false)
            this.$store.commit("setGroupAdmin", false)
        },
        '$store.state.selectionCanvasMBits': function () {
            this.projectName = this.$store.state.dataInit.projectName
            this.selectCanvas = false
            this.$store.commit("setproyectAdmin", false)
            this.$store.commit("setEmpresaAdmin", false)
            this.$store.commit("setUserAdmin", false)
            this.$store.commit("setGroupAdmin", false)
        },
        activeOpciones: function () {
            this.$store.commit("setModalOpciones", true);
        },
        '$store.state.modalOpciones': function () {
            if (this.$store.state.modalOpciones == false) {
                this.activeOpciones = this.$store.state.modalOpciones
            }
        },
        activeBuscar: function () {
            if (this.activeBuscar == true) {
                this.$store.commit("setModalBuscar", true);
            }
        },
        '$store.state.modalBuscar': function () {
            if (this.$store.state.modalBuscar == false) {
                this.activeBuscar = this.$store.state.modalBuscar
            }
        },
        activeNovedades: function () {
            if (this.activeNovedades == true) {
                this.$store.commit("setModalNovedades", true);
            }
        },
        '$store.state.modalNovedades': function () {
            if (this.$store.state.modalNovedades == false) {
                this.activeNovedades = this.$store.state.modalNovedades
            }
        },
        '$store.state.refreshDrawGeo': async function () {
            var self = this
            setTimeout(function () {
                self.verifyIdCanvas()
            }, 3000)
        },
        '$store.state.refreshDataGeo': async function () {
            var self = this
            setTimeout(function () {
                self.verifyIdCanvas()
            }, 3000)
        },
        '$store.state.refreshImage': async function () {
            var self = this
            setTimeout(function () {
                self.verifyIdCanvas()
            }, 2500)
        },
    }

}
</script>
<style>
    .v-tab{
        font-size: .775rem!important;
    }

    .v-btn.v-size--large {
        font-size: .775rem!important;
    }

</style>