<template>
  <b-card>
    <v-col id="app2">
      <BotonDirectory :posicionTitle="posicionTitle"/>
      <v-row   class="conteiner-circular" v-if="circular">
        <v-col  >
          <v-progress-circular
              style="top: 50%; left: 50%;
              transform: translate(-50%, -50%);"
              indeterminate
              color="#283271"  >
          </v-progress-circular>
        </v-col>
      </v-row>       
      <v-card v-if="!circular" class="container_directG">
          <v-jstree
            :data="data"
            multiple
            allow-batch
            whole-row
            draggable
            @item-click="itemClick"
            @item-drag-start="itemDragStart"
            @item-drag-end="itemDragEnd"
            @item-drop-before="itemDropBefore"
            @item-drop="itemDrop"
            ref="tree2"
          >
            <template slot-scope="_">
              <div
                style="display: inherit;  "
                @click.ctrl="customItemClickWithCtrl"
              >
                <i
                  :class="_.vm.themeIconClasses"
                  role="presentation"
                  v-if="!_.model.loading"
                ></i>
                  {{ _.model.text }}
              </div>
            </template>
          </v-jstree>
      </v-card>
      <modal-01 :typeDirct="`Make`" :dataDelete="dataDelete" :projectId="projectId"></modal-01>
      <modal-02 :dataDelete="dataDelete" :editingItem="editingItem" :projectId="projectId" :idCanvas="idCanvas"
      :typeDocument="typeDocument" :typeCanvas="typeCanvas" :data="data"></modal-02>
      <b-modal centered id="modal-03" :title="`Agregar directorio a la carpeta ${editingItem.text}`" hide-footer>
        <formCreateFolders
          :typeDirct="`Make`"
          :folderId="folderId"
          :projectId="projectId"
          :idFather="editingItem.id"
          v-if="Object.keys(editingItem).length > 0"
        ></formCreateFolders>
      </b-modal>
      <b-modal centered id="modal-05" :title="`Cargar archivo a la carpeta ${editingItem.text}`" hide-footer>
        <b-row>
          <formUploadFile
            :typeDirct="`Make`"
            :folderId="folderId"
            :projectId="projectId"
            :idFather="editingItem.id"
            :editingItem="editingItem"
            v-if="Object.keys(editingItem).length > 0"
          ></formUploadFile>
        </b-row>
      </b-modal>
    </v-col>
    <pocketDir :projectId="projectId"/>
  </b-card>
</template>

<script>
import Vue from 'vue'
import axios from "axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


import VJstree from "vue-jstree"
import modal02 from "../../../../modals/DownloadFileD.vue"
import modal01 from "../../../../modals/modal-01.vue"
import formUploadFile from "../../../../modals/FormUploadFile.vue"
import formCreateFolders from "../../../../modals/FormCreateFolders.vue"
import pocketDir from "./PocketDirect";
import BotonDirectory from './BotonDirectory.vue';

export default {
  name: "directorioGlobal",
  components: {
    VJstree,
    modal01,
    modal02,
    formUploadFile,
    formCreateFolders,
    pocketDir,
    BotonDirectory,
  },
  data() {
    return {
      self: this,
      nameDirect: "",
      iconSeleccionado: {
        className: "",
        cssValue: "F2BC",
      },
      searchText: "",
      editingItem: {},
      editingNode: null,
      data: [],
      asyncData: [],
      filesToAdd: 1,
      filesToAddIndex: 0,
      editingTree: false,
      currentIndexTree: 0,
      folderId: "",
      clientInfo: {},
      dataDelete: {},
      posicionTitle: 0,
      circular: true,
      initData: false
    };
  }, 
  async mounted() {
    this.clientInfo = JSON.parse(localStorage.user).clientInfo
    //await this.getTreePath();
    await this.getTreePathFromExtern();
    this.posicionTitle = this.$store.state.select_diretory
    this.circular = false;

    this.$nextTick(() => {
      const element =  this.$el.querySelector('div.tree-anchor');
      
      const event = new MouseEvent('click', {
        ctrlKey: true
      });
      element.dispatchEvent(event);
    });
    
  },
  props: ['nameProject', 'projectId', 'typeDocument', 'idCanvas', 'typeCanvas'],
  methods: {
    desactiveDirectorioGlobal(){
      this.$store.commit("setViewDirectorioGlobal", false);
    },
    updateTreePath: async function () {
      var dataTree = JSON.stringify(this.data, dataTree, undefined, 4);
      var obj = {
        id_project: this.projectId,
        tree: dataTree,
      };

      var flag;

      await axios
        .post("https://api.safered.co/api/savePathTree", obj)
        .then(function (data) {
          if (data.data.message == "Canvas actualizadao correctamente.") {
            flag = true;
          } else {
            flag = false;
          }
        })
        .catch(function (e) {
          console.log(e);
        });

      if (flag) {
        alert("Directorios actualizados correctamente");
      } else {
        alert("Error al actualizar.");
      }
    },
    getTreePath: async function () {
      var obj = {
        id_project: await this.projectId,
      };
      var dt;
      await axios
        .post("https://api.safered.co/api/getPathTree", obj)
        .then(function (data) {
          dt = data.data[0].tree;
        })
        .catch(function (e) {
          console.log(e);
        });
      this.data = await JSON.parse(dt);
    },
    getTreePathFromExtern: async function () {
      var obj = {
        idUser: JSON.parse(localStorage.user).id,
        idProject: this.projectId,
        emailClient: this.clientInfo.emailContacto
      };
      
      var token = JSON.parse(localStorage.user).token;
      
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      console.log(obj)
      var dt;
      var self = this 
      await axios
        .post("https://microservices.safered.co/api/getStorageProject", obj, config)
        .then(function (data) {
          self.folderId = data.data.GOOGLE_API_FOLDER_ID;
          dt = data.data.docProjects;
          console.log(data)
        })
        .catch(function (e) {
          console.log(e.message);
        });
        
        //this.data = await JSON.parse(JSON.stringify(dt));

        var array = [
          {
            "text": this.nameProject,
            "value": this.nameProject,
            "id": self.folderId,
            "icon": "",
            "opened": true,
            "parent": self.folderId,
            "children": JSON.parse(JSON.stringify(dt))
          }
        ]

        this.data = await array;
    },
    addDirWhitName: function () {
      if (this.nameDirect.length > 0) {
        if (this.editingItem !== undefined) {
          if (!this.editingTree) {
            var index = Math.max(...this.data.map((o) => o.id));
            this.currentIndexTree = index + 1;
            this.editingItem.children.push({
              text: this.nameDirect,
              children: [],
              opened: true,
              "show-checkbox": false,
              index: this.currentIndexTree,
            });
            this.editingTree = true;
          } else {
            this.currentIndexTree = this.currentIndexTree + 1;
            this.editingItem.children.push({
              text: this.nameDirect,
              children: [],
              opened: true,
              "show-checkbox": false,
              index: this.currentIndexTree,
            });
          }
        } else {
          console.log(this.editingItem);
        }
      } else {
        alert("Debe ingresar un nombre para el directorio.");
      }
    },
    iconSelect(selectedIcon) {
      this.iconSeleccionado = {
        className: "fa " + selectedIcon.className,
        cssValue: selectedIcon.cssValue,
      };
      this.editingItem.icon = this.iconSeleccionado.className;
    },
    itemClick(node, item, e) {
      this.editingNode = node;
      this.editingItem = node.model;
      if (node.model.icon == "") {
        this.initData ? this.$root.$emit("bv::show::modal", "modal-01", "#focusThisOnClose") : ''
        this.dataDelete = {
          node,
          item, 
          e
        }
      }else{
        this.$root.$emit("bv::show::modal", "modal-02-file", "#focusThisOnCLose");
        this.dataDelete = {
          node,
          item, 
          e
        }
        
      }
      this.initData = true
    },
    itemDragStart(node) {
      console.log(node.model.text + " drag start !");
    },
    itemDragEnd(node) {
      console.log(node.model.text + " drag end !");
    },
    itemDropBefore(node, item, draggedItem) {
      if (!draggedItem) {
        item.addChild({
          text: "newNode",
          value: "newNode",
        });
      }
    },
    itemDrop(node, item) {
      var sortBy = function (attr, rev) {
        if (rev == undefined) {
          rev = 1;
        } else {
          rev = rev ? 1 : -1;
        }
        return function (a, b) {
          a = a[attr];
          b = b[attr];
          if (a < b) {
            return rev * -1;
          }
          if (a > b) {
            return rev * 1;
          }
          return 0;
        };
      };
      item.children.sort(sortBy("text", true));
    },
    inputKeyUp: function () {
      var text = this.searchText;
      const patt = new RegExp(text);
      this.$refs.tree2.handleRecursionNodeChilds(
        this.$refs.tree2,
        function (node) {
          if (text !== "" && node.model !== undefined) {
            const str = node.model.text;
            if (patt.test(str)) {
              node.$el.querySelector(".tree-anchor").style.color = "red";
            } else {
              node.$el.querySelector(".tree-anchor").style.color = "#000";
            } // or other operations
          } else {
            node.$el.querySelector(".tree-anchor").style.color = "#000";
          }
        }
      );
    },
    addChildNode: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.addChild({
          text: "newNode",
        });
      }
    },
    removeNode: function () {
      if (this.editingItem.id !== undefined) {
        var index = this.editingNode.parentItem.indexOf(this.editingItem);
        this.editingNode.parentItem.splice(index, 1);
      }
    },
    addBeforeNode: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.addBefore(
          {
            text: this.editingItem.text + " before",
          },
          this.editingNode
        );
      }
    },
    addAfterNode: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.addAfter(
          {
            text: this.editingItem.text + " after",
          },
          this.editingNode
        );
      }
    },
    openChildren: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.openChildren();
      }
    },
    closeChildren: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.closeChildren();
      }
    },
    refreshNode: function () {
      this.asyncData = [this.$refs.tree2.initializeLoading()];
      this.$refs.tree2.handleAsyncLoad(this.asyncData, this.$refs.tree2);
    },
    customItemClick: async function (node, item, e) {
      var sign = confirm("¿Desea eliminar este nodo?");

      if (sign) {
        e.stopPropagation();
        var index = node.parentItem.indexOf(item);
        await this.deleteNode(item.id);
        node.parentItem.splice(index, 1);
      }
    },
    customItemClickWithCtrl: function () {
      console.log("click + ctrl");
    },
    fillData: function () {
      if (this.editingItem.id !== undefined) {
        for (var i = 0; i < this.filesToAdd; i++) {
          this.filesToAddIndex++;
          this.editingItem.addChild({
            text: "File" + this.filesToAddIndex,
            icon: "fa fa-file icon-state-danger",
          });
        }
      }
    },
    deleteNode: async function (id) {
      var token = JSON.parse(localStorage.user).token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let formData = new FormData();
      formData.append("fileId", id);
      formData.append("idProject", this.projectId)
      formData.append("emailClient", this.clientInfo.emailContacto)
      

      const data = {};
      formData.forEach((value, key) => (data[key] = value));
      // Log the data.
      await axios
        .post("https://microservices.safered.co/api/deleteComponent", formData, config, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));
    },
  },
  watch:{   
    data: function(){
      this.$store.commit("setDataGlobal", this.data);
    },
    '$store.state.indexNodeDelete': function(){
      if(this.$store.state.indexNodeDelete != null){
        var self = this;
        var node = this.dataDelete.node;
        var index = this.$store.state.indexNodeDelete;
        node.parentItem.splice(index, 1);

        setTimeout(function(){
          self.getTreePathFromExtern();
        }, 2000);
        this.$store.commit("setIndexNodeDelete", null)
      } 
    },
    '$store.state.refreshDGeneral': function(){

      this.getTreePathFromExtern();
    },
    '$store.state.addTempDirectFlex': function(){
      var node = this.dataDelete.node
      var index = this.$store.state.indexNodeDelete;
      node.parentItem.splice(index, 1);

      this.getTreePathFromExtern();
    },
  }
};
</script>

<style scoped>

  .container_directG{
    height: 408px; 
    margin-top: 15px; 
    overflow: auto;
  }

  .container_directG::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .container_directG::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 5px;
  }

#app2 h1,
#app2 h2 {
  font-weight: normal;
}

#app2 ul {
  list-style-type: none;
  padding: 0;
}

#app2 li {
  display: inline-block;
  margin: 0 10px;
}

#app2 a {
  color: #42b983;
}


#app2 table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #eee;
  font-size: 14px;
}

#app2 table th {
  background: #eee;
  border-bottom: 1px solid #ccc;
  padding: 4px;
}

#app2 table td {
  border: 1px solid #eee;
  padding: 4px;
}

#app2 .icon-state-default {
  color: gray;
}

#app2 .icon-state-danger {
  color: red;
}

#app2 .icon-state-warning {
  color: yellow;
}

#app2 .icon-state-success {
  color: green;
}

.conteiner-circular{
      height: 421px; 
      margin-top: 10px; 
    }
</style>
 