<template lang="">
    <div>
        <v-row justify="center">
            <v-dialog
                v-model="dialog"
                width="700"
            >
            <v-card v-if="currentUser != null" style="height: 400px; width: 700px; background-color:#E4E4E4;">
                <v-row style="margin-left: 10px; margin-right: 5px;">
                    <v-col cols="6">
                        <v-card style="margin-top: 15px;">
                            <h6 class="text_titleP"><b>{{project.name}}</b></h6>
                            <p style="margin-left: 5px; font-size: 13px; margin-bottom: 0px;"><b>Creado por: </b>{{project.creator}}</p>
                            <p style="margin-left: 5px; font-size: 13px; padding-bottom: 10px;"><b>Fecha de creación: </b>{{dateCreated[0]}}</p>
                        </v-card>
                        <v-card style="height: 150px; display: flex; flex-direction: column; justify-content: center;">
                            <p style="margin-left: 5px; font-size: 14px; padding-top: 10px; margin-bottom: 0px;"><b>Descripción:</b></p>
                            <p class="text_descriptionP"
                                >
                                {{project.description}}
                            </p>
                        </v-card>
                        <v-card style="margin-top: 15px;" v-if="currentUser.isAdmin">
                            <p style="margin-left: 5px; font-size: 14px; padding-top: 10px; margin-bottom: 0px;"><b>Nube asociada:</b> Google Drive</p>
                            <p v-if="dataBackup != undefined" style="margin-left: 5px; font-size: 14px; padding-top: 10px; padding-bottom: 10px;">
                                <b>Ubicacion Backup:</b> {{ dataBackup.IP }}
                            </p>
                        </v-card>
                    </v-col>
                    <v-col cols="6" style="display: flex;flex-direction: column; justify-content: center;">
                        <v-expansion-panels style="margin-top: 10px;" accordion>
                            <v-expansion-panel
                                v-if="currentUser.isAdmin"
                            >
                                <v-expansion-panel-header>
                                    Usuarios adscritos al proyecto 
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list-item  v-for="(user, i) in project.users"
                                        :key="i">
                                        <v-list-item-content>
                                          <v-list-item-title v-text="user.name"></v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                                v-if="currentUser.isAdmin"
                            >
                                <v-expansion-panel-header>
                                    Tipos de archivos
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list-item  v-for="(typeD, i) in typeDocument"
                                        :key="i">
                                        <v-list-item-content>
                                          <v-list-item-title v-text="typeD.text"></v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header>
                                    Canvas Georreferenciados
                                </v-expansion-panel-header>
                                <v-expansion-panel-content v-if="dataCanvas != null">
                                    <v-list-item  v-for="(canvaG, i) in dataCanvas.C_GEO"
                                        :key="i">
                                        <v-list-item-content>
                                          <v-list-item-title v-text="canvaG.name"></v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                            >
                                <v-expansion-panel-header>
                                    Canvas Mapa de Bits
                                </v-expansion-panel-header>
                                <v-expansion-panel-content v-if="dataCanvas != null">
                                    <v-list-item  v-for="(canvaMpB, i) in dataCanvas.C_MP_BITS"
                                        :key="i">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="canvaMpB.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                </v-col>
                </v-row>
            </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>

import axios from 'axios'
import api from '../../api/index.js'

export default {
    name: 'modalDetalleProject',
    data(){
        return{
            dialog: false,
            dateCreated: "",
            userList: [],
            idUser: null,
            typeDocument: [],
            dataBackup: [],
            listCodeP: [],
            dataCanvas: null,
            currentUser: null,
        }
    },
    props:['hover', 'project'],
    mounted(){
        this.idUser = JSON.parse(window.localStorage.user).id;
        this.currentUser = JSON.parse(localStorage.user)
        console.log(this.currentUser)
        this.getdataBackup();
    },
    methods:{
        getdataBackup: async function(){
            var dataBackup;
            var obj = {
                idClient: JSON.parse(localStorage.user).client_id,
            }
            await api
            .request("post", "/getHistorialBakcups", obj)
            .then((response) => {
                dataBackup = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
            console.log(dataBackup)
            this.dataBackup = dataBackup[0];
            console.log( this.dataBackup);
        },
        getTypeDocument: async function(){
            await api
            .request(
                "post",
                "/list-tags-project-nversion/" + this.project.id
            )
            .then((response) => {
                for(let i=0;i<response.data.length;i++){
                    this.typeDocument.push({
                        value: response.data[i].idTag,
                        text: response.data[i].nameTag
                    })
                }
            }).catch((error) =>{
                console.log(error)
                console.log("error importar data list tag")
            });
            console.log(this.typeDocument)
        },
        getDataCanvas: async function(){
            var dataC = null
            var obj = {
                idProject: this.project.id
            }
            await api
            .request(
                "post",
                "/getCanvasForIdProject", obj
            )
            .then((response) => {
                console.log(response)
                dataC = response.data
            }).catch((error) =>{
                console.log(error)
            });
            this.dataCanvas = dataC;
            console.log(this.dataCanvas)
        },
        getFolderCloud: async function(){
            let listCode = null
            var obj = {
                idProject: this.project.id
            };

            const config = {
                headers: { Authorization: `Bearer ${JSON.parse(localStorage.user).token}` },
            };

            await axios
                .post("https://microservices.safered.co/api/GetFolderCloudFromProject", obj, config)
                .then(function (data) {
                    listCode = data.data.codeP
                })
                .catch(function (e) {
                    console.log(e.message);
                });
            
            this.listCodeP = listCode
        },
    },
    watch:{
        hover: function(){
            this.dialog = this.hover
        },
        dialog: function(){
            if(this.dialog == false){
                this.$store.commit("setdataHoverP", false)
            }
        },
        project: async function(){
            console.log(this.project)
            var self = this

            if(this.project.created_at != undefined){
                let users = Object.values(this.project.users)
                this.userList = [];
                users.map(function(obj){
                    if(obj.id != self.idUser){
                        self.userList.push(obj)
                    }
                });
                this.dateCreated = this.project.created_at.split(" ")
                await this.getTypeDocument();
                await this.getFolderCloud();
                await this.getDataCanvas();
            }
            
        }
    }
}
</script>
<style>
    h6.text_titleP{
        margin-left: 5px; 
        padding-top: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p.text_descriptionP{
        margin-left: 5px; 
        padding-top: 5px; 
        font-size: 13px; 
        padding-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-expansion-panel-content__wrap{
        overflow-y: auto!important;
        max-height: 140px;
    }
</style>