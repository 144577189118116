<template>
        <v-row>
            <v-col cols="2" style="display: flex; justify-content: flex-start; align-items: center;">
                <v-img
                    v-if="existProfie"
                    :src="logoS"
                    max-height="70"
                    max-width="70"
                >
                </v-img>
            </v-col>
            <v-col cols="4" style="display: flex; justify-content: flex-end;">
                <v-img
                    v-if="waitFile"
                    :src="existProfie ? imageForm : logoS"
                    max-height="85"
                    max-width="85"
                >
                </v-img>
            </v-col>
            <v-col cols="6" style="display: flex; justify-content: flex-end; align-items: center;">
                <modalPerfil :user="currentUser" :initialName="initialName"/>
            </v-col>
        </v-row>
</template>
<script>
import axios from "axios";
import ModalPerfil from "../modals/ModalPerfil.vue"
import LogoS from "../../assets/img/logos/safered-logo-02.svg"

export default {
    name: 'NavbarP',
    components:{
        ModalPerfil,
    },
    data(){
        return{
            waitFile: false,
            logoS: LogoS,
            currentUser: {},
            initialName: '',
            imageForm: null,
            existProfie: false,
        };
    },
    mounted(){
        this.currentUser = JSON.parse(localStorage.user)
        this.cutName()
        this.getLogoClient()
    },
    methods: {
        cutName(){
            let name = this.currentUser.name
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
            let initials = [...name.matchAll(rgx)] || []

            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase()

            this.initialName = initials
        },
        getLogoClient: async function(){
            var imgProfile = null;
            var data = {
                "user_id": JSON.parse(window.localStorage.user).client_id,
                "id_tag": 17
            };
            await axios
                .post('https://api.safered.co/api/getLogoClient', data, {responseType: 'blob'})
                    .then((response) => {
                    imgProfile = response
                })
                .catch(function (e) {
                    console.log(e);
                });

                if(imgProfile.data.size > 55){
                    this.imageForm = URL.createObjectURL(imgProfile.data);
                    this.existProfie = true;
                }else{
                    this.existProfie = false;
                }
            this.waitFile = true
        }
    }
}
</script>
<style>
    #cardPerfil:hover {
        transition: all 0.2s ease-out;
        top: -3px;
        background-color: white;
    }

    #cardPerfil:before {
        transform: scale(2);
        transition: transform 0.15s ease-out;
    }
    
    #cardPerfil:hover:before {
        transform: scale(2.15);
    }
</style>