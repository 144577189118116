<template>
  <div>
    <v-card
      style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
      @click="CreateClients"
    >
      <v-img
        :src="icon_add"
        alt=""
        style="width: 110px"
      />

      <v-card-title>
        <h3 style="font-size: 12px">Crear usuario</h3>
      </v-card-title>
    </v-card>
    <!-- Modal -->
    <b-modal
      ref="my-modal-add-client"
      hide-footer
      title="Agregar Usuarios"
      centered
    >
      <form-wizard
        shape="circle"
        title="Registrar usuario"
        subtitle="Ingrese la información del usuario"
        color="#1565c0"
        nextButtonText="Siguiente"
        backButtonText="Volver"
        finishButtonText="Finalizar"
        @on-complete="saveUser"
      >
        <tab-content
          title="Información del usuario"
          style="width: 100%"
        >
          <div class="form-group">
            <label for="nameClientInput">Nombres</label>
            <input
              v-model="user.nombres"
              type="text"
              class="form-control"
              id="nameClientInput"
              placeholder="Escriba nombres completos"
            />
          </div>
          <div class="form-group">
            <label for="lastNameClientInput">Apellidos</label>
            <input
              v-model="user.apellidos"
              type="text"
              class="form-control"
              id="lastNameClientInput"
              placeholder="Escriba apellidos completos"
            />
          </div>
          <div style="width: 100%">
            <div style="width: 30%; display: inline-block">
              <div class="form-group">
                <label for="selectTypeDocId">Tipo de documento</label>
                <select
                  v-model="user.tipoDocumentoContacto"
                  class="form-control"
                  id="selectTypeDocId"
                  aria-label="Default select example"
                >
                  <option selected disabled>
                    Seleccione el tipo de documento
                  </option>
                  <option value="Cedula de ciudadania">
                    Cedula de ciudadania
                  </option>
                  <option value="Cedula de extranjeria">
                    Cedula de extranjeria
                  </option>
                  <option value="Pasaporte">Pasaporte</option>
                  <option value="DNI">DNI</option>
                </select>
              </div>
            </div>
            <div style="width: 69%; display: inline-block">
              <div class="form-group">
                <label for="nameProjectInput"
                  >Numero de documento de identificación</label
                >
                <input
                  v-model="user.numeroDocumento"
                  type="number"
                  class="form-control"
                  id="lastNameProjectInput"
                  placeholder="Numero de identificación"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="lastNameClientInput">Email</label>
              <input
                v-model="user.email"
                type="text"
                class="form-control"
                id="lastNameClientInput"
                placeholder="Escriba un email de contacto"
              />
            </div>
            <div class="form-group">
              <label for="lastNameClientInput">Número de telefono</label>
              <input
                v-model="user.numCelular"
                type="text"
                class="form-control"
                id="lastNameClientInput"
                placeholder="Escriba un número telefónico de contacto"
              />
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>
<script>

import icon_add from '../../../../../../../assets/img/icons/icon_add_user.svg'
import axios from "axios";

export default {
  name: "create-client-modal",
  components: {},
  data() {
    return {
      icon_add: icon_add,
      user: {
        nombres: '',
        apellidos: '',
        tipoDocumentoContacto: '',
        numeroDocumento: '',
        email: '',
        numCelular: ''
      }
    };
  },
  methods: {
    saveUser: async function () {
      if (
        this.user.nombres === "" ||
        this.user.apellidos === "" ||
        this.user.email === ""
      ) {
        alert("Los campos: nombres, apellidos y email son obligatorios.");
      } else {
        let formData = new FormData();
        formData.append("idUser", JSON.parse(localStorage.user).id)
        formData.append("name", this.user.nombres);
        formData.append("lastname", this.user.apellidos);
        formData.append("email", this.user.email);
        formData.append("client_id", JSON.parse(localStorage.user).client_id);
        formData.append("documentType", this.user.tipoDocumentoContacto);
        formData.append("documentNumber", this.user.numeroDocumento);
        formData.append("phoneNumber", this.user.numCelular);
        
        await axios
          .post("https://api.safered.co/api/addUser", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (data) {
            if(data.data.message == "Ya existe un usuario con ese email registrado"){
              alert("No se pudo crear el usuario: Email ya existente");
            }
            else if(data.data.message == "Ya existe un usuario con ese numero de documento registrado"){
              alert("No se pudo crear el usuario: número de documento ya existente")
            }
            else if(data.data.message == "Se ha registrado satisfatoriamente"){
              alert("Usuario creado exitosamente")
              formData = new FormData();
            }
          })
          .catch(function (e) {
            console.log(e);
          });
          this.$refs["my-modal-add-client"].hide();
      }
    },
    CreateClients: function () {
      this.$refs["my-modal-add-client"].show();
    }
  },
  mounted() {},
};
</script>
