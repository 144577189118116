<template>
    <div>
        <b-form @submit="onSubmit" v-if="show">
            <b-form-group id="input-group-3" label="idProject:" label-for="input-3" v-show="false">
                <b-form-input id="idProject" v-model="form.idProject" type="text" required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-3" label="Nombre del directorio:" label-for="nameFolder" style="margin-top: 15px;">
                <b-form-input id="nameFolder" v-model="form.nameFolder" type="text" required></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="success" style="color: white; margin-top: 25px;">Crear</b-button>
        </b-form>
    </div>
</template>

<script>
/*eslint-disable */
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import axios from "axios";

export default {
    data() {
        return {
            form: {
                folderId: "1--i6wP_UNEGVuOZFLVJfIWM_l2BJlnx0",
                idProject: "1",
                nameFolder: "",
                idFath: null,
            },
            show: true,
        };
    },
    props: ['typeDirct', 'idFather', 'folderId', 'projectId', 'editingItem'],
    created: async function(){
        this.form.idFath = await this.idFather
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.createdFolder()
        },
        onReset(event) {
            event.preventDefault();
            // Reset our form values
            this.form.email = "";
            this.form.name = "";
            this.form.food = null;
            this.form.checked = [];
            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        createdFolder: async function () {
            if(this.typeDirct == 'Make'){
                var self = this
                let formData = new FormData();
                formData.append("folderId", this.form.idFath)
                formData.append("idProject", this.projectId)
                formData.append("nameFolder", this.form.nameFolder)
                formData.append("emailClient", JSON.parse(localStorage.user).clientInfo.emailContacto)

                var token = JSON.parse(localStorage.user).token;
            
                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                console.log(this.form)
                console.log(this.projectId)
                await axios
                    .post("https://microservices.safered.co/api/storeDirectory", formData, config, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(function(){
                        var count = self.$store.state.refreshDGeneral + 1;
                        self.$store.commit("setRefreshDGeneral", count);
                        alert("Directorio cargado exitosamente.")
                    })
                    .catch(e =>
                        console.log(e)
                    );

                this.documentP = null;
                this.selectedTypeDocument = 0;
            }else{

                var data = {
                    nameFile: this.form.nameFolder,
                    folder: this.idFather, 
                    type: 'Folder'
                }
              this.$store.commit("setAddTempDirectFlex", data)
            }
            
        },
    },
};
/*eslint-enable */
</script>
<style>
.custom-file-label::after {
    content: "Cargar archivo" !important;
}
</style>