<template>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        :retain-focus="false"
        persistent
        >
            <v-card>
                <v-toolbar
                dark
                color="#283271"
                >
                <v-btn
                    icon
                    dark
                    @click="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Buscar</v-toolbar-title>
                <v-spacer></v-spacer>
                </v-toolbar>
                <v-card>
                    <v-tabs color="#5c708f" left>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-tab>
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"    
                                    >
                                        mdi-calendar-month
                                    </v-icon>
                                </v-tab>
                            </template>
                            <span>Filtro por fecha</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-tab
                                    v-bind="attrs"
                                    v-on="on" 
                                >
                                    <v-icon>
                                        mdi-tag-search
                                    </v-icon>
                                </v-tab>
                            </template>
                            <span>Filtro por tag</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-tab>
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-magnify
                                    </v-icon>
                                </v-tab>
                            </template>
                            <span>Filtro por estructura</span>
                        </v-tooltip>

                        <v-tab-item>
                            <filtroF :permitEdit="permitEdit" :dateFilt="dateFilt"/>
                        </v-tab-item>
                        <v-tab-item>
                            <filtroT :permitEdit="permitEdit" :dateFilt="dateFilt"  :dataPointsMpBits="dataPointsMpBits" :polygonsLoadedMpBits="polygonsLoadedMpBits" 
                            :tagsAndFiles="tagsAndFiles" :projectName="projectName" :dataMpBits="dataMpBits" :threeGoogle="threeGoogle" 
                            :treeDirectories="treeDirectories" :dataPoints="dataPoints" :polygonsLoaded="polygonsLoaded" 
                            :dataGeo="dataGeo" :linesLoaded="linesLoaded" :typeDocument="typeDocument" />
                        </v-tab-item>
                        <v-tab-item>
                            <filtroN :dataPointsMpBits="dataPointsMpBits" :polygonsLoadedMpBits="polygonsLoadedMpBits" 
                            :tagsAndFiles="tagsAndFiles" :projectName="projectName" :dataMpBits="dataMpBits" :threeGoogle="threeGoogle" 
                            :treeDirectories="treeDirectories" :dataPoints="dataPoints" :polygonsLoaded="polygonsLoaded" 
                            :dataGeo="dataGeo" :linesLoaded="linesLoaded" :permitEdit="permitEdit" :dateFilt="dateFilt" />
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>

import filtroF from './FiltroFecha.vue'
import filtroT from './FiltroTags.vue'
import filtroN from './FiltroNombre.vue'

export default {
    name: 'buscarF',
    components: {
        filtroF,
        filtroT,
        filtroN
    },
    data(){
        return{
            dialog: false,
            permitEdit: false,
        }
    },
    props: [ 'dataPointsMpBits', 'polygonsLoadedMpBits', 'dateFilt',
        'rolesActualU', 'tagsAndFiles', 'projectName', 'dataMpBits', 'threeGoogle', 'treeDirectories', 
        'dataPoints', 'polygonsLoaded', 'dataGeo', 'linesLoaded', 'typeDocument' ],
    async mounted(){
        await this.permissionEditor()
    },
    methods:{
        permissionEditor: async function(){
            var groups = await JSON.parse(window.localStorage.user).groups
            var idSelect = this.$store.state.dataInit.idProject
            var statusE = false
            var self = this

            if(groups != "NaN"){
                groups.map(function(arr){
                    self.rolesActualU.map(function(arr2){
                        if(arr2.project_id == idSelect){
                            if(arr2.role_id == 2){
                                statusE = false
                            }else if(arr2.role_id == 4){
                                statusE = true
                            }
                        }else{
                            if(arr.idProject == idSelect){
                                if(arr.idRole == 2){
                                    statusE = false
                                }else if(arr.idRole == 4 ){
                                    statusE = true
                                }
                            }
                        }
                    })
                })
            }else{
                self.rolesActualU.map(function(arr){
                    if(arr.project_id == idSelect){
                        if(arr.role_id == 2){
                            statusE = false
                        }else if(arr.role_id == 4){
                            statusE = true
                        }
                    }
                })
            }

            console.log(statusE)
            if(JSON.parse(window.localStorage.user).isAdmin == true || statusE){
                this.permitEdit = true;
            }else{
                this.permitEdit = false;
            }
        }
    },
    watch:{
        dialog: function(){
            if(this.dialog == false){
                this.$store.commit("setModalBuscar", false)
            }
        },
        '$store.state.modalBuscar': function(){
            if(this.$store.state.modalBuscar == true){
                this.dialog = this.$store.state.modalBuscar
            }
        }, 
    }
    
}
</script>
<style>
    
</style>