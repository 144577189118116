<template>
    <div>
        <v-card
        style="width: 170px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
        @click="dialog = true"    >
            <v-img
                :src="icon_add"
                alt=""
                style="width: 110px"
            />

            <v-card-title>
                <h3 style="font-size: 12px">Crear empresa</h3>
            </v-card-title>
        </v-card>
        <v-dialog v-model="dialog" width="650">
            <v-card>
                <v-card-title class="text-h5">
                    Crear empresa
                </v-card-title>

                <v-container>
                    <v-row>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                v-model="name_business"
                                :counter="55"
                                label="Nombre de la empresa"
                                required
                                :error-messages="error_nameBusiness"
                            ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                v-model="nit"
                                :counter="12"
                                label="Nit"
                                type="number"
                                required
                                :error-messages="error_nit"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-combobox
                                @change="getState()"
                                v-model="selectCountries"
                                :items="countries"
                                label="Pais de domicilio de la empresa"
                                item-text="country"
                                required
                            ></v-combobox>
                        </v-col>

                        <v-col
                        cols="12"
                        md="6"
                        >   
                            <v-combobox
                                @change="getCities()"
                                v-model="selectStates"
                                :items="states"
                                label="Estado de domicilio de la empresa"
                                item-text="name"
                                required
                            ></v-combobox>
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >   
                            <v-combobox
                                v-model="selectCities"
                                :items="cities"
                                label="Ciudad de domicilio de la empresa"
                                required
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                v-model="direccion"
                                label="Dirección del domicilio"
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model="legalRepresentative"
                                label="Nombre del representante legal"
                                :counter="55"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <div style="display: flex; justify-content: flex-end;">
                    <v-btn
                        @click="sendCreateBusiness()"
                        class="mb-3 mr-3"
                        type="submit"
                        :disabled="submit_btn"
                        color="success"
                    >
                        Enviar
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="notiCreate"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha creado la nueva empresa <strong>{{infoEmpresa}}</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="notiCreate = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar
            v-model="notiNoCreate"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
            No se pudo crear la empresa

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="notiNoCreate = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import api from "@/api";
import axios from "axios";
import icon_add from '../../../../../../../assets/img/icons/icon_create_business.svg'

export default {
    name: 'createBusiness',
    data(){
        return{
            notiCreate: false,
            notiNoCreate: false,
            submit_btn: true,
            icon_add: icon_add,
            dialog: false,
            name_business: '',
            nit: '',
            countries: null,
            states: null,
            cities: null,
            direccion: '',
            legalRepresentative: '',
            selectCountries: null,
            selectStates: null,
            selectCities: null,
            error_nameBusiness: '',
            error_nit: '',
            infoEmpresa: null,
        }
    },
    mounted(){
        this.getCountrie();
    },
    props: [ 'dataBusiness' ],
    methods:{
        async getCountrie(){
            await axios
            .get("https://countriesnow.space/api/v0.1/countries/")
            .then((result) => {
                this.countries = result.data.data;
            });
        },
        async getState(){
            if (this.selectCountries != null) {

                await axios
                .post("https://countriesnow.space/api/v0.1/countries/states", {
                    country: this.selectCountries.country,
                })
                .then((result) => {
                    this.states = result.data.data.states;
                });
            } 
        },
        async getCities(){
            if(this.selectStates != null){
                
                await axios
                .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
                    country:  this.selectCountries.country,
                    state: this.selectStates.name,
                })
                .then((result) => {
                    this.cities = result.data.data;
                });
            }
        },
        async sendCreateBusiness(){
            var self = this;
            var obj = {
                name: this.name_business,
                nit: this.nit,
                country: this.selectCountries.country,
                state: this.selectStates.name,
                city: this.selectCities,
                address: this.direccion,
                legal_representative: this.legalRepresentative,
                idUser: JSON.parse(localStorage.user).id
            }

            await api
            .request("post", "/storeCompany", obj)
            .then((data) => {
                if(data.data.msj == "Empresa creada exitosamente"){
                    self.notiCreate = true;
                    self.infoEmpresa = data.data.name;

                    self.name_business = '';
                    self.nit = '';
                    self.selectCountries = null;
                    self.selectStates = null;
                    self.selectCities = null;
                    self.direccion = '';
                    self.legalRepresentative = '';
                    self.dialog = false;
                    var count = 1 + self.$store.state.refreshBusiness;
                    self.$store.commit("setRefreshBusiness",  count);
                }else{
                    self.notiNoCreate = true;
                }
            })
            .catch(function () {
                self.notiNoCreate = true;
            });
        }
    },
    watch:{
        legalRepresentative(){
            this.submit_btn = (this.name_business != '' && this.nit != '' && this.direccion != '' 
            && this.legalRepresentative != '' && this.selectCountries != null && this.selectStates != null 
            && this.selectCities != null) ? this.error_nameBusiness == '' &&  this.error_nit == '' 
            ? false : true : true
        },
        name_business(){
            var data = this.dataBusiness.filter( obj => obj.name == this.name_business)
            if(data.length > 0){
                this.error_nameBusiness = "El nombre ya se encuentra en uso"
                this.submit_btn = true
            }else{
                this.error_nameBusiness = ''
                if(this.legalRepresentative.length > 0){
                   this.submit_btn = false
                }
            }
        },
        nit(){
            var data = this.dataBusiness.filter( obj => obj.nit == this.nit)
            if(data.length > 0){
                this.error_nit = "El nit ya se encuentra en uso"
                this.submit_btn = true
            }else{
                this.error_nit = ''
                if(this.legalRepresentative.length > 0){
                    this.submit_btn = false
                }
            }
        }
    }
}
</script>
<style>

</style>