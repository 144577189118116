<template>
  <div id="ChardBody">
    <div v-if="this.loadingMain">
      <b-container
        style="
          display: flex;
          background: transparent;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          margin-top: 70px;
          margin-bottom: 80px;
        "
      >
        <v-row>
          <v-col cols="3"></v-col>
          <v-col cols="6" style="display: flex; justify-content: center;">
            <v-card style="height: 410px; width: 310px;">
              <v-col style="display: flex; justify-content: center;">
                <img 
                height="280"
                width="300"
                :src="logoSafered" alt="Logo SAFE NET" />
              </v-col>
              <v-col>
                <clip-loader
                  :loading="loadingMain"
                  color="#1e88e5"
                  size="50px"
                ></clip-loader>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </b-container>
    </div>
    <div v-if="!this.loadingMain">
      <b-container fluid class="bv-example-row">
        <b-row v-if="finish == true">
          <b-col md="6" v-for="(dataC, index) in dataChart" :key="dataC.id">
            <b-card class="card_chart">
              <apexchart
                ref="deformaciones"
                type="line"
                height="240"
                :options="dataC.options"
                :series="dataC.series"
              />
              <b-col class="col_btn">
                <b-button
                  pill
                  variant="outline-secondary"
                  v-b-modal.modal-1
                  @click="selectP(index)"
                >
                  <i class="fa fa-external-link" aria-hidden="true" />
                </b-button>
                <b-button v-if="permitEdit == true"
                  pill
                  variant="outline-secondary"
                  v-b-modal.modal-2
                  @click="
                    selectConfig(index);
                    setDataConfig(index);
                  "
                >
                  <i class="fa fa-cog" aria-hidden="true" />
                </b-button>
                <b-button
                  v-if="series[0][0].data_Update.length !== 0"
                  pill
                  variant="outline-secondary"
                  v-b-modal.modal-5
                  @click="
                    selectInfo(index);
                    setDataInfo(index);
                  "
                >
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                </b-button>
                <b-button
                  v-if="exportExcel == true"
                  pill
                  variant="outline-secondary"
                  @click="downloadReadings(index)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </b-button>
                <b-button  v-if="permitEdit == true"
                  pill
                  variant="outline-secondary"
                  v-b-modal.modal-7
                  @click="checkSeries(arraySeriesName[index], index)"
                >
                  <i class="fa fa-bullseye" aria-hidden="true"></i>
                </b-button>
              </b-col>
            </b-card>

            <b-modal
              v-if="finish == true"
              size="xl"
              :id="'modal-1-' + index"
              :title="dataChart[index].options.title.text"
              hide-footer
            >
              <apexchart
                ref="deformaciones"
                type="line"
                height="420"
                :options="dataChart[index].options"
                :series="dataChart[index].series"
              />
            </b-modal>

            <b-modal
              hide-footer
              size="xl"
              :id="'modal-2-' + index"
              :title="dataChart[index].options.title.text"
            >
              <b-row>
                <b-col sm="6">
                  <b-input-group prepend="Titulo de la grafica" class="mb-2">
                    <b-form-input
                      v-model="tituloGrafica"
                      aria-label=""
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="3">
                  <b-input-group prepend="Invertir" class="mb-2">
                    <b-form-select
                      v-model="reversedChart"
                      :options="optionreversedChart"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-col>
                <b-col sm="3">
                  <b-input-group prepend="Tipo de valores X" class="mb-2">
                    <b-form-select
                      v-model="selectedTypeValorX"
                      :options="optionsTypeValorX"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-input-group prepend="Titulo eje Y" class="mb-2">
                    <b-form-input
                      v-model="tituloY"
                      aria-label=""
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="6">
                  <b-input-group prepend="Titulo eje X" class="mb-2">
                    <b-form-input
                      v-model="tituloX"
                      aria-label=""
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3">
                  <b-input-group prepend="# decimales eje Y" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="numeroDecimalY"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="3">
                  <b-input-group prepend="# de marcas eje Y" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="numeroMarcasY"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="3" v-if="dataPreCloud == true">
                  <b-input-group prepend="# decimales eje X" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="numeroDecimalX"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="3">
                  <b-input-group prepend="# de marcas eje X" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="numeroMarcasX"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3">
                  <b-input-group prepend="Máximo número eje Y" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="numeroMaximoY"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="3">
                  <b-input-group prepend="Mínimo número eje Y" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="numeroMinimoY"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="3" v-if="dataPreCloud == true">
                  <b-input-group prepend="Máximo número eje X" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="numeroMaximoX"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="3" v-if="dataPreCloud == true">
                  <b-input-group prepend="Mínimo número eje X" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="numeroMinimoX"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3">
                  <b-input-group prepend="Rango máximo eje Y" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="rangoMaximoY"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
                <b-col sm="3">
                  <b-input-group prepend="Rango minimo eje Y" class="mb-2">
                    <b-form-input
                      type="number"
                      v-model="rangoMinimoY"
                      aria-label=""
                    >
                    </b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3">
                  <b-button
                    variant="success"
                    class="mt-3"
                    block
                    @click="
                      indexChart = index;
                      saveDataOption(index);
                      $bvModal.hide('modal-2-' + index);
                    "
                  >
                    Guardar</b-button
                  >
                </b-col>
              </b-row>
            </b-modal>
            <b-modal
              hide-footer
              centered
              :id="'modal-5-' + index"
              :title="dataChart[index].options.title.text"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(dateI, index) in dateInfo"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >
                  <b>Serie:</b> {{ dateI.name }} - <b>Valor:</b>
                  {{ dateI.valor}}
                  <b-badge variant="primary" pill>{{
                    dateI.data_Update
                  }}</b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
            <b-modal
              hide-footer
              centered
              :id="'modal-7-' + index"
              :title="dataChart[index].options.title.text"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(dateI, index2) in seriesActuales"
                  :key="index2"
                  class="d-flex justify-content-between align-items-center"
                >
                  <b>Serie:</b> {{ dateI.name }}
                  <b-button
                    @click="disableSerie(dateI, index, index2)"
                    variant="outline-danger"
                    v-if="dateI.status == 'enable'"
                    >Marcar</b-button
                  >
                  <b-button
                    @click="enableSerie(dateI, index, index2)"
                    variant="outline-success"
                    v-if="dateI.status == 'disable'"
                    >Desmarcar</b-button
                  >
                  - <b>Estatus:</b> {{ dateI.status }}
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
/*eslint-disable */

import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import logoSafered from "@/assets/img/logos/safered-logo-01.png";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BodyC",
  components: {
    ClipLoader,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      seriesActualesStatus: ["1"],
      permitEdit: false,
      seriesActuales: [],
      selected: [],
      options: [
        { text: "Marcar serie", value: "check", id: "0" },
        { text: "Desmarcar serie", value: "uncheck", id: "0" },
      ],
      logoSafered,
      finish: false,
      arraySeriesModal: [],
      arraySeriesName: [],
      dateAct: [],
      dateInfo: [],
      dataChart: [],
      tituloGrafica: "",
      tituloY: "",
      tituloX: "",
      reversedChart: false,
      optionreversedChart: [
        { value: true, text: "Si" },
        { value: false, text: "No" },
      ],
      numeroDecimalY: 0,
      numeroMarcasY: 0,
      numeroDecimalX: 0,
      numeroMarcasX: 0,
      numeroMaximoY: 0,
      numeroMinimoY: 0,
      numeroMaximoX: 0,
      numeroMinimoX: 0,
      rangoMaximoY: 0,
      rangoMinimoY: 0,
      selectedTypeValorX: "fechas",
      optionsTypeValorX: [
        { value: "fechas", text: "Fechas" },
        { value: "numerico", text: "Numérico" },
      ],
      indexChart: 0,
      dataPreCloud: false,
      dataConfig: [],
      arrayCharts: [],
      animationChartEnd: false,
      loadingMain: true,
    };
  },
  props: [
    "series",
    "nameProject",
    "chartOptions",
    "namePoint",
    "activeDataChart",
    "exportExcel",
  ],
  async mounted() {
    await this.loadConfigforChart();
    await this.addData();
    await this.switchSeries();
    this.permitSection();
    await this.getStatusSeries(
      this.$route.params.idChart,
      this.$route.params.projectId
    );

    this.seriesActualesStatus.forEach(element => {
        this.series.forEach((grafica, keygrafica) => {
            grafica.forEach((serie,keyserie) => {
                if(serie.name == element.serie && keygrafica == element.indexChart && element.status == "disable"){
                    this.series[keygrafica][keyserie].name = "*"+this.series[keygrafica][keyserie].name
                    //console.log( this.series[keygrafica][keyserie])
                    //console.log(serie.name)
                }
                //console.log(serie.name)
            });
            
        });
    });



    this.finish = true;
    this.loadingMain = false;
  },
  async updated() {},
  methods: {
    permitSection: async function(){
      if(JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true){
        this.permitEdit = true;
      }else{
        this.permitEdit = false;
      }
    },
    disableSerie: async function (serie, index, index2) {
      var data = {
        serie: serie.name,
        status: "disable",
        point: this.$route.params.idChart,
        idProject: parseInt(this.$route.params.projectId),
        idPoint: parseInt(this.$route.params.idChart),
        indexChart: index,
        namePoint: this.$route.params.namePoint.split('.')[0],
      };

      this.dataChart[index].series.forEach((element,indexLocal) => {
          if(element.name == serie.name){
              this.dataChart[index].series[indexLocal].name = '*'+this.dataChart[index].series[indexLocal].name
            }
      });


      await axios
        .post("https://api.safered.co/api/setstatusSerie", data)
        .then((result) => {
          this.seriesActuales[index2].status = "disable"
        })
        .catch((err) => {
          console.log(err);
        });
      //window.location.reload();
    },
    enableSerie: async function (serie, index, index2) {
      var data = {
        serie: serie.name,
        status: "enable",
        point: this.$route.params.idChart,
        idProject: parseInt(this.$route.params.projectId),
        idPoint: parseInt(this.$route.params.idChart),
        indexChart: index,
        namePoint: this.$route.params.namePoint.split('.')[0],
      };

      this.dataChart[index].series.forEach((element,indexLocal) => {
          if(element.name == serie.name){
              this.dataChart[index].series[indexLocal].name = this.dataChart[index].series[indexLocal].name.slice(1)
            }
      });

      await axios
        .post("https://api.safered.co/api/setstatusSerie", data)
        .then((result) => {
          this.seriesActuales[index2].status = "enable"
        })
        .catch((err) => {
          console.log(err);
        });
      //window.location.reload();
    },
    switchSeries: async function () {
      var arraySeries = [];
      if (this.dataChart.length > 0) {
        this.dataChart.forEach((chart, i) => {
          if (chart.series != null && chart.series.length > 0) {
            chart.series.forEach((serie) => {
              arraySeries.push({
                indice: i,
                name: serie.name,
              });
            });
          }
        });

        let groupBy = (array, key) => {
          return array.reduce((result, obj) => {
            (result[obj[key]] = result[obj[key]] || []).push(obj.name);
            return result;
          }, {});
        };
        let a = groupBy(arraySeries, "indice");
        this.arraySeriesName = a;
      }
    },
    setDataInfo(index) {
      this.dateInfo = [];
      for (let i = 0; i < this.dateAct.length; i++) {
        if (index == i) {
          for (let j = 0; j < this.dateAct[i].length; j++) {

            var datenew = new Date(this.dateAct[i][j].data_Update);
            var temp =
              datenew.getFullYear() +
              "-" +
              (datenew.getMonth() + 1) +
              "-" +
              datenew.getDate() +
              " " +
              datenew.getHours() +
              ":" +
              datenew.getMinutes();
            this.dateInfo.push({
              name: this.dateAct[i][j].name,
              data_Update: temp,
              valor: this.dateAct[i][j].valor,
            });
          }
        }
      }
    },
    setDataConfig: async function(index) {

      var posicion = await index;

      this.tituloGrafica = "";
      this.tituloY = "";
      this.tituloX = "";
      this.numeroDecimalY = 0;
      this.numeroMarcasY = 0;
      this.numeroMarcasX = 0;
      this.numeroMaximoY = 0;
      this.numeroMinimoY = 0;
      this.numeroMaximoX = 0;
      this.numeroMinimoX = 0;
      this.rangoMaximoY = 0;
      this.rangoMinimoY = 0;
      this.selectedTypeValorX = "";
      this.reversedChart = false;

      for (let i = 0; i < this.dataConfig.length; i++) {
        
        if(await this.dataConfig[i].indexChart == await posicion){

          this.tituloGrafica = this.dataConfig[i].config.tituloGrafica;
          this.tituloY = this.dataConfig[i].config.tituloY;
          this.tituloX = this.dataConfig[i].config.tituloX;
          this.numeroDecimalY = this.dataConfig[i].config.numeroDecimalY;
          this.numeroMarcasY = this.dataConfig[i].config.numeroMarcasY;
          this.numeroMarcasX = this.dataConfig[i].config.numeroMarcasX;
          this.numeroMaximoY = this.dataConfig[i].config.numeroMaximoY;
          this.numeroMinimoY = this.dataConfig[i].config.numeroMinimoY;
          this.numeroMaximoX = this.dataConfig[i].config.numeroMaximoX;
          this.numeroMinimoX = this.dataConfig[i].config.numeroMinimoX;
          this.rangoMaximoY = this.dataConfig[i].config.rangoMaximoY
          this.rangoMinimoY = this.dataConfig[i].config.rangoMinimoY
          this.selectedTypeValorX = this.dataConfig[i].config.selectedTypeValorX;
          this.reversedChart = this.dataConfig[i].config.reversedChart;
          
        }
      }

    },
    getCharts: async function () {
      var arrC = [];
      var cont = 0;
      var n = this.namePoint;
      await this.$refs.deformaciones.forEach((element) => {
        var nanm = n[cont];
        element.chart.dataURI().then((uri) => {
          arrC.push({
            name: nanm,
            chart: uri.imgURI,
          });
        });
        cont++;
      });
      this.arrayCharts = arrC;
    },
    checkSeries: async function (arraySeries, index) {
      this.arraySeriesModal = arraySeries;
      this.seriesActuales = [];
      if (arraySeries.length > 0) {
        arraySeries.forEach((element) => {
          this.seriesActuales.push({
            name: element,
            status: "enable",
          });
        });

        if (this.seriesActualesStatus.length > 0) {
          this.seriesActualesStatus.forEach((elementStatus) => {
            const found = this.seriesActuales.findIndex(
              (element) =>
                element.name == elementStatus.serie &&
                index == elementStatus.indexChart
            );
            if (found >= 0) {
              this.seriesActuales[found].status = elementStatus.status;
            }
          });
        }
        this.$root.$emit(
          "bv::show::modal",
          "modal-7-" + index,
          "#focusThisOnClose"
        );
      }
    },
    getStatusSeries: async function (point, idProject) {
      var arrl = [];
      await axios
        .get(
          "https://api.safered.co/api/getstatusSerie/" +
            point +
            "/" +
            idProject
        )
        .then((result) => {
          arrl = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.seriesActualesStatus = arrl;
    },
    addData: async function () {
      var tempUpdate = [];
      for (let i = 0; i < this.series.length; i++) {
        this.dataChart.push({
          series: this.series[i],
          options: this.chartOptions[0],
        });

        for (let j = 0; j < this.series[i].length; j++) {
          if (this.series[i][j].data_Update !== 0) {
            var tempU = {
              name: this.series[i][j].name,
              data_Update: this.series[i][j].data_Update.x,
              valor: this.series[i][j].data_Update.y,
            };
            tempUpdate.push(tempU);
          }
        }
        this.dateAct.push(tempUpdate);
        tempUpdate = [];
      }

      for (let i = 0; i < this.dataChart.length; i++) {
        this.dataChart[i].options = {
          ...this.dataChart[i].options,
          ...{
            title: {
              text: this.namePoint[i],
            },
            chart: {
              toolbar: {
                export: {
                  csv: {
                    filename: this.namePoint[i] + "-readings",
                  },
                  svg: {
                    filename: this.namePoint[i],
                  },
                  png: {
                    filename: this.namePoint[i],
                  },
                },
              },
            },
          },
        };

        if (this.dataPreCloud == true) {
          for (let j = 0; j < this.dataConfig.length; j++) {
            if (this.dataConfig[j].indexChart == i) {
              var dataC = this.dataConfig[j].config;

              let numeroMaxY = parseInt(dataC.numeroMaximoY);
              let numeroMinY = parseInt(dataC.numeroMinimoY);
              let numeroMaxX = parseInt(dataC.numeroMaximoX);
              let numeroMinX = parseInt(dataC.numeroMinimoX);
              let numeroMarcasY = parseInt(dataC.numeroMarcasY);
              let numeroMarcasX = parseInt(dataC.numeroMarcasX);
              let numeDecY = parseInt(dataC.numeroDecimalY);
              var tituloGrafica = dataC.tituloGrafica;
              var TyperValorX = dataC.selectedTypeValorX;
              var tituloY = dataC.tituloY;
              var tituloX = dataC.tituloX;
              var reversedC = dataC.reversedChart;

              if (TyperValorX == "numerico") {
                this.dataChart[i].options = {
                  ...this.dataChart[i].options,
                  ...{
                    title: {
                      text: tituloGrafica,
                    },
                    chart: {
                      toolbar: {
                        export: {
                          csv: {
                            filename: tituloGrafica + "-readings",
                          },
                          svg: {
                            filename: tituloGrafica,
                          },
                          png: {
                            filename: tituloGrafica,
                          },
                        },
                      },
                    },
                    yaxis: {
                      reversed: reversedC,
                      min: numeroMinY,
                      max: numeroMaxY,
                      labels: {
                        formatter: function (val, index) {
                          return val.toFixed(numeDecY);
                        },
                      },
                      tickAmount: numeroMarcasY,
                      title: {
                        text: tituloY,
                      },
                    },
                    xaxis: {
                      min: numeroMinX,
                      max: numeroMaxX,
                      type: "numeric",
                      tickAmount: numeroMarcasX,
                      title: {
                        text: tituloX,
                      },
                    },
                    tooltip: {
                      shared: false,
                    },
                  },
                };
              } else {
                this.dataChart[i].options = {
                  ...this.dataChart[i].options,
                  ...{
                    title: {
                      text: tituloGrafica,
                    },
                    chart: {
                      toolbar: {
                        export: {
                          csv: {
                            filename: tituloGrafica + "-readings",
                          },
                          svg: {
                            filename: tituloGrafica,
                          },
                          png: {
                            filename: tituloGrafica,
                          },
                        },
                      },
                    },
                    yaxis: {
                      reversed: reversedC,
                      min: numeroMinY,
                      max: numeroMaxY,
                      labels: {
                        formatter: function (val, index) {
                          return val.toFixed(numeDecY);
                        },
                      },
                      tickAmount: numeroMarcasY,
                      title: {
                        text: tituloY,
                      },
                    },
                    xaxis: {
                      type: "datetime",
                      labels: {
                        datetimeFormatter: {
                          year: "yyyy",
                          month: "yyyy-MM",
                          day: "yyyy-MM-dd",
                          hour: "HH:mm:ss dd-MM-yyyy",
                        },
                      },
                      convertedCatToNumeric: false,
                      tickAmount: numeroMarcasX,
                      title: {
                        text: tituloX,
                      },
                    },
                    tooltip: {
                      shared: false,
                      x: {
                        show: true,
                        format: "yyyy-MM-dd  hh:mm:ss",
                      },
                    },
                  },
                };
              }
            }
          }
        }
      }
    },
    updateData(index) {
      let numeroMaxY = parseInt(this.numeroMaximoY);
      let numeroMinY = parseInt(this.numeroMinimoY);
      let numeroMaxX = parseInt(this.numeroMaximoX);
      let numeroMinX = parseInt(this.numeroMinimoX);
      let numeroMarcasY = parseInt(this.numeroMarcasY);
      let numeroMarcasX = parseInt(this.numeroMarcasX);
      let numeDecY = parseInt(this.numeroDecimalY);
      let reversedC = this.reversedChart;
      let TyperValorX = this.selectedTypeValorX;

      for (let i = 0; i < this.dataChart.length; i++) {
        if (i === index) {
          if (TyperValorX == "numerico") {
            this.dataChart[i].options = {
              ...this.dataChart[i].options,
              ...{
                title: {
                  text: this.tituloGrafica,
                },
                chart: {
                  toolbar: {
                    export: {
                      csv: {
                        filename: this.tituloGrafica + "-readings",
                      },
                      svg: {
                        filename: this.tituloGrafica,
                      },
                      png: {
                        filename: this.tituloGrafica,
                      },
                    },
                  },
                },
                yaxis: {
                  reversed: reversedC,
                  min: numeroMinY,
                  max: numeroMaxY,
                  labels: {
                    formatter: function (val, index) {
                      return val.toFixed(numeDecY);
                    },
                  },
                  tickAmount: numeroMarcasY,
                  title: {
                    text: this.tituloY,
                  },
                },
                xaxis: {
                  type: "numeric",
                  min: numeroMinX,
                  max: numeroMaxX,
                  tickAmount: numeroMarcasX,
                  title: {
                    text: this.tituloX,
                  },
                },
                tooltip: {
                  shared: false,
                },
              },
            };
          } else {
            this.dataChart[i].options = {
              ...this.dataChart[i].options,
              ...{
                title: {
                  text: this.tituloGrafica,
                },
                chart: {
                  toolbar: {
                    export: {
                      csv: {
                        filename: this.tituloGrafica + "-readings",
                      },
                      svg: {
                        filename: this.tituloGrafica,
                      },
                      png: {
                        filename: this.tituloGrafica,
                      },
                    },
                  },
                },
                yaxis: {
                  reversed: reversedC,
                  min: numeroMinY,
                  max: numeroMaxY,
                  labels: {
                    formatter: function (val, index) {
                      return val.toFixed(numeDecY);
                    },
                  },
                  tickAmount: numeroMarcasY,
                  title: {
                    text: this.tituloY,
                  },
                },
                xaxis: {
                  type: "datetime",
                  labels: {
                    datetimeFormatter: {
                      year: "yyyy",
                      month: "yyyy-MM",
                      day: "yyyy-MM-dd",
                      hour: "HH:mm:ss dd-MM-yyyy",
                    },
                  },
                  convertedCatToNumeric: false,
                  tickAmount: numeroMarcasX,
                  title: {
                    text: this.tituloX,
                  },
                },
                tooltip: {
                  shared: false,
                  x: {
                    show: true,
                    format: "yyyy-MM-dd  hh:mm:ss",
                  },
                },
              },
            };
          }
        }
      }

      this.tituloGrafica = "";
      this.tituloY = "";
      this.tituloX = "";
      this.numeroDecimalY = 0;
      this.numeroMarcasY = 0;
      this.numeroMarcasX = 0;
      this.numeroMaximoY = 0;
      this.numeroMinimoY = 0;
      this.numeroMaximoX = 0;
      this.numeroMinimoX = 0;
      this.rangoMaximoY = 0;
      this.rangoMinimoY = 0;
      this.selectedTypeValorX = "";
      this.reversedChart = false;
    },
    selectP(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-1-" + index,
        "#focusThisOnClose"
      );
    },
    selectConfig(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-2-" + index,
        "#focusThisOnClose"
      );
    },
    selectInfo(index) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-5-" + index,
        "#focusThisOnClose"
      );
    },
    saveDataOption(index) {
      var optionsAdd = {
        config: {
          tituloGrafica: this.tituloGrafica,
          tituloY: this.tituloY,
          tituloX: this.tituloX,
          numeroDecimalY: this.numeroDecimalY,
          numeroMarcasY: this.numeroMarcasY,
          numeroMarcasX: this.numeroMarcasX,
          numeroMaximoY: this.numeroMaximoY,
          numeroMinimoY: this.numeroMinimoY,
          numeroMaximoX: this.numeroMaximoX,
          numeroMinimoX: this.numeroMinimoX,
          rangoMaximoY: this.rangoMaximoY,
          rangoMinimoY: this.rangoMinimoY,
          selectedTypeValorX: this.selectedTypeValorX,
          reversedChart: this.reversedChart,
        },
        indexChart: index,
        idProject: this.$route.params.projectId,
        idPoint: this.$route.params.idChart,
      };
      axios
        .post("https://api.safered.co/api/setConfigChart", optionsAdd)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      this.updateData(index);
    },
    loadConfigforChart: async function () {
      var temp = {
        idPoint: this.$route.params.idChart,
      };
      var dataC = [];
      await axios
        .post("https://api.safered.co/api/getConfigChart", temp)
        .then((response) => {
          dataC = response.data;
          for (let i = 0; i < dataC.length; i++) {
            this.dataConfig.push({
              config: dataC[i].config,
              indexChart: dataC[i].indexChart,
            });
          }
          this.dataPreCloud = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    downloadReadings: async function (chart) {
      if (chart === 0) {
        var dataOut = "profundidad,fecha,valor\n";
        this.series[chart].forEach((element) => {
          for (let index = 0; index < element.data.length; index++) {
            const element2 = element.data[index];
            dataOut =
              dataOut +
              element2["y"] +
              "," +
              element.name +
              "," +
              element2["x"] +
              "\n";
          }
        });

        var link = document.createElement("a");
        link.href = "data:text/plain;charset=UTF-8," + escape(dataOut);
        link.download = "readings-safered.csv";
        link.click();
      } else if (chart === 3) {
        var dataOut = "profundidad,fecha,valor\n";
        this.series[chart].forEach((element) => {
          for (let index = 0; index < element.data.length; index++) {
            const element2 = element.data[index];
            dataOut =
              dataOut +
              element2["y"] +
              "," +
              element.name +
              "," +
              element2["x"] +
              "\n";
          }
        });
        var link = document.createElement("a");
        link.href = "data:text/plain;charset=UTF-8," + escape(dataOut);
        link.download = "readings-safered.csv";
        link.click();
      } else if (chart === 6) {
        var dataOut = "profundidad,estadistica,valor\n";
        this.series[chart].forEach((element) => {
          for (let index = 0; index < element.data.length; index++) {
            const element2 = element.data[index];
            dataOut =
              dataOut +
              element2["y"] +
              "," +
              element.name +
              "," +
              element2["x"] +
              "\n";
          }
        });

        var link = document.createElement("a");
        link.href = "data:text/plain;charset=UTF-8," + escape(dataOut);
        link.download = "readings-safered-stadistcs.csv";
        link.click();
      } else if (chart === 7) {
        var dataOut = "profundidad,estadistica,valor\n";
        this.series[chart].forEach((element) => {
          for (let index = 0; index < element.data.length; index++) {
            const element2 = element.data[index];
            dataOut =
              dataOut +
              element2["y"] +
              "," +
              element.name +
              "," +
              element2["x"] +
              "\n";
          }
        });
        var link = document.createElement("a");
        link.href = "data:text/plain;charset=UTF-8," + escape(dataOut);
        link.download = "readings-safered.csv";
        link.click();
      } else if (chart === 1) {
        var dataOut = "valor,profundidad,fecha de control\n";
        this.series[chart].forEach((element) => {
          for (let index = 0; index < element.data.length; index++) {
            const element2 = element.data[index];
            dataOut =
              dataOut +
              element2["y"] +
              "," +
              element.name +
              "," +
              element2["x"] +
              "\n";
          }
        });

        var link = document.createElement("a");
        link.href = "data:text/plain;charset=UTF-8," + escape(dataOut);
        link.download = "readings-safered-stadistcs.csv";
        link.click();
      } else if (chart === 4) {
        var dataOut = "valor,profundidad,fecha de control\n";
        this.series[chart].forEach((element) => {
          for (let index = 0; index < element.data.length; index++) {
            const element2 = element.data[index];
            dataOut =
              dataOut +
              element2["y"] +
              "," +
              element.name +
              "," +
              element2["x"] +
              "\n";
          }
        });
        var link = document.createElement("a");
        link.href = "data:text/plain;charset=UTF-8," + escape(dataOut);
        link.download = "readings-safered.csv";
        link.click();
      } else if (chart === 2) {
        var dataOut = "valor,profundidad,fecha de control\n";
        this.series[chart].forEach((element) => {
          for (let index = 0; index < element.data.length; index++) {
            const element2 = element.data[index];
            dataOut =
              dataOut +
              element2["y"] +
              "," +
              element.name +
              "," +
              element2["x"] +
              "\n";
          }
        });

        var link = document.createElement("a");
        link.href = "data:text/plain;charset=UTF-8," + escape(dataOut);
        link.download = "readings-safered-stadistcs.csv";
        link.click();
      } else if (chart === 5) {
        var dataOut = "valor,profundidad,fecha de control\n";
        this.series[chart].forEach((element) => {
          for (let index = 0; index < element.data.length; index++) {
            const element2 = element.data[index];
            dataOut =
              dataOut +
              element2["y"] +
              "," +
              element.name +
              "," +
              element2["x"] +
              "\n";
          }
        });
        var link = document.createElement("a");
        link.href = "data:text/plain;charset=UTF-8," + escape(dataOut);
        link.download = "readings-safered.csv";
        link.click();
      }
    },
  },
  watch: {
    arrayCharts: function () {
      this.$store.commit("setDataChartPrint", this.arrayCharts);
    },
    activeDataChart: function () {
      this.getCharts();
    },
  },
};
/*eslint-enable */
</script>
<style >
#ChardBody .card_chart {
  margin: 20px 0px 25px 0px;
  padding: 18px 0px 0px 0px;
  border-radius: 0.75rem !important;
  border: initial !important;
  background: #fff !important;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%) !important;
}

#ChardBody .col_btn{
  padding: 0px 0px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>