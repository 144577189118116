<template>
    <!-- Delete Mapa Bits -->
    <div>
        <b-modal centered id="DeleteMpBits" :title="`Eliminar vista:   ${titleFolder}`" hide-footer ref="my-modal" >
            <b-container class="bv-example-row" >
                <v-row>
                    <v-col cols="4">
                        <b-form-checkbox v-model="statusElimincarCI" value="eliminar" unchecked-value="">
                        </b-form-checkbox>
                    </v-col>
                        <v-col cols="8">
                            <p>Confirma que deseas eliminar la vista.</p>
                        </v-col>
                </v-row>
                <v-row >  
                    <b-button  v-if="statusElimincarCI.length > 0" variant="danger"  @click="deleteCanvas(idCanvaSelect); showMDeleteC = false;
                            "> Eliminar
                    </b-button>
                    <b-button   v-if="statusElimincarCI.length <= 0" disabled variant="danger">
                         Eliminar
                    </b-button>    
                </v-row>
            </b-container>        
        </b-modal>
    </div>
</template>
<script>

import axios from 'axios'

export default {
    name: 'modalDeleteCanva',
    data(){
        return{
            selectDeleteI: 0,
            statusElimincarCI: '',
            showMDeleteC: false,
            selectDeleteG: 0,
            statusElimincarCG: '',
            nameCanvas: '',
        }
    },
    props: [ 'idCanvaSelect', 'titleFolder', 'ultimType' ],
    methods:{
        deleteCanvas: async function (idCanvas) {
            this.$store.commit("setSelect_diretory", null)
            var self = this
            var refr = null

            if(this.ultimType == 'MpBits'){
                // this.$store.commit("setDirectoryGeoMpBits", { data: [], typeCanvas: "Geo" })
                if (JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true) {
                    
                    await axios
                    .delete('https://api.safered.co/api/deleteCanvasImage/' + idCanvas +"/"+ 
                        JSON.parse(localStorage.user).id)
                    .then(function (response) {
                        console.log(response);
                        
                        refr = self.$store.state.notiDeleteC + 1
                        self.$store.commit("setNotiDeleteC", refr)
                        
                        // self.toggleModal()
                    })
                    .catch(function (error) {
                        console.log(error);
                        
                        refr = self.$store.state.notiFail + 1
                        self.$store.commit("setNotiFail", refr)
                    });
                
                } else {
                    console.log("No tiene los permisos.")
                }
                refr = this.$store.state.refreshDataGeo + 1
                this.$store.commit("setRefreshDataGeo", refr)
                this.$store.commit("setSelect_diretory", 3)
            }else{
                // this.$store.commit("setDirectoryGeoMpBits", { data: [], typeCanvas: "MpBits" })
                if (JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true){
 
                    await axios
                    .delete('https://api.safered.co/api/deleteCanvasGPS/' + idCanvas +"/"+
                        JSON.parse(localStorage.user).id)
                    .then(function (response) {
                        console.log(response);

                        refr = self.$store.state.notiDeleteC + 1
                        self.$store.commit("setNotiDeleteC", refr)
                        // self.toggleModal()
                    })
                    .catch(function (error) {
                        console.log(error);

                        refr = self.$store.state.notiFail + 1
                        self.$store.commit("setNotiFail", refr)
                    });
                } else {
                    console.log("No tiene permisos")
                }
                refr = this.$store.state.refreshDrawGeo + 1
                this.$store.commit("setRefreshDrawGeo", refr)
                this.$store.commit("setSelect_diretory", 2)
            }
            
        },
        // toggleModal() {
        //   this.$refs['my-modal'].toggle('#toggle-btn');
        // },
    },
    watch:{
        showMDeleteCanvasI: function(){
            if(this.ultimType == 'Geo'){
                if(this.showMDeleteCanvasI == true){
                    this.showMDeleteC = this.showMDeleteCanvasI; 
                }else{
                    if(this.showMDeleteCanvasI == true){
                        this.showMDeleteC = this.showMDeleteCanvasI;
                    }
                }
            }
        },
        showMDeleteC: function(){
            if(this.ultimType == 'Geo'){
                if(this.showMDeleteC == false){
                    this.$store.commit("setShowMDeleteCanvasI", false)
                }
            }else{
                if(this.showMDeleteC == false){
                    this.$store.commit("setShowMDeleteCanvasG", false)
                }
            }
        }
    }
}
</script>
<style>
    
</style>