<template>
  <div class="center grid">
    <b-row>
      <b-col sm="1">
      </b-col>
      <b-col sm="10">
        <b-card style="margin-top: 29px; margin-bottom: 30px;">
          <div style="height: 90px;">
          </div>
          <b-row>
            <b-col sm="2">
            </b-col>
            <b-col sm="3">
              <createGroup :listProject="listProject" :listU="listUser"  id="card_style"/>
            </b-col>
            <b-col sm="3">
              <editGroup :listProject="listProject" :listU="listUser" id="card_style"/>
            </b-col>
            <b-col sm="3">
              <deleteGroup :listProject="listProject" id="card_style"/>
            </b-col>
            <b-col sm="2">
            </b-col>
          </b-row>
          <div style="height: 110px;">
          </div>
        </b-card>
      </b-col>
      <b-col sm="1">
      </b-col>
    </b-row>
  </div>
</template>
<script>

import api from '../../../../../../api/index.js'
import createGroup from './modals/CreateGroup.vue'
import editGroup from './modals/EditGroup.vue'
import deleteGroup from './modals/DeleteGroup.vue'

export default {
  name: 'groupAdm',
  components: {
    createGroup,
    editGroup,
    deleteGroup
  },
  data() {
    return {
      listProject: [],
      listUser: []
    }
  },
  props: ['projects'],
  mounted() {
    this.initListG()
    this.getUsers()
    console.log(this.projects)
  },
  methods: {
    getUsers: async function () {
      var usersTmp = [];
      await api
        .request("get", "/listUsers/" + JSON.parse(localStorage.user).client_id + "/clients")
        .then(function (data) {
          usersTmp = data.data.users;
        })
        .catch(function (e) {
          console.log(e);
        });

        console.log(usersTmp);
        usersTmp.forEach(element => {
        if (JSON.parse(localStorage.user).id != element.id) {
          this.listUser.push({
            text: element.name,
            value: element.id
          })
        }
      });
    },
    initListG: async function () {
      var self = this
      this.projects.map(function (project) {
        self.listProject.push({
          text: project.name,
          value: project.id
        })
      })
    },
  },
  watch: {

  }
}
</script>
<style>

</style>