<template>
  <v-app>
    <v-app-bar absolute color="#283271" app>
      <NavbarP></NavbarP>
    </v-app-bar>

    <v-main>
      <div v-if="isUser">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
              <v-card style="height: 480px; margin-bottom: 35px; margin-top: 35px; display: flex; flex-direction: column; 
                align-items: center; justify-content: center;">
                <div class="py-5 text-center">
                  <h4>{{$t('register.activaAcc_activeAccUser')}}</h4>
                  <p class="lead" style="font-size: unset;">
                    {{$t('register.confirmUser_activeAcc')}}
                  </p>
                </div>
                <v-col>
                  <h4  class="d-flex justify-content-between align-items-center mb-3">
                    <span class="text-primary">{{$t('register.persInf_activeAcc')}}</span>
                  </h4>
                  <v-row style="margin-bottom: 15px; margin-top: 35px;">
                    <v-col cols="6">
                      <v-row>
                        <h6 class="my-0">{{$t('register.contNa_activeAcc')}}</h6>
                        <small class="text-muted">{{ dataUser.nombreContacto }} {{ dataUser.apellidosContacto }}</small>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <h6 class="my-0">{{$t('register.typeIDN_activeAcc')}}</h6>
                        <small class="text-muted">{{ dataUser.tipoDocumentoContacto }} :
                            {{ dataUser.numeroDocumentoContacto }}</small>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row style="margin-bottom: 15px;">
                    <v-col cols="6">
                      <v-row>
                        <h6 class="my-0">{{$t('register.emailCont_activeAcc')}}</h6>
                        <small class="text-muted">{{ dataUser.emailContacto }}</small>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <h6 class="my-0">{{$t('register.phoneCont_activeAcc')}}</h6>
                        <small class="text-muted">{{ dataUser.numeroTelefonoContacto }}</small>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row style="margin-bottom: 15px;">
                    <v-col cols="6">
                      <v-row>
                        <h6 class="my-0">{{$t('register.coStCi')}}</h6>
                        <small class="text-muted">{{ dataUser.PaisContacto }}/{{ dataUser.estadoContacto }}/{{
                        dataUser.ciudadContacto}}</small>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <h6 class="my-0">{{$t('register.address_activeAcc')}}</h6>
                        <small class="text-muted">{{ dataUser.direccionContacto }}</small>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                    
                <v-btn
                  @click="modalShow = !modalShow"
                  style="margin-bottom: 15px; margin-top: 25px; display: inline-block"
                  color="success"
                >
                  {{$t('register.activaAcc_activeAccUser')}}
                </v-btn>

              </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <b-modal v-model="modalShow" :title="$t('register.setAdmPass_activeAcc')" hide-footer>
          <div class="modal-body">
            <div>
              <div class="mb-3">
                <label for="nameFormControlInput1" class="form-label">{{$t('register.name_activeAccUser')}}</label>
                <input type="text" class="form-control" id="nameFormControlInput1"
                  placeholder="Escriba el nombre del usuario..." v-model="nameUser">
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">{{$t('register.email_activeAccUser')}}</label>
                <input type="email" class="form-control" id="exampleFormControlInput1"
                  :placeholder="$t('validEmail_activeAccUser')+'... name@example.com'" v-model="emailaccount">
              </div>
              <div class="mb-3">
                <label for="inputPassword" class="form-label">{{$t('register.pass_activeAccUser')}}</label>
                <input type="password" class="form-control" id="inputPassword" placeholder="*************"
                  v-model="passwordaccount">
              </div>
              <div class="mb-3">
                <label for="inputPassword2" class="form-label">{{$t('register.passConfir_activeAccUser')}}</label>
                <input type="password" class="form-control" id="inputPassword2" placeholder="*************" v-model="password2account">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <v-btn
              @click="modalShow = !modalShow"
              style="margin-top: 15px; margin-right: 15px; color: white"
              color="#FF6060"
            >
              {{$t('register.close_activeAccUser')}}
            </v-btn>
            <v-btn
              style="margin-top: 15px; color: white"
              color="#5AB55E"
              @click="saveUser(); modalShow = !modalShow"
            >
              {{$t('register.save_activeAccUser')}}
            </v-btn>
          </div>
        </b-modal>
          
      </div>
      <div v-if="!isUser">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-card style="height: 350px; margin-top: 35px; display: flex; flex-direction: column; 
              align-items: center; justify-content: center;">
              <div class="py-5 text-center">
                <v-alert
                  dense
                  outlined
                  type="error"
                >
                  <strong>{{$t('register.invaLink_activeAccUser')}}</strong>
                </v-alert>
                <p></p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </div>
    </v-main>
    <v-footer color="#283271" padless>
      <footerP/>
    </v-footer>
  </v-app>
</template>
<script>
/*eslint-disable */
import axios from 'axios'
import footerP from "./structure_p/FooterP.vue"
import NavbarP from "./structure_p/NavbarP.vue"

export default {
  name: 'active',
  components: {
    footerP,
    NavbarP
  },
  mounted: async function () {
    await this.listUsers()
  },
  data: function () {
    return {
      dataUser: null,
      nameUser: '',
      emailaccount: '',
      passwordaccount: '',
      password2account: '',
      isActiveUser: false,
      isUser: false,
      modalShow: false,
    }
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
    },
    listUsers: async function () {
      var self = this
      var tmpObJ
      await axios
        .get(
          'https://api.safered.co/api/takeClient/' + this.$route.params.idClient + '/p_natural'
        )
        .then((result) => {
          self.isUser = true
          tmpObJ = result.data[0]
        })
        .catch((error) => {
          console.log(error.status);
          self.isUser = false
        });
      this.emailaccount = tmpObJ.emailContacto
      this.nameUser = tmpObJ.nombreContacto + ' ' + tmpObJ.apellidosContacto
      this.dataUser = tmpObJ
      this.client_id = tmpObJ.id
      return tmpObJ.active.replace(/['"]+/g, '')
    },
    saveUser: async function () {
      var self = this
      if (
        this.emailaccount !== '' &&
        this.passwordaccount !== '' &&
        this.password2account !== '' &&
        this.nameUser !== ''
      ) {
        if (this.passwordaccount === this.password2account) {
          let formData = new FormData()
          formData.append('name', this.nameUser)
          formData.append('email', this.emailaccount)
          formData.append('password', this.passwordaccount)
          formData.append('client_id', this.client_id)

          var createUser = false
          await axios
            .post(
              'https://api.safered.co/api/register',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
            .then((result) => {
              if (result.status === 200) {
                console.log(result)
                alert(self.$t('register.UserSucc_activeAcc'))
                createUser = true
              } else {
                console.log(result)
                createUser = true
                alert(self.$t('register.notCreated_activeAccUser'))
              }
            })

          if (createUser) {
            await axios
              .put(
                'https://api.safered.co/api/updateStatus/' + this.client_id + '/p_natural')
              .then((result) => {
                if (result.status === 200) {
                  console.log(result)
                  alert(self.$t('register.statUpd_activeAccUser'))
                  createUser = true
                } else {
                  console.log(result)
                  createUser = true
                  alert(self.$t('register.statUpdNot_activeAccUser'))
                }
              })
          }
          this.$router.replace("/");
        } else {
          alert(self.$t('register.passNotMatch_activeAccUser'))
        }
      } else {
        if (this.emailaccount === '') {
          alert(self.$t('register.enterValEmail_activeAccUser'))
        } else if (this.passwordaccount === '') {
          alert(self.$t('register.enterValPass_activeAccUser'))
        } else if (this.password2account === '') {
          alert(self.$t('register.confPass_activeAccUser'))
        } else if (this.nameUser === '') {
          alert(self.$t('register.insetUName_activeAccUser'))
        }
      }
    }
  },
  watch:{
    isUser(){
      console.log(this.isUser)
    }

  }
}
/*eslint-enable */
</script>
<style>
.bg-primary-dark {
  background-color: #283271;
}
</style>

