<template>
    <div>
        <v-card
        style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
            @click="dialog = true">
            <v-img
            :src="icon_info_user"
            alt=""
            style="width: 110px"
            />

            <v-card-title style="padding-left: 12px; padding-right: 12px;">
                <h3 style="font-size: 12px">Editar usuario</h3>
            </v-card-title>
        </v-card>

        <v-dialog v-model="dialog" width="620">
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Seleccion proyecto
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                    >
                        Seleccion usuario
                    </v-stepper-step>

                    <v-stepper-step
                        step="3"
                    >
                        Editar usuario
                    </v-stepper-step>
                </v-stepper-header>

                
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card
                        class="mb-12"
                        height="250px"
                        style="display: flex;"
                        >
                            <v-row style="display: flex; justify-content: center; align-items: center;">
                                <v-col cols="10">
                                    <v-select
                                        v-model="selectP"
                                        :items="listProject"
                                        label="Seleccion el proyecto"
                                        persistent-hint
                                        return-object
                                        single-line
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-btn
                            :disabled="selectP.value == undefined"
                            color="primary"
                            @click="e1=2"
                        >
                            Continuar
                        </v-btn>

                        <v-btn @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card height="250px" class="mb-12" style="display: flex; flex-direction: row;
                        align-items: center;">
                            <v-col>
                                <v-row style="display: flex; justify-content: center;">
                                    <v-col cols="10">
                                        <v-select
                                        v-model="selectUser"
                                        :items="foundUsuarios"
                                        label="Ingrese el nombre del usuario"
                                        persistent-hint
                                        return-object
                                        single-line
                                    ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>

                        <v-btn v-if="!activeLoad"
                        :disabled="selectUser.length == 0"
                        color="primary"
                        @click="loadRolP(); e1=3"
                        >
                            Continuar
                        </v-btn>

                        <v-btn @click="dialog = false; reset()">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card
                            class="mb-12"
                            height="380px"
                        >
                            <v-row style="padding-top: 10px;
                            text-align: end; padding-right: 20px;">
                                <p v-if="actualRol.text != undefined" >Rol Actual: {{ actualRol.text }}</p>
                                <p v-if="actualRol.text == undefined" >Rol Actual: {{ actualRol }}</p>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-col>
                                        <v-text-field
                                            label="Nombre del usuario:"
                                            v-model="selectUser.text"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-col>
                                <v-col cols="6">
                                    <v-col>
                                        <v-select
                                            v-model="rolNew"
                                            :items="opcionesRol"
                                            label="Cambiar rol actual"
                                            outlined
                                        ></v-select>
                                    </v-col>
                                </v-col>
                            </v-row>
                            <v-row >
                                <v-combobox
                                    class="mx-4"
                                    style="padding-bottom: 10px;"
                                    v-model="selectBusiness"
                                    :items="dataBusiness"
                                    label="Seleccione la empresa a asignar"
                                    item-text="name"
                                    required
                                ></v-combobox>
                            </v-row>
                            <v-divider></v-divider>
                            <h6>Reasignación de recursos</h6>
                            <v-row
                                style="margin-bottom: 10px;"
                                >
                                <v-col cols="7" style="display: flex;
                                    align-items: center;">
                                    <p style="font-size: 13px;">
                                        <em>Si desea reasginar proyectos, roles, grupos y las politicas de otro 
                                            usuario seleccione el usuario:</em>
                                    </p>
                                </v-col>
                                <v-col cols="5">
                                    <v-select
                                        v-model="selectUserT"
                                        :items="userTranf"
                                        label="Ingrese el nombre del usuario"
                                        outlined
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-btn
                            @click="sendRol();"
                            color="primary"
                        >
                            Continuar
                        </v-btn>

                        <v-btn @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-dialog>

        <b-modal ref="alert-transf" hide-footer>
            <div class="d-block text-center">
              <h6>Los recursos del usuario {{textselectUserT}} serán reasignado al usuario 
                    {{selectUser.text}}. Por favor confirmar.</h6>
              <p>Recuerde que {{textselectUserT}} perderá todos sus datos.</p>
            </div>
            <b-button class="mt-2" variant="outline-success" block @click="sendTranf()">Aceptar</b-button>
            <b-button class="mt-2" variant="outline-warning" block  @click="$bvModal.hide('alert-transf')">Cancelar</b-button>
        </b-modal>

        <v-snackbar
            v-model="NotiAsingR"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha editado el <strong>Usuario</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="NotiAsingR = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar
            v-model="NotiFail"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
            No se pudo realizar la acción

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="NotiFail = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>
<script>

import api from "@/api";
import icon_info_user from '../../../../../../../assets/img/icons/icon_user_info.svg'
import axios from "axios";

export default {
    name: 'rolesClientM',
    components:{
        
    },
    data(){
        return{
            icon_info_user: icon_info_user,
            NotiAsingR: false,
            NotiFail: false,
            e1: 1,
            selectP: {},
            dialog: false,
            selectUserT: null,
            selectUser: {},
            foundUsuarios: [],
            userTranf: [],
            activeLoad: false,
            rolNew: null,
            opcionesRol: [
                {text: "Usuario Base", value: 2},
                {text: "Usuario Editor", value: 4}
            ],
            rolesActualU: [],
            actualRol: '',
            textselectUserT: null,
            selectBusiness: null,
        }
    },
    async mounted(){
        this.getClients();
    },
    props:[ 'listProject', 'dataBusiness'],
    methods:{
        loadRolP: async function(){
            var self = this
            await axios
                .get(
                "https://api.safered.co/api/getRoleFromUser/" + self.selectUser.value )
                .then(function (data) {
                    console.log(data)
                    self.rolesActualU = data.data;
                })
                .catch(function (e) {
                    console.log(e);
                    self.actualRol = "N/A"
                });
            this.verifyRol();
        },
        verifyRol: async function(){
            var self = this
            this.rolesActualU.map(function(rolA){
                if(rolA.project_id != null){
                    if(rolA.project_id == self.selectP.value){
                        if(rolA.role_id == 2){
                            self.actualRol = {
                                text: "Usuario Base",
                                value:2
                            }
                        }else if(rolA.role_id == 4){
                            self.actualRol = {
                                text: "Usuario Editor",
                                value:2
                            }
                        }   
                    }
                }else{
                    self.actualRol = "N/A"
                }
            })

            this.foundUsuarios.map(function(obj){
                if(self.selectUser.value != obj.value){
                    self.userTranf.push(obj)
                }
            })

            console.log(this.actualRol);
        },
        sendRol: async function(){
            var self = this
            if(this.selectBusiness != null){
                var obj = {
                    "idUser": self.selectUser.value
                } 
                await api
                .request("post", `/company/${this.selectBusiness.id}/linkUser`, obj)
                .then((data) => {
                    console.log(data)
                })
                .catch(function(error) {
                    console.log(error)
                });
            }

            if(this.actualRol == "N/A"){

                if(self.rolNew != null){
                    await axios
                    .post(
                    "https://api.safered.co/api/asignRoleToUser", {
                        "idUserEdit": JSON.parse(localStorage.user).id,
                        "idUser": self.selectUser.value,
                        "idRole": self.rolNew,
                        "idProject": self.selectP.value
                    })
                    .then(function (data) {
                        console.log(data)
                    })
                    .catch(function (e) {
                        console.log(e);
                    });
                }
                
                var editUser = {
                    idUserEdit: JSON.parse(localStorage.user).id,
                    idUser: self.selectUser.value,
                    nameUser: self.selectUser.text
                }

                await axios
                .post("https://api.safered.co/api/editUser", editUser)
                .then(function (data) {
                    console.log(data)
                    self.dialog = (self.selectUserT == null ) ? false : true
                    self.NotiAsingR = (self.selectUserT == null ) ? false : true
                })
                .catch(function (e) {
                    self.NotiFail = true
                    console.log(e);
                });

            }else{
                await axios
                .put(
                "https://api.safered.co/api/reasignRoleToUser", {
                    "idUserEdit": JSON.parse(localStorage.user).id,
                    "idUser": self.selectUser.value,
                    "idRole": self.rolNew,
                    "idProject": self.selectP.value
                })
                .then(function (data) {
                    console.log(data)
                    
                })
                .catch(function (e) {
                    console.log(e);
                });

                editUser = {
                    idUserEdit: JSON.parse(localStorage.user).id,
                    idUser: self.selectUser.value,
                    nameUser: self.selectUser.text
                }

                await axios
                .post("https://api.safered.co/api/editUser", editUser)
                .then(function (data) {
                    console.log(data)
                    self.dialog = (self.selectUserT == null ) ? false : true
                    self.NotiAsingR = (self.selectUserT == null ) ? false : true
                })
                .catch(function (e) {
                    self.NotiFail = true
                    console.log(e);
                });
            }
            
            if(this.selectUserT != null){
                this.$refs['alert-transf'].show();
            }
        },
        sendTranf: async function(){
            var self = this
            this.$refs['alert-transf'].hide()
            var obj = {
                "idOrigen": self.selectUserT,
                "idDestino": self.selectUser.value,
            }
            await axios
                .post("https://api.safered.co/api/transferirPermisos", obj)
                .then(function (data) {
                    console.log(data)

                    self.NotiAsingR = true;
                })
                .catch(function (e) {
                    console.log(e);
                    self.NotiFail = true
                });
            
            const config = {
                headers: { Authorization: `Bearer ${JSON.parse(localStorage.user).token}` },
            };
            
            await axios
                .post("https://microservices.safered.co/api/reemplazarRules", obj, config)
                .then(function (data) {


                    self.dialog = false;
                    console.log(data)
                })
                .catch(function (e) {
                    console.log(e);
                });


        },
        getClients: async function () {
            var self = this
            var clientsTmp = [];

            await axios
                .get(
                "https://api.safered.co/api/listUsers/" +
                    JSON.parse(localStorage.user).client_id +
                    "/clients"
                )
                .then(function (data) {
                clientsTmp = data.data.users;
                })
                .catch(function (e) {
                console.log(e);
                });
                console.log(clientsTmp)
                clientsTmp.forEach(element => {
                    if (JSON.parse(localStorage.user).id != element.id) {
                        self.foundUsuarios.push({
                            text: element.name,
                            value: element.id
                        })
                    }
                });
        },
    },
    watch:{
        dialog: function(){
            if(!this.dialog){
                this.e1 = 1,
                this.selectUser = {},
                this.rolNew = '',
                this.actualRol = ''
            }
        },
        selectUserT: function(){
            var userSelect = this.foundUsuarios.find(element => element.value == this.selectUserT)
            console.log(userSelect)
            this.textselectUserT = userSelect.text
        }
    }
}
</script>
<style>
    
</style>