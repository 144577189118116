<template>
    <div v-if="currentCanvas != null">
        
        <v-row style="margin: 24px;" >
            <v-card
                elevation="5"
                style="padding-right: 0px!important; 
                padding-left: 0px!important;"
                
            >
                <v-card-text  class="text-lg-right py-2">
                    <v-btn  small class="text-caption" color="#283271" style=" cursor: auto; margin: -5px;  color: white;  ">{{ this.currentCanvas.projectName }} / {{this.currentCanvas.dataGeo[currentCanvas.posicionGeo].Name}}</v-btn>
                </v-card-text>
            
                   
                <canvasGeo style="margin: 1px 10px 10px 10px;" :principal="currentCanvas.posicionGeo" :key="count + '-' + currentCanvas.dataGeo[currentCanvas.posicionGeo].IdCanvas"
                :dataPoints="currentCanvas.dataPoints[currentCanvas.posicionGeo]" :polygonsLoaded="currentCanvas.polygonsLoaded[currentCanvas.posicionGeo]"
                :linesLoaded="currentCanvas.linesLoaded[currentCanvas.posicionGeo]" :idCanvas="currentCanvas.dataGeo[currentCanvas.posicionGeo].IdCanvas" :dateFilt="currentCanvas.dateFilt"
                :nameCanvas="currentCanvas.dataGeo[currentCanvas.posicionGeo].Name" :refM="currentCanvas.dataGeo[currentCanvas.posicionGeo].Name.replace(/\s+/g, '') + '-' + currentCanvas.posicionGeo"
                :refP="currentCanvas.dataGeo[currentCanvas.posicionGeo].Name.replace(/\s+/g, '') + '-' + currentCanvas.posicionGeo + 'P'" :typeDocument="currentCanvas.typeDocument"
                :projectId="currentCanvas.idSelect" :rolesActualU="currentCanvas.rolesActualU" :folderGoogle="currentCanvas.folderGoogle" :listCodeP="currentCanvas.listCodeP"
                :dataObjectP="currentCanvas.dataObjectP" :idPrincipal="currentCanvas.dataGeo[0].IdCanvas" :dataLinks="currentCanvas.dataLinks" :permissionC="currentCanvas.permissionC"
                :projectName="currentCanvas.projectName" :dataMap="currentCanvas.dataMap[0].children" :dataLocation="currentCanvas.dataLocation != null ? currentCanvas.dataLocation : null"/>
            </v-card>
        </v-row>
    </div>
</template>

<script>
import canvasGeo from '@/components/layout/component_Main/body_main/canvas/CanvasGeo.vue';
import filtExistObj from '../../../api/scriptE/index';

export default {
    name: "mainCanvasGeo",
    components:{
        canvasGeo
    },
    data(){
        return{
            currentCanvas: null,
            count: 0,
        }
    },
    async mounted(){
        this.currentCanvas = JSON.parse(localStorage.canvas_info);
        var oldCurrentCanvas = this.currentCanvas;
        var self = this

        window.addEventListener('storage', function(e) {
            if(e.key === 'canvas_info') {
                self.currentCanvas = JSON.parse(e.newValue);
                console.log(filtExistObj.newChildren(oldCurrentCanvas.dataMap[0], self.currentCanvas.dataMap[0]))
                // if(self.currentCanvas == ){

                // }else{
                //     self.count++;
                //     self.currentCanvas.keyG = self.count;
                // }
            }
        });
    },
    methods:{
    },
    watch:{
        "$store.state.refreshDrawGeo": function(){
            var obj = {
                refreshDrawGeo: this.$store.state.refreshDrawGeo,
                refreshDataGeo: this.$store.state.refreshDataGeo,
                refreshDGeneral: this.$store.state.refreshDGeneral
            }
            window.localStorage.setItem("info_event",  JSON.stringify(obj))
        },
         "$store.state.refreshDataGeo": function(){
            var obj = {
                refreshDrawGeo: this.$store.state.refreshDrawGeo,
                refreshDataGeo: this.$store.state.refreshDataGeo,
                refreshDGeneral: this.$store.state.refreshDGeneral
            }
            window.localStorage.setItem("info_event",  JSON.stringify(obj))
        },
         "$store.state.refreshDGeneral": function(){
            var obj = {
                refreshDrawGeo: this.$store.state.refreshDrawGeo,
                refreshDataGeo: this.$store.state.refreshDataGeo,
                refreshDGeneral: this.$store.state.refreshDGeneral
            }
            window.localStorage.setItem("info_event",  JSON.stringify(obj))
        },
    }
}
</script>

<style>


</style>