<template>
  <div>
    <v-card
      style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
      @click="DeleteProjects"
    >
      <v-img
        :src="icon_remove"
        alt=""
        style="width: 110px"
      />
      <v-card-title>
        <h3 style="font-size: 12px">Eliminar proyecto</h3>
      </v-card-title>
    </v-card>
    <!-- Modal -->
    <b-modal
      class="h-50 d-inline-block"
      id="modal-xl"
      ref="my-modal-delete-project"
      hide-footer
      title="Eliminar Proyecto"
      centered
      v-b-modal.modal-scrollable
      button-size="sm"
    >
      <form-wizard
        shape="circle"
        title="Proyectos - SAFE RED"
        subtitle="Eliminar proyectos"
        color="#1565c0"
        nextButtonText="Eliminar"
        backButtonText="Volver"
        finishButtonText="Eliminar"
        @on-complete="confirmDeleteProjects"
      >
        <tab-content title="Seleccionar proyecto" style="width: 100%" :before-change="validateProject">
          <div class="form-group">
            <v-select
              v-model="selectedProject"
              :items="projectsFormat"
              label="Seleccionar proyecto"
            ></v-select>
          </div>
        </tab-content>
        </form-wizard>
    </b-modal>

    <b-modal
      ref="modal-delete-project_confirm"
      title="Eliminar proyecto - confirmación"
    >
      <b-container fluid>
        <v-col>
          <v-row>
            <p class="text-muted" style="font-size: 12px;">
              Al eliminar tu proyecto, perderás acceso permanente a toda la información 
              relacionada. Esta acción no se puede deshacer. Te recomendamos hacer una 
              copia de seguridad antes de continuar. Para obtener ayuda, contacta a nuestro 
              equipo de soporte.
            </p>
          </v-row>

          <v-row>
            <v-col cols="2" style="display: flex; align-items: center;">
              <b-form-checkbox v-model="statusElimincarCG" value="eliminar" unchecked-value="">
              </b-form-checkbox>
            </v-col>
            <v-col cols="9" style="display: flex; align-items: center;">
              <p class="mb-0">Confirma que deseas eliminar el proyecto.</p>
            </v-col>
          </v-row>
        </v-col>
      </b-container>

      <template #modal-footer>
        <b-button v-if="statusElimincarCG.length > 0" variant="danger" @click="deleteProject();">
          Eliminar
        </b-button>
        <b-button v-if="statusElimincarCG.length <= 0" disabled variant="danger">
          Eliminar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import icon_remove from '../../../../../../../assets/img/icons/icon_remove_project.svg'
import api from "@/api";

export default {
  name: "delete-project-modal",
  components: {},
  data() {
    return {
      icon_remove: icon_remove,
      selectedProject: null,
      projectsFormat: [],
      statusElimincarCG: '',
      infoProject:{
        id:'',
        nameProject: '',
        canvas: [],
        fileConfig: '',
        sizeDisk: '1',
        sizeFile:'1',
        typeFiles:[],
        selectedUsers:[],
        users:[]
      },
      selectedUser: null,
      viewSelectUsers: [],
      usersC: []
    };
  },
  props: {
    projects: {},
    users:[]
  },
  mounted() {
    this.formatProject()
    this.usersC = this.users
  },
  methods: {
    confirmDeleteProjects: function(){
      this.$refs["modal-delete-project_confirm"].show();
    },
    DeleteProjects: function () {
      this.$refs["my-modal-delete-project"].show();
    },
    deleteProject: async function () {
      var self = this
     await api
        .request(
          "delete",
          "/deleteProject/"+self.selectedProject +"/"+ JSON.parse(localStorage.user).id+"/"+
          JSON.parse(localStorage.user).token,
          self.infoProject
        )
        .then((data) => {
          if(data.status == 200){
            alert('Proyecto eliminado exitosamente');
            self.$refs["my-modal-delete-project"].hide()
            self.$refs["modal-delete-project_confirm"].hide();

            var refD =  self.$store.state.refreshProject + 1;
            self.$store.commit("setRefreshProject", refD);
          }
        })
        .catch(function (e) {
          console.log(e);
        });
        
    },
    formatProject: async function (){
      console.log('aqui')
      for (let property1 in this.projects) {
        var tmp_obj = {
            value: this.projects[property1][0].id,
            text: this.projects[property1][0].name,
        };
        this.projectsFormat.push(tmp_obj)
      }
      var itemNull = { value: null, text: "Por favor seleccione un proyecto" };
      this.projectsFormat.unshift(itemNull);
    },
    validateProject: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.selectedProject) {
            this.getInfoProject()
            resolve(true);
          }
          else {
            reject("Debe seleccionar un proyecto para eliminar.");
            alert("Debe seleccionar un proyecto para eliminar.");
          }
        }, 1000);
      });
    },
    getInfoProject: function () {
      for (let property1 in this.projects) {
        if(this.selectedProject == this.projects[property1][0].id){
          this.infoProject.nameProject = this.projects[property1][0].name
          }
        }
      
    },
    deleteUserForProject: function (user){
      var indexDelete = 0
      this.infoProject.users.forEach(function (element, i) {
        if(user.id === element){
          indexDelete = i
        }
      })
      this.infoProject.users.splice(indexDelete,1)
        

      this.viewSelectUsers.forEach(function (element, i) {
        if(element.id === user.id){
          indexDelete = i
        }
      })
      this.viewSelectUsers.splice(indexDelete,1)

      this.usersC.push(
        {
          "text": user.name,
          "value": user.id
        }
      )
    },
    setUser: function () {
      if (this.selectedUser) {
        this.infoProject.users.push(this.selectedUser);
        var selUsTmp = this.selectedUser
        var listUser = []
        var listUsersId = []
        var index = 0
        this.usersC.forEach(
          function (element, i) {
            if(element.value == selUsTmp){
              listUser.push(element.text)
              listUsersId.push(
                {
                  "name" : element.text,
                  "id" : element.value
                }
              )
              index = i
            }
          });

          
          if(listUsersId.length>0){
            this.viewSelectUsers.push(
            {
              "name" : listUsersId[0].name,
              "id" : listUsersId[0].id
            }
          )
            this.usersC.splice(index,1)
          }
      } else {
        alert("Debe seleccionar un usuario");
      }
    }
  },
};
</script>