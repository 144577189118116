<template lang="">
    <v-container>
        <v-row>
            <v-col
                cols="3"
            >
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: small;font-weight: bold;">
                        <small>Sponsored by:</small>
                    </p>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <p class="font-size-sm mb-0" style="color:rgb(255,255,255)">
                        <small>Logo</small>
                    </p>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: xx-small">
                        <small> © Copyright 2021. SafeRed. {{ $t('Structure_p.allRigh_footer') }}</small>
                    </p>
                </div> 
            </v-col>
            <v-col
                cols="2"
            >
            <div style="display: flex; flex-direction: column; align-items: center;">
                <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: small;font-weight: bold;">
                    <small>{{ $t('Structure_p.custServ_footer') }}</small>
                </p>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: xx-small">
                <small>{{ $t('Structure_p.contUs_footer') }}</small>
                </p>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: xx-small">
                <small>316-803 2153</small>
                </p>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: xx-small">
                <small>info@safered.co</small>
                </p>
            </div>
            </v-col>
            <v-col
                cols="2"
            >
            <div style="display: flex; flex-direction: column; align-items: center;">
                <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: small;font-weight: bold;">
                    <small>{{ $t('Structure_p.legalInf_footer') }}</small>
                </p>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: xx-small">
                    <small>{{ $t('Structure_p.privPolicy_footer') }}</small>
                </p>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center;">
                <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: xx-small">
                    <small>{{ $t('Structure_p.termUse') }}</small>
                </p>
            </div>
            </v-col>
            <v-col
                cols="3"
            >
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: small;font-weight: bold;">
                        <small>{{ $t('Structure_p.veracityInf_footer') }}</small>
                    </p>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: xx-small">
                        <small>{{ $t('Structure_p.consPro_footer') }}</small>
                    </p>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <p class="font-size-sm mb-0" style="color:rgb(255,255,255);font-size: xxx-small">
                        <small>PQRS</small>
                    </p>
                </div>
            </v-col>
            <v-col
                cols="2"
                style="display: flex; flex-direction: column; justify-content: center;"
            >
                <div style="background: white; display: flex; flex-direction: column;
                      align-items: center;">
                    <v-img
                        style="margin-top: 5px; margin-bottom: 5px; "
                        :src="logoS"
                        max-width="120"
                    >
                    </v-img>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import LogoS from "../../../assets/img/logos/logo_sic.png"

export default {
    name: 'FooterP',
    components: {
        
    },
    data(){
        return{
            logoS: LogoS
        }
    }
}
</script>
<style lang="">
    
</style>