import { render, staticRenderFns } from "./CreateClientForm.vue?vue&type=template&id=1bd21924&scoped=true&"
import script from "./CreateClientForm.vue?vue&type=script&lang=js&"
export * from "./CreateClientForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd21924",
  null
  
)

export default component.exports