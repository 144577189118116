<template>
    <v-dialog
        v-model="dialog"
        width="500"    
    >
        <v-card>
            <v-card-title class="text-h5 white lighten-2">
                Crear vista
            </v-card-title>
                
            <v-card-text>
                <v-text-field
                    v-model="tituloGeo"
                    label="Titulo de la vista"
                    required
                ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn v-if="tituloGeo.length > 0" variant="success" @click="newGeo(); dialog = false;">
                        Crear
                    </v-btn>
                    <v-btn v-if="tituloGeo.length <= 0" disabled variant="success">
                        Crear
                    </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from 'axios';

export default {
    name: "modalCreateVistaGeo",
    data(){
        return{
            dataGeo: "",
            tituloGeo: "",
            dialog: false,
            idCancasResponse: null,
        }
    },
    props:['viewModalCreateVistaGeo', 'dataGeoL', 'permitEdit'],
    methods:{
        newGeo: async function(){
            if (JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true || this.permitEdit) {
                var newDataGeo = [];
                for (let i = 0; i < this.dataGeo.length; i++) {
                    newDataGeo.push({
                        Figuras: this.dataGeo[i].Figuras,
                        Puntos: this.dataGeo[i].Puntos,
                        Name: this.dataGeo[i].Name
                    })
                }
                newDataGeo.push({
                    Figuras: [],
                    Puntos: [],
                    Name: this.tituloGeo,
                });

                this.dataGeo = newDataGeo;
                await this.saveGeoCloud();
                this.tituloGeo = "";
            } else {
                console.log("No tienes los permisos");
            }
        },
        saveGeoCloud: async function () {
            this.idCancasResponse = null
            var dataGeoCloud = {
                idProject: this.$store.state.dataInit.idProject,
                name: this.tituloGeo,
                idUser: JSON.parse(localStorage.user).id
            }
            var self = this
            var refr = null 
            await axios
                .post('https://api.safered.co/api/createCanvasGPS', dataGeoCloud)
                .then(function (response) {
                    self.idCancasResponse = response.data.canvas.id;
                    console.log(response)
                })
                .catch(function (error) {
                    console.log(error);

                    refr = self.$store.state.notiFail + 1
                    self.$store.commit("setNotiFail", refr)
                });
            

            if(this.idCancasResponse != null && this.dataGeoL.length > 0){
                var obj = {
                    arrayObject: [
                        {
                            idP: this.dataGeoL[0].IdCanvas,
                            idS: this.idCancasResponse,
                            tCanvas: 0
                        }
                    ]
                }

                console.log(obj)

                await axios
                .post('https://api.safered.co/api/createSecondaryCanvas', obj)
                .then(function () {

                    refr = self.$store.state.notiCreateC + 1
                    self.$store.commit("setNotiCreateC", refr)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }else{
                refr = self.$store.state.notiCreateC + 1
                self.$store.commit("setNotiCreateC", refr)
            }

            refr = this.$store.state.refreshDrawGeo + 1
            this.$store.commit("setRefreshDrawGeo", refr)
        },
    },
    watch:{
        viewModalCreateVistaGeo: function(){
            if(this.viewModalCreateVistaGeo == true){
                this.dialog = this.viewModalCreateVistaGeo
            }
            
        },
        dialog: function(){
            if(this.dialog == false){
                this.$store.commit("setactiveModalCreateVistaGeo", false);
            }
        }
    }
}
</script>
<style>
    
</style>