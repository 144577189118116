<template>
  <div class="center grid" style="background-color: #E4E4E4;">
    <b-row>
      <b-col sm="1"> </b-col>
      <b-col v-if="dataCompany.repLegal != undefined" sm="10">
          <div style="height: 20px"></div>
          <b-row>
            <b-col sm="1">
            </b-col>
            <b-col>
              <v-card>
                <v-row style="display: flex; flex-direction: row; align-items: center;">
                  <v-col cols="4" style="display: flex; flex-direction: column; align-items: center;">
                    <img v-if="waitFile" @click="active_2=!active_2" :src="existProfie ? imageForm : safered_logo" alt="foto de project" :style="'width: 200px;'">
                  </v-col>
                  <v-col cols="8">
                    <b-row>
                      <b-col>
                        <h3>{{dataCompany.razonSocial}}</h3>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <p>
                          <b>Dirección:</b> {{dataCompany.direccionCompany}}, 
                          {{dataCompany.ciudadCompany}} - {{dataCompany.paisCompany}}
                        </p>
                        <p>
                          <b>NIT:</b> {{dataCompany.numIdTributaria}}
                        </p>
                      </b-col>
                    </b-row>
                    <v-row>
                      <v-col cols="7">
                        <p>
                          <b>Estado de la cuenta:</b> Activo
                        </p>
                        <p>
                          <b>Saldo de la cuenta:</b> Estas al dia
                        </p>
                        <p>
                          <b>Próximo vencimiento de pago:</b> N/A
                        </p>
                        <p>
                          <b>Cliente desde:</b> {{dataCompany.create_at}}
                        </p>
                      </v-col>
                      <v-col cols="5" v-if="dataBackup != null">
                        <p>
                          <b>Listado de nubes:</b>
                        </p>
                        <p>
                          -  Google Drive
                        </p>
                        <p>
                          <b>Información del último Back Up:</b>
                        </p>
                        <p>
                          -  Fecha: {{ dataBackup.Fecha }}
                        </p>
                        <p>
                          -  Ip: {{ dataBackup.IP }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="7">
                        <v-row>
                          <v-col cols="8">
                            <v-card color="#338169" height="35px" style="display: flex; justify-content: flex-start;">
                              <i style="color: white; margin-left: 15px; margin-top: 13px;" class="fa-brands fa-whatsapp fa-xl"></i>
                              <p style="margin-bottom: 0px; margin-left: 15px; margin-top: 5px; color:white;" >Servicio al Cliente</p>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="5" style="display: flex; align-items: center;">
                        <a  href="mailto:support@safered.co" style="text-decoration: none;">
                          <i style="color: black;" class="fa-solid fa-headset"></i> Soporte: support@safered.co
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </b-col>
          </b-row>
          <div style="height: 40px"></div>
      </b-col>
      <b-col sm="1"></b-col>
    </b-row>

    <v-dialog width="450" v-model="active_2">
      <v-card  style="padding-bottom: 10px">
        <v-card-title>
          <h4 class="not-margin">Cargar imagen</h4>
        </v-card-title>

        <div class="con-form">
          <b-container class="mt-3" fluid>
            <b-form @submit.stop.prevent="onSubmit">
              <div class="d-flex mb-3" style="align-items: center;">
                <v-file-input
                  v-model="image"
                  chips
                  accept="image/png, image/jpeg, image/bmp"
                  label="Seleccionar una imagen"
                ></v-file-input>
                <v-btn
                  v-if="hasImage"
                  class="ml-3"
                  color="error"
                  @click="clearImage">
                  Limpiar imagen
                </v-btn>
              </div>

              <b-img
                v-if="hasImage"
                :src="imageSrc"
                class="mb-3"
                fluid
                block
                rounded
                :style="'height: 250px; width: auto;'"
              ></b-img>
              <div style="display: flex; flex-direction: row; justify-content: center;">
                <b-button :disabled="!hasImage" variant="primary" type="submit"
                  @click="setImage">
                  Guardar imagen
                </b-button>
              </div>

            </b-form>
          </b-container>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable */
import axios from "axios";
import safered_logo from '../../../../../../assets/img/logos/safered-logo-01.svg'

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "MyEmpresa",
    data:() => ({
        safered_logo: safered_logo,
        currentUser: {
          client_id: 0,
        },
        dataCompany: {},
        active_2: false,
        existProfie: false,
        imageForm: null,
        image: null,
        imageSrc: null,
        waitFile: false,
        dataBackup: null
  }),
  mounted(){
      this.currentUser.client_id = JSON.parse(localStorage.user).client_id
      this.getdataCompany();
      this.logoGet();
      this.getdataBackup();
  },
  methods:{
    getdataBackup: async function(){
      var dataBackup;
      var temp = {
        idClient: this.currentUser.client_id,
      }
      await axios
      .post('https://api.safered.co/api/getHistorialBakcups', temp)
      .then((response) => {
        dataBackup = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
      console.log(dataBackup)
      this.dataBackup = dataBackup[0];
    },
    getdataCompany: async function(){
      var dataCompany;
      var temp = {
        id_client: this.currentUser.client_id,
      }
        await axios
        .post('https://api.safered.co/api/getCompanyInfo', temp)
        .then((response) => {
          dataCompany = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
        console.log(dataCompany)
        this.dataCompany = dataCompany.infoClient;
    },
    async setImage() {
      let URL = window.URL;
      this.imageForm = URL.createObjectURL(this.image);
      if(this.existProfie == false){
        this.uploadImage();
      }else{
        this.updateLogoClient();
      }
    },
    clearImage() {
      this.image = null;
    },
    onSubmit() {
      if (!this.image) {
        alert("Por favor seleccione una imagen.");
        return;
      }
    },
    uploadImage: async function(){
      let formData = new FormData();
      formData.append("point_id", null);
      formData.append("file", this.image);
      formData.append("tag_id", 17),
      formData.append("project_id", null),
      formData.append("user_id", JSON.parse(window.localStorage.user).client_id)

      await axios
        .post("https://api.safered.co/api/store-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (data) {
            alert('Imagen de perfil cargada exitosamente.')
        })
        .catch(function (e) {
          console.log(e);
        });

    },
    logoGet: async function(){
      var imgProfile = null;
      var data = {
        "user_id": JSON.parse(window.localStorage.user).client_id,
        "id_tag": 17
      };
      await axios
      .post('https://api.safered.co/api/getLogoClient', data, {responseType: 'blob'})
        .then((response) => {
        imgProfile = response
      })
      .catch(function (e) {
          console.log(e);
      });
      
      if(imgProfile.data.size > 55){
        this.imageForm = URL.createObjectURL(imgProfile.data);
        this.existProfie = true;
      }else{
        this.existProfie = false;
      }
      this.waitFile = true
    },
    updateLogoClient: async function(){
      let formData = new FormData();
      formData.append("file", this.image);
      formData.append("id_tag", 17),
      formData.append("user_id", JSON.parse(window.localStorage.user).client_id)

      await axios
        .post("https://api.safered.co/api/updateLogoClient", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (data) {
            alert('Imagen de perfil cargada exitosamente.')
            this.formatPoints();
        })
        .catch(function (e) {
          console.log(e);
        });
      this.waitFile = true
    },
  },
  computed: {
    hasImage() {
      return !!this.image;
    },
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  }
};
/*eslint-enable */
</script>
<style>
  
</style>