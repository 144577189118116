<template>
  <div>
    <v-card
      style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
      @click="DeleteClients"
    >
      <v-img
        :src="icon_delete_user"
        alt=""
        style="width: 110px"
      />

      <v-card-title>
        <h3 style="font-size: 12px">Eliminar cliente</h3>
      </v-card-title>
    </v-card>

    <b-modal hide-footer title="Eliminar clientes" ref="my-modal-delete-client">
      <form-wizard
        shape="circle"
        title="Eliminar cliente"
        subtitle="Seleccione el cliente a eliminar"
        color="#1565c0"
        nextButtonText="Siguiente"
        backButtonText="Volver"

        finishButtonText="Eliminar"
        @on-complete="DeleteClient"
      >
        <tab-content
          title="Seleccione el cliente a eliminar"
          style="width: 100%"
          :before-change="isSelectUser"
        >
          <div class="form-group">
            <label for="selectType">Clientes</label>
            <select
              v-model="selectedClient"
              class="form-control"
              id="selectClient"
              aria-label="Default select example"
            >
              <option
                :value="{
                  id: cliente.id,
                  text:
                    cliente.nombreContacto + ' ' + cliente.apellidosContacto,
                }"
                v-for="(cliente, index) in clients"
                :key="index"
                :id="index"
              >
                {{ cliente.nombreContacto }} {{ cliente.apellidosContacto }}
              </option>
            </select>
          </div>
        </tab-content>
        
      </form-wizard>
    </b-modal>
  </div>
</template>
<script>

import icon_delete_user from '../../../../../../../assets/img/icons/icon_delete_user.svg'
import api from "@/api";

export default {
  name: "delete-client-modal",
  components: {},
  data() {
    return {
      icon_delete_user: icon_delete_user,
      formEdit: {
        id: "",
        email: "",
        name: "",
        lastName: "",
        typeClient: null,
        typeDocument: null,
        numIdClient: 0,
        checked: [],
      },
      selectedClient: "",
      typeClients: [
        { text: "Seleccionar tipo de cliente", value: null },
        "Persona Natural",
        "Persona Juridica",
      ],
      typeDocuments: [
        { text: "Seleccionar tipo de documento", value: null },
        "Cedula de ciudadania",
        "DNI",
        "Pasaporte",
        "Cedula Extranjeria",
      ],
      clientes: []
    };
  },
  props: {
    clients: [],
  },
  mounted(){
    this.clientes = this.clients
  },
  methods: {
    DeleteClient: async function () {
      api
        .request(
          "delete",
          "/deleteClientById/" + this.selectedClient.id,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if(response.status == 200){
            alert("Cliente eliminado exitosamente");
            this.$refs["my-modal-delete-client"].hide();
          }
        })
        .catch(function (e) {
          console.log(e);
        });

        this.clientes.forEach((value, key) => {
           if(value.id == this.selectedClient.id){
             this.clientes.splice(key, 1);
            }})
        
    },
    isSelectUser: async function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.selectedClient === "") {
            reject("Debe seleccionar un cliente para continuar.");
            alert("Debe seleccionar un cliente para continuar.");
          } else {
            api
              .request("get", "/getClientById/" + this.selectedClient.id)
              .then((data) => {
                if (data.data[0].tipo === "Persona natural") {
                  this.formEdit.email = data.data[0].emailContacto;
                  this.formEdit.name = data.data[0].nombreContacto;
                  this.formEdit.lastName = data.data[0].apellidosContacto;
                  this.formEdit.typeClient = data.data[0].tipo;
                  this.formEdit.numIdClient =
                    data.data[0].numeroDocumentoContacto;
                  this.formEdit.typeDocument =
                    data.data[0].tipoDocumentoContacto;
                  this.formEdit.id = data.data[0].id;
                  this.editPNatural = true;
                  this.editPJuridica = false;
                } else if (data.data[0].tipo === "Persona juridica") {
                  this.editPNatural = false;
                  this.editPJuridica = true;
                }
              })
              .catch(function (e) {
                console.log(e);
              });
            resolve(true);
          }
        }, 1000);
      });
    },
    checkChanges: function () {
      var status = true;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (status) {
            reject("Debe seleccionar un cliente para continuar.");
            alert("Debe seleccionar un cliente para continuar.");
          } else {
            resolve(true);
          }
        }, 1000);
      });
    },
    checkChangesEdit: function () {
      var status = true;
      if (
        this.formEdit.email !== "" &&
        this.formEdit.name !== "" &&
        this.formEdit.lastName !== "" &&
        this.formEdit.typeClient !== "" &&
        this.formEdit.numIdClient !== ""
      ) {
        status = false;
      } else {
        status = true;
      }
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (status) {
            reject("No puede eliminar con campos vacios.");
            alert("No puede actualizar con campos vacios.");
          } else {
            resolve(true);
          }
        }, 1000);
      });
    },
    DeleteClients: function () {
      this.$refs["my-modal-delete-client"].show();
    },
  },
};
</script>
