 export default function checkcheck (x, y, cornersX, cornersY) {

  var i, j=cornersX.length-1 ;
  var odd = false;

  var pX = cornersX;
  var pY = cornersY;

  for (i=0; i<cornersX.length; i++) {
      if ((pY[i]< y && pY[j]>=y ||  pY[j]< y && pY[i]>=y)
          && (pX[i]<=x || pX[j]<=x)) {
            odd ^= (pX[i] + (y-pY[i])*(pX[j]-pX[i])/(pY[j]-pY[i])) < x; 
      }
      j=i; 
  }

return odd;
}