<template>
    <b-modal centered id="modal-01" title="Opciones de directorio" hide-footer>
        <b-row>
            <b-col sm="6" style="display: flex; justify-content: center;">
                <b-button variant="outline-primary" @click="$bvModal.show('modal-03')">
                    Crear directorio 
                </b-button>
            </b-col>
            <b-col sm="6" style="display: flex; justify-content: center;">
                <b-button  variant="outline-primary" @click="$bvModal.show('modal-05')">
                    Cargar archivo
                </b-button>
            </b-col>
        </b-row>
        <div style="display: flex; justify-content: flex-end;">
            <v-btn
            style="margin-top: 15px;"
            depressed
            color="#F3575D"
            @click="customItemClick()"
            >
                <i style="color:white;" class="fa-solid fa-trash"></i>
            </v-btn>
        </div>
    </b-modal>
</template>
<script>

import Vue from 'vue'
import axios from "axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

export default {
    name: 'modal01-DirMake',
    components: {},
    data(){
        return{
            token: null,
            currentUser: null,
        };
    },
    async mounted() {
        this.currentUser = JSON.parse(localStorage.user)
        this.token = JSON.parse(localStorage.user).token
    },
    props: [ 'typeDirct', 'dataDelete', 'projectId' ],
    methods: {
        customItemClick: async function () {
            var sign = confirm("¿Desea eliminar este nodo?");

            if (sign) {
                this.dataDelete.e.stopPropagation();
                var index = this.dataDelete.node.parentItem.indexOf(this.dataDelete.item);
                await this.deleteNode(this.dataDelete.item.id, index);
            }
        },
        deleteNode: async function (id, index) {
            if(this.typeDirct == 'Make'){

                var token = JSON.parse(localStorage.user).token;
                const config = {
                    headers: { Authorization: `Bearer ${token}` },
                };
                let formData = new FormData();
                formData.append("fileId", id);
                formData.append("idProject", this.projectId)
                formData.append("emailClient", this.currentUser.clientInfo.emailContacto)
                

                const data = {};
                formData.forEach((value, key) => (data[key] = value));
                // Log the data.
                await axios
                    .post("https://microservices.safered.co/api/deleteComponent", formData, config, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => console.log(res))
                    .catch((e) => console.log(e));
                
                this.$store.commit("setIndexNodeDelete", index)
            }else{
                console.log(this.dataDelete.item.id)
                this.$store.commit("setDeleteFolderGhost", this.dataDelete.item.id)
            }
        },
    },
    
}
</script>