<template>
    <v-app>
        <v-app-bar absolute color="#283271" app>
          <NavbarP></NavbarP>
        </v-app-bar>

        <v-main>
            
        </v-main>

        <v-footer color="#283271" padless>
            <footerP/>
          </v-footer>
    </v-app>
</template>
<script>

// import axios from 'axios'
import footerP from "./structure_p/FooterP.vue"
import NavbarP from "./structure_p/NavbarP.vue"

export default {
    name: "recoveryPass",
    components: {
        footerP,
        NavbarP
    },
    data(){
        return{

        }
    },
    mounted() {
        console.log(this.$route.params.emailClient)
    },
}
</script>
<style>
    
</style>