<template>
    <div>
        <v-card
            style="width: 160px; heigth: 220px; display: flex; flex-direction: column; 
            align-items: center;" @click="infoProjects"
        >
            <v-img
            :src="icon_info"
            alt=""
            style="width: 110px"
            />
            <v-card-title style="padding: 16px 16px 10px 16px;">
                <h3 style="font-size: 12px; text-align: center; margin-bottom: 0px; ">
                    Información adicional del proyecto
                </h3>
            </v-card-title>
        </v-card>
        <b-modal
            class="h-50 d-inline-block"
            id="modal-lg"
            ref="my-modal-info-client"
            hide-footer
            title="Información adicional del proyecto"
            centered
            v-b-modal.modal-scrollable
            size="lg"
        >   
            <div v-if="!selectData">
                <v-row style="justify-content: center; margin-top: 30px; margin-bottom: 30px;">
                    <v-col cols="8">
                        <v-select
                        v-model="selectProject"
                        :items="projectsFormat"
                        label="Seleccionar proyecto"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row style="justify-content: flex-end;">
                    <v-col cols="2" style="display: flex;
                        justify-content: flex-start;
                        flex-direction: column;">
                        <v-btn
                            depressed
                            color="#3471C5"
                            style="color: white;"
                            @click="selectP()"
                        >
                            Siguiente
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-if="selectData">
                <v-row style="display: flex;
                    justify-content: flex-end;">
                    <v-btn
                        v-b-modal.add-info
                        class="mx-2"
                        fab
                        dark
                        color="#7072C7"
                        v-b-modal.addCarateristica
                    >
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-row>
                <v-container :class="verify ? '' : 'containerW'" style="height: 300px">
                    <i v-if="!verify" style="margin-bottom: 3px;" class="fa-solid fa-circle-info"></i>
                    <p v-if="!verify" style="margin-bottom: 0px; margin-left: 10px;">No se encuentra datos</p>
                    <v-col v-if="verify">
                        <div style="overflow-x: hidden;
                            overflow-y: scroll;
                            height: 280px;">
                            <v-list two-line>
                                <v-list-item-group
                                    v-model="selected"
                                    multiple
                                >
                                <template v-for="(item, index) in arrayQuestions" >
                                    <v-list-item v-if="item != null" :key="index+'_'+item">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="'Titulo: '+item"></v-list-item-title>
                                            <v-list-item-subtitle
                                                class="text--primary"
                                                v-text="arrayResponses[index]"
                                            ></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider
                                        v-if="index < arrayQuestions.length - 1"
                                        :key="index"
                                    ></v-divider>
                                </template>
                                </v-list-item-group>
                            </v-list>
                        </div>
                    </v-col>
                </v-container>
            </div>
            <b-modal id="addCarateristica" hide-footer 
                centered hide-backdrop>
                <v-text-field v-model="title" label="Titulo de la información"></v-text-field>
                <v-textarea v-model="detail" label="Descripción o contenido"></v-textarea>
                <v-select
                    v-model="typeData"
                    :items="itemsData"
                    label="Tipo de dato"
                ></v-select>
                <v-btn
                    style="color: white;"
                    elevation="2"
                    color="#53B053"
                    @click="sendDataInfi()"
                >
                    Agregar
                </v-btn>
            </b-modal>
        </b-modal>
    </div>
</template>
<script>
    import api from "@/api";
    import icon_info from '../../../../../../../assets/img/icons/icon_info_project.svg'

export default {
    name: "info-project-modal",
    data(){
        return{
            selectProject: null,
            projectsFormat: [],
            arrayQuestions: [],
            arrayResponses: [],
            arrayTypeData:[],
            selected: '',
            icon_info,
            itemsData: [
                {text: "Cadena de texto", value: 'string'},
                {text: "Numerico", value: 'int'},
                {text: "Fecha", value: 'Date'}
            ],
            dataInfoAdc: [],
            title: '',
            detail: '',
            typeData: '',
            verify: false,
            selectData: false,
        }
    },
    props: [ 'projects' ],
    async mounted() {
        this.verifyContent();
        this.formatProject();
    },
    methods: {
        selectP: async function(){
            this.selectData = true;
            var self = this
            console.log()
            await api
                .request(
                    "post",
                    "/getBDProject",{
                        idProject: this.selectProject
                    }
                )
                .then((data) => {
                    if(data.status == 200){
                        self.arrayQuestions = JSON.parse(data.data[0].arrayQuestions)
                        self.arrayResponses = JSON.parse(data.data[0].arrayResponses)
                        self.arrayTypeData = JSON.parse(data.data[0].arrayTypeData)

                        console.log(self.arrayQuestions)
                    }
                })
                .catch(function (e) {
                    console.log(e);
                });
            this.verifyContent()
        },
        formatProject: async function (){
            for (let property1 in this.projects) {
                var tmp_obj = null
                tmp_obj = {
                    value: this.projects[property1][0].id,
                    text: this.projects[property1][0].name,
                };        
                this.projectsFormat.push(tmp_obj)
            }
        },
        infoProjects: function () {
            this.$refs["my-modal-info-client"].show();
        },
        sendDataInfi: async function(){
            this.arrayQuestions.push(this.title)
            this.arrayResponses.push(this.detail)
            this.arrayTypeData.push(this.typeData)
            
            await this.sendData();
            this.verifyContent();
        },
        sendData: async function(){
            var obj = {
                idUser: JSON.parse(localStorage.user).id,
                arrayQuestions: JSON.stringify(this.arrayQuestions),
                arrayResponses: JSON.stringify(this.arrayResponses),
                arrayTypeData: JSON.stringify(this.arrayTypeData),
                idProject: this.selectProject
            }
            console.log(obj)
            await api
                .request(
                    "post",
                    "/setBDProject",
                    obj
                )
                .then((data) => {
                    if(data.status == 200){
                        console.log(data)
                    }
                })
                .catch(function (e) {
                    console.log(e);
                });
        },
        verifyContent(){
            if(this.arrayQuestions.length > 0){
                this.verify = true
            }else{
                this.verify = false
            }
        }
    },
}
</script>
<style>
.containerW {
    border: 1px dashed rgba(0,0,0, .4);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
</style>