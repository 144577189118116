var axios = require('axios')

module.exports = {
  getToken: async function (email, password) {

    var token = null

    await axios
      .post("https://microservices.safered.co/api/signIn", { email, password })
      .then(function(data){
          token = data.data
      })
      .catch(function () {
        token = {
          token: null,
          user:{
            id: null,
          }
        }
      });
    return token
  },
  getRegister: async function(name, email, password, id_safered){
    var register = null
    let nameM = await name.split(" ").join("")
    var obj = {
      name: nameM.replace(/[^a-zA-Z]/g, ''),
      email,  
      password,
      id_safered
    }

    await axios
      .post("https://microservices.safered.co/api/signUp", obj)
      .then(function(){
        register = true
      })
      .catch(function () {
        register = false
      });

    return register
  },
  registerUser(email,password){
    console.log(email+"--"+password)
    return "email+--+password"
  }
}