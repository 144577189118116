<template>
    <div>
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="8" v-if="activeCre">
                        <v-card
                        style="margin-top: 18px"
                        class="mx-auto"
                        height="450"
                        >
                            <v-row style="display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;">
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="nameCode"
                                        label="Ingrese codigo"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4" style="display: flex;
                                justify-content: center;
                                align-items: flex-end;">
                                    <template>
                                        <v-btn v-if="folderSelectN.length == 0" block v-b-modal.modal-SelectFile>
                                            No se ha seleccionado carpeta
                                        </v-btn>
                                        <v-btn v-if="folderSelectN.length > 0" block v-b-modal.modal-SelectFile>
                                            Carpeta seleccionada: {{folderSelectN}}
                                        </v-btn>

                                        <b-modal hide-footer id="modal-SelectFile" :title="`Seleccionar carpeta para: ${nameCode}`">
                                            <v-responsive
                                                height="240"
                                                style="overflow: scroll;"
                                                class="overflow-y-auto"
                                                max-height="250"
                                            >
                                                <v-treeview
                                                v-model="treeTest"
                                                :open="initiallyOpen"
                                                :items="folderGoogle"
                                                activatable
                                                item-key="text"
                                                item-text="text"
                                                >
                                                <template v-slot:prepend="{item, open}">
                                                    <v-icon @click="selectFolderGoogle = item.id; $bvModal.hide('modal-SelectFile');
                                                    folderSelectN = item.text" >
                                                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                                                    </v-icon>
                                                </template>
                                                </v-treeview>
                                            </v-responsive>
                                        </b-modal>
                                    </template>
                                </v-col>
                                <v-col cols="2" style="display: flex;
                                justify-content: center;
                                align-items: center;">
                                    <v-btn
                                    class="mx-2"
                                    small
                                    fab
                                    dark
                                    color="light-green darken-4"
                                    @click="createCode();"
                                    >
                                        <v-icon dark>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-responsive
                                class="overflow-y-auto"
                                max-height="400"
                            >
                                <v-list-item
                                    v-for="(codeP, index) in listCodeP"
                                    :key="index"
                                >
                                    <v-list-item-avatar>
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-code-braces
                                        </v-icon>
                                    </v-list-item-avatar>
                            
                                    <v-list-item-content>
                                        <v-list-item-title v-text="codeP.code"></v-list-item-title>
                                
                                        <v-list-item-subtitle v-text="codeP.createdAt"></v-list-item-subtitle>
                                    </v-list-item-content>
                            
                                    <v-list-item-action>
                                        <v-btn icon>
                                            <v-icon color="grey lighten-1">mdi-information</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-responsive>
                        </v-card>  
                    </v-col>
                    <v-col cols="8" v-if="activeEdi">
                        <v-card
                        style="margin-top: 18px"
                        class="mx-auto"
                        height="450"
                        >
                            <v-row style="display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;">
                                <v-col cols="6">
                                    <v-text-field
                                        label="Ingrese codigo"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" style="display: flex;
                                justify-content: center;
                                align-items: center;">
                                    <v-btn
                                    class="mx-2"
                                    small
                                    fab
                                    dark
                                    color="light-green darken-4"
                                    >
                                        <v-icon dark>
                                            mdi-magnify
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-responsive
                                class="overflow-y-auto"
                                style="margin-top: 22px;"
                                max-height="400"
                            >
                                <v-list-item
                                    v-for="(codeP, index) in listCodeP"
                                    :key="index"
                                >
                                    <v-list-item-avatar>
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-code-braces
                                        </v-icon>
                                    </v-list-item-avatar>
                            
                                    <v-list-item-content>
                                        <v-list-item-title v-text="codeP.code"></v-list-item-title>
                                
                                        <v-list-item-subtitle v-text="codeP.createdAt"></v-list-item-subtitle>
                                    </v-list-item-content>
                            
                                    <v-list-item-action>
                                        <v-btn icon @click="$bvModal.show(`modal-codeEdit-${index}`)">
                                            <v-icon color="grey lighten-1"
                                            >mdi-lead-pencil</v-icon>
                                        </v-btn>
                                    </v-list-item-action>

                                    <b-modal :id="`modal-codeEdit-${index}`" size="lg" hide-footer>
                                        <v-row style="justify-content: space-evenly;">
                                            <v-col cols="4" style="display: flex; text-align: center;
                                            align-items: center;">
                                                Codigo: {{codeP.code}}
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="idSelect"
                                                    :items="folderGoogle"
                                                    label="Asignar carpeta"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="1"></v-col>
                                            <v-col cols="4">
                                                <v-btn
                                                    color="blue lighten-4"
                                                    class="ma-2 white--text"
                                                    @click="editCode(codeP.id)"
                                                >
                                                Guardar
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </b-modal>

                                </v-list-item>
                            </v-responsive>
                        </v-card>  
                    </v-col>
                    <v-col cols="8" v-if="activeDel">
                        <v-card
                        style="margin-top: 18px"
                        class="mx-auto"
                        height="450"
                        >
                            <v-row style="display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;">
                                <v-col cols="6">
                                    <v-text-field
                                        label="Ingrese codigo"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2" style="display: flex;
                                justify-content: center;
                                align-items: center;">
                                    <v-btn
                                    class="mx-2"
                                    small
                                    fab
                                    dark
                                    color="light-green darken-4"
                                    >
                                        <v-icon dark>
                                            mdi-magnify
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-responsive
                                class="overflow-y-auto"
                                style="margin-top: 22px;"
                                max-height="400"
                            >
                                <v-list-item
                                    v-for="(codeP, index) in listCodeP"
                                    :key="index"
                                >
                                    <v-list-item-avatar>
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-code-braces
                                        </v-icon>
                                    </v-list-item-avatar>
                            
                                    <v-list-item-content>
                                        <v-list-item-title v-text="codeP.code"></v-list-item-title>
                                
                                        <v-list-item-subtitle v-text="codeP.createdAt"></v-list-item-subtitle>
                                    </v-list-item-content>
                            
                                    <v-list-item-action>
                                        <v-btn icon @click="$bvModal.show(`modal-codeDelete-${index}`)">
                                            <v-icon color="grey lighten-1">mdi-delete-empty</v-icon>
                                        </v-btn>

                                        <b-modal :id="`modal-codeDelete-${index}`" size="lg" hide-footer>
                                            <v-row>
                                                <v-col cols="8" style="display: flex;
                                                justify-content: center;
                                                align-items: center;">
                                                    {{"Desea eliminar este codigo? Confirme haciendo click en la casilla: "}}
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-checkbox
                                                    v-model="confirmDelete"
                                                    label="Confirmar"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="1"></v-col>
                                                <v-col cols="4">
                                                    <v-btn
                                                        :disabled="!confirmDelete"
                                                        style="color: white;"
                                                        color="#F44336"
                                                        class="ma-2 white--text"
                                                        @click="deleteCode(codeP.id)"
                                                    >
                                                    Eliminar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </b-modal>

                                    </v-list-item-action>
                                </v-list-item>
                            </v-responsive>
                        </v-card>  
                    </v-col>
                    <v-col cols="2"></v-col>
                </v-row> 
                <v-row>
                    <v-card
                        style="display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 18px;"
                        class="mx-auto"
                        height="60"
                        max-width="600"
                    >
                        <v-slide-group
                        multiple
                        show-arrows
                        >
                            <v-slide-item
                            >
                                <v-btn
                                class="mx-2"
                                :class="activeCre ? `blue-grey white--text` : ``"
                                depressed
                                rounded
                                @click="activeCre = true; countCre = countCre+1"
                                >
                                Crear codigo
                                </v-btn>
                            </v-slide-item>
                            <v-slide-item
                            >
                                <v-btn
                                class="mx-2"
                                :class="activeEdi ? `blue-grey white--text` : ``"
                                depressed
                                rounded
                                @click="activeEdi = true; countEdi = countEdi+1"
                                >
                                Editar codigo
                                </v-btn>
                            </v-slide-item>
                            <v-slide-item
                            >
                                <v-btn
                                class="mx-2"
                                :class="activeDel ? `blue-grey white--text` : ``"
                                depressed
                                rounded
                                @click="activeDel = true; countDel = countDel+1"
                                >
                                Eliminar codigo
                                </v-btn>
                            </v-slide-item>
                        </v-slide-group>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>

import axios from "axios";

export default {
    name: "confCodeEA",
    data(){
        return{
            activeCre: true,
            activeEdi: false,
            activeDel: false,
            countEdi: 0,
            countDel: 0,
            countCre: 0,
            token: null,
            nameCode: '',
            idSelect: null,
            confirmDelete: false,
            folderSelectN: '',
            initiallyOpen: [],
            treeTest: [],
            selectFolderGoogle: ''
        }
    },
    mounted() {
        this.token = JSON.parse(localStorage.user).token;
    },
    props: [ 'projectId', 'folderGoogle', 'listCodeP' ],
    methods: {
        createCode: async function(){
            var obj = {
                code: this.nameCode,
                idProject: this.projectId,
                idFolder: this.idSelect
            };

            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };

            await axios
                .post("https://microservices.safered.co/api/createCodePath", obj, config)
                .then(function (data) {
                    console.log(data)
                })
                .catch(function (e) {
                    console.log(e.message);
                });

        },
        editCode: async function(id){
            var obj = {
                id,
                idFolder: this.idSelect
            };

            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };

            await axios
                .post("https://microservices.safered.co/api/editCodePath", obj, config)
                .then(function (data) {
                    console.log(data)
                })
                .catch(function (e) {
                    console.log(e.message);
                });

        },
        deleteCode: async function(id){
            var obj = {
                id
            };

            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };

            await axios
                .post("https://microservices.safered.co/api/deleteCode", obj, config)
                .then(function (data) {
                    console.log(data)
                })
                .catch(function (e) {
                    console.log(e.message);
                });

        }
    },
    watch:{
        countEdi: function(){
            this.activeCre = false
            this.activeDel = false
        },
        countDel: function(){
            this.activeCre = false
            this.activeEdi = false
        },
        countCre: function(){
            this.activeEdi = false
            this.activeDel = false
        }
    }
}
</script>
<style>
    
</style>