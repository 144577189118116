<template>
    <div class="center grid">
      <b-row>
        <b-col sm="1">
        </b-col>
        <b-col sm="10">
          <b-card style="margin-top: 29px; margin-bottom: 30px;">
            <div style="height: 90px;">
            </div>
              <b-row v-if="dataReady">
                <b-col sm="2">

                </b-col>
                <b-col sm="2" >
                  <createproject-modal :key="keyP" :users="users" id="card_style"/>
                </b-col>
                <b-col sm="2">
                  <editproject-modal
                    :key="keyP"
                    :projects="projects"
                    :dataBusiness="dataBusiness"
                    :users="users"
                    id="card_style"
                  />
                </b-col>
                <b-col sm="2">
                  <deleteproject-modal :key="keyP" :projects="projects" id="card_style"/>
                </b-col>
                <b-col sm="2">
                  <infoprojectModal :projects="projects" id="card_style"/>
                </b-col>
                <b-col sm="2">

                </b-col>
              </b-row>
            <div style="height: 110px;">
            </div>
          </b-card>
        </b-col>
        <b-col sm="1">
        </b-col>
      </b-row>
    </div>
</template>
<script>

  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import editprojectModal from "./modals/EditProjectModal.vue";
  import createprojectModal from "./modals/CreateProjectModal.vue";
  import deleteprojectModal from "./modals/DeleteProjectModal.vue";
  import infoprojectModal from "./modals/InfoAdcProjectModal.vue"

  import api from "@/api";
  
  export default {
    name: "projectAdm",
    components: {
      editprojectModal,
      createprojectModal,
      deleteprojectModal,
      infoprojectModal,
    },
    data() {
      return {
        projects: [],
        dataReady: false,
        users: [],
        keyP: 0,
        dataBusiness: [],
      };
    },
    async mounted() {
      this.sizeChange();
      await this.getprojects();
      await this.getUsers();
      await this.getBusiness()
      this.dataReady = true
    },
    methods: {
      async getBusiness(){
        var self = this

        var obj = {
          idUser: JSON.parse(localStorage.user).id
        }

        await api
        .request("post", "/showCompany", obj)
        .then((data) => {
          console.log(data)
          self.dataBusiness = data.data
        })
        .catch(function (error) {
          console.log(error)
        });
        self.dataBusiness.push({
          name: JSON.parse(localStorage.user).nameCompany,
          id: 0
        })
      },
      sizeChange(){
        var data = {
          namepage: "users",
          sizePageChange: false,
        }
        this.$store.commit("setNovedadesDash", data);
      },
      getprojects: async function () {
        var projectsTmp = [];
        this.projects = []

        await api
          .request(
            "post",
            "/getProjectsForUser/" + JSON.parse(localStorage.user).id
          )
          .then((data) => {
            projectsTmp = data.data;
          })
          .catch(function (e) {
            console.log(e);
          });
        console.log(projectsTmp)
        this.projects = projectsTmp;
      },
      getUsers: async function () {
        var clientsTmp = []
        this.users = []

        await api
          .request(
            "get",
            "/listUsers/" + JSON.parse(localStorage.user).client_id + "/clients"
          )
          .then((data) => {
            clientsTmp = data.data.users;
          })
          .catch(function (e) {
            console.log(e);
          });
  
        var itemNull = { value: null, text: "Por favor seleccione un usuario" };
        clientsTmp.forEach((element) => {
          if (JSON.parse(localStorage.user).id != element.id) {
            var tmp_obj = {
              value: element.id,
              text: element.name,
            };
            this.users.push(tmp_obj);
          }
        });
        this.users.unshift(itemNull);
      },
    },
    watch:{
      '$store.state.refreshProject': async function(){
        this.keyP = this.$store.state.refreshProject
        this.dataReady = false
        await this.getUsers()
        await this.getprojects()
        this.dataReady = true
      }
    }
  };
  </script>