<template>
    <v-row style="background-color: #E4E4E4;">
        <v-col cols="1"></v-col>
        <v-col cols="10">
            <v-card height="380" style="margin-top: 35px; margin-bottom: 35px; display: flex; 
                flex-direction: row; align-items: center;">
                <v-row >
                    <v-col cols="4">
                        <v-card
                            id="card_st"
                            elevation="2"
                            style="margin-left: 2px; height: 220px"
                        >
                            <v-col cols="12" style="display: flex; flex-direction: column; align-items: center;">
                                <h5 style="margin-bottom: 40px;">Registros de usuarios</h5>
                                <p style="font-size: 13px; text-align: justify;">
                                    Descargar la información de base de datos de todos los archivos del cliente, en archivo con formato .sql
                                </p>

                                <b-button style="color: white; margin-top: 20px;" variant="info" @click="downUsu(idUser, idClient)">Descargar</b-button>
                            </v-col>
                        </v-card>
                    </v-col>

                    <v-col cols="4">
                        <v-card
                            id="card_st"
                            elevation="2"
                            style="height: 220px"
                        >
                            <v-col cols="12" style="display: flex; flex-direction: column; align-items: center;">
                                <h5 style="margin-bottom: 40px;">Registros de proyectos</h5>
                                <p style="font-size: 13px; text-align: justify;">
                                    Descargar la información de base de datos de todos los proyectos del cliente, en archivo con formato .sql
                                </p>

                                <b-button style="color: white; margin-top: 20px;" variant="info" @click="downProy(idUser, idClient)">Descargar</b-button>
                            </v-col>
                        </v-card>
                    </v-col>

                    <v-col cols="4">
                        <v-card
                            id="card_st"
                            elevation="2"
                            style="margin-right: 2px; height: 220px"
                        >
                            <v-col style="display: flex; flex-direction: column; align-items: center;">
                                <h5 style="margin-bottom: 40px;">Registro General</h5>
                                <p style="font-size: 13px; text-align: justify;">
                                    Descargar toda la información de base de datos del cliente, en archivo con formato .sql. 
                                </p>

                                <b-button style="color: white;" variant="info" @click="downSys(idUser, idClient)">Descargar</b-button>
                            </v-col>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="1"></v-col>
    </v-row>
</template>
<script>

import axios from 'axios'

export default {
    name: "exportEmpresa",
    data() {
        return {
            idUser: JSON.parse(window.localStorage.user).id,
            idClient: JSON.parse(window.localStorage.user).client_id
        };
    },
    methods:{
        downUsu: async function(idUser, idClient){
            var obj = {
                idUser, 
                idClient
            }
            console.log(obj)
            await axios.post("https://api.safered.co/api/downloadBackupUsers", obj, {
                headers: {
                "Content-Type": "application/json",
                },
                }).then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", 'BackupUsuarios');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
        },
        downProy: async function(idUser, idClient){
            var obj = {
                idUser, 
                idClient
            }
            console.log(obj)
            await axios.post("https://api.safered.co/api/downloadBackupProject", obj, {
                headers: {
                "Content-Type": "application/json",
                },
                }).then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", 'BackupProyectos');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
        },
        downSys: async function(idUser, idClient){
            var obj = {
                idUser, 
                idClient
            }
            console.log(obj)
            await axios.post("https://api.safered.co/api/downloadBackup", obj, {
                headers: {
                "Content-Type": "application/json",
                },
                }).then((response) => {

                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", 'BackupGeneral');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
        }
    }
}
</script>
<style>
    .divider{
        position:absolute;
        left:50%;
        top:10%;
        bottom:10%;
        border-left:2px solid gray;
    }
</style>