<template>
    <v-card  :style="finishLoad ? 'background-color: #F2F2F2; display: flex; display: flex; justify-content: center;' : 'background-color: #F2F2F2; display: flex;'">
        <v-item-group class="pa-2">
            <v-row v-if="finishLoad">
                <div style="padding-top: 5px;" class="text-center">
                    <v-progress-circular
                      :rotate="360"
                      :size="60"
                      :width="8"
                      :value="valueLoad"
                      color="#283271"
                    >
                      {{ valueLoad }}
                    </v-progress-circular>
                    <h6 style="margin-top: 5px;">Cargando información</h6>
                </div>
            </v-row>
            <v-row v-if="!finishLoad">
                <v-col style="background-color: #5c708f; padding: 0px;">
                    <v-item>
                        <div style="display: flex;">
                            <v-card
                                @click="viewGeo()"
                                width="70"
                                height="80"
                                class="ma-4"
                            >
                                
                                <v-badge
                                    color="error"
                                    :content="dataGeo.length > 0 ? dataGeo.length : '0'"
                                    offset-x="8"
                                    offset-y="20"
                                >
                                    <v-img
                                        style="margin-left: 10px; margin-top: 3px;"
                                        max-height="50"
                                        max-width="50"
                                        :src="logoGPS"
                                    >
                                    </v-img>
                                </v-badge>
                                <infinite-slide-bar>
                                    <h6 class="marquee">
                                        Georreferenciado &nbsp;
                                    </h6>
                                </infinite-slide-bar>
                            </v-card>
                            <vistaGeo :dataGeo="dataGeo" :orgdataGeo="orgdataGeo"
                            :permitEdit="permitEdit" v-if="canvasGeo" :viewPrincipal="viewPrincipal"/>
                        </div>
                    </v-item>
                </v-col>
                <v-col style=" background-color: #d5d4cf; padding: 0px;">
                    <v-item>
                        <div style="display: flex;">
                            <v-card
                                @click="viewMpbits()"
                                width="70"
                                height="80"
                                class="ma-4"
                            >
                                
                                <v-badge
                                    color="error"
                                    :content="dataMpBits.length > 0 ? dataMpBits.length : '0'"
                                    offset-x="8"
                                    offset-y="20"
                                >
                                    <v-img
                                        style="margin-left: 10px; margin-top: 3px;"
                                        max-height="50"
                                        max-width="50"
                                        :src="logoMP"
                                    >
                                    </v-img>
                                </v-badge>
                                <infinite-slide-bar>
                                    <h6 class="marquee">
                                        Mapa de Bits &nbsp;
                                    </h6>
                                </infinite-slide-bar>
                            </v-card>
                            <vistaMBits :permitEdit="permitEdit" :dataMpBits="dataMpBits" v-if="canvasMBits"/>
                        </div>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </v-card>
</template>
<script>

import vistaGeo from './VistasGeoreferenciado.vue'
import vistaMBits from './VistasMapaBits.vue'
import logoGPS from '../../../../../assets/img/icons/icon_card_georeferenciado.svg'
import logoMP from '../../../../../assets/img/icons/icon_card_mapabits.svg'
import logoDir from '../../../../../assets/img/icons/icon_card_direct.svg'


export default {
    name: "canvasSubCanvas",
    components:{
        vistaGeo,
        vistaMBits
    },
    data(){
        return{
            logoDir,
            logoMP,
            logoGPS,
            canvasGeo: false,
            canvasMBits: false,
            directActvie: false,
            NMpbits: 1,
        }
    },
    props:['projects', 'dataGeo', 'dataMpBits', 'permitEdit', 'finishLoad', 'valueLoad', 
        'orgdataGeo', 'viewPrincipal'],
    mounted: async function(){
        console.log(this.dataGeo)
    },
    methods:{
        viewGeo(){
            
            if(this.canvasGeo == false){
                this.canvasGeo = true
                this.canvasMBits = false
                this.directActvie = false

            }else{
                this.canvasGeo = false
            }

        },
        viewMpbits(){
            if(this.canvasMBits == false){
                this.canvasGeo = false
                this.canvasMBits = true
                this.directActvie = false
            }else{
                this.canvasMBits = false
            }
        }
    },
    watch:{
        '$store.state.restoreInit': function(){
            this.canvasGeo = false
            this.canvasMBits = false
            this.NMpbits = 1
        },
        '$store.state.selectionCanvas': function(){
            this.NMpbits = 2
        },
        '$store.state.selectionCanvasMBits': function(){
            this.NMpbits = 2
        },
    }
}
</script>
<style>
    .marquee{
        font-size: 0.95rem!important;
        font-weight: 100!important;
    }
</style>