<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :retain-focus="false"
      persistent
    >
        <v-card>
            <v-toolbar
            dark
            color="#283271"
            >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Opciones</v-toolbar-title>
            <v-spacer></v-spacer>
            </v-toolbar>
            <v-card>
                <v-tabs color="#5c708f" left>
                    <v-tab v-if="idProject == 4">Config. Alarmas</v-tab>
                    <v-tab>Config. Tipo de Documentos</v-tab>
                    <v-tab>Config. Codigos de estructura de archivos</v-tab>
                    <v-tab>Config. Privacidad Canvas</v-tab>
                    <v-tab>Config. Privacidad Documentos</v-tab>

                    <v-tab-item v-if="idProject == 4">
                        <confAlarmas />
                    </v-tab-item>
                    <v-tab-item>
                        <confTipoDoc :listTags="listTags"/>
                    </v-tab-item>
                    <v-tab-item>
                        <confCodeEA :projectId="idProject" :listCodeP="listCodeP" :folderGoogle="folderGoogle"/>
                    </v-tab-item>
                    <v-tab-item>
                        <confPrivaciCan :idProject="idProject" :dataGeo="dataGeo"/>
                    </v-tab-item>
                    <v-tab-item>
                        <confPrivaciDoc :idProject="idProject" :itemsGoogle="itemsGoogle"/>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-card>
    </v-dialog>
</template>
<script>

import confAlarmas from './ConfigAlarmas.vue'
import confTipoDoc from './ConfigTipoDocumentos.vue'
import confCodeEA from './ConfigCodigoEA.vue'
import confPrivaciCan from './ConfigPrivacidadCanvas.vue'
import confPrivaciDoc from './ConfigPrivacidadDoc.vue'

export default {
    name: "opcionesG",
    components:{
        confAlarmas,
        confTipoDoc,
        confCodeEA,
        confPrivaciCan,
        confPrivaciDoc
    },
    data(){
        return{
            dialog: false,
            listTags: [],
        }
    },
    mounted() {
        this.token = JSON.parse(localStorage.user).token
    },
    props: [ 'activeOpciones', 'dataGoogle', 'idProject', 'folderGoogle', 'itemsGoogle', 'listCodeP', 'dataGeo' ],
    methods: {
        
    },
    watch: {
        activeOpciones: function(){
            this.dialog = this.activeOpciones
        },
        dialog: function(){
            if(this.dialog == false){
                this.$store.commit("setModalOpciones", false)
            }
        },
        '$store.state.datalistTag': function(){
            this.listTags = this.$store.state.datalistTag
        },
    }
}
</script>
<style>
    
</style>