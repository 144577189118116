<template>
  <div>
    <v-card
      style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
      @click="EditProjects"
    >
      <v-img
        :src="icon_create"
        alt=""
        style="width: 110px"
      />
      <v-card-title>
        <h3 style="font-size: 12px">Editar proyecto</h3>
      </v-card-title>
    </v-card>
    <!-- Modal -->
    <b-modal
      class="h-50 d-inline-block"
      id="modal-xl"
      ref="my-modal-add-client"
      hide-footer
      title="Editar Proyecto"
      centered
      v-b-modal.modal-scrollable
      button-size="sm"
    >
      <form-wizard
        shape="circle"
        title="Proyectos - SAFE RED"
        subtitle="Editar proyectos"
        color="#1565c0"
        nextButtonText="Siguiente"
        backButtonText="Volver"
        finishButtonText="Actualizar"
        @on-complete="updateProject"
      >
        <tab-content title="Seleccionar proyecto" style="width: 100%" :before-change="validateProject">
          <div class="form-group">
            <v-select
              v-model="selectedProject"
              :items="projectsFormat"
              label="Seleccionar proyecto"
            ></v-select>
          </div>
        </tab-content>
        <tab-content title="Editar información general del proyecto" style="width: 100%">
          <div class="form-group">
            <v-text-field
              label="Nombre del proyecto"
              v-model="infoProject.nameProject"
              hide-details="auto"
              id="nameClientInput"
              placeholder="Escriba el nombre del proyecto"
            ></v-text-field>
          </div>
          <b-row>
            <b-col sm="6">
              <label style="margin-top: 15px;" for="selectUsers">Canvas</label>

              <v-list-item>
                <v-checkbox value="api-geo" color="#195BFF" v-model="infoProject.canvas" label="Canvas Georeferenciado" />
              </v-list-item>
              <v-list-item two-line>
                <v-checkbox value="back-img" color="#195BFF" v-model="infoProject.canvas" label="Canvas Mapa de Bits" />
              </v-list-item>
              <v-list-item four-line>
                <v-checkbox value="directory" color="#195BFF" v-model="infoProject.canvas" label="Estructura de directorio  "/>
              </v-list-item>
            </b-col>
            <b-col sm="6" style="display: flex; flex-direction: column;justify-content: center;">
              <v-card>
                <v-img @click="active_2=!active_2" :src="existProfie ?  imageForm : logo_safe" alt="foto de project" :style="'width: 300px;'" />
              </v-card>
            </b-col>
          </b-row>
          <v-textarea v-model="infoProject.description" label="Descripción"></v-textarea>
        </tab-content>
        <tab-content
          title="Editar propiedades del proyecto"
          style="width: 100%"
        > 
        <v-list-item>
            <v-list-item-content>
              <v-file-input
                id="file-storage"
                label="Archivo de config. para nubes"
                v-model="infoProject.fileConfig"
                :state="Boolean(infoProject.fileConfig)"
              ></v-file-input>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="4">
                  <b-form-group label="Tamaño de disco">
                  </b-form-group>
                </v-col>
                <v-col cols="8">
                  <b-form-input
                    id="range-1"
                    v-model="infoProject.sizeDisk"
                    type="range"
                    min="1"
                    max="1000"
                  ></b-form-input>
                  {{ infoProject.sizeDisk }} GB
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="4">
                  <b-form-group label="Tamaño máximo de archivos">
                  </b-form-group>
                </v-col>
                <v-col cols="8">
                  <b-form-input
                    id="range-1"
                    v-model="infoProject.sizeFile"
                    type="range"
                    min="1"
                    max="1000"
                  ></b-form-input>
                  {{ infoProject.sizeFile }} GB
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item> -->
          
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="3">
                  <b-form-group label="Directorio Raíz">
                  </b-form-group>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="infoProject.path"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-combobox
                v-model="selectBusiness"
                :items="dataBusiness"
                label="Seleccione la empresa a asignar"
                item-text="name"
                required
              ></v-combobox>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="3">
                  <b-form-group label="Tipos de archivos">
                  </b-form-group>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    label="Escríbalos separado por coma, si no desea fijar deje en blanco"
                    v-model="infoProject.typeFiles"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

        </tab-content>
        <tab-content title="Usuarios" style="width: 100%">
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="6">
                <v-list style="height: 180px; overflow-x: hidden; overflow-y: scroll;" flat>
                  <v-list-item
                    v-for="(value, i) in viewSelectUsers"
                    :key="i"
                  >
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="8">
                          <v-list-item-title v-text="value.name"></v-list-item-title>
                        </v-col>
                        <v-col cols="4">
                          <b-button pill variant="outline-danger" size="sm" @click="deleteUserForProject(value)">x</b-button>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </b-col>
              <b-col cols="6">
                <v-select
                  v-model="selectedUser"
                  :items="users"
                  label="Asignar usuario manualmente"
                ></v-select>
                <b-button pill variant="outline-secondary" @click="setUser">
                  Agregar
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </tab-content>
      </form-wizard>
    </b-modal>

    
    <b-modal title="Cargar imagen" hide-footer v-model="active_2">
      <v-card-text>
          <b-form @submit.stop.prevent="onSubmit">
            <div class="d-flex mb-3" style="align-items: center;">
              <v-file-input
                v-model="image"
                chips
                accept="image/png, image/jpeg, image/bmp"
                label="Seleccionar una imagen"
              ></v-file-input>
              <v-btn
                v-if="hasImage"
                depressed
                color="#FF0000"
                @click="clearImage"
                style="color: white;"
               >
                Limpiar imagen
              </v-btn>
            </div>

            <b-img
              v-if="hasImage"
              :src="imageSrc"
              class="mb-3"
              fluid
              block
              rounded
              :style="'height: 250px; width: auto;'"
            ></b-img>

            <b-button :disabled="!hasImage" variant="primary" type="submit"
              @click="setImage(); active_2 = false" style="color: white;">
              Cargar imagen
            </b-button>
          </b-form>
      </v-card-text>
    </b-modal>

  </div>
</template>
<script>
/*eslint-disable */
import icon_create from '../../../../../../../assets/img/icons/icon_create_project.svg'
import logo_safe from '../../../../../../../assets/img/logos/safered-logo-01.svg'
import axios from "axios";
import api from "@/api";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "edit-project-modal",
  components: {},
  data() {
    return {
      icon_create: icon_create,
      logo_safe: logo_safe,
      imageSrc: null,
      image: null,
      active_2: false,
      selectedProject: null,
      existProfie: false,
      imageForm: null,
      projectsFormat: [],
      infoProject:{
        path: '',
        id:'',
        nameProject: '',
        canvas: [],
        fileConfig: '',
        sizeDisk: '1',
        sizeFile:'1',
        typeFiles:[],
        selectedUsers:[],
        users:[],
        description: '',
      },
      selectedUser: null,
      viewSelectUsers: [],
      fileReader: '',
      projectD: null,
      selectBusiness: null
    };
  },
  props: {
    projects: {},
    users: [],
    dataBusiness: [],
  },
  computed: {
    hasImage() {
      return !!this.image;
    },
  },
  mounted() {
    this.formatProject()
    this.projectCloudD()
  },
  methods: {
    projectCloudD: async function(){
      var token = JSON.parse(localStorage.user).token;
      var dataP = null;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var id_cloudD = JSON.parse(localStorage.user).id_cloudD

      await axios
        .get("https://microservices.safered.co/api/projects", config)
        .then(function (data) {
          dataP = data.data
        })
        .catch(function (e) {
          console.log(e);
        });
      
      
      this.projectD = dataP.filter(data => data.userId == id_cloudD);
      console.log(this.projectD);
    },
    async setImage() {
      let URL = window.URL;
      this.imageForm = URL.createObjectURL(this.image);
      if(this.existProfie == false){
        this.uploadDocumentsP();
      }else{
        this.updateIconProject();
      }
    },
    cloudataInfo: async function(){
      var token = JSON.parse(localStorage.user).token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      
      console.log(this.projectD);
      var resultNameP = this.projectD.filter(project => project.name == this.infoProject.nameProject)
      console.log(resultNameP)      

      var obj = {
        config: this.fileReader.result,
        userId: JSON.parse(localStorage.user).id_cloudD,
        projectId: resultNameP[0].id,
        idFolder: this.infoProject.path,        
      }

      console.log(obj);
      await axios
        .post("https://microservices.safered.co/api/setStorageFile", obj, config)
        .then(function (data) {
          console.log(data)
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    uploadDocumentsP: async function(){

      var self = this;
      let formData = new FormData();
      formData.append("point_id", 0);
      formData.append("file", this.image);
      formData.append("tag_id", 16),
      formData.append("project_id", this.infoProject.id),
      formData.append("user_id", 0)

      console.log(formData);
      await axios
        .post("https://api.safered.co/api/store-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (data) {
            alert('Imagen de proyecto cargada exitosamente.')
            self.logoProjectGet();
        })
        .catch(function (e) {
          console.log(e);
        });

    },
    EditProjects: function () {
      this.$refs["my-modal-add-client"].show();
    },
    clearImage() {
      this.image = null;
    },
    onSubmit() {
      if (!this.image) {
        alert("Por favor seleccione una imagen.");
        return;
      }

    },
    updateProject: async function () {

      var self = this
      this.infoProject.users.push(JSON.parse(localStorage.user).id)
      console.log(self.infoProject)

      if(this.infoProject.fileConfig != undefined){
        var fileR = new FileReader();
        fileR.readAsText(this.infoProject.fileConfig)
        this.fileReader = fileR
      }

      await api
        .request(
          "post",
          "/updateProject",
          this.infoProject
        )
        .then((data) => {
          if(data.status == 200){

            if(this.infoProject.fileConfig != undefined){
              self.cloudataInfo()
            }

            alert('Proyecto actualizado exitosamente')
            self.$refs["my-modal-add-client"].hide()

            var refD =  self.$store.state.refreshProject + 1
            self.$store.commit("setRefreshProject", refD);
          }
        })
        .catch(function (e) {
          console.log(e);
        });

      if(this.selectBusiness != null || this.selectBusiness.id != 0){
        var obj = {
          "idProjects": this.infoProject.id
        } 
        await api
        .request("post", `/company/${this.selectBusiness.id}/linkProjects`, obj)
        .then((data) => {
          console.log(data)
        })
        .catch(function(error) {
          console.loh(error)
        });
      }
    },
    formatProject: async function (){
      for (let property1 in this.projects) {
        var tmp_obj = null
          tmp_obj = {
            value: this.projects[property1][0].id,
            text: this.projects[property1][0].name,
        };        
        this.projectsFormat.push(tmp_obj)
      }
      var itemNull = { value: null, text: "Por favor seleccione un proyecto" };
      this.projectsFormat.unshift(itemNull)
      console.log(this.projectsFormat)
    },
    validateProject: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.selectedProject) {
            this.getInfoProject()
            resolve(true);
          }
          else {
            reject("Debe seleccionar un proyecto para editar.");
            alert("Debe seleccionar un proyecto para editar.");
          }
        }, 1000);
      });
    },
    getInfoProject: function () {
      for (let property1 in this.projects) {
        console.log(this.projects[property1][0])
        if(this.selectedProject == this.projects[property1][0].id){
          this.infoProject.nameProject = this.projects[property1][0].name
          if(this.projects[property1][0].canvas){
            this.infoProject.canvas = this.projects[property1][0].canvas.split(',')
          }
          this.infoProject.id = this.projects[property1][0].id
          this.infoProject.fileConfig = this.projects[property1][0].fileConfig
          this.infoProject.sizeDisk = this.projects[property1][0].sizeDisk
          this.infoProject.sizeFile = this.projects[property1][0].sizeFile
          this.infoProject.description = this.projects[property1][0].description
          if(this.projects[property1][0].typeFiles){
            //["[\"Doc\"","\"Pdf\"]"
            var s = this.projects[property1][0].typeFiles
            .replaceAll('[', '').
            replaceAll('\\', '').
            replaceAll('"', '').
            replaceAll(']', '')
            
            
            //console.log(s)
            this.infoProject.typeFiles = s
            .split(',')
          }
          
          if(Object.values(this.projects[property1][1]).length>0){
            for (let index = 0; index < Object.values(this.projects[property1][1]).length; index++) {
              this.infoProject.selectedUsers.push(Object.values(this.projects[property1][1])[index])
              //console.log(Object.values(this.projects[property1][1])[index])
            }
            //this.infoProject.selectedUsers = this.projects[property1][0].selectedUsers.split(',')
            
            this.infoProject.selectedUsers.forEach(element => {
              for (let index = 0; index < this.users.length; index++) {
                if(this.users[index].value == element.user_id){
                  this.viewSelectUsers.push(
                    {
                      "name" : this.users[index].text,
                      "id" : this.users[index].value
                    }
                  )
                  this.infoProject.users.push(this.users[index].value)
                  this.users.splice(index, 1)
                  //console.log(this.users[index])
                }
              }
            });
          }
        }
      }
    },
    deleteUserForProject: function (user){
      var indexDelete = 0
      this.infoProject.users.forEach(function (element, i) {
        if(user.id === element){
          indexDelete = i
        }
      })
      this.infoProject.users.splice(indexDelete,1)
        

      this.viewSelectUsers.forEach(function (element, i) {
        if(element.id === user.id){
          indexDelete = i
        }
      })
      this.viewSelectUsers.splice(indexDelete,1)

      this.users.push(
        {
          "text": user.name,
          "value": user.id
        }
      )
    },
    setUser: function () {
      if (this.selectedUser) {
        this.infoProject.users.push(this.selectedUser);
        var selUsTmp = this.selectedUser
        var listUser = []
        var listUsersId = []
        var index = 0
        this.users.forEach(
          function (element, i) {
            if(element.value == selUsTmp){
              listUser.push(element.text)
              listUsersId.push(
                {
                  "name" : element.text,
                  "id" : element.value
                }
              )
              index = i
            }
          });

          
          if(listUsersId.length>0){
            this.viewSelectUsers.push(
            {
              "name" : listUsersId[0].name,
              "id" : listUsersId[0].id
            }
          )
            this.users.splice(index,1)
          }
      } else {
        alert("Debe seleccionar un usuario");
      }
    },
    logoProjectGet: async function(){
      var imgProfile = null;
      var data = {
        "project_id": this.selectedProject,
        "id_tag": 16
      };
      await axios
      .post('https://api.safered.co/api/GetIconProjectPick', data, {responseType: 'blob'})
        .then((response) => {
        imgProfile = response
      })
      .catch(function (e) {
          console.log(e);
      });

      if(imgProfile.data.size > 55){
        this.imageForm = URL.createObjectURL(imgProfile.data);
        this.existProfie = true;
      }else{
        this.existProfie = false;
      }
    },
    updateIconProject: async function(){
      let formData = new FormData();
      formData.append("file", this.image);
      formData.append("id_tag", 16);
      formData.append("project_id", this.selectedProject);

      var self = this;

      await axios
        .post("https://api.safered.co/api/UpdateIconProjectPick", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (data) {
          alert('Imagen de perfil cargada exitosamente.')
          self.logoProjectGet();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
  },
  watch:{
    selectedProject(){
      this.logoProjectGet();
    },
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
};
/*eslint-enable */
</script>