<template>
    <div>
        <v-card
        style="width: 170px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
        @click="dialog = true"    >
            <v-img
                :src="icon_add"
                alt=""
                style="width: 110px"
            />

            <v-card-title>
                <h3 style="font-size: 12px">Crear grupo de usuarios</h3>
            </v-card-title>
        </v-card>

        <v-dialog v-model="dialog" width="650">
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Asignar proyecto
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                    >
                        Crear grupo
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        step="3"
                    >
                        Asignar usuarios
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card
                        class="mb-12"
                        height="250px"
                        style="display: flex;"
                        >
                            <v-row style="display: flex; justify-content: center; align-items: center;">
                                <v-col cols="10">
                                    <v-select
                                        v-model="selectP"
                                        :items="listProject"
                                        label="Seleccion el proyecto"
                                        persistent-hint
                                        return-object
                                        single-line
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-btn
                            :disabled="selectP.value == undefined"
                            color="primary"
                            @click="e1=2"
                        >
                            Continuar
                        </v-btn>

                        <v-btn @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card class="mb-12">
                            <v-col>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="nameGroup"
                                            label="Nombre del grupo"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="descGroup"
                                            filled
                                            label="Descripción"
                                            auto-grow
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>

                        <v-btn v-if="!activeLoad"
                        :disabled="nameGroup.length == 0 || descGroup.length == 0"
                        color="primary"
                        @click="createGroup()"
                        >
                            Continuar
                        </v-btn>

                        <v-btn v-if="activeLoad"
                        color="primary"
                        >
                            <clip-loader
                                :loading="activeLoad"
                                color="#1e88e5"
                                size="10px"
                            ></clip-loader>
                        </v-btn>

                        <v-btn @click="dialog = false; reset()">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card
                        class="mb-12"
                        height="350px"
                        >
                            <v-row>
                                <v-col cols="6">
                                    <v-list style="height: 180px; overflow-x: hidden; overflow-y: scroll;" flat>
                                        <v-list-item v-for="(value, i) in listAdd" :key="i">
                                            <v-list-item-content>
                                                <v-row>
                                                    <v-col cols="8" style="display: flex;">
                                                        <v-list-item-title v-if="value.text != undefined" v-text="value.text">
                                                        </v-list-item-title>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-btn
                                                            class="mx-2"
                                                            fab
                                                            dark
                                                            small
                                                            color="red darken-1"
                                                            @click="deleListU(value.value)"
                                                        >
                                                            <v-icon dark>
                                                                mdi-minus
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col cols="8">
                                            <v-select
                                                v-model="selectU"
                                                :items="listUser"
                                                label="Seleccione el usuario"
                                                persistent-hint
                                                return-object
                                                single-line
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="4" style="display: flex; align-items: center;">
                                            <v-btn
                                            class="mx-2"
                                            fab
                                            dark
                                            small
                                            color="green darken-1"
                                            @click="addUserG()"
                                            >
                                                <v-icon dark>
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <!-- <v-col cols="6">
                                    <v-col>
                                        <p>Rol Actual:</p>
                                        {{actualRol}}
                                    </v-col>
                                </v-col> -->
                                <v-col cols="1"></v-col>
                                <v-col cols="10">
                                    <v-col>
                                        <p>Asignar rol:</p>
                                        <v-select
                                        v-model="selectRol"
                                        :items="opcionesRol"
                                        label="Seleccione el rol"
                                        persistent-hint
                                        return-object
                                        single-line
                                        ></v-select>
                                    </v-col>
                                </v-col>
                                <v-col cols="1"></v-col>
                            </v-row>
                        </v-card>

                        <v-btn
                            color="green"
                            @click="asingUserG()"
                            style="color:white;"
                        >
                            Finalizar
                        </v-btn>

                        <v-btn @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>

            <v-snackbar
                v-model="NoticreateG"
                color="success"
                outlined
                timeout="5000"
                :top="true"
            >
                Se ha creado el <strong>Grupo</strong>

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="success"
                    text
                    v-bind="attrs"
                    @click="NoticreateG = false"
                    >
                    <v-icon>
                        mdi-close
                    </v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            
            <v-snackbar
                v-model="NotiFail"
                color="red accent-2"
                outlined
                timeout="5000"
                :top="true"
            >
                No se puedo realizar la accion

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="red accent-2"
                    text
                    v-bind="attrs"
                    @click="NotiFail = false"
                    >
                    <v-icon>
                        mdi-close
                    </v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-dialog>
        
        <v-snackbar
            v-model="NotiasignI"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha asignado los <strong>Integrantes</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="NotiasignI = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>

import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import api from '../../../../../../../api/index.js'
import icon_add from '../../../../../../../assets/img/icons/icon_users_create.svg'

export default {
    name: 'createGroup',
    components:{
        ClipLoader
    },
    data(){
        return{
            icon_add: icon_add,
            dialog: false,
            nameGroup: '',
            e1: 1,
            descGroup: '',
            activeLoad: false,
            selectP: {},
            selectU: {},
            listUser: [],
            listAdd: [],
            idGroupC: null,
            NoticreateG: false,
            NotiasignI: false,
            NotiFail: false,
            selectRol: {},
            opcionesRol: [
                {text: "Usuario Base", value: 2},
                {text: "Usuario Editor", value: 4}
            ],
        }
    },
    props: [ 'listProject', 'listU' ],
    mounted(){
        this.listUser = this.listU
    }, 
    methods:{
        addUserG: function(){
            var self = this
            if(self.listAdd.length <= 0){
                self.listAdd.push(self.selectU)
            }else{
                self.listAdd.map(function(listA){
                    if(listA.value != self.selectU.value){
                        self.listAdd.push(self.selectU)
                    }
                })
            }
        },
        deleListU: function(id){
            var data = this.listAdd.filter((item) => item.value !== id) 
            this.listAdd = data
        },
        asingUserG: async function(){
            this.activeLoad = true;
            var self = this
            var obj = []
            
            this.listAdd.map(function(listA){
                obj.push({
                    idUserEdit:  JSON.parse(localStorage.user).id,
                    idGroup: self.idGroupC,
                    idUser: listA.value
                })
            })
            
            var rol = []
            if(this.selectRol.value != undefined){
                rol.push({
                    idUser: JSON.parse(localStorage.user).id,
                    idGroup: this.idGroupC ,
                    idRole: this.selectRol.value
                })
            }else{
                rol.push({
                    idUser: JSON.parse(localStorage.user).id,
                    idGroup: this.idGroupC ,
                    idRole: 2
                })
            }

            await api
            .request("post", "/asignRoleToGroup", rol)
            .then(function(response){
                console.log(response)
            })
            .catch(function(e){
                console.log(e)
            })


            await api
            .request("post", "/asignUserToGroup", obj)
            .then(function (response) {
                console.log(response)

                self.reset()
                self.NotiasignI = true
            })
            .catch(function (e) {
                console.log(e);
                self.NotiFail = true
            });
        },
        createGroup: async function(){
            this.activeLoad = true;
            var self = this
            var obj = {
                idUser: JSON.parse(localStorage.user).id,
                name: this.nameGroup,
                description : this.descGroup,
                idProject: this.selectP.value
            }
            await api
            .request("post", "/createGroup", obj)
            .then(function (response) {
                console.log(response)

                self.idGroupC = response.data.id
                self.e1 = 3
                self.activeLoad = false
                self.NoticreateG = true
            })
            .catch(function (e) {
                console.log(e);
                self.NotiFail = true;
                self.activeLoad = false;
            });
        },
        reset: function(){
            this.dialog = false
            this.e1= 1
            this.nameGroup = ''
            this.descGroup = ''
            this.activeLoad = false
        },
    },
    watch:{
        dialog: function(){
            if(!this.dialog){
                this.reset();
            }
        },
    }
}
</script>
<style>
    
</style>