<template>
  <b-card>
    <v-col  id="app2">
      <BotonDirectory :posicionTitle="posicionTitle"   />
      <v-row style="margin-top: -10px; flex-direction: row;  display: flex; justify-content: flex-end;"> 
        <v-row   class="conteiner-circular" v-if="circular">
          <v-col>
            <v-progress-circular
                style="top: 50%; left: 50%;
                transform: translate(-50%, -50%);"
                indeterminate
                color="#283271"  >
            </v-progress-circular>
          </v-col>
        </v-row> 
        <v-row  class="cambioData" >
          <v-col  cols="2" style="display: flex; flex-direction: column;
            justify-content: center;
            align-items: center;">
            <i v-if="statusDraw"  @click="uploadDataDirect()" style="margin-top: 20px; cursor: pointer;" class="fa-solid fa-rotate fa-spin"></i>
          </v-col>
        </v-row>
      </v-row>
      <v-card v-if="!circular" class="container_directGAux"   >
          <v-jstree
            :data="data"
            multiple
            allow-batch
            whole-row
            draggable
            @item-click="itemClick"
            @item-drag-start="itemDragStart"
            @item-drag-end="itemDragEnd"
            @item-drop-before="itemDropBefore"
            @item-drop="itemDrop"
            ref="tree2"
          >
            <template slot-scope="_">
              <div
                style="display: inherit;  "
                @click.ctrl="customItemClickWithCtrl"
                
              >
                <i
                  :class="_.vm.themeIconClasses"
                  role="presentation"
                  v-if="!_.model.loading"
                ></i>
                  {{ _.model.text }}
              </div>
            </template>
          </v-jstree>
      </v-card>
      <modal-01 :typeDirct="`Aux`" :dataDelete="dataDelete" ></modal-01>
      <modal-02 :dataDelete="dataDelete" :editingItem="editingItem" :projectId="projectId" :idCanvas="idCanvas"
      :typeDocument="typeDocument" :typeCanvas="typeCanvas"></modal-02>

      <b-modal centered id="modal-03" :title="`Agregar directorio a la carpeta ${editingItem.text}`" hide-footer>
        <formCreateFolders
          :typeDirct="`Aux`"
          :folderId="folderId"
          :projectId="projectId"
          :idFather="editingItem.id"
          :editingItem="editingItem"
          v-if="Object.keys(editingItem).length > 0"
        ></formCreateFolders>
      </b-modal>

      <b-modal centered id="modal-05" :title="`Cargar archivos a la carpeta ${editingItem.text}`" hide-footer>
        <b-row>
          <formUploadFile
            :typeDirct="`Aux`"
            :folderId="folderId"
            :projectId="projectId"
            :idFather="editingItem.id"
            :nameFolders="nameFolders"
            v-if="Object.keys(editingItem).length > 0"
          ></formUploadFile>
        </b-row>
      </b-modal>

    </v-col>
    <pocketDir :projectId="projectId"/>
  </b-card>
</template>

<script>
import Vue from 'vue'
import axios from "axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


import filtExistObj from '../../../../../api/scriptE/index'
import VJstree from "vue-jstree"
import modal02 from "../../../../modals/DownloadFileD.vue"
import modal01 from "../../../../modals/modal-01.vue"
import formUploadFile from "../../../../modals/FormUploadFile.vue"
import formCreateFolders from "../../../../modals/FormCreateFolders.vue"
import pocketDir from './PocketDirect.vue';
import BotonDirectory from './BotonDirectory.vue';

export default {
  name: "directorioAux",
  components: {
    VJstree,
    BotonDirectory,
    modal01,
    modal02,
    formUploadFile,
    formCreateFolders,
    pocketDir, 
  },
  data() {
    return {
      self: this,
      nameDirect: "",
      iconSeleccionado: {
        className: "",
        cssValue: "F2BC",
      },
      searchText: "",
      editingItem: {},
      editingNode: null,
      data: [],
      asyncData: [],
      filesToAdd: 1,
      filesToAddIndex: 0,
      editingTree: false,
      currentIndexTree: 0,
      folderId: "",
      clientInfo: {},
      statusDraw: false,
      dataDelete: {},
      nameFolders: [],
      posicionTitle: 0,
      circular: true,

    };
  },
  async mounted() {
    this.clientInfo = JSON.parse(localStorage.user).clientInfo
    this.posicionTitle = this.$store.state.select_diretory;
    await this.getTreePath();
    this.circular =  false; 
    await this.getTreePathFromExtern();
     
  },
  props: ['nameProject', 'projectId', 'typeDocument', 'idCanvas', 'typeCanvas'],
  methods: {
    uploadDataDirect: async function(){

      var obj = {
        idProject: this.projectId,
        idUser: JSON.parse(localStorage.user).id,
        data: this.data
      }

      await axios
        .post("https://api.safered.co/api/syncThreeDirectoriesGlobal", obj)
        .then(function (response) {
          console.log(response)
        })
        .catch(function (e) {
          console.log(e);
        });
        this.statusDraw = false
    },
    desactiveDirectorioAux(){
      this.$store.commit("setViewDirectorioAux", false);
    },
    updateTreePath: async function () {
      var dataTree = JSON.stringify(this.data, dataTree, undefined, 4);
      var obj = {
        id_project: this.projectId,
        tree: dataTree,
      };

      var flag;

      await axios
        .post("https://api.safered.co/api/savePathTree", obj)
        .then(function (data) {
          if (data.data.message == "Canvas actualizadao correctamente.") {
            flag = true;
          } else {
            flag = false;
          }
        })
        .catch(function (e) {
          console.log(e);
        });

      if (flag) {
        alert("Directorios actualizados correctamente.");
      } else {
        alert("Error al actualizar.");
      }
    },
    getTreePath: async function () {
      var obj = {
        idProject: await this.projectId,
        idUser: await JSON.parse(localStorage.user).id,
      };
      var dt = [];
      await axios
        .post("https://api.safered.co/api/getThreeDirectoriesGlobal", obj)
        .then(function (data) {
          dt = data.data;
        })
        .catch(function (e) {
          console.log(e);
        });

      if(dt.length > 0){
        this.data = JSON.parse(dt[0].data)
      }

    },
    getTreePathFromExtern: async function () {
      var obj = {
        idUser: JSON.parse(localStorage.user).id,
        idProject: this.projectId,
        emailClient: this.clientInfo.emailContacto
      };
      
      var token = JSON.parse(localStorage.user).token;
      
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      var dt;
      var self = this 
      await axios
        .post("https://microservices.safered.co/api/getStorageProject", obj, config)
        .then(function (data) {
          
          self.folderId = data.data.GOOGLE_API_FOLDER_ID;
          dt = data.data.docProjects;
        })
        .catch(function (e) {
          console.log(e.message);
        });
        
        var array = [
          {
            "text": this.nameProject,
            "value": this.nameProject,
            "id": self.folderId,
            "icon": "",
            "opened": true,
            "parent": self.folderId,
            "children": JSON.parse(JSON.stringify(dt))
          }
        ]

      if(this.data.length > 0){
        var objIdent = []
        var temp =  await filtExistObj.find(array[0]);

        var temp_1 =  await filtExistObj.find(this.data[0]);

        temp.map(function(obj){
          var found = temp_1.find(element => element.text == obj.text);
          if(found == undefined){
            objIdent.push(obj)
          }
        });
        

        var isExistNov = this.data[0].children.find(element => element.text == 'Novedades');

        if(isExistNov != undefined){
          this.data[0].children.map(function(obj){
            if(obj.text == 'Novedades'){
              objIdent.map(function(obj1){
                obj.children.push(obj1)
              }) 
            }
          });
        }else{
          this.data[0].children.push({
            text: "Novedades",
            value: "Novedades",
            id: "Temp_Nov",
            icon: "",
            opened: true,
            parent: "Temp_Nov",
            children: objIdent
          });
        }

        var elementDelete = []
        temp_1.map(function(obj){
          if(obj.id != null){
            var idGhost = obj.id.includes("temp")
            if(obj.text != "Novedades" && !idGhost){
              var found = temp.find(element => element.text == obj.text);
              if(found == undefined){
                elementDelete.push(obj)
              } 
            }
          }
        });

        
        if(elementDelete.length > 0){
          await filtExistObj.findDelete(this.data[0], elementDelete)
          this.statusDraw = true
        }

        var objGhost = []
        temp.map(function(obj){
          var foundCopy = temp_1.find(element => element.text == obj.text && element.icon != null && element.id == null);
          if(foundCopy != undefined){
            objGhost.push(foundCopy)
          }
        });


        if(objGhost.length > 0){
          var resultData = []
          objGhost.map(function(obj){
            var temp_File =  temp.find(element => (element.text == obj.text))
            if(temp_File != undefined){
              resultData.push(temp_File)
            }
          })
          
          var result = await filtExistObj.asingValue(this.data[0], resultData)
          this.data[0].children = result
          await this.uploadDataDirect()
          await this.getTreePath();
          this.getTreePathFromExtern();

        }
        
        this.nameFolders = []

        temp.map(function(obj){
          if(obj.icon == ''){
            self.nameFolders.push(obj);
          }
        })
        self.nameFolders.unshift(
          {
            "text": this.nameProject,
            "value": this.nameProject,
            "id": self.folderId,
            "icon": "",
            "opened": true,
            "parent": self.folderId,
            "children": []
          }
        )
        
      }else{
        this.data = await array
      }

    },
    addDirWhitName: function () {
      if (this.nameDirect.length > 0) {
        if (this.editingItem !== undefined) {
          if (!this.editingTree) {
            var index = Math.max(...this.data.map((o) => o.id));
            this.currentIndexTree = index + 1;
            this.editingItem.children.push({
              text: this.nameDirect,
              children: [],
              opened: true,
              "show-checkbox": false,
              index: this.currentIndexTree,
            });
            this.editingTree = true;
          } else {
            this.currentIndexTree = this.currentIndexTree + 1;
            this.editingItem.children.push({
              text: this.nameDirect,
              children: [],
              opened: true,
              "show-checkbox": false,
              index: this.currentIndexTree,
            });
          }
        }
      } else {
        alert("Debe ingresar un nombre para el directorio.");
      }
    },
    iconSelect(selectedIcon) {
      this.iconSeleccionado = {
        className: "fa " + selectedIcon.className,
        cssValue: selectedIcon.cssValue,
      };
      this.editingItem.icon = this.iconSeleccionado.className;
    },
    itemClick(node, item, e) {
      this.editingNode = node;
      this.editingItem = node.model;

      if (node.model.icon == null || node.model.icon == "") {
        this.$root.$emit("bv::show::modal", "modal-01", "#focusThisOnClose");
        this.dataDelete = {
          node,
          item, 
          e
        }
      }else{
        this.$root.$emit("bv::show::modal", "modal-02-file", "#focusThisOnCLose");
        this.dataDelete = {
          node,
          item, 
          e
        }
        
      }
    },
    itemDragStart: async function(){
      this.statusDraw = true;
      console.log(this.data)
    },
    itemDragEnd(e) {
      console.log(e)
    },
    itemDropBefore(node, item, draggedItem) {
      if (!draggedItem) {
        item.addChild({
          text: "newNode",
          value: "newNode",
        });
      }
    },
    itemDrop(node, item) {
      var sortBy = function (attr, rev) {
        if (rev == undefined) {
          rev = 1;
        } else {
          rev = rev ? 1 : -1;
        }
        return function (a, b) {
          a = a[attr];
          b = b[attr];
          if (a < b) {
            return rev * -1;
          }
          if (a > b) {
            return rev * 1;
          }
          return 0;
        };
      };
      item.children.sort(sortBy("text", true));
    },
    inputKeyUp: function () {
      var text = this.searchText;
      const patt = new RegExp(text);
      this.$refs.tree2.handleRecursionNodeChilds(
        this.$refs.tree2,
        function (node) {
          if (text !== "" && node.model !== undefined) {
            const str = node.model.text;
            if (patt.test(str)) {
              node.$el.querySelector(".tree-anchor").style.color = "red";
            } else {
              node.$el.querySelector(".tree-anchor").style.color = "#000";
            } // or other operations
          } else {
            node.$el.querySelector(".tree-anchor").style.color = "#000";
          }
        }
      );
    },
    addChildNode: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.addChild({
          text: "newNode",
        });
      }
    },
    removeNode: function () {
      if (this.editingItem.id !== undefined) {
        var index = this.editingNode.parentItem.indexOf(this.editingItem);
        this.editingNode.parentItem.splice(index, 1);
      }
    },
    addBeforeNode: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.addBefore(
          {
            text: this.editingItem.text + " before",
          },
          this.editingNode
        );
      }
    },
    addAfterNode: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.addAfter(
          {
            text: this.editingItem.text + " after",
          },
          this.editingNode
        );
      }
    },
    openChildren: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.openChildren();
      }
    },
    closeChildren: function () {
      if (this.editingItem.id !== undefined) {
        this.editingItem.closeChildren();
      }
    },
    refreshNode: function () {
      this.asyncData = [this.$refs.tree2.initializeLoading()];
      this.$refs.tree2.handleAsyncLoad(this.asyncData, this.$refs.tree2);
    },
    customItemClick: async function (node, item, e) {
      var sign = confirm("¿Desea eliminar este nodo?");

      if (sign) {
        e.stopPropagation();
        var index = node.parentItem.indexOf(item);
        await this.deleteNode(item.id);
        node.parentItem.splice(index, 1);
      }
    },
    customItemClickWithCtrl: function () {
      console.log("click + ctrl");
    },
    fillData: function () {
      if (this.editingItem.id !== undefined) {
        for (var i = 0; i < this.filesToAdd; i++) {
          this.filesToAddIndex++;
          this.editingItem.addChild({
            text: "File" + this.filesToAddIndex,
            icon: "fa fa-file icon-state-danger",
          });
        }
      }
    },
    deleteNode: async function (id) {
      var token = JSON.parse(localStorage.user).token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let formData = new FormData();
      formData.append("fileId", id);
      formData.append("idProject", this.projectId)
      formData.append("emailClient", this.clientInfo.emailContacto)
      

      const data = {};
      formData.forEach((value, key) => (data[key] = value));
      // Log the data.
      await axios
        .post("https://microservices.safered.co/api/deleteComponent", formData, config, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));
    },
    addElement: async function(data){
      await filtExistObj.addElement(this.data[0], data);
      await this.uploadDataDirect();

      if(data.type == "Folder"){
        alert("Carpeta creada exitosamente.");
      }else{
        alert("Archivo cargado exitosamente.");
      }
     
      await this.getTreePath()
      await this.getTreePathFromExtern();
    },
    deleteFolderGhost: async function(id){
      await filtExistObj.deleteGhost(this.data[0], id)

      this.uploadDataDirect();
      this.$store.commit("setDeleteFolderGhost", null)
    }
  },
  watch:{
    data: function(){
      this.$store.commit("setDiretoryAux", this.data);
    },
    '$store.state.addTempDirectFlex': async function(){
      var data = this.$store.state.addTempDirectFlex;
      await this.addElement(data)
    },
    '$store.state.indexNodeDelete': async function(){
      var node = this.dataDelete.node
      var index = this.$store.state.indexNodeDelete;
      node.parentItem.splice(index, 1);

      await this.getTreePath();
      await this.getTreePathFromExtern();
    },
    '$store.state.refreshDGeneral': function(){
      this.getTreePathFromExtern();
    },
    '$store.state.deleteFolderGhost': function(){
      var id = this.$store.state.deleteFolderGhost
      this.deleteFolderGhost(id);
    }
  }
};
</script>

<style scoped>
.cambioData{
    margin-top: -50px!important;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}
  #app2 h1,
  #app2 h2 {
    font-weight: normal;
  }

  .container_directGAux{
    height: 400px; 
    margin-top: 40px; 
    overflow-y: scroll;
  }
  
  .container_directGAux::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .container_directGAux::-webkit-scrollbar-thumb {
    background: #A8A8A8;
    border-radius: 5px;
  }

  #app2 ul {
    list-style-type: none;
    padding: 0;
  }

  #app2 li {
    display: inline-block;
    margin: 0 10px;
  }

  #app2 a {
    color: #42b983;
  }


  #app2 table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #eee;
    font-size: 14px;
  }

  #app2 table th {
    background: #eee;
    border-bottom: 1px solid #ccc;
    padding: 4px;
  }

  #app2 table td {
    border: 1px solid #eee;
    padding: 4px;
  }

  #app2 .icon-state-default {
    color: gray;
  }

  #app2 .icon-state-danger {
    color: red;
  }

  #app2 .icon-state-warning {
    color: yellow;
  }

  #app2 .icon-state-success {
    color: green;
  }

  
  .conteiner-circular{
      height: 410px; 
      margin-top: 40px; 
    }

    
</style>
 