<template>
    <div>
        <b-card>
            <v-col  id="app2">
                <BotonDirectory :posicionTitle="posicionTitle" :projects="projects"/>
                <v-row class="conteiner-circular" v-if="circular">
                    <v-col  >
                        <v-progress-circular
                            style="top: 50%; left: 50%;
                            transform: translate(-50%, -50%);"
                            indeterminate
                            color="#283271"  >
                        </v-progress-circular>
                    </v-col>
                </v-row> 
                <v-card v-if="!circular" class="container_directG">
                    <v-jstree
                        :data="data"
                        multiple
                        allow-batch
                        whole-row
                        draggable
                        ref="tree2"
                        @item-click="itemClick"
                    >
                    </v-jstree>
                </v-card>
            </v-col>
            <pocketDir :projectId="projectId"/>
        </b-card>
        <findDataC :key="count+'_geo'" v-if="posicionCSelect != null && typeCanvas == 'Geo'"
            :treeDirectoriesIndex="dataPoints[posicionCSelect]" :typeCanvas="0"
            :polygonsLoadedIndex="polygonsLoaded[posicionCSelect]" :treeDirectories="treeDirectories"
            :polygonsLoaded="polygonsLoaded" :tagsAndFiles="tagsAndFiles" :nameProject="nameProject"
            :dataVista="dataGeo[posicionCSelect]" :threeGoogle="threeGoogle" :searchData="searchData"
            :linesLoaded="linesLoaded[posicionCSelect]" :projectId="projectId" :contanintD="searchData ? false : true"
        />
        <findDataC :key="count+'_MpBts'" v-if="posicionCSelect != null && typeCanvas == 'MpBits'"
            :treeDirectoriesIndex="dataPointsMpBits[posicionCSelect]"
            :polygonsLoadedIndex="polygonsLoadedMpBits[posicionCSelect]" 
            :treeDirectories="treeDirectories" :polygonsLoaded="polygonsLoadedMpBits" 
            :tagsAndFiles="tagsAndFiles" :nameProject="nameProject"
            :dataVista="dataMpBits[posicionCSelect]" :typeCanvas="1" :threeGoogle="threeGoogle"
            :projectId="projectId" :contanintD="searchData ? false : true" :searchData="searchData"
        />
    </div>
</template>
<script>

import findDataC from '../buscar/FindDataCanvas.vue';
import VJstree from "vue-jstree";
import BotonDirectory from '../directories/BotonDirectory.vue';
import pocketDir from "../directories/PocketDirect.vue";
import api from '../../../../../api/index.js';

export default {
    name: "containDirectory",
    components: {
        BotonDirectory,
        findDataC,
        pocketDir,
        VJstree,
    },
    data(){
        return{
            data: [],
            dataCanvas: null,
            circular: true,
            posicionTitle: 0,
            posicionCSelect: null,
            count: 0,
        }
    },
    props: [ "dataPointsMpBits", "treeDirectories", "polygonsLoadedMpBits", "tagsAndFiles", 
            "nameProject", "dataMpBits", "threeGoogle", "projectId", "dataPoints", "polygonsLoaded", 
            "dataGeo", "linesLoaded" , "typeCanvas", "searchData", "projects" ],
    mounted(){

        this.getDataCanvas();
        this.circular = false;
        this.posicionTitle = this.$store.state.select_diretory   
    },
    methods:{
        getDataCanvas: async function(){
            var dataC = null
            var obj = {
                idProject: this.projectId
            }
            await api
            .request(
                "post",
                "/getCanvasForIdProject", obj
            )
            .then((response) => {
                dataC = response.data
            }).catch((error) =>{
                console.log(error)
            });
            this.dataCanvas = dataC;

            var array = []
            if(this.typeCanvas == 'Geo'){
                this.dataCanvas.C_GEO.map(function(obj){
                    array.push({
                        "text": obj.name,
                        "value": obj.name,
                        "id": obj.id,
                        "icon": "",
                        "opened": true,
                        "parent": obj.idProject,
                        "children": []
                    })
                })
            }else{
                this.dataCanvas.C_MP_BITS.map(function(obj){
                    array.push({
                        "text": obj.name,
                        "value": obj.name,
                        "id": obj.id,
                        "icon": "",
                        "opened": true,
                        "parent": obj.idProject,
                        "children": []
                    })
                })
            }
            this.data = array
        },
        itemClick: function (node, item, e){
            console.log(node)
            console.log(item)
            console.log(e)
        }
    },
    watch:{
        data: function(){
            console.log(this.data)
            if(!this.searchData){
                this.$store.commit("setDirectoryGeoMpBits", { data: this.data, typeCanvas: this.typeCanvas })
            }else{
                this.$store.commit("setDirectoryGeoMpBits_noview", { data: this.data, typeCanvas: this.typeCanvas })
            }
        },
        typeCanvas: function(){
            this.getDataCanvas();
        },
        "$store.state.activeDataGeoMpBits": function(){
            this.count++
            this.posicionCSelect = this.$store.state.activeDataGeoMpBits;
        },
        "$store.state.findDataFolder": function(){
            console.log(this.$store.state.findDataFolder)
            this.data[this.posicionCSelect].children = this.$store.state.findDataFolder[0].children[0].children;
        },
        "$store.state.findDataCanvas_market": function(){
            this.data[this.posicionCSelect].children = this.$store.state.findDataCanvas_market[0].children[0].children;
        },
    }
}
</script>
<style scoped>

    .container_directG{
        height: 408px; 
        margin-top: 15px; 
        overflow: auto;
    }

    .container_directG::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    .container_directG::-webkit-scrollbar-thumb {
        background: #A8A8A8;
        border-radius: 5px;
    }

    
    #app3 h1,
    #app3 h2 {
        font-weight: normal;
    }

    #app3 ul {
        list-style-type: none;
        padding: 0;
    }

    #app3 li {
        display: inline-block;
        margin: 0 10px;
    }

    #app3 a {
        color: #42b983;
    }


    #app3 table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #eee;
        font-size: 14px;
    }

    #app3 table th {
        background: #eee;
        border-bottom: 1px solid #ccc;
        padding: 4px;
    }

    #app3 table td {
        border: 1px solid #eee;
        padding: 4px;
    }

    #app3 .icon-state-default {
        color: gray;
    }

    #app3 .icon-state-danger {
        color: red;
    }

    #app3 .icon-state-warning {
        color: yellow;
    }

    #app3 .icon-state-success {
        color: green;
    }

  
  .conteiner-circular{
      height: 410px; 
      margin-top: 14px; 
    }


</style>