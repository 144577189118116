<template>
    <div>
        <v-col>
            <v-row>
                <v-col cols="1">
                </v-col>
                <v-col cols="3" style="align-self: center;">
                    <v-combobox v-model="valueTag" :items="typeDocument" chips clearable 
                        multiple label="Ingrese el tipo de documento">
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="remove(item)">
                                <strong>{{ item.text }}</strong>&nbsp;
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="2" style="align-self: center;">
                    <div>
                        <v-select label="Ingrese el nombre de vista" v-model="selectCanva"
                        :items="listCanvas"></v-select>
                    </div>
                </v-col>
                <v-col cols="2" style="align-self: center;">
                    <div @click="filtroB()">
                        <v-select v-model="selectedFig" :items="dataFigRebuild"></v-select>
                    </div>
                </v-col>
                <v-col cols="2" style="align-self: center;">
                    <div @click="listPointG()">
                        <v-select v-model="selectedPoint" :items="dataPointsRebuild"></v-select>
                    </div>
                </v-col>
                <v-col cols="1" style="align-self: center;">
                    <b-button @click="filtrarPorTag()" variant="light">
                        <i class="fa fa-search fa-lg prop_i" aria-hidden="true"></i>
                    </b-button>
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>
            <br />
            <v-row>
                <v-col cols="1">
                </v-col>
                <v-col cols="10" style="height: 443px">
                    <v-list style="height: 450px; overflow-x: hidden; overflow-y: scroll;" flat>
                        <v-list-item v-for="(tagF, index) in tagFound" :key="index" style="padding-top: 18px;">
                            <v-col>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b>
                                            <p>Nombre del Archivo: </p>
                                        </b>
                                        <p>&nbsp;{{ tagF.title }}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="10" style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b>
                                            <p>Ubicacion: </p>
                                        </b>
                                        <p>&nbsp;{{ tagF.ubicacion }}</p>
                                    </v-col>
                                    <v-col cols="1" style="display: flex; justify-content: center;">
                                        <i style="cursor: pointer;" class="fa fa-cloud-download fa-lg"
                                            @click="downloadFile(tagF.path, tagF.title)" aria-hidden="true"></i>
                                    </v-col>
                                    <v-col cols="1" style="display: flex; justify-content: center;">
                                        <i v-if="permitEdit" style="cursor: pointer;" class="fa fa-trash fa-lg"
                                            @click="deleteFile(tagF.path, tagF.id)" aria-hidden="true"></i>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b>
                                            <p>Tipo: </p>
                                        </b>
                                        <p>&nbsp;{{ tagF.tag }}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <p style="font-style: oblique;">{{ new Date(tagF.created_at) }}</p>
                                    </v-col>
                                </v-row>
                                <v-divider />
                            </v-col>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="1">
                </v-col>
            </v-row>
        </v-col>

        <findDataC v-if="posicionCSelect != null && typeCanvas != null"
        :key="posicionCSelect+'_'+typeCanvas"   :treeDirectoriesIndex="mixDataPoints[posicionCSelect]"
        :polygonsLoadedIndex="mixPolygonsLoaded[posicionCSelect]" :treeDirectories="treeDirectories"
        :polygonsLoaded="mixPolygonsLoaded" :tagsAndFiles="tagsAndFiles" :nameProject="projectName"
        :dataVista="mixDataVista[posicionCSelect]" :typeCanvas="typeCanvas" :threeGoogle="threeGoogle" />

        <v-snackbar v-model="deleteDoc" color="red accent-2" outlined timeout="5000" :top="true">
            Se ha eliminado el <strong>archivo</strong>

            <template v-slot:action="{ attrs }">
                <v-btn color="red accent-2" text v-bind="attrs" @click="deleteDoc = false">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>
<script>
/*eslint-disable */
import axios from 'axios'
import findDataC from './FindDataCanvas.vue'

export default {
    name: 'FiltroT',
    components: {
        findDataC
    },
    data() {
        return {
            deleteDoc: false,
            valueTag: [],
            tagFound: [],
            pointsS: [],
            mixPolygonsLoaded: [],
            mixDataPoints: [],
            mixDataVista: [],
            listCanvas: [],
            posicionCSelect: null,
            typeCanvas: null,
            textCanva: '',
            selectCanva: '',
            dataFigRebuild: [],
            dataPointsRebuild: [],
            selectedPoint: '',
            selectedFig: '',
        }
    },
    props: [ 'permitEdit', 'typeDocument', 'dataPointsMpBits', 
        'polygonsLoadedMpBits', 'tagsAndFiles', 'projectName', 'dataMpBits', 'threeGoogle', 
        'treeDirectories', 'dataPoints', 'polygonsLoaded', 'dataGeo', 
        'linesLoaded', 'dateFilt' ],
    mounted: async function () {
        this.initlistC();
        this.pointsS = await this.pointsSearch
    },
    methods: {
        initlistC: async function(){
            var self = this
            await this.dataGeo.map(function(dataG){
                self.listCanvas.push({
                    text: dataG.Name,
                    value: dataG.IdCanvas,
                    typeCanvas: 0 
                })
            })
            await this.dataMpBits.map(function(dataM){
                self.listCanvas.push({
                    text: dataM.Name,
                    value: dataM.IdCanvas,
                    typeCanvas: 1
                })
            })

            await this.polygonsLoaded.map(function(polygonsL){
                self.mixPolygonsLoaded.push(polygonsL)
            })
            await this.polygonsLoadedMpBits.map(function(polygonsLMp){
                self.mixPolygonsLoaded.push(polygonsLMp)
            })

            await this.dataPoints.map(function(dataP){
                self.mixDataPoints.push(dataP)
            })
            await this.dataPointsMpBits.map(function(dataPMb){
                self.mixDataPoints.push(dataPMb)
            })

            await this.dataGeo.map(function(dataG){
                self.mixDataVista.push(dataG)
            })
            await this.dataMpBits.map(function(dataMp){
                self.mixDataVista.push(dataMp)
            })
        },
        filtroB(){
            var data = this.$store.state.findDataCanvas;

            this.dataFigRebuild = [];
            data[0].children.forEach((element) => {
                if(element.created_at == undefined){
                    if(this.typeCanvas == 0){
                        this.dataFigRebuild.push({
                            text: element.text,
                            value: element.text
                        });
                    }else{
                        console.log(element)
                        if(element.parent == this.textCanva){
                            this.dataFigRebuild.push({
                                text: element.text,
                                value: element.text
                            });
                        }
                    }
                }
            });
        },
        listPointG(){
            this.dataPointsRebuild = [];
            var data = this.$store.state.findDataCanvas;
            if(this.selectedFig.length > 0){
                data[0].children.forEach((element) => {
                    if(element.text ==  this.selectedFig){
                        element.children.forEach((element2) =>{
                            if(element.created_at == undefined){
                                this.dataPointsRebuild.push({
                                    text: element2.text,
                                    value: element2.text
                                });
                            }
                        })
                    }
                })
            }else if(this.selectedFig.length == 0){
                data[0].children.forEach((element) => {
                    element.children.forEach((element2) =>{
                        if(element.created_at == undefined){
                            this.dataPointsRebuild.push(element2);
                        }
                    })
                })
            }
        },
        remove(item) {
            this.valueTag.splice(this.valueTag.indexOf(item), 1)
        },
        filtrarPorTag() {
            this.tagFound = [];
            var namePG = this.$store.state.findDataCanvas;

            for (let i = 0; i < this.dateFilt.length; i++) {
                for (let j = 0; j < this.valueTag.length; j++) {
                    if (this.dateFilt[i].tag.toLowerCase() == this.valueTag[j].text.toLowerCase()) {
                        if (this.selectedFig.length > 0 && this.selectedPoint.length == 0) {
                            for (let x = 0; x < namePG.length; x++) {
                                console.log(this.selectedFig)
                                console.log(namePG[x])
                                if (namePG[x].name == this.selectedFig) {
                                    for (let y = 0; y < namePG[x].namePoints.length; y++) {
                                        if (this.dateFilt[i].namePoint == namePG[x].namePoints[y][0]) {
                                            this.tagFound.push({
                                                path: this.dateFilt[i].path,
                                                ubicacion: this.dateFilt[i].ubicacion,
                                                tag: this.dateFilt[i].tag,
                                                title: this.dateFilt[i].title,
                                                id: this.dateFilt[i].idFile,
                                                created_at: this.dateFilt[i].created_at
                                            });
                                        }
                                    }
                                }
                            }
                        } else if (this.selectedPoint.length > 0) {
                            if (this.dateFilt[i].ubicacion == this.selectedPoint) {
                                this.tagFound.push({
                                    path: this.dateFilt[i].path,
                                    ubicacion: this.dateFilt[i].ubicacion,
                                    tag: this.dateFilt[i].tag,
                                    title: this.dateFilt[i].title,
                                    id: this.dateFilt[i].idFile,
                                    created_at: this.dateFilt[i].created_at
                                });
                            }
                        }else {
                            this.tagFound.push({
                                path: this.dateFilt[i].path,
                                ubicacion: this.dateFilt[i].ubicacion,
                                tag: this.dateFilt[i].tag,
                                title: this.dateFilt[i].title,
                                id: this.dateFilt[i].idFile,
                                created_at: this.dateFilt[i].created_at
                            })
                        }
                    }
                }
            }
            this.selectedPoint = "";
            this.tableResul = this.tagFound;
        },
        downloadFile: async function (pathFile, title) {
            var nameFile = pathFile.split('/')[2]
            await axios({
                url: 'https://api.safered.co/api/download-file/' + nameFile,
                method: 'POST',
                responseType: 'blob'
            })
                .then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]))
                    var fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    fileLink.setAttribute('download', title)
                    document.body.appendChild(fileLink)
                    fileLink.click()
                })
        },
        deleteFile: async function (pathFile, id) {
            var self = this
            var refr = this.$store.state.refreshDataGeo + 1
            await axios
                .delete("https://api.safered.co/api/delete-file/" + id + "/" + pathFile.split('/')[2])
                .then(function () {
                    self.deleteDoc = true
                    self.$store.commit("setRefreshDataGeo", refr)
                })
                .catch(function (e) {
                    console.log(e);
                });
        },
    },
    watch: {
        selectedNameFig: function () {
            this.pointsS = [];
            var namePG = this.$store.state.findDataCanvas;

            for (let i = 0; i < namePG.length; i++) {
                if (namePG[i].name == this.selectedNameFig) {
                    for (let j = 0; j < namePG[i].namePoints.length; j++) {
                        this.pointsS.push(namePG[i].namePoints[j][0]);
                    }
                }
            }
        },
        selectCanva: function(){
            var self = this
            this.listCanvas.map(function(listC, index){
                if(self.selectCanva == listC.value){
                    self.textCanva = listC.text
                    self.posicionCSelect = index;
                    self.typeCanvas = listC.typeCanvas;
                }
            })
        }
    }
}
/*eslint-enable */
</script>
<style>

</style>