<template style="margin-top:20px;">
  <div class="center grid">
    <b-row>
      <b-col sm="1">
      </b-col>
      <b-col sm="10">
        <b-card style="margin-top: 29px; margin-bottom: 30px;">
          <div style="height: 90px;">
          </div>
          <b-row>
            <b-col sm="2">
            </b-col>
            <b-col sm="3">
              <createclient-modal />
            </b-col>
            <b-col sm="3">
              <editclient-modal :clients="clients"/>
            </b-col>
            <b-col sm="3">
              <deleteclient-modal :clients="clients"/>
            </b-col>
            <b-col sm="2">
            </b-col>
          </b-row>
          <div style="height: 110px;">
          </div>
        </b-card>
      </b-col>
      <b-col sm="1">
      </b-col>
    </b-row>
  </div>
</template>
<script>
import editclientModal from "./modals/EditClientModal.vue"
import createclientModal from "./modals/CreateClientModal.vue"
import deleteclientModal from "./modals/DeleteClientModal.vue"
import api from "@/api";

export default {
  name: "clientsB",
  components: {
    editclientModal,
    createclientModal,
    deleteclientModal,
  },
  data() {
    return {
      clients: []
    }
  },
  methods: {
    getClients: async function () {
      var clientsTmp = [];
      await api
        .request("get", "/listClients")
        .then((data) => {
          clientsTmp = data.data.clients;
        })
        .catch(function (e) {
          console.log(e);
        });
        this.clients = clientsTmp;
    }
  },
  created() {
    this.getClients()
  }
};
</script>