<template>
  <div class="mb-2 mr-2" style="display: flex;
  justify-content: flex-end;">
    <v-badge
      bordered
      color="green"
      overlap
      dot
      v-if="showDot"
    >
      <v-btn
        rounded
        color="#283271"
        style="color: white;"
        @click="showPocket = true"
      >
        Pocket &nbsp;
        <i class="fa-brands fa-get-pocket fa-xl"></i>
      </v-btn>
    </v-badge>
    <v-btn
      rounded
      color="#283271"
      style="color: white;"
      v-if="!showDot"
      @click="showPocket = true"
    >
      Pocket &nbsp;
      <i class="fa-brands fa-get-pocket fa-xl"></i>
    </v-btn>

    <b-modal v-model="showPocket" size="lg" hide-footer title="Archivos compartidos">
      <b-container fluid style="max-height: 320">
        <v-expansion-panels v-model="panel" v-if="dataPocket.length > 0">
          <v-expansion-panel
            v-for="(item,i) in dataPocket"
            :key="i"
          >
            <v-expansion-panel-header>
              {{item.title}}
            </v-expansion-panel-header>
            <v-expansion-panel-content id="modf">
              <div v-if="item.data.length > 0">
                <div v-for="dataP in item.data" :key="dataP.id">
                  <v-list-item v-if="i == 0 ? dataP.status == 0 || dataP.status == 2 : dataP.status == 0 || dataP.status == 1">
                    <v-list-item-content>
                      <v-list-item-title>{{dataP.nameFile}}</v-list-item-title>
                      <v-list-item-subtitle v-if="i == 0">Compartido por: {{dataP.userSend}}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="i == 1">Enviado a: {{dataP.userReceived}}</v-list-item-subtitle>
                      <v-list-item-subtitle>Fecha del registro: {{dataP.created_at}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-row>
                          <v-col cols="8" style="display: flex;
                            justify-content: flex-end;">
                            <v-btn
                              class="ma-2"
                              color="#57B55D"
                              style="text-transform: none!important; color: white;"
                              small
                              @click="downloadF(dataP.idCloudFile)"
                            >
                              Descargar
                            </v-btn>
                          </v-col>
                          <v-col cols="4" >
                            <v-btn
                              class="ma-2"
                              color="#F3575D"
                              small
                              @click="deleteShare(dataP, i);"
                            >
                              <i class="fa-solid fa-xmark" style="color: white;"></i>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </b-container>
    </b-modal>

  </div>
</template>
<script>

import api from "@/api";
import axios from "axios";

export default {
  name: 'pocketDir',
  data(){
    return{
      panel: 0,
      showPocket: false,
      dataPocket: [],
      token: JSON.parse(localStorage.user).token,
      showDot: false,
    }
  },
  props: [ 'projectId' ],
  mounted: async function(){
    await this.getElementPocket();
  },
  methods:{
    getElementPocket: async function(){
      var self = this
      var obj = {
        'idUserRecive': JSON.parse(localStorage.user).id,
        'idProject': this.projectId
      }
      var dataResponse = [];
      var clientsTmp = null;

      await api
        .request(
          "post",
          "/getPocketFile", obj,
        )
        .then((res) => {
          dataResponse.push({
            title: 'Archivos recibidos:',
            data: res.data.POCKET
          });
        })
        .catch(function (e) {
            console.log(e);
        });

      var objSend = {
        'idUserSend': JSON.parse(localStorage.user).id,
      }

      await api
        .request(
          "post",
          "/getPocketFileSend", objSend,
        )
        .then((res) => {
          console.log(res)
          dataResponse.push({
            title: 'Archivos compartidos por mi:',
            data: res.data.POCKET
          });
        })
        .catch(function (e) {
            console.log(e);
        });

      console.log(dataResponse)
      await api
        .request(
            "get",
            "/listUsers/" + JSON.parse(localStorage.user).client_id + "/clients"
        )
        .then((data) => {
            clientsTmp = data.data.users;
        })
        .catch(function (e) {
            console.log(e);
        });

      this.dataPocket = dataResponse;

      if(dataResponse.length > 0){
        dataResponse[0].data.map(function(obj, index){
          var dataFind = clientsTmp.find( element => element.id == obj.idUserSend);
          var dateM = new Date(obj.created_at)
          console.log(dateM)
          obj = {
            ...obj,
            created_at: dateM.toLocaleDateString('es-CO'),
            userSend: dataFind.name
          }
          self.dataPocket[0].data[index] = obj;
        })

        dataResponse[1].data.map(function(obj1, index1){
          var dataFind1 = clientsTmp.find( element_ => element_.id == obj1.idUserRecive);
          var dateM = new Date(obj1.created_at)
          obj1 = {
            ...obj1,
            created_at: dateM.toLocaleDateString('es-CO'),
            userReceived: dataFind1.name
          }
          self.dataPocket[1].data[index1] = obj1;
        })
      }

      // self.dataPocket[0].data.reverse()
      // self.dataPocket[1].data.reverse()
      
      var filt0 = dataResponse[0].data.filter(obj => obj.status == 0 || obj.status == 2)

      this.showDot = filt0.length > 0 ? true : false
      this.panel = self.dataPocket[0].data.length > 0 ? 0 : self.dataPocket[1].data.length > 0 ? 1 : null 
    },
    deleteShare: async function(element, index){
      var self = this
      if(index == 0){
        var obj = {
          "id": element.id,
          "key": "receiver"
        }
      }else{
        obj = {
          "id": element.id,
          "key": "sender"
        }
      }
      await api
        .request(
          "post",
          "/deleteFileInPocket", obj,
        )
        .then((res) => {
          console.log(res)
          self.getElementPocket()
        })
        .catch(function (e) {
            console.log(e);
        });
    },
    downloadF: async function(id){

      var result = {}
      var obj = {
          idFile: id,
          idProject: this.projectId
      }
      const config = {
          headers: { Authorization: `Bearer ${this.token}` },
      };

      console.log(obj)
      await axios
      .post("https://microservices.safered.co/api/downloadFile", obj, config, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
      })
      .then((res) => result = res.data)
      .catch((e) => console.log(e));

      const base64 = await fetch(result.FileB64)
      const blob = await base64.blob();

      var fileURL = window.URL.createObjectURL(blob);
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", result.NameFile);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  }
}
</script>
<style>
  #modf .v-expansion-panel-content__wrap{
    overflow-y: auto!important;
    max-height: 250px;
  }
</style>