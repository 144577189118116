<template>
    <div>
    <b-modal :title="`Crear Vista`" centered id="CreateCanvas"  hide-footer ref="my-modal" >        
            <v-card-text>
                <v-text-field
                    v-model="tituloCanvas"
                    label="Titulo de la vista"
                    required
                ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn v-if="tituloCanvas.length > 0" variant="success" @click="newGeo() ; dialog = false;">
                        Crear
                    </v-btn>
                    <v-btn v-if="tituloCanvas.length <= 0" disabled variant="success">
                        Crear
                    </v-btn>
            </v-card-actions>
    </b-modal>
    </div>
</template>
<script>

import axios from 'axios';

export default {
    name: "modalCreateVistaGeo",
    data(){
        return{
            dataGeo: "",
            tituloCanvas: "",
            dialog: false,
            idCanvasResponse: null,          
        }
    },
    props:['viewModalCreateVistaGeo', 'dataGeoL', 'permitEdit', 'titleFolder', 'dataMpBits', 'ultimType'],
    
    methods:{
        newGeo: async function(){
            var self = this
            this.$store.commit("setSelect_diretory", null)
            
            if (this.ultimType == 'Geo'){ 
                if (JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true || this.permitEdit){
                    var newDataGeo = [];
                    for (let i = 0; i < this.dataGeo.length; i++) {
                        newDataGeo.push({
                            Figuras: this.dataGeo[i].Figuras,
                            Puntos: this.dataGeo[i].Puntos,
                            Name: this.dataGeo[i].Name
                        })
                    }
                    newDataGeo.push({
                        Figuras: [],
                        Puntos: [],
                        Name: this.tituloCanvas,
                    });

                    this.dataGeo = newDataGeo;
                    await this.saveGeoCloud();
                    this.tituloCanvas = "";
                } else {
                    console.log("No tiene los permisos")
                }
            }else{
                if (JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true || JSON.parse(window.localStorage.user).isEditor == true || this.permitEdit){ 
                    this.idCancasResponse = null
                    var dataCloud = []
                    let formData = new FormData();
                    formData.append("idUser", JSON.parse(localStorage.user).id)
                    formData.append("name", this.tituloCanvas);
                    formData.append("data", JSON.stringify(dataCloud)),
                    formData.append("id_project", this.$store.state.dataInit.idProject)

                    var refr = null 

                    await axios
                    .post('https://api.safered.co/api/setCanvasImage', formData, {
                        headers: {
                        "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(function (response) {
                        self.idCancasResponse = response.data.id;

                    })
                    .catch(function (error) {
                        console.log(error);

                        refr = self.$store.state.notiFail + 1
                        self.$store.commit("setNotiFail", refr)
                    });

                    refr = self.$store.state.refreshImage + 1
                    self.$store.commit("setRefreshImage", refr)
                    // self.toggleModal()
                    this.$store.commit("setSelect_diretory", 3)
                } else {
                    console.log("No tiene los permisos")
                }
       
            }
        },
        saveGeoCloud: async function () {
            this.idCancasResponse = null
            var dataGeoCloud = {
                idProject: this.$store.state.dataInit.idProject,
                name: this.tituloCanvas,
                idUser: JSON.parse(localStorage.user).id
            }
            var self = this
            var refr = null 
            await axios
                .post('https://api.safered.co/api/createCanvasGPS', dataGeoCloud)
                .then(function (response) {
                    self.idCanvasResponse = response.data.canvas.id;
                    console.log(response)
                })
                .catch(function (error) {
                    console.log(error);

                    refr = self.$store.state.notiFail + 1
                    self.$store.commit("setNotiFail", refr)
                   
                });

            if(this.idCanvasResponse != null && this.dataGeoL[0].children.length > 0){
                var obj = {
                    arrayObject: [
                        {
                            idP: this.dataGeoL[0].children[0].id,
                            idS: this.idCanvasResponse,
                            tCanvas: 0
                        }
                    ]
                }

                console.log(obj)

                await axios
                .post('https://api.safered.co/api/createSecondaryCanvas', obj)
                .then(function () {

                    refr = self.$store.state.notiCreateC + 1
                    self.$store.commit("setNotiCreateC", refr)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }else{
                refr = self.$store.state.notiCreateC + 1
                self.$store.commit("setNotiCreateC", refr)
            }

            refr = this.$store.state.refreshDrawGeo + 1
            this.$store.commit("setRefreshDrawGeo", refr)
            this.$store.commit("setSelect_diretory", 2)
            // self.toggleModal()
            
        },
        // toggleModal() {
        //   this.$refs['my-modal'].toggle('#toggle-btn');
        // }
        
    }
}    
        


</script>
<style>
    
</style>