export default {
    projects:{
        welcome_projectsM: "Bienvenido, usted tiene acceso a estos proyectos:"
    },
    Auth:{
        enterEmail_login: "Ingrese su email",
        enterPass_login: "Ingrese su contraseña",
        login: "Acceder",
        forgotPass_login: "¿Olvidaste la contraseña?",
        enterReseting_login: "Ingrese el correo que desea restablecer la contraseña.",
        reset_login: "Restablecer",
        sentMail: "Se ha enviado correo electrónico para restablecer su contraseña",
        emailReq_login: "Correo electrónico obligatorio",
        emailValid_login: "El correo electrónico debe ser válido",
        req_login: "Requerido",
        minCha_login: "Minimo 3 caracteres",
        passReq_login: "La contraseña es obligatoria.",
        emailR_login: "El correo electrónico es obligatorio.",
        validEma_login: "El correo electrónico debe ser válido.",
        passV_login: "Las credenciales no son invalidas",
        userBloc_login: "Este usuario ha sido bloqueado.",
    },
    Structure_p:{
        allRigh_footer: "Todos los derechos reservados.",
        custServ_footer: "Servicio al cliente",
        contUs_footer: "Contáctanos",
        legalInf_footer: "Información legal",
        privPolicy_footer: "Política de protección de datos personales",
        termUse: "Términos y condiciones de uso",
        veracityInf_footer: "Política de veracidad de la información",
        consPro_footer: "Protección al consumidor",

    },
    register:{
        activaAcc_activeAccUser: "Activar cuenta",
        userCreated_activeAccUser: "Se te ha registrado como usuario SAFERED, por favor registra una contraseña e inicia sesión para completar tu perfil.",
        comfAcc_activeAccUser: "Confirmar cuenta",
        enterInf_activeAccUser: "Ingresa tus datos de inicio de sesión",
        name_activeAccUser: "Nombre",
        nameUser_activeAccUser: "Escriba el nombre del usuario:",
        email_activeAccUser: "Dirección de correo electrónico:",
        validEmail_activeAccUser: "Escriba un email valido",
        pass_activeAccUser: "Contraseña:",
        passConfir_activeAccUser: "Confirmar contraseña",
        close_activeAccUser: "Cerrar",
        save_activeAccUser: "Guardar",
        invaLink_activeAccUser: "Link Invalido",
        notCreated_activeAccUser: "No se ha podido crear el usuario, por favor contacte a soporte técnico.",
        statUpd_activeAccUser: "Actualizado el status",
        statUpdNot_activeAccUser: "No se pudo actualizar el status",
        passNotMatch_activeAccUser: "Las contraseñas no coinciden",
        enterValEmail_activeAccUser: "Por favor ingrese un email valido",
        enterValPass_activeAccUser: "Debe ingresar una contraseña valida",
        confPass_activeAccUser: "Confirme la contraseña",
        insetUName_activeAccUser: "Ingrese el nombre del usuario",
        confirmUser_activeAcc: "Se te ha registrado como usuario SAFERED, por favor registra una contraseña e inicia sesión para completar tu perfil.",
        persInf_activeAcc: "Información de administrador",
        contNa_activeAcc: "Nombre de contacto:",
        typeIDN_activeAcc: "Tipo de documento y numero:",
        emailCont_activeAcc: "Email de contacto:",
        phoneCont_activeAcc: "Número telefónico de contacto:",
        coStCi: "País/Estado/Ciudad:",
        address_activeAcc: "Dirección de contacto:",
        setAdmPass_activeAcc: "Establecer email y contraseña de administrador",
        userSucc_activeAcc: "Usuario creado exitosamente"
    },
    modals:{
        infOf_modalPerfil: "Información de",
        downFile_modalPerfil: "Descargar archivo",
        attCanv_modalPerfil: "Asociar al canvas",
        attArea_modalPerfil: "Asociar a área",
        tagAssig_modalPerfil: "Asignación del tag para",
        selTyp_modalPerfil: "Seleccione tipo",
        assArea: "Asignar a un área",
        attPoint_modalPerfil: "Asociar a punto",
        assPoint_modalPerfil: "Asignar a un punto",
        shaPock_modalPerfil: "Compartir al pocket",
        "shaFilePock_modalPerfil {text}": "Compartir el archivo {text} al pocket",
        selUser_modalPerfil: "Seleccione el usuario",
        share_modalPerfil: "Compartir",
        deleFile_modalPerfil: "Eliminar Archivo",
        docAtt_modalPerfil: "Se han asociado el documento",
        assoValid_modalPerfil: "No se ha podido realizar la asociación",
        wiRemNode: "¿Desea eliminar este nodo?",

    }
}