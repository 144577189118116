<template>
    <div>
        <v-card
        style="width: 170px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
        @click="dialog = true">
            <v-img
                :src="icon_edit"
                alt=""
                style="width: 110px"
            />

            <v-card-title>
                <h3 style="font-size: 12px">Editar grupo de usuarios</h3>
            </v-card-title>
        </v-card>

        <v-dialog v-model="dialog" width="700">
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        Elegir proyecto
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                    >
                        Seleccionar grupo
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        :complete="e1 > 3"
                        step="3"
                    >
                        Inf. Grupo
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step
                        step="4"
                    >
                        Integrantes
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card height="250px" class="mb-12" style="display: flex; flex-direction: row;
                        align-items: center;">
                            <v-col>
                                <v-row style="display: flex; justify-content: center;">
                                    <v-col cols="10">
                                        <v-select
                                            v-model="selectP"
                                            :items="listProject"
                                            label="Seleccione el proyecto"
                                            persistent-hint
                                            return-object
                                            single-line
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>

                        <v-btn v-if="!activeLoad"
                        :disabled="selectP.length == 0"
                        color="primary"
                        @click="getProject()"
                        >
                            Continuar
                        </v-btn>

                        <v-btn v-if="activeLoad"
                        color="primary"
                        >
                            <clip-loader
                                :loading="activeLoad"
                                color="#1e88e5"
                                size="10px"
                            ></clip-loader>
                        </v-btn>

                        <v-btn @click="dialog = false; reset()">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                            class="mb-12"
                            height="200px"
                        >
                            <v-row style="display: flex; justify-content: center;">
                                <v-col cols="10">
                                    <v-select
                                        v-model="selectGroup"
                                        :items="listGroup"
                                        label="Seleccione el grupo"
                                        persistent-hint
                                        return-object
                                        single-line
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-btn
                            v-if="!activeLoad"
                            :disabled="selectGroup.length == 0"
                            color="primary"
                            @click="infoGroup()"
                        >
                            Continuar
                        </v-btn>

                        <v-btn v-if="activeLoad"
                        color="primary"
                        >
                            <clip-loader
                                :loading="activeLoad"
                                color="#1e88e5"
                                size="10px"
                            ></clip-loader>
                        </v-btn>

                        <v-btn @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card class="mb-12">
                            <v-col>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="nameGroup"
                                            label="Nombre del grupo"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="descGroup"
                                            filled
                                            label="Descripción"
                                            auto-grow
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>

                        <v-btn
                            :disabled="nameGroup.length == 0 || descGroup.length == 0"
                            color="primary"
                            @click="editGroup()"
                        >
                            Continuar
                        </v-btn>

                        <v-btn @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                        <v-card
                        class="mb-12"
                        height="350px"
                        >
                        
                        <v-row>
                                <v-col cols="6">
                                    <v-list style="height: 180px; overflow-x: hidden; overflow-y: scroll;" flat>
                                        <v-list-item v-for="(value, i) in inteGroup" :key="i">
                                            <v-list-item-content>
                                                <v-row>
                                                    <v-col cols="8" style="display: flex;">
                                                        <v-list-item-title v-if="value.text != undefined" v-text="value.text">
                                                        </v-list-item-title>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-btn
                                                            class="mx-2"
                                                            fab
                                                            dark
                                                            small
                                                            color="red darken-1"
                                                            @click="deleListU(value.value)"
                                                        >
                                                            <v-icon dark>
                                                                mdi-minus
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col cols="8">
                                            <v-select
                                                v-model="selectU"
                                                :items="listUser"
                                                label="Seleccion el usuario"
                                                persistent-hint
                                                return-object
                                                single-line
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="4" style="display: flex; align-items: center;">
                                            <v-btn
                                            class="mx-2"
                                            fab
                                            dark
                                            small
                                            color="green darken-1"
                                            @click="addUserG()"
                                            >
                                                <v-icon dark>
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row v-if="udpsate">
                                <!-- <v-col cols="6">
                                    <v-col>
                                        <p>Rol Actual:</p>
                                        {{actualRol}}
                                    </v-col>
                                </v-col> -->
                                <v-col cols="1"></v-col>
                                <v-col cols="10">
                                    <v-col>
                                        <p>Asignar rol:</p>
                                        <v-select
                                        v-model="selectRol"
                                        :items="opcionesRol"
                                        label="Seleccione el rol"
                                        persistent-hint
                                        return-object
                                        single-line
                                        ></v-select>
                                    </v-col>
                                </v-col>
                                <v-col cols="1"></v-col>
                            </v-row>
                        </v-card>

                        <v-btn
                            color="green"
                            @click="asingUserG()"
                            style="color: white;"
                        >
                            Finalizar
                        </v-btn>

                        <v-btn @click="dialog = false">
                            Cancelar
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>

            <v-snackbar
                v-model="NotimodiG"
                color="success"
                outlined
                timeout="5000"
                :top="true"
            >
                Se ha modificado el <strong>Grupo</strong>

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="success"
                    text
                    v-bind="attrs"
                    @click="NotimodiG = false"
                    >
                    <v-icon>
                        mdi-close
                    </v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            
            <v-snackbar
                v-model="NotiFail"
                color="red accent-2"
                outlined
                timeout="5000"
                :top="true"
            >
                No se puedo realizar la accion

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="red accent-2"
                    text
                    v-bind="attrs"
                    @click="NotiFail = false"
                    >
                    <v-icon>
                        mdi-close
                    </v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

        </v-dialog>
        
        <v-snackbar
            v-model="NotiasignI"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha asignado los <strong>Integrantes</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="NotiasignI = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>

import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import api from '../../../../../../../api/index.js'
import icon_edit from '../../../../../../../assets/img/icons/icon_users_edit.svg'

export default {
    name: 'editGroup',
    components:{
        ClipLoader
    },
    data(){
        return{
            udpsate: false,
            selectGroup: {},
            selectP: {},
            foundGroup: [],
            icon_edit: icon_edit,
            e1: 1,
            dialog: false,
            activeLoad: false,
            listGroup: [],
            nameGroup: '',
            descGroup: '',
            idGroup: null,
            inteGroup: [],
            NotimodiG: false,
            NotiasignI: false,
            NotiFail: false,
            selectU: {},
            newInt: [],
            delInt: [],
            listUser: [],
            selectRol: {},
            opcionesRol: [
                {text: "Usuario", value: 2},
                {text: "Usuario Editor", value: 4}
            ],
        }
    },
    props:[ 'listProject', 'listU' ],
    mounted(){
        this.listUser = this.listU
    },
    methods:{
        addUserG: function(){
            var self = this
            if(self.inteGroup.length <= 0){
                self.inteGroup.push(self.selectU)
                self.newInt.push(self.selectU)
            }else{
                self.inteGroup.map(function(listA){
                    if(listA.value != self.selectU.value){
                        self.inteGroup.push(self.selectU)
                        self.newInt.push(self.selectU)
                    }
                })
            }

            var data = self.delInt.filter((item) => item.value !== self.selectU.value) 
            self.delInt = data

        },
        deleListU: function(id){
            var self = this

            var dataE = self.inteGroup.filter((item) => item.value == id) 
            console.log(dataE)

            var data = self.inteGroup.filter((item) => item.value !== id)
            self.inteGroup = data

            var dataI = self.newInt.filter((item) => item.value !== id)
            self.newInt = dataI

            if(self.delInt.length <= 0){
                self.delInt.push(dataE[0])
            }else{
                self.delInt.map(function(delI){
                    if(delI.value != dataE.value){
                        self.delInt.push(dataE[0])
                    }
                })
            }
        },
        deleteUserG: async function(){
            var self = this
            var obj = []
            
            self.delInt.map(function(delI){
                obj.push({
                    idGroup: self.idGroup,
                    idUser: delI.value
                })
            })

            await api
            .request("delete", "/deleteUserToGroup", obj)
            .then(function (response) {
                console.log(response)

                self.reset()
                self.NotiasignI = true
            })
            .catch(function (e) {
                if(e.response.status == 404){
                    self.reset()
                    self.NotiasignI = true
                }else{
                    self.NotiFail = true
                }
                
            });
        },
        asingUserG: async function(){
            var self = this
            var obj = []
            
            this.newInt.map(function(listA){
                obj.push({
                    idUserEdit:  JSON.parse(localStorage.user).id,
                    idGroup: self.idGroup,
                    idUser: listA.value
                })
            })
                
            await api
            .request("post", "/asignUserToGroup", obj)
            .then(function () {

                self.deleteUserG()
                self.NotiasignI = true
            })
            .catch(function (e) {
                console.log(e);
                self.NotiFail = true
            });

            var rol = []
            if(this.selectRol.value != undefined){
                rol.push({
                    idUser: JSON.parse(localStorage.user).id,
                    idGroup: this.idGroup ,
                    idRole: this.selectRol.value
                })
            }else{
                rol.push({
                    idUser: JSON.parse(localStorage.user).id,
                    idGroup: this.idGroup ,
                    idRole: 2
                })
            }

            await api
            .request("post", "/asignRoleToGroup", rol)
            .then(function(e){
                console.log(e)          
            })
            .catch(function(e){
                console.log(e)
            })
        },
        editGroup: async function(){
            this.activeLoad = true;
            var self = this
            var obj = {
                idUser: JSON.parse(localStorage.user).id,
                name: this.nameGroup,
                description : this.descGroup,
                idGroup: this.idGroup
            }
            await api
            .request("put", "/editGroup", obj)
            .then(function (response) {
                console.log(response)

                self.e1 = 4
                self.activeLoad = false
                self.NotiactuG = true
            })
            .catch(function (e) {
                console.log(e);

                self.activeLoad = false;
            });
        },
        getProject: async function(){
            this.activeLoad = true;
            var self = this

            await api
            .request("get", "/getGroupByProject/" + self.selectP.value)
            .then(function (response) {
                
                response.data.map(function(arr){
                    self.listGroup.push({
                        text: arr.grupos.name,
                        value: arr.grupos.id
                    })
                })

                self.e1 = 2
                self.activeLoad = false
            })
            .catch(function (e) {
                console.log(e);

                self.activeLoad = false;
            });
        },
        infoGroup: async function(){
            var self = this
            await api
            .request("get", "/getGroup/" + self.selectGroup.value)
            .then(function (response) {

                self.descGroup = response.data[0][0].description
                self.nameGroup = response.data[0][0].name
                self.idGroup = response.data[0][0].id

                response.data[1].map(function(arr){
                    self.inteGroup.push({
                        text: arr.name,
                        value: arr.id
                    })
                })

                self.e1 = 3
            })
            .catch(function (e) {
                console.log(e);
            });
        },
        reset: function(){
            this.dialog = false
            this.e1= 1
            this.nameGroup = ''
            this.descGroup = ''
            this.inteGroup = []
            this.delInt = []
            this.newInt = []
            this.activeLoad = false
        },
    },
    watch:{
        dialog: function(){
            if(!this.dialog){
                this.reset();
            }
        },
    }

}
</script>
<style>
    
</style>