<template>
    <div>
        <v-col>
            <v-row>
                <v-col cols="1">
                </v-col>
                <v-col cols="2">
                    <div>
                        <v-select label="Ingrese nombre de la vista" v-model="selectCanva"
                        :items="listCanvas"></v-select>
                    </div>
                </v-col>  
                <v-col cols="2">
                    <div @click="filtroB()">
                        <v-select label="Ingrese nombre del área" v-model="selectedFig"
                        :items="dataFigRebuild"></v-select>
                    </div>
                </v-col>  
                <v-col cols="2">
                    <div @click="listPointG()">
                        <v-select label="Ingrese nombre del punto" v-model="selectedPoint"
                        :items="dataPointsRebuild"></v-select>
                    </div>
                </v-col>
                <v-col>
                    <div @click="listFileF()">
                        <v-combobox
                            label="Ingrese nombre archivo"
                            v-model="selectedFile"
                            :items="dataRebuild" 
                            clearable
                        ></v-combobox>
                    </div>
                </v-col>
                <v-col sm="1">
                    <b-button @click="filtrarX" variant="light">
                        <i class="fa fa-search fa-lg prop_i" aria-hidden="true"></i>
                    </b-button>
                </v-col>
                <v-col cols="1">
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1"></v-col>
                <v-col cols="10" style="height: 443px">
                    <v-list  style="height: 450px; overflow-x: hidden; overflow-y: scroll;" flat>
                        <v-list-item
                            v-for="(fileF, index) in fileFound"
                            :key="index"
                            style="padding-top: 18px;"
                        >
                            <v-col>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b v-if="fileF.text != undefined"><p>Nombre del Archivo: </p></b><p v-if="fileF.text != undefined">&nbsp;{{ fileF.text }}</p>
                                        <b v-if="fileF.text == undefined"><p>Nombre del Archivo: </p></b><p v-if="fileF.text == undefined">&nbsp;{{ fileF.title }}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="10" style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b v-if="fileF.namePoint != undefined"><p>Ubicacion: </p></b>
                                            <p v-if="fileF.namePoint != undefined" >&nbsp;{{fileF.namePoint}}</p>
                                        <b v-if="fileF.namePoint == undefined && fileF.ubicacion != undefined"><p>Ubicacion: </p></b>
                                            <p v-if="fileF.namePoint == undefined &&  fileF.ubicacion != undefined && fileF.ubicacion != 'NaN'">&nbsp;{{fileF.ubicacion}}</p>
                                            <p v-if="fileF.ubicacion == 'NaN'" >N/A</p>
                                        <b v-if="fileF.namePoint == undefined && fileF.ubicacion == undefined"><p>Ubicacion: </p></b>
                                            <p v-if="fileF.namePoint == undefined && fileF.ubicacion == undefined">&nbsp;{{fileF.parent}}</p>
                                    </v-col>
                                    <v-col cols="1" style="display: flex; justify-content: center;">
                                        <i style="cursor: pointer;" class="fa fa-cloud-download fa-lg"
                                        @click="downloadFile(fileF.path, fileF.text)"
                                        aria-hidden="true"></i>
                                    </v-col>
                                    <v-col cols="1" style="display: flex; justify-content: center;">
                                        <i v-if="permitEdit" style="cursor: pointer;" class="fa fa-trash fa-lg"
                                        @click="deleteFile(fileF.path, fileF.idFile)"
                                        aria-hidden="true"></i>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b><p>Tipo: </p></b><p>&nbsp;{{fileF.tag}}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <p style="font-style: oblique;">{{ new Date(fileF.created_at)}}</p>
                                    </v-col>
                                </v-row>
                                <v-divider/>
                            </v-col>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>
        </v-col>
        
        <findDataC v-if="posicionCSelect != null && typeCanvas != null"
        :key="posicionCSelect+'_'+typeCanvas"   :treeDirectoriesIndex="mixDataPoints[posicionCSelect]"
        :polygonsLoadedIndex="mixPolygonsLoaded[posicionCSelect]" :treeDirectories="treeDirectories"
        :polygonsLoaded="mixPolygonsLoaded" :tagsAndFiles="tagsAndFiles" :nameProject="projectName"
        :dataVista="mixDataVista[posicionCSelect]" :typeCanvas="typeCanvas" :threeGoogle="threeGoogle"/>
        
        <v-snackbar
            v-model="deleteDoc"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha eliminado el <strong>archivo</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="deleteDoc = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>

import axios from 'axios';
import findDataC from './FindDataCanvas.vue'

export default {
    name: 'FiltroN',
    components: {
        findDataC
    },
    data(){
        return{
            deleteDoc: false,
            selectedFig: '',
            selectedPoint: '',
            selectCanva: '',
            posicionCSelect: null,
            typeCanvas: null,
            fileFound: [],
            dataFigRebuild: [],
            dataPointsRebuild: [],
            listCanvas: [],
            mixPolygonsLoaded: [],
            mixDataPoints: [],
            mixDataVista: [],
            textCanva: '',
            dataRebuild: [],
            selectedFile: null,
        }
    },
    props: [ 'permitEdit', 'dataPointsMpBits', 'polygonsLoadedMpBits', 'tagsAndFiles', 'projectName', 
        'dataMpBits', 'threeGoogle', 'treeDirectories', 'dataPoints', 'polygonsLoaded', 'dataGeo', 
        'linesLoaded', 'dateFilt' ],
    mounted() {
        this.initlistC();
    },
    methods: {
        initlistC: async function(){
            var self = this
            await this.dataGeo.map(function(dataG){
                self.listCanvas.push({
                    text: dataG.Name,
                    value: dataG.IdCanvas,
                    typeCanvas: 0 
                })
            })
            await this.dataMpBits.map(function(dataM){
                self.listCanvas.push({
                    text: dataM.Name,
                    value: dataM.IdCanvas,
                    typeCanvas: 1
                })
            })

            await this.polygonsLoaded.map(function(polygonsL){
                self.mixPolygonsLoaded.push(polygonsL)
            })
            await this.polygonsLoadedMpBits.map(function(polygonsLMp){
                self.mixPolygonsLoaded.push(polygonsLMp)
            })

            await this.dataPoints.map(function(dataP){
                self.mixDataPoints.push(dataP)
            })
            await this.dataPointsMpBits.map(function(dataPMb){
                self.mixDataPoints.push(dataPMb)
            })

            await this.dataGeo.map(function(dataG){
                self.mixDataVista.push(dataG)
            })
            await this.dataMpBits.map(function(dataMp){
                self.mixDataVista.push(dataMp)
            })
        },  
        filtroB: async function(){
            var data = this.$store.state.findDataCanvasName;

            this.dataFigRebuild = [];
            await data[0].children.forEach(async (element) => {
                if(element.created_at == undefined){
                    if(this.typeCanvas == 0){
                        var verify = element.children.find(element => element.text.includes('.sfr'))

                        if(verify == undefined){
                            this.dataFigRebuild.push({
                                text: element.text,
                                value: element.text
                            });
                        }
                    }else{
                        if(element.parent == this.textCanva){
                            verify = element.children.find(element => element.text.includes('.sfr'))

                            if(verify == undefined){
                                this.dataFigRebuild.push({
                                    text: element.text,
                                    value: element.text
                                });
                            }
                        }
                    }
                }
            });
        },
        listPointG(){
            this.dataPointsRebuild = [];
            var data = this.$store.state.findDataCanvasName;
            if(this.selectedFig.length > 0){
                data[0].children.forEach((element) => {
                    if(element.text ==  this.selectedFig){
                        

                        element.children.forEach((element2) =>{
                            if(element2.created_at == undefined){
                                console.log(element2)
                                this.dataPointsRebuild.push({
                                    text: element2.text,
                                    value: element2.text
                                });
                            }
                        })
                    }
                })
            }else if(this.selectedFig.length == 0){
                data[0].children.forEach((element) => {

                    var verify = element.children.find(element => element.text.includes('.sfr'))

                    if(verify != undefined){

                        this.dataPointsRebuild.push({
                            text: element.text,
                            value: element.text
                        });
                    }

                    element.children.forEach((element2) =>{

                        console.log(element2)
                        if(element2.created_at == undefined && !element2.text.includes('.sfr')){
                            this.dataPointsRebuild.push({
                                text: element2.text,
                                value: element2.text
                            });
                        }
                    })
                })
            }
        },
        listFileF(){
            this.dataRebuild = [];
            var data = this.$store.state.findDataCanvasName;
            console.log(data);
            if(this.selectedFig.length > 0 && this.selectedPoint.length > 0){
                data[0].children.forEach((element) => {
                    if(element.text == this.selectedFig){
                        element.children.forEach((element2) => {
                            if(element2.text == this.selectedPoint){
                                element2.children.forEach((element3) => {
                                    if(element3.tag != undefined){
                                        this.dataRebuild.push({
                                            text: element3.text,
                                            value: element3.value
                                        });
                                    }
                                });
                            }
                        }); 
                    }
                });
            }else if(this.selectedFig.length == 0 && this.selectedPoint.length > 0){
                data[0].children.forEach((element) => {
                    element.children.forEach((element2) => {
                        if(element2.text == this.selectedPoint){
                            element2.children.forEach((element3)=>{
                                if(element3.relacion != null){
                                    this.dataRebuild.push({
                                        text: element3.text,
                                        value: element3.value
                                    });
                                }
                            })
                        }
                    })
                })
            }else if(this.selectedFig.length > 0 && this.selectedPoint.length == 0){
                data[0].children.forEach((element) => {
                    if(element.text == this.selectedFig){
                        element.children.forEach((element2) => {
                            if(element2.relacion != null){
                                this.dataRebuild.push({
                                    text: element2.text,
                                    value: element2.value
                                });
                            }
                            element2.children.forEach((element3) => {
                                if(element3.relacion != null){
                                    this.dataRebuild.push({
                                        text: element3.text,
                                        value: element3.value
                                    });
                                }
                            })
                        })
                    }
                })
            }else{
                if(data != null){
                    data[0].children.forEach((element) => {
                        element.children.forEach((element2) => {
                            if(element2.relacion != null){
                                this.dataRebuild.push({
                                    text: element2.text,
                                    value: element2.value
                                });
                            }
                            element2.children.forEach((element3) => {
                                console.log(element3)
                                if(element3.relacion != null){
                                    this.dataRebuild.push({
                                        text: element3.text,
                                        value: element3.value
                                    });
                                }
                            })
                        })
                    })
                }else{
                    this.dateFilt.forEach((element) => {
                        this.dataRebuild.push({
                                text: element.title,
                                value: element.title
                            });
                    })
                }

            }
        },
        filtrarX(){
            var data = this.$store.state.findDataCanvasName;
            this.fileFound = [];

            if(this.selectedFig.length > 0 && this.selectedPoint.length > 0){
                data[0].children.forEach((element) => {
                    if(element.text == this.selectedFig){
                        element.children.map((element2) => {
                            if(this.selectedPoint == element2.text){
                                element2.children.map((element3) => {
                                    console.log(element3)
                                    if(this.selectedFile != null){
                                        if(element3.value == this.selectedFile.text){
                                            this.fileFound.push(element3);
                                        }
                                    }else{
                                        if(element3.tag != undefined){
                                            this.fileFound.push(element3);
                                        }
                                    }
                                })
                            }
                        })
                    }
                })
            }else if(this.selectedFig.length == 0 && this.selectedPoint.length > 0){
                data[0].children.forEach((element) => {
                    element.children.forEach((element2) => {
                        if(this.selectedPoint == element2.text){
                            element2.children.forEach((element3) => {
                                if(element3.tag != undefined){
                                    this.fileFound.push(element3);
                                }
                            })
                        }
                    })
                })
            }else if(this.selectedFig.length > 0 && this.selectedPoint.length == 0){
                data[0].children.forEach((element) => {
                    if(element.text == this.selectedFig){
                        element.children.forEach((element2) => {
                            if(element2.tag != undefined){
                                if(element2.relacion != null){
                                    this.fileFound.push(element2);
                                }
                            }
                            element2.children.forEach((element3) => {
                                if(element3.tag != undefined){
                                    this.fileFound.push(element3)
                                }
                            })
                        })
                    }
                })
            }else{
                if(data != null){

                    data[0].children.forEach((element) => {
                        console.log(element)

                        element.children.forEach((element2) => {
                            if(element2.tag != undefined){
                                if(element2.relacion != null){

                                    this.fileFound.push(element2);

                                }
                            }
                        
                            element2.children.forEach((element3)=>{
                                if(element3.tag != undefined){
                                    this.fileFound.push(element3);
                                }
                            })
                        })
                    })
                }else if(this.selectedFile != null){
                    this.dateFilt.forEach((element) => {
                        if(element.title == this.selectedFile.text){
                            this.fileFound.push(element);
                        }
                    })
                }else{
                    this.dateFilt.forEach((element) => {
                        this.fileFound.push(element);
                    })
                }
            }
        },
        deleteFile: async function (pathFile, id){
            var self = this
            var refr = this.$store.state.refreshDataGeo + 1
            await axios
                .delete("https://api.safered.co/api/delete-file/"+id+"/"+pathFile.split('/')[2])
                .then(function () {
                    self.deleteDoc = true
                    self.$store.commit("setRefreshDataGeo", refr)
                })
                .catch(function (e) {
                    console.log(e);
                });
        },
        downloadFile: async function (pathFile, title) {
            var nameFile = pathFile.split('/')[2]
            await axios({
                url: 'https://api.safered.co/api/download-file/' + nameFile,
                method: 'POST',
                responseType: 'blob'
            })
                .then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]))
                    var fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    fileLink.setAttribute('download', title)
                    document.body.appendChild(fileLink)
                    fileLink.click()
                })
            },
    },
    watch:{
        selectCanva: function(){
            var self = this
            this.listCanvas.map(function(listC, index){
                if(self.selectCanva == listC.value){
                    self.textCanva = listC.text
                    self.posicionCSelect = index;
                    self.typeCanvas = listC.typeCanvas;
                }
            })
        }
    }
}
</script>
<style>
    
</style>