<template>
    <div>
        <v-col>
            <v-row>
                <v-col cols="1"></v-col>
                <v-col cols="8">
                    <VueCtkDateTimePicker style="width: 100%" v-model="dateSet"
                    :range="true" label="Seleccionar rango de fecha" />
                </v-col>
                <v-col cols="2" style="    display: flex; justify-content: center;">
                    <b-button variant="light" @click="filtrarPorRango()">
                        <i class="fa fa-search fa-lg prop_i" aria-hidden="true"></i>
                    </b-button>
                </v-col>
                <v-col cols="1">
                </v-col>
            </v-row>
            <br />
            <v-row>
                <v-col cols="1">
                </v-col>
                <v-col cols="10" style="height: 476px;">
                    <v-list  style="height: 450px; overflow-x: hidden; overflow-y: scroll;" flat>
                        <v-list-item
                            v-for="(dateF, index) in dateFound"
                            :key="index"
                            style="padding-top: 18px;"
                        >
                            <v-col>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b><p>Nombre del Archivo: </p></b><p>&nbsp;{{ dateF.title }}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="10" style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b><p>Ubicación: </p></b><p>&nbsp;{{dateF.ubicacion}}</p>
                                    </v-col>
                                    <v-col cols="1" style="display: flex; justify-content: center;">
                                        <i style="cursor: pointer;" class="fa fa-cloud-download fa-lg"
                                        @click="downloadFile(dateF.path, dateF.title)"
                                        aria-hidden="true"></i>
                                    </v-col>
                                    <v-col cols="1" style="display: flex; justify-content: center;">
                                        <i v-if="permitEdit" style="cursor: pointer;" class="fa fa-trash fa-lg"
                                        @click="deleteFile(dateF.path, dateF.id)"
                                        aria-hidden="true"></i>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <b><p>Tipo: </p></b><p>&nbsp;{{dateF.tag}}</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col style="display: flex; flex-direction: row; padding-bottom: 0px;
                                    padding-top: 0px;">
                                        <p style="font-style: oblique;">{{ dateF.created_at }}</p>
                                    </v-col>
                                </v-row>
                                <v-divider/>
                            </v-col>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="1">
                </v-col>
            </v-row>
        </v-col>

        <v-snackbar
            v-model="deleteDoc"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha eliminado el <strong>archivo</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="deleteDoc = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>
<script>

import axios from 'axios'

export default {
    name: 'FiltroF',
    data(){
        return{
            deleteDoc: false,
            dateSet: {},
            dateFound: []
        }
    },
    props: [ 'dateFilt', 'permitEdit'],
    async mounted(){
        console.log(this.dateFilt)
    },
    methods:{
        filtrarPorRango() {

            this.dateFound = [];
            var start = new Date(this.dateSet.start);
            var end = new Date(this.dateSet.end);

            for (let i = 0; i < this.dateFilt.length; i++) {
                console.log(this.dateFilt[i])
                var created_at = new Date(this.dateFilt[i].created_at);

                if (created_at >= start && created_at <= end) {
                    var tmp = created_at.getFullYear() + "-" + (created_at.getMonth() + 1) + "-" + created_at.getDate() + " " + created_at.getHours() + ":" + created_at.getMinutes()
                    this.dateFound.push({
                        id:  this.dateFilt[i].idFile,
                        created_at: tmp,
                        path: this.dateFilt[i].path,
                        ubicacion: this.dateFilt[i].ubicacion,
                        tag: this.dateFilt[i].tag,
                        title: this.dateFilt[i].title
                    })
                }
            }
        },
        downloadFile: async function (pathFile, title) {
            var nameFile = pathFile.split('/')[2]
            await axios({
                url: 'https://api.safered.co/api/download-file/' + nameFile,
                method: 'POST',
                responseType: 'blob'
            })
            .then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]))
                var fileLink = document.createElement('a')
                fileLink.href = fileURL
                fileLink.setAttribute('download', title)
                document.body.appendChild(fileLink)
                fileLink.click()
            })
        },
        deleteFile: async function (pathFile, id){
            var self = this
            var refr = this.$store.state.refreshDataGeo + 1

            await axios
                .delete("https://api.safered.co/api/delete-file/"+id+"/"+pathFile.split('/')[2])
                .then(function () {
                    self.deleteDoc = true                  
                    self.$store.commit("setRefreshDataGeo", refr)
                })
                .catch(function (e) {
                    console.log(e);
                });
        },
    },
    watch:{

    }
}
</script>
<style>
    
</style>