<template>
    <div>
        <v-card
        style="width: 185px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
        @click="dialog=true">
            <v-img
                :src="icon_delete"
                alt=""
                style="width: 110px"
            />

            <v-card-title>
                <h3 style="font-size: 12px">Eliminar empresa</h3>
            </v-card-title>
        </v-card>
        <v-dialog v-model="dialog" width="650">
            <v-card>
                <v-card-title class="text-h5">
                    Eliminar empresa
                </v-card-title>

                <v-container style="height: 180px; display: flex; align-items: center;">
                    <v-row >
                        <v-combobox
                            v-model="selectBusiness"
                            :items="dataBusiness"
                            label="Seleccione la empresa que desea eliminar"
                            item-text="name"
                            required
                        ></v-combobox>
                    </v-row>
                </v-container>
                <div style="display: flex; justify-content: flex-end;">
                    <v-btn
                        @click="deleteBusiness()"
                        class="mb-3 mr-3"
                        type="submit"
                        :disabled="selectBusiness == null"
                        color="success"
                    >
                        Eliminar
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="notiDelete"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
            Se ha eliminado la empresa

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="notiDelete = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar
            v-model="notiNoDelete"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
            No se pudo eliminar la empresa

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="notiNoDelete = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>

import api from "@/api";
import icon_delete from '../../../../../../../assets/img/icons/icon_delete_business.svg'

export default {
    
    name: 'deleteBusiness',
    data(){
        return{
            icon_delete: icon_delete,
            dialog: false,
            selectBusiness: null,
            notiDelete: false,
            notiNoDelete: false
        }
    },
    props: [ 'dataBusiness' ],
    mounted(){

    },
    methods:{
        async deleteBusiness(){
            var self = this

            await api
            .request("delete", `/company/${this.selectBusiness.id}/delete`)
            .then((data) => {
                if(data.data.msj == "EMPRESA ELIMINADA"){

                    self.dialog = false
                    self.notiDelete = true
                    self.selectBusiness = null
                    var count = 1 + self.$store.state.refreshBusiness;
                    self.$store.commit("setRefreshBusiness",  count);
                }else{
                    self.notiNoDelete = true
                }
            })
            .catch(function () {
                self.notiNoDelete = true;
            });
        }
    }
}
</script>

<style>

</style>