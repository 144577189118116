<template>
    <b-card>
        <v-col>
            <v-row>
                <v-col cols="2">
                </v-col>
                <v-col cols="3" style="display: flex; flex-direction: column; align-items: flex-start">
                    <strong>Ingrese el nombre del tipo de documento:</strong>
                    <b-form-input
                    id="name-typeD"
                    v-model="nameT"
                    placeholder=""
                    required
                    ></b-form-input>
                </v-col>
                <v-col cols="4" style="display: flex; flex-direction: column; align-items: flex-start">
                    <strong>Ingrese descripción del tipo de documento:</strong>
                    <b-form-textarea
                    id="desc-typeD"
                    v-model="descriptionT"
                    placeholder=""
                    required
                    ></b-form-textarea>
                </v-col>
                <v-col cols="1" style="display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">
                    <v-btn
                        class="mx-2"
                        fab
                        dark
                        color="blue darken-1"
                        v-b-tooltip.hover variant="outline-info" title="Añadir tag" 
                        @click='addDataTag()'
                    >
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    </v-btn>
                </v-col>
                <v-col cols="1">
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="8">
                    <b-list-group style="overflow-x: hidden; overflow-y: scroll; height: 348px;">
                        <b-list-group-item v-for="(dataT, index) in dataTags" :key="dataT.id" :id="index">
                            <v-row>
                                <v-col cols="10" style="text-align: initial;">
                                    <v-row>
                                        <v-col>
                                            <h6><strong>{{dataT.name}}</strong></h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="2">
                                            <h6>Descripción: </h6>
                                        </v-col>
                                        <v-col cols="9">
                                            {{dataT.description}}
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="1" style="text-align: center;">
                                    <br/>
                                    <i style="cursor: pointer;" class="fa fa-pencil-square-o fa-lg" aria-hidden="true" @click="setUpdate(index)"></i>
                                </v-col>
                            </v-row>
                        </b-list-group-item>
                    </b-list-group>
                </v-col>
                <v-col cols="2"></v-col>
            </v-row>
            <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="1" style="display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">
                    <v-btn
                        depressed
                        color="green darken-1"
                        v-b-tooltip.hover title="Enviar Tags" @click="sendTags();"
                    >
                        <i class="fa fa-paper-plane-o" aria-hidden="true" style="color: white"></i>
                    </v-btn>
                </v-col>
                <v-col cols="9"></v-col>
            </v-row>
        </v-col>
        <v-snackbar
            v-model="NotiTypeD"
            color="success"
            outlined
            timeout="5000"
            :top="true"
        >
        Se ha asignado el <strong>tipo de documento</strong>

            <template v-slot:action="{ attrs }">
                <v-btn
                color="success"
                text
                v-bind="attrs"
                @click="NotiTypeD = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar
            v-model="NotiFail"
            color="red accent-2"
            outlined
            timeout="5000"
            :top="true"
        >
        No se puedo realizar la acción

            <template v-slot:action="{ attrs }">
                <v-btn
                color="red accent-2"
                text
                v-bind="attrs"
                @click="NotiFail = false"
                >
                <v-icon>
                    mdi-close
                </v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </b-card>
</template>
<script>

import axios from 'axios'

export default {
    name: "confTipoDoc",
    data(){
        return{
            NotiTypeD: false,
            NotiFail: false,
            nameT: '',
            descriptionT: '',
            dataTags: [],
            positionS: null,
        }
    },
    mounted: async function(){
        await this.cargarTags();
    },
    props:['listTags'],
    methods:{
        cargarTags: async function(){
            var data = this.listTags
            for(let i=0;i<data.length;i++){
                this.dataTags.push({
                    name: data[i].nameTag,
                    description: data[i].description,
                    idProject: this.$store.state.dataInit.idProject
                });
            }
        },
        addDataTag(){

            if(this.positionS != null){
                for(let i = 0; i < this.dataTags.length; i++){
                    if(this.positionS == i){
                        this.dataTags[i] = {
                            name: this.nameT,
                            description: this.descriptionT,
                            idProject: this.$store.state.dataInit.idProject
                        }
                    }
                }               
            }else{
                this.dataTags.push({
                    name: this.nameT,
                    description: this.descriptionT,
                    idProject: this.$store.state.dataInit.idProject
                });
            }
            this.positionS = null
            this.nameT = ''
            this.descriptionT = ''
        },
        setUpdate(index){
            this.nameT = this.dataTags[index].name;
            this.descriptionT = this.dataTags[index].description;
            this.positionS = index;
        },
        sendTags: async function(){
            var obj = this.dataTags;
            var self = this

            await axios
            .post(
            "https://api.safered.co/api/addTag", obj)
            .then((result) => {
                if(result.status == 200){
                    self.NotiTypeD = true;
                    var refr = this.$store.state.refreshDataGeo + 1
                    this.$store.commit("setRefreshDataGeo", refr)
                }
            }).catch(() =>{
                self.NotiFail = true;
            });

        },
    },
    watch:{
        '$store.state.dataInit': function(){
            this.cargarTags();
        },
    }
}
</script>
<style>
    
</style>