<template >
    <div>
        <v-card  v-b-tooltip.hover.righttop="'Visualizar perfil!'" @click="viewPerfil()" style="cursor: pointer; background: transparent; 
            box-shadow: none;">
            <v-row>
                <v-col>
                    <p class="white--text text_large_pe" style="width: 145px; margin-bottom: 0px;">
                        {{user.name}}
                    </p>
                    <p class="white--text" style="margin-bottom: 0px; font-size: 12px;">
                        {{textUser}}
                    </p>
                </v-col>
                <v-col>
                    <v-avatar style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px;"
                        color="grey"
                        size="40"
                    >
                        <img v-if="existProfie"
                            :src="imageForm"
                            :alt="initialName">
                        <span v-if="!existProfie" class="white--text text-h5">{{initialName}}</span>
                    </v-avatar>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog
            v-model="dialog"
            width="500"
        >
                <v-card>
                    <v-img
                        v-if="waitFile"
                        height="150px"
                        :src="existBnner ? imageFormBanner : logoS"
                        @click="dialog2 = true"
                    >
                        <v-card-title class="white--text mt-8">
                            <v-badge
                                v-if="!existProfie"
                                bordered
                                bottom
                                color="green darken-3"
                                dot
                                offset-x="20"
                                offset-y="20"
                                style="cursor: pointer;"
                                v-b-tooltip.hover.righttop="'Cambiar foto de perfil'"
                            >
                                <v-avatar
                                    color="grey"
                                    size="100"
                                    v-if="!existProfie"
                                >
                                    <span class="white--text text-h5">{{initialName}}</span>
                                </v-avatar>
                            </v-badge>
                            <v-badge
                                v-if="existProfie"
                                bordered
                                bottom
                                color="green darken-3"
                                dot
                                offset-x="20"
                                offset-y="20"
                                style="cursor: pointer;"
                                v-b-tooltip.hover.righttop="'Cambiar foto de perfil'"
                            >
                                <v-avatar size="100" v-if="existProfie">
                                    <img
                                        :src="imageForm"
                                        :alt="initialName"
                                    >
                                </v-avatar>
                            </v-badge>
                        </v-card-title>
                    </v-img>

                    <v-card-text style="background-color: #283271; ">
                            <v-col>
                                <v-row>
                                    <h3 style="color: white; margin-top: 25px;">
                                        {{user.name}}
                                    </h3>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <h5 style="color: white">
                                            Tipo de cuenta: 
                                        </h5>
                                        <h6 style="color: white">
                                            <p>{{textUser}}</p>
                                        </h6>
                                    </v-col>
                                    <v-col>
                                        <h5 style="color: white; size:16px;">
                                            Correo electrónico:
                                        </h5>
                                        <h6 style="color: white">
                                            {{user.email}}
                                        </h6>
                                    </v-col>
                                </v-row>
                            </v-col>
                    </v-card-text>
                    <v-card-actions style="display: flex; background-color: rgb(40, 50, 113);
                        justify-content: space-between;">
                        <v-col cols="6">
                            <v-select
                                color="white"
                                dark
                                @change="changeLenguage()"
                                :items="items"
                                label="Cambiar Lenguaje"
                                v-model="lenguage"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" style="display: flex; justify-content: flex-end;">
                            <v-btn
                                depressed
                                color="error"
                                @click="logout"
                            >
                                Cerrar sesión
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
        </v-dialog>

        <v-dialog width="450" v-model="dialog2">
            <v-card style="padding-bottom: 10px">
                <v-card-title>
                    Cargar imagen
                </v-card-title>

                <div class="con-form">
                    <b-container class="mt-3" fluid>
                    <b-form @submit.stop.prevent="onSubmit">
                        <div class="d-flex mb-3">
                            <v-file-input
                                v-model="image"
                                chips
                                accept="image/png, image/jpeg, image/bmp"
                                label="Seleccionar una imagen"
                            ></v-file-input>
                            <b-button
                                v-if="hasImage"
                                variant="danger"
                                class="ml-3"
                                @click="clearImage"
                                >Limpiar imagen</b-button
                            >
                        </div>

                        <b-img
                            v-if="hasImage"
                            :src="imageSrc"
                            class="mb-3"
                            fluid
                            block
                            rounded
                            :style="'height: 250px; width: auto;'"
                        ></b-img>

                        <div style="display: flex; flex-direction: row; justify-content: center;">
                            <b-button :disabled="!hasImage" variant="primary" type="submit"
                                @click="setImage">
                                Cargar imagen
                            </b-button>
                        </div>
                    </b-form>
                    </b-container>
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>

import axios from "axios";
import LogoS from '../../assets/img/logos/safered-logo-01_back.png'

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
    name: "modalPerfil",
    data(){
        return{
            existBnner: false,
            existProfie: false,
            waitFile: false,
            dialog: false,
            dialog2: false,
            logoS: LogoS,
            image: null,
            imageSrc: null,
            imageForm: null,
            imageFormBanner: null,
            permission: null,
            textUser: '',
            items: [
                {
                    text: 'Español',
                    value: 'es'
                },
                {
                    text: 'English',
                    value:'en'
                }
            ],
            lenguage: null,
                
        }
    },
    props:['user', 'initialName'],
    async mounted(){
        this.getLogoClient();
        this.avatarGet();
    },
    computed: {
        hasImage() {
        return !!this.image;
        },
    },
    methods: {
        changeLenguage: async function(){
            localStorage.setItem("lang", this.lenguage);
            window.location.reload();
        },
        updateProfile: async function(){
            let formData = new FormData();
            formData.append("file", this.image);
            formData.append("id_tag", 15),
            formData.append("user_id", JSON.parse(window.localStorage.user).id)

            await axios
                .post("https://api.safered.co/api/UpdateProfilePick", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function () {
                    alert('Imagen de perfil cargada exitosamente.')
                    this.formatPoints();
                })
                .catch(function (e) {
                    console.log(e);
                });
        },
        avatarGet: async function(){
            var imgProfile = null;
            var data = {
                "user_id": JSON.parse(window.localStorage.user).id,
                "id_tag": 15
            };
            await axios
            .post('https://api.safered.co/api/GetProfilePick', data, {responseType: 'blob'})
                .then((response) => {
                imgProfile = response
            })
            .catch(function (e) {
                console.log(e);
            });
            if(imgProfile.data.size > 55){
                this.imageForm = URL.createObjectURL(imgProfile.data);
                this.existProfie = true;
            }else{
                this.existProfie = false;
            }
        },
        getLogoClient: async function(){
            var imgProfile = null;
            var data = {
                "user_id": JSON.parse(window.localStorage.user).client_id,
                "id_tag": 17
            };
            await axios
                .post('https://api.safered.co/api/getLogoClient', data, {responseType: 'blob'})
                    .then((response) => {
                    imgProfile = response
                })
                .catch(function (e) {
                    console.log(e);
                });

                if(imgProfile.data.size > 55){
                    this.imageFormBanner = URL.createObjectURL(imgProfile.data);
                    this.existBnner = true;
                }else{
                    this.existBnner = false;
                }
            
            this.waitFile = true
        },
        viewPerfil(){
            this.dialog = true
        },
        logout: async function(){
            localStorage.removeItem("user");
            localStorage.removeItem("info_event");
            localStorage.removeItem("canvas_info");
            await this.$router.replace("/");
            window.location.reload();
        },
        uploadDocumentsP: async function(){
            let formData = new FormData();

            formData.append("point_id", null);
            formData.append("file", this.image);
            formData.append("tag_id", 15),
            formData.append("project_id", null),
            formData.append("user_id", JSON.parse(window.localStorage.user).id)

            await axios
                .post("https://api.safered.co/api/store-file", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function () {
                    alert('Imagen de perfil cargada exitosamente.')
                })
                .catch(function (e) {
                    console.log(e);
                });

        },
        async setImage() {
            let URL = window.URL;
            this.imageForm = URL.createObjectURL(this.image);
            if(this.existProfie == false){
                this.uploadDocumentsP();
            }else{
                this.updateProfile();
            }
        },
        clearImage() {
            this.image = null;
        },
        onSubmit() {
            if (!this.image) {
                alert("Por favor seleccione una imagen.");
                return;
            }
        },
        tipoUser: function(){
            console.log(this.permission)
            if(this.permission != 0){
                if(this.permission == true && this.user.isAdmin){
                    this.textUser = "Administrador"
                }else if(this.permission == true && !this.user.isAdmin){
                    this.textUser = "Usuario Editor"
                }else{
                    this.textUser = "Usuario Base"
                }
                
            }else{
                if(this.user.isAdmin){
                    this.textUser = "Administrador"
                }else{
                    this.textUser = "Usuario Base"
                }  
            }
        }
    },
    watch:{
        user: function(){
            if(this.user.isAdmin){
                this.textUser = "Administrador"
            }else{
                this.textUser = "Usuario Base"
            }            
        },
        image(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                base64Encode(newValue)
                    .then((value) => {
                    this.imageSrc = value;
                    })
                    .catch(() => {
                    this.imageSrc = null;
                    });
                } else {
                this.imageSrc = null;
                }
            }
        },
        '$store.state.permission': function(){
            console.log(this.$store.state.permission)
            this.permission = this.$store.state.permission
            this.tipoUser()
        }
    }
    
}
</script>
<style>
    p.text_large_pe{
        font-size: 1.15rem!important;
        font-weight: 400!important;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>