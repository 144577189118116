<template>
    <b-modal centered id='CreateUploadFile' :title="`Cargar archivo a la carpeta ${editingItem}`" hide-footer ref="my-modal"> 
      <v-row>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
          <b-form-group
            id="input-group-1"
            label="folderId:"
            label-for="input-1"
            description="Carpeta contenedora"
            v-show="false"
          >
            <b-form-input
              id="folderId"
              v-model="form.folderId"
              type="text"
              placeholder="input folderId"
              required
            >
            </b-form-input>
          </b-form-group>

          <b-form-group>
            <v-row>
              <v-col :cols="typeDirct == 'Aux' ? '6' : '12'">
                <v-file-input
                  v-model="form.sampleFile"
                  :state="Boolean(form.sampleFile)"
                  label="Clic para cargar archivo"
                ></v-file-input>
              </v-col>
              <v-col  v-if="typeDirct == 'Aux'" cols="6">
                <v-combobox
                  v-model="select"
                  :items="nameFolders"
                  label="Seleccionar carpeta"
                  hint="Asosiar carpeta existente en directorio global"
                  persistent-hint
                ></v-combobox>
              </v-col>
            </v-row>
          </b-form-group>

          <b-button v-if="typeDirct == 'Aux'" :disabled="select.length == 0" type="submit" variant="success" style="color: white;">Cargar archivo</b-button>
          <b-button v-if="typeDirct == 'Make'" type="submit" variant="success" style="color: white;">Cargar archivo</b-button>
        </b-form>
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="dialog"
        >
        <v-card>
            <v-card-text>
              <div class="text-h6 pa-12">El archivo que intenta cargar está duplicado.</div>
              <p>Ingrese un nombre diferente o se le asignara un consecutivo al final</p>
              <v-text-field
                :label="nameFileRandom"
                hide-details="auto"
                v-model="mameFile"
                :rules="fileRules"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                :disabled="!statusVerify"
                color="#57B55D"
                class="ma-2"
                style="color: white;"
                @click="reeSendFile();"
              >Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
     </v-row> 
    </b-modal>
      

</template>
<script>

import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import axios from "axios";

export default {
  data() {
    return {
      form: {
        folderId: "",
        mimeType: "",
        idProject: "",
        sampleFile: null,
      },
      fileRules: [
        v =>  !/[():<>*\;"\\|.\/?~]/.test(v) || 'No se admiten caracteres especiales', //eslint-disable-line
      ],
      statusVerify: true,
      nameFileRandom: '',
      mameFile: null,
      currentUser: null,
      show: true,
      select: [],
      dialog: false,
    };
  },
  props:['nameFolders', 'typeDirct', 'idFather', 'folderId', 'projectId', 'editingItem'],
  async created(){
    this.currentUser = JSON.parse(localStorage.user)
    this.form.folderId = this.idFather
  },
  methods: {
    onSubmit_test: function(){
      console.log(this.form.sampleFile)
    },  
    onSubmit(event) {
      event.preventDefault();
      this.form.mimeType = this.form.sampleFile.type
      this.uploadDocuments()
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    uploadDocuments: async function () {
        
        var self = this
        let formData = new FormData();

        if(this.typeDirct == 'Make'){
          formData.append("folderId", this.idFather)
        }else{
          formData.append("folderId", this.select.id)
        }

        var nameFile = this.form.sampleFile.name.replaceAll('(', '[');
        nameFile = nameFile.replaceAll(')', ']');

        var f = new File([this.form.sampleFile], `${nameFile}`, {type: this.form.sampleFile.type, lastModified: Date()})

        formData.append("mimeType", await this.form.sampleFile.type)
        formData.append("idProject", this.projectId)
        formData.append("sampleFile", f)
        formData.append("emailClient", this.currentUser.clientInfo.emailContacto)
        

        console.log(this.form)
        var token = JSON.parse(localStorage.user).token;
        
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        
        await axios
        .post("https://microservices.safered.co/api/storeFile", formData, config,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          function(response){
            console.log(response)

            if(self.typeDirct == 'Make'){

              var count = self.$store.state.refreshDGeneral + 1;
              self.$store.commit("setRefreshDGeneral", count);
              alert("Archivo cargado exitosamente.")
              self.toggleModal();
            }else{
              var data = {
                nameFile: self.form.sampleFile.name,
                folder: self.idFather, 
                type: 'File'
              }
              self.$store.commit("setAddTempDirectFlex", data)
            }
            self.form.folderId = ""
            self.form.mimeType = ""
            self.form.idProject = ""
            self.form.sampleFile = null
          }
        ).catch(function(e){
          if(e.response.status == 409){
            var dataResponse = e.response.data.resultsLike
            var count = dataResponse.length
            var numSecuente = count +1

            if(count > 0){
              dataResponse.map(function(obj, index){
                var data = dataResponse.find(element => element.text.includes(`(${index+1})`))
                if(data == undefined){
                  numSecuente = index+1
                }
              })               
            }
            

            var nameF = self.form.sampleFile.name.split('.')

            self.nameFileRandom = `${nameF[0]}(${numSecuente})`
            self.dialog = true;
          }
        }
        );
    },
    reeSendFile: async function(){
      var nameF = this.form.sampleFile.name.split('.')
      var f = null

      if(this.mameFile == nameF[0] || this.mameFile == null){
        f = new File([this.form.sampleFile], `${this.nameFileRandom}.${nameF[1]}`, {type: this.form.sampleFile.type, lastModified: Date()})
        this.form.sampleFile = f
      }else{
        f = new File([this.form.sampleFile], `${this.mameFile}.${nameF[1]}`, {type: this.form.sampleFile.type, lastModified: Date()})
        this.form.sampleFile = f
      }

      var self = this
      let formData = new FormData();

      if(this.typeDirct == 'Make'){
        formData.append("folderId", this.idFather)
      }else{
        formData.append("folderId", this.select.id)
      }

      formData.append("mimeType", await this.form.sampleFile.type)
      formData.append("idProject", this.projectId)
      formData.append("sampleFile", this.form.sampleFile)
      formData.append("emailClient", this.currentUser.clientInfo.emailContacto)
      
      var token = JSON.parse(localStorage.user).token;
      
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      
      await axios
      .post("https://microservices.safered.co/api/storeFile", formData, config,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(
        function(response){
          console.log(response)

          if(self.typeDirct == 'Make'){

            var count = self.$store.state.refreshDGeneral + 1;
            self.$store.commit("setRefreshDGeneral", count);
            alert("Archivo cargado exitosamente.")
            
          }else{
            var data = {
              nameFile: self.form.sampleFile.name,
              folder: self.idFather, 
              type: 'File'
            }
            self.$store.commit("setAddTempDirectFlex", data)

          }

          self.form.folderId = ""
          self.form.mimeType = ""
          self.form.idProject = ""
          self.form.sampleFile = null
          self.dialog = false;
        }
      ).catch(function(e){
        if(e.response.status == 409){
          console.log(e)
        }
      }
      );

    },
    toggleModal() {
           this.$refs['my-modal'].toggle('#toggle-btn');
        }
  },
  watch:{
    mameFile: function(){
      this.statusVerify = !/[():<>*\;"\\|.\/?~]/.test(this.mameFile); //eslint-disable-line
    }
  }
};
</script>
<style>
.custom-file-label::after {
  content: "Cargar archivo" !important;
}
</style>