<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :retain-focus="false"
      persistent
    >
        <v-card>
            <v-toolbar
            dark
            color="#283271"
            >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Novedades</v-toolbar-title>
            <v-spacer></v-spacer>
            </v-toolbar>
            <TimeL :newsArrays="newsArrays" :idProject="idProject" />
        </v-card>
    </v-dialog>
</template>
<script>

import TimeL from './TimeLine'
import api from '../../../../../api/index.js'

export default {
    name: 'BodyN',
    components: {
        TimeL
    },
    data(){
        return{
            dialog: false,
            newsArrays: [],
            idProject: null,
        }
    },
    props: [ 'activeNovedades' ],
    mounted: async function(){
        this.getNewsForProject();
        this.idProject = this.$store.state.dataInit.idProject 
    },
    methods:{
        getNewsForProject: async function(){
            var idProject = this.$store.state.dataInit.idProject 

            var newsTmp = [];
            await api
            .request("post", "/last-files/" + idProject)
            .then((data) => {
                newsTmp.push(data.data)
            })
            .catch(function (e) {
                console.log(e);
            });
            this.newsArrays = newsTmp
        }
    },
    watch:{
        activeNovedades: function(){
            if(this.activeNovedades == true){
                this.dialog = this.activeNovedades
            }
        },
        dialog: function(){
            if(this.dialog == false){
                this.$store.commit("setModalNovedades", false)
            }
        },
    }
}
</script>
<style>
    
</style>