import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en'
import es from './locales/es'

Vue.use(VueI18n)

const messages = {
    en: en,
    es: es
}

const i18n = new VueI18n({
    locale: localStorage.getItem("lang"),
    messages
})

export default i18n