<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="8">
            <v-card style="margin-top: 18px" class="mx-auto" height="480">
              <v-row style="
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                ">
                <v-col style="margin-top: 15px" cols="3">
                  <v-select v-model="selectList" :items="TypeList" label="Seleccione tipo de lista" outlined dense></v-select>
                </v-col>
                <v-col style="margin-top: 15px" cols="3" v-if="selectList == 'Usuarios'">
                  <v-combobox v-model="nameUser" :items="users" label="Ingrese usuario" outlined dense></v-combobox>
                </v-col>
                <v-col style="margin-top: 15px" cols="3" v-if="selectList == 'Grupos'">
                  <v-combobox v-model="nameGroup" :items="groups" label="Ingrese grupo" outlined dense></v-combobox>
                </v-col>
                <v-col cols="1" style="display: flex;
                  justify-content: center; margin-top: 10px
                  align-items: flex-start;">
                  <v-btn class="mx-2" small fab dark color="light-green darken-4" @click="getDataPer()">
                    <v-icon dark> mdi-account-search </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <h6 style="margin-left:6px; margin-bottom: 6px;" v-if="items.length > 0">Seleccione las carpetas o documentos que desea bloquear:</h6>
              <v-row>
                <v-container v-if="items.length > 0">
                  <div style="overflow-x: scroll;
                    overflow-y: scroll;
                    height: 360px;">
                    <v-treeview 
                      v-model="selectItem" 
                      :items="items"
                      :open="initiallyOpen"
                      :active="active"
                      :multiple-active="true"
                      color="#F44336"
                      selection-type="independent"
                      item-key="id"
                      selectable
                      item-text="text"
                      open-all
                      transition
                    >
                      <template v-slot:prepend="{ item, open }">
                        <v-icon v-if="item.icon == ''">
                          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                        </v-icon>
                        <v-icon v-else>
                          {{ 'mdi-file-document-outline' }}
                        </v-icon>
                      </template>
                    </v-treeview>
                  </div>
                </v-container>
                <v-container v-if="items.length == 0">
                  <div style="overflow-x: hidden;
                    overflow-y: scroll;
                    height: 360px; background-color: rgb(181 181 181);
                    display: flex;
                    justify-content: center;
                    align-items: center;">
                    <h5 style="color: white;">Seleccione un usuario o grupo</h5>
                  </div>
                </v-container>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="1" style="display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">
            <v-btn
              style="margin-bottom: 25px;"
              :disabled="items.length > 0 ? false : true"
              depressed
              color="green darken-1"
              v-b-tooltip.hover title="Enviar reglas" @click="sendRules();"
            >
              <i class="fa fa-paper-plane-o" aria-hidden="true" style="color: white"></i>
            </v-btn>
          </v-col>
          <v-col cols="9" style="display: flex; align-items: center;">
            <p v-if="items.length > 0" style="">Obs. Los archivos o carpetas que se encuentren en las carpetas seleccionadas se verán afectadas.</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="NotiPriva"
      color="success"
      outlined
      timeout="5000"
      :top="true"
    >
      Se han realizado los cambios de <strong>privacidad</strong>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="NotiPriva = false"
        >
          <v-icon>
              mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="NotiFail"
      color="red accent-2"
      outlined
      timeout="5000"
      :top="true"
    >
      No se ha podido realizar los cambios

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red accent-2"
          text
          v-bind="attrs"
          @click="NotiFail = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>
<script>
import axios from "axios";
import api from "@/api";

export default {
  name: "configPrivaciDoc",
  data() {
    return {
      NotiFail: false,
      NotiPriva: false,
      users: [],
      nameUser: {
        text: "",
        value: ""
      },
      token: '',
      dataRules: null,
      data: [],
      items: [],
      selectItem: [],
      active: [],
      initiallyOpen: ['public'],
      selectList: 'Usuarios',
      TypeList: [
        {
          text: 'Usuarios',
          value: 'Usuarios'
        }, 
        {
          text: 'Grupos',
          value: 'Grupos'
        }
      ],
      nameGroup: {
        text: "",
        value: ""
      },
      groups: []
    };
  },
  mounted() {
    this.token = JSON.parse(localStorage.user).token;
    this.getUsers();
    this.getGroup();
  },
  props: ['idProject', 'itemsGoogle'],
  methods: {
    getUsers: async function () {
      var self = this;
      var clientsTmp = [];
      this.users = [];

      await api
        .request(
          "get",
          "/listUsers/" + JSON.parse(localStorage.user).client_id + "/clients"
        )
        .then((data) => {
          clientsTmp = data.data.users;
        })
        .catch(function (e) {
          console.log(e);
        });

      clientsTmp.forEach((element) => {
        if (JSON.parse(localStorage.user).id != element.id) {
          
          if (element.roleId.length > 0) {
            element.roleId.map(function (rol) {
              
              if (rol.project_id == self.idProject && rol.role_id == 2) {
                
                var tmp_obj = {
                  value: element.id,
                  text: element.name,
                };

                self.users.push(tmp_obj);
              } else {
                if (element.grupoRole.length > 0) {
                  element.grupoRole.map(function (grupo) {
                    if (grupo.idProject == self.idProject && grupo.IdRole == 2) {
                      var tmp_obj = {
                        value: element.id,
                        text: element.name,
                      };

                      self.users.push(tmp_obj);
                    }
                  });
                }
              }
            });
          }
        }
      });
    },
    getGroup: async function(){
      var self = this
      var dataGroup = null;
      await api
        .request("get", "/getGroupByProject/" + this.idProject)
        .then(function (response) {
          dataGroup = response.data
        })
        .catch(function (e) {
            console.log(e);
        });
      
      dataGroup.map(function(group){
        console.log(group)
        if(group.IdRole == 2){
          self.groups.push({
            value: group.grupos.id,
            text: group.grupos.name            
          })
        }
      })

    },
    getDataPer: async function () {
      var self = this

      if(this.selectList == "Usuarios"){
        var typeKey = 'user'
        var id = this.nameUser.value
      }else{
        typeKey = 'group'
        id = this.nameGroup.value
      }

      var obj = {
        id,
        key: typeKey
      };
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      console.log(obj)
      await axios
        .post("https://microservices.safered.co/api/getRules", obj, config, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (res) {
          self.dataRules = res.data.rules;
          console.log(res)
        })
        .catch(function (e) {
          console.log(e)
        });

      if (!(this.dataRules.length > 0)) {
        this.selectItem = []
        this.items = this.itemsGoogle;
        this.active = this.selectItem;
      } else {
        this.selectItem = JSON.parse(this.dataRules[0].rule)
        this.items = this.itemsGoogle;
        this.active = this.selectItem;
      }
    },
    sendRules(){
      if(this.dataRules.length == 0){
        this.createRules();
      }else{
        this.updateRules();
      }
    },
    createRules: async function(){
      var self = this
      if(this.selectList == "Usuarios"){
        var idUser = this.nameUser.value;
        var obj = { 
          idProject: this.idProject, 
          idUser,
          idGroup: 0,
          rule: JSON.stringify(this.selectItem), 
          active: "true" 
        }
      }else{
        var idGroup = this.nameGroup.value;
        obj = { 
          idProject: this.idProject, 
          idUser: 0,
          idGroup,
          rule: JSON.stringify(this.selectItem), 
          active: "true" 
        }
      }
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      await axios
      .post("https://microservices.safered.co/api/createRules", obj, config, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        self.NotiPriva = true;
      })
      .catch(function () {
        self.NotiFail = true;
      });
    },
    updateRules: async function(){
      var self = this;
      if(this.selectList == "Usuarios"){
        var idUser = this.nameUser.value;
        var obj = {
          id: this.dataRules[0].id,
          idProject: this.idProject, 
          idUser,
          idGroup: 0,
          rule: JSON.stringify(this.selectItem), 
          active: "true" 
        }
      }else{
        var idGroup = this.nameGroup.value;
        obj = {
          id: this.dataRules[0].id,
          idProject: this.idProject, 
          idUser: 0,
          idGroup,
          rule: JSON.stringify(this.selectItem), 
          active: "true" 
        }
      }

      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      await axios
      .post("https://microservices.safered.co/api/editRules", obj, config, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        self.NotiPriva = true;
      })
      .catch(function () {
        self.NotiFail = true;
      });
    }
  },
  watch: {
    selectItem: async function () {
      this.active = this.selectItem
    },
    nameUser: async function(){
      this.selectItem = [];
      this.items = [];
      this.active = [];
    },
    nameGroup: async function(){
      this.selectItem = [];
      this.items = [];
      this.active = [];
    }
  }
};
</script>
<style>

</style>
