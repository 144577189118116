<template>
  <div>
    <v-card
      style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
      @click="EditClients"
    >
      <v-img
        :src="icon_set_config"
        alt=""
        style="width: 110px"
      />

      <v-card-title>
        <h3 style="font-size: 12px">Editar cliente</h3>
      </v-card-title>
    </v-card>
    
    <b-modal hide-footer title="Agregar clientes" ref="my-modal-add-client">
      <form-wizard
        ref="wizard"
        shape="circle"
        title="Editar cliente"
        subtitle="Seleccione el cliente a editar"
        color="#1565c0"
        nextButtonText="Siguiente"
        backButtonText="Volver"
        finishButtonText="Actualizar"
        @on-complete="saveChangesPerNatural"
      >
        <tab-content
          title="Seleccione el cliente a editar"
          style="width: 100%"
          :before-change="isSelectUser"
        >
          <div class="form-group">
            <label for="selectType">Clientes</label>
            <select
              v-model="selectedClient"
              class="form-control"
              id="selectClient"
              aria-label="Default select example"
            >
              <option
                :value="{
                  id: cliente.id,
                  text:
                    cliente.nombreContacto + ' ' + cliente.apellidosContacto,
                }"
                v-for="(cliente, index) in clients"
                :key="index"
                :id="index"
              >
                {{ cliente.nombreContacto }} {{ cliente.apellidosContacto }}
              </option>
            </select>
          </div>
        </tab-content>
        <tab-content
          title="Editar información del cliente"
          style="width: 100%"
          :before-change="checkChangesEdit"
        >
          <div class="form-group">
            <b-form-group
              id="input-group-3"
              label="Tipo de cliente"
              label-for="tClientEdit"
            >
              <v-select
                id="tClientEdit-x"
                v-model="formEdit.typeClient"
                required
                :items="typeClients"
              ></v-select>
              </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Nombres:"
              label-for="namesEdit"
            >
              <b-form-input
                id="namesEdit"
                v-model="formEdit.name"
                placeholder="Ingresar nuevo nombre"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Apellidos:"
              label-for="lastnamesEdit"
            >
              <b-form-input
                id="lastnamesEdit"
                v-model="formEdit.lastName"
                placeholder="Ingresar nuevos apellidos"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Tipo de documento"
              label-for="tDocumentEdit"
            >
              <v-select
                id="tDocumentEdit-3"
                v-model="formEdit.typeDocument"
                required
                :items="typeDocuments"
              ></v-select>
            </b-form-group>

            <b-form-group
              id="input-numIdEdit"
              label="Número de identificación"
              label-for="numIdEdit"
            >
              <b-form-input
                id="numIdEdit"
                v-model="formEdit.numIdClient"
                type="number"
                placeholder="Ingresar número de identificación"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Email"
              label-for="input-1"
              description="Se solicitara confirmación por parte del cliente."
            >
              <b-form-input
                id="input-1"
                v-model="formEdit.email"
                type="email"
                placeholder="Ingresar email"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </tab-content>
        <tab-content title="Verificar y guardar cambios" style="width: 100%">
          <div class="form-group">
            <label>Perfil actualizado</label>
            <div class="form-group">
              <b-form-group
                id="input-group-2"
                label="Nombres del cliente:"
                label-for="namesEdit"
              >
                <b-form-input
                  id="namesEdit"
                  v-model="formEdit.name"
                  placeholder="Nombre del cliente"
                  required
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Apellidos del cliente:"
                label-for="lastnamesEdit"
              >
                <b-form-input
                  id="lastnamesEdit"
                  v-model="formEdit.lastName"
                  placeholder="Ingresar nuevos apellidos"
                  required
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Tipo de documento"
                label-for="tDocumentEdit"
              >
                <v-select
                  id="tDocumentEdit-3"
                  v-model="formEdit.typeDocument"
                  required
                  disabled
                  :items="typeDocuments"
                ></v-select>
              </b-form-group>

              <b-form-group
                id="input-numIdEdit"
                label="Número de identificación"
                label-for="numIdEdit"
              >
                <b-form-input
                  id="numIdEdit"
                  v-model="formEdit.numIdClient"
                  type="number"
                  placeholder="Número de identificación del cliente"
                  required
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label="Email de contacto"
                label-for="input-1"
                description="Se solicitara confirmación por parte del cliente."
              >
                <b-form-input
                  id="input-1"
                  v-model="formEdit.email"
                  type="email"
                  placeholder="Ingresar email"
                  required
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>
<script>

import icon_set_config from '../../../../../../../assets/img/icons/icon_set_config.svg'
import api from "@/api";

export default {
  name: "edit-client-modal",
  components: {},
  data() {
    return {
      icon_set_config: icon_set_config,
      formEdit: {
        id: "",
        email: "",
        name: "",
        lastName: "",
        typeClient: null,
        typeDocument: null,
        numIdClient: 0,
        checked: [],
      },
      selectedClient: "",
      typeClients: [
        { text: "Seleccionar tipo de cliente", value: null },
        "Persona Natural",
        "Persona Juridica",
      ],
      typeDocuments: [
        { text: "Seleccionar tipo de documento", value: null },
        "Cedula de ciudadania",
        "DNI",
        "Pasaporte",
        "Cedula Extranjeria",
      ],
    };
  },
  props: {
    clients: [],
  },
  methods: {
    saveChangesPerNatural: async function () {
      let formData = new FormData();
      formData.append("email", this.formEdit.email);
      formData.append("name", this.formEdit.name);
      formData.append("lastName", this.formEdit.lastName);
      formData.append("typeClient", this.formEdit.typeClient);
      formData.append("typeDocument", this.formEdit.typeDocument);
      formData.append("numIdClient", this.formEdit.numIdClient);

      api
        .request(
          "post",
          "/updateClient/" + this.formEdit.id + "/client",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response);
          alert("Cliente actualizado exitosamente");
          this.formEdit = {}
          this.selectedClient === ""
        })
        .catch(function (e) {
          console.log(e);
        });
        this.$refs.wizard.reset()
        this.$refs["my-modal-add-client"].hide();
    },
    isSelectUser: async function () {
      console.log(this.selectedClient)
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.selectedClient === "") {
            reject("Debe seleccionar un cliente para continuar.");
            alert("Debe seleccionar un cliente para continuar.");
          } else {
            api
              .request("get", "/getClientById/" + this.selectedClient.id)
              .then((data) => {
                console.log(data.data[0]);
                if (data.data[0].tipo === "Persona natural") {
                  console.log(data.data[0]);
                  this.formEdit.email = data.data[0].emailContacto;
                  this.formEdit.name = data.data[0].nombreContacto;
                  this.formEdit.lastName = data.data[0].apellidosContacto;
                  this.formEdit.typeClient = data.data[0].tipo;
                  this.formEdit.numIdClient =
                    data.data[0].numeroDocumentoContacto;
                  this.formEdit.typeDocument =
                    data.data[0].tipoDocumentoContacto;
                  this.formEdit.id = data.data[0].id;
                  this.editPNatural = true;
                  this.editPJuridica = false;
                } else if (data.data[0].tipo === "Persona juridica") {
                  this.editPNatural = false;
                  this.editPJuridica = true;
                }
                console.log(this.formEdit);
              })
              .catch(function (e) {
                console.log(e);
              });
            resolve(true);
          }
        }, 1000);
      });
    },
    checkChanges: function () {
      var status = true;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (status) {
            reject("Debe seleccionar un cliente para continuar.");
            alert("Debe seleccionar un cliente para continuar.");
          } else {
            resolve(true);
          }
        }, 1000);
      });
    },
    checkChangesEdit: function () {
      var status = true;
      if (
        this.formEdit.email !== "" &&
        this.formEdit.name !== "" &&
        this.formEdit.lastName !== "" &&
        this.formEdit.typeClient !== "" &&
        this.formEdit.numIdClient !== ""
      ) {
        status = false;
      } else {
        status = true;
      }
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (status) {
            reject("No puede actualizar con campos vacios.");
            alert("No puede actualizar con campos vacios.");
          } else {
            resolve(true);
          }
        }, 1000);
      });
    },
    EditClients: function () {
      this.$refs["my-modal-add-client"].show();
    },
  },
};
</script>
