import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    current_user : {},
    dataInit: [],
    dataHoverP: false,
    dataHoverPDoc: false,
    modalOpciones: false,
    modalNovedades: false,
    modalBuscar: false,
    restoreInit: [],
    activeModalCreateVistaGeo: true,
    activeModalCreateVistaMBits: true,
    posicionselectC: null,
    dataselectC: null,
    posicionselectMb: null,
    selectionCanvas: {},
    selectionCanvasMBits: {},
    posicionselectMbE: null,
    posicionselectCE: null,
    viewDirectorioGlobal: false,
    viewDirectorioVista: false,
    viewDirectorioAux: false,
    dataMap: [],
    refreshDrawGeo: 0,
    refreshDataGeo: 0,
    createGeo: 0,
    createMpBits: 0,
    datalistTag: null,
    dataFindPoint: [],
    dataPoint: [],
    chartOptions: { "chart": { "type": "line", "stacked": false, "height": 350, "zoom": { "type": "x", "enabled": true, "autoScaleYaxis": true, "autoScaleXaxis": true }, "toolbar": { "show": true, "offsetX": 0, "offsetY": 0, "tools": { "download": true, "selection": true, "zoom": true, "zoomin": true, "zoomout": true, "pan": true, "reset": 1, "customIcons": [] }, "export": { "csv": { "filename": "KM182_639-readings", "columnDelimiter": ",", "headerCategory": "category", "headerValue": "value", dateFormatter(timestamp) { return new Date(timestamp).toDateString(); } }, "svg": { "filename": "KM182_639" }, "png": { "filename": "KM182_639" } }, "autoSelected": "zoom" } }, "dataLabels": { "enabled": false }, "markers": { "size": 0 }, "title": { "text": "KM182_639 - Deformación unitaria", "align": "left", "style": { "fontSize": '12px' } }, "yaxis": { "labels": { "formatter": function(val) { return val.toFixed(0); } }, "tickAmount": 5, "title": { "text": "Microstrain" }}, "xaxis": { "type": "datetime", "title": { "text": "Tiempo" }, "labels": { "datetimeFormatter": { "year": "yyyy", "month": "yyyy-MM", "day": "yyyy-MM-dd", "hour": "HH:mm:ss dd-MM-yyyy" } }, "convertedCatToNumeric": false }, "tooltip": { "shared": false, "x": { "show": true, "format": "yyyy-MM-dd  hh:mm:ss" } }, "stroke": { "show": true, "curve": "smooth", "lineCap": "butt", "width": 1, "dashArray": 0 } },
    dataChartPrint: [],
    showMDeleteCanvasG: false,
    showMDeleteCanvasI: false,
    proyectAdmin: false,
    empresaAdmin: false,
    bussinesAdmin: false,
    groupAdmin: false,
    userAdmin: false,
    clientesAdmin: false,
    createClients: false,
    novedadesDash: [],
    notiCreateC: 0,
    notiDeleteC: 0,
    notiFail: 0,
    restorePDelete: null,
    restoreDDelete: null,
    restoreGeo: null,
    refreshProject: 0,
    refreshImage: 0,
    refreshDGeneral: 0,
    permissionC: null,
    indexNodeDelete: null,
    addTempDirectFlex: null,
    deleteFolderGhost: null,
    permission: null,
    findDataCanvas: null,
    findDataCanvasName: null,
    dataGlobal: null,
    select_diretory: 0,
    directoryAux: null,
    directoryGeoMpBits: null,
    activeDataGeoMpBits: null,
    findDataFolder: null,
    newVentana: null,
    newVentana_Mpbits: null,
    filtData: null,
    findDataCanvas_market: null,
    directoryGeoMpBits_noview: null,
    locationObject: null,
    refreshBusiness: 0
  },
  getters: {
  },
  mutations: {
    setdataInit(state, data){
      state.dataInit = data
    },
    setFindDataCanvas(state, data){
      state.findDataCanvas = data
    },
    setFindDataCanvasName(state, data){
      state.findDataCanvasName = data
    },
    setdataHoverP(state, data){
      state.dataHoverP = data
    },
    setdataHoverPDoc(state, data){
      state.dataHoverPDoc = data
    },
    setrestoreInit(state, data){
      state.restoreInit = data
    },
    setactiveModalCreateVistaGeo(state, data){
      state.activeModalCreateVistaGeo = data
    },
    setactiveModalCreateVistaMBits(state, data){
      state.activeModalCreateVistaMBits = data
    },
    setPosicionselectC(state, data){
      state.posicionselectC = data
    },
    setPosicionselectCE(state, data){
      state.posicionselectCE = data
    },
    setselectionCanvas(state, data){
      state.selectionCanvas = data
    },
    setDataselectC(state, data){
      state.dataselectC = data
    },
    setPosicionselectMb(state, data){
      state.posicionselectMb = data
    },
    setPosicionselectMbE(state, data){
      state.posicionselectMbE = data
    },
    setselectionCanvasMBits(state, data){
      state.selectionCanvasMBits = data
    },
    setRefreshDrawGeo(state, data){
      state.refreshDrawGeo = data
    },
    setRefreshDataGeo(state, data){
      state.refreshDataGeo = data
    },
    setCreateGeo(state, data){
      state.createGeo = data
    },
    setCreateMpBits(state, data){
      state.createMpBits = data
    },
    setDataMap(state, data){
      state.dataMap = data;
    },
    setModalOpciones(state, data){
      state.modalOpciones = data
    },
    setModalNovedades(state, data){
      state.modalNovedades = data
    },
    setDatalistTag(state, data){
      state.datalistTag = data
    },
    setModalBuscar(state, data){
      state.modalBuscar = data
    },
    setDataFindPoint(state, data){
      state.dataFindPoint = data;
    },
    setDataPoint(state, data){
      state.dataPoint = data
    },
    setDataChartPrint(state, data){
      state.dataChartPrint = data;
    },
    setDataLienzo(state, data){
      state.dataLienzo = data;
    },
    setShowMDeleteCanvasG(state, data){
      state.showMDeleteCanvasG = data;
    },
    setShowMDeleteCanvasI(state, data){
      state.showMDeleteCanvasI = data;
    },
    setproyectAdmin(state, data){
      state.proyectAdmin = data
    },
    setEmpresaAdmin(state, data){
      state.empresaAdmin = data
    },
    setBusinessAdmin(state, data){
      state.bussinesAdmin = data
    },
    setUserAdmin(state, data){
      state.userAdmin = data
    },
    setGroupAdmin(state, data){
      state.groupAdmin = data
    },
    setClientesAdmin(state, data){
      state.clientesAdmin = data
    },
    setCreateClients(state, data){
      state.createClients = data
    },
    setNovedadesDash(state, data){
      state.novedadesDash = data;
    },
    setNotiCreateC(state, data){
      state.notiCreateC = data;
    },
    setNotiDeleteC(state, data){
      state.notiDeleteC = data;
    },
    setNotiFail(state, data){
      state.notiFail = data;
    },
    setRestoreGeo(state, data){
      state.restoreGeo = data;
    },
    setRefreshProject(state, data){
      state.refreshProject = data;
    },
    setRefreshImage(state, data){
      state.refreshImage = data;
    },
    setRefreshDGeneral(state, data){
      state.refreshDGeneral = data;
    },
    setPermissionC(state, data){
      state.permissionC = data;
    },
    setIndexNodeDelete(state, data){
      state.indexNodeDelete = data
    },
    setAddTempDirectFlex(state, data){
      state.addTempDirectFlex = data
    },
    setDeleteFolderGhost(state, data){
      state.deleteFolderGhost = data
    },
    setPermission(state, data){
      state.permission = data
    },
    setDataGlobal(state, data){
      state.dataGlobal = data
    },
    setSelect_diretory(state, data){
      state.select_diretory = data
    },
    setDiretoryAux(state, data){
      state.directoryAux = data
    },
    setDirectoryGeoMpBits(state, data){
      state.directoryGeoMpBits = data
    },
    setActiveDataGeoMpBits(state, data){
      state.activeDataGeoMpBits = data
    },
    setFindDataFolder(state, data){
      state.findDataFolder = data
    },
    setNewVentana(state, data){
      state.newVentana = data
    },
    setNewVentana_Mpbits(state, data){
      state.newVentana_Mpbits = data
    },
    setFiltData(state, data){
      state.filtData = data
    },
    setFindDataCanvas_market(state, data){
      state.findDataCanvas_market = data
    },
    setDirectoryGeoMpBits_noview(state, data){
      state.directoryGeoMpBits_noview = data
    },
    setLocationObject(state, data){
      state.locationObject = data
    },
    setRefreshBusiness(state, data){
      state.refreshBusiness = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
