<template>
  <div>
    <v-card
      style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
      @click="BlockUsers"
    >
      <v-img
        :src="icon_bloq"
        alt=""
        style="width: 110px"
      />

      <v-card-title>
        <h3 style="font-size: 12px">Bloquear usuario</h3>
      </v-card-title>
    </v-card>
    <!-- Modal -->
    <b-modal
      ref="my-modal-block-user"
      hide-footer
      title="Bloquear Usuarios"
      centered
    >
      <form-wizard
        title="Bloquear Usuarios"
        subtitle="Seleccione el usuario a bloquear"
        color="#1565c0"
        nextButtonText="Siguiente"
        backButtonText="Volver"
        finishButtonText="Bloquear"
        @on-complete="blockUser"
      >
        <tab-content
          title="Listado de usuarios"
          style="width: 100%"
          icon="ti-trash"
        >
          <div class="form-group">
            <label for="selectType">Usuarios</label>
            <select
              v-model="blockUserData"
              class="form-control"
              id="selectType"
              aria-label="Default select example"
            >
              <option selected>Seleccionar el usuario a bloquear</option>
              <option
                :value="{
                  id: cliente.id,
                  text:
                    cliente.nombreContacto + ' ' + cliente.apellidosContacto,
                }"
                v-for="(cliente, index) in clients"
                :key="index"
                :id="index"
              >
                {{ cliente.name }} {{ cliente.lastname }}
              </option>
            </select>
          </div>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>
<script>
import icon_bloq from '../../../../../../../assets/img/icons/icon_safered_user_block.svg'
import axios from "axios";

export default {
  name: "block-user-modal",
  components: {},
  data() {
    return {
      icon_bloq: icon_bloq,
      blockUserData: null,
      clients: [],
      count: 0,
    };
  },
  props: ['msjunblock'],
  watch: { 
    msjunblock: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.clients = []
      this.getUsers();
    }
  },
  methods: {
    async blockUserEvent() {
      this.$emit('blocked_user', true);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    blockUser: async function () {
        await axios
        .post(
          "https://api.safered.co/api/blockUser/" +
            this.blockUserData.id + "/" + JSON.parse(localStorage.user).id
        )
        .then(function (data) {
          if(data.status == 200){
            alert('Usuario bloqueado.')
          }
        })
        .catch(function (e) {
          console.log(e);
        });
        this.$refs["my-modal-block-user"].hide();
        var indexBlock
        this.clients.forEach((element,index) => {
          if(element.id == this.blockUserData.id){
            indexBlock = index
          }
          this.clients.splice(indexBlock,1)
        });
        this.blockUserEvent()
    },
    BlockUsers: function () {
      this.$refs["my-modal-block-user"].show();
    },
    getUsers: async function () {
      // console.log(JSON.parse(localStorage.user))
      var clientsTmp = [];
      await axios
        .get(
          "https://api.safered.co/api/listUsers/" +
            JSON.parse(localStorage.user).client_id +
            "/clients"
        )
        .then(function (data) {
          clientsTmp = data.data.users;
        })
        .catch(function (e) {
          console.log(e);
        });
      
      clientsTmp.forEach(element => {
        if (JSON.parse(localStorage.user).id != element.id) {
          if(element.active == 'True'){
            this.clients.push(element)
          }
        }
      });
    },
    showToast() {
        // Use a shorter name for this.$createElement
        const h = this.$createElement
        // Increment the toast count
        this.count++
        // Create the message
        const vNodesMsg = h(
          'p',
          { class: ['text-center', 'mb-0'] },
          [
            h('b-spinner', { props: { type: 'grow', small: true } }),
            ' Flashy ',
            h('strong', 'toast'),
            ` message #${this.count} `,
            h('b-spinner', { props: { type: 'grow', small: true } })
          ]
        )
        // Create the title
        const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h('strong', { class: 'mr-2' }, 'The Title'),
            h('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
          ]
        )
        // Pass the VNodes as an array for message and title
        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: 'info'
        })
      }
  },
  mounted() {
    this.getUsers();
  },
};
</script>