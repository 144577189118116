<template>
    <b-card>
        <v-col>
            <v-row>
                <v-col cols="2">
                </v-col>
                <v-col cols="6" >
                    <v-row style="align-items: center;">
                        <v-col cols="4">
                            <strong>Seleccione el punto:</strong>
                        </v-col>
                        <v-col cols="8">
                            <v-select v-model="selectedNamePoint" :items="options"></v-select>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2" style="display: flex; align-self: center; justify-content: center;">
                    <b-button variant="info" @click="filtPointA(selectedNamePoint)" style="color: white">Filtrar</b-button>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                </v-col>
                <v-col cols="8">
                    <div style="overflow-x: hidden; overflow-y: scroll; height: 302px;">
                        <v-row v-for="(nameA, index) in foundNameA" :key="nameA.id" :id="index" style="margin-bottom: 40px;">
                            <v-col cols="1">
                            </v-col>
                            <v-col cols="3" style="display: flex; flex-direction: column; align-items: flex-start">
                                <strong>Nombre del punto:</strong>
                                <p>{{nameA.name}}</p>
                            </v-col>
                            <v-col cols="3" style="display: flex; flex-direction: column; align-items: flex-start">
                                <strong>Valor de la alerta:</strong>
                                <p>{{nameA.value}}</p>
                            </v-col>
                            <v-col cols="3" style="display: flex; flex-direction: column; align-items: flex-start">
                                <strong>Estado de la alerta:</strong>
                                <p>{{nameA.status}}</p>
                            </v-col>
                            <v-col cols="2" style="display: flex; flex-direction: column; align-items: flex-start">
                                <b-button v-b-modal.modal-1-config variant="outline-secondary" @click="selectConfig(index);">
                                    <b-icon icon="gear-fill" aria-hidden="true"></b-icon> 
                                </b-button>
                            </v-col>

                            <b-modal size="lg" v-if="foundNameA.length > 0" hide-footer centered :id="'modal-1-config-'+ index" :title="'Agregar alerta para el punto: '+foundNameA[index].name">
                                <v-row>
                                    <v-col cols="4">
                                        <b-form-group id="valueAlert-group-2" label="Ingrese valor de referencia:" label-for="value-alert">
                                            <b-form-input
                                            id="value-alert"
                                            v-model="currentValue"
                                            placeholder=""
                                            required
                                            ></b-form-input>
                                        </b-form-group>
                                    </v-col>
                                    <v-col cols="4">
                                        <b-form-group id="typeAlert-group-2" label="Seleccione el nivel de alerta:" >
                                            <v-select v-model="currentAlert" :items="optionsAlert"></v-select>
                                        </b-form-group>
                                    </v-col>
                                    <v-col cols="1" >
                                    </v-col>
                                    <v-col cols="1" style="display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">
                                        <b-button variant="outline-success" @click="saveAlert(foundNameA[index].name)">
                                            <i class='fa fa-floppy-o'></i>
                                        </b-button>
                                    </v-col>
                                    <v-col cols="3" >
                                    </v-col>
                                </v-row>
                            </b-modal>

                        </v-row>
                    </div>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            <v-row style="margin-bottom: 25px;">
                <v-col cols="2">
                </v-col>
                <v-col>
                    <v-row>
                        <v-col sm="10" style="display: flex; flex-direction: column; align-items: flex-start">
                            <strong>Ingrese separados por coma el email de los destinatarios:</strong>
                            <b-form-input
                                id="email-alert"
                                v-model="destinatarios"
                                placeholder=""
                                required
                                ></b-form-input>
                        </v-col>
                        <v-col cols="2" style="display: flex; align-self: center; justify-content: center;">
                            <b-button v-b-tooltip.hover variant="outline-success" title="Enviar Email" @click="sendEmail(); openNotificationSendEmail(6000, 'top-center',
                                'success', `<i class='bx bx-select-multiple' ></i>`)">
                                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            </b-button>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
        </v-col>
    </b-card>
</template>
<script>

import axios from 'axios'

export default {
    name: "confAlarmas",
    data(){
        return{
            selectedNamePoint: '',
            options: [],
            foundNameA: [],
            currentValue: '',
            currentAlert: '',
            optionsAlert: [
                {value: "Alerta Roja", text: "Alerta Roja"},
                {value: "Alerta Naranja", text: "Alerta Naranja"},
                {value: "Alerta Negra", text: "Alerta Negra"},
                {value: "Equipo Dañado", text: "Equipo Dañado"},
                {value: "Sin Alerta", text: "Sin Alerta"},
            ],
            destinatarios: '',
            dataEmail: [],
            anillos: {
                data: [],
            },
            nameT: '',
            descriptionT: ''
        }
    },
    mounted(){
        this.loadSensors();
    },
    methods:{
        loadSensors: async function () {
            var lista = [];
            await axios
                .post(
                'https://api.safered.co/api/getSensorsForProject/' +
                    this.$store.state.dataInit.idProject
                )
                .then((result) => {
                let data = JSON.parse(result.data[0])
                //console.log(data)
                
                data.forEach((element) => {
                    let coordenadaArray = element.UbigacionGPS.replace(/'/g, '')
                    .replace(/[[\]]/g, '')
                    .split(',')
                    var dato = {
                        name: element.Codigo,
                        position: coordenadaArray,
                        tipoSensor: element.TipoSensor,
                        grupo: element.UbigacionCartesiana,
                        status: element.Status,
                        value: element.Value
                    }
                    lista.push(dato)

                    this.options.push(element.Codigo);
                })
                })
            this.anillos.data = lista
        },
        filtPointA(point){
            var actItem = false;
            for(let i=0; i<this.anillos.data.length; i++){
                if(this.foundNameA.length > 0){
                    for(let j=0;j<this.foundNameA.length;j++){
                        if(this.foundNameA[j].name == point){
                            this.foundNameA[j] = {
                                name: this.anillos.data[i].name,
                                value: this.anillos.data[i].value,
                                status: this.anillos.data[i].status
                            }
                            actItem = true;
                        }
                    }
                    if (actItem == false){
                        if(this.anillos.data[i].name == point){
                            this.foundNameA.push({
                                name: this.anillos.data[i].name,
                                value: this.anillos.data[i].value,
                                status: this.anillos.data[i].status
                            })
                            break;
                        }
                    }
                }else{
                    if(this.anillos.data[i].name == point){
                        this.foundNameA.push({
                            name: this.anillos.data[i].name,
                            value: this.anillos.data[i].value,
                            status: this.anillos.data[i].status
                        })
                        break;
                    }
                }
            }
        },
        selectConfig(index){
            this.$root.$emit('bv::show::modal', 'modal-1-config-'+index, '#focusThisOnClose');
        },
        saveAlert: function (name) {
            var search = this.dataEmail.findIndex(x => x.name === name)
            if (search >= 0) {
                this.dataEmail[search] =
                {
                'name': name,
                'currentValue': this.currentValue,
                'currentAlert': this.currentAlert
                }
            } else {
                this.dataEmail.push(
                {
                    'name': name,
                    'currentValue': this.currentValue,
                    'currentAlert': this.currentAlert
                }
                )
            }

            this.currentValue = ''
            this.currentAlert = ''
        },
        sendEmail: async function () {
            var statusPoints = []
            this.anillos.data.forEach((element) => {
                if(element.status){
                    if(element.status != null || element.status.length > 0 ){
                    statusPoints.push({
                        'point': element.name,
                        'status':element.status,
                        "value": element.value
                    })
                this.msj = this.msj+' - El punto: '+element.name+' reporta : '+element.status+'con la lectura:'+ element.value +'\n'
                }
            }
                
            })

            var obj = {
                'Mensaje': this.msj,
                'destinatarios': this.destinatarios,
                'status': statusPoints
            }
            await axios
                .post(
                "https://api.safered.co/api/sendEmailAlertsR40", obj)
                .then((result) => {
                if(result.status == 200){
                    console.log(result)
                    alert('Email enviado')
                }
                }).catch((err) =>{
                console.log(err)
                alert('Error al enviar email')
                });


            this.msj = ''
        },
    },
    watch:{
        '$store.state.dataInit': function(){
            this.loadSensors();
        },
    }
}
</script>
<style>
    
</style>