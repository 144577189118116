<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        width="700"
      >
        <v-card style="height: 500px; width: 700px; background-color:#E4E4E4;">
          <v-row style="margin-left: 10px; margin-right: 5px; margin-top: 1px; margin-bottom: 1px;
            height: -webkit-fill-available;">
            <v-col cols="5" style="display: flex; align-items: center;">
              <v-card style="width: -webkit-fill-available;">
                <div class="pa-4">
                  <v-file-input
                    v-model="document"
                    class="ma-2"
                    label="Documento...."
                    outlined
                    dense
                  ></v-file-input>
                  <v-btn
                    class="ma-2"
                    color="success"
                    small
                    @click="verifyDocument()"
                  >
                    Cargar documento
                  </v-btn>
                </div>
              </v-card>
            </v-col>
            <v-col cols="7" style="display: flex; align-items: center;">
              <v-card style="width: -webkit-fill-available;">
                <div style="overflow-y: auto!important;
                  height: 400px;">
                    <div class="ma-3">
                      <p v-if="textFile != null" class="text_document_a">
                        {{textFile}}
                      </p>
                    </div>
                    <v-fab-transition>
                      <v-btn
                        color="green"
                        fab
                        dark
                        small
                        absolute
                        bottom
                        right
                        @click="descargarDocument()"
                      >
                        <v-icon>mdi-arrow-down-bold</v-icon>
                      </v-btn>
                    </v-fab-transition>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>

import axios from 'axios';

export default {
  name: 'modalDocumentProject',
  data(){
    return{
      dialog: false,
      document: null,
      documentD: null,
      textFile: null,
    }
  },
  props: [ 'project', 'hover'],
  mounted() {

  },
  methods: {
    documentGet: async function(){
      var document = null;
      var data = {
          "user_id": JSON.parse(window.localStorage.user).client_id,
          "id_tag": 93,
          "project_id": this.project.id
      };	
      await axios
      .post('https://api.safered.co/api/getDocumentProject', data, {responseType: 'blob'})
        .then((response) => {
          console.log(response);
          document = response.data
      })
      .catch(function (e) {
          console.log(e);
      });
      this.textFile = await document.text();
      this.documentD = document;
    },
    verifyDocument(){
      if(this.documentD.size > 52){
        this.updateDocumentClient();
      }else{
        this.uploadDocument();
      }
    },
    uploadDocument: async function(){
      var self = this
      let formData = new FormData();
      formData.append("point_id", null);
      formData.append("file", this.document);
      formData.append("tag_id", 93),
      formData.append("project_id", this.project.id),
      formData.append("user_id", JSON.parse(window.localStorage.user).client_id)

      await axios
        .post("https://api.safered.co/api/store-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
            alert('Archivo cargado exitosamente.');
            self.documentGet();
        })
        .catch(function (e) {
          console.log(e);
        });

    },
    updateDocumentClient: async function(){
      var self = this
      let formData = new FormData();
      formData.append("file", this.document);
      formData.append("id_tag", 93),
      formData.append("user_id", JSON.parse(window.localStorage.user).client_id)
      formData.append("project_id", this.project.id)

      await axios
        .post("https://api.safered.co/api/updateDocumentProject", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
            alert('Documento cargado exitosamente.');
            self.documentGet();
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    descargarDocument(){
      var fileURL = window.URL.createObjectURL(new Blob([this.documentD]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", this.project.nameDocument);
      document.body.appendChild(fileLink);
      fileLink.click();
    }

  },
  watch:{
    hover: function(){
      this.dialog = this.hover
    },
    dialog: function(){
      if(this.dialog == false){
        this.$store.commit("setdataHoverPDoc", false)
      }
    },
    project: async function(){
      await this.documentGet();
    }
  }
}
</script>
<style>
  p.text_document_a{
    font-size: 0.95rem!important;
    font-weight: 400!important;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 24;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }
</style>