<template>  
<div>
    <b-modal centered id="CreateFolders" :title="`Agregar directorio a la carpeta: ${titleFolder}`" hide-footer ref="my-modal"> 
        <b-form @submit="onSubmit" v-if="show">
            <b-form-group id="input-group-3" label="Nombre del directorio:" label-for="nameFolder" style="margin-top: 15px;">
                <b-form-input id="nameFolder" v-model="form.nameFolder" type="text" required></b-form-input>
            </b-form-group>
            <b-button id="toggle-btn" type="submit" variant="success" style="color: white; margin-top: 25px;" block>Crear</b-button>
        </b-form>
    </b-modal> 
</div>     
</template>
<script>

import Vue from 'vue'
import axios from "axios"
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

export default {
    name: 'modalCrearCarpeta',
    components: {},
    data(){
        return{
            form: {
                folderId: "",
                idProject: "",
                nameFolder: "",
                idFath: null,
            },
            token : JSON.parse(localStorage.user).token,
            show: true,
           
        };
    },
    async mounted() {
    this.clientInfo = JSON.parse(localStorage.user).clientInfo
    },
    props: ['typeDirct', 'titleFolder', 'folderId', 'projectId', 'emailClient'],
    created: async function(){
        this.form.idFath = await this.idFather
        
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.createdFolder();
        },
        onSubmitAux(event){
            event.preventDefault();
            this.createdFolderAux();
        },
        toggleModal() {
          this.$refs['my-modal'].toggle();
        },
        onReset(event) {
            event.preventDefault();
            // Reset our form values
            this.form.email = "";
            this.form.name = "";
            this.form.food = null;
            this.form.checked = [];
            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });

        },
        createdFolder: async function () {
            if(this.typeDirct == "Make"){
                var self = this
                let formData = new FormData();
                formData.append("folderId", this.folderId)
                formData.append("idProject", this.projectId)
                formData.append("nameFolder", this.form.nameFolder)
                formData.append("emailClient", JSON.parse(localStorage.user).clientInfo.emailContacto)

            
                const config = {
                    headers: { Authorization: `Bearer ${this.token}` },
                };

                await axios
                    .post("https://microservices.safered.co/api/storeDirectory", formData, config, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(function(){
                        var count = self.$store.state.refreshDGeneral + 1;
                        self.$store.commit("setRefreshDGeneral", count);
                        alert("Directorio cargado exitosamente.")
                        self.toggleModal();
                    })
                    .catch(e =>
                        console.log(e)
                    );

                this.documentP = null;
                this.selectedTypeDocument = 0;
            }else{

                var data = {
                    nameFile: this.form.nameFolder,
                    folder: this.folderId, 
                    type: 'Folder'
                }
                this.$store.commit("setAddTempDirectFlex", data)
            }
            
        },   
    },
}

</script>

<style>
.custom-file-label::after {
    content: "Cargar archivo" !important;
}
</style>