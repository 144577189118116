<template>
    <v-dialog
        v-model="dialog"
        width="500"    
    >
        <v-card>
            <v-card-title class="text-h5 white lighten-2">
                Crear vista
            </v-card-title>
                
            <v-card-text>
                <v-text-field
                    v-model="tituloMBits"
                    label="Titulo de la vista"
                    required
                ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn v-if="tituloMBits.length > 0" variant="success" @click="newMBits(); dialog = false;">
                        Crear
                    </v-btn>
                    <v-btn v-if="tituloMBits.length <= 0" disabled variant="success">
                        Crear
                    </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from 'axios'

export default {
    name: 'ModalCreateVistaMBits',
    data(){
        return{
            tituloMBits: "",
            dialog: false,
            idCancasResponse: null,
        }
    },
    props:['dataMpBits', 'viewModalCreateVistaMBits', 'permitEdit'],
    methods:{
        newMBits: async function () {
            if (JSON.parse(window.localStorage.user).isAdmin == true || JSON.parse(window.localStorage.user).isBoss == true || JSON.parse(window.localStorage.user).isEditor == true || JSON.parse(window.localStorage.user).isEditor == true || this.permitEdit) {
                this.idCancasResponse = null
                var dataCloud = []
                let formData = new FormData();
                formData.append("idUser", JSON.parse(localStorage.user).id)
                formData.append("name", this.tituloMBits);
                formData.append("data", JSON.stringify(dataCloud)),
                formData.append("id_project", this.$store.state.dataInit.idProject)

                var self = this
                var refr = null 

                await axios
                .post('https://api.safered.co/api/setCanvasImage', formData, {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (response) {
                    self.idCancasResponse = response.data.id;

                })
                .catch(function (error) {
                    console.log(error);

                    refr = self.$store.state.notiFail + 1
                    self.$store.commit("setNotiFail", refr)
                });

                refr = self.$store.state.refreshImage + 1
                self.$store.commit("setRefreshImage", refr)

            } else {
                console.log("No tiene los permisos")
            }

        },
    },
    watch:{
        viewModalCreateVistaMBits: function(){
            if(this.viewModalCreateVistaMBits == true){
                this.dialog = this.viewModalCreateVistaMBits
            }
        },
        dialog: function(){
            if(this.dialog == false){
                this.$store.commit("setactiveModalCreateVistaMBits", false);
            }
        }
    }
}
</script>
<style>   
</style>