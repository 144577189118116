import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import vuetify from '@/plugins/vuetify'
import InfiniteSlideBar from 'vue-infinite-slide-bar'
import VueApexCharts from 'vue-apexcharts'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.use(VueApexCharts)
Vue.component('ApexCharts', VueApexCharts)

Vue.component('InfiniteSlideBar', InfiniteSlideBar)

//Global image-preview-vue
import imagePreview from 'image-preview-vue'
import 'image-preview-vue/lib/imagepreviewvue.css'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

Vue.prototype.moment = moment

Vue.use(imagePreview)

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

Vue.directive('contextmenu', {
  bind: function(el, binding) {
    el.addEventListener('contextmenu', function(event) {
      if (binding.value) {
        binding.value(event);
        event.preventDefault();
      }
    });
  }
});

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
