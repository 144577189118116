<template>
  <div>
    <v-card
      style="width: 160px; heigth: 220px; display: flex; flex-direction: column; align-items: center;"
      @click="CreateProjects"
    >
      <v-img
        :src="icon_add"
        alt=""
        style="width: 110px"
      />

      <v-card-title>
        <h3 style="font-size: 12px">Crear proyecto</h3>
      </v-card-title>
    </v-card>
    <!-- Modal -->
    <b-modal
      class="h-50 d-inline-block"
      id="modal-xl"
      ref="my-modal-add-client"
      hide-footer
      title="Crear Proyecto"
      centered
      v-b-modal.modal-scrollable
      button-size="sm"
    >
      <form-wizard
        shape="circle"
        title="Proyectos - SAFE RED"
        subtitle="Agregar proyectos"
        color="#1565c0"
        nextButtonText="Siguiente"
        backButtonText="Volver"
        finishButtonText="Finalizar"
        @on-complete="saveProject"
      >
        <tab-content
          title="Información general"
          style="width: 100%; padding-top: 0px;"
          :before-change="validateInfGen"
        >
          <v-text-field
            label="Nombre del proyecto"
            v-model="infoProject.nameProject"
            hide-details="auto"
            id="nameClientInput"
          ></v-text-field>

          <label style="margin-top: 15px;" for="selectUsers">Canvas</label>

          <v-row>
            <v-col style="padding: 0px;">
              <v-list-item>
                <v-checkbox value="api-geo" color="#195BFF" v-model="infoProject.canvas" label="Canvas Georreferencia" />
              </v-list-item>
              <v-list-item two-line>
                <v-checkbox value="back-img" color="#195BFF" v-model="infoProject.canvas" label="Canvas Mapa de Bits" />
              </v-list-item>
            </v-col>
            <v-col style="padding: 0px;">
              <v-list-item two-line>
                <v-checkbox value="directory" color="#195BFF" v-model="infoProject.canvas" label="Estructura directorios"/>
              </v-list-item>
            </v-col>
          </v-row>
          <v-textarea v-model="infoProject.description" label="Descripción"></v-textarea>
        </tab-content>

        <tab-content title="Usuarios" style="width: 100%">
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="5">
                <v-list style="height: 180px; overflow-x: hidden; overflow-y: scroll;" flat>
                  <v-list-item
                    v-for="(value, i) in viewSelectUsers"
                    :key="i"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="value"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </b-col>
              <b-col cols="7">
                <v-select
                  v-model="selectedUser"
                  :items="users"
                  label="Asignar usuario manualmente"
                ></v-select>
                <b-button pill variant="outline-secondary" @click="setUser">
                  Agregar
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>
<script>
//import api from "@/api";
import icon_add from '../../../../../../../assets/img/icons/icon_add_project.svg'
import axios from "axios";

export default {
  name: "create-project-modal",
  components: {},
  data() {
    return {
      icon_add: icon_add,
      infoProject: {
        nameProject: "",
        canvas: [],
        fileConfig: '',
        sizeDisk: '1',
        sizeFile:'1',
        typeFiles:'',
        users: [],
        idUserEdit: JSON.parse(localStorage.user).id,
        description: ''
      },
      selectedUser: null,
      usersFormat: [],
      viewSelectUsers: [],
      usersC: [], 
    };
  },
  props: {
    users:[]
  },
  mounted() {
    this.usersC = this.users
    console.log(this.users);
  },
  methods: {
    setUser: function () {
      if (this.selectedUser) {
        this.infoProject.users.push(this.selectedUser);
        var selUsTmp = this.selectedUser
        var listUser = []
        var index = 0
        this.usersC.forEach(
          function (element, i) {
            if(element.value == selUsTmp){
              listUser.push(element.text)
              index = i
            }
          });

          if(listUser.length>0){
            this.viewSelectUsers.push(listUser[0])
            this.usersC.splice(index,1)
          }

          console.log(this.infoProject)
          
      } else {
        alert("Debe seleccionar un usuario");
      }
    },
    CreateProjects: function () {
      this.$refs["my-modal-add-client"].show();
    },
    CloseCreateProject: function () {
      //this.$refs["my-modal-add-client"].hide();
      this.$bvModal.hide("modal-xl")
    },
    saveProject: async function () {
      this.infoProject.users.push(JSON.parse(localStorage.user).id)
      var self = this
      await axios
        .post("https://api.safered.co/api/uploadProjects", this.infoProject)
        .then(function (response) {
          if(response.status == "200"){
            console.log(response.data.idProject);
            alert("Proyecto creado exitosamente")
            
            self.creteProjectD(response.data.idProject)

            var refD =  self.$store.state.refreshProject + 1
            self.$store.commit("setRefreshProject", refD);
          }
          else {
            alert("Error al crear proyecto")
          }
        })
        .catch(function (e) {
          console.log(e);
        });
        this.$bvModal.hide("modal-xl")
        
    },
    creteProjectD: async function(idProject){
      var token = JSON.parse(localStorage.user).token;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      var obj = {
        idSfr: idProject,
        name: this.infoProject.nameProject,
        userId: JSON.parse(localStorage.user).id_cloudD,
        description: this.detail
      }
      
      console.log(obj);
      await axios
        .post("https://microservices.safered.co/api/createProject", obj, config)
        .then(function (data) {
          console.log(data)
        })
        .catch(function (e) {
          console.log(e);
        });

    },
    validateInfGen: function () {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (
            this.infoProject.nameProject == "" &&
            this.infoProject.canvas.length <= 0
          ) {
            reject("Debe completar la información general del proyecto.");
            alert("Debe completar la información general del proyecto.");
          } else if (this.infoProject.canvas.length <= 0) {
            reject("Debe seleccionar minimo una opción de canvas.");
            alert("Debe seleccionar minimo una opción de canvas.");
          } else {
            console.log(this.infoProject);
            resolve(true);
          }
        }, 1000);
      });
    },
  },
  watch:{
    detail(){
      console.log(this.detail)
    }
  }
};
</script>
