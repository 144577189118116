<template>
    <v-row style="margin-top: 10px;">
        <v-col cols="1"></v-col>
        <v-col cols="10">
            <v-card style="height: 540px; overflow-x: hidden; overflow-y: scroll;">
                <v-timeline
                    align-top
                    :dense="$vuetify.breakpoint.smAndDown"
                >
                    <v-timeline-item
                        v-for="(news, i) in arrayNoti"
                        :key="i"
                        color="#008000"
                        icon="mdi-check-outline"
                        fill-dot
                    >
                    <v-card
                        color="#008000"
                        dark
                    >
                        <v-card-title class="text-h6">
                            {{news.title}}
                        </v-card-title>
                        <v-card-text class="white text--primary">
                            <p>{{news.body}}</p>
                            <p style="font-style: oblique;" >{{new Date(news.time)}}</p>
                        </v-card-text>
                    </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-card>
        </v-col>
        <v-col cols="1"></v-col>
    </v-row>
</template>
<script>
import axios from 'axios'

export default {
    name: 'TimeL',
    data(){
        return{
            arrayNoti: []
        }
    },
    props: [ 'newsArrays', 'idProject' ],
    mounted: async function(){
        this.getAuditoria()
        var data = await this.newsArrays

        data.forEach(element => {
            element.forEach(element2 => {
                this.arrayNoti.push(element2)
            });
        });

        this.arrayNoti = this.arrayNoti.reverse()

    },
    methods:{
        getAuditoria: async function(){
            var csv_data = null;
            var obj = {
              idProject: this.idProject,
              keyTime: "all",
              tipo: "EDICIÓN",
            };
            await axios
            .post("https://api.safered.co/api/getLogForDate", obj, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    csv_data = response.data

                });
            this.csvJSON(csv_data);
        },
        csvJSON: function (csv){

            var lines=csv.split("\n");

            var result = [];

            var headers=lines[0].split(",");

            for(var i=1;i<lines.length;i++){

                var obj = {};
                var currentline=lines[i].split(",");

                for(var j=0;j<headers.length;j++){
                    obj[headers[j]] = currentline[j];
                }

                result.push(obj);

            }

            var self = this
            result.map(function(element){
                if(element.Accion != undefined){
                    self.arrayNoti.push({
                        title: element.Tipo,
                        body: element.Recurso,
                        time: element.Fecha
                    })
                }
            })
            this.arrayNoti = this.arrayNoti.reverse()
        }
    },
    
}
</script>
<style>
    
</style>