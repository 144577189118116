<template>
    <div class="center grid" style="padding-top: 40px;">
        <v-tabs v-model="tab"  background-color="#283271">
            <v-tabs-slider color="white"></v-tabs-slider>
            <v-tab style="color: white">
                Información
            </v-tab>
            <v-tab style="color: white">
                BackUp
            </v-tab>
            <v-tab style="color: white">
                Auditoria
            </v-tab>
        </v-tabs>
        <v-card style="background-color: #E4E4E4;">
            <v-tabs-items v-model="tab" center-active>
                <v-tab-item>
                    <MyEmpresa></MyEmpresa>
                </v-tab-item>
                <v-tab-item>
                    <exportEmpresa></exportEmpresa>
                </v-tab-item>
                <v-tab-item>
                    <auditoriaUsu></auditoriaUsu>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>
<script>

import MyEmpresa from './MyEmpresa.vue'
import exportEmpresa from './ExportEmpresa'
import auditoriaUsu from './AuditoriaUsu';

export default {
    name: "empresaB",
    components: {
        MyEmpresa,
        exportEmpresa,
        auditoriaUsu,
    },
    data() {
        return {
            tab: null,
        };
    }
}
</script>
<style>
    
</style>