<template>
    <v-app>
        <v-app-bar absolute color="#283271" app>
            <NavbarP></NavbarP>
        </v-app-bar>

        <v-main style="background-color: #F2F2F2;">
            <mainEstruct v-if="!activeChart && !activeGeo && !activeMpbits" :user="currentUser" :rolesActualU="rolesActualU"/>
            <ChartB v-if="activeChart"/>
            <mainCanvasGeo v-if="activeGeo" />
            <mainCanvasMpbits v-if="activeMpbits" />
        </v-main>

        <v-footer color="#283271" padless>
            <footerP/>
        </v-footer>
  </v-app>
</template>
<script>

import axios from "axios";
import footerP from "../structure_p/FooterP.vue"
import NavbarP from "../structure_p/NavbarP.vue"
import mainEstruct from "./component_Main/MainEstruct.vue";
import ChartB from "./chart/Chart.vue"
import mainCanvasGeo from "./canvasGeorrefenciado/mainCanvasGeo.vue"
import mainCanvasMpbits from "./canvasGeorrefenciado/mainCanvasMpbits.vue"

export default {
    
    name: 'MainF',
    metaInfo: {
      title: 'SAFERED',
      titleTemplate: '%s | Bienvenidos'
    },
    components: {
        footerP,
        NavbarP,
        mainEstruct,
        ChartB,
        mainCanvasGeo,
        mainCanvasMpbits,
    },
    data() {
      return {
        currentUser: null,
        activeChart: false,
        activeGeo: false,
        activeMpbits: false,
        rolesActualU: null,
      };
    },
    async mounted(){
        this.currentUser = JSON.parse(localStorage.user)
        localStorage.removeItem("info_event");

        if(this.$route.params.namePoint != undefined){
            if(this.$route.params.namePoint.length > 0){
                this.activeChart = true
            }
        }else if(this.$route.path == "/app/mapGeo"){
            this.activeGeo = true
        }else if(this.$route.path == "/app/mapBits"){
            this.activeMpbits = true
        }
        this.loadRolP();
        
        var antes = new Date(this.currentUser.timeLogin)
        var hoy = new Date()

        var diaEnMils = 1000 * 60 * 60 * 24,
        diff = antes.getTime() - hoy.getTime() + diaEnMils;
        var finishSession = diff/diaEnMils

        if(finishSession <= 0){
            localStorage.removeItem("user");
            localStorage.removeItem("canvas_info");
            localStorage.removeItem("info_event");
            await this.$router.replace("/");
            window.location.reload();
        }else{
            console.log(finishSession)
        }

    },
    methods:{
        loadRolP: async function(){
            var self = this
            await axios
                .get(
                "https://api.safered.co/api/getRoleFromUser/" + this.currentUser.id)
                .then(function (data) {
                    self.rolesActualU = data.data;
                })
                .catch(function (e) {
                    console.log(e);
                });
        },
    }
}
</script>
<style>
    
</style>