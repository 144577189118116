<template>
  <v-app>
    <v-app-bar absolute color="#283271" app>
        <NavbarP></NavbarP>
    </v-app-bar>

    <v-main>
      <div v-if="isUser">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-card style="height: 350px; margin-top: 35px; display: flex; flex-direction: column; 
              align-items: center; justify-content: center;">
              <div class="py-5 text-center">
                <h4>{{ $t('register.activaAcc_activeAccUser') }}</h4>
                <p class="lead" style="font-size: unset;">
                  {{ $t('register.userCreated_activeAccUser') }}
                </p>
              </div>
              <v-btn
                @click="modalShow = !modalShow"
                style="margin-bottom: 2px; margin-top: 45px; display: inline-block"
                color="success"
              >
                {{ $t('register.comfAcc_activeAccUser') }}
              </v-btn>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        

        <b-modal v-model="modalShow" :title="$t('register.enterInf_activeAccUser')" hide-footer>
          <div>
            <div class="mb-3">
              <label for="nameFormControlInput1" class="form-label"
                >{{ $t('register.name_activeAccUser') }}</label
              >
              <input
                type="text"
                class="form-control"
                id="nameFormControlInput1"
                disabled
                :placeholder="$t('register.nameUser_activeAccUser')"
                v-model="nameUser"
              />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label"
                >{{ $t('register.email_activeAccUser') }}</label
              >
              <input
                disabled
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
                :placeholder="$t('register.validEmail_activeAccUser')+' ... name@example.com'"
                v-model="emailaccount"
              />
            </div>
            <div class="mb-3">
              <label for="inputPassword" class="form-label">{{ $t('register.pass_activeAccUser') }}</label>
              <input type="password" class="form-control" id="inputPassword" placeholder="*************"
                v-model="passwordaccount">
            </div>
            <div class="mb-3">
              <label for="inputPassword2" class="form-label">{{ $t('register.passConfir_activeAccUser') }}</label>
              <input type="password" class="form-control" id="inputPassword2" placeholder="*************" 
              v-model="password2account">
            </div>
          </div>
          <div class="modal-footer">
            <v-btn
              @click="modalShow = !modalShow"
              style="color: white"
              color="#FF6060"
            >
              {{ $t('register.close_activeAccUser') }}
            </v-btn>
            <v-btn
              style="color: white"
              color="#5AB55E"
              @click="saveUser(); modalShow = !modalShow"
            >
              {{ $t('register.save_activeAccUser') }}
            </v-btn>
          </div>
          
        </b-modal>
      </div>
      <div v-if="!isUser">
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-card style="height: 350px; margin-top: 35px; display: flex; flex-direction: column; 
              align-items: center; justify-content: center;">
              <div class="py-5 text-center">
                <v-alert
                  dense
                  outlined
                  type="error"
                >
                  <strong>{{ $t('register.invaLink_activeAccUser') }}</strong>
                </v-alert>
                <p></p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </div>
    </v-main>

    <v-footer color="#283271" padless>
      <footerP/>
    </v-footer>
  </v-app>
</template>
<script>
/*eslint-disable */
import axios from "axios";
import footerP from "./structure_p/FooterP.vue"
import NavbarP from "./structure_p/NavbarP.vue"

export default {
  name: "active",
  components: {
    footerP,
    NavbarP
  },
  mounted() {
    this.listUsers();
  },
  data: function () {
    return {
      dataUser: null,
      nameUser: "",
      emailaccount: "",
      passwordaccount: "",
      password2account: "",
      isActiveUser: false,
      isUser: null,
      modalShow: false
    };
  },
  methods: {
    listUsers: async function () {
      var tmpObJ;
      await axios
        .get(
          "https://api.safered.co/api/getUserInactive/" +
            this.$route.params.emailClient
        )
        .then((result) => {
          this.isUser = true
          tmpObJ = result.data[0];
        })
        .catch((error) => {
          console.log(error.status);
          this.isUser = false
        });
      this.emailaccount = tmpObJ.email;
      this.nameUser = tmpObJ.name + " " + tmpObJ.lastname;
      this.dataUser = tmpObJ;
      this.client_id = tmpObJ.client_id;
      if(tmpObJ.active != null){
        console.log(tmpObJ.active)
        return tmpObJ.active.replace(/['"]+/g, "");
      }
      else {
        console.log('null:')
        console.log(tmpObJ.active)
        return false
      }
    },
    saveUser: async function () {
      if (
        this.emailaccount !== "" &&
        this.passwordaccount !== "" &&
        this.password2account !== "" &&
        this.nameUser !== ""
      ) {
        if (this.passwordaccount === this.password2account) {
          let formData = new FormData();
          formData.append("name", this.nameUser);
          formData.append("email", this.emailaccount);
          formData.append("password", this.passwordaccount);
          formData.append("client_id", this.client_id);

          var createUser = false;
          await axios
            .post("https://api.safered.co/api/register", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((result) => {
              if (result.status === 200) {
                alert(this.$t('register.notCreated_activeAccUser'));
                createUser = true;
              } else {
                createUser = true;
                alert(
                  this.$t('register.statUpd_activeAccUser')
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });

          if (createUser) {
            await axios
              .put(
                "https://api.safered.co/api/updateStatus/"+formData.getAll('email')[0]+"/user"
              )
              .then((result) => {
                if (result.status === 200) {
                  alert(this.$t('register.statUpdNot_activeAccUser'));
                  createUser = true;
                } else {
                  createUser = true;
                  alert(this.$t('register.passNotMatch_activeAccUser'));
                }
              });
              this.$router.replace("/");
          }
        } else {
          alert(this.$t('register.'));
        }
      } else {
        if (this.emailaccount === "") {
          alert(this.$t('register.enterValEmail_activeAccUser'));
        } else if (this.passwordaccount === "") {
          alert(this.$t('register.enterValPass_activeAccUser'));
        } else if (this.password2account === "") {
          alert(this.$t('register.confPass_activeAccUser'));
        } else if (this.nameUser === "") {
          alert(this.$t('register.insetUName_activeAccUser'));
        }
      }
    },
  },
};
/*eslint-enable */
</script>
<style>
.bg-primary-dark {
  background-color: #283271;
}
</style>

